import { FC, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { universalDateAndTimeFormatter } from '../../../../utils/time';
import { HandoverRemark } from '../../../../types/handover-remarks';
import { utc } from 'moment';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tooltip } from 'antd';
import { generate } from 'shortid';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { getRemarksBackgroundColor } from '../../utils';

export const HandoverCardView: FC<{
  editAllowed: boolean;
  remark: HandoverRemark;
  setEditMode?: () => void;
  onDelete?: () => void;
}> = ({ editAllowed, remark, onDelete, setEditMode }) => {
  const {
    remarks: description,
    lastUpdatedAtMs,
    updatedBy,
    createdAtMs,
    createdBy,
    id,
  } = remark;
  const userRow = `${updatedBy ? 'Last updated' : 'Created'} by ${
    updatedBy ? updatedBy : createdBy
  } at ${universalDateAndTimeFormatter(
    utc(lastUpdatedAtMs ? lastUpdatedAtMs : createdAtMs),
    'dayMonthYearUIz'
  )}`;
  const parsedDescription = ReactHtmlParser(description, {
    transform: node => {
      if (
        node.type === 'tag' &&
        typeof node.children[0]?.data === 'string' &&
        node.children[0]?.data.length > 0
      ) {
        if (node.name === 'strong') {
          return (
            <span className="bold" key={generate()}>
              {node.children[0].data}
            </span>
          );
        }
        if (node.name === 'em') {
          return (
            <span className="italic" key={generate()}>
              {node.children[0].data}
            </span>
          );
        }
      }
    },
  });
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isSorting,
  } = useSortable({
    id,
    transition: {
      duration: 50, // milliseconds
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    },
  });
  const isAllView = typeof setEditMode !== 'undefined';
  const editable = isAllView && editAllowed;
  const sortingCursor = isSorting ? 'grabbing' : 'grab';
  const [isDeletePopConfirmVisible, setDeletePopConfirmVisible] = useState<
    boolean
  >(false);
  const onClickDeleteButton = () => {
    setDeletePopConfirmVisible(c => !c);
    setDeleteTooltipVisible(false);
  };
  const onConfirmDelete = () => {
    onDelete();
    setDeletePopConfirmVisible(false);
  };
  const [isDeleteTooltipVisible, setDeleteTooltipVisible] = useState<boolean>(
    false
  );
  return (
    <div
      className="remarks-card-view-container"
      style={{
        transform: CSS.Translate.toString(transform),
        transition,
        background: getRemarksBackgroundColor(remark),
        cursor: editable ? sortingCursor : isAllView ? 'auto' : 'inherit',
      }}
      {...attributes}
      ref={setNodeRef}
    >
      <Tooltip title="Edit Handover remark">
        <Button
          icon={<EditOutlined />}
          size="small"
          onClick={setEditMode}
          hidden={!editable}
        />
      </Tooltip>
      <Popconfirm
        onConfirm={onConfirmDelete}
        title="Are you sure you want to delete this remark?"
        okText="Yes, Delete"
        cancelText="No, Cancel"
        placement="top"
        open={isDeletePopConfirmVisible}
        onCancel={() => setDeletePopConfirmVisible(false)}
      >
        <Tooltip
          title="Delete Handover remark"
          open={isDeleteTooltipVisible}
          onOpenChange={visible => setDeleteTooltipVisible(visible)}
        >
          <Button
            hidden={!editable}
            icon={<DeleteOutlined />}
            size="small"
            style={{
              right: '34px',
            }}
            onClick={onClickDeleteButton}
          />
        </Tooltip>
      </Popconfirm>

      <div className="remarks-card-view-container-text" {...listeners}>
        <div className="remarks-card-view-container-text-main">
          {parsedDescription}
        </div>
        <div className="remarks-card-view-container-text-user">{userRow}</div>
      </div>
    </div>
  );
};
