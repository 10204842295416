import { apolloClient } from '../../data-processing/graphql';
import { gql } from '@apollo/client';

const getEmailAddressOptionsGql = require('./get-email-address-options.gql');

export function getEmailAddressOptions(filter: string) {
  return apolloClient.query<{ filter: string }>({
    query: gql(getEmailAddressOptionsGql),
    variables: {
      filter,
    },
  });
}
