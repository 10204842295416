import { message } from 'antd';
import { scaleTime } from 'd3-scale';
import { xor } from 'lodash';
import { utc } from 'moment';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import * as actions from '../actions';
import {
  FILTER_BAR_HEIGHT,
  HOLD_FLIGHT_HEIGHT,
  HOLD_FLIGHT_MARGIN,
  HOLD_LANE_STROKE,
  MAX_HOLD_FLIGHTS_ON_LESS_THAN_3_DAYS_VIEW,
  MULTIPLE_SEARCH_BAR_HEIGHT,
  OFFSET_FOR_VERTICAL_MODE,
} from '../constants';
import { SIDEBAR_ENABLED } from '../constants/environment';
import { getZoomLevelForHoldFlightRender } from '../d3/components/zoom';
import Aircraft, { MxEventPresenceType } from '../types/aircraft';
import PeakDay from '../types/peak-day';
import EventElement, { OverlappedElements } from '../types/event-element';
import Flight, {
  FlightLabelTimeType,
  HoldFlightsPoolHoursDelimiter,
} from '../types/flight';
import { GroundTimeType } from '../types/ground-time';
import {
  PeakDaysSegmentType,
  ExtendedSegmentType,
  SegmentType,
} from '../types/segment-types';
import Transform from '../types/transform';
import { DEFAULT_USER_PREFERENCES } from '../types/user-preferences';
import { getEuclideanDistance, getPositionMap } from '../utils';
import { RootState } from './';
import { AircraftTogglerState } from './aircraft';

const BOTTOM_WHITE_SPACE = 2;
const timeStart = utc().startOf('year');
const timeEnd = timeStart.clone().add(1, 'week');
export const getInitialScale = width =>
  scaleTime<number, number>()
    .domain([timeStart.toDate(), timeEnd.toDate()])
    .range([0, width]);
export type DayZoomLevel = '1d' | '3d' | '1w' | '2w' | '3w';
export type StatisticsMode = 'VG' | 'VJ' | 'XOJET' | 'RWA' | 'JE' | 'AH';
export interface ReducerShape {
  width: number;
  height: number;
  isVerticalMode: boolean;
  textLabelAbility: boolean;
  transform: Transform;
  rowHeight: number;
  holdAircraftPositionMap: { [id: number]: { y1: number; y2: number } };
  planeBlockWidth: number;
  overviewMapHeight: number;
  marginTop: number;
  holdLineHeight: number;
  controlsBarHeight: number;
  timelineBarHeight: number;
  filterBarHeight: number;
  multipleSearchBarHeight: number;
  isShowFilter: boolean;
  isShowMultipleSearchBar: boolean;
  hoveredAircraftIndex: number;
  segmentsVisibility: { [st in ExtendedSegmentType]: boolean };
  isSidebarCollapsed: boolean;
  openedOverlappedElement: OverlappedElements;
  exactHoveredNote: EventElement;
  hoveredNoteId: number;
  dataForSegment: EventElement | PeakDay;
  hoveredSegment: SegmentType | PeakDaysSegmentType;
  timeLabelDisplayMode: FlightLabelTimeType;
  mxEventPresenceType: MxEventPresenceType;
  timelineSelection: {
    isSelecting: boolean;
    start: {
      x: number;
      y: number;
    };
    end: {
      x: number;
      y: number;
    };
  };
  mousePositionAtRightClick: {
    x: number;
    y: number;
  };
  isHiddenTooltipWrapper: boolean;
  isCreateEventMenuOpen: boolean;
  iframeOffset: { x: number; y: number };
  iframeDragStartPosition: { x: number; y: number };
  iframePosition: { x: number; y: number };
  isDaySeparatorOnTop: boolean;
  isScrollingVertically: boolean;
  isScrollingHorizontally: boolean;
  positionMap: { [key in SegmentType]: number };
  flightTooltipWidth: number;
  showContactSupportMsg: boolean;
  hoveredAircraftId: number;
  hoveredAircraftPart: string;
  zoomLvl: {
    value: DayZoomLevel;
    isChanging: boolean;
  };
  draggingFlightsSelection: {
    isSelecting: boolean;
    start: {
      x: number;
      y: number;
    };
    end: {
      x: number;
      y: number;
    };
    dateStart: number;
    firstAircraftId: number;
  };
  selectedFlights: number[];
  newAircraftId: number;
  isMultipleDnDModalVisible: boolean;
  visibleHoldAcIds: number[];
  statisticsMode: StatisticsMode;
  isEditModeAcTypes: boolean;
}

const initialVisibility = {
  airportMismatches: true,
  groundTime: true,
  emptyLegOffers: false,
  fboMismatches: false,
  flights: true,
  maintenances: true,
  maintenanceItems: false,
  availabilityNotes: true,
  generalNotes: false,
  oneWayOffers: false,
  crewAssignment: false,
  crewRoster: false,
  crewRestNotes: true,
  handoverRemarks: false,
  handoverRemarks_cs: false,
};
const initialPositionMap = getPositionMap(initialVisibility);

export const initialState: ReducerShape = {
  width: 100,
  height: 100,
  isVerticalMode: false,
  textLabelAbility: true,
  rowHeight: getRowHeight(initialVisibility, initialPositionMap) || 55, // 55 is W/A for first render on local start
  planeBlockWidth: 130,
  overviewMapHeight: 240,
  marginTop: 40,
  holdLineHeight: 0,
  holdAircraftPositionMap: {},
  controlsBarHeight: 50,
  timelineBarHeight: 64,
  filterBarHeight: 0,
  multipleSearchBarHeight: 0,
  isShowFilter: false,
  isShowMultipleSearchBar: false,
  transform: {
    kx: 1,
    ky: 1,
    translateX: 0,
    translateY: 0,
    scaleX: getInitialScale(100),
  },
  hoveredAircraftIndex: 0,
  segmentsVisibility: initialVisibility,
  isSidebarCollapsed: SIDEBAR_ENABLED,
  dataForSegment: null,
  hoveredSegment: null,
  openedOverlappedElement: null,
  exactHoveredNote: null,
  hoveredNoteId: null,
  timeLabelDisplayMode: FlightLabelTimeType.NONE,
  mxEventPresenceType: 'ALL',
  timelineSelection: {
    isSelecting: false,
    start: { x: null, y: null },
    end: { x: null, y: null },
  },
  isHiddenTooltipWrapper: false,
  isCreateEventMenuOpen: false,
  isScrollingVertically: false,
  isScrollingHorizontally: false,
  isDaySeparatorOnTop: false,
  mousePositionAtRightClick: {
    x: null,
    y: null,
  },
  iframeOffset: { x: 0, y: 0 },
  iframeDragStartPosition: undefined,
  iframePosition: null,
  positionMap: initialPositionMap,
  flightTooltipWidth: 400,
  showContactSupportMsg: false,
  hoveredAircraftId: null,
  hoveredAircraftPart: null,
  zoomLvl: {
    value: null,
    isChanging: false,
  },
  draggingFlightsSelection: {
    isSelecting: false,
    start: { x: 0, y: 0 },
    end: { x: 0, y: 0 },
    dateStart: 0,
    firstAircraftId: null,
  },
  selectedFlights: [],
  newAircraftId: null,
  isMultipleDnDModalVisible: false,
  visibleHoldAcIds: [],
  statisticsMode: 'VJ',
  isEditModeAcTypes: false,
};

export const reducer = reducerWithInitialState(initialState)
  .case(
    actions.userMoveIframeStart,

    (state, { mousePos, nodeOffset }) => ({
      ...state,
      iframeDragStartPosition: { x: mousePos.x, y: mousePos.y },
      iframePosition: { x: nodeOffset.x, y: nodeOffset.y },
    })
  )
  .case(actions.userMoveIframe, (state, [x, y]) => ({
    ...state,
    iframeOffset: {
      x: x - state.iframeDragStartPosition.x,
      y: y - state.iframeDragStartPosition.y,
    },
  }))
  .case(actions.userMoveIframeEnd, (state, [x, y]) => ({
    ...state,
    iframeDragStartPosition: null,
    iframeOffset: {
      x: 0,
      y: 0,
    },
    iframePosition: {
      x: state.iframePosition.x + x - state.iframeDragStartPosition.x,
      y: state.iframePosition.y + y - state.iframeDragStartPosition.y,
    },
  }))
  .case(
    actions.timelineMounted,
    (
      state,
      {
        refDimensions: [width, height],
        innerDimensions: [innerWidth, innerHeight],
      }
    ) => {
      const isVerticalMode = innerHeight > innerWidth;
      const controlsBarHeight = isVerticalMode
        ? initialState.controlsBarHeight + OFFSET_FOR_VERTICAL_MODE
        : initialState.controlsBarHeight;
      return {
        ...state,
        width,
        height,
        isVerticalMode,
        controlsBarHeight,
        transform: {
          ...state.transform,
          scaleX: getInitialScale(width - state.planeBlockWidth),
        },
      };
    }
  )
  .case(actions.userHoverSegment, (state, payload) => ({
    ...state,
    hoveredSegment: payload[2],
    dataForSegment: payload[0],
    openedOverlappedElement: null,
    exactHoveredNote: payload[0],
    hoveredNoteId: null,
  }))
  .case(actions.userHoverCrew, (state, payload) => ({
    ...state,
    hoveredSegment: payload[2],
    dataForSegment: payload[0],
    openedOverlappedElement: null,
    exactHoveredNote: null,
    hoveredNoteId: null,
  }))
  .case(actions.userSetDaysSeparatorVisibility, (state, payload) => ({
    ...state,
    isDaySeparatorOnTop: payload,
  }))
  .case(actions.userHoverMergedNotes, (state, payload) => {
    return {
      ...state,
      exactHoveredNote: payload[3],
      hoveredSegment: payload[2],
      dataForSegment: payload[0],
      hoveredNoteId: null,
    };
  })
  .case(actions.userHoverOverlap, (state, payload) => ({
    ...state,
    openedOverlappedElement: payload[0],
    exactHoveredNote: null,
    hoveredNoteId: null,
  }))
  .case(actions.userHoverTooltipNote, (state, hoveredNoteId) => ({
    ...state,
    hoveredNoteId,
  }))
  .case(actions.userHoverTailBlock, (state, payload) => ({
    ...state,
    hoveredAircraftId: payload.aircraftId,
    hoveredAircraftPart: payload.part,
  }))
  .case(actions.userUnhoverTailTooltip, state => ({
    ...state,
    hoveredAircraftId: null,
    hoveredAircraftPart: null,
  }))
  .case(actions.userDragFlightsBegin, (state, payload) => ({
    ...state,
    isHiddenTooltipWrapper: true,
    timelineSelection: {
      ...state.timelineSelection,
      isSelecting: false,
    },
    draggingFlightsSelection: {
      ...state.draggingFlightsSelection,
      isSelecting: false,
    },
    selectedFlights: state.selectedFlights.includes(payload.flightId)
      ? state.selectedFlights
      : [payload.flightId],
  }))
  .case(actions.userDragFlightsEnd, (state, payload) => ({
    ...state,
    isMultipleDnDModalVisible: true,
    isHiddenTooltipWrapper: false,
    newAircraftId: payload.newAircraftId,
  }))
  .case(actions.userCancelDraggingFlights, state => ({
    ...state,
    draggingFlightsSelection: initialState.draggingFlightsSelection,
    newAircraftId: initialState.newAircraftId,
    selectedFlights: [],
  }))
  .case(actions.userSelectFlightsForDnDBegin, (state, payload) => ({
    ...state,
    isHiddenTooltipWrapper: true,
    draggingFlightsSelection: {
      ...state.draggingFlightsSelection,
      isSelecting: true,
      start: payload.start,
      end: payload.start, // for selection at the starting in a single point
      dateStart: payload.dateStart,
      firstAircraftId: payload.firstAircraftId,
    },
  }))
  .case(actions.userSelectFlightsForDnDInProgress, (state, payload) => ({
    ...state,
    draggingFlightsSelection: {
      ...state.draggingFlightsSelection,
      end: payload.end,
    },
  }))
  .case(actions.userSelectFlightsForDnDEnd, (state, payload) => ({
    ...state,
    isHiddenTooltipWrapper: false,
    draggingFlightsSelection: {
      ...state.draggingFlightsSelection,
      isSelecting: false,
    },
    selectedFlights: payload.selectedFlights,
  }))
  .case(actions.userToggleFlightSelection, (state, { flightId }) => ({
    ...state,
    selectedFlights: xor(state.selectedFlights, [flightId]),
  }))
  .case(actions.userCloseDndModal, state => ({
    ...state,
    isMultipleDnDModalVisible: false,
    selectedFlights: [],
  }))
  .case(actions.userChangeTailForFlights.started, state => ({
    ...state,
    isMultipleDnDModalVisible: false,
    selectedFlights: [],
  }))
  .case(actions.userBlurOverlap, state => ({
    ...state,
    openedOverlappedElement: initialState.openedOverlappedElement,
  }))
  .case(actions.userZoomHor, (state, payload) => ({
    ...state,
    transform: {
      ...state.transform,
      translateX: payload.x,
      scaleX: payload.rescaleX(
        getInitialScale(state.width - state.planeBlockWidth)
      ),
      kx: payload.k,
    },
    isScrollingHorizontally: true,
    zoomLvl: {
      ...state.zoomLvl,
      value:
        state.transform.kx === payload.k || state.zoomLvl.isChanging
          ? state.zoomLvl.value
          : null,
    },
  }))
  .case(actions.userZoomVer, (state, payload) => {
    return {
      ...state,
      transform: {
        ...state.transform,
        translateY: Math.min(payload.y, 0),
        ky: payload.k,
      },
      isScrollingVertically: true,
    };
  })
  .case(actions.doStopScroll, state => ({
    ...state,
    isScrollingVertically: false,
    isScrollingHorizontally: false,
    zoomLvl: {
      ...state.zoomLvl,
      isChanging: false,
    },
  }))
  .case(actions.userHoverAircraft, (state, payload) => ({
    ...state,
    hoveredAircraftIndex: payload,
  }))
  .case(actions.userSetTextAbility, (state, payload) => ({
    ...state,
    textLabelAbility: payload,
  }))
  .case(actions.doEnableTextLabels, state => ({
    ...state,
    textLabelAbility: true,
  }))
  .case(actions.doDisableTextLabels, state => ({
    ...state,
    textLabelAbility: false,
  }))
  .case(actions.userSetSegmentVisibility, (state, payload) => {
    const [segmentType, isVisible] = payload;
    const nextSegmentsVisibility = {};
    if (segmentType === 'flights') {
      nextSegmentsVisibility['flights'] = isVisible;
    }
    if (segmentType === 'maintenances') {
      nextSegmentsVisibility['maintenances'] = isVisible;
    }
    if (segmentType === 'crew') {
      nextSegmentsVisibility['crewRoster'] = isVisible;
      nextSegmentsVisibility['crewAssignment'] = isVisible;
    } else nextSegmentsVisibility[segmentType] = isVisible;
    const newVisibility = {
      ...state.segmentsVisibility,
      ...nextSegmentsVisibility,
    };
    const positionMap = getPositionMap(newVisibility);
    // if at least one entity is visible
    if (Object.keys(newVisibility).some(key => newVisibility[key]))
      return {
        ...state,
        rowHeight: getRowHeight(newVisibility, positionMap),
        segmentsVisibility: newVisibility,
        positionMap,
      };
    return state;
  })
  .case(actions.userSetGroundTimeType, (state, payload: GroundTimeType) => {
    const visible = payload !== 'NONE' && payload !== 'FBO';
    return {
      ...state,
      segmentsVisibility: {
        ...state.segmentsVisibility,
        groundTime: visible,
        airportMismatches: visible,
        fboMismatches: payload === 'FBO',
      },
    };
  })
  .case(
    actions.timelineResized,
    (
      state,
      {
        refDimensions: [width, height],
        innerDimensions: [innerWidth, innerHeight],
      }
    ) => {
      const isVerticalMode = innerHeight > innerWidth;
      const controlsBarHeight = isVerticalMode
        ? initialState.controlsBarHeight + OFFSET_FOR_VERTICAL_MODE
        : initialState.controlsBarHeight;
      return {
        ...state,
        width,
        height,
        isVerticalMode,
        controlsBarHeight,
        transform: {
          ...state.transform,
          translateX: -getInitialScale(state.width - state.planeBlockWidth)(
            state.transform.scaleX.domain()[0]
          ),
          scaleX: state.transform.scaleX.copy().range([0, width]),
        },
      };
    }
  )
  .case(
    actions.userToggleTimeLabelView,
    (state, payload: FlightLabelTimeType) => ({
      ...state,
      timeLabelDisplayMode: payload,
    })
  )
  .case(actions.userToggleAircraftByMxEvents, (state, payload) => {
    return {
      ...state,
      mxEventPresenceType: payload,
    };
  })
  .case(actions.userToggleFilterPanel, state => {
    const isShowFilter = !state.isShowFilter;
    return {
      ...state,
      filterBarHeight: isShowFilter ? FILTER_BAR_HEIGHT : 0,
      isShowFilter,
    };
  })
  .case(actions.userToggleMultipleSearchBarPanel, state => {
    const isShowMultipleSearchBar = !state.isShowMultipleSearchBar;
    return {
      ...state,
      multipleSearchBarHeight: isShowMultipleSearchBar
        ? MULTIPLE_SEARCH_BAR_HEIGHT
        : 0,
      isShowMultipleSearchBar,
    };
  })
  .case(actions.userTimelineSelectionBegin, (state, payload) => ({
    ...state,
    mousePositionAtRightClick: initialState.mousePositionAtRightClick,
    timelineSelection: {
      ...state.timelineSelection,
      isSelecting: true,
      start: {
        x: payload.mouseX,
        y: payload.mouseY,
      },
      end: {
        x: payload.mouseX,
        y: payload.mouseY,
      },
    },
    isCreateEventMenuOpen: false,
    draggingFlightsSelection: initialState.draggingFlightsSelection,
  }))
  .case(actions.userTimelineSelection, (state, payload) => ({
    ...state,
    timelineSelection: {
      ...state.timelineSelection,
      end: payload,
    },
  }))
  .case(actions.userTimelineSelectionEnd, (state, payload) => ({
    ...state,
    timelineSelection: {
      ...state.timelineSelection,
      isSelecting: false,
    },
    isCreateEventMenuOpen: getEuclideanDistance(state.timelineSelection) > 3,
    selectedFlights: state.isMultipleDnDModalVisible
      ? state.selectedFlights
      : initialState.selectedFlights,
  }))
  .case(actions.wsUpdateBatch, (state, payload) => {
    const { flights } = payload.data;
    if (!flights) return state;
    let openedOverlappedElement = state.openedOverlappedElement;
    if (
      state.openedOverlappedElement?.elements.some(e => flights[e.id] === null)
    ) {
      openedOverlappedElement = initialState.openedOverlappedElement;
    }

    if (state.openedOverlappedElement?.elements.some(e => !!flights[e.id])) {
      openedOverlappedElement = {
        ...state.openedOverlappedElement,
        elements: state.openedOverlappedElement.elements.map(
          el => flights[el.id] || el
        ),
      };
    }
    let selectedFlights = state.selectedFlights;
    if (selectedFlights.some(e => flights[e] === null)) {
      const cancelledFlights = Object.keys(flights).reduce((acc, key) => {
        if (flights[key] === null) {
          return acc.concat(+key);
        }
        return acc;
      }, []);
      selectedFlights = xor(selectedFlights, cancelledFlights);
      message.error(
        `Flight${
          cancelledFlights.length > 1
            ? `s ${cancelledFlights.map(f => f).join(', ')} were`
            : ` ${cancelledFlights[0]} was`
        } cancelled during changing the tail. Unable to proceed with the operation. Please, choose active flights for changing tail`,
        10
      );
    }
    return {
      ...state,
      openedOverlappedElement,
      selectedFlights,
    };
  })
  .cases(
    [
      actions.userClickCreateMaintenance,
      actions.userClickCreateNote,
      actions.userClickToOpenArincEmail,
      actions.doCloseContextMenu,
    ],
    state => ({
      ...state,
      isCreateEventMenuOpen: false,
    })
  )
  .case(actions.userOpenHandoverRemarksDrawer, state => ({
    ...state,
    isCreateEventMenuOpen: false,
  }))
  .case(actions.userOpenCreateEventsMenu, (state, payload) => ({
    ...state,
    isCreateEventMenuOpen: true,
    mousePositionAtRightClick: payload.mousePositionAtRightClick,
  }))
  .case(actions.userOpenCreateEventsMenuFromTopBar, state => ({
    ...state,
    isCreateEventMenuOpen: false,
  }))
  .cases(
    [
      actions.setReInitLoadingDataOnWebSocketConnectionLost.started,
      actions.webSocketConnectionClosed,
    ],
    state => {
      const {
        width,
        height,
        textLabelAbility,
        rowHeight,
        planeBlockWidth,
        overviewMapHeight,
        marginTop,
        timelineBarHeight,
        filterBarHeight,
        multipleSearchBarHeight,
        isShowFilter,
        transform,
        segmentsVisibility,
        isDaySeparatorOnTop,
        timeLabelDisplayMode,
        timelineSelection,
        mousePositionAtRightClick,
        iframeOffset,
        iframeDragStartPosition,
        iframePosition,
        isScrollingVertically,
        isScrollingHorizontally,
        showContactSupportMsg,
        positionMap,
        hoveredAircraftId,
        hoveredAircraftPart,
        hoveredNoteId,
        hoveredSegment,
        holdAircraftPositionMap,
        holdLineHeight,
        exactHoveredNote,
        hoveredAircraftIndex,
        openedOverlappedElement,
        dataForSegment,
        isCreateEventMenuOpen,
        visibleHoldAcIds,
        statisticsMode,
      } = state;
      return {
        ...initialState,
        width,
        height,
        textLabelAbility,
        rowHeight,
        planeBlockWidth,
        overviewMapHeight,
        marginTop,
        holdAircraftPositionMap,
        holdLineHeight,
        timelineBarHeight,
        filterBarHeight,
        multipleSearchBarHeight,
        isDaySeparatorOnTop,
        timelineSelection,
        isShowFilter,
        transform,
        timeLabelDisplayMode,
        segmentsVisibility,
        iframeOffset,
        iframePosition,
        iframeDragStartPosition,
        mousePositionAtRightClick,
        isScrollingVertically,
        isScrollingHorizontally,
        showContactSupportMsg,
        positionMap,
        hoveredAircraftId,
        hoveredAircraftPart,
        hoveredNoteId,
        hoveredSegment,
        exactHoveredNote,
        hoveredAircraftIndex,
        openedOverlappedElement,
        dataForSegment,
        isCreateEventMenuOpen,
        visibleHoldAcIds,
        statisticsMode,
      };
    }
  )
  .cases(
    [
      actions.userFocusOverlapCards,
      actions.userOpenFlightMenu,
      actions.userOpenNoteContextMenu,
      actions.userOpenContextMenuForMxEvent,
      actions.userOpenContextMenuForPeakDay,
      actions.userOpenContextMenuForMxEvent,
      actions.userOpenContextMenuForEmptyLegOffer,
      actions.userOpenContextMenuForOneWayOffer,
    ] as any[],
    state => ({
      ...state,
      isCreateEventMenuOpen: false,
    })
  )
  .case(actions.doGetUserPreferences.done, (state, { result }) => {
    const newPositionMap = getPositionMap(result.segmentsVisibility);
    return {
      ...state,
      segmentsVisibility: result.segmentsVisibility,
      timeLabelDisplayMode: result.timeLabelDisplayMode,
      transform: {
        ...state.transform,
        kx: result.kx,
        ky: result.ky,
        scaleX: result.scaleX,
      },
      rowHeight: getRowHeight(result.segmentsVisibility, newPositionMap),
      isDaySeparatorOnTop: result.isDaySeparatorOnTop,
      zoomLvl: {
        ...state.zoomLvl,
        value: result.zoomLvl,
      },
      positionMap: newPositionMap,
      visibleHoldAcIds: result.visibleHoldAcIds,
      statisticsMode: result.statisticsMode,
      mxEventPresenceType: result.mxEventPresenceType,
    };
  })
  .case(actions.userResetPreferences, state => ({
    ...state,
    segmentsVisibility: DEFAULT_USER_PREFERENCES.segmentsVisibility,
    timeLabelDisplayMode: DEFAULT_USER_PREFERENCES.timeLabelDisplayMode,
    transform: {
      ...state.transform,
      kx: DEFAULT_USER_PREFERENCES.kx,
      ky: DEFAULT_USER_PREFERENCES.ky,
      scaleX: DEFAULT_USER_PREFERENCES.scaleX,
    },
    rowHeight: getRowHeight(initialVisibility, initialPositionMap),
    isDaySeparatorOnTop: DEFAULT_USER_PREFERENCES.isDaySeparatorOnTop,
    zoomLvl: {
      ...state.zoomLvl,
      value: DEFAULT_USER_PREFERENCES.zoomLvl,
    },
    visibleHoldAcIds: DEFAULT_USER_PREFERENCES.visibleHoldAcIds,
    statisticsMode: DEFAULT_USER_PREFERENCES.statisticsMode,
  }))
  .case(actions.doShowContactSupportMessage, state => ({
    ...state,
    showContactSupportMsg: true,
  }))
  .case(actions.userChangeZoomLevel, (state, payload) => ({
    ...state,
    zoomLvl: {
      value: payload.zoomLevel,
      isChanging: true,
    },
  }))
  .case(actions.userToggleSidebar, state => ({
    ...state,
    isSidebarCollapsed: !state.isSidebarCollapsed,
  }))
  .case(actions.userToggleHoldLineTypeView, (state, payload) => ({
    ...state,
    visibleHoldAcIds: payload,
  }))
  .case(actions.doSetHeightForHoldAircraft, (state, payload) => {
    const { holdAircraftPositionMap, holdLineHeight } = getHoldLinePosition(
      state,
      payload
    );
    return {
      ...state,
      holdAircraftPositionMap,
      holdLineHeight,
    };
  })
  .case(actions.userMoveFlightToTail, (state, payload) => ({
    ...state,
    isMultipleDnDModalVisible: true,
    isHiddenTooltipWrapper: false,
    newAircraftId: payload.newAircraftId,
    selectedFlights: [payload.selectedFlightId],
  }))
  .case(actions.userChangeStatisticsMode, (state, payload) => ({
    ...state,
    statisticsMode: payload,
  }))
  .case(actions.userToggleEditModeOfAcTypesPosition, (state, payload) => ({
    ...state,
    isEditModeAcTypes: payload,
  }))
  .case(actions.userSavePreferences.started, state => ({
    ...state,
    isEditModeAcTypes: false,
  }))
  .case(actions.userResetChangesInAcTypesPositionMap, state => ({
    ...state,
    isEditModeAcTypes: false,
  }));

export function getRowHeight(
  visible: { [st in SegmentType]: boolean },
  positionMap: { [k in SegmentType]: number }
): number {
  const height =
    getElementOffset(visible, 'maintenanceItems', {}, positionMap) +
    (visible.maintenanceItems ? positionMap.maintenanceItems : 0) +
    BOTTOM_WHITE_SPACE;
  return isNaN(height)
    ? positionMap.availabilityNotes + BOTTOM_WHITE_SPACE
    : height;
}
export function getRowFullHeight(
  visible: { [st in SegmentType]: boolean },
  togglerState: AircraftTogglerState,
  positionMap: { [k in SegmentType]: number }
) {
  return (
    getElementOffset(visible, 'maintenanceItems', togglerState, positionMap) +
    (visible.maintenanceItems ? positionMap.maintenanceItems : 0) +
    BOTTOM_WHITE_SPACE
  );
}

export function getElementOffset(
  visible: { [st in SegmentType]: boolean },
  segment: SegmentType,
  togglerState: AircraftTogglerState,
  positionMap: { [k in SegmentType]: number }
) {
  const firstLine = [
    'emptyLegOffers',
    'oneWayOffers',
    'maintenances',
    'flights',
    'groundTime',
    'airportMismatches',
    'fboMismatches',
  ];
  const firstLineHeight = positionMap.availabilityNotes;
  if (segment === 'flights') {
    return firstLineHeight - positionMap.flights;
  }
  if (segment === 'maintenances') {
    return firstLineHeight - positionMap.maintenances;
  }
  if (
    segment === 'groundTime' ||
    segment == 'airportMismatches' ||
    segment === 'fboMismatches'
  ) {
    return firstLineHeight - positionMap.groundTime;
  }
  if (segment === 'emptyLegOffers' || segment === 'oneWayOffers') {
    return firstLineHeight - positionMap.emptyLegOffers;
  }
  if (firstLine.some(s => s === segment)) {
    return 0;
  }
  if (segment === 'crewRoster' || segment === 'crewAssignment') {
    return firstLineHeight;
  }
  const togglerSafeState = togglerState || {};
  const secondLineHeight =
    visible.crewAssignment || visible.crewRoster || togglerSafeState.crew
      ? positionMap.crewRoster
      : 0;
  if (segment === 'availabilityNotes') {
    return 0;
  }
  if (segment === 'generalNotes') {
    /** Availability notes + first line */
    return firstLineHeight + secondLineHeight;
  }
  const generalNotesHeight =
    visible.generalNotes || togglerSafeState.notes
      ? positionMap.generalNotes
      : 0;

  if (segment == 'maintenanceItems') {
    return secondLineHeight + generalNotesHeight + firstLineHeight;
  }
  return 0;
}
export const getLaneHeightKoef = (
  visible: { [st in SegmentType]: boolean },
  togglerState: AircraftTogglerState,
  positionMap: { [k in SegmentType]: number }
) => {
  return (
    getRowFullHeight(visible, togglerState, positionMap) /
    getRowHeight(visible, positionMap)
  );
};

export const getElementHeight = (
  visible: { [st in SegmentType]: boolean },
  segment: SegmentType,
  togglerState: AircraftTogglerState,
  positionMap: { [k in SegmentType]: number }
) => {
  const ky = getLaneHeightKoef(visible, togglerState, positionMap);
  return positionMap[segment] / ky;
};
export function getElementOffsetWithKoef(
  visible: { [st in SegmentType]: boolean },
  segment: SegmentType,
  togglerState: AircraftTogglerState,
  positionMap: { [k in SegmentType]: number }
) {
  return (
    getElementOffset(visible, segment, togglerState, positionMap) /
    getLaneHeightKoef(visible, togglerState, positionMap)
  );
}

export const getIndexByYPosition = (
  state: RootState,
  y: number,
  holdAcList?: Aircraft[]
) => {
  const {
    transform,
    holdLineHeight,
    holdAircraftPositionMap,
    marginTop,
    rowHeight,
  } = state.ui;
  const { ky, translateY } = transform;
  const absolutePosition = y - marginTop;
  const relativePosition = absolutePosition - translateY - holdLineHeight;
  const isOnHoldLine = holdLineHeight > 0 && absolutePosition < holdLineHeight;
  if (isOnHoldLine) {
    const holdAcId = Object.keys(holdAircraftPositionMap).find(
      key =>
        holdAircraftPositionMap[key].y1 < absolutePosition &&
        holdAircraftPositionMap[key].y2 >= absolutePosition
    );
    const index = holdAcList?.findIndex(ac => ac.id === +holdAcId);
    return index ?? -1;
  }
  const holdAcLength = holdAcList?.length ?? 0;
  const fromLengthToIndex = 1;
  return Math.ceil(
    relativePosition / ky / rowHeight - fromLengthToIndex + holdAcLength
  );
};

export const getDragPosition = (
  state: ReducerShape,
  event: DragEvent | React.DragEvent<Element>
) => {
  const {
    timelineBarHeight,
    filterBarHeight,
    marginTop,
    planeBlockWidth,
    multipleSearchBarHeight,
  } = state;
  const absoluteOffset =
    timelineBarHeight + filterBarHeight + multipleSearchBarHeight + marginTop;
  const leftOffset = planeBlockWidth;
  return { x: event.clientX - leftOffset, y: event.clientY - absoluteOffset };
};

export const getVerticalOffsetToElementsNode = (
  marginTop: number,
  holdLineHeight: number,
  translateY: number
): number => marginTop + holdLineHeight + translateY;

function getHoldLinePosition(
  state: ReducerShape,
  flights: HoldFlightsPoolHoursDelimiter
): {
  holdAircraftPositionMap: ReducerShape['holdAircraftPositionMap'];
  holdLineHeight: number;
} {
  const {
    holdLineHeightDailyMapByAircraftId,
    holdLineHeightHourlyMapByAircraftId,
  } = Object.keys(flights).reduce<{
    holdLineHeightHourlyMapByAircraftId: { [aircraftId: number]: number };
    holdLineHeightDailyMapByAircraftId: { [aircraftId: number]: number };
  }>(
    (acc, aircraftId) => {
      const flightsPool = flights[aircraftId];
      acc.holdLineHeightHourlyMapByAircraftId = {
        ...acc.holdLineHeightHourlyMapByAircraftId,
        [aircraftId]:
          Math.max(
            ...flightsPool.map(day =>
              Math.max(
                ...Object.values(day.pool).map((x: Flight[]) => x.length)
              )
            )
          ) *
          (HOLD_FLIGHT_HEIGHT + HOLD_FLIGHT_MARGIN),
      };
      acc.holdLineHeightDailyMapByAircraftId = {
        ...acc.holdLineHeightDailyMapByAircraftId,
        [aircraftId]:
          Math.max(
            ...flightsPool.map(day =>
              Object.keys(day.pool).reduce((acc, key) => {
                return (acc += day.pool[key].length);
              }, 0)
            )
          ) *
          (HOLD_FLIGHT_HEIGHT + HOLD_FLIGHT_MARGIN),
      };
      return acc;
    },
    {
      holdLineHeightHourlyMapByAircraftId: {},
      holdLineHeightDailyMapByAircraftId: {},
    }
  );
  const zoomLevel = getZoomLevelForHoldFlightRender(state.transform.scaleX);
  const {
    holdAircraftPositionMap,
    holdLineHeight,
  } = state.visibleHoldAcIds.reduce<{
    holdAircraftPositionMap: { [id: number]: { y1: number; y2: number } };
    holdLineHeight: number;
  }>(
    (acc, key, index) => {
      const heightWithMaxFightsHourly =
        holdLineHeightHourlyMapByAircraftId[key];
      const heightOfAllFlightsInDay = holdLineHeightDailyMapByAircraftId[key];
      const holdHeight =
        (() => {
          switch (zoomLevel) {
            case 'fullView': {
              return heightWithMaxFightsHourly &&
                heightWithMaxFightsHourly > state.rowHeight
                ? heightWithMaxFightsHourly
                : state.rowHeight;
            }
            case 'weeklyView': {
              const maxFlights = MAX_HOLD_FLIGHTS_ON_LESS_THAN_3_DAYS_VIEW;
              const maxFlightsHeightOnWeekView =
                (HOLD_FLIGHT_HEIGHT + HOLD_FLIGHT_MARGIN) * (maxFlights + 1); // 1 - is a button with rest of the flights
              if (heightOfAllFlightsInDay) {
                if (heightOfAllFlightsInDay > maxFlightsHeightOnWeekView) {
                  return maxFlightsHeightOnWeekView;
                }
                if (heightOfAllFlightsInDay > state.rowHeight) {
                  return heightOfAllFlightsInDay;
                }
                return state.rowHeight;
              }
              return state.rowHeight;
            }
            default:
              return state.rowHeight;
          }
        })() +
        HOLD_LANE_STROKE * 2;
      const prevKey = state.visibleHoldAcIds[index - 1];
      acc.holdAircraftPositionMap[key] = {
        y1: index === 0 ? 0 : acc.holdAircraftPositionMap[prevKey].y2,
        y2:
          index === 0
            ? holdHeight
            : acc.holdAircraftPositionMap[prevKey].y2 + holdHeight,
      };
      acc.holdLineHeight =
        index === state.visibleHoldAcIds.length - 1
          ? acc.holdAircraftPositionMap[key].y2
          : acc.holdLineHeight;
      return acc;
    },
    { holdAircraftPositionMap: {}, holdLineHeight: 0 }
  );
  return { holdLineHeight, holdAircraftPositionMap };
}
