import { PureComponent } from 'react';
import * as actions from '../../actions';
import { RootState } from '../../reducers';
import { connect } from 'react-redux';
import '../context-menu/styles.scss';
import { CreateEventsMenuCard } from './create-events-menu-card';
import { hasPermission } from '../../utils/check-permissions';

import {
  EmptyLegWindowParameters,
  getEmptyLegOfferWindowParams,
  openOrderWindow,
  openWindowAppEmptyLeg2020,
  OrderWindowParameters,
} from '../../utils';
import { message } from 'antd';
import { getAircraftById } from '../../selectors';
import { isHoldAircraft } from '../../common/aircraft/aircraft-check-status';
import { generate } from 'shortid';

interface StateProps {
  position: {
    x: number;
    y: number;
  };
  isOpen: boolean;
  hasCreateMxEventPermission: boolean;
  hasCreateOrderPermission: boolean;
  hasCreateNotePermission: boolean;
  hasCreateEmptyLegPermission: boolean;
  planeBlockWidth: number;
  aircraftId: number;
  start: number;
  end: number;
  beforeLegId: number;
  beforeMaintenanceId: number;
  afterLegId: number;
  afterMaintenanceId: number;
  isValidGap: boolean;
  isHoldAircraft: boolean;
  hasArincPermission: boolean;
  hasHandoverViewPermission: boolean;
}

interface DispatchProps {
  onClickCreateMxEvent: () => void;
  onClickCreateNote: () => void;
  onClickHandover: (aircraftId: number) => void;
  onClickCreateOrder: (props: OrderWindowParameters) => void;
  onClickCreateEmptyLeg: (props: EmptyLegWindowParameters) => void;
  onClickSendArincMessage: (aircraftId: number) => void;
}
class CreateEventsMenu extends PureComponent<StateProps & DispatchProps> {
  eventsMenuRef: HTMLDivElement;
  width: number;
  height: number;
  constructor(props) {
    super(props);
    this.width = 200; // context menu hardcoded the width
    this.height = 128; // default value
  }
  setEventsMenuRef = (ref: HTMLDivElement) => {
    this.eventsMenuRef = ref;
    if (this.eventsMenuRef)
      this.height = this.eventsMenuRef.getBoundingClientRect().height;
  };
  getLeftPosition = () => {
    const { position, planeBlockWidth } = this.props;
    const pageWidth = Math.max(window.innerWidth - planeBlockWidth, 0);
    const width =
      (this.eventsMenuRef &&
        this.eventsMenuRef.getBoundingClientRect().width) ||
      0;
    return pageWidth - position.x <= 20
      ? position.x - Math.max(width, this.width)
      : position.x;
  };
  getTopPosition = () => {
    const { position } = this.props;
    const pageHeight = Math.max(window.innerHeight, 0);
    const height =
      (this.eventsMenuRef &&
        this.eventsMenuRef.getBoundingClientRect().height) ||
      0;
    return pageHeight - position.y <= Math.max(height, this.height)
      ? position.y - Math.max(height, this.height)
      : position.y;
  };
  handleClickOrder = () =>
    this.props.onClickCreateOrder({
      aircraftId: this.props.aircraftId,
      start: this.props.start,
    });
  handleClickHandover = () => this.props.onClickHandover(this.props.aircraftId);
  handleClickCreateEmptyLeg = () =>
    this.props.onClickCreateEmptyLeg({
      aircraftId: this.props.aircraftId,
      start: this.props.start,
      end: this.props.end,
      afterLegId: this.props.afterLegId,
      beforeLegId: this.props.beforeLegId,
      beforeMaintenanceId: this.props.beforeMaintenanceId,
      afterMaintenanceId: this.props.afterMaintenanceId,
      isValidGap: this.props.isValidGap,
    });
  handleClickArinc = () => {
    this.props.onClickSendArincMessage(this.props.aircraftId);
  };
  render() {
    const {
      isHoldAircraft,
      isOpen,
      hasCreateMxEventPermission,
      hasCreateOrderPermission,
      hasCreateNotePermission,
      hasCreateEmptyLegPermission,
      hasArincPermission,
      hasHandoverViewPermission,
      onClickCreateMxEvent,
      onClickCreateNote,
      position,
    } = this.props;
    const isVisible =
      typeof position.y == 'number' && typeof position.x == 'number';

    return (
      isOpen &&
      isVisible && (
        <div
          style={{
            position: 'absolute',
            width: this.width,
            left: this.getLeftPosition(),
            top: this.getTopPosition(),
          }}
          ref={this.setEventsMenuRef}
        >
          <CreateEventsMenuCard
            hasCreateMxEventPermission={hasCreateMxEventPermission}
            hasCreateOrderPermission={hasCreateOrderPermission}
            hasCreateNotePermission={hasCreateNotePermission}
            hasCreateEmptyLegPermission={hasCreateEmptyLegPermission}
            hasHandoverViewPermission={hasHandoverViewPermission}
            onClickCreateMxEvent={onClickCreateMxEvent}
            onClickCreateNote={onClickCreateNote}
            onClickCreateOrder={this.handleClickOrder}
            onClickCreateEmptyLeg={this.handleClickCreateEmptyLeg}
            onClickSendArincMessage={this.handleClickArinc}
            onClickHandover={this.handleClickHandover}
            hasArincPermission={hasArincPermission}
            isHoldAircraft={isHoldAircraft}
          />
        </div>
      )
    );
  }
}
const isRightClickPosition = ui => {
  const { mousePositionAtRightClick } = ui;
  return (
    mousePositionAtRightClick &&
    mousePositionAtRightClick.x !== null &&
    mousePositionAtRightClick.y !== null
  );
};

const mapStateToProps = (state: RootState): StateProps => {
  const { aircraftId, start, end } = state.eventElementCreationEdit;
  return {
    position: isRightClickPosition(state.ui)
      ? state.ui.mousePositionAtRightClick
      : state.ui.timelineSelection.end,
    isHoldAircraft: aircraftId
      ? isHoldAircraft(getAircraftById(state, aircraftId))
      : false,
    isOpen: state.ui.isCreateEventMenuOpen,
    hasCreateNotePermission: hasPermission(state, 'AG-Timeline-Create-Note'),
    hasCreateOrderPermission: hasPermission(state, 'AG-Timeline-Create-Order'),
    hasCreateMxEventPermission: hasPermission(
      state,
      'AG-Timeline-Create-MX-Event'
    ),
    hasCreateEmptyLegPermission: hasPermission(
      state,
      'AG-Timeline-View-EL-Offer'
    ),
    hasHandoverViewPermission: hasPermission(
      state,
      'AG-Timeline-Handover-View'
    ),
    planeBlockWidth: state.ui.planeBlockWidth,
    aircraftId,
    start,
    end,
    ...getEmptyLegOfferWindowParams(
      state.timelineEvents.flights,
      state.timelineEvents.maintenances,
      start,
      end,
      aircraftId
    ),
    hasArincPermission: hasPermission(state, 'AG-Timeline-MOC_ARINC-send'),
  };
};

const mapDispatchToProps = (dispatch): DispatchProps => ({
  onClickCreateMxEvent: () =>
    dispatch(
      actions.userClickCreateMaintenance({
        end: null,
        start: null,
        uniqIdentifier: generate(),
      })
    ),
  onClickCreateNote: () =>
    dispatch(
      actions.userClickCreateNote({
        end: null,
        start: null,
        uniqIdentifier: generate(),
      })
    ),
  onClickCreateOrder: ({ aircraftId, start }) => {
    dispatch(actions.doCloseContextMenu());
    openOrderWindow({ aircraftId, start });
  },
  onClickCreateEmptyLeg: ({
    start,
    end,
    aircraftId,
    afterLegId,
    beforeLegId,
    beforeMaintenanceId,
    afterMaintenanceId,
    isValidGap,
  }) => {
    dispatch(actions.doCloseContextMenu());
    if (!isValidGap) {
      message.warning('Could not retrieve last arrival airport');
      return;
    }
    openWindowAppEmptyLeg2020({
      start,
      end,
      aircraftId,
      afterLegId,
      beforeLegId,
      beforeMaintenanceId,
      afterMaintenanceId,
    });
  },
  onClickSendArincMessage: (aircraftId: number) => {
    dispatch(actions.userClickToOpenArincEmail(aircraftId));
  },
  onClickHandover: (aircraftId: number) => {
    dispatch(
      actions.userOpenHandoverRemarksDrawer({
        isFor: aircraftId,
      })
    );
  },
});

export const CreateEventsMenuWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEventsMenu);
