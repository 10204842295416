import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Popconfirm, Tooltip } from 'antd';
import { isEqual } from 'lodash';
import { CSSProperties, FC, memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Flight from '../../../../types/flight';
import {
  AllRemarksShape,
  HandoverRemark,
  HandoverRemarksViewByType,
} from '../../../../types/handover-remarks';
import { HandoverCardView } from '../card/HandoverCardView';
import { useInView } from 'react-intersection-observer';
import { HandoverRemarksState } from '../../../../reducers/handover-remarks';
import { HANDOVER_REMARK_CARD_WIDTH } from '../../constants';
import { OneRemarkTitleComponent } from '../title/OneRemarkTitle';
import { getUtilsForViewAllCardsStyle } from '../../utils';
import { RootState } from '../../../../reducers';
import { generate } from 'shortid';
import {
  doSetHandoverNavigateTo,
  userOpenHandoverRemarksDrawer,
} from '../../../../actions';

interface CardProps {
  navigate: HandoverRemarksState['navigate'];
  remark: HandoverRemark;
  onClick: () => void;
}
const CardComponent: FC<CardProps> = ({ navigate, remark, onClick }) => {
  const isActive = navigate && navigate.remarkId === remark.id;
  const [showBorder, setShowBorder] = useState(isActive);
  const [isScrolled, setIsScrolled] = useState(false);
  const [inViewRef, inView] = useInView({
    rootMargin: '40px',
    threshold: 1,
  });
  const setRef = (node: Element) => {
    if (node) {
      inViewRef(node);
      if (isActive && !inView && !isScrolled) {
        setShowBorder(true);
        node.scrollIntoView({
          behavior: 'smooth',
        });
        setIsScrolled(true);
      }
    }
  };
  useEffect(() => {
    const onScroll = () => {
      if (showBorder) {
        setShowBorder(false);
      }
    };
    document.addEventListener('wheel', onScroll);
    return () => {
      document.removeEventListener('wheel', onScroll);
    };
  }, [showBorder]);
  return (
    <div
      key={remark.id}
      className="remarks-card"
      onClick={onClick}
      ref={setRef}
      style={{
        border: showBorder ? '1px solid orange' : 'none',
      }}
    >
      <HandoverCardView key={remark.id} remark={remark} editAllowed={false} />
    </div>
  );
};
const plusButtonId = 'first tail remark';

interface Props {
  isChangedOrder?: boolean;
  hasUnsavedRemarks?: boolean;
  tailNotesAreSticked: boolean;
  remarks: AllRemarksShape['remarks'][];
  showDivider: boolean;
  setNeedToAddRemark?: (flag: boolean) => void;
  reset?: () => void;
}
const ViewBodyContainer: FC<Props> = ({
  isChangedOrder = false,
  hasUnsavedRemarks = false,
  remarks,
  tailNotesAreSticked,
  showDivider,
  setNeedToAddRemark,
  reset,
}) => {
  const {
    filters: { radioByType },
    navigate,
    savingIds,
  } = useSelector<RootState, Partial<HandoverRemarksState>>(
    state => ({
      filters: state.handoverRemarks.filters,
      navigate: state.handoverRemarks.navigate,
      savingIds: state.handoverRemarks.savingIds,
    }),
    isEqual
  );
  const saving = savingIds?.length > 0;
  const dispatch = useDispatch();
  // control for navigation in case some edit in progress
  const [visiblePopConfirmCardId, setVisible] = useState<string | null>(null);
  useEffect(() => {
    if (!hasUnsavedRemarks && !isChangedOrder) {
      setVisible(null);
    }
  }, [hasUnsavedRemarks, isChangedOrder]);
  const onClickCard = (navigateTo: Flight | number, remarkId: string) => {
    // cancel case
    if (isChangedOrder || hasUnsavedRemarks) {
      reset();
    }
    // navigation
    dispatch(
      userOpenHandoverRemarksDrawer({
        isFor: navigateTo,
        navigateFrom: remarkId,
      })
    );
  };
  const onOpenTailHandoverWithAddingRemark = (aircraftId: number) => {
    dispatch(
      userOpenHandoverRemarksDrawer({
        isFor: aircraftId,
      })
    );
    setNeedToAddRemark(true);
  };
  const onClickPlusButton = (aircraftId: number) => {
    if (isChangedOrder || hasUnsavedRemarks) {
      if (visiblePopConfirmCardId === plusButtonId) {
        onOpenTailHandoverWithAddingRemark(aircraftId);
        setVisible(null);
        reset();
      } else {
        setVisible(plusButtonId);
        dispatch(doSetHandoverNavigateTo({ to: aircraftId, remarkId: null }));
        setNeedToAddRemark(true);
      }
    } else {
      onOpenTailHandoverWithAddingRemark(aircraftId);
    }
  };
  const onClickNavigate = (navigateTo: number | Flight, id: string) => {
    if (isChangedOrder || hasUnsavedRemarks) {
      dispatch(doSetHandoverNavigateTo({ to: navigateTo, remarkId: id }));
      setVisible(id);
    } else {
      onClickCard(navigateTo, id);
    }
  };
  const {
    getKeyByValue,
    isForTailRemarkArray,
    mapOfTails,
    tailsForAllRemarksArray,
  } = useMemo(() => getUtilsForViewAllCardsStyle(remarks), [remarks]);

  const getStyle = (
    c: AllRemarksShape['remarks'],
    index: number
  ): CSSProperties => {
    if (!tailNotesAreSticked || radioByType !== HandoverRemarksViewByType.all) {
      return {};
    }
    return c.aircraftId
      ? {
          position: isForTailRemarkArray[index + 1] ? 'relative' : 'sticky',
          top: '0px',
          zIndex: getKeyByValue(mapOfTails, tailsForAllRemarksArray[index]) + 1,
          background: 'white',
          paddingTop: '10px',
        }
      : {
          position: 'relative',
          zIndex: getKeyByValue(mapOfTails, tailsForAllRemarksArray[index]),
          background: 'white',
          paddingTop: '10px',
        };
  };
  return (
    // sticky position is working this way relative to its parent
    // only if next section is about flight - position will be sticky
    <>
      {remarks.map((c, index) => (
        <div style={getStyle(c, index)} key={generate()}>
          <div
            className={`remarks-card-container-${
              c.aircraftId ? 'tail' : 'flight'
            }`}
          >
            <Tooltip
              title={c.aircraftId && c.cards.length === 0 ? '' : c.title}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  className={`remarks-card-title-${
                    c.aircraftId ? 'tail' : 'flight'
                  }`}
                  style={{
                    width:
                      c.aircraftId && c.cards.length === 0
                        ? '450px'
                        : `${HANDOVER_REMARK_CARD_WIDTH}px`,
                  }}
                >
                  <OneRemarkTitleComponent
                    forTail={!!c.aircraftId}
                    title={c.title}
                    flight={c.flight}
                  />
                </div>
                {c.aircraftId && c.cards.length === 0 ? (
                  <Tooltip title="Add a new remark for this tail">
                    <Popconfirm
                      onCancel={() => onClickPlusButton(c.aircraftId)}
                      title={
                        isChangedOrder && !hasUnsavedRemarks
                          ? 'Would you like to save new order of remarks?'
                          : 'Would you like to save changes before switch?'
                      }
                      okText="Yes, Save"
                      cancelText="No"
                      open={visiblePopConfirmCardId === plusButtonId}
                      key={plusButtonId}
                      okButtonProps={{
                        htmlType: 'submit',
                        form: 'handoverRemarks',
                        loading: saving,
                      }}
                    >
                      <Button
                        icon={<PlusOutlined />}
                        type="primary"
                        onClick={() => onClickPlusButton(c.aircraftId)}
                        className="remarks-title-row-buttons-plus"
                      />
                    </Popconfirm>
                  </Tooltip>
                ) : null}
              </div>
            </Tooltip>
            {c.cards.map(rem => (
              <Popconfirm
                onCancel={() => onClickCard(c.flight || c.aircraftId, rem.id)}
                title={
                  isChangedOrder && !hasUnsavedRemarks
                    ? 'Would you like to save new order of remarks?'
                    : 'Would you like to save changes before switch?'
                }
                okText="Yes, Save"
                cancelText="No"
                open={visiblePopConfirmCardId === rem.id}
                key={rem.id}
                okButtonProps={{
                  htmlType: 'submit',
                  form: 'handoverRemarks',
                  loading: saving,
                }}
              >
                <CardComponent
                  onClick={() =>
                    onClickNavigate(c.flight || c.aircraftId, rem.id)
                  }
                  remark={rem}
                  navigate={navigate}
                />
              </Popconfirm>
            ))}
          </div>
          {showDivider ? (
            <Divider className="remarks-container-divider" />
          ) : null}
        </div>
      ))}
    </>
  );
};

export const MemoizedViewBodyContainer = memo(ViewBodyContainer);
