import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { FieldProps, FormikProps } from 'formik';
import htmlToDraft from 'html-to-draftjs';
import { debounce } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EmailFormValues } from './interfaces';
import { EmailFormFieldName } from './constants';

export const EmailBodyEditor: FC<FormikProps<EmailFormValues> & {
  field: { value: string };
} & FieldProps<EmailFormValues['body']>['field']> = props => {
  const {
    field: { value },
    setFieldValue,
  } = props;
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );

  useEffect(() => {
    if (value) {
      const blocksFromHTML = htmlToDraft(value);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(state));
    }
  }, [value]);

  const onChange = useMemo(
    () =>
      debounce((editorState: EditorState) => {
        setEditorState(editorState);
      }),
    []
  );

  const onBlur = useCallback(() => {
    setFieldValue(
      EmailFormFieldName.body,
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  }, [setFieldValue, editorState]);

  return (
    <Editor
      editorState={editorState}
      editorClassName="email-editor"
      wrapperClassName="email-editor"
      editorStyle={{
        height: '400px',
      }}
      onBlur={onBlur}
      onEditorStateChange={onChange}
      stripPastedStyles
      toolbar={{
        options: [
          'inline',
          'fontSize',
          'list',
          'textAlign',
          'colorPicker',
          'link',
          'remove',
          'history',
        ],
        inline: {
          options: ['bold', 'italic', 'underline', 'strikethrough'],
        },
        list: {
          options: ['unordered', 'ordered'],
        },
        fontSize: {
          options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48],
        },
        remove: {
          title: 'Clear formatting',
        },
      }}
      toolbarStyle={{
        padding: 0,
        margin: 0,
      }}
    />
  );
};
