import { PureComponent, MouseEvent } from 'react';
import { ScanOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { DispatchProp, connect } from 'react-redux';
import * as actions from '../../actions';
import { RootState } from '../../reducers';
import { AnyAction } from 'redux';

interface Props {
  isDragging: boolean;
  onDragStart: (
    mousePos: { x: number; y: number },
    nodeOffset: { x: number; y: number }
  ) => void;
}

export const TOP_BAR_HEIGHT = 46;

class DragTopBar extends PureComponent<Props & DispatchProp<AnyAction>> {
  ref: HTMLDivElement;
  getRef = ref => (this.ref = ref);
  onResetPosition = (e: MouseEvent) => {
    e.stopPropagation();
    this.props.dispatch(actions.userResetIframePosition());
  };
  render() {
    return (
      <div
        ref={this.getRef}
        style={{
          background: '#FFFFFF',
          cursor: this.props.isDragging ? 'grabbing' : 'grab',
          height: TOP_BAR_HEIGHT,
          padding: `8px 12px`,
          borderLeft: `2px solid grey`,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
        onClick={e => e.stopPropagation()}
        onMouseDown={e => {
          e.preventDefault();
          this.props.onDragStart(
            { x: e.clientX, y: e.clientY },
            {
              x: this.ref.parentElement.offsetLeft,
              y: this.ref.parentElement.offsetTop,
            }
          );
        }}
      >
        <Button
          style={{ background: '#F2F2F2' }}
          onClick={this.onResetPosition}
          icon={<ScanOutlined />}
        >
          Center Window
        </Button>
      </div>
    );
  }
}

export const DragTopBarConnected = connect((state: RootState) => ({}))(
  DragTopBar
);
