import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as actions from '../actions';
import { UserProfile } from '../types/user';

export interface UserReducerShape {
  userProfile: UserProfile;
  roles: string[];
  userName: string;
}

export const initialState: UserReducerShape = {
  userProfile: {
    email: '',
    family_name: '',
    given_name: '',
    groups: [],
    name: '',
    preferred_username: '',
    roles: [],
    sub: '',
  },
  roles: [],
  userName: null,
};

export default reducerWithInitialState(initialState).case(
  actions.receivedUserAndRoles,
  (state, payload) => ({
    ...state,
    ...payload,
  })
);
