import { apolloClient } from '../../data-processing/graphql';
import { MXServiceProviderDto } from '../../types/maintenance';
import { gql } from '@apollo/client';

const getMxServiceProviders = require('./get-service-provider.gql');

export function getServiceProvidersForAirport(airportId: number) {
  return apolloClient.query<MXServiceProviderDto>({
    query: gql(getMxServiceProviders),
    variables: {
      airportId,
    },
  });
}
