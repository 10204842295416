import { message, Tooltip } from 'antd';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { utc } from 'moment';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../actions';
import { isOwnedByVJ } from '../../../common/aircraft/aircraft-check-status';
import {
  isDraggableByOpStatus,
  isFlightOnDemandLike,
} from '../../../common/flight/flight-check-status';
import {
  HEIGHT_FLIGHT_MENU_TAILS,
  WIDTH_FLIGHT_MENU,
} from '../../../constants';
import { RootState } from '../../../reducers';
import {
  getAircraftByIdMapWithHold,
  getAvailableAircraftForMoveToTailMenu,
  getBaseCompaniesIds,
  getSubChartersAircraft,
  getAllAcTypeOptions,
} from '../../../selectors';
import Aircraft from '../../../types/aircraft';
import { OfferStatus } from '../../../types/empty-leg-offer';
import Flight, {
  BpmItemStatus,
  FUFI_CLASS_NAME,
  FUFI_TOOLTIP_TITLE,
  LegBusinessType,
  OperationalStatus,
} from '../../../types/flight';
import {
  openFlightCenter,
  openOnDemand,
  openOrderWindow,
  openWindowAppEmptyLeg2020,
  setDataTestEntityId,
} from '../../../utils';
import { hasPermission } from '../../../utils/check-permissions';
import ContactSupportTeamMessage from '../../contact-support-team';
import { ContextMenuWrapperAsPopper } from '../wrappers';
import { AutoAdvertisementInput } from '../../../types/operation-result-types';
import {
  GLOBAL_HUD_ENABLED,
  OLD_PFA_ENABLED,
} from '../../../constants/environment';
import { AnyAction, Dispatch } from 'redux';
interface ContextMenuStateProps {
  now: number;
  baseCompaniesIds: number[];
  aircraftMap: { [aircraftId: string]: Aircraft };
  openedFlight: Flight;
  hasPFAPermission: boolean;
  hasFPPermission: boolean;
  hasMarkFlightPermission: boolean;
  hasAddFerryPermission: boolean;
  hasCheckedEmptyLegOffersPermission: boolean;
  hasFCPermission: boolean;
  hasOWPermission: boolean;
  hasOQPermission: boolean;
  hasFlightMovePermission: boolean;
  hasHandoverViewPermission: boolean;
  acTypesOptions: { typeId: number; name: string; rank: number }[];
  aircraft: Aircraft[];
  subcharters: Aircraft[];
}
interface ContextMenuDispatchProps {
  openPFA: (orderId: number) => (e: React.MouseEvent<HTMLElement>) => void;
  openGlobalHudPFA: (
    orderId: number,
    operatingCompanyId: number
  ) => (e: React.MouseEvent<HTMLElement>) => void;
  openFP: (legId: number) => (e: React.MouseEvent<HTMLElement>) => void;
  openNearestAC: (flight: Flight) => (e: React.MouseEvent<HTMLElement>) => void;
  closeMenu: () => void;
  clickDemo: (flight: Flight) => (e: React.MouseEvent<HTMLElement>) => void;
  clickLineCheck: (
    flight: Flight
  ) => (e: React.MouseEvent<HTMLElement>) => void;
  clickLock: (flight: Flight) => (e: React.MouseEvent<HTMLElement>) => void;
  clickSensitive: (
    flight: Flight
  ) => (e: React.MouseEvent<HTMLElement>) => void;
  userMoveFlightToTail: (id: number, flightId: number) => void;
  excludeIncludeEmptyLeg: (payload: AutoAdvertisementInput) => void;
  openHandoverRemarks: (flight: Flight) => void;
}
interface FlightMenuClientRect {
  top: number;
  right: number;
  bottom: number;
  left: number;
  width: number;
  height: number;
  x: number;
  y: number;
}

interface State {
  flightMenuRootClientRect: FlightMenuClientRect;
  flightMenuDropClientRect: FlightMenuClientRect;
  isIndentBottomFlightMenuDrop: boolean;
  isIndentBottomFlightMenuDropTails: boolean;
  isIndentRightFlightMenuRoot: boolean;
  isIndentRightFlightMenuDrop: boolean;
  indentBottomFlightMenuDropTails: number;
  isHoverElement: boolean;
}

const getPositionContextFlightMenu = (
  flightMenuRootClientRect: FlightMenuClientRect,
  flightMenuDropClientRect: FlightMenuClientRect
) => {
  const offsetRightFlightMenuRoot =
    window.innerWidth -
    flightMenuRootClientRect?.left -
    flightMenuRootClientRect?.width;
  const offsetRightFlightMenuDrop =
    window.innerWidth -
    flightMenuDropClientRect?.left -
    flightMenuDropClientRect?.width;

  const offsetBottomFlightMenuRoot =
    window.innerHeight - flightMenuRootClientRect?.bottom;
  const bottomFlightMenuDropTails =
    flightMenuDropClientRect?.height - offsetBottomFlightMenuRoot;
  const offsetBottomFlightMenuDropTails =
    window.innerHeight - flightMenuDropClientRect?.bottom;
  const isIndentBottomFlightMenuDrop =
    offsetBottomFlightMenuRoot < flightMenuDropClientRect?.height;
  const isIndentBottomFlightMenuDropTails =
    offsetBottomFlightMenuDropTails < HEIGHT_FLIGHT_MENU_TAILS;

  const isIndentRightFlightMenuRoot =
    offsetRightFlightMenuRoot < WIDTH_FLIGHT_MENU;
  const isIndentRightFlightMenuDrop =
    offsetRightFlightMenuDrop < WIDTH_FLIGHT_MENU;

  let indentBottomFlightMenuDropTails = 0;

  if (bottomFlightMenuDropTails > 0) {
    indentBottomFlightMenuDropTails = -bottomFlightMenuDropTails;
  }

  return {
    isIndentBottomFlightMenuDrop,
    isIndentBottomFlightMenuDropTails,
    isIndentRightFlightMenuRoot,
    isIndentRightFlightMenuDrop,
    indentBottomFlightMenuDropTails,
  };
};

function getBoundingClientRect(element) {
  const rect = element?.getBoundingClientRect();
  return {
    top: rect?.top,
    right: rect?.right,
    bottom: rect?.bottom,
    left: rect?.left,
    width: rect?.width,
    height: rect?.height,
    x: rect?.x,
    y: rect?.y,
  };
}
export class ContextFlightMenu extends PureComponent<
  ContextMenuStateProps & ContextMenuDispatchProps,
  State
> {
  constructor(props) {
    super(props);
    this.state = {
      flightMenuRootClientRect: null,
      flightMenuDropClientRect: null,
      isIndentBottomFlightMenuDrop: false,
      isIndentBottomFlightMenuDropTails: false,
      isIndentRightFlightMenuRoot: false,
      isIndentRightFlightMenuDrop: false,
      indentBottomFlightMenuDropTails: 0,
      isHoverElement: false,
    };
  }
  componentDidMount() {
    const flightMenuRoot = document.getElementsByClassName(
      'flight-menu-root'
    )[0] as HTMLDivElement;
    const flightMenuDrop = document.getElementsByClassName(
      'flight-menu-drop-position'
    )[0] as HTMLDivElement;
    const flightMenuRootClientRect = getBoundingClientRect(flightMenuRoot);
    const flightMenuDropClientRect = getBoundingClientRect(flightMenuDrop);

    const getPosition = getPositionContextFlightMenu(
      flightMenuRootClientRect,
      flightMenuDropClientRect
    );
    this.setState({
      flightMenuRootClientRect: flightMenuRootClientRect,
      flightMenuDropClientRect: flightMenuDropClientRect,
      isIndentBottomFlightMenuDrop: getPosition.isIndentBottomFlightMenuDrop,
      isIndentBottomFlightMenuDropTails:
        getPosition.isIndentBottomFlightMenuDropTails,
      isIndentRightFlightMenuRoot: getPosition.isIndentRightFlightMenuRoot,
      isIndentRightFlightMenuDrop: getPosition.isIndentRightFlightMenuDrop,
      indentBottomFlightMenuDropTails:
        getPosition.indentBottomFlightMenuDropTails,
    });
  }

  componentWillUnmount() {
    this.setState({
      flightMenuRootClientRect: null,
      flightMenuDropClientRect: null,
      isIndentBottomFlightMenuDrop: false,
      isIndentBottomFlightMenuDropTails: false,
      isIndentRightFlightMenuRoot: false,
      isIndentRightFlightMenuDrop: false,
      indentBottomFlightMenuDropTails: 0,
    });
  }

  componentDidUpdate() {
    const flightMenuRoot = document.getElementsByClassName(
      'flight-menu-root'
    )[0] as HTMLDivElement;
    const flightMenuDrop = document.getElementsByClassName(
      'flight-menu-drop-position'
    )[0] as HTMLDivElement;

    const flightMenuRootClientRect = getBoundingClientRect(flightMenuRoot);
    const flightMenuDropClientRect = getBoundingClientRect(flightMenuDrop);

    if (
      !isEqual(this.state.flightMenuRootClientRect, flightMenuRootClientRect) ||
      !isEqual(this.state.flightMenuDropClientRect, flightMenuDropClientRect)
    ) {
      const getPosition = getPositionContextFlightMenu(
        flightMenuRootClientRect,
        flightMenuDropClientRect
      );
      this.setState({
        flightMenuRootClientRect: flightMenuRootClientRect,
        flightMenuDropClientRect: flightMenuDropClientRect,
        isIndentBottomFlightMenuDrop: getPosition.isIndentBottomFlightMenuDrop,
        isIndentBottomFlightMenuDropTails:
          getPosition.isIndentBottomFlightMenuDropTails,
        isIndentRightFlightMenuRoot: getPosition.isIndentRightFlightMenuRoot,
        isIndentRightFlightMenuDrop: getPosition.isIndentRightFlightMenuDrop,
        indentBottomFlightMenuDropTails:
          getPosition.indentBottomFlightMenuDropTails,
      });
    }
  }

  openWindowFlightCenter = (inNewTab?: boolean) => {
    openFlightCenter(this.props.openedFlight.id, inNewTab);
  };
  openOrderWindowApp = () => {
    const { flightOrderId } = this.props.openedFlight;
    openOrderWindow({ id: flightOrderId });
  };
  openOnDemand = () => {
    openOnDemand(this.props.openedFlight.flightOrderMspId);
  };
  openFerryBefore = () => {
    const { flightOrderId, id, aircraftId } = this.props.openedFlight;
    openOrderWindow({
      id: flightOrderId,
      beforeLeg: id,
      aircraftId,
      start: utc().valueOf(),
    });
  };
  openFerryAfter = () => {
    const { flightOrderId, id, aircraftId } = this.props.openedFlight;
    openOrderWindow({
      id: flightOrderId,
      afterLeg: id,
      aircraftId,
      start: utc().valueOf(),
    });
  };
  openEmptyLegWindow = () => {
    const { id, emptyLegOffer } = this.props.openedFlight;
    const offerId = emptyLegOffer?.id?.key || null;
    openWindowAppEmptyLeg2020(offerId ? { id: offerId } : { ferryLegId: id });
  };
  excludeOrIncludeAutoAdvertisement = () => {
    const { id, flightAdvertise } = this.props.openedFlight;
    this.props.excludeIncludeEmptyLeg({
      enable: !flightAdvertise,
      flightLegId: id,
    });
  };

  getFUFIOptions = () => {
    const { openedFlight } = this.props;
    const defaultPayload = {
      className: FUFI_CLASS_NAME.active,
      tooltipTitle: FUFI_TOOLTIP_TITLE[FUFI_CLASS_NAME.active],
    };
    if (!openedFlight) {
      return defaultPayload;
    }
    const { fufi } = openedFlight;
    return {
      className: !fufi ? FUFI_CLASS_NAME.disabled : defaultPayload.className,
      tooltipTitle: !fufi
        ? FUFI_TOOLTIP_TITLE[FUFI_CLASS_NAME.disabled]
        : defaultPayload.tooltipTitle,
    };
  };

  onClickCopyFUFI = () => {
    try {
      const { fufi } = this.props.openedFlight;
      if (fufi) {
        window.navigator.clipboard.writeText(fufi);
        message.success('FUFI copied successfully');
      }
    } catch (e) {
      message.error(ContactSupportTeamMessage('Failed to copy FUFI'));
    }
  };

  onSelectFlightToTail = (id: number) => {
    const { userMoveFlightToTail, openedFlight } = this.props;
    userMoveFlightToTail(id, openedFlight.id);
  };

  onOpenHandover = () => {
    const { openHandoverRemarks, openedFlight } = this.props;
    openHandoverRemarks(openedFlight);
  };

  onMouseEnterElement = () => this.setState({ isHoverElement: true });

  onMouseLeaveElement = () => this.setState({ isHoverElement: false });

  render() {
    const {
      aircraftMap,
      baseCompaniesIds,
      clickDemo,
      clickSensitive,
      clickLock,
      openedFlight,
      openNearestAC,
      openFP,
      openPFA,
      openGlobalHudPFA,
      now,
      hasFPPermission,
      hasPFAPermission,
      hasMarkFlightPermission,
      hasAddFerryPermission,
      hasFCPermission,
      hasOWPermission,
      hasOQPermission,
      hasCheckedEmptyLegOffersPermission,
      hasFlightMovePermission,
      hasHandoverViewPermission,
      acTypesOptions,
      aircraft,
      subcharters,
    } = this.props;
    const fufiOptions = this.getFUFIOptions();
    const isFerryLeg =
      openedFlight.legBusinessTypeId === LegBusinessType.FERRY_OPERATIONAL;
    const offerStatusId =
      openedFlight.emptyLegOffer?.offerStatusId?.key || null;
    const isELOffersForFerryLeg =
      hasCheckedEmptyLegOffersPermission &&
      isFerryLeg &&
      openedFlight.end > utc().valueOf();

    const {
      isIndentRightFlightMenuRoot,
      isIndentBottomFlightMenuDrop,
      isIndentRightFlightMenuDrop,
      isIndentBottomFlightMenuDropTails,
      isHoverElement,
      indentBottomFlightMenuDropTails,
    } = this.state;

    const flightMenuMoveTailList = classNames({
      'flight-menu-drop': true,
      'flight-menu-drop-position': true,
      'flight-menu-drop-position-tails': isIndentRightFlightMenuRoot,
      'flight-menu-drop-bottom-position-tails': isIndentBottomFlightMenuDrop,
    });

    const flightMenuDropMoveTail = classNames({
      'flight-menu-drop-move-tail': true,
      'flight-menu-drop-position-tails': isIndentRightFlightMenuDrop,
      'flight-menu-drop-bottom-position-tails-list': isIndentBottomFlightMenuDropTails,
    });

    return (
      openedFlight && (
        <ul className="flight-menu" {...setDataTestEntityId(`flight-menu`)}>
          {hasOWPermission && (
            <li onClick={this.openOrderWindowApp}>Open Order Window</li>
          )}
          {hasFCPermission && (
            <li
              className="flight-menu-list"
              onMouseEnter={this.onMouseEnterElement}
              onMouseLeave={this.onMouseLeaveElement}
            >
              Open Flight Centre
              <ul className="flight-menu-drop flight-menu-drop-elements">
                <li onClick={() => this.openWindowFlightCenter(true)}>
                  New Tab
                </li>
                <li onClick={() => this.openWindowFlightCenter()}>
                  New Window
                </li>
              </ul>
            </li>
          )}
          {isOwnedByVJ(
            aircraftMap[openedFlight.aircraftId],
            baseCompaniesIds
          ) && (
            <>
              {hasPFAPermission &&
                openedFlight.legOperationalStatusId ===
                  OperationalStatus.UNCONFIRMED && (
                  <>
                    {OLD_PFA_ENABLED ? (
                      <li onClick={openPFA(openedFlight.flightOrderId)}>
                        Open PFA task
                      </li>
                    ) : null}
                    {GLOBAL_HUD_ENABLED ? (
                      <li
                        onClick={openGlobalHudPFA(
                          openedFlight.flightOrderId,
                          aircraftMap[openedFlight.aircraftId]
                            .operatingCompanyId
                        )}
                      >
                        Open Global HUD PFA
                      </li>
                    ) : null}
                  </>
                )}
              {hasFPPermission &&
                openedFlight.bpmItemStatus === BpmItemStatus.IN_PROGRESS && (
                  <li onClick={openFP(openedFlight.id)}>Open FP task</li>
                )}
              {now < openedFlight.arrivalUtcScheduled &&
                openedFlight.legOperationalStatusId !==
                  OperationalStatus.NO_SHOW && (
                  <>
                    {openedFlight.legOperationalStatusId !==
                      OperationalStatus.OPTION && (
                      <>
                        {hasAddFerryPermission && (
                          <li
                            className="flight-menu-list"
                            onMouseEnter={this.onMouseEnterElement}
                            onMouseLeave={this.onMouseLeaveElement}
                          >
                            Add Ferry
                            <ul className="flight-menu-drop flight-menu-drop-elements">
                              <li onClick={this.openFerryBefore}>
                                Ferry Before
                              </li>
                              <li onClick={this.openFerryAfter}>Ferry After</li>
                            </ul>
                          </li>
                        )}
                        {hasFlightMovePermission && (
                          <li onClick={openNearestAC(openedFlight)}>
                            Show Nearest A/C
                          </li>
                        )}
                      </>
                    )}
                    {hasMarkFlightPermission && (
                      <>
                        <li onClick={clickDemo(openedFlight)}>
                          Toggle Demo Flight
                        </li>
                        <li onClick={clickLock(openedFlight)}>
                          {openedFlight.lockedFlight
                            ? 'Unlock Flight'
                            : 'Lock Flight'}
                        </li>
                        <li onClick={clickSensitive(openedFlight)}>
                          {openedFlight.sensitive
                            ? 'Insensitive Flight'
                            : 'Sensitive Flight'}
                        </li>
                      </>
                    )}
                  </>
                )}
              {hasOQPermission && isFlightOnDemandLike(openedFlight) && (
                <li onClick={this.openOnDemand}>View Quote</li>
              )}
              {hasFlightMovePermission &&
                isDraggableByOpStatus(openedFlight?.legOperationalStatusId) && (
                  <li className="flight-menu-list">
                    Move to tail
                    <ul
                      className={flightMenuMoveTailList}
                      style={
                        isHoverElement
                          ? {}
                          : {
                              top: `${indentBottomFlightMenuDropTails}px`,
                            }
                      }
                    >
                      {acTypesOptions.map(el => {
                        return (
                          <li
                            key={`${el.typeId}_${el.name}_${el.rank}`}
                            className="flight-menu-list-move-tail"
                          >
                            {el.name}
                            <ul className={flightMenuDropMoveTail}>
                              {aircraft
                                .filter(ac => ac.aircraftTypeId === el.typeId)
                                .map((j, i) => {
                                  return (
                                    <li
                                      key={`${el.typeId}_${j.id}_${i}`}
                                      onClick={() =>
                                        this.onSelectFlightToTail(j.id)
                                      }
                                    >
                                      {j.tailNumber}
                                    </li>
                                  );
                                })}
                            </ul>
                          </li>
                        );
                      })}
                      <li className="flight-menu-list-move-tail">
                        Subcharters
                        <ul className={flightMenuDropMoveTail}>
                          {subcharters.map(j => {
                            return (
                              <li
                                key={j.id}
                                onClick={() => this.onSelectFlightToTail(j.id)}
                              >
                                {j.tailNumber}
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    </ul>
                  </li>
                )}
              {isELOffersForFerryLeg && (
                <li onClick={this.openEmptyLegWindow}>
                  {!offerStatusId || offerStatusId === OfferStatus.TRANSIENT
                    ? 'Advertise as Empty Leg'
                    : 'Modify Empty Leg'}
                </li>
              )}
              {isFerryLeg && (
                <>
                  <li onClick={this.excludeOrIncludeAutoAdvertisement}>
                    {openedFlight.flightAdvertise
                      ? 'Exclude from Auto Advertisement'
                      : 'Include in Auto Advertisement'}
                  </li>
                </>
              )}
              <Tooltip title={fufiOptions.tooltipTitle} placement="right">
                <li
                  className={fufiOptions.className}
                  onClick={this.onClickCopyFUFI}
                >
                  Copy FUFI
                </li>
              </Tooltip>
              {hasHandoverViewPermission && (
                <li onClick={this.onOpenHandover}>Handover</li>
              )}
            </>
          )}
        </ul>
      )
    );
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ContextMenuDispatchProps => ({
  openPFA: (orderId: number) => (e: React.MouseEvent<HTMLElement>) => {
    dispatch(actions.userOpenPFA.started(orderId));
  },
  openGlobalHudPFA: (orderId: number, operatingCompanyId: number) => (
    e: React.MouseEvent<HTMLElement>
  ) => {
    dispatch(
      actions.userOpenGlobalHudPFA.started({
        operatingCompanyId,
        orderId,
      })
    );
  },
  openFP: (legId: number) => (e: React.MouseEvent<HTMLElement>) => {
    dispatch(actions.userOpenFP.started(legId));
  },
  openNearestAC: (f: Flight) => (e: React.MouseEvent<HTMLElement>) => {
    dispatch(actions.userOpenNearestAircraftModal(f));
  },
  clickLineCheck: (flight: Flight) => (e: React.MouseEvent<HTMLElement>) => {
    dispatch(
      actions.userMarkFlight.started({
        flight: flight,
        newDemo: null,
        newLocked: null,
        newSensitive: null,
        newLineCheck: !flight.lineCheck,
      })
    );
  },
  clickDemo: (flight: Flight) => (e: React.MouseEvent<HTMLElement>) => {
    dispatch(
      actions.userMarkFlight.started({
        flight: flight,
        newDemo: !flight.demoFlight,
        newLocked: null,
        newSensitive: null,
        newLineCheck: null,
      })
    );
  },
  clickLock: (flight: Flight) => (e: React.MouseEvent<HTMLElement>) => {
    dispatch(
      actions.userMarkFlight.started({
        flight: flight,
        newDemo: null,
        newLocked: !flight.lockedFlight,
        newSensitive: null,
        newLineCheck: null,
      })
    );
  },
  clickSensitive: (flight: Flight) => (e: React.MouseEvent<HTMLElement>) => {
    dispatch(
      actions.userMarkFlight.started({
        flight: flight,
        newDemo: null,
        newLocked: null,
        newSensitive: !flight.sensitive,
        newLineCheck: null,
      })
    );
  },
  closeMenu: () => {
    dispatch(actions.userCloseContextMenu());
  },
  userMoveFlightToTail: (id: number, flightId: number) => {
    dispatch(
      actions.userMoveFlightToTail({
        newAircraftId: id,
        selectedFlightId: flightId,
      })
    );
  },
  excludeIncludeEmptyLeg: (payload: AutoAdvertisementInput) => {
    dispatch(actions.userToggleExcludeIncludeEmptyLeg(payload));
  },
  openHandoverRemarks: (flight: Flight) => {
    dispatch(actions.userOpenHandoverRemarksDrawer({ isFor: flight }));
  },
});

const mapStateToProps = (
  state: RootState
): Omit<ContextMenuStateProps, 'openedFlight'> => ({
  now: state.time.now,
  baseCompaniesIds: getBaseCompaniesIds(state),
  aircraftMap: getAircraftByIdMapWithHold(state),
  hasFPPermission: hasPermission(state, 'AG-Timeline-FP-View'),
  hasPFAPermission: hasPermission(state, 'AG-Timeline-PFA-View'),
  hasMarkFlightPermission: hasPermission(state, 'AG-Timeline-Mark-Flight'),
  hasAddFerryPermission: hasPermission(state, 'AG-Timeline-Add-Ferry-View'),
  hasFCPermission: hasPermission(state, 'AG-Timeline-Flight-Center-View'),
  hasOWPermission: hasPermission(state, 'AG-Timeline-Order-Window-View'),
  hasOQPermission: hasPermission(state, 'AG-Timeline-Open-Quote'),
  hasCheckedEmptyLegOffersPermission: hasPermission(
    state,
    'AG-Timeline-View-EL-Offer'
  ),
  hasFlightMovePermission: hasPermission(state, 'AG-Timeline-Fight-Move'),
  hasHandoverViewPermission: hasPermission(state, 'AG-Timeline-Handover-View'),
  acTypesOptions: getAllAcTypeOptions(state),
  aircraft: getAvailableAircraftForMoveToTailMenu(state),
  subcharters: getSubChartersAircraft(state),
});

export const ContextFlightMenuConnected = connect(
  (state: RootState): ContextMenuStateProps => ({
    ...mapStateToProps(state),
    openedFlight: state.flightDetails.flightMenuOpenedFor,
  }),
  mapDispatchToProps
)(ContextFlightMenu);

export const ReactContextFlightMenuConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContextFlightMenu);

class FlightContextMenuComponent extends PureComponent<{
  isOpened: boolean;
  closeContextMenu: () => void;
}> {
  render() {
    const { isOpened, closeContextMenu } = this.props;
    return (
      <ContextMenuWrapperAsPopper
        isOpen={isOpened}
        menuType={'flight'}
        onCloseContextMenu={closeContextMenu}
        openMenuAction={actions.userOpenFlightMenu}
      >
        <ContextFlightMenuConnected />
      </ContextMenuWrapperAsPopper>
    );
  }
}
export const FlightContextMenuConnected = connect(
  (state: RootState) => ({
    isOpened: !!state.flightDetails.flightMenuOpenedFor,
  }),
  dispatch => ({
    closeContextMenu: () => {
      dispatch(actions.userCloseContextMenu());
    },
  })
)(FlightContextMenuComponent);
