import { transformIncomeMessage } from '../../../services/transform-from-gv';
import Note from '../../../types/note';
import { WsIncome, WsUpdateBatchData } from '../../../types/ws-income';
import * as debug from '../../../utils/debug';
import { isNoteGeneral } from '../../../utils/note';
import { isUpdateWsIncome } from '../../../utils/ws-income';

export class WsUpdateBatch {
  data: WsUpdateBatchData;
  counter: number;
  creationTime: number;
  constructor() {
    this.data = {};
    this.counter = 0;
    this.creationTime = new Date().valueOf();
  }
  release() {
    debug.addToCounter(
      this.creationTime,
      this.counter,
      new Date().valueOf() - this.creationTime
    );
    console.debug(
      '%s%c%i%c%s%c%i%s',
      'WS PUSH: Last batch has ',
      'font-weight: bold',
      this.counter,
      'font-weight: normal',
      ' messages in time ',
      'font-weight: bold',
      new Date().valueOf() - this.creationTime,
      'ms'
    );
  }
  insertData(type: keyof WsUpdateBatch['data'], id: number, insertionData) {
    if (this.data[type]) {
      this.data[type][id] = insertionData;
    } else {
      this.data[type] = { [id]: insertionData };
    }
  }
  addToQueue(income: WsIncome) {
    if (income.type) this.counter += 1;
    const id = income.id.key;

    if (isUpdateWsIncome(income)) {
      const transformedData = transformIncomeMessage(income.type, income.data);
      if (income.type === 'notes') {
        if (isNoteGeneral(transformedData as Note)) {
          this.insertData('generalNotes', id, transformedData);
        } else {
          this.insertData('availabilityNotes', id, transformedData);
        }
      } else {
        this.insertData(income.type, id, transformedData);
      }
    } else {
      if (income.type === 'notes') {
        this.insertData('generalNotes', id, null);
        this.insertData('availabilityNotes', id, null);
      } else {
        this.insertData(income.type, id, null);
      }
    }
  }
}
