class ResponseSizeLimitError extends Error {
  static counter: number;
  constructor(message, autoCount = false) {
    super(message);
    this.name = 'ResponseSizeLimitError';
    if (autoCount) {
      ResponseSizeLimitError.incrementCounter();
    }
    Object.setPrototypeOf(this, ResponseSizeLimitError.prototype);
  }
  static getCounter() {
    return ResponseSizeLimitError.counter;
  }
  static setCounter(val = 1, defaultVal = ResponseSizeLimitError.counter || 0) {
    ResponseSizeLimitError.counter = defaultVal + val;
  }
  static incrementCounter() {
    ResponseSizeLimitError.setCounter();
  }
  static hasThrown() {
    return ResponseSizeLimitError.counter > 1;
  }
}

export default ResponseSizeLimitError;
