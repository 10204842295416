import axios, {
  AxiosError,
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { getTokenFromLocalStorage } from '../utils/tokenLocalStorage';
import { funcRefreshToken } from '../initialize';
import { capitalize } from '../utils';
import { handleAxiosError } from '../components/error-handling/utils';

const RETRY_MAX_COUNT = 4;

class AxiosWrapper {
  private readonly axiosAuthed: AxiosInstance;

  constructor() {
    this.axiosAuthed = axios.create({
      baseURL: 'https://timeline.dev.vistajet.io'
    });
    this.axiosAuthed.interceptors.request.use(function(config) {
      const token = getTokenFromLocalStorage();
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: token ? `Bearer ${token}` : '',
        },
      };
    });
  }

  private handleError(
    error: AxiosError,
    method: 'get' | 'post' | 'delete',
    url?: string,
    message?: string,
    variables?: string
  ): never {
    handleAxiosError(error, method, url, message, variables);
    throw error;
  }

  public get<T = any>(
    url: string,
    config?: AxiosRequestConfig
  ): AxiosPromise<T> {
    const [baseUrl] = config ? config?.url?.split('?') : url.split('?');
    return funcRefreshToken().then(() => {
      return this.axiosAuthed.get<T>(url, config).then(
        (d: AxiosResponse) => {
          return d;
        },
        (
          error: AxiosError & {
            config: {
              _retry_counter: number;
            };
          }
        ) => {
          const originalRequest = error.config;
          if (
            error.response?.status === 403 &&
            (!originalRequest._retry_counter ||
              originalRequest._retry_counter < RETRY_MAX_COUNT)
          ) {
            originalRequest._retry_counter = originalRequest._retry_counter
              ? originalRequest._retry_counter + 1
              : 1;
            return this.get(originalRequest.url, originalRequest);
          } else {
            const message = `Failed to load ${capitalize(
              baseUrl
                .replace('integration/timeline/', '')
                .replace(/[\/-]/g, ' ')
            )}. Error: ${error?.response?.statusText || error.message}`;
            this.handleError(
              error.response?.data?.error || error,
              'get',
              baseUrl,
              baseUrl.includes('handover') ? '' : message
            );
          }
        }
      );
    });
  }

  public post<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): AxiosPromise<T> {
    const variables = JSON.stringify(data);
    return funcRefreshToken().then(() => {
      return this.axiosAuthed.post<T>(url, data, config).then(
        (d: AxiosResponse) => {
          return d;
        },
        (e: AxiosError) => {
          const firstMatch = url.search(/(\w|\d)+\//gi);
          const method = url.substring(firstMatch);
          const message = `Failed to ${capitalize(method)}. Error: ${e?.response
            ?.statusText || e.message}`;
          this.handleError(
            e.response?.data?.error || e,
            'post',
            url,
            url.includes('handover') ? '' : message,
            variables
          );
        }
      );
    });
  }

  public delete<T>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> {
    return funcRefreshToken().then(() => {
      return this.axiosAuthed.delete(url, config).then(
        (d: AxiosResponse) => {
          return d;
        },
        (e: AxiosError) => {
          const what = url.match('note')?.length > 0 ? 'Note' : 'Event';
          const message = `Failed to delete ${what}`;
          this.handleError(
            e.response?.data?.error || e,
            'delete',
            url,
            url.includes('handover') ? '' : message
          );
        }
      );
    });
  }
}

export const axiosAuthed = new AxiosWrapper();
