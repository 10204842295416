import { FC, PureComponent } from 'react';
import * as actions from '../../actions';
import * as d3Selection from 'd3-selection';
import * as d3Scale from 'd3-scale';
import * as d3Time from 'd3-time';
import './styles.scss';
import { subMiddle, store } from '../../root';
import { RootState } from '../../reducers';
import { connect } from 'react-redux';

const interval = d3Time.utcDay;

export class DayDelimiterAxis extends PureComponent<{}> {
  rootRef: HTMLDivElement;
  rootNode: d3Selection.Selection<HTMLDivElement, {}, null, undefined>;
  getRef = (ref: HTMLDivElement) => {
    this.rootRef = ref;
    this.rootNode = d3Selection.select(this.rootRef);
    const scale = store.getState().ui.transform.scaleX;
    this.update(scale);
    subMiddle.on(actions.userZoomHor, this.d3Updater);
    subMiddle.on(actions.timelineResized, this.d3Updater);
  };
  d3Updater = (_action, state: RootState) => {
    this.update(state.ui.transform.scaleX);
  };
  update = (newScaleX: d3Scale.ScaleTime<number, number>) => {
    const height = store.getState().ui.height;

    const ticks = newScaleX.ticks(interval);
    const datedTicks = this.rootNode
      .selectAll('.day-tick')
      .data(ticks, d => d.valueOf().toString());
    datedTicks.exit().remove();
    const enteredGroups = this.renderEntered(datedTicks.enter());
    datedTicks
      .merge(enteredGroups)
      .style('left', d => `${newScaleX(d)}px`)
      .style('height', `${height}px`);
  };
  renderEntered = (
    entered: d3Selection.Selection<any, Date, any, any>
  ): d3Selection.Selection<any, Date, any, any> => {
    return entered.append('div').classed('day-tick', true);
  };
  componentWillUnmount() {
    subMiddle.off(actions.userZoomHor, this.d3Updater);
    subMiddle.off(actions.timelineResized, this.d3Updater);
  }
  render() {
    return (
      <div
        className="day-delimeter-top-axis"
        style={{
          position: 'absolute',
          overflow: 'visible',
          top: 0,
          left: 0,
          width: 1,
          height: 1,
        }}
        ref={this.getRef}
      />
    );
  }
}
const DisabledAxis: FC<{ visible: boolean }> = ({ visible }) => {
  return visible && <DayDelimiterAxis />;
};
export const ConnectedDaySeparator = connect((state: RootState) => ({
  visible: state.ui.isDaySeparatorOnTop,
}))(DisabledAxis);
