import { PureComponent } from 'react';
import * as d3S from 'd3-selection';
import { AxisCombined } from '../../d3/components/axis';
import Transform from '../../types/transform';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';

interface Props {
  transform: Transform;
  marginTop: number;
  height: number;
}
export class AxisCombinedComponent extends PureComponent<Props> {
  axis: AxisCombined;
  groupRef: SVGGElement;
  getGroupRef = ref => {
    this.groupRef = ref;
  };
  componentWillUnmount() {
    if (this.axis) {
      this.axis.clear();
      delete this.axis;
    }
  }
  componentDidMount() {
    this.axis = new AxisCombined(
      {
        verticesHeight: this.props.height,
        verticesTopOffset: this.props.marginTop,
      },
      this.props.transform.scaleX
    );
    this.axis.render(d3S.select(this.groupRef));
  }

  componentDidUpdate(prevProps) {
    if (!this.axis) {
      this.axis = new AxisCombined(
        {
          verticesHeight: this.props.height,
          verticesTopOffset: this.props.marginTop,
        },
        this.props.transform.scaleX
      );
      this.axis.render(d3S.select(this.groupRef));
      this.axis.reZoom(this.props.transform);
      return;
    }
    if (
      prevProps.marginTop !== this.props.marginTop ||
      prevProps.height !== this.props.height
    ) {
      this.axis.clear();
      this.axis = new AxisCombined(
        {
          verticesHeight: this.props.height,
          verticesTopOffset: this.props.marginTop,
        },
        this.props.transform.scaleX
      );
      this.axis.render(d3S.select(this.groupRef));
      return;
    }
    this.axis.reZoom(this.props.transform);
  }
  render() {
    return <g className="axis" ref={this.getGroupRef} />;
  }
}

export const AxisConnected = connect((state: RootState) => ({
  transform: state.ui.transform,
  marginTop: state.ui.marginTop,
  height: state.ui.height,
}))(AxisCombinedComponent);
