const StickyNoteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 500 511.78"
  >
    <path
      d="M497.71 66 433.95 2.23c-5.43-5.43-14.03.18-16.61 4.95-.43.82.34 1.55-.36 7.07-.87 6.84-2.42 14.44-4.92 20.46l-28.35 28.36c-11.32 11.32-27.51 6.29-41.32-.58-2.91-1.44-6.66-4.64-9.88-1.41L320.5 73.09c-1.76 1.74-1.76 4.61 0 6.37l100.77 100.78c1.76 1.76 4.63 1.76 6.38 0l12.01-12.01c3.19-3.19-.88-7.33-2.56-10.79-6.14-12.72-11.64-29.66-1.41-39.9l29.61-29.6c5.82-1.82 13.34-3.53 20.51-4.66 5.58-.87 6.27-.19 7.07-.71 4.88-3.15 10.29-11.11 4.83-16.57zM129.54 98.24l-83.31 88.01h83.31V98.24zM0 202.93c0-1.14.14-2.25.4-3.32.12-3.23 1.36-6.44 3.76-8.98L130.89 56.75c2.36-4.27 6.9-7.16 12.12-7.16h156.91l-1.47 1.48a35.576 35.576 0 0 0-10.44 25.2l.02 1.02h-130.8v122.8c0 7.65-6.2 13.85-13.85 13.85H27.7v270.14h399.45V212.62c8.21-.61 16.25-4.07 22.53-10.35l5.16-5.16v287.13c0 7.59-3.09 14.48-8.07 19.46-5.03 4.97-11.92 8.08-19.46 8.08H27.53c-7.56 0-14.45-3.11-19.44-8.1C3.11 498.7 0 491.8 0 484.24V202.93zm331.02 80.9c7.69 0 13.92 6.17 13.92 13.78 0 7.61-6.23 13.78-13.92 13.78h-207.2c-7.68 0-13.92-6.17-13.92-13.78 0-7.61 6.24-13.78 13.92-13.78h207.2zm0 92.95c7.69 0 13.92 6.18 13.92 13.78 0 7.61-6.23 13.78-13.92 13.78h-207.2c-7.68 0-13.92-6.17-13.92-13.78 0-7.6 6.24-13.78 13.92-13.78h207.2zm39.09-223.86-22.95-22.95-73.66 93.14 3.46 3.47 93.15-73.66z"
      fill="white"
    />
  </svg>
);

export default StickyNoteIcon;
