import { store as reduxStore } from '../../../../../root';
import * as d3S from 'd3-selection';
import {
  getElementOffsetWithKoef,
  getLaneHeightKoef,
} from '../../../../../reducers/ui';
import { getICAOLabelFromAirport } from '../../../../../utils/airport';
import {
  FboMismatch,
  isGroundTimeFbo,
} from '../../../../../types/fbo-mismatch';
import { utc } from 'moment';

export const getFboMismatchesD3creator = (
  d: d3S.Selection<any, any, any, any>
): d3S.Selection<any, any, any, any> => {
  const state = reduxStore.getState();
  const { transform, segmentsVisibility, positionMap } = state.ui;
  const { airportsById } = state.airports;
  const { togglersState } = state.aircraft;

  const container = d
    .append('div')
    .classed('label-text', true)
    .style(
      'transform',
      d =>
        `translate(${d.x}px, ${d.y -
          4 +
          d.ky *
            getElementOffsetWithKoef(
              segmentsVisibility,
              'fboMismatches',
              togglersState[d.aircraftId],
              positionMap
            )}px)`
    )
    .classed('fbo-mm-text-label', true)
    .style('padding-left', d => `${-d.x > d.width ? 0 : Math.max(-d.x, 0)}px`)
    .style(
      'padding-right',
      d => `${Math.max(d.x + d.width - transform.scaleX.range()[1], 0)}px`
    )
    .style(
      'height',
      d =>
        `${(positionMap.fboMismatches * d.ky) /
          getLaneHeightKoef(
            segmentsVisibility,
            togglersState[d.aircraftId],
            positionMap
          )}px`
    )
    .style('width', d => `${d.width}px`)
    .style('color', (d: FboMismatch) =>
      isGroundTimeFbo(d) ? '#028001' : '#ff0000'
    )
    .style(
      'border-bottom',
      (d: FboMismatch) =>
        `1px solid ${isGroundTimeFbo(d) ? '#028001' : '#ff0000'}`
    )
    .text((d: FboMismatch) => {
      const mins = utc(d.end).diff(d.start, 'm');
      const hrs = Math.floor(mins / 60);
      const justMins = mins % 60;
      let time = hrs ? `${hrs}h ` : '';
      time += justMins ? `${justMins}m ` : '';
      let icaoStr = '';
      if (isGroundTimeFbo(d)) {
        icaoStr = getICAOLabelFromAirport(airportsById[d.baseAirportId]);
      } else {
        let departureIcao = getICAOLabelFromAirport(
          airportsById[d.departureAirportId]
        );
        let arrivalIcao = getICAOLabelFromAirport(
          airportsById[d.arrivalAirportId]
        );
        icaoStr = `- [${departureIcao}-${arrivalIcao}]`;
      }
      return `${time}${icaoStr}`;
    });
  container
    .append('span')
    .style('color', '#ff0000')
    .style('margin-left', (d: FboMismatch) =>
      isGroundTimeFbo(d) && d.hasFBOMismatch ? '4px' : '0'
    )
    .text((d: FboMismatch) =>
      isGroundTimeFbo(d) && d.hasFBOMismatch ? 'FBO' : ''
    );
  return container;
};
