import { Space } from 'antd';
import DatePicker from '../../../common/components/date-time-pickers/date-picker';
import TimePicker from '../../../common/components/date-time-pickers/time-picker';
import { FieldProps, FormikProps } from 'formik';
import { FC, useRef } from 'react';
import { MxEventFormValues } from '../interfaces';

import { Moment, utc } from 'moment';
import { MxEventFormName } from '../constants';
import { NoteFormValues } from '../interfaces';

import { NoteFormName } from '../constants';
import { useDispatch } from 'react-redux';
import { userChangeDatesInEditDrawer } from '../../../actions';

const DatePickerGroup: FC<{
  value: number;
  onChange: (date: Moment) => void;
  disabled: boolean;
}> = ({ value, onChange, disabled }) => {
  const refDate = useRef(null);
  const refTime = useRef(null);
  const v = value ? utc(value) : null;
  return (
    <Space.Compact className="edit-event-form-body-picker">
      <DatePicker
        format="DD-MM-YYYY"
        placeholder="DD-MM-YYYY"
        defaultValue={v}
        onChange={onChange}
        allowClear={false}
        className="edit-event-form-body-picker-date"
        disabled={disabled}
        ref={refDate}
      />
      <TimePicker
        format="HH:mm"
        placeholder="HH:mm"
        defaultValue={v}
        onChange={onChange}
        className="edit-event-form-body-picker-time"
        disabled={disabled}
        ref={refTime}
        changeOnBlur
      />
    </Space.Compact>
  );
};

export const DateTimePickerComponent: FC<Pick<
  FormikProps<MxEventFormValues | NoteFormValues>,
  'setFieldValue'
> &
  FieldProps<
    | MxEventFormValues['startTimeMs' | 'endTimeMs']
    | NoteFormValues['fromDate' | 'toDate']
  >['field'] & {
    disabled: boolean;
    isRtsSelected?: boolean;
  }> = props => {
  const dispatch = useDispatch();
  const { name, value, isRtsSelected, disabled, setFieldValue } = props;
  const onHandleChange = (date: Moment) => {
    const time = date ? utc(date).valueOf() : null;
    setFieldValue(name, time);
    const type =
      name === NoteFormName.fromDate || name === MxEventFormName.startTimeMs
        ? 'start'
        : 'end';
    dispatch(
      userChangeDatesInEditDrawer({
        type,
        time,
      })
    );
  };
  return (
    <DatePickerGroup
      disabled={disabled || isRtsSelected}
      onChange={onHandleChange}
      value={value}
    />
  );
};
