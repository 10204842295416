import { getMaintenancesD3creator } from './d3-creators/maintenances';
import { getEmptyLegOffersD3creator } from './d3-creators/empty-leg-offers';
import { getOneWayOffersD3creator } from './d3-creators/one-way-offers';
import { getAirportMismatchesD3creator } from './d3-creators/airport-mismatches';
import { getGroundTimeD3creator } from './d3-creators/ground-time';
import { getCrewRosterD3creator } from './d3-creators/crew-roster';
import { getCrewAssignmentD3creator } from './d3-creators/crew-assignment';
import { getFlightsD3creator } from './d3-creators/flights';
import { getFboMismatchesD3creator } from './d3-creators/fbo-mismatches';
import { ElementName, ComponentD3creator } from './group-of-text-labels';

export const elementTypes: {
  elementName: ElementName;
  componentD3creator: ComponentD3creator;
  hasRootId?: boolean;
}[] = [
  {
    elementName: 'maintenances',
    hasRootId: true,
    componentD3creator: getMaintenancesD3creator,
  },
  {
    elementName: 'emptyLegOffers',
    hasRootId: true,
    componentD3creator: getEmptyLegOffersD3creator,
  },
  {
    elementName: 'oneWayOffers',
    hasRootId: true,
    componentD3creator: getOneWayOffersD3creator,
  },
  {
    elementName: 'airportMismatches',
    hasRootId: true,
    componentD3creator: getAirportMismatchesD3creator,
  },
  {
    elementName: 'groundTime',
    hasRootId: true,
    componentD3creator: getGroundTimeD3creator,
  },
  {
    elementName: 'fboMismatches',
    hasRootId: true,
    componentD3creator: getFboMismatchesD3creator,
  },
  {
    elementName: 'flights',
    hasRootId: true,
    componentD3creator: getFlightsD3creator,
  },
];

export const crewElements: {
  elementName: ElementName;
  componentD3creator: ComponentD3creator;
  hasRootId?: boolean;
}[] = [
  {
    elementName: 'crewRoster',
    hasRootId: true,
    componentD3creator: getCrewRosterD3creator,
  },
  {
    elementName: 'crewAssignment',
    hasRootId: true,
    componentD3creator: getCrewAssignmentD3creator,
  },
];
