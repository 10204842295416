import { PureComponent } from 'react';
import { Popover, Button } from 'antd';
import HoldAircraftOptionsConnected from './hold-line-content';

export default class FilterHoldLine extends PureComponent {
  render() {
    return (
      <Popover content={<HoldAircraftOptionsConnected />} placement="bottom">
        <Button type="dashed">HOLD AC TYPE</Button>
      </Popover>
    );
  }
}
