import { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { isOwnedByVJ } from '../../../common/aircraft/aircraft-check-status';
import { RootState } from '../../../reducers';
import { getTimezoneOffsetForAirportByIcao } from '../../../selectors/timezones';
import Aircraft from '../../../types/aircraft';
import { setDataTestEntityId } from '../../../utils';
import { getAircraftLocationStatus } from '../../../utils/aircraft';
import { HoveredTailPart } from '../tooltip';

interface StateProps {
  timezoneOffset: number;
}

interface AircraftLocationProps {
  aircraft: Aircraft;
  baseCompaniesIds: number[];
  hasTailHandover: boolean;
}
interface DispatchProps {
  onHover: (aircraftId: number, part: HoveredTailPart, ref) => void;
  onUnhover: () => void;
}

class AircraftLocation extends PureComponent<
  AircraftLocationProps & StateProps & DispatchProps
> {
  ref: HTMLDivElement;
  getRef = ref => (this.ref = ref);
  onHover = () => {
    this.props.onHover(this.props.aircraft.id, 'local-time', this.ref);
  };
  onUnhover = () => {
    this.props.onUnhover();
  };
  render() {
    const {
      aircraft,
      baseCompaniesIds,
      hasTailHandover,
      timezoneOffset,
    } = this.props;
    let style = {};
    if (hasTailHandover) {
      style = {
        minWidth: '38px',
        maxWidth: '38px',
        textAlign: 'right',
      };
    }
    if (!isOwnedByVJ(aircraft, baseCompaniesIds)) {
      return hasTailHandover ? (
        <div
          className="aircraft-content-secondary-info-location"
          style={style}
        />
      ) : null;
    }
    return timezoneOffset !== null ? (
      <div
        className="aircraft-content-secondary-info-location"
        onMouseEnter={this.onHover}
        onMouseLeave={this.onUnhover}
        ref={this.getRef}
        style={style}
        {...setDataTestEntityId('aircraft-content-secondary-info-location')}
      >
        {getAircraftLocationStatus(aircraft)}
      </div>
    ) : (
      <div
        className="aircraft-content-secondary-info-location"
        style={style}
        {...setDataTestEntityId('aircraft-content-secondary-info-location')}
      >
        {getAircraftLocationStatus(aircraft)}
      </div>
    );
  }
}
const mapStateToProps = (
  state: RootState,
  props: AircraftLocationProps
): StateProps => ({
  timezoneOffset:
    props.aircraft.location &&
    getTimezoneOffsetForAirportByIcao(state, props.aircraft.location),
});
const mapDispatchToProps = dispatch => ({
  onHover: (aircraftId, part, ref) => {
    dispatch(
      actions.userHoverTailBlock({
        aircraftId,
        part,
        ref,
      })
    );
  },
  onUnhover: () => dispatch(actions.userUnhoverTailTooltip()),
});
export const AircraftLocationConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(AircraftLocation);
