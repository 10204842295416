import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../reducers';
import { ReducerShape } from '../../../reducers/ui';
import { getOperatingCompaniesDividersData } from '../../../selectors';
import { OperatingCompanyLabelData } from '../../../types/aircraft';

interface StateProps {
  companyGroups: OperatingCompanyLabelData[];
  marginTop: number;
  holdLineHeight: number;
  transform: ReducerShape['transform'];
  showLabels: boolean;
  width: number;
}
class OperatingCompanyDividers extends PureComponent<StateProps> {
  render() {
    const {
      companyGroups,
      showLabels,
      marginTop,
      holdLineHeight,
      transform: { ky, translateY },
      width,
    } = this.props;
    if (!showLabels) {
      return null;
    }
    return (
      <g clipPath="url(#eventsSVGClip)">
        <g
          className="aircraft-operator-delimiter"
          transform={`translate(0,${translateY +
            marginTop +
            holdLineHeight})scale(1, ${ky})`}
        >
          {companyGroups.map(company => {
            return (
              <rect
                key={`${company.companyName}_${company.y}`}
                x={0}
                y={company.y}
                width={width}
                height={1.2}
                fill="rgba(0,0,0,0.5)"
              />
            );
          })}
        </g>
      </g>
    );
  }
}

export const OperatingCompanyDividersConnected = connect(
  (state: RootState): StateProps => ({
    companyGroups: getOperatingCompaniesDividersData(state),
    holdLineHeight: state.ui.holdLineHeight,
    marginTop: state.ui.marginTop,
    transform: state.ui.transform,
    showLabels: state.aircraft.showLabels,
    width: state.ui.width,
  })
)(OperatingCompanyDividers);
