import { PureComponent } from 'react';

interface Props {
  isDarkened: boolean;
}

export default class DarkenedBox extends PureComponent<Props> {
  render() {
    const { isDarkened, children } = this.props;
    return (
      <div
        style={{
          backgroundColor: isDarkened ? 'rgba(0, 0, 0, 0.3)' : 'transparent',
        }}
      >
        {children}
      </div>
    );
  }
}
