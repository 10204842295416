import Note from '../../types/note';
interface NoteGVModel {
  inputSource: {
    inputSourceId: {
      key: number;
    };
    name: string;
  };
  person: {
    personId: {
      key: number;
    };
    firstName: string;
    lastName: string;
    active: boolean;
  };
  aircraftScheduleNote: {
    id: {
      key: number;
    };
    inputSourceId: {
      key: number;
    };
    personId: {
      key: number;
    };
    isActive: boolean;
    aircraftId: {
      key: number;
    };
    fromDate: number;
    toDate: number;
    lastUpdated: number;
    importanceFactor: number;
    crewNotAvailable: boolean;
    message: string;
  };
}

export const transformToFE = (n: NoteGVModel): Note => ({
  id: n.aircraftScheduleNote.id.key,
  start: n.aircraftScheduleNote.fromDate,
  end: n.aircraftScheduleNote.toDate,
  aircraftId: n.aircraftScheduleNote.aircraftId.key,
  person: {
    id: n.person.personId.key,
    firstName: n.person.firstName,
    lastName: n.person.lastName,
  },
  message: n.aircraftScheduleNote.message,
  inputSourceName: n.inputSource.name,
  lastUpdated: n.aircraftScheduleNote.lastUpdated,
  crewNotAvailable: n.aircraftScheduleNote.crewNotAvailable,
  importanceFactor: n.aircraftScheduleNote.importanceFactor,
  isActive: n.aircraftScheduleNote.isActive,
  inputSourceId: n.inputSource.inputSourceId.key,
});
