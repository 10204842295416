import { gql } from '@apollo/client';
import { apolloClient } from '../../data-processing/graphql';
import Flight, { UpgradeReason } from '../../types/flight';
import { ChangeTailForMultipleFlightsMutationVariables } from '../../types/operation-result-types';

const changeTailForMultipleFlights = require('./change-tail-for-multiple-flights.gql');

export function changeTailForMultipleFlightsMutation(
  flights: Flight[],
  newAircraftId: number,
  upgradeReason?: { [id: number]: UpgradeReason }
) {
  const variables: ChangeTailForMultipleFlightsMutationVariables = {
    flights: flights.map(f => ({
      id: f.id,
      arrivalUtcEstimated: f.arrivalUtcEstimated,
      arrivalUtcScheduled: f.arrivalUtcScheduled,
      departureUtcEstimated: f.departureUtcEstimated,
      departureUtcScheduled: f.departureUtcScheduled,
      flightOrderId: f.flightOrderId,
      upgradeReason: upgradeReason[f.id] || -1,
      newAircraftId,
    })),
  };
  return apolloClient.mutate({
    mutation: gql(changeTailForMultipleFlights),
    variables,
  });
}
