import { LabeledValue } from 'antd/es/select';
import Aircraft from '../../types/aircraft';
import Airport from '../../types/airport';
import { MailTemplate } from '../../types/email';
import { HandoverEmail } from '../../types/handover-remarks';
import {
  EmailAddressVoInput,
  EMailVoInput,
} from '../../types/operation-result-types';
import { EmailFormValues, MxEventFormValuesForEmail } from './interfaces';

export const REGEXP_EMAIL_INSIDE_A_STRING = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/gi;
export const REGEXP_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi;

export const getNameFromEmailOption = (value: string): string => {
  const indexOfEmail = value.search(REGEXP_EMAIL_INSIDE_A_STRING);
  const name = value?.substring(0, indexOfEmail - 1)?.trim();

  return name || value.split('@')[0];
};

export const isValidEmail = (email: string) =>
  REGEXP_EMAIL.test(email.toLowerCase());

export const getEmailValueFromEmailOption = (value: string): string => {
  if (isValidEmail(value)) return value;
  const indexOfEmail = value.search(REGEXP_EMAIL_INSIDE_A_STRING);
  return value.substring(indexOfEmail);
};

export const transformAddresses = (
  addresses: LabeledValue[]
): EmailAddressVoInput[] => {
  return addresses.map(el => ({
    displayName: getNameFromEmailOption(`${el.value}`),
    emailAddress: getEmailValueFromEmailOption(`${el.value}`),
  }));
};

export const toOptionsValue = (
  value?: string | string[] | null
): LabeledValue[] => {
  if (!value) return [];
  if (typeof value === 'string') {
    return value
      .split(';')
      .map(em => ({ value: em.trim(), label: em.trim(), key: em.trim() }));
  }
  return value.map(em => ({
    value: em.trim(),
    label: em.trim(),
    key: em.trim(),
  }));
};

export function transformDataToSaveVariables(payload: {
  data: EmailFormValues;
  isArincEmail: boolean;
  isHandoverEmail: boolean;
}): EMailVoInput | HandoverEmail {
  const { data, isArincEmail, isHandoverEmail } = payload;
  if (isArincEmail) {
    return {
      fromAddress: transformAddresses(data.from)[0],
      toAddresses: transformAddresses(data.to),
      ccAddresses: !data.cc?.length ? [] : transformAddresses(data.cc),
      bccAddresses: !data.bcc?.length ? [] : transformAddresses(data.bcc),
      subject: data.subject,
      body: data.body.replace(/[\r\n]/g, '<br>'),
    };
  }
  // W/A changes underline and line-through tags to avoid those text view as a link in windows mail viewer
  const body = data.body
    .replace(/<ins>/g, '<span style="text-decoration: underline;">')
    .replace(/<\/ins>/g, '</span>')
    .replace(/<del>/g, '<span style="text-decoration: line-through;">')
    .replace(/<\/del>/g, '</span>');
  if (isHandoverEmail) {
    return {
      bodyHtml: body,
      email_cc: !data.cc?.length
        ? []
        : transformAddresses(data.cc).map(ad => ad.emailAddress),

      email_to: transformAddresses(data.to).map(ad => ad.emailAddress),
      email_from: transformAddresses(data.from)[0].emailAddress,
      subject: data.subject,
    };
  }
  return {
    fromAddress: null,
    toAddresses: transformAddresses(data.to),
    ccAddresses: !data.cc?.length ? null : transformAddresses(data.cc),
    bccAddresses: !data.bcc?.length ? null : transformAddresses(data.bcc),
    subject: data.subject,
    body,
  };
}
const hydrateStylesFromFontTagToInline = (template: string): string => {
  return template
    .split('<font')
    .map(st => {
      const size = st.split(/ size=\"/)[1]?.substr(0, 2);
      const color = st.split(/ color=\"#?/)[1];
      const indexOfEndOfTheColor = color?.indexOf('"');
      const colorName = color?.substr(0, indexOfEndOfTheColor);
      const resized = +size
        ? st
            .replace(/font-size:\s?\d+pt;/g, `font-size: ${size}px;`)
            .replace(/<\/div><div style=".*">/, '')
        : st;
      const colored = color
        ? resized.replace(
            /style=\"/g,
            `style="color: ${
              /^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(colorName) ? '#' : ''
            }${colorName};`
          )
        : resized;
      return /(face|<\/font)/.test(colored) ? `<font ${colored}` : colored;
    })
    .join('')
    .replace(/<b>/g, '<strong>')
    .replace(/<\/b>/g, '</strong>');
};

export function hydrateFromValuesFromTemplate(props: {
  airportsById: { [id: number]: Airport };
  aircraftById: { [aircraftId: number]: Aircraft };
  eMailTemplate: MailTemplate;
  mxEventFormValues: MxEventFormValuesForEmail;
  userEmail: string;
  userName: string;
}): EmailFormValues {
  const {
    eMailTemplate,
    userName,
    mxEventFormValues,
    airportsById,
    userEmail,
    aircraftById,
  } = props;
  const { toField, ccField, bccField, subject, value } = eMailTemplate;
  const {
    aircraftId,
    airportId,
    reason,
    serviceProviderName,
  } = mxEventFormValues;
  const tail = aircraftId && aircraftById[aircraftId];
  const icao = (airportId && airportsById[airportId]?.ICAO) || '';
  const subjectHydrated = subject
    .replace('[SERIAL_NR]', tail?.serialNumber || '')
    .replace('[TAIL_NR]', tail?.tailNumber || '');
  const bodyHydrated = value
    .replace('[SERIAL_NR]', tail?.serialNumber || '')
    .replace('[TAIL_NR]', tail?.tailNumber || '')
    .replace('[ICAO]', icao)
    .replace('[PROVIDER]', serviceProviderName || '')
    .replace('[USER_NAME]', userName)
    .replace('[FROM_USER_EMAIL]', userEmail)
    .replace('[REASON]', reason);
  const correctedStyle = hydrateStylesFromFontTagToInline(bodyHydrated);
  return {
    bcc: toOptionsValue(bccField),
    body: correctedStyle,
    cc: toOptionsValue(ccField),
    from: [],
    subject: subjectHydrated,
    to: toOptionsValue(toField),
  };
}

export const transformFromHandoverToMainTemplate = (
  template: HandoverEmail
): EmailFormValues => ({
  bcc: [],
  body: template.bodyHtml,
  cc: toOptionsValue(template.email_cc),
  from: toOptionsValue(template.email_from),
  subject: template.subject,
  to: toOptionsValue(template.email_to),
});
