import { Modal, Spin } from 'antd';
import { LabeledValue } from 'antd/es/select';
import { Formik, FormikHelpers } from 'formik';
import { FC, memo } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import {
  userCancelEmail,
  userResetEmailAddressOptions,
  userSearchEmailAddressOptions,
  userSendHandoverEmail,
  userSendMxEmail,
} from '../../actions';
import { RootState } from '../../reducers';
import { getAircraftByIdMap } from '../../selectors';
import { EMailVoInput } from '../../types/email';
import { spinnerStyles } from '../edit-event-drawer/constants';
import { arincFormValues, initialFormValues } from './constants';
import { EmailFormComponent } from './email-form';
import { SharedProps, EmailFormValues } from './interfaces';
import {
  getEmailValueFromEmailOption,
  hydrateFromValuesFromTemplate,
  transformDataToSaveVariables,
} from './utils';
import { HandoverEmail } from '../../types/handover-remarks';
import { getFilteredAndSortedAllRemarks } from '../../selectors/handover-remarks';
import { AnyAction, Dispatch } from 'redux';
import { isEqual } from 'lodash';

const validateEmail = Yup.array()
  .nullable()
  .transform((emails: LabeledValue[]) => {
    if (!emails) return [];
    return emails.reduce<string[]>((acc, item) => {
      return item?.key
        ? acc.concat(getEmailValueFromEmailOption(item.key))
        : acc;
    }, []);
  })
  .of(
    Yup.string().email(({ value }) => {
      return `Entered email address ${value} is incorrect`;
    })
  );

const validationSchema = Yup.object().shape({
  from: validateEmail,
  to: validateEmail
    .min(1, 'Please define recipients')
    .required('Please define recipients'),
  cc: validateEmail,
  bcc: validateEmail,
});

interface Props {
  isArincEmail?: boolean;
  isHandoverEmail?: boolean;
  isLoading?: boolean;
  onSubmit: (email: EMailVoInput | HandoverEmail) => void;
}

const EmailComponent: FC<Props & SharedProps> = memo(
  ({
    emailAddressOptions,
    filterEmailAddressOptions,
    formValues,
    fromUser = '',
    isArincEmail = false,
    isHandoverEmail = false,
    isLoading = false,
    handoverRemarks = [],
    filters,
    sending,
    visible,
    onClose,
    onSubmit,
    onUserSearchEmailAddressOptions,
    resetEmailAddressOptions,
  }) => {
    return (
      <Modal
        title={isArincEmail ? 'ARINC Message' : 'Mail'}
        open={visible}
        width={1152}
        onCancel={onClose}
        footer={null}
        keyboard
        maskClosable={false}
        destroyOnClose
      >
        <Spin spinning={isLoading} style={spinnerStyles} tip="Loading...">
          <Formik
            enableReinitialize={true}
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            onSubmit={(
              values: EmailFormValues,
              { setSubmitting }: FormikHelpers<EmailFormValues>
            ) => {
              onSubmit(
                transformDataToSaveVariables({
                  data: values,
                  isArincEmail,
                  isHandoverEmail,
                })
              );
              setSubmitting(false);
            }}
          >
            {formikProps => {
              return (
                <EmailFormComponent
                  formikProps={formikProps}
                  isArincEmail={isArincEmail}
                  isHandoverEmail={isHandoverEmail}
                  isLoading={isLoading}
                  onClose={onClose}
                  onUserSearchEmailAddressOptions={
                    onUserSearchEmailAddressOptions
                  }
                  resetEmailAddressOptions={resetEmailAddressOptions}
                  filterEmailAddressOptions={filterEmailAddressOptions}
                  emailAddressOptions={emailAddressOptions}
                  sending={sending}
                  formValues={formValues}
                  fromUser={fromUser}
                  handoverRemarks={handoverRemarks}
                  filters={filters}
                />
              );
            }}
          </Formik>
        </Spin>
      </Modal>
    );
  },
  isEqual
);

const mxEmailMapStateToProps = (state: RootState) => ({
  emailAddressOptions: state.email.emailAddressOptions,
  formValues: state.email.eMailTemplate
    ? hydrateFromValuesFromTemplate({
        aircraftById: getAircraftByIdMap(state),
        airportsById: state.airports.airportsById,
        eMailTemplate: state.email.eMailTemplate,
        mxEventFormValues: state.eventElementCreationEdit.mxEventFormValues,
        userEmail: state.user.userProfile?.email ?? '',
        userName: state.user.userName,
      })
    : initialFormValues,
  filterEmailAddressOptions: state.email.filterEmailAddressOptions,
  isLoading: state.email.isLoadingEmailTemplate,
  sending: state.email.sending,
  visible: state.email.isMxEmailModalVisible,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onClose: () => {
    dispatch(userCancelEmail());
  },
  onUserSearchEmailAddressOptions: (value: string) => {
    dispatch(userSearchEmailAddressOptions(value));
  },
  onSubmit: (mailTemplate: EMailVoInput) => {
    dispatch(userSendMxEmail.started({ mailTemplate }));
  },
  resetEmailAddressOptions: () => {
    dispatch(userResetEmailAddressOptions());
  },
});

export const MxEmailComponentConnected = connect(
  mxEmailMapStateToProps,
  mapDispatchToProps
)(EmailComponent);

const arincMapStateToProps = (state: RootState) => ({
  emailAddressOptions: state.email.emailAddressOptions,
  formValues: {
    ...arincFormValues,
    subject:
      getAircraftByIdMap(state)[state.email.aircraftId]?.tailNumber?.replace(
        '-',
        ''
      ) ?? '',
  },
  filterEmailAddressOptions: state.email.filterEmailAddressOptions,
  isArincEmail: true,
  sending: state.email.sending,
  visible: state.email.isArincEmailVisible,
});

export const ArincEmailComponentConnected = connect(
  arincMapStateToProps,
  mapDispatchToProps
)(EmailComponent);

export const HandoverEmailComponentConnected = connect(
  (state: RootState) => {
    return {
      emailAddressOptions: state.email.emailAddressOptions,
      filterEmailAddressOptions: state.email.filterEmailAddressOptions,
      formValues: initialFormValues,
      fromUser: state.user.userProfile.email,
      handoverRemarks: getFilteredAndSortedAllRemarks(state),
      filters: state.handoverRemarks.filters,
      isHandoverEmail: true,
      sending: state.email.sending,
      visible: state.email.isHandoverEmailModalVisible,
    };
  },
  (dispatch: Dispatch<AnyAction>) => ({
    onClose: () => {
      dispatch(userCancelEmail());
    },
    onUserSearchEmailAddressOptions: (value: string) => {
      dispatch(userSearchEmailAddressOptions(value));
    },
    onSubmit: (email: HandoverEmail) => {
      dispatch(userSendHandoverEmail.started(email));
    },
    resetEmailAddressOptions: () => {
      dispatch(userResetEmailAddressOptions());
    },
  })
)(EmailComponent);
