import { PureComponent } from 'react';
import * as actions from '../../../actions';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { DispatchProp } from 'react-redux';
import { StatusGroupType } from '../../../reducers/operational-statuses';
import { AnyAction } from 'redux';

interface Props {
  statusMap: { [st in StatusGroupType]: boolean };
  allCheckboxStatus: -1 | 0 | 1;
}

export class OpStatusesContent extends PureComponent<
  Props & DispatchProp<AnyAction>
> {
  onChangeOpStatusVisibility = (statusGroup: StatusGroupType) => (
    e: CheckboxChangeEvent
  ) =>
    this.props.dispatch(
      actions.userToggleOpStatusView([
        statusGroup,
        !this.props.statusMap[statusGroup],
      ])
    );
  onChangeOpStatusVisibilityAll = (e: CheckboxChangeEvent) =>
    this.props.dispatch(
      actions.userToggleOpStatusViewAll(!this.props.allCheckboxStatus)
    );
  render() {
    const { allCheckboxStatus, statusMap } = this.props;
    return (
      <div data-test-entity="filter-bar-opstatus" className="unselectable">
        <Checkbox
          onChange={this.onChangeOpStatusVisibilityAll}
          checked={!!allCheckboxStatus}
          indeterminate={allCheckboxStatus === -1}
        >
          All
        </Checkbox>
        {Object.keys(statusMap).map(key => (
          <div key={key}>
            <Checkbox
              onChange={this.onChangeOpStatusVisibility(key as StatusGroupType)}
              checked={statusMap[key]}
            >
              {key}
            </Checkbox>
          </div>
        ))}
      </div>
    );
  }
}
