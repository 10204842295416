import { PureComponent } from 'react';
import MaintenanceItem from '../../../../types/maintenance-item';
import TooltipDates from '../../dates';
import TooltipCreationLogTime from '../../creation-log-time';
import TooltipHeader from '../../header/';
import { LINE_HEIGHT } from '../../../../constants';
import { setDataTestEntityId } from '../../../../utils';

interface TooltipContentProps {
  segmentData: MaintenanceItem;
}
class TooltipContent extends PureComponent<
  TooltipContentProps,
  { isHovered: boolean }
> {
  render() {
    const {
      start,
      end,
      description,
      maintenanceItemRef: ref,
      updatedDate,
      createdDate,
    } = this.props.segmentData;
    const isUpdated = createdDate !== updatedDate;
    return (
      <div className="tooltip-common">
        <div
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ width: 272 }}>
            <TooltipCreationLogTime
              lastUpdated={isUpdated ? updatedDate : createdDate}
              isUpdated={isUpdated}
            />
            <div
              className="tooltip-common-ref"
              {...setDataTestEntityId('tooltip-mel-ref')}
            >
              MEL Ref: {ref}
            </div>
            <div
              className="tooltip-common-description"
              {...setDataTestEntityId('tooltip-mel-description')}
              style={{
                overflow: 'hidden',
                maxHeight: `${LINE_HEIGHT * 2.5}px`,
              }}
            >
              <div
                style={{
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                }}
              >
                {description.map((el, idx) => (
                  <p key={idx}> {el} </p>
                ))}
              </div>
            </div>
          </div>
        </div>
        <TooltipDates start={start} end={end} />
      </div>
    );
  }
}

interface Props {
  segmentData: MaintenanceItem;
}

export default class TooltipMaintenanceItemCommon extends PureComponent<Props> {
  render() {
    const { segmentData } = this.props;
    const { id, maintenanceItemType } = segmentData;
    return (
      <div className="tooltip-container">
        <TooltipHeader
          id={id}
          color="rgba(0, 0, 0, 0.85)"
          backgroundColor="#D9EEFF"
          name={`MEL • ${maintenanceItemType}`}
        />
        <TooltipContent segmentData={segmentData} />
      </div>
    );
  }
}
