import './styles.scss';
import { v4 as uuidv4 } from 'uuid';
import { App, Button, Drawer } from 'antd';
import { FC, FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import {
  userCloseHandoverRemarksDrawer,
  userOpenHandoverEmailModal,
  userOpenHandoverRemarksDrawer,
  userPinHandoverRemarksDrawer,
  userSaveHandoverRemark,
} from '../../actions';
import { ConfirmationFooter } from '../../common/components/confirmation-modal/confirmation-footer';
import { ConfirmationModal } from '../../common/components/confirmation-modal/confirmation-modal';
import { RootState } from '../../reducers';
import {
  AllRemarksShape,
  HandoverRemark,
  HandoverType,
} from '../../types/handover-remarks';
import { CurrentHandoverRemarksTitle } from './components/title/CurrentRemarksTitle';
import { AllHandoverRemarksTitle } from './components/title/AllRemarksTitle';
import Airport from '../../types/airport';
import Aircraft from '../../types/aircraft';
import { getAllAvailableAircraftByIdMap } from '../../selectors';
import Flight from '../../types/flight';
import {
  getFlightDetailsForHandoverTitle,
  setCardsOnAdd,
  transformRemarkForSave,
} from './utils';
import { getRelatedRemarks } from '../../selectors/handover-remarks';
import { HandoverBody } from './components/body';
import { HandoverRemarksState } from '../../reducers/handover-remarks';
import { HandoverEmailComponentConnected } from '../email-component';
import { HANDOVER_REMARK_CARD_WIDTH } from './constants';
import { UserProfile } from '../../types/user';
import { DEFINE_TYPE_WARNING, EMPTY_REMARK_WARNING } from './constants';
import { hasPermission } from '../../utils/check-permissions';

interface StateProps {
  aircraftById: { [id: number]: Aircraft };
  airportsById: { [id: number]: Airport };
  allRemarks: AllRemarksShape['remarks'][];
  currentRemarks: HandoverRemark[];
  loadingAll: boolean;
  loadingCurrentRemarks: boolean;
  navigate: HandoverRemarksState['navigate'];
  relatedRemarks: AllRemarksShape['remarks'][];
  savingRemarkIds: string[];
  selectedFlight: Flight;
  selectedTail: Aircraft;
  userName: string;
  visible: boolean;
  unPinned: boolean;
}

export const HandoverRemarksPanelSolid: FC = () => {
  const dispatch = useDispatch();
  // selected props
  const aircraftById = useSelector<RootState, StateProps['aircraftById']>(
    getAllAvailableAircraftByIdMap
  );
  const airportsById = useSelector<RootState, StateProps['airportsById']>(
    state => state.airports.airportsById,
    isEqual
  );
  const {
    currentRemarks,
    loadingCurrent: loadingCurrentRemarks,
    loadingAll,
    navigate,
    savingIds: savingRemarkIds,
    flight: selectedFlight,
    isDrawerVisible: visible,
    unPinned,
    isHandoverEmailModalVisible,
  } = useSelector<
    RootState,
    Partial<HandoverRemarksState & { isHandoverEmailModalVisible: boolean }>
  >(
    state => ({
      currentRemarks: state.handoverRemarks.currentRemarks,
      loadingCurrent: state.handoverRemarks.loadingCurrent,
      loadingAll: state.handoverRemarks.loadingAll,
      navigate: state.handoverRemarks.navigate,
      savingIds: state.handoverRemarks.savingIds,
      flight: state.handoverRemarks.flight,
      isDrawerVisible: state.handoverRemarks.isDrawerVisible,
      unPinned: state.handoverRemarks.unPinned,
      isHandoverEmailModalVisible: state.email.isHandoverEmailModalVisible,
    }),
    isEqual
  );
  const selectedTail = useSelector<RootState, Aircraft | null>(state =>
    state.handoverRemarks.aircraftId
      ? getAllAvailableAircraftByIdMap(state)[state.handoverRemarks.aircraftId]
      : null
  );
  const relatedRemarks = useSelector<RootState, StateProps['relatedRemarks']>(
    getRelatedRemarks
  );
  const hasOpsEditPermission = useSelector<RootState, boolean>(state =>
    hasPermission(state, 'AG-Timeline-Handover-Ops_Edit')
  );
  const hasCsEditPermission = useSelector<RootState, boolean>(state =>
    hasPermission(state, 'AG-Timeline-Handover-CS_Edit')
  );
  const hasFullEditPermission = hasCsEditPermission && hasOpsEditPermission;
  const user = useSelector<RootState, UserProfile>(
    state => state.user.userProfile,
    isEqual
  );
  const userName = `${user.given_name} ${user.family_name}`;
  // panel state
  const isAll = !selectedFlight?.id && !selectedTail?.id;
  const [cards, setCards] = useState<HandoverRemark[]>([]);
  const [editingIds, setEditIds] = useState<string[]>([]);
  const [newRemarkIds, setNewRemarkIds] = useState<string[]>([]);
  const [isChangedOrder, setOrderChanged] = useState<boolean>(false);
  const [isShowingRelated, setShowRelated] = useState<boolean>(false);
  const [isToConfirmClose, setConfirmVisibility] = useState<boolean>(false);
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [needToAddRemark, setNeedToAddRemark] = useState<boolean>(false);
  const [notesAreSticked, setPinTailRemarks] = useState<boolean>(true);
  const [showMoreFilters, toggleShowMoreFilters] = useState<boolean>(false);
  // panel state and props handlers
  const reset = () => {
    setIsTouched(false);
    setConfirmVisibility(false);
    setOrderChanged(false);
    setEditIds([]);
    setCards([]);
    setShowRelated(false);
    setNewRemarkIds([]);
    setPinTailRemarks(true);
  };
  useEffect(() => {
    if (isAll || !visible) {
      reset();
    }
  }, [isAll, visible]);
  // control for confirmation modal
  useEffect(() => {
    if (editingIds.length > 0 || isChangedOrder) {
      setIsTouched(true);
    } else {
      setIsTouched(false);
    }
  }, [editingIds, isChangedOrder]);
  // adding tail remark from related box in case it was empty
  useEffect(() => {
    if (needToAddRemark && selectedTail?.id && !loadingCurrentRemarks) {
      onAddRemark();
      setNeedToAddRemark(false);
    }
  }, [selectedTail, loadingCurrentRemarks]);

  const onAddRemark = () => {
    const generated = uuidv4();
    const aircraftId = selectedTail?.id || null;
    const newRemark: HandoverRemark = {
      flightLegId: selectedFlight?.id || null,
      aircraftId,
      id: generated,
      isActive: true,
      remarks: '',
      type:
        aircraftId || hasFullEditPermission
          ? undefined
          : hasOpsEditPermission
          ? HandoverType.ops
          : hasCsEditPermission
          ? HandoverType.cs
          : undefined,
    };
    setCards(c => setCardsOnAdd(newRemark, c));
    setEditIds(c => c.concat(generated));
    setNewRemarkIds(c => c.concat(generated));
  };
  const onToggleRelatedRemarks = () => setShowRelated(c => !c);
  const onSaveRemark = (remark: HandoverRemark, keepOpened: boolean) => {
    dispatch(
      userSaveHandoverRemark.started({
        remark,
        keepOpened,
        isNew: newRemarkIds.includes(remark.id),
      })
    );
  };
  const onClose = () => dispatch(userCloseHandoverRemarksDrawer());
  const handleClose = () => {
    if (isToConfirmClose) {
      setConfirmVisibility(false);
      onClose();
    }
    if (isTouched) {
      setConfirmVisibility(true);
    } else {
      onClose();
    }
  };
  const onCloseConfirmationModal = () => setConfirmVisibility(false);
  const { message } = App.useApp();
  const doCheckBeforeSave = () => {
    const hasEmptyRemarks = cards.some(c => !c.remarks.trim());
    const hasNotDefinedType =
      hasFullEditPermission &&
      cards.some(
        c =>
          !c.type &&
          !c.aircraftId &&
          (editingIds.includes(c.id) || newRemarkIds.includes(c.id))
      );

    if (hasEmptyRemarks) {
      message.warning(EMPTY_REMARK_WARNING);
    }
    if (hasNotDefinedType) {
      message.warning(DEFINE_TYPE_WARNING);
    }
    return hasEmptyRemarks || hasNotDefinedType;
  };
  // this event works out of the box on click buttons with role submit on handover form
  // important to use native submit for smooth saving flow
  const onSubmit = (
    e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();
    const hasWarnings = doCheckBeforeSave();
    if (hasWarnings) {
      return;
    } else {
      cards
        .reduce((acc, card) => {
          return acc
            .then(() => {
              if (isChangedOrder || editingIds.includes(card.id)) {
                onSaveRemark(
                  transformRemarkForSave(card, userName),
                  navigate.to !== null // keepOpened
                );
              }
            })
            .then(() => {
              setEditIds(c => c.filter(id => id !== card.id));
              setNewRemarkIds(c => c.filter(id => id !== card.id));
            });
        }, Promise.resolve())
        .finally(() => {
          setEditIds([]);
          setOrderChanged(false);
          setIsTouched(false);
        });
    }
  };
  const onTogglePinSidebars = () => {
    dispatch(userPinHandoverRemarksDrawer(!unPinned));
  };
  const onTogglePinTailRemarks = () => {
    setPinTailRemarks(c => !c);
  };
  const currentViewTitle = !!selectedFlight?.id
    ? getFlightDetailsForHandoverTitle(
        selectedFlight,
        airportsById,
        aircraftById
      )
    : `Tail ${(selectedTail?.tailNumber || 'N/A').toUpperCase()}`;
  const onOpenEmailModal = () => dispatch(userOpenHandoverEmailModal());
  const onToggleRangeFilters = () => toggleShowMoreFilters(c => !c);
  return (
    <>
      <ConfirmationModal
        onClose={onCloseConfirmationModal}
        open={visible && isToConfirmClose}
        footer={
          <ConfirmationFooter
            formId={'handoverRemarks'}
            handleClose={handleClose}
            loading={savingRemarkIds.length > 0}
            saveTitle="Yes"
            cancelTitle="No"
          />
        }
        confirmationTitle={
          isChangedOrder && !editingIds.length
            ? 'You have unsaved changes (remarks order)'
            : undefined
        }
      />
      {isHandoverEmailModalVisible && <HandoverEmailComponentConnected />}
      <Drawer
        closable={false}
        destroyOnClose
        keyboard={unPinned}
        mask={unPinned}
        rootStyle={{ zIndex: unPinned ? 3 : 1 }}
        placement="right"
        styles={{
          body: {
            padding: '16px',
            overflowY: 'hidden',
          },
          header: {
            padding: '16px 8px 16px 20px',
          },
        }}
        title={
          isAll ? (
            <AllHandoverRemarksTitle
              notesAreSticked={notesAreSticked}
              onClickPin={onTogglePinSidebars}
              onClickPinTailNotes={onTogglePinTailRemarks}
              showMoreFilters={showMoreFilters}
              onToggleRangeFilters={onToggleRangeFilters}
            />
          ) : (
            <CurrentHandoverRemarksTitle
              editingIds={editingIds}
              isChangedOrder={isChangedOrder}
              isShowingRelated={isShowingRelated}
              onAddRemark={onAddRemark}
              onClickPin={onTogglePinSidebars}
              onToggleRelatedRemarks={onToggleRelatedRemarks}
              setOrderChanged={setOrderChanged}
              handleClose={handleClose}
              title={currentViewTitle}
            />
          )
        }
        rootClassName="handover-panel"
        open={visible}
        width={HANDOVER_REMARK_CARD_WIDTH + 50}
        onClose={handleClose}
      >
        <HandoverBody
          showRangeFilter={showMoreFilters}
          isAll={isAll}
          cards={cards}
          currentRemarks={currentRemarks}
          editingIds={editingIds}
          isShowingRelated={isShowingRelated}
          isChangedOrder={isChangedOrder}
          loadingAll={loadingAll}
          loadingCurrentRemarks={loadingCurrentRemarks}
          newRemarkIds={newRemarkIds}
          tailNotesAreSticked={notesAreSticked}
          setNeedToAddRemark={setNeedToAddRemark}
          onSubmit={onSubmit}
          relatedRemarks={relatedRemarks}
          reset={reset}
          setCards={setCards}
          setEditIds={setEditIds}
          setNewRemarkIds={setNewRemarkIds}
          setOrderChanged={setOrderChanged}
          userName={userName}
        />
        {isAll ? (
          <Button
            className="send-email-button"
            type="primary"
            onClick={onOpenEmailModal}
          >
            Send by email
          </Button>
        ) : null}
      </Drawer>
    </>
  );
};

export const HandoverRemarksPanel: FC<{}> = () => (
  <App>
    <HandoverRemarksPanelSolid />
  </App>
);
