import './styles.scss';
import { FC } from 'react';
import { CreateEventsMenuWrapper } from '../create-events-menu/create-events-menu';
import { EmptyLegOfferContextMenuConnected } from './elements/emptyLegOfferMenu';
import { FlightContextMenuConnected } from './elements/flightMenu';
import { MxEventsContextMenuConnected } from './elements/mxMenu';
import { NoteContextMenuConnected } from './elements/noteMenu';
import { OneWayOfferContextMenuConnected } from './elements/oneWayOfferMenu';
import { PeakDaysContextMenuConnected } from './elements/peakDaysMenu';

export const ContextMenuWrappers: FC<{}> = () => (
  <>
    <CreateEventsMenuWrapper />
    <FlightContextMenuConnected />
    <NoteContextMenuConnected />
    <MxEventsContextMenuConnected />
    <EmptyLegOfferContextMenuConnected />
    <OneWayOfferContextMenuConnected />
    <PeakDaysContextMenuConnected />
  </>
);
