import { FC } from 'react';
import { ReloadOutlined } from '@ant-design/icons';
import { Button, Popconfirm, message } from 'antd';
import * as actions from '../../../actions';
import { RootState } from '../../../reducers';
import { useDispatch, useSelector } from 'react-redux';

import {
  VISIBLE_AC_TYPES,
  VISIBLE_OPERATING_COMPANIES,
} from '../../../constants/environment';
import { getAvailableAircraftTypesByOperatingCompanyMap } from '../../../selectors';
import { hydrateVisibleAcTypesByOpCompanyConfig } from '../../../utils/aircraft';

import { FilterByAircraft } from '../../../types/aircraft';

export const ResetPreset: FC<{}> = () => {
  const dispatch = useDispatch();
  const acTypesOptionsByOperatingCompanyMap = useSelector<
    RootState,
    { [companyId: number]: FilterByAircraft[] }
  >(getAvailableAircraftTypesByOperatingCompanyMap);
  const visibleAircraftTypeByOperatingCompanyMap = hydrateVisibleAcTypesByOpCompanyConfig(
    {
      visibleAcTypes: VISIBLE_AC_TYPES,
      visibleOperatingCompanies: VISIBLE_OPERATING_COMPANIES,
      acTypesOptionsByOperatingCompanyMap,
    }
  );
  const reset = () => {
    dispatch(
      actions.userResetPreferences({ visibleAircraftTypeByOperatingCompanyMap })
    );

    message.success(
      'Default settings applied. Please, click Save Preset to apply this configuration'
    );
  };

  return (
    <Popconfirm
      title="Do you want to apply default settings?"
      onConfirm={reset}
      placement="bottom"
      okText="Yes"
      cancelText="No"
    >
      <Button
        icon={<ReloadOutlined />}
        style={{
          background: '#F2F2F2',
          border: 'none',
        }}
      >
        Reset
      </Button>
    </Popconfirm>
  );
};
