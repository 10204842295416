import { PureComponent } from 'react';
import { DispatchProp, connect } from 'react-redux';
import { RootState } from '../../reducers';
import {
  SearchType,
  searchMultipleBarTypeList,
  SearchQueryAllFilters,
} from '../../reducers/search';
import { ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import * as actions from '../../actions';
import { createDelayedForScrollConnect } from '../../utils/create-delayed-for-scroll-connect';
import { getFoundElements } from '../../selectors';
import './styles.scss';
import { AnyAction } from 'redux';
import { MultiSearchAutoComplete } from './autocomplete-multi-search';

interface StateProps {
  searchType: SearchType;
  height: number;
  isVerticalMode: boolean;
  count: number;
  searchQueryAllFilters: SearchQueryAllFilters;
}

export class MultipleSearchBar extends PureComponent<
  StateProps & DispatchProp<AnyAction>
> {
  onSearch = () => {
    const { count, dispatch, searchQueryAllFilters, searchType } = this.props;
    if (!searchQueryAllFilters[searchType]) return;
    if (count > 0) {
      dispatch(actions.userFocusFirstFound());
    }
  };
  onResetFilters = () => {
    this.props.dispatch(actions.userResetMultipleSearchBarPanel());
  };
  render() {
    const { height, isVerticalMode } = this.props;
    return (
      <div
        className="filter-bar"
        style={
          height > 0
            ? {
                height,
                zIndex: 0,
                flexWrap: isVerticalMode ? 'wrap' : 'nowrap',
                padding: isVerticalMode ? '4px 8px 5px 15px' : '0 15px',
                maxHeight: height,
                justifyContent: isVerticalMode ? 'space-around' : 'flex-start',
              }
            : { height: 0, display: 'none' }
        }
      >
        {searchMultipleBarTypeList.map(searchType => (
          <MultiSearchAutoComplete
            key={searchType}
            searchType={searchType}
            onSearch={this.onSearch}
          />
        ))}
        <Button className="multiple-search-button" onClick={this.onSearch}>
          <SearchOutlined />
        </Button>

        <Button
          className="multiple-search-button"
          icon={<ReloadOutlined />}
          onClick={this.onResetFilters}
        >
          Reset
        </Button>
      </div>
    );
  }
}

const advancedMap = createDelayedForScrollConnect(
  (state: RootState): StateProps => {
    const count = getFoundElements(state).length;
    return {
      searchType: state.search.searchType,
      count,
      height: state.ui.multipleSearchBarHeight,
      isVerticalMode: state.ui.isVerticalMode,
      searchQueryAllFilters: state.search.searchQueryAllFilters,
    };
  }
);

export const MultipleSearchBarConnected = connect(advancedMap)(
  MultipleSearchBar
);
