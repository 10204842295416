import './styles.scss';
import { FC, ReactElement, useRef, useState } from 'react';
import { CloseCircleOutlined, DownOutlined } from '@ant-design/icons';
import { Checkbox, Select, Tooltip } from 'antd';
import { OptionsShape, getSuffixText } from './helper';
import { SelectProps } from 'antd/lib/select';
import { SelectComponentStyled } from './styled';

interface Props {
  onBlur?: () => void;
  onChange: (ids: number[]) => void;
  onClickClearIcon: () => void;
  allItems: OptionsShape[];
  value: number[];
}
const Item: FC<{ item: OptionsShape; checked?: boolean }> = ({
  item,
  checked,
}) => (
  <div className="multiple-select-option-item">
    <Checkbox checked={item.checked || checked} />
    <Tooltip title={item.value}>
      <div className="multiple-select-option-text">{item.value}</div>
    </Tooltip>
  </div>
);

export const MultiSelectComponent: FC<Props & SelectProps> = ({
  onBlur,
  onChange,
  onClickClearIcon,
  filterOption,
  allItems,
  placeholder,
  style,
  value,
}) => {
  const [focused, setFocused] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const selectRef = useRef(null);
  const onFocus = () => setFocused(true);

  const onBlurHandler = () => {
    setFocused(false);
    selectRef.current.blur();
    setSearchValue('');
    if (typeof onBlur !== 'undefined') onBlur();
  };

  const onChangeHandler = value => {
    onChange(value);
    setSearchValue('');
  };
  const onClickTagOrSuffix = () => {
    setFocused(!focused);
    if (focused) {
      selectRef.current.blur();
    } else {
      selectRef.current.focus();
    }
  };
  const onSearchValue = (value: string) => setSearchValue(value);

  const dropdownRender = (menu: ReactElement) => (
    <div className="multiple-select-dropdown">{menu}</div>
  );

  const clearIcon = <CloseCircleOutlined onClick={onClickClearIcon} />;
  const suffixIcon = (
    <div onClick={onClickTagOrSuffix}>
      <span
        className="multiple-select-suffix-text"
        style={{ color: focused ? '#bfbfbf' : 'rgba(0, 0, 0, 0.85)' }}
      >
        {getSuffixText(value.length)}
      </span>
      <DownOutlined className="multiple-select-suffix-icon" />
    </div>
  );

  const maxTagPlaceholder = searchValue
    ? ''
    : allItems.find(it => it.id === value[0])?.value || '';
  return (
    <SelectComponentStyled
      allowClear={clearIcon}
      className="multiple-select"
      popupMatchSelectWidth={false}
      dropdownRender={dropdownRender}
      filterOption={filterOption}
      menuItemSelectedIcon={null}
      maxTagCount={Number(!focused)}
      maxTagPlaceholder={maxTagPlaceholder}
      mode="multiple"
      onBlur={onBlurHandler}
      onChange={onChangeHandler}
      onFocus={onFocus}
      onSearch={onSearchValue}
      open={focused}
      optionFilterProp="label"
      optionLabelProp="label"
      ref={selectRef}
      removeIcon={null}
      placeholder={placeholder}
      searchValue={searchValue}
      style={style}
      suffixIcon={suffixIcon}
      value={value}
      $focused={!!Number(focused)}
      $multiple={!!Number(value.length > 1)}
    >
      {allItems.map(el => (
        <Select.Option
          className="multiple-select-option"
          key={el.id}
          label={el.value}
          value={el.id}
        >
          <Item item={el} checked={value.includes(el.id)} />
        </Select.Option>
      ))}
    </SelectComponentStyled>
  );
};
