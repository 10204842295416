import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ChangeHandoverAircraftFilterPayload,
  userChangeHandoverRemarksAircraftFilter,
  userChangeHandoverViewByCsTeam,
} from '../../../../actions';
import { RootState } from '../../../../reducers';
import {
  getAcTypeSelectOptions,
  getCsTeamSelectOptions,
  getOperatorSelectOptions,
  getTailsSelectOptions,
} from '../../../../selectors/handover-remarks';
import { HandoverSelectFilterType } from '../../../../types/handover-remarks';
import { getPlaceholderValueFromType } from '../../utils';
import { MultiSelectComponent } from '../../../../common/components/multi-select/multi-select-component';
import { OptionsShape } from '../../../../common/components/multi-select/helper';

interface StateProps {
  allItems: OptionsShape[];
}
interface OwnProps {
  type: HandoverSelectFilterType;
  width: number;
}

const MultiSelectContainer: FC<StateProps & OwnProps> = ({
  allItems,
  type,
  width,
}) => {
  const dispatch = useDispatch();
  const onChange = (payload: ChangeHandoverAircraftFilterPayload['ids']) =>
    dispatch(
      userChangeHandoverRemarksAircraftFilter({
        type: type,
        ids: payload,
      })
    );
  const onClickClearIcon = () => onChange([]);

  const value = allItems.reduce((acc, item) => {
    if (item.checked) {
      return acc.concat(item.id);
    }
    return acc;
  }, []);
  return (
    <MultiSelectComponent
      filterOption
      onChange={onChange}
      onClickClearIcon={onClickClearIcon}
      allItems={allItems}
      placeholder={getPlaceholderValueFromType(type)}
      value={value}
      style={{ width }}
    />
  );
};

export const OperatorMultiSelectComponent: FC<OwnProps> = ownProps => {
  const allItems = useSelector<RootState, OptionsShape[]>(
    getOperatorSelectOptions
  );
  return <MultiSelectContainer allItems={allItems} {...ownProps} />;
};
export const AcTypeMultiSelectComponent: FC<OwnProps> = ownProps => {
  const allItems = useSelector<RootState, OptionsShape[]>(
    getAcTypeSelectOptions
  );
  return <MultiSelectContainer allItems={allItems} {...ownProps} />;
};
export const TailMultiSelectComponent: FC<OwnProps> = ownProps => {
  const allItems = useSelector<RootState, OptionsShape[]>(
    getTailsSelectOptions
  );
  return <MultiSelectContainer allItems={allItems} {...ownProps} />;
};

export const CsTeamMultiSelectComponent: FC = () => {
  const dispatch = useDispatch();
  const allItems = useSelector<RootState, OptionsShape[]>(
    getCsTeamSelectOptions
  );
  const value = useSelector<RootState, number[]>(
    state => state.handoverRemarks.filters.csTeam
  );

  const loading = useSelector<RootState, boolean>(
    state => state.csTeam.loading
  );

  const onChange = (payload: number[]) => {
    const nextCsTeam = payload.includes(0) ? [] : payload;
    dispatch(userChangeHandoverViewByCsTeam(nextCsTeam));
  };

  const onClickClearIcon = () => onChange([]);

  return (
    <div className="remarks-title-column-more-filters-container">
      <MultiSelectComponent
        filterOption
        onChange={onChange}
        onClickClearIcon={onClickClearIcon}
        allItems={allItems}
        placeholder={'CS Team'}
        value={value}
        loading={loading}
        style={{ width: 150, marginLeft: 4 }}
      />
    </div>
  );
};
