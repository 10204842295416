import { OptionsShape } from '../../common/components/multi-select/helper';
import { PEAK_DAY_REGIONS, PEAK_DAY_TYPES } from '../../constants/environment';
import PeakDay from '../../types/peak-day';
import { sortById } from '../../utils/peak-days';

export interface PeakDayGVModel {
  id: {
    key: number;
  };
  startDate: number;
  endDate: number;
  description: string;
  isAutoAdvertising: boolean;
  isCrewChange: boolean;
  isActive: boolean;
  lastSavedByName: string;
  lastUpdateDate: number;
  peakDayTypes: Partial<OptionsShape>[];
  peakDayRegions: Partial<OptionsShape>[];
}

export const transformPeakDayToFE = (a: PeakDayGVModel): PeakDay => ({
  id: a.id.key,
  start: a.startDate,
  end: a.endDate,
  isAutoAdvertising: a.isAutoAdvertising,
  text: a.description,
  lastUpdated: a.lastUpdateDate,
  updatedBy: a.lastSavedByName,
  isCrewChange: a.isCrewChange,
  isActive: a.isActive,
  peakDayRegions:
    a.peakDayRegions
      ?.map(v => ({
        id: v.id,
        value: v.value
          ? v.value
          : PEAK_DAY_REGIONS.find(r => r.id === v.id)?.value ||
            'Unknown region',
      }))
      .sort(sortById) || [],
  peakDayTypes:
    a.peakDayTypes
      ?.map(v => ({
        id: v.id,
        value: v.value
          ? v.value
          : PEAK_DAY_TYPES.find(r => r.id === v.id)?.value || 'Unknown type',
      }))
      .sort(sortById) || [],
});
