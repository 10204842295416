import { message } from 'antd';
import { ApolloQueryResult } from '@apollo/client';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';

import {
  doFetchEmailAddressOptions,
  doFetchEmailTemplate,
  doFetchMxEventById,
  doFetchMxOrderTypes,
  EmailTemplateParams,
  userClickCreateMaintenance,
  userClickEditMxEvent,
  userDeleteMxEvent,
  userOpenMxEmailTemplate,
  userSaveMxEvent,
  userSearchEmailAddressOptions,
  userSearchForServiceProviders,
  userSendMxEmail,
  userClickCloneMxEvent,
} from '../actions';
import {
  getAircraftOperator,
  isAircraftForMxEmails,
} from '../common/aircraft/aircraft-check-status';
import { isRtsEmailResponse } from '../common/maintenance/maintenance-labels';
import { RootState } from '../reducers';
import { getAircraftById } from '../selectors';
import { deleteMxEventMutation } from '../services/delete-mx-event-service/delete-mx-event';
import { getAogEmailTemplate } from '../services/maintenance-service/get-aog-email-template';
import { getEmailAddressOptions } from '../services/maintenance-service/get-email-address-options';
import { getMxEventById } from '../services/maintenance-service/get-mx-event';
import { getMxOrderTypes } from '../services/maintenance-service/get-mx-event-order-types';
import { getRtsEmailTemplate } from '../services/maintenance-service/get-rts-email-template';
import { getServiceProvidersForAirport } from '../services/maintenance-service/get-service-provider';
import { saveMxEventMutation } from '../services/maintenance-service/save-mx-event';
import { sendMxEmail } from '../services/maintenance-service/send-email';
import {
  MaintenanceType,
  MXEventDtoFull,
  MXEventOrderTypeDto,
  MXServiceProviderDto,
} from '../types/maintenance';
import {
  aogMailTemplateQuery,
  DeleteMxEventMutation,
  EMailVoInput,
  MXEventDtoInput,
  rtsMailTemplateQuery,
} from '../types/operation-result-types';
import Aircraft from '../types/aircraft';

function* getMxEventByIdSaga(action: Action<{ id: number }>) {
  yield put(doFetchMxEventById.started(action.payload));
  try {
    const result: ApolloQueryResult<{ event: MXEventDtoFull }> = yield call(
      getMxEventById,
      action.payload.id
    );
    if (!result?.data) {
      throw new Error(`Failed to load Mx Event #${action.payload.id}`);
    }
    yield put(
      doFetchMxEventById.done({
        params: action.payload,
        result: result.data?.event,
      })
    );
    if (result.data?.event.airportId) {
      yield put(
        userSearchForServiceProviders.started(result.data.event.airportId)
      );
    }
  } catch (error) {
    yield put(
      doFetchMxEventById.failed({
        params: action.payload,
        error,
      })
    );
  }
}

function* getMxOrderTypesSaga(action: Action<null>) {
  const orderTypes = yield select(
    (state: RootState) => state.eventElementCreationEdit.orderTypes
  );
  if (orderTypes) return;
  yield put(doFetchMxOrderTypes.started(action.payload));
  try {
    const result: ApolloQueryResult<{
      eventOrderTypes: MXEventOrderTypeDto[];
    }> = yield call(getMxOrderTypes);
    yield put(
      doFetchMxOrderTypes.done({
        params: action.payload,
        result: result.data?.eventOrderTypes,
      })
    );
  } catch (error) {
    yield put(
      doFetchMxOrderTypes.failed({
        params: action.payload,
        error,
      })
    );
  }
}

function* saveMxEventSaga(
  action: Action<{ mxEvent: MXEventDtoInput; serviceProviderName: string }>
) {
  try {
    const res: ApolloQueryResult<{ saveMxEvent: string | null }> = yield call(
      saveMxEventMutation,
      action.payload.mxEvent
    );
    yield put(
      userSaveMxEvent.done({
        params: action.payload,
        result: res.data?.saveMxEvent,
      })
    );
    message.success(
      `MX event is ${action.payload.mxEvent.id ? 'saved' : 'created'}`
    );
    const {
      maintenanceTypeId,
      id,
      rtsSelected,
      aircraftId,
    } = action.payload.mxEvent;
    const aircraft = yield select((state: RootState) =>
      getAircraftById(state, aircraftId)
    );
    if (
      isAircraftForMxEmails(aircraft) &&
      maintenanceTypeId === MaintenanceType.AOG &&
      (rtsSelected || !id)
    ) {
      yield put(userOpenMxEmailTemplate(action.payload));
    }
  } catch (error) {
    yield put(
      userSaveMxEvent.failed({
        params: action.payload,
        error,
      })
    );
  }
}

function* sendEmailSaga(action: Action<{ mailTemplate: EMailVoInput }>) {
  try {
    const res: ApolloQueryResult<{ sendEmail: string | null }> = yield call(
      sendMxEmail,
      action.payload.mailTemplate
    );
    yield put(
      userSendMxEmail.done({
        params: action.payload,
        result: res.data?.sendEmail,
      })
    );
    message.success('Email is sent successfully');
  } catch (error) {
    yield put(
      userSendMxEmail.failed({
        params: action.payload,
        error,
      })
    );
  }
}

function* searchServiceProvidersSaga(action: Action<number>) {
  try {
    const res: ApolloQueryResult<{
      mxServiceProviders: MXServiceProviderDto[];
    }> = yield call(getServiceProvidersForAirport, action.payload);
    if (res.errors) {
      throw new Error(res.errors.map[0]?.message);
    }
    yield put(
      userSearchForServiceProviders.done({
        params: action.payload,
        result: res.data?.mxServiceProviders,
      })
    );
  } catch (error) {
    yield put(
      userSearchForServiceProviders.failed({
        params: action.payload,
        error,
      })
    );
  }
}

function* getAogOrRtsMailTemplateSaga(
  action: Action<{ mxEvent: MXEventDtoInput; serviceProviderName: string }>
) {
  const { rtsSelected, aircraftId } = action.payload.mxEvent;
  const aircraft: Aircraft = yield select((state: RootState) =>
    getAircraftById(state, aircraftId)
  );
  const operator = getAircraftOperator(aircraft);
  const params: EmailTemplateParams = {
    emailType: rtsSelected ? 'RTS' : 'AOG',
    operator,
    manufacturerId: aircraft.manufacturerId,
  };
  const method = rtsSelected ? getRtsEmailTemplate : getAogEmailTemplate;
  yield put(doFetchEmailTemplate.started(params));

  try {
    const result: ApolloQueryResult<
      rtsMailTemplateQuery | aogMailTemplateQuery
    > = yield call(method, operator, aircraft.manufacturerId);
    if (result.errors) {
      throw new Error(result.errors.map[0]?.message);
    }
    yield put(
      doFetchEmailTemplate.done({
        params,
        result: isRtsEmailResponse(result.data)
          ? result.data?.rtsMailTemplate
          : result.data?.aogMailTemplate,
      })
    );
  } catch (error) {
    yield put(
      doFetchEmailTemplate.failed({
        params,
        error,
      })
    );
  }
}

function* getEmailAddressOptionsSaga(action: Action<string>) {
  yield put(doFetchEmailAddressOptions.started(action.payload));
  try {
    const result: ApolloQueryResult<{
      getEmailAddressOptions: string[];
    }> = yield call(getEmailAddressOptions, action.payload);
    yield put(
      doFetchEmailAddressOptions.done({
        params: action.payload,
        result: result.data?.getEmailAddressOptions,
      })
    );
  } catch (error) {
    yield put(
      doFetchEmailAddressOptions.failed({
        params: action.payload,
        error,
      })
    );
  }
}

function* deleteMxEventSaga(action: Action<number>) {
  try {
    const response: ApolloQueryResult<DeleteMxEventMutation> = yield call(
      deleteMxEventMutation,
      action.payload
    );
    yield put(
      userDeleteMxEvent.done({
        params: action.payload,
        result: response.data,
      })
    );
    message.success('Maintenance Event has been deleted');
  } catch (error) {
    yield put(userDeleteMxEvent.failed(error));
  }
}

export function* mxEventSaga() {
  yield all([
    takeLatest(userClickEditMxEvent, getMxEventByIdSaga),
    takeLatest(userClickEditMxEvent, getMxOrderTypesSaga),
    takeLatest(userClickCloneMxEvent, getMxEventByIdSaga),
    takeLatest(userClickCloneMxEvent, getMxOrderTypesSaga),
    takeLatest(userClickCreateMaintenance, getMxOrderTypesSaga),
    takeLatest(userSaveMxEvent.started, saveMxEventSaga),
    takeLatest(
      userSearchForServiceProviders.started,
      searchServiceProvidersSaga
    ),
    takeLatest(userOpenMxEmailTemplate, getAogOrRtsMailTemplateSaga),
    takeLatest(userSearchEmailAddressOptions, getEmailAddressOptionsSaga),
    takeLatest(userSendMxEmail.started, sendEmailSaga),
    takeLatest(userDeleteMxEvent.started, deleteMxEventSaga),
  ]);
}
