import { FC, ReactNode, useState } from 'react';
import { HANDOVER_HEADER_HEIGHT } from '../../constants';

interface Props {
  bodyHeight: number;
  topChildren?: ReactNode;
  bottomChildren?: ReactNode;
}
export const DividedBody: FC<Props> = ({
  bodyHeight,
  bottomChildren,
  topChildren,
}) => {
  const minTopHeight = 150;
  const dividerHeight = 16 * 2;
  const headerHeight = HANDOVER_HEADER_HEIGHT;
  const maxTopHeight = bodyHeight - minTopHeight - dividerHeight * 1.5;
  const [topHeight, setTopHeight] = useState<number>(
    bodyHeight / 2 - dividerHeight
  );

  const onDrag = (v: React.DragEvent) => {
    // keep min top size
    if (v.clientY <= minTopHeight) {
      setTopHeight(minTopHeight);
    }
    // keep min bottom size
    if (v.clientY > maxTopHeight + headerHeight) {
      setTopHeight(maxTopHeight);
    } else {
      setTopHeight(Math.abs(v.clientY - headerHeight));
    }
  };
  const onDragOver = (ev: React.DragEvent) => {
    ev => {
      ev.preventDefault();
      ev.dataTransfer.dropEffect = 'move';
    };
  };
  return (
    <div style={{ height: bodyHeight }}>
      <div
        className="divided"
        style={{
          overflowX: 'hidden',
          height: `${topHeight}px`,
          minHeight: `${minTopHeight}px`,
        }}
        onDragOver={onDragOver}
      >
        {topChildren}
      </div>
      <div
        className="custom-divider"
        onDrag={onDrag}
        onDragEnd={onDrag}
        onDragOver={onDragOver}
      />
      <div
        className="divided"
        style={{
          height: `${bodyHeight - topHeight - dividerHeight}px`,
        }}
        onDragOver={onDragOver}
      >
        {bottomChildren}
      </div>
    </div>
  );
};
