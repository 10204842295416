import { utc } from 'moment';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as actions from '../actions';
import {
  reduceRemarksByBatchUpdate,
  updateCurrentRemarks,
} from '../components/handover-remarks/utils';
import Flight from '../types/flight';
import {
  HandoverRemarksViewByType,
  HandoverType,
  HandoverRemark,
  HandoverFilters,
} from '../types/handover-remarks';
import { isNumber, uniq } from 'lodash';

export interface HandoverRemarksState {
  aircraftId: number | null;
  allRemarks: HandoverRemark[];
  currentRemarks: HandoverRemark[];
  deletingIds: string[];
  filters: HandoverFilters;
  flight: Flight | null;
  isDrawerVisible: boolean;
  isTailSearch: boolean;
  keepOpened: boolean;
  loadingAll: boolean;
  loadingCurrent: boolean;
  navigate: {
    from: Flight | number | null;
    remarkId: string;
    to: 'All' | 'Back' | Flight | number | null;
  };
  pushedIds: string[];
  savingIds: string[];
  showContactSupportMsg: boolean;
  unPinned: boolean;
}

export const initialState: HandoverRemarksState = {
  aircraftId: null,
  allRemarks: [],
  currentRemarks: [],
  deletingIds: [],
  flight: null,
  filters: {
    acTypes: [],
    csTeam: [],
    from: utc().valueOf(),
    operator: [],
    radioByType: HandoverRemarksViewByType.all,
    radioByDepartment: [HandoverType.ops, HandoverType.cs],
    tail: [],
    to: null,
  },
  isDrawerVisible: false,
  isTailSearch: false,
  keepOpened: false,
  loadingCurrent: false,
  loadingAll: false,
  navigate: { remarkId: null, from: null, to: null },
  pushedIds: [],
  savingIds: [],
  showContactSupportMsg: false,
  unPinned: true,
};

const isFlight = (payload: Flight | number | null): payload is Flight =>
  !!(payload as Flight)?.id;

const getNavigateToFlight = (
  navigate: HandoverRemarksState['navigate']
): Flight | null => {
  const { from, to } = navigate;
  if (!to || to === 'All') return null;
  if (to === 'Back' && isFlight(from)) return from;
  if (typeof to !== 'string' && isFlight(to)) return to;
  return null;
};
const getNavigateToAircraft = (
  navigate: HandoverRemarksState['navigate']
): number | null => {
  const { from, to } = navigate;
  if (!to || to === 'All') return null;
  if (to === 'Back' && isNumber(from)) return from;
  if (typeof to !== 'string' && isNumber(to)) return to;
  return null;
};

const getNavigateBackFrom = (
  navigate: HandoverRemarksState['navigate'],
  flight: Flight | null,
  aircraftId: number | null
): number | Flight | null => {
  const { from, to } = navigate;
  if (to === 'Back') {
    return flight || aircraftId;
  }
  if (isNumber(to)) {
    return flight;
  }
  if (typeof to !== 'string' && isFlight(to)) {
    return aircraftId;
  }
  return from;
};

export default reducerWithInitialState(initialState)
  .case(actions.userOpenHandoverRemarksDrawer, (state, payload) => ({
    ...state,
    flight: isFlight(payload.isFor) ? payload.isFor : null,
    aircraftId: typeof payload.isFor === 'number' ? payload.isFor : null,
    isDrawerVisible: true,
    keepOpened: false,
    currentRemarks: initialState.currentRemarks,
    navigate: {
      remarkId:
        typeof payload.navigateFrom !== 'undefined'
          ? payload.navigateFrom
          : payload.isFor === null
          ? // navigate to view all with scroll position
            state.navigate.remarkId
          : // keep only one step back
            null,
      from:
        typeof payload.navigateFrom !== 'undefined'
          ? state.aircraftId || state.flight
          : null,
      to: null,
    },
    pushedIds: [],
    filters: {
      ...state.filters,
      radioByType:
        payload.isFor === null && payload.navigateFrom === null
          ? HandoverRemarksViewByType.all
          : state.filters.radioByType,
    },
  }))
  .case(actions.userCloseHandoverRemarksDrawer, state => ({
    ...initialState,
    aircraftId: state.aircraftId,
    allRemarks: state.allRemarks,
    currentRemarks: state.currentRemarks,
    flight: state.flight,
  }))
  .case(actions.userPinHandoverRemarksDrawer, (state, payload) => ({
    ...state,
    unPinned: payload,
  }))
  .case(actions.userSelectDashboardWidget, state => ({
    ...state,
    unPinned: false,
  }))
  .case(actions.userChangeSearchACQuery, (state, payload) => ({
    ...state,
    unPinned: !payload.query?.trim().length ? state.unPinned : false,
    isTailSearch: payload.isFromHandover,
  }))
  .cases(
    [
      actions.doGetHandoverRemarksForFlight.started,
      actions.doGetHandoverRemarksForAircraft.started,
    ],
    state => ({
      ...state,
      loadingCurrent: true,
    })
  )
  .cases(
    [
      actions.doGetHandoverRemarksForFlight.failed,
      actions.doGetHandoverRemarksForAircraft.failed,
    ],
    state => ({
      ...state,
      loadingCurrent: false,
    })
  )
  .cases(
    [
      actions.doGetHandoverRemarksForFlight.done,
      actions.doGetHandoverRemarksForAircraft.done,
    ],
    (state, payload) => ({
      ...state,
      loadingCurrent: false,
      currentRemarks: payload.result,
    })
  )
  .case(actions.doGetAllHandoverRemarks.started, (state, payload) => ({
    ...state,
    loadingAll: true,
  }))
  .case(actions.doGetAllHandoverRemarks.done, (state, payload) => ({
    ...state,
    loadingAll: false,
    allRemarks: payload.result,
  }))
  .case(actions.doGetAllHandoverRemarks.failed, (state, payload) => ({
    ...state,
    loadingAll: false,
  }))
  .case(actions.doSetHandoverNavigateTo, (state, payload) => ({
    ...state,
    navigate: {
      ...state.navigate,
      to: payload.to,
      //                            in case we go back need to keep remarkId
      remarkId: payload.remarkId || state.navigate.remarkId,
    },
  }))
  .case(actions.userSaveHandoverRemark.started, (state, payload) => {
    return {
      ...state,
      savingIds: state.savingIds.concat(payload.remark.id),
      keepOpened: payload.keepOpened,
    };
  })
  .case(actions.userSaveHandoverRemark.done, (state, payload) => {
    const savingIds = state.savingIds.filter(
      ids => ids !== payload.params.remark.id
    );
    const flight = getNavigateToFlight(state.navigate);
    const aircraftId = getNavigateToAircraft(state.navigate);
    const isDrawerVisible = !state.keepOpened
      ? savingIds.length > 0
      : state.keepOpened;
    return {
      ...state,
      savingIds,
      isDrawerVisible,
      aircraftId: isDrawerVisible
        ? state.navigate.to !== null
          ? aircraftId
          : state.aircraftId
        : initialState.aircraftId,
      flight: isDrawerVisible
        ? state.navigate.to !== null
          ? flight
          : state.flight
        : initialState.flight,
      navigate: {
        ...state.navigate,
        from: getNavigateBackFrom(
          state.navigate,
          state.flight,
          state.aircraftId
        ),
        to: null,
      },
    };
  })
  .case(actions.userSaveHandoverRemark.failed, (state, payload) => ({
    ...state,
    savingIds: state.savingIds.filter(ids => ids !== payload.params.remark.id),
  }))
  .case(actions.userDeleteHandoverRemark.started, (state, payload) => ({
    ...state,
    deletingIds: state.deletingIds.concat(payload),
  }))
  .cases(
    [
      actions.userDeleteHandoverRemark.done,
      actions.userDeleteHandoverRemark.failed,
    ],
    (state, payload) => ({
      ...state,
      deletingIds: state.deletingIds.filter(ids => ids !== payload.params),
    })
  )
  .case(actions.userChangeHandoverRemarksRangeFilter, (state, payload) => {
    let to = state.filters.to;
    if (to !== null && payload.type === 'from' && payload.value > to) {
      to = null;
    }
    return {
      ...state,
      filters: {
        ...state.filters,
        to,
        [payload.type]: payload.value,
      },
    };
  })
  .case(actions.userChangeHandoverRemarksAircraftFilter, (state, payload) => ({
    ...state,
    filters: {
      ...state.filters,
      [payload.type]: payload.ids,
    },
  }))
  .case(actions.userChangeHandoverViewByType, (state, payload) => ({
    ...state,
    filters: {
      ...state.filters,
      radioByType: payload,
      radioByDepartment:
        payload === HandoverRemarksViewByType.tail
          ? [HandoverType.ops]
          : state.filters.radioByType === HandoverRemarksViewByType.tail
          ? initialState.filters.radioByDepartment
          : state.filters.radioByDepartment,
    },
  }))
  .case(actions.userChangeHandoverViewByCsTeam, (state, payload) => ({
    ...state,
    filters: {
      ...state.filters,
      csTeam: payload,
    },
  }))
  .case(actions.userChangeHandoverViewByDepartment, (state, payload) => ({
    ...state,
    filters: {
      ...state.filters,
      radioByDepartment: payload,
    },
  }))
  .case(actions.wsHandoverUpdateBatch, (state, payload) => ({
    ...state,
    allRemarks: reduceRemarksByBatchUpdate(state.allRemarks, payload),
    currentRemarks: updateCurrentRemarks(
      state.currentRemarks,
      payload,
      state.aircraftId,
      state.flight
    ),
    pushedIds: uniq(
      state.pushedIds.concat(
        Array.isArray(payload) ? payload.map(a => a.id) : payload.id
      )
    ),
  }))
  .case(actions.doResetPushedId, (state, payload) => ({
    ...state,
    pushedIds: state.pushedIds.filter(c => c !== payload),
  }))
  .case(actions.doShowContactSupportMessage, state => ({
    ...state,
    showContactSupportMsg: true,
  }));
