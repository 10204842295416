import { transformToFE } from './one-way-offer-model';
import { axiosAuthed as axios } from '../axios-config';

export const getOneWayOffers = (startTime: number, endTime: number) =>
  axios
    .get(
      `/integration/timeline/one-way-offers?startTime=${startTime}&endTime=${endTime}`
    )
    .then(resp => resp.data.map(transformToFE))
    .catch(e => {
      throw e;
    });
