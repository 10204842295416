import { transformToFE } from './note-model';
import { axiosAuthed as axios } from '../axios-config';

export const getNotes = (startTime: number, endTime: number) =>
  axios
    .get(
      `/integration/timeline/notes?startTime=${startTime}&endTime=${endTime}`
    )
    .then(resp => resp.data.map(transformToFE))
    .catch(e => {
      throw e;
    });
