import * as d3Scale from 'd3-scale';
import * as d3Selection from 'd3-selection';
import * as d3Time from 'd3-time';
import { utc } from 'moment';
import { zoomLevels, getZoomLevel } from '../zoom';
import TimelineElement from '../timeline-element';
import Transform from '../../../types/transform';
import '../styles.scss';
import { AXIS_TOP_MARGIN } from '../../../constants';
import { axisHourD3Creator } from './d3-creator';
interface LabelFormatter extends d3Selection.ValueFn<any, Date, string> {
  (data: Date): string;
}

export default class Axis extends TimelineElement<
  d3Scale.ScaleTime<number, number>
> {
  protected d3creator: (
    d: d3Selection.Selection<any, Date, any, any>
  ) => d3Selection.Selection<any, Date, any, any>;
  protected labelFormatter: LabelFormatter;
  protected interval: d3Time.TimeInterval;
  protected hasDelimeter: boolean;
  protected marginTop: number;
  protected isBold: boolean;
  protected rootGroup: d3Selection.Selection<any, Date, any, any>;
  verticesTopOffset: number;
  verticesHeight: number;
  constructor(
    options: {
      labelFormatter: LabelFormatter;
      interval: d3Time.TimeInterval;
      hasDelimeter: boolean;
      isBold: boolean;
      marginTop: number;
      verticesTopOffset: number;
      verticesHeight: number;
      d3creator?: (
        d: d3Selection.Selection<any, Date, any, any>
      ) => d3Selection.Selection<any, Date, any, any>;
    },
    data: d3Scale.ScaleTime<number, number>
  ) {
    super(data);
    this.labelFormatter = options.labelFormatter;
    this.interval = options.interval;
    this.hasDelimeter = options.hasDelimeter;
    this.isBold = options.isBold;
    this.marginTop = options.marginTop;
    this.verticesTopOffset = options.verticesTopOffset;
    this.verticesHeight = options.verticesHeight;
    this.d3creator = options.d3creator;
  }
  render(rootNode: d3Selection.Selection<any, Date, any, any>) {
    super.render(rootNode);
    const ticks = this.data.ticks(this.interval);
    this.rootGroup = this.rootNode.append('g');
    const groups = this.rootGroup
      .attr('transform', `translate(0,${this.marginTop})`)
      .selectAll('.tick')
      .data(ticks, (d: Date) =>
        utc(d)
          .unix()
          .toString()
      );
    this.renderEntered(groups.enter());
  }
  protected renderEntered(
    entered: d3Selection.Selection<any, Date, any, any>
  ): d3Selection.Selection<any, Date, any, any> {
    if (this.d3creator) {
      return this.d3creator(entered);
    }
    const rendered = entered.append('g').classed('tick', true);
    if (this.hasDelimeter)
      rendered
        .append('line')
        .attr('stroke', this.isBold ? '#E0E0DE' : 'rgba(0,0,0,0.05)')
        .attr('y1', -this.verticesTopOffset)
        .attr('y2', this.verticesHeight);
    rendered
      .attr('transform', d => `translate(${this.data(d)},0)`)
      .append('text')
      .text(this.labelFormatter)
      .attr('x', 6)
      .attr('dy', '-0.7em')
      .attr('style', 'color:#272727;font-size:11px;')
      .style('font-weight', d => (this.isBold ? 'bold' : 'regular'));
    return rendered;
  }
  reZoom(transform: Transform) {
    this.update(transform.scaleX);
    const ticks = this.data.ticks(this.interval);
    const datedGroups = this.rootGroup
      .selectAll('.tick')
      .data(ticks, (d: Date) => d.valueOf().toString());
    datedGroups.exit().remove();
    const enteredGroups = this.renderEntered(datedGroups.enter());
    datedGroups
      .merge(enteredGroups)
      .attr('transform', d => `translate(${this.data(d)},0)`);
    if (this.hasDelimeter) {
      datedGroups
        .selectAll('line')
        .attr('y1', -this.verticesTopOffset)
        .attr('y2', this.verticesHeight);
    }
  }
  clear() {
    this.rootGroup && this.rootGroup.remove();
  }
  update(data: d3Scale.ScaleTime<number, number>) {
    this.data = data;
  }
}
export class AxisCombined extends TimelineElement<
  d3Scale.ScaleTime<number, number>
> {
  dayDelimeterAxis: Axis;
  weekAxis: Axis;
  dayAxis: Axis;
  hour3Axis: Axis;
  hourAxis: Axis;
  zoomLevel: zoomLevels;
  constructor(
    options: {
      verticesTopOffset: number;
      verticesHeight: number;
    },
    data: d3Scale.ScaleTime<number, number>
  ) {
    super(data);
    const { verticesTopOffset, verticesHeight } = options;
    this.weekAxis = new Axis(
      {
        labelFormatter: (e: Date) => {
          const firstDay = utc(e).format('MMM DD');
          let lastDay;
          if (
            utc(e).get('month') !==
            utc(e)
              .add(6, 'days')
              .get('month')
          ) {
            lastDay = utc(e)
              .add(6, 'days')
              .format('MMM DD');
          } else {
            lastDay = utc(e)
              .add(6, 'days')
              .format('DD');
          }
          return `${firstDay} – ${lastDay}`;
        },
        interval: d3Time.utcWeek,
        hasDelimeter: true,
        marginTop: AXIS_TOP_MARGIN,
        isBold: true,
        verticesHeight,
        verticesTopOffset,
      },
      data
    );
    this.dayAxis = new Axis(
      {
        d3creator: entered => {
          const rendered = entered.append('g').classed('tick', true);
          rendered
            .attr('transform', d => `translate(${this.data(d)},0)`)
            .append('text')
            .text((d: Date) => utc(d).format('DD'))
            .attr('x', 3)
            .attr('dy', '-0.5em')
            .attr('fill', 'rgba(0,0,0,0.85)')
            .classed('axis-days', true);
          rendered
            .append('text')
            .text((d: Date) => {
              const currentMoment = utc(d);
              const endOfYear = utc(d)
                .endOf('year')
                .dayOfYear();
              const current = utc(d).dayOfYear();
              return currentMoment.format(
                current === 1 || current === endOfYear ? 'MMM YYYY' : 'MMM'
              );
            })
            .attr('x', 28)
            .attr('dy', '-2.2em')
            .attr('fill', 'rgba(0,0,0,0.85)')
            .classed('axis-months', true);
          rendered
            .append('text')
            .text((d: Date) =>
              utc(d)
                .format('ddd')
                .toUpperCase()
            )
            .attr('x', 28)
            .attr('dy', '-1.1em')
            .attr('fill', 'rgba(0, 0, 0, 0.45)')
            .classed('axis-days-of-weak', true);
          return rendered;
        },
        labelFormatter: (e: Date) => utc(e).format('ddd DD, MMM '),
        interval: d3Time.utcDay,
        marginTop: AXIS_TOP_MARGIN,
        hasDelimeter: false,
        isBold: true,
        verticesHeight,
        verticesTopOffset,
      },
      data
    );
    this.dayDelimeterAxis = new Axis(
      {
        labelFormatter: (e: Date) => '',
        interval: d3Time.utcDay,
        marginTop: AXIS_TOP_MARGIN,
        hasDelimeter: true,
        isBold: false,
        verticesHeight,
        verticesTopOffset,
      },
      data
    );
    this.hourAxis = new Axis(
      {
        d3creator: entered => axisHourD3Creator(entered, options, this.data),
        labelFormatter: (e: Date) => utc(e).format('HH:mm'),
        interval: d3Time.utcHour,
        marginTop: AXIS_TOP_MARGIN + 20,
        hasDelimeter: true,
        isBold: false,
        verticesHeight,
        verticesTopOffset: verticesTopOffset - 23,
      },
      data
    );
    this.hour3Axis = new Axis(
      {
        d3creator: entered => axisHourD3Creator(entered, options, this.data),
        labelFormatter: (e: Date) => utc(e).format('HH:mm'),
        interval: d3Time.utcHour.every(6),
        hasDelimeter: true,
        marginTop: AXIS_TOP_MARGIN + 20,
        isBold: false,
        verticesHeight,
        verticesTopOffset: verticesTopOffset - 23,
      },
      data
    );
  }
  render(rootNode: d3Selection.Selection<any, Date, any, any>) {
    super.render(rootNode);
    this.zoomLevel = getZoomLevel(this.data);
    this.dayDelimeterAxis.render(rootNode);
    switch (this.zoomLevel) {
      case zoomLevels.HOUR: {
        this.hourAxis.render(rootNode);
        this.dayAxis.render(rootNode);
        break;
      }
      case zoomLevels.HOUR3: {
        this.hour3Axis.render(rootNode);
        this.dayAxis.render(rootNode);
        break;
      }
      case zoomLevels.DAY: {
        this.dayAxis.render(rootNode);
        break;
      }
      case zoomLevels.WEEK: {
        this.weekAxis.render(rootNode);
        break;
      }
      default:
    }
  }
  reZoom(transform: Transform) {
    const currentZoomLevel = getZoomLevel(transform.scaleX);
    this.dayDelimeterAxis.reZoom(transform);
    if (currentZoomLevel !== this.zoomLevel) {
      // zoom level changed from this.zoomLevel
      switch (this.zoomLevel) {
        case zoomLevels.HOUR: {
          this.hourAxis.clear();
          break;
        }
        case zoomLevels.HOUR3: {
          this.hour3Axis.clear();
          break;
        }
        case zoomLevels.WEEK: {
          this.weekAxis.clear();
          this.dayAxis.render(this.rootNode);
          break;
        }
        default:
      }
      // zoom level changed to currentZoomLevel
      switch (currentZoomLevel) {
        case zoomLevels.HOUR: {
          this.hourAxis.render(this.rootNode);
          this.hourAxis.reZoom(transform);
          this.dayAxis.reZoom(transform);
          break;
        }
        case zoomLevels.HOUR3: {
          this.hour3Axis.render(this.rootNode);
          this.hour3Axis.reZoom(transform);
          this.dayAxis.reZoom(transform);
          break;
        }
        case zoomLevels.DAY: {
          this.dayAxis.reZoom(transform);
          break;
        }
        case zoomLevels.WEEK: {
          this.dayAxis.clear();
          this.weekAxis.render(this.rootNode);
          this.weekAxis.reZoom(transform);
          break;
        }
        default:
      }
    } else {
      // Zoom level doesn't changed and still is this.zoomLevel
      switch (this.zoomLevel) {
        case zoomLevels.HOUR: {
          this.hourAxis.reZoom(transform);
          this.dayAxis.reZoom(transform);
          break;
        }
        case zoomLevels.HOUR3: {
          this.hour3Axis.reZoom(transform);
          this.dayAxis.reZoom(transform);
          break;
        }
        case zoomLevels.DAY: {
          this.dayAxis.reZoom(transform);
          break;
        }
        case zoomLevels.WEEK: {
          this.weekAxis.reZoom(transform);
          break;
        }
        default:
      }
    }
    this.zoomLevel = currentZoomLevel;
  }
  update(data: d3Scale.ScaleTime<number, number>) {
    super.update(data);
    this.weekAxis.update(data);
    this.dayAxis.update(data);
    this.dayDelimeterAxis.update(data);
    this.hour3Axis.update(data);
    this.hourAxis.update(data);
  }
  clear() {
    this.dayDelimeterAxis.clear();
    switch (this.zoomLevel) {
      case zoomLevels.HOUR: {
        this.hourAxis.clear();
        this.dayAxis.clear();
        break;
      }
      case zoomLevels.HOUR3: {
        this.hour3Axis.clear();
        this.dayAxis.clear();
        break;
      }
      case zoomLevels.DAY: {
        this.dayAxis.clear();
        break;
      }
      case zoomLevels.WEEK: {
        this.weekAxis.clear();
        break;
      }
      default:
    }
  }
}
