import { Checkbox, Radio } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Field, FieldInputProps, FormikProps } from 'formik';
import { PureComponent } from 'react';
import { ImportanceFactor } from '../../types/note';
import { NoteFormValues } from './interfaces';
import { NoteFormName } from './constants';
import { DateTimePickerComponent } from './components/date-picker';
import { AircraftAutocomplete } from './components/aircraft-auto-complete';

export class NoteFormDetails extends PureComponent<
  FormikProps<NoteFormValues> & {
    disabled: boolean;
    formIsTouched: boolean;
    setFormIsTouched: (v: boolean) => void;
  }
> {
  componentDidUpdate() {
    const { formIsTouched, dirty, setFormIsTouched } = this.props;
    if (!formIsTouched && dirty) {
      setFormIsTouched(true);
    }
  }
  render() {
    const {
      disabled,
      values: { importanceFactor, message },
      errors,
      touched,
      handleChange,
      setFieldValue,
    } = this.props;
    return (
      <div className="edit-event-from-body">
        <div className="edit-event-form-body-new-line">
          <label htmlFor={NoteFormName.importanceFactor}>Type</label>
          <div>
            <Field
              id={NoteFormName.importanceFactor}
              name={NoteFormName.importanceFactor}
              as={(
                formProps: FieldInputProps<NoteFormValues['importanceFactor']>
              ) => (
                <Radio.Group {...formProps} disabled={disabled}>
                  <Radio.Button value={ImportanceFactor.GENERAL}>
                    General
                  </Radio.Button>
                  <Radio.Button value={ImportanceFactor.AVAILABILITY}>
                    Availability
                  </Radio.Button>
                </Radio.Group>
              )}
            />
            {importanceFactor === ImportanceFactor.AVAILABILITY && (
              <label
                htmlFor={NoteFormName.crewNotAvailable}
                className="edit-event-form-body-checkbox-on-line"
              >
                <Field
                  id={NoteFormName.crewNotAvailable}
                  name={NoteFormName.crewNotAvailable}
                  as={(
                    formProps: FieldInputProps<
                      NoteFormValues['crewNotAvailable']
                    >
                  ) => (
                    <Checkbox
                      {...formProps}
                      checked={formProps.value}
                      disabled={disabled}
                    >
                      Crew not available
                    </Checkbox>
                  )}
                />
              </label>
            )}
          </div>
        </div>
        <div className="edit-event-form-body-new-line">
          <div>
            <label
              htmlFor={NoteFormName.aircraftName}
              className="edit-event-form-body-label-required"
            >
              Tail#
            </label>
            <Field
              id={NoteFormName.aircraftName}
              as={(
                formikProps: FieldInputProps<NoteFormValues['aircraftName']>
              ) => (
                <AircraftAutocomplete
                  {...formikProps}
                  disabled={disabled}
                  errors={errors}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  touched={touched}
                />
              )}
              name={NoteFormName.aircraftName}
            />
          </div>
          {errors.aircraftId && touched.aircraftId ? (
            <div className="edit-event-form-body-error-message">
              {errors.aircraftId}
            </div>
          ) : null}
        </div>
        <div className="edit-event-form-body-new-line">
          <label
            htmlFor={NoteFormName.fromDate}
            className="edit-event-form-body-label-required"
          >
            Starts (UTC)
          </label>
          <div>
            <Field
              id={NoteFormName.fromDate}
              name={NoteFormName.fromDate}
              as={(formProps: FieldInputProps<NoteFormValues['fromDate']>) => (
                <DateTimePickerComponent
                  {...formProps}
                  disabled={disabled}
                  setFieldValue={setFieldValue}
                />
              )}
            />
          </div>
          {errors.fromDate && touched.fromDate ? (
            <div className="edit-event-form-body-error-message">
              {errors.fromDate}
            </div>
          ) : null}
        </div>
        <div className="edit-event-form-body-new-line">
          <label
            htmlFor={NoteFormName.toDate}
            className="edit-event-form-body-label-required"
          >
            Ends (UTC)
          </label>
          <div className="edit-event-form-body-picker-with-tick">
            <Field
              id={NoteFormName.toDate}
              name={NoteFormName.toDate}
              as={(formProps: FieldInputProps<NoteFormValues['toDate']>) => (
                <DateTimePickerComponent
                  {...formProps}
                  disabled={disabled}
                  setFieldValue={setFieldValue}
                />
              )}
            />
          </div>
          {errors.toDate && touched.toDate ? (
            <div className="edit-event-form-body-error-message">
              {errors.toDate}
            </div>
          ) : null}
        </div>
        <div className="edit-event-form-body-new-line">
          <label
            htmlFor={NoteFormName.message}
            className="edit-event-form-body-label-required"
          >
            Message
          </label>
          <div>
            <TextArea
              autoSize={{
                minRows: 4,
                maxRows: 13,
              }}
              onChange={this.props.handleChange}
              disabled={disabled}
              id={NoteFormName.message}
              value={message}
              name={NoteFormName.message}
              style={{
                border:
                  errors.message && touched.message ? '1px solid red' : '',
              }}
              className="edit-event-form-body-input"
            />
          </div>
          {errors.message && touched.message ? (
            <div className="edit-event-form-body-error-message">
              {errors.message}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
