import { chain, flatMap, groupBy, sortBy, uniqBy } from 'lodash';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import {
  getAllAvailableAircraftByIdMap,
  getAvailableAircraftForMoveToTailMenu,
  getOperatingCompaniesMap,
} from '.';
import {
  filterByTeam,
  getDepartmentFilter,
  getDividedFlightRemarks,
  getFlightDetailsForHandoverTitle,
  sortCards,
} from '../components/handover-remarks/utils';
import { RootState } from '../reducers';
import { HandoverRemarksState } from '../reducers/handover-remarks';
import Aircraft, {
  FilterByAircraftType,
  FilterByOperatingCompany,
} from '../types/aircraft';
import Airport from '../types/airport';
import Flight from '../types/flight';
import {
  HandoverRemarksViewByType,
  AllRemarksShape,
  HandoverRemark,
  HandoverType,
} from '../types/handover-remarks';
import { ascending, convertHtmlToText } from '../utils';
import {
  getFlightsByIdFromPool,
  getFlightsByOriginalIdFromPool,
} from './flights';
import { elementsSelector } from './event-elements';
import { utc } from 'moment';
import { OptionsShape } from '../common/components/multi-select/helper';
import { CsTeamItem } from '../types/csTeam';
import { CsTeamState } from '../reducers/csTeam';

export const getFilteredAndSortedAllRemarks = createSelector<
  RootState,
  HandoverRemark[],
  { [id: number]: Flight },
  { [id: number]: Flight },
  { [id: number]: Airport },
  { [id: number]: Aircraft },
  HandoverRemarksState['filters'],
  CsTeamState['csTeamIdByName'],
  AllRemarksShape[]
>(
  state => state.handoverRemarks.allRemarks,
  getFlightsByIdFromPool,
  getFlightsByOriginalIdFromPool,
  state => state.airports.airportsById,
  getAllAvailableAircraftByIdMap,
  state => state.handoverRemarks.filters,
  state => state.csTeam.csTeamIdByName,
  (
    remarks,
    flightsMap,
    originalFlightMap,
    airportsMap,
    aircraftMap,
    filters,
    csTeamIdByNameMap
  ) => {
    const {
      from,
      to,
      operator,
      acTypes,
      tail: tailFilter,
      radioByType,
      radioByDepartment,
      csTeam,
    } = filters;

    // Filtering by team
    const departmentFilter = getDepartmentFilter(radioByDepartment);

    // if selected flight filter and nothing selected in department
    if (
      departmentFilter === 'None' &&
      radioByType === HandoverRemarksViewByType.flight
    ) {
      return [];
    }

    if (
      departmentFilter === HandoverType.cs ||
      departmentFilter === HandoverType.ops
    ) {
      remarks = filterByTeam(remarks, departmentFilter);
    }

    const groupedByFlightLegId = groupBy(
      remarks.filter(rem => rem.flightLegId),
      r => r.flightLegId
    );

    const filteredFlightRemarks = Object.keys(groupedByFlightLegId).reduce<
      AllRemarksShape[]
    >((flightAcc, flightKey) => {
      const flight = flightsMap[+flightKey] || originalFlightMap[+flightKey];
      if (!flight) return flightAcc;
      const aircraft = aircraftMap[flightsMap[flight.id].aircraftId];
      if (!aircraft) return flightAcc;
      const {
        operatingCompanyId,
        operatingCompanyName,
        id: aircraftId,
        aircraftTypeId,
        aircraftTypeName,
        tailNumber,
      } = aircraft;

      if (operator.length > 0 && !operator.includes(operatingCompanyId))
        return flightAcc;

      if (acTypes.length > 0 && !acTypes.includes(aircraftTypeId))
        return flightAcc;

      if (tailFilter.length > 0 && !tailFilter.includes(aircraftId))
        return flightAcc;

      if (from && flight.arrivalUtcScheduled < from) return flightAcc;
      if (to && flight.arrivalUtcScheduled > to) return flightAcc;
      if (
        csTeam.length > 0 &&
        !csTeam.includes(
          csTeamIdByNameMap[flight.csTeam?.trim().toLocaleLowerCase()]
        )
      )
        return flightAcc;
      const { csCards, opsCards } = getDividedFlightRemarks(
        groupedByFlightLegId[flightKey] || []
      );
      const data = {
        remarks: {
          cards: opsCards.sort(sortCards).concat(csCards.sort(sortCards)),
          title: getFlightDetailsForHandoverTitle(
            flight,
            airportsMap,
            aircraftMap
          ),
          flight, // need to open flight based remarks drawer from all remarks drawer
        },
        flightLegId: flight.id,
        operatingCompanyName,
        tailNumber: tailNumber.trim(),
        aircraftTypeName,
        aircraftId,
      };
      return flightAcc.concat(data);
    }, []);

    // first sort by departure
    filteredFlightRemarks.sort((a, b) => {
      const left = flightsMap[a.flightLegId];
      const right = flightsMap[b.flightLegId];
      if (left.departureUtcScheduled > right.departureUtcScheduled) return 1;
      if (left.departureUtcScheduled < right.departureUtcScheduled) return -1;
      return 0;
    });

    // second sort by operator, actype and tail alphabetically
    const flightRemarksSorted = chain(filteredFlightRemarks)
      .sortBy('tailNumber')
      .sortBy(a => a.aircraftTypeName.toLowerCase())
      .sortBy('operatingCompanyName')
      .value();

    const groupedByAircraftId = groupBy(
      remarks.filter(rem => rem.aircraftId),
      r => r.aircraftId
    );
    const filteredAircraftRemarks = Object.keys(groupedByAircraftId).reduce<
      AllRemarksShape[]
    >((aircraftAcc, aircraftKey) => {
      const aircraft = aircraftMap[aircraftKey];
      if (!aircraft) return aircraftAcc;
      const {
        operatingCompanyId,
        operatingCompanyName,
        aircraftTypeId,
        aircraftTypeName,
        tailNumber,
      } = aircraft;
      if (operator.length > 0 && !operator.includes(operatingCompanyId))
        return aircraftAcc;
      if (acTypes.length > 0 && !acTypes.includes(aircraftTypeId))
        return aircraftAcc;
      if (tailFilter.length > 0 && !tailFilter.includes(+aircraftKey))
        return aircraftAcc;
      const data = {
        remarks: {
          cards: (groupedByAircraftId[aircraftKey] || []).sort(sortCards),
          title: `Tail# ${(tailNumber || 'N/A').toUpperCase()}`,
          aircraftId: +aircraftKey, // need to open tail based remarks drawer from all remarks drawer
        },
        flightLegId: null,
        operatingCompanyName,
        tailNumber: tailNumber.trim(),
        aircraftTypeName,
        aircraftId: +aircraftKey,
      };
      return aircraftAcc.concat(data);
    }, []);

    const aircraftRemarksSorted = chain(filteredAircraftRemarks)
      .sortBy('tailNumber')
      .sortBy(a => a.aircraftTypeName.toLowerCase())
      .sortBy('operatingCompanyName')
      .value();

    if (radioByType === HandoverRemarksViewByType.flight) {
      return flightRemarksSorted;
    }
    if (
      radioByType === HandoverRemarksViewByType.tail ||
      (radioByType === HandoverRemarksViewByType.all &&
        departmentFilter === 'None')
    ) {
      return aircraftRemarksSorted;
    }
    const allAircraft = chain(aircraftMap)
      .sortBy('tailNumber')
      .sortBy(a => a.aircraftTypeName.toLowerCase())
      .sortBy('operatingCompanyName')
      .map(a => a.id)
      .value();
    const tailRemarksSet = groupBy(filteredAircraftRemarks, 'aircraftId');
    const flightRemarksSet = groupBy(filteredFlightRemarks, 'aircraftId');
    const combined = flatMap(
      allAircraft.reduce<AllRemarksShape[]>((combinedAcc, aircraftId) => {
        const group = [];
        const tailRemarks = tailRemarksSet[aircraftId];
        if (tailRemarks) {
          group.push(tailRemarks);
        }
        const flightRemarks = flightRemarksSet[aircraftId];
        if (flightRemarks) {
          group.push(flightRemarks);
        }
        return combinedAcc.concat(group);
      }, [])
    );
    return combined;
  }
);

export const getTailsWithHandover = createSelector<
  RootState,
  HandoverRemark[],
  boolean,
  Set<number>
>(
  state => state.handoverRemarks.allRemarks,
  state => state.ui.segmentsVisibility['handoverRemarks'],
  (remarks, visible) => {
    const tailSet: Set<number> = new Set();
    if (!visible) return tailSet;
    else {
      remarks.forEach(a => (a.aircraftId ? tailSet.add(a.aircraftId) : null));
      return tailSet;
    }
  }
);

export const getOperatorSelectOptions = createSelector<
  RootState,
  FilterByOperatingCompany[],
  number[],
  OptionsShape[]
>(
  getOperatingCompaniesMap,
  state => state.handoverRemarks.filters.operator,
  (companies, pickedIds) => {
    const list = companies.map(c => ({
      id: c.id,
      value: c.name,
      checked: pickedIds.includes(c.id),
    }));
    list.sort((a: OptionsShape, b: OptionsShape) =>
      ascending(a.value, b.value)
    );
    return list;
  }
);
export const getAcTypeSelectOptions = createSelector<
  RootState,
  Aircraft[],
  number[],
  number[],
  OptionsShape[]
>(
  getAvailableAircraftForMoveToTailMenu,
  state => state.handoverRemarks.filters.operator,
  state => state.handoverRemarks.filters.acTypes,
  (aircraft, pickedOperatorIds, pickedAcTypeIds) => {
    const acTypesMap = aircraft.reduce<{
      [companyId: number]: Omit<FilterByAircraftType, 'checked' | 'tails'>[];
    }>((acc, tail) => {
      const acType = {
        id: tail.aircraftTypeId,
        code: tail.aircraftTypeCode,
        name: tail.aircraftTypeName,
        rank: tail.aircraftTypeRank,
      };
      const accByOpCompany = acc[tail.operatingCompanyId] || [];
      return {
        ...acc,
        [tail.operatingCompanyId]: accByOpCompany.find(
          t => t.id === tail.aircraftTypeId
        )
          ? accByOpCompany // removing duplications
          : chain(accByOpCompany.concat(acType))
              .sortBy('code')
              .sortBy('rank')
              .value(),
      };
    }, {});
    const operatorsIds =
      pickedOperatorIds.length > 0
        ? pickedOperatorIds
        : Object.keys(acTypesMap).map(k => +k);
    const list = uniqBy(
      operatorsIds.reduce<OptionsShape[]>((acc, id) => {
        const availableAcTypes = acTypesMap[id];
        return acc.concat(
          availableAcTypes.map(acType => ({
            id: acType.id,
            value: acType.name,
            checked: pickedAcTypeIds.includes(acType.id),
          }))
        );
      }, []),
      c => c.id
    );
    list.sort((a: OptionsShape, b: OptionsShape) =>
      ascending(a.value, b.value)
    );
    return list;
  }
);

export const getTailsSelectOptions = createSelector<
  RootState,
  Aircraft[],
  FilterByOperatingCompany[],
  number[],
  number[],
  number[],
  OptionsShape[]
>(
  getAvailableAircraftForMoveToTailMenu,
  getOperatingCompaniesMap,
  state => state.handoverRemarks.filters.operator,
  state => state.handoverRemarks.filters.acTypes,
  state => state.handoverRemarks.filters.tail,
  (
    aircraft,
    allOperators,
    pickedOperatorIds,
    pickedAcTypeIds,
    pickedTailIds
  ) => {
    const operatorsIds =
      pickedOperatorIds.length > 0
        ? pickedOperatorIds
        : allOperators.map(k => k.id);
    const list = aircraft.reduce<OptionsShape[]>((acc, tail) => {
      if (
        (operatorsIds.includes(tail.operatingCompanyId) &&
          !pickedAcTypeIds.length) ||
        (pickedAcTypeIds.length > 0 &&
          pickedAcTypeIds.includes(tail.aircraftTypeId))
      ) {
        return acc.concat({
          id: tail.id,
          value: tail.tailNumber.trim(),
          checked: pickedTailIds.includes(tail.id),
        });
      }
      return acc;
    }, []);
    list.sort((a: OptionsShape, b: OptionsShape) =>
      ascending(a.value, b.value)
    );
    return list;
  }
);
export const getCsTeamSelectOptions = createSelector<
  RootState,
  CsTeamItem[],
  number[],
  OptionsShape[]
>(
  state => state.csTeam.csTeamList,
  state => state.handoverRemarks.filters.csTeam,
  (csTeamList, pickedIds) => {
    const list = csTeamList.map<OptionsShape>(item => ({
      id: item.id,
      value: item.teamName,
      checked: pickedIds.includes(item.id),
    }));
    list.sort((a: OptionsShape, b: OptionsShape) =>
      ascending(a.value, b.value)
    );
    const all: OptionsShape = {
      checked: !pickedIds.length,
      id: 0,
      value: 'All',
    };
    return [all, ...list];
  }
);

export const getRelatedRemarks = createSelector<
  RootState,
  Flight,
  number,
  HandoverRemark[],
  Flight[],
  { [id: number]: Airport },
  { [id: number]: Aircraft },
  AllRemarksShape['remarks'][]
>(
  state => state.handoverRemarks.flight,
  state => state.handoverRemarks.aircraftId,
  state => state.handoverRemarks.allRemarks,
  state => elementsSelector(state, 'flights'),
  state => state.airports.airportsById,
  getAllAvailableAircraftByIdMap,
  (
    selectedFlight,
    selectedAircraftId,
    allRemarks,
    visibleFlights,
    airportsMap,
    aircraftMap
  ) => {
    if (selectedFlight) {
      const { aircraftId } = selectedFlight;
      const { tailNumber } = aircraftMap[aircraftId];
      const remarks = allRemarks.filter(r => r.aircraftId === aircraftId);
      const title = `Tail# ${(tailNumber || 'N/A').toUpperCase()}`;
      return [
        {
          title,
          cards: remarks.sort(sortCards),
          aircraftId,
        },
      ];
    }
    if (selectedAircraftId) {
      const flightForAircraft = sortBy(
        visibleFlights.filter(f => f.aircraftId === selectedAircraftId),
        'start'
      );
      const now = utc().valueOf();
      return flightForAircraft.reduce<AllRemarksShape['remarks'][]>(
        (acc, flight) => {
          if (flight.departureUtcScheduled < now) return acc;
          const remarksForFlight = allRemarks.filter(
            r => r.flightLegId === flight.id
          );
          if (remarksForFlight.length > 0) {
            const { csCards, opsCards } = getDividedFlightRemarks(
              remarksForFlight
            );
            const cards = opsCards
              .sort(sortCards)
              .concat(csCards.sort(sortCards));
            return acc.concat({
              title: getFlightDetailsForHandoverTitle(
                flight,
                airportsMap,
                aircraftMap
              ),
              flight,
              cards,
            });
          }
          return acc;
        },
        []
      );
    }
    return [];
  }
);

export const getTailHandoverTooltipText = createCachedSelector<
  RootState,
  number,
  HandoverRemark[],
  number,
  string
>(
  state => state.handoverRemarks.allRemarks,
  (_, id) => id,
  (remarks, id) => {
    return remarks
      .filter(r => r.aircraftId === id)
      .sort(sortCards)
      .map(t => convertHtmlToText(t.remarks))
      .join('\n');
  }
)((_, id) => id);
