import { message } from 'antd';
import { ApolloQueryResult } from '@apollo/client';

import * as actions from '../actions';
import { Action } from 'typescript-fsa';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { RootState } from '../reducers';
import { createNoteMutation } from '../services/create-note';
import { deleteNoteMutation } from '../services/delete-note-service/delete-note-service';
import NoteTimelineSegmentDto from '../types/NoteTimelineSegmentDto';
import {
  CreateNoteMutation,
  DeleteNoteMutation,
} from '../types/operation-result-types';

function* createNoteSaga(action: Action<NoteTimelineSegmentDto>) {
  try {
    const response: ApolloQueryResult<CreateNoteMutation> = yield call(
      createNoteMutation,
      action.payload
    );
    yield put(
      actions.userSaveNote.done({
        params: action.payload,
        result: response,
      })
    );
  } catch (error) {
    yield put(actions.userSaveNote.failed({ params: action.payload, error }));
  }
}

function* deleteNoteSaga(action: Action<number>) {
  try {
    const response: ApolloQueryResult<DeleteNoteMutation> = yield call(
      deleteNoteMutation,
      action.payload
    );
    yield put(
      actions.userDeleteNote.done({
        params: action.payload,
        result: response.data,
      })
    );
    message.success('Note has been deleted');
  } catch (error) {
    yield put(actions.userDeleteNote.failed({ params: action.payload, error }));
  }
}
const isEditPayload = (
  payload: actions.EditNotePayload | actions.CreateNotePayload
): payload is actions.EditNotePayload =>
  (payload as actions.EditNotePayload).id !== undefined;

function* doCheckIfPossibleEditOrCreateNotesSaga(
  action: Action<actions.EditNotePayload | actions.CreateNotePayload>
) {
  const isEditNoteDisabled = yield select(
    (state: RootState) => state.eventElementCreationEdit.isEditDisabled
  );
  if (isEditNoteDisabled) {
    message.error(
      `Please try to ${
        isEditPayload(action.payload) ? 'edit' : 'create'
      } Note when WS connection is established`
    );
  } else {
    isEditPayload(action.payload)
      ? yield put(actions.doOpenNoteForEdit(action.payload))
      : yield put(actions.doCreateNote(action.payload));
  }
}

export function* notesSaga() {
  yield all([
    takeLatest(actions.userSaveNote.started, createNoteSaga),
    takeLatest(actions.userDeleteNote.started, deleteNoteSaga),

    takeLatest(
      actions.userClickOpenNote,
      doCheckIfPossibleEditOrCreateNotesSaga
    ),
    takeLatest(
      actions.userClickCreateNote,
      doCheckIfPossibleEditOrCreateNotesSaga
    ),
  ]);
}
