import { ApolloQueryResult } from '@apollo/client';
import { sortBy } from 'lodash';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';

import * as actions from '../actions';
import { handleGraphQlErrors } from '../components/error-handling/utils';
import { RootState } from '../reducers';
import { getOperatingCompaniesMap } from '../selectors';
import { getAcTypeFilterByOperatorMap } from '../selectors/nearest-aircraft';
import { getNearestAircraft } from '../services/nearest-aircraft-service/get-nearest-aircraft';
import Flight from '../types/flight';
import { nearestAircraftQuery } from '../types/operation-result-types';

function* getNearestAircraftSaga(action: Action<Flight>) {
  const {
    aircraftId,
    departureAirportId,
    departureUtcEstimated,
    departureUtcScheduled,
  } = action.payload;
  const icao = yield select(
    (state: RootState) =>
      state.airports.airportsById[departureAirportId]?.ICAO || ''
  );
  const dateMs = departureUtcEstimated || departureUtcScheduled;
  const params = { aircraftId, icao, dateMs };
  yield put(actions.doGetNearestAircraftData.started(params));

  try {
    const result: ApolloQueryResult<nearestAircraftQuery> = yield call(
      getNearestAircraft,
      params
    );
    if (result.errors) {
      const { errors } = result;
      handleGraphQlErrors(errors);
      yield put(
        actions.doGetNearestAircraftData.failed({
          error: result.errors[0],
          params,
        })
      );
    }
    if (result.data) {
      const {
        downgradeTableData,
        inFleetTableData,
        upgradeTableData,
      } = result.data.nearestAircraft;
      const operatingCompanies = yield select(state =>
        getOperatingCompaniesMap(state)
      );
      const mainOperatorIds = operatingCompanies.map(c => c.id);
      const downgradeTableDataSorted = sortBy(
        downgradeTableData,
        'ferryTimeMs'
      );
      const inFleetTableDataSorted = sortBy(inFleetTableData, 'ferryTimeMs');
      const upgradeTableDataSorted = sortBy(upgradeTableData, 'ferryTimeMs');
      const list = [
        ...downgradeTableDataSorted,
        ...inFleetTableDataSorted,
        ...upgradeTableDataSorted,
      ];
      const visibleAircraftIds = list.reduce((acc, el) => {
        if (mainOperatorIds.includes(el.operatingCompanyId)) {
          return acc.concat(el.aircraftId);
        }
        return acc;
      }, []);
      yield put(
        actions.doGetNearestAircraftData.done({
          params,
          result: {
            downgradeTableData: downgradeTableDataSorted,
            inFleetTableData: inFleetTableDataSorted,
            upgradeTableData: upgradeTableDataSorted,
            mainOperatorIds,
            visibleAircraftIds,
          },
        })
      );
    }
  } catch (e) {
    yield put(
      actions.doGetNearestAircraftData.failed({
        error: e,
        params,
      })
    );
  }
}

function* setNearestAircraftFilterConfigSaga() {
  const filterOptions = yield select((state: RootState) =>
    getAcTypeFilterByOperatorMap(state)
  );
  const acTypeFilterByOperatorMap = Object.keys(filterOptions).reduce<{
    [companyId: number]: number[];
  }>((acc, key) => {
    return { ...acc, [+key]: filterOptions[+key].map(f => f.id) };
  }, {});
  yield put(
    actions.doHydrateAircraftFilterData({
      acTypeFilterByOperatorMap,
    })
  );
}

export function* nearestAircraftSaga() {
  yield all([
    takeLatest(actions.userOpenNearestAircraftModal, getNearestAircraftSaga),
    takeLatest(
      actions.doGetNearestAircraftData.done,
      setNearestAircraftFilterConfigSaga
    ),
  ]);
}
