import {
  Auth0Client,
  Auth0ClientOptions,
  createAuth0Client,
  RedirectLoginOptions,
} from '@auth0/auth0-spa-js';
import { FC, createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AUTH_AUDIENCE,
  AUTH_SCOPE,
  AUTH_DOMAIN,
  AUTH_CLIENT_ID,
} from './constants/environment';
import { setUserToStore } from './utils/user-profile';
import { removeTokenPair, setAuth0Token } from './utils/tokenLocalStorage';

export const AUTH0_PARAMS: Auth0ClientOptions = {
  authorizationParams: {
    audience: AUTH_AUDIENCE,
    scope: AUTH_SCOPE,
    redirect_uri: window.location.origin,
  },
  domain: AUTH_DOMAIN,
  clientId: AUTH_CLIENT_ID,
};

type Auth0ContextType = {
  auth0Client: Auth0Client;
  isAuthenticated: boolean;
  loading: boolean;
  loginWithRedirect: (options?: RedirectLoginOptions) => Promise<void>;
  logout: () => void;
};

const Auth0Context = createContext<Auth0ContextType>({
  auth0Client: {} as Auth0Client,
  isAuthenticated: false,
  loading: true,
  loginWithRedirect: async () => {},
  logout: () => {},
});

export const useAuth0 = (): Auth0ContextType => useContext(Auth0Context);

export const Auth0Provider: FC<{}> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [auth0Client, setAuth0Client] = useState<Auth0Client | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const loginWithRedirect = async (
    options?: RedirectLoginOptions
  ): Promise<void> => {
    if (auth0Client) {
      await auth0Client.loginWithRedirect(options);
    }
  };

  const onRedirectCallback = (appState: any): void => {
    const redirectLink = appState?.returnTo || window.location.origin;
    const url = new URL(redirectLink);
    navigate(url.pathname);
  };

  const logout = (): void => {
    auth0Client?.logout({
      clientId: AUTH_CLIENT_ID,
      logoutParams: {
        federated: true,
        returnTo: window.location.origin,
      },
    });
    removeTokenPair();
  };

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(AUTH0_PARAMS);
      setAuth0Client(auth0FromHook);
      if (
        window.location.search.includes('code=') &&
        window.location.search.includes('state=')
      ) {
        try {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback(appState);
        } catch (e) {
          console.error(e);
          window.history.replaceState({}, document.title, '/');
          onRedirectCallback({});
        }
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();
      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        if (user) {
          const {
            realm_access: { roles },
          } = user;
          setUserToStore(user, roles);
        }
      }
      setLoading(false);
    };
    initAuth0();
  }, []);

  return (
    <Auth0Context.Provider
      value={{
        auth0Client,
        isAuthenticated,
        loading,
        loginWithRedirect,
        logout,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
