import { FC, useEffect, useState } from 'react';
import { Auth0Provider, useAuth0 } from './Auth0Wrapper';
import { Main } from './Main';
import { USE_AUTH } from './constants/environment';
import { setAuth0Token } from './utils/tokenLocalStorage';

const AppOnAuth0: FC = () => {
  const [hasToken, setHasToken] = useState<boolean>(false);
  const {
    auth0Client,
    isAuthenticated,
    loginWithRedirect,
    loading,
  } = useAuth0();
  // redirect to Auth0 Universal Login Page not authorized users
  useEffect(() => {
    if (loading || isAuthenticated) return;
    (async () => {
      await loginWithRedirect({
        appState: {
          returnTo: window.location.href,
        },
      });
    })();
  }, [isAuthenticated, loading, loginWithRedirect]);

  useEffect(() => {
    const getToken = async () => {
      try {
        const token = await auth0Client?.getTokenSilently();
        if (token) {
          setAuth0Token(token);
          setHasToken(true);
        }
      } catch (error) {
        if (
          error?.error === 'login_required' ||
          error?.error === 'consent_required'
        ) {
          if (auth0Client) {
            await auth0Client.loginWithRedirect({
              appState: {
                returnTo: window.location.origin,
              },
            });
          }
        }
        throw new Error('Failed to get access token');
      }
    };
    getToken();
  }, [auth0Client]);

  return isAuthenticated && hasToken ? <Main /> : null;
};

export const App = () => {
  return USE_AUTH ? (
    <Auth0Provider>
      <AppOnAuth0 />
    </Auth0Provider>
  ) : (
    <Main />
  );
};
