import { gql } from '@apollo/client';
import { apolloClient } from '../../data-processing/graphql';
import {
  AutoAdvertisementInput,
  SaveFerryAutoAdvertisementMutation,
  SaveFerryAutoAdvertisementMutationVariables,
} from '../../types/operation-result-types';

const saveFerryAutoAdvertisement = require('./save-Ferry-Auto-Advertisement.gql');

export function saveFerryAutoAdvertisementMutation(
  dto: AutoAdvertisementInput
) {
  return apolloClient.mutate<
    SaveFerryAutoAdvertisementMutation,
    SaveFerryAutoAdvertisementMutationVariables
  >({
    mutation: gql(saveFerryAutoAdvertisement),
    variables: { dto },
  });
}
