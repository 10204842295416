import { render } from 'react-dom';
import './styles.scss';
import Root, { subMiddle } from './root';
import * as actions from './actions';
import * as Keycloak from 'keycloak-js';
import {
  removeTokenPair,
  setKeycloakTokenPair,
  storageKey,
} from './utils/tokenLocalStorage';
import { setTrackEvent } from './utils/raygun-middleware';
import { ErrorDialog } from './components/error-handling/error-dialog';
import { setUserToStore } from './utils/user-profile';
import {
  DATADOG_APPLICATION_SERVICE,
  DATADOG_RUM_APPLICATION_ID,
  DATADOG_RUM_CLIENT_TOKEN,
  DATADOG_SITE,
  ENV_NAME,
  USE_AUTH,
} from './constants/environment';
import { datadogRum } from '@datadog/browser-rum';

//@ts-ignore
const buildNumber = BUILD_NUMBER || GIT_REVISION || '';

datadogRum.init({
  applicationId: DATADOG_RUM_APPLICATION_ID,
  clientToken: DATADOG_RUM_CLIENT_TOKEN,
  site: DATADOG_SITE,
  service: DATADOG_APPLICATION_SERVICE,
  env: ENV_NAME,
  version: buildNumber,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

datadogRum.startSessionReplayRecording();

export const keycloak = Keycloak({
  url: window.ENV.KEYCLOAK_URL,
  realm: window.ENV.KEYCLOAK_REALM,
  clientId: window.ENV.KEYCLOAK_CLIENT_ID,
});

function criticalSsoIssue(e): void {
  console.error(`Detected error:`, e);
  const alert = (
    <ErrorDialog
      title="SSO server is unreachable"
      content={`Error: ${e?.toString()}`}
      visible
    />
  );

  render(alert, window.document.getElementById('root'));
}

export async function funcRefreshToken() {
  if (!USE_AUTH) {
    try {
      await keycloak
        .updateToken(10)
        .then(refreshed => {
          if (refreshed) {
            console.debug('keycloak-updateToken', keycloak);
            setKeycloakTokenPair(keycloak);
          }
        })
        .catch(() => {
          const err = 'Something went wrong during token refresh!';
          setTrackEvent(err);
          removeTokenPair();
          keycloak.logout();
        });
    } catch (e) {
      criticalSsoIssue(e);
      throw e;
    }
  } else {
    return Promise.resolve('fake refresh');
  }
}

const doRender = Component => {
  render(<Component />, window.document.getElementById('root'));
};

if (USE_AUTH) {
  try {
    doRender(Root);
  } catch (e) {
    criticalSsoIssue(e);
  }
} else {
  const getInitOptions = (): Keycloak.KeycloakInitOptions => {
    const initOptions: Keycloak.KeycloakInitOptions = {
      checkLoginIframe: false,
      onLoad: 'check-sso',
      useNonce: false,
    };
    const value = localStorage.getItem(storageKey);
    if (value) {
      try {
        const parsedValue = JSON.parse(value);
        const { token, refreshToken } = parsedValue;
        initOptions.refreshToken = refreshToken;
        initOptions.token = token;
      } catch (err) {
        localStorage.removeItem(storageKey);
      }
    }
    console.debug('keycloak-init-options', initOptions);
    return initOptions;
  };
  const logout = () => {
    removeTokenPair();
    keycloak.logout();
  };
  keycloak.onAuthRefreshError = () => {
    logout();
  };
  keycloak.onAuthError = () => {
    logout();
  };
  keycloak.onTokenExpired = () => {
    funcRefreshToken();
  };
  try {
    keycloak
      .init(getInitOptions())
      .then(authed => {
        if (authed) {
          console.debug('keycloak', keycloak);
          setKeycloakTokenPair(keycloak);
          setUserToStore();
          subMiddle.on(actions.userLogout, () => {
            history.replaceState({}, document.title, '/');
            logout();
          });

          doRender(Root);
        } else {
          keycloak.login();
        }
      })
      .catch(e => {
        setTrackEvent(`failed init: error: ${e}`);
        criticalSsoIssue(e);
      });
  } catch (e) {
    setTrackEvent(`failed login process: error: ${e}`);
    criticalSsoIssue(e);
  }
}
