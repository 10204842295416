import { xor } from 'lodash';
import { utc } from 'moment';
import { createSelector } from 'reselect';
import { DAY_IN_MILLISECONDS, GENERATE_NEW_ID } from '../constants';
import { RootState } from '../reducers';
import { PeakDaysState } from '../reducers/peak-days';
import PeakDay, { BusyDays } from '../types/peak-day';
import { getBusyDaysFromEvent } from '../utils/peak-days';

export const getVisiblePeakDays = createSelector<
  RootState,
  number,
  number,
  PeakDay[],
  PeakDay[]
>(
  state =>
    utc(state.ui.transform.scaleX.invert(0))
      .subtract(1, 'd')
      .startOf('day')
      .valueOf(),
  state =>
    utc(state.ui.transform.scaleX.invert(state.ui.width))
      .endOf('day')
      .valueOf(),
  state => state.peakDays.peakDaysData,
  (startDay, endDay, peakDaysData) =>
    peakDaysData.filter(e => e.start < endDay && e.end > startDay && e.isActive)
);

export const getPeakDaysMap = createSelector<
  RootState,
  PeakDay[],
  { [id: number]: PeakDay }
>(
  state => state.peakDays.peakDaysData,
  list =>
    list.reduce<{ [id: number]: PeakDay }>((acc, event) => {
      acc[event.id] = event;
      return acc;
    }, {})
);

export const getPeakDayFromStateById = createSelector<
  RootState,
  number,
  { [id: number]: PeakDay },
  PeakDay | null
>(
  state => state.peakDays.eventId,
  getPeakDaysMap,
  (id, map) => {
    if (id && id !== GENERATE_NEW_ID) {
      return map[id];
    }
    if (id === GENERATE_NEW_ID) {
      const tomorrow =
        utc()
          .startOf('day')
          .valueOf() + DAY_IN_MILLISECONDS;
      return {
        end: tomorrow,
        id: GENERATE_NEW_ID,
        isActive: false,
        isAutoAdvertising: true,
        isCrewChange: false,
        lastUpdated: null,
        peakDayRegions: [],
        peakDayTypes: [],
        start: tomorrow,
        text: '',
        updatedBy: null,
      };
    }
    return null;
  }
);

export const getActivePeakDays = createSelector<
  RootState,
  PeakDay[],
  number,
  PeakDay[]
>(
  state => state.peakDays.peakDaysData,
  state => state.time.today,
  (events, today) =>
    events
      .filter(
        ev =>
          ev.isActive &&
          utc(today)
            .endOf('day')
            .valueOf() <= ev.end
      )
      .sort((a, b) => a.start - b.start)
);

export const getPeakDaysBusyDates = createSelector<
  RootState,
  PeakDay[],
  BusyDays
>(getActivePeakDays, events =>
  events.reduce((acc, e) => {
    const days = getBusyDaysFromEvent(e.end, e.start);
    return [...acc, ...days];
  }, [])
);

export const getBusyDatesWithoutCurrent = createSelector<
  RootState,
  BusyDays,
  PeakDay,
  BusyDays
>(getPeakDaysBusyDates, getPeakDayFromStateById, (busyDays, currentEvent) => {
  if (currentEvent) {
    const { id, end, start } = currentEvent;
    const days = getBusyDaysFromEvent(end, start);
    if (id !== GENERATE_NEW_ID) {
      return xor(busyDays, days);
    }
    return busyDays;
  }
  return busyDays;
});

export const getFilteredPeakDays = createSelector<
  RootState,
  PeakDay[],
  PeakDaysState['filters'],
  PeakDay[]
>(
  getActivePeakDays,
  state => state.peakDays.filters,
  (events, filters) =>
    events
      .filter(ev => {
        const days = getBusyDaysFromEvent(ev.end, ev.start);
        if (filters.from && filters.to) {
          return days.some(d => d >= filters.from && d < filters.to);
        }
        if (filters.from) {
          return days.some(d => d === filters.from) || ev.end > filters.from;
        }
        if (filters.to) {
          return days.some(d => d < filters.to);
        }
        return true;
      })
      .filter(item => {
        const isAutoAdvertising = item.isAutoAdvertising;
        if (
          filters.advertising.length === 0 ||
          filters.advertising.length === 2
        ) {
          return true;
        }
        if (isAutoAdvertising && filters.advertising.includes('Yes')) {
          return true;
        }
        if (!isAutoAdvertising && filters.advertising.includes('No')) {
          return true;
        }
        return false;
      })
      .filter(item => {
        const isCrewChange = item.isCrewChange;
        if (filters.crew.length === 0 || filters.crew.length === 2) {
          return true;
        }
        if (isCrewChange && filters.crew.includes('Yes')) {
          return true;
        }
        if (!isCrewChange && filters.crew.includes('No')) {
          return true;
        }
        return false;
      })
);
