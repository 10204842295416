import { PureComponent } from 'react';
import { DispatchProp, connect } from 'react-redux';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RootState } from '../../reducers';
import { getHoldAircraftFilterOptions } from '../../selectors';
import * as actions from '../../actions';
import { setDataTestEntityId } from '../../utils';
import { AnyAction } from 'redux';
interface Props {
  holdAircraftOptions: { id: number; tailNumber: string }[];
  visibleHoldAcIds: number[];
}
class HoldAircraftOptions extends PureComponent<
  Props & DispatchProp<AnyAction>
> {
  onChangeHoldTypeVisibility = (id: number) => (e: CheckboxChangeEvent) => {
    const { holdAircraftOptions, visibleHoldAcIds } = this.props;
    const holdAcIds = holdAircraftOptions.reduce((acc, ac) => {
      const marked = visibleHoldAcIds.includes(ac.id);
      if (ac.id === id) {
        return marked ? acc : acc.concat(ac.id);
      }
      return marked ? acc.concat(ac.id) : acc;
    }, []);

    this.props.dispatch(actions.userToggleHoldLineTypeView(holdAcIds));
  };
  render() {
    const { holdAircraftOptions, visibleHoldAcIds } = this.props;
    return (
      <div
        className="filter-bar-hold-ac-types unselectable"
        {...setDataTestEntityId('filter-bar-hold-ac-types')}
      >
        {holdAircraftOptions.map(acType => (
          <div key={acType.id}>
            <Checkbox
              onChange={this.onChangeHoldTypeVisibility(acType.id)}
              checked={visibleHoldAcIds.includes(acType.id)}
            >
              {acType.tailNumber}
            </Checkbox>
          </div>
        ))}
      </div>
    );
  }
}

const HoldAircraftOptionsConnected = connect(
  (state: RootState): Props => ({
    holdAircraftOptions: getHoldAircraftFilterOptions(state),
    visibleHoldAcIds: state.ui.visibleHoldAcIds,
  })
)(HoldAircraftOptions);

export default HoldAircraftOptionsConnected;
