import DatePicker from '../date-time-pickers/date-picker';
import { Moment, utc } from 'moment';
import { FC, useMemo } from 'react';
import { ChangeDaysRangeFilterPayload } from '../../../actions';
import { PickerProps } from 'antd/es/date-picker/generatePicker';

function getDateRangePickerValue(
  date: number | null,
  type: ChangeDaysRangeFilterPayload['type']
): Moment | null {
  if (!date) return null;
  return type === 'from' ? utc(date).startOf('day') : utc(date).endOf('day');
}
export interface StateDateRangePickerProps {
  date: number | null;
  type: ChangeDaysRangeFilterPayload['type'];
  title: string;
  containerClassName?: string;
  width?: number;
}
export interface DispatchDateRangePickerProps {
  onChangeRange: (payload: ChangeDaysRangeFilterPayload) => void;
}
export const DatePickerComponent: FC<StateDateRangePickerProps &
  DispatchDateRangePickerProps &
  PickerProps<Moment>> = ({
  containerClassName,
  date,
  disabledDate,
  onChangeRange,
  title,
  type,
  width,
}) => {
  const onChangeValue = (v: Moment) => {
    const value = v
      ? getDateRangePickerValue(v.valueOf(), type).valueOf()
      : null;
    onChangeRange({ type, value });
  };
  const pickerValue = useMemo(() => getDateRangePickerValue(date, type), [
    date,
    type,
  ]);

  return (
    <div className={containerClassName}>
      <label>{title}</label>
      <DatePicker
        value={pickerValue}
        onChange={onChangeValue}
        allowClear
        format="DD MMM'YY"
        disabledDate={disabledDate}
        style={{ width }}
      />
    </div>
  );
};
