import { CSSProperties, PureComponent } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { Button } from 'antd';
import { RootState } from '../../reducers';
import {
  CENTRIK_ACCESS_URL,
  CENTRIK_ACCIDENT_WORKFLOW_ID,
  CENTRIK_INCIDENT_WORKFLOW_ID,
} from '../../constants/environment';
import { getTokenFromLocalStorage } from '../../utils/tokenLocalStorage';
import { AnyAction } from 'redux';

interface StateProps {
  roles: string[];
}

class AccidentIncidentBox extends PureComponent<
  StateProps & DispatchProp<AnyAction>
> {
  warningButtonStyles: CSSProperties = {
    backgroundColor: '#faad14',
    borderColor: '#faad14',
    marginLeft: '8px',
  };
  openCentrick = (isAccident = true) =>
    window.open(
      `${CENTRIK_ACCESS_URL}?WorkflowDefinitionId=${
        isAccident ? CENTRIK_ACCIDENT_WORKFLOW_ID : CENTRIK_INCIDENT_WORKFLOW_ID
      }&token=${getTokenFromLocalStorage()}`,
      '_blank'
    );
  render() {
    return (
      this.props.roles.includes('AG-ECN-Launch-Emergency-Communication') && (
        <div className="accident-incident-box" style={{ display: 'flex' }}>
          <Button onClick={() => this.openCentrick()} type="primary" danger>
            Accident
          </Button>
          <Button
            type="primary"
            style={this.warningButtonStyles}
            onClick={() => this.openCentrick(false)}
          >
            Incident
          </Button>
        </div>
      )
    );
  }
}

export const AccidentIncidentBoxComponent = connect<StateProps>(
  (state: RootState) => ({
    roles: state.user.roles,
  })
)(AccidentIncidentBox);
