import Flight, { OperationalStatus } from '../types/flight';
import Maintenance from '../types/maintenance';
import { AirportMismatch } from '../types/airport-mismatch';
import { GroundTime } from '../types/ground-time';
import createCachedSelector from 're-reselect';
import { RootState } from '../reducers';
import { flattenPool } from '../data-processing/pool-structure';
import { groupBy, sortBy } from 'lodash';
import { FboMismatch } from '../types/fbo-mismatch';
import { HANDLING_ID } from '../constants';

type AirportAffectingElement = Flight | Maintenance;

function isMaintenance(e: AirportAffectingElement): e is Maintenance {
  return !!(e as Maintenance).maintenanceType;
}
const getElementTimeFrame = (d: AirportAffectingElement) => {
  // Because we will check these properties for existence
  const f = d as Flight;
  if (f.arrivalUtcBlock && f.departureUtcBlock)
    return [f.departureUtcBlock, f.arrivalUtcBlock];
  if (f.arrivalUtcEstimated && f.departureUtcEstimated)
    return [f.departureUtcEstimated, f.arrivalUtcEstimated];
  if (f.arrivalUtcScheduled && f.departureUtcScheduled)
    return [f.departureUtcScheduled, f.arrivalUtcScheduled];
  return [d.start, d.end];
};
const haveElementsMismatch = (
  prev: AirportAffectingElement,
  next: AirportAffectingElement
): boolean => {
  const startAirport = getStartAirportIdFromPrevious(prev);
  const endAirport = getEndAirportIdFromNext(next);
  const areAirportsDifferent = startAirport !== endAirport;
  const areBothNull = startAirport === null && endAirport === null;
  return areAirportsDifferent || areBothNull;
};
const haveFboMismatch = (
  prev: AirportAffectingElement,
  next: AirportAffectingElement
): boolean => {
  if (isMaintenance(prev) || isMaintenance(next))
    return haveElementsMismatch(prev, next);
  if (prev.requests?.length > 0 && next.requests?.length > 0) {
    const prevAgent = prev.requests.find(
      req =>
        req.serviceTypeId === HANDLING_ID &&
        req.airportId === prev.arrivalAirportId
    )?.serviceProviderId;
    const nextAgent = next.requests.find(
      req =>
        req.serviceTypeId === HANDLING_ID &&
        req.airportId === next.departureAirportId
    )?.serviceProviderId;
    return prevAgent !== nextAgent;
  }
  return false;
};
function traverseAircraftForAirportMismatch(
  elements: AirportAffectingElement[]
): AirportMismatch[] {
  const sorted = sortBy(elements, 'start');
  return sorted.reduce<{
    mms: AirportMismatch[];
    prev: AirportAffectingElement;
  }>((acc, currentElement) => {
    if (acc === null) return { mms: [], prev: currentElement };
    if (currentElement.end < acc.prev.end) {
      return acc;
    }
    if (
      haveElementsMismatch(acc.prev, currentElement) &&
      currentElement.start - acc.prev.end > 0
    )
      return {
        mms: acc.mms.concat(
          createMismatchBetweenElements(acc.prev, currentElement)
        ),
        prev: currentElement,
      };
    return { mms: acc.mms, prev: currentElement };
  }, null).mms;
}
function traverseAircraftForGroundTime(
  flights: AirportAffectingElement[]
): GroundTime[] {
  const sorted = sortBy(flights, 'start');
  return sorted.reduce<{
    groundTime: GroundTime[];
    prev: AirportAffectingElement;
  }>((acc, currentElement) => {
    if (acc === null) return { groundTime: [], prev: currentElement };
    if (currentElement.end < acc.prev.end) {
      return acc;
    }
    if (
      !haveElementsMismatch(acc.prev, currentElement) &&
      currentElement.start - acc.prev.end > 0
    )
      return {
        groundTime: acc.groundTime.concat(
          createGroundTimeBetweenElements(acc.prev, currentElement)
        ),
        prev: currentElement,
      };
    return { groundTime: acc.groundTime, prev: currentElement };
  }, null).groundTime;
}
function traverseAircraftForFBOMismatches(
  elements: AirportAffectingElement[]
): FboMismatch[] {
  const sorted = sortBy(elements, 'start');
  return sorted.reduce<{
    fboMm: FboMismatch[];
    prev: AirportAffectingElement;
  }>((acc, currentElement) => {
    if (acc === null) return { fboMm: [], prev: currentElement };
    if (currentElement.end < acc.prev.end) {
      return acc;
    }
    if (currentElement.start - acc.prev.end > 0) {
      const element = haveElementsMismatch(acc.prev, currentElement)
        ? createMismatchBetweenElements(acc.prev, currentElement)
        : createGroundTimeBetweenElements(acc.prev, currentElement);
      return {
        fboMm: acc.fboMm.concat({
          ...element,
          hasFBOMismatch: haveFboMismatch(acc.prev, currentElement),
        }),
        prev: currentElement,
      };
    }
    return { fboMm: acc.fboMm, prev: currentElement };
  }, null).fboMm;
}
function getStartAirportIdFromPrevious(lastElement: AirportAffectingElement) {
  if (isMaintenance(lastElement)) {
    return lastElement.airportId;
  }
  return lastElement.arrivalAirportId;
}
function getEndAirportIdFromNext(nextElement: AirportAffectingElement) {
  if (isMaintenance(nextElement)) {
    return nextElement.airportId;
  }
  return nextElement.departureAirportId;
}
function createMismatchBetweenElements(
  prev: AirportAffectingElement,
  next: AirportAffectingElement
): AirportMismatch {
  const [, prevEnd] = getElementTimeFrame(prev);
  const [nextStart] = getElementTimeFrame(next);
  const departureAirportId = getStartAirportIdFromPrevious(prev);
  const arrivalAirportId = getEndAirportIdFromNext(next);
  return {
    start: prevEnd,
    end: nextStart,
    id: prev.id,
    aircraftId: prev.aircraftId,
    departureAirportId,
    arrivalAirportId,
  };
}
function createGroundTimeBetweenElements(
  prev: AirportAffectingElement,
  next: AirportAffectingElement
): GroundTime {
  const [, prevEnd] = getElementTimeFrame(prev);
  const [nextStart] = getElementTimeFrame(next);
  const departureAirportId = getStartAirportIdFromPrevious(prev);
  return {
    start: prevEnd,
    end: nextStart,
    id: prev.id,
    aircraftId: prev.aircraftId,
    baseAirportId: departureAirportId,
  };
}
function getAirportMismatchForAllElements(
  flattenElements: AirportAffectingElement[]
): AirportMismatch[] {
  const grouped = groupBy(flattenElements, fl => fl.aircraftId);
  return Object.keys(grouped).reduce((acc, indexOfAircraft) => {
    return acc.concat(
      traverseAircraftForAirportMismatch(grouped[indexOfAircraft])
    );
  }, []);
}
function getAircraftGroundTimeForAllElements(
  flattenElements: AirportAffectingElement[]
): GroundTime[] {
  const grouped = groupBy(flattenElements, fl => fl.aircraftId);
  return Object.keys(grouped).reduce((acc, indexOfAircraft) => {
    return acc.concat(traverseAircraftForGroundTime(grouped[indexOfAircraft]));
  }, []);
}
function getAircraftFBOMismatchesForAllElements(
  flattenElements: AirportAffectingElement[]
): GroundTime[] {
  const grouped = groupBy(flattenElements, fl => fl.aircraftId);
  return Object.keys(grouped).reduce((acc, indexOfAircraft) => {
    return acc.concat(
      traverseAircraftForFBOMismatches(grouped[indexOfAircraft])
    );
  }, []);
}

export const getAirportAffectingElementsByType = createCachedSelector(
  (state: RootState, type) => state.timelineEvents.flights,
  (state: RootState, type) => state.timelineEvents.maintenances,
  (state: RootState, type) => state.ui.segmentsVisibility,
  (state: RootState, type) => state.opstatuses.statusMap,
  (state: RootState, type) => state.timelineEvents.groundTimeType,
  (state: RootState, type) => type,
  (
    flightsPool,
    maintenancesPool,
    segmentsVisibility,
    opstatusesMap,
    groundTimeType,
    elementsType
  ) => {
    const flightIgnorantOpStatuses = {
      [OperationalStatus.CANCELLED]: true,
      [OperationalStatus.QUOTED]: true,
      [OperationalStatus.NO_SHOW]: true,
      [OperationalStatus.OPTION]: !opstatusesMap.Option,
      [OperationalStatus.UNCONFIRMED]: !opstatusesMap.Unconfirmed,
      [OperationalStatus.CONFIRMED]: !opstatusesMap.Confirmed,
      [OperationalStatus.LANDED]: !opstatusesMap.Landed,
    };
    const flights: AirportAffectingElement[] = segmentsVisibility['flights']
      ? flattenPool(flightsPool).filter(
          f => !flightIgnorantOpStatuses[f.legOperationalStatusId]
        )
      : [];
    const elements =
      segmentsVisibility['maintenances'] && groundTimeType !== 'CPL'
        ? flights.concat(flattenPool(maintenancesPool))
        : flights;
    if (elementsType === 'groundTime') {
      return getAircraftGroundTimeForAllElements(elements);
    }
    if (elementsType === 'fboMismatches') {
      return getAircraftFBOMismatchesForAllElements(elements);
    }
    return getAirportMismatchForAllElements(elements);
  }
)((state, type) => type);
