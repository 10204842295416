import { ReferenceObject } from 'popper.js';
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';

import {
  DashboardWidgetPayload,
  userHoverAircraft,
  userHoverFlight,
  userHoverOverlap,
  userHoverSegment,
  userHoverTailBlock,
  userMarkFlight,
  userOpenContextMenuForMxEvent,
  userOpenFlightMenu,
  userOpenHandoverRemarksDrawer,
  userOpenNearestAircraftModal,
  userSelectDashboardWidget,
  userTimelineSelection,
} from '../actions';
import { HoveredTailPart } from '../components/aircraft/tooltip';
import EventElement, { OverlappedElements } from '../types/event-element';
import Flight from '../types/flight';
import Maintenance from '../types/maintenance';
import { PeakDaysSegmentType, SegmentType } from '../types/segment-types';

const cuttingSensitiveData: {
  [actionType: string]: (payload: any) => any;
} = {
  [userMarkFlight.started.type]: (payload: {
    flight: Flight;
    newDemo: boolean;
    newLocked: boolean;
    newSensitive: boolean;
  }) => ({
    ...payload,
    flight: payload.flight.id,
  }),
  [userMarkFlight.done.type]: payload => payload.result,
  [userOpenNearestAircraftModal.type]: (payload: Flight) => payload.id,
  [userOpenHandoverRemarksDrawer.type]: (payload: { isFor?: Flight }) =>
    payload?.isFor?.id,
  [userHoverFlight.type]: (payload: [Flight, HTMLElement]) => payload[0].id,
  [userHoverSegment.type]: (
    payload: [
      EventElement,
      HTMLElement | ReferenceObject,
      SegmentType | PeakDaysSegmentType
    ]
  ) => [payload[0].id, payload[2]],
  [userOpenFlightMenu.type]: (
    payload: [HTMLElement | ReferenceObject, Flight]
  ) => payload[1].id,
  [userHoverOverlap.type]: (
    payload: [OverlappedElements, HTMLElement | ReferenceObject]
  ) => ({
    aircraftId: payload[0].aircraftId,
    elementsIds: payload[0].elements.map(el => el.id),
  }),
  [userOpenContextMenuForMxEvent.type]: (
    payload: [ReferenceObject, Maintenance & EventElement]
  ) => [payload[1].id],
  [userHoverTailBlock.type]: (payload: {
    aircraftId: number;
    part: HoveredTailPart;
    ref: ReferenceObject;
  }) => ({
    aircraftId: payload.aircraftId,
    part: payload.part,
  }),
  [userSelectDashboardWidget.type]: (payload: DashboardWidgetPayload) =>
    payload.flight
      ? {
          ...payload,
          flight: payload.flight?.id,
        }
      : payload,
};

const skippedUserActions: { [actionType: string]: boolean } = {
  [userHoverAircraft.type]: true,
  [userTimelineSelection.type]: true,
};
export const raygunMiddleware: Middleware = <
  StoreType extends Dispatch<AnyAction>
>(
  store: MiddlewareAPI<StoreType>
) => next => action => {
  if (typeof (window as any).rg4js !== 'function') return next(action);
  const rg4js = (window as any).rg4js;
  const { type, payload } = (action as unknown) as {
    type: string;
    payload: any;
  };
  const isUserAction = type.includes('/USER_');
  const isSkippedAction = !!skippedUserActions[type];
  if (isUserAction && !isSkippedAction) {
    // it is user-produced action
    const recordPayload = cuttingSensitiveData[type]
      ? cuttingSensitiveData[type](payload)
      : payload;

    rg4js('recordBreadcrumb', type, recordPayload);
    setTrackEvent(
      `user action: ${type}${
        recordPayload ? `; payload: ${JSON.stringify(recordPayload)}` : ''
      }`
    );
  }
  return next(action);
};

export function setTrackEvent(name: string, type = 'pageView') {
  if (typeof (window as any).rg4js !== 'undefined') {
    try {
      const { pathname } = (window as any).location;
      const path = `${pathname}${name.toLocaleLowerCase().replace(/\//g, '_')}`;
      (window as any).rg4js('trackEvent', {
        path,
        type,
      });
      console.debug('User set trackEvent', path);
    } catch (error) {
      console.error('Raygun did not set trackEvent!!! \n Error:', error);
    }
  }
}

export function sendErrorToRaygun<E = Error>(
  error: E,
  url?: string,
  data?: string
): void {
  if (typeof (window as any).rg4js !== 'undefined') {
    try {
      (window as any).rg4js('send', {
        error,
        customData: {
          timestamp: new Date().toISOString(),
          url,
          data,
        },
      });
    } catch (err) {
      console.error(
        'Raygun failed to send error to crash report!!! \n Error:',
        err
      );
    }
  }
}
