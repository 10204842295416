import { CSSProperties, PureComponent } from 'react';
import { DispatchProp, connect } from 'react-redux';
import { RootState } from '../../reducers';
import { getStatsTextFlightsByRequiredDay } from '../../selectors/statistic';
import {
  getStatsTextByTypeMaintenance,
  getStatsTextByFlightAvailable,
  getStatsTextByTotalTails,
} from '../../selectors/statistic';
import { AnyAction } from 'redux';
interface StateProps {
  headerText: string;
  statsText: string;
}
interface OwnProps {
  styles?: CSSProperties;
}
const colors = {
  'Today Live/Ttl': 'rgba(0, 0, 0, 0.65)',
  'Tomorrow Live/Ttl': 'rgba(0, 0, 0, 0.65)',
  SMX: '#7373FF',
  UMX: '#A977DB',
  AOG: 'rgba(245, 34, 45, 0.85)',
  'FL AVAIL': '#7ED321',
  'Ttl A/C': 'rgba(0, 0, 0, 0.65)',
};
const statsKeysList = Object.keys(colors);
export class StatsTemplate extends PureComponent<
  StateProps & DispatchProp<AnyAction> & OwnProps
> {
  render() {
    const { headerText, statsText, styles = { minWidth: '30px' } } = this.props;
    return (
      <div
        style={{
          fontFamily: 'Roboto Condensed',
          ...styles,
        }}
      >
        <div
          style={{
            fontSize: 9,
            fontWeight: 500,
            lineHeight: '10px',
            color: colors[headerText],
          }}
        >
          {headerText}
        </div>
        <div
          style={{
            fontSize: 14,
            lineHeight: '16px',
            color: 'rgba(0, 0, 0, 0.65)',
          }}
        >
          {statsText}
        </div>
      </div>
    );
  }
}

export const TodayStats = connect((state: RootState) => {
  const { live, total } = getStatsTextFlightsByRequiredDay(state, 'Today');
  return {
    headerText: statsKeysList[0],
    statsText: `${live}/${total}`,
  };
})(StatsTemplate);

export const TomorrowStats = connect((state: RootState) => {
  const { live, total } = getStatsTextFlightsByRequiredDay(state, 'Tomorrow');
  return {
    headerText: statsKeysList[1],
    statsText: `${live}/${total}`,
  };
})(StatsTemplate);

export const SmxStats = connect((state: RootState) => ({
  headerText: statsKeysList[2],
  statsText: getStatsTextByTypeMaintenance(state, 'SMX'),
}))(StatsTemplate);

export const UmxStats = connect((state: RootState) => ({
  headerText: statsKeysList[3],
  statsText: getStatsTextByTypeMaintenance(state, 'UMX'),
}))(StatsTemplate);

export const AogStats = connect((state: RootState) => ({
  headerText: statsKeysList[4],
  statsText: getStatsTextByTypeMaintenance(state, 'AOG'),
}))(StatsTemplate);

export const FlightAvailableStats = connect((state: RootState) => ({
  headerText: statsKeysList[5],
  statsText: getStatsTextByFlightAvailable(state),
}))(StatsTemplate);

export const TotalAc = connect((state: RootState) => ({
  headerText: statsKeysList[6],
  statsText: getStatsTextByTotalTails(state),
}))(StatsTemplate);
