import { Slider } from 'antd';
import { duration } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { FC, useState, useEffect } from 'react';
import {
  userChangeMaxFerryRange,
  userChangeNextFlightTimeRange,
} from '../../../actions';
import { getRangeForSliderByKey } from '../../../selectors/nearest-aircraft';
import { formatNearestAcFerryTime } from '../utils';
import { isEqual } from 'lodash';

interface StateProps {
  className: 'next' | 'ferry';
  rangeValue: [number, number];
  step: number;
  timeFormatter: (value: number) => string;
  title: string;
}

interface DispatchProps {
  onChangeRange: (values: [number, number]) => void;
}

const NearestAircraftRangeSlider: FC<StateProps & DispatchProps> = ({
  className,
  onChangeRange,
  rangeValue,
  step,
  timeFormatter,
  title,
}) => {
  const disabled = useSelector<RootState, boolean>(
    state => state.nearestAircraft.loading
  );
  const minimized = useSelector<RootState, boolean>(
    state => state.nearestAircraft.minimized
  );
  const isVerticalMode = useSelector<RootState, boolean>(
    state => state.ui.isVerticalMode
  );
  const [tooltipVisible, setTooltipVisibility] = useState(false);

  useEffect(() => {
    let timeoutId = null;
    if (!disabled && !minimized) {
      timeoutId = setTimeout(() => {
        setTooltipVisibility(true);
      }, 1500);
    }
    return () => {
      setTooltipVisibility(false);
      clearTimeout(timeoutId);
    };
  }, [disabled, minimized]);
  const [values, setValues] = useState<[number, number]>([0, 0]);
  // important to set max value on componentDidMount to freeze the value and keep min on zero
  const [maxValue, setMaxValue] = useState<number>(0);
  useEffect(() => {
    const [, max] = rangeValue;
    if (max > 0) {
      setValues([0, max]);
      setMaxValue(max);
      onChangeRange([0, max]);
    }
  }, [rangeValue]);

  const onChange = (e: [number, number]) => {
    setValues(e);
  };

  const onAfterChange = (e: [number, number]) => {
    onChangeRange(e);
  };

  return (
    <div
      className={`nearest-ac-modal-body-header-filter-${className} unselectable`}
      style={{
        width:
          className === 'ferry'
            ? 'inherit'
            : isVerticalMode
            ? '378px'
            : '900px',
      }}
    >
      <div>{title}</div>
      <Slider
        disabled={disabled}
        max={maxValue}
        min={0}
        onChange={onChange}
        onAfterChange={onAfterChange}
        range
        step={step}
        tooltip={{
          autoAdjustOverflow: true,
          defaultOpen: false,
          destroyTooltipOnHide: true,
          formatter: timeFormatter,
          open: tooltipVisible,
          placement: 'bottom',
        }}
        value={values}
      />
    </div>
  );
};

export const MaxFerryRangeFilter: FC<{}> = () => {
  const rangeValue = useSelector<RootState, StateProps['rangeValue']>(
    state => getRangeForSliderByKey(state, 'ferryTimeMs'),
    isEqual
  );
  const timeFormatter = (value: number): string => {
    return formatNearestAcFerryTime(value);
  };
  const dispatch = useDispatch();
  const onChangeRange = (payload: [number, number]): void => {
    dispatch(userChangeMaxFerryRange(payload));
  };
  return (
    <NearestAircraftRangeSlider
      className="ferry"
      onChangeRange={onChangeRange}
      rangeValue={rangeValue}
      title="Ferry Time Range (HH:mm)"
      timeFormatter={timeFormatter}
      step={0.1}
    />
  );
};
export const NextFlightTimeFilter: FC<{}> = () => {
  const rangeValue = useSelector<RootState, StateProps['rangeValue']>(
    state => getRangeForSliderByKey(state, 'nextFlightTimeMs'),
    isEqual
  );
  const timeFormatter = (date: number): string =>
    `${Math.floor(duration(date).asHours())}`;
  const dispatch = useDispatch();
  const onChangeRange = (payload: [number, number]): void => {
    dispatch(userChangeNextFlightTimeRange(payload));
  };
  return (
    <NearestAircraftRangeSlider
      className="next"
      onChangeRange={onChangeRange}
      rangeValue={rangeValue}
      title="Next Flight Time (HH)"
      timeFormatter={timeFormatter}
      step={1}
    />
  );
};
