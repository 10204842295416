import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  userChangeSearchACQuery,
  userPinHandoverRemarksDrawer,
  userSelectDashboardWidget,
} from '../../../../actions';
import { parseRemarkTitleForLinks } from '../../utils';
import Flight from '../../../../types/flight';
import { RootState } from '../../../../reducers';
import { getAircraftIndexById } from '../../../../selectors';
import { App } from 'antd';

interface Props {
  flight?: Flight;
  forTail: boolean;
  title: string;
}

export const OneRemarkTitleComponent: FC<Props> = ({
  flight,
  forTail,
  title,
}) => {
  const dispatch = useDispatch();
  const aircraftIndexById = useSelector<RootState, number>(state =>
    getAircraftIndexById(state, flight?.aircraftId || 0)
  );
  const unPinned = useSelector<RootState, boolean>(
    state => state.handoverRemarks.unPinned
  );
  const { tailNumber, flightLegId, route, time } = parseRemarkTitleForLinks(
    title,
    !forTail
  );

  const onClickTail = () => {
    dispatch(
      userChangeSearchACQuery({
        query: tailNumber,
        isFromNearestAC: false,
        isFromHandover: true,
      })
    );
  };
  const { message } = App.useApp();
  const onClickFlightLeg = () => {
    if (aircraftIndexById === -1) {
      message.warning('Selected flight is hidden by the filter settings');
    } else {
      dispatch(
        userSelectDashboardWidget({
          type: 'Handover Remark Flights',
          id: +flightLegId,
          flight,
        })
      );
    }
  };

  const handleRightClick = (event: React.MouseEvent<HTMLOrSVGElement>) => {
    event.preventDefault();
    if (!unPinned) {
      dispatch(userPinHandoverRemarksDrawer(false));
    }
  };

  return (
    <>
      {forTail ? (
        <>
          <span>Tail# </span>
          <a onClick={onClickTail}>{`${tailNumber}`}</a>
        </>
      ) : (
        <>
          <a onClick={onClickTail}>{`${tailNumber}, `}</a>
          <span>{`${route}, `}</span>
          <a
            onClick={onClickFlightLeg}
            onContextMenu={handleRightClick}
          >{`Leg# ${flightLegId},`}</a>
          <span>{`${time}`}</span>
        </>
      )}
    </>
  );
};
