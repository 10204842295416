import { Button, Checkbox, Divider, Popover } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  userToggleNearestAcAircraftTypeView,
  userToggleNearestAcExactTailView,
  userToggleNearestAcOperatorView,
  userToggleNearestAcSelectAllView,
  userToggleNearestAcSubChartersView,
} from '../../../actions';
import { TailsByAcTypeOnOperatorList } from '../../../common/components/aircraft-filter/ac-types-list';
import { OperatingCompanyItem } from '../../../common/components/aircraft-filter/operator-item';
import { RootState } from '../../../reducers';
import {
  getFullFilterConfig,
  getOnlyMainFleet,
  getOperatingCompaniesFilter,
  getCachedAircraftFilter,
} from '../../../selectors/nearest-aircraft';
import {
  FilterByAircraftType,
  FilterByOperatingCompany,
  OperatingCompanyLabel,
} from '../../../types/aircraft';
import { setDataTestEntityId } from '../../../utils';
import {
  getAllAircraftOptionsAndIds,
  getCompanyAcTypeIdsAndAircraftIds,
} from '../../../utils/aircraft';
import { isEqual } from 'lodash';

const MainContent: FC<{
  classNamePrefix: string;
  company: FilterByOperatingCompany;
  index: number;
  operatingCompanies: FilterByOperatingCompany[];
}> = ({ classNamePrefix, company, index, operatingCompanies }) => {
  const dispatch = useDispatch();
  const filterByAircraftType = useSelector<RootState, FilterByAircraftType[]>(
    state => getCachedAircraftFilter(state, company.id),
    isEqual
  );
  const handleSelectCompany = (e: CheckboxChangeEvent, companyId: number) => {
    const {
      companiesAcTypeIds,
      companyAircraftIds,
    } = getCompanyAcTypeIdsAndAircraftIds(filterByAircraftType);
    dispatch(
      userToggleNearestAcOperatorView({
        companiesAcTypeIds,
        companyAircraftIds,
        companyId,
        checked: e.target.checked,
      })
    );
  };
  const handleSelectAcType = (
    event: CheckboxChangeEvent,
    payload: {
      companyId: number;
      acTypeId: number;
    }
  ) => {
    const { acTypeId, companyId } = payload;
    const type = filterByAircraftType.find(ac => ac.id === acTypeId);
    const aircraftIdsOnThisType = type.tails.map(t => t.id);
    const { checked } = event.target;
    dispatch(
      userToggleNearestAcAircraftTypeView({
        companyId,
        acTypeId,
        aircraftIdsOnThisType,
        checked,
        isToRemoveAcType: !checked,
      })
    );
  };
  const handleSelectTail = (
    event: CheckboxChangeEvent,
    payload: {
      companyId: number;
      acTypeId: number;
      acId: number;
    }
  ) => {
    const { acTypeId, companyId, acId } = payload;
    const aircraftIdsOnThisType =
      filterByAircraftType
        .find(ac => ac.id === acTypeId)
        ?.tails.filter(t => t.checked) || [];
    const { checked } = event.target;
    const isToRemoveAcType = !checked && aircraftIdsOnThisType.length === 1;
    dispatch(
      userToggleNearestAcExactTailView({
        companyId,
        acTypeId,
        aircraftIdsOnThisType: [acId],
        isToRemoveAcType,
        checked,
      })
    );
  };
  return (
    <div
      className={`${classNamePrefix}actypes-content-companies-column`}
      key={company.id}
    >
      <OperatingCompanyItem
        filterByAircraftType={filterByAircraftType}
        disabled={false}
        company={company}
        classNamePrefix={classNamePrefix}
        onSelectOperatingCompany={handleSelectCompany}
      />
      <Divider
        className={`${classNamePrefix}actypes-content-companies-divider`}
      />

      <TailsByAcTypeOnOperatorList
        company={company}
        classNamePrefix={classNamePrefix}
        filterByAircraftType={filterByAircraftType}
        onSelectAcType={handleSelectAcType}
        onSelectTail={handleSelectTail}
        isLastOne={operatingCompanies.length === index + 1}
      />
    </div>
  );
};

interface StateProps {
  acTypesByCompanyIdMap: {
    [id: number]: FilterByAircraftType[];
  };
  operatingCompanies: FilterByOperatingCompany[];
  showSubCharters: boolean;
  isAllSelected: boolean;
  isAllIndeterminate: boolean;
}

const NearestAcAircraftFilterContent: FC<{}> = () => {
  const dispatch = useDispatch();
  const {
    acTypesByCompanyIdMap,
    isAllIndeterminate,
    isAllSelected,
    operatingCompanies,
    showSubCharters,
  } = useSelector<RootState, StateProps>(state => {
    const {
      visibleAircraftIds,
      showSubCharters,
      loading,
      flight,
    } = state.nearestAircraft;
    if (flight && !loading) {
      const mainFleetLength = getOnlyMainFleet(state).length || 0;
      const pickedAcCount = visibleAircraftIds.length;
      const isAllSelected =
        mainFleetLength === pickedAcCount && showSubCharters;
      return {
        acTypesByCompanyIdMap: getFullFilterConfig(state),
        isAllSelected,
        isAllIndeterminate:
          !isAllSelected &&
          ((mainFleetLength !== pickedAcCount && pickedAcCount > 0) ||
            showSubCharters),
        operatingCompanies: getOperatingCompaniesFilter(state),
        showSubCharters,
      };
    }
    return {
      acTypesByCompanyIdMap: {},
      isAllSelected: true,
      isAllIndeterminate: false,
      operatingCompanies: [],
      showSubCharters,
    };
  }, isEqual);

  const handleSelectAll = (e: CheckboxChangeEvent) => {
    const { allOptionsMap, allAcIds } = getAllAircraftOptionsAndIds(
      acTypesByCompanyIdMap
    );
    dispatch(
      userToggleNearestAcSelectAllView({
        allOptionsMap,
        selected: e.target.checked,
        allAcIds,
      })
    );
  };
  const onSelectSubCharters = () =>
    dispatch(userToggleNearestAcSubChartersView());

  const classNamePrefix = 'nearest-ac-modal-body-header-filter-';
  return (
    <div className={`${classNamePrefix}actypes-content unselectable`}>
      <div className={`${classNamePrefix}actypes-content-first-row`}>
        <div className={`${classNamePrefix}actypes-content-first-row`}>
          Operating companies
        </div>
        <Checkbox
          onChange={handleSelectAll}
          checked={isAllSelected}
          indeterminate={isAllIndeterminate}
          className={`${classNamePrefix}actypes-content-first-row-select-all`}
          {...setDataTestEntityId('ac-type-filter-select-all')}
        >
          Select All
        </Checkbox>
      </div>
      <div className={`${classNamePrefix}actypes-content-companies`}>
        {operatingCompanies.map((company, i) => (
          <MainContent
            classNamePrefix={classNamePrefix}
            company={company}
            index={i}
            operatingCompanies={operatingCompanies}
            key={company.id}
          />
        ))}
      </div>
      <Divider className="nearest-ac-modal-body-header-filter-actypes-content-sub-divider" />
      <Checkbox
        onChange={onSelectSubCharters}
        checked={showSubCharters}
        {...setDataTestEntityId(`ac-type-filter-subcharters`)}
      >
        {OperatingCompanyLabel.subCharters}
      </Checkbox>
    </div>
  );
};

export const FilterByAircraft: FC<{}> = () => {
  return (
    <Popover
      content={<NearestAcAircraftFilterContent />}
      placement="bottom"
      trigger="hover"
    >
      <Button style={{ marginRight: '20px' }}>Filter By Aircraft</Button>
    </Popover>
  );
};
