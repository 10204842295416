import { utc } from 'moment';
import { CSSProperties } from 'react';
import { MxEventFormValues } from './interfaces';
import { ImportanceFactor } from '../../types/note';
import { NoteFormValues } from './interfaces';

export enum MxEventFormName {
  id = 'id', // hidden
  aircraftId = 'aircraftId', // hidden
  aircraftName = 'aircraftName',
  airportId = 'airportId', // hidden
  airportIcao = 'airportIcao',
  maintenanceTypeId = 'maintenanceTypeId',
  dispatchable = 'dispatchable',
  startTimeMs = 'startTimeMs',
  endTimeMs = 'endTimeMs',
  rtsSelected = 'rtsSelected',
  categoryName = 'categoryName',
  orderNumber = 'orderNumber',
  orderTypeId = 'orderTypeId',
  serviceProviderId = 'serviceProviderId',
  serviceProviderName = 'serviceProviderName',
  reason = 'reason',
  notSplit = 'notSplit',
  splitHours = 'splitHours',
}

export const spinnerStyles: CSSProperties = {
  margin: 'auto',
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 10,
};

export enum MxEventType {
  SMX = 'SMX',
  UMX = 'UMX',
  AOG = 'AOG',
}

// Category name and id is hardcoded
export enum MxEventCategoryName {
  Normal = 'Normal', // 0
  Provisional = 'Provisional', // 1
  Must = 'Must do', // 2
}
export enum MxEventCategoryId {
  Normal,
  Provisional,
  Must,
}

// normally returned from BE
export enum MxEventOrderTypeId {
  None,
  CampWorkOrder,
  ServiceOrder,
  PurchaseOrder,
}

export const initialMxEventFormValues: MxEventFormValues = {
  aircraftId: null,
  aircraftName: '',
  airportIcao: '',
  airportId: null,
  categoryName: MxEventCategoryName.Normal,
  dispatchable: false,
  endTimeMs: utc().valueOf(),
  id: null,
  maintenanceTypeId: null,
  notSplit: false,
  orderNumber: '',
  orderTypeId: null,
  reason: '',
  rtsSelected: false,
  serviceProviderId: null,
  serviceProviderName: '',
  splitHours: 36,
  startTimeMs: utc().valueOf(),
};

export enum FormId {
  createEditMxEvent = 'createEditMxEvent',
  createEditNote = 'createEditNote',
  emailTemplate = 'emailTemplate',
  peakDay = 'PeakDay',
}

export enum NoteFormName {
  id = 'id', // hidden
  aircraftId = 'aircraftId', // hidden
  aircraftName = 'aircraftName',
  crewNotAvailable = 'crewNotAvailable',
  fromDate = 'fromDate',
  importanceFactor = 'importanceFactor',
  message = 'message',
  toDate = 'toDate',
}

export const initialNoteFormValues: NoteFormValues = {
  aircraftId: null,
  aircraftName: '',
  crewNotAvailable: false,
  fromDate: utc().valueOf(),
  id: null,
  importanceFactor: ImportanceFactor.GENERAL,
  message: '',
  toDate: utc().valueOf(),
};
