import Maintenance, {
  MaintenanceCategoryType,
  MaintenanceType,
  MaintenanceOrderType,
} from '../../types/maintenance';

export const getMaintenanceBorderColor = (
  categoryId: number,
  orderType: MaintenanceOrderType,
  type: MaintenanceType,
  isDispatchable: boolean
): string => {
  if (categoryId === MaintenanceCategoryType.MUST_DO) return 'red';
  if (categoryId === MaintenanceCategoryType.PROVISIONAL) return 'white';
  switch (type) {
    case MaintenanceType.UNSCHEDULED: {
      if (isDispatchable) {
        return 'rgba(169,119,219,1)';
      }
      if (orderType === MaintenanceOrderType.SERVICE_ORDER)
        return 'rgba(206,245,92,1)';
      return 'rgba(91,21,163,1)';
    }
    case MaintenanceType.SCHEDULED: {
      if (isDispatchable) {
        return 'rgba(115,115,255,1)';
      }
      if (orderType === MaintenanceOrderType.SERVICE_ORDER)
        return 'rgba(206,245,92,1)';
      return 'rgba(36,36,252,1)';
    }
    case MaintenanceType.AOG: {
      if (isDispatchable) {
        return 'rgba(255,84,84,1)';
      }
      return 'rgba(156,0,0,1)';
    }
  }
};

export const getMaintenanceIsDispatchedGradient = (m: Maintenance) => {
  switch (m.maintenanceType) {
    case MaintenanceType.AOG:
      if (m.isDispatchable)
        return `url(#maintenanceIsDispatchedGradientForAOGDispatchable)`;
      return `url(#maintenanceIsDispatchedGradientForAOG)`;
    case MaintenanceType.UNSCHEDULED:
      return `url(#maintenanceIsDispatchedGradientForUMX)`;
    case MaintenanceType.SCHEDULED:
      return `url(#maintenanceIsDispatchedGradientForSMX)`;
  }
};

export const getMaintenanceSegmentColor = (
  orderType: MaintenanceOrderType,
  type: MaintenanceType,
  isDispatchable?: boolean
) => {
  switch (type) {
    case MaintenanceType.UNSCHEDULED: {
      if (isDispatchable) {
        return 'rgba(169,119,219,.4)';
      }
      if (orderType === MaintenanceOrderType.SERVICE_ORDER)
        return 'rgba(174,234,0,1)';
      return 'rgba(169,119,219,1)';
    }
    case MaintenanceType.SCHEDULED: {
      if (isDispatchable) {
        return 'rgba(115,115,255,.4)';
      }
      if (orderType === MaintenanceOrderType.SERVICE_ORDER)
        return 'rgba(174,234,0,1)';
      return 'rgba(115,115,255,1)';
    }
    case MaintenanceType.AOG: {
      if (isDispatchable) {
        return 'rgba(255,84,84,.4)';
      }
      return 'rgba(255,84,84,1)';
    }
  }
};

export const getMaintenanceSegmentBorderColor = (
  orderType: MaintenanceOrderType,
  type: MaintenanceType
) => {
  switch (type) {
    case MaintenanceType.SCHEDULED: {
      if (orderType === MaintenanceOrderType.SERVICE_ORDER)
        return 'rgba(174,234,0,1)';
      return 'rgba(115,115,255,1)';
    }
    case MaintenanceType.UNSCHEDULED: {
      if (orderType === MaintenanceOrderType.SERVICE_ORDER)
        return 'rgba(174,234,0,1)';
      return 'rgba(169,119,219,1)';
    }
    case MaintenanceType.AOG: {
      return 'rgba(255,84,84,1)';
    }
  }
};

export const getMaintenanceTooltipColor = (type: MaintenanceType) => {
  switch (type) {
    case MaintenanceType.SCHEDULED: {
      return 'rgb(115,115,255)';
    }
    case MaintenanceType.UNSCHEDULED: {
      return 'rgb(169,119,219)';
    }
    case MaintenanceType.AOG: {
      return 'rgb(255, 84, 84)';
    }
  }
};

export const getMaintenanceTextBackgroundColor = (m: Maintenance) => {
  if (!m.isDispatchable) {
    return 'transparent';
  }
  const type = m.maintenanceType;
  switch (type) {
    case MaintenanceType.SCHEDULED: {
      return '#BBBBFE';
    }
    case MaintenanceType.UNSCHEDULED: {
      return '#CCB3ED';
    }
    case MaintenanceType.AOG: {
      return '#F7838D';
    }
  }
};
