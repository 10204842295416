import { duration, utc } from 'moment';
import Flight from '../../types/flight';
import { NearestDurationKey } from '../../types/nearest-aircraft';
import { universalDateAndTimeFormatter } from '../../utils/time';
import {
  DEFAULT_MODAL_HEADER_HEIGHT,
  DEFAULT_TABLE_HEADER_HEIGHT,
  MODAL_VERTICAL_PADDING,
  TABLE_COUNT,
  TABLE_TITLE_HEIGHT,
} from './constants';

export const getNearestAcTitle = (
  flight: Flight,
  tailNumber: string,
  flightLocation: string
): string => {
  const { departureUtcEstimated, departureUtcScheduled } = flight;
  const firstLine = `Tail #: ${tailNumber} Location: ${flightLocation}`;
  const secondLine = `Selected flight ${
    departureUtcEstimated ? 'E' : 'S'
  }TD: ${universalDateAndTimeFormatter(
    utc(departureUtcEstimated || departureUtcScheduled),
    'dayMonthYear'
  )} ${universalDateAndTimeFormatter(
    utc(departureUtcEstimated || departureUtcScheduled),
    'timeUtc'
  )}`;
  return `${firstLine}\n${secondLine}`;
};

export const formatNearestAcDuration = (args: {
  date: number;
  flightTime: number;
  key: NearestDurationKey;
}): string => {
  const { date, key, flightTime } = args;
  if (!date) return key === 'lastFlightTimeMs' ? 'N/A' : '';
  const difference =
    key === 'lastFlightTimeMs' ? flightTime - date : date - flightTime;
  const momentDuration = duration(difference); // is mutable!
  const days = Math.floor(momentDuration.asDays());
  const hours = Math.floor(momentDuration.subtract(days, 'days').asHours());
  const minutes = Math.floor(
    momentDuration.subtract(hours, 'hours').asMinutes()
  );
  return `${days}d ${hours}h ${minutes}m`;
};

export const getTableBodyHeight = (
  isVerticalMode: boolean,
  bodyHeight: number
) => {
  const modalHeaderElement = document
    .querySelector('.nearest-ac-modal-body-header')
    ?.getBoundingClientRect()?.height;
  const modalHeaderHeight = modalHeaderElement || DEFAULT_MODAL_HEADER_HEIGHT;
  const tableHeaderElements = document.querySelectorAll('.ant-table-thead');
  const headersHeight = [];
  tableHeaderElements?.forEach(el => {
    return headersHeight.push(el.clientHeight);
  });
  const tableHeaderHeight =
    Math.max(...headersHeight, 0) ||
    DEFAULT_TABLE_HEADER_HEIGHT(isVerticalMode);
  const tableTopHeight = TABLE_TITLE_HEIGHT + tableHeaderHeight;

  return Math.floor(
    (Math.floor(bodyHeight) -
      MODAL_VERTICAL_PADDING * 2 -
      modalHeaderHeight -
      tableTopHeight * TABLE_COUNT) /
      TABLE_COUNT
  );
};

export const formatNearestAcFerryTime = (ferryTimeMs: number): string => {
  const dur = duration(ferryTimeMs);
  const durationInHours = Math.floor(dur.asHours());
  const hoursString =
    durationInHours < 10 ? `0${durationInHours}` : `${durationInHours}`;
  const durationInMinutes = Math.floor(
    dur.subtract(durationInHours, 'hours').asMinutes()
  );
  const minutesString =
    durationInMinutes < 10 ? `0${durationInMinutes}` : `${durationInMinutes}`;
  return `${hoursString}:${minutesString}`;
};
