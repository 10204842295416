import { FC, MouseEvent } from 'react';
import Aircraft from '../../types/aircraft';
import { AircraftTogglerState } from '../../reducers/aircraft';
import CrewIcon from '../svg-icons/aircraft/CrewIcon';
import NoteIcon from '../svg-icons/aircraft/Note';
import { isSubCharter } from '../../common/aircraft/aircraft-check-status';

const IconToggler: FC<{
  active: boolean;
  onClick: (event: MouseEvent<HTMLDivElement>) => void;
  title: string;
}> = ({ active, onClick, title, children }) => (
  <div
    className={`aircraft-toggle-icon-wrapper ${active ? 'active' : ''}`}
    onClick={onClick}
    title={title}
  >
    {children}
  </div>
);

interface Props {
  aircraft: Aircraft;
  baseCompaniesIds: number[];
  aircraftTogglerState: AircraftTogglerState;
  aircraftComponentNode: React.MutableRefObject<HTMLDivElement> | null;
  onClickElementsToggler: (
    type: 'crew' | 'notes',
    value: boolean,
    aircraftId: number,
    top: number
  ) => void;
}

export const ToggleIcons: FC<Props> = ({
  aircraft,
  baseCompaniesIds,
  aircraftTogglerState,
  aircraftComponentNode,
  onClickElementsToggler,
}) => {
  const onClick = (e, type: 'crew' | 'notes') => {
    e.stopPropagation();
    onClickElementsToggler(
      type,
      !(aircraftTogglerState && aircraftTogglerState[type]),
      aircraft.id,
      aircraftComponentNode?.current?.getBoundingClientRect().top
    );
  };
  return (
    !isSubCharter(aircraft, baseCompaniesIds) && (
      <div className="aircraft-toggle-icons">
        <IconToggler
          onClick={e => onClick(e, 'crew')}
          active={aircraftTogglerState && aircraftTogglerState.crew}
          title="Toggle Crew"
        >
          <CrewIcon />
        </IconToggler>
        <IconToggler
          onClick={e => onClick(e, 'notes')}
          active={aircraftTogglerState && aircraftTogglerState.notes}
          title="Toggle General Notes"
        >
          <NoteIcon />
        </IconToggler>
      </div>
    )
  );
};
