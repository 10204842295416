import { PureComponent } from 'react';

interface StateProps {
  fill?: string;
  width?: number;
  height?: number;
  strokeWidth?: number;
}

export default class DurationArrow extends PureComponent<StateProps> {
  render() {
    const {
      width = 84,
      fill = '#000000',
      height = 16,
      strokeWidth = 1,
    } = this.props;
    return (
      <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox={`0 0 ${width} ${height}`}
        version="1.1"
        className="tooltip-common-dates-date-duration-arrow"
      >
        <g
          stroke="none"
          strokeWidth={strokeWidth}
          fill="none"
          fillRule="evenodd"
          fillOpacity="0.09"
        >
          <g transform="translate(-122.000000, -4763.000000)" fill={fill}>
            <g transform="translate(4.000000, 4614.000000)">
              <polygon points="118 149 192 149 202 157.000055 192 165 118 165 128 157.000055" />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
