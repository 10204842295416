import { utc } from 'moment';
import {
  all,
  takeLatest,
  delay,
  put,
  call,
  select,
  spawn,
} from 'redux-saga/effects';
import * as actions from '../actions';
import { elementsSaga, getSegmentElementsSaga } from './elements';
import { fetchCommonDataMappers } from './mappers-common';
import { SegmentType } from '../types/segment-types';
import { RootState } from '../reducers';
import { getTimeSegments } from '../utils/time';
import { getNotFetchedDays } from '../reducers/timeline-events';
import { hasPermission } from '../utils/check-permissions';
import { getSegmentsWithDayRangesForNonFetchedDays } from '../common/sagas-common';
import { mxEventSaga } from './maintenance';
import { peakDaySaga } from './peak-day';
import { handoverRemarksSaga } from './handover';
import { notesSaga } from './notes';
import { nearestAircraftSaga } from './nearestAircraft';

function* scrollingDebounce() {
  yield delay(300);
  yield put(actions.doStopScroll());
}

function* fetchAllSegmentsSaga() {
  const startingRunning = new Date().valueOf();
  const { hasExistPermissions, notFetchedDays } = yield select(
    (state: RootState) => ({
      notFetchedDays: !state.timelineEvents.isReIniting
        ? getTimeSegments(
            getNotFetchedDays(
              state.timelineEvents.fetchedDaysMap,
              utc(
                utc(state.ui.transform.scaleX.invert(0))
                  .subtract(1, 'd')
                  .startOf('day')
                  .valueOf()
              ),
              utc(
                utc(state.ui.transform.scaleX.invert(state.ui.width))
                  .endOf('day')
                  .valueOf()
              )
            )
          )
        : [],
      hasExistPermissions: {
        emptyLegOffers: hasPermission(state, 'AG-Timeline-View-EL-Offer'),
        oneWayOffers: hasPermission(state, 'AG-Timeline-View-OW-Offer'),
      },
    })
  );
  yield put(
    actions.doSegmentsFetchingByDaysRange.started({
      fetchedDays: notFetchedDays,
      totalSegmentsFetchingByDaysRangeLoadingTime: 0,
    })
  );
  try {
    yield all([
      ...getSegmentsWithDayRangesForNonFetchedDays(
        notFetchedDays,
        hasExistPermissions
      ).map(
        (params: { segmentType: SegmentType; dayRange: [number, number] }) =>
          call(getSegmentElementsSaga, params)
      ),
    ]);
    yield put(
      actions.doSegmentsFetchingByDaysRange.done({
        params: null,
        result: {
          totalSegmentsFetchingByDaysRangeLoadingTime:
            new Date().valueOf() - startingRunning,
        },
      })
    );
  } catch (error) {
    yield put(
      actions.doSegmentsFetchingByDaysRange.failed({
        fetchedDays: notFetchedDays,
      } as any)
    );
  }
}

function* loadMoreSegmentsSaga() {
  const hasInitialLoadingDone = yield select(
    (state: RootState) => state.timelineEvents.hasInitialLoadingDone
  );
  if (hasInitialLoadingDone) yield delay(300);
  yield spawn(fetchAllSegmentsSaga);
}

function* commonSaga() {
  yield all([
    takeLatest(actions.timelineMounted, fetchCommonDataMappers),
    takeLatest([actions.userZoomHor, actions.userZoomVer], scrollingDebounce),
  ]);
}

export default function* rootSaga() {
  yield all([
    commonSaga(),
    elementsSaga(),
    nearestAircraftSaga(),
    notesSaga(),
    mxEventSaga(),
    peakDaySaga(),
    handoverRemarksSaga(),
    takeLatest(actions.userZoomHor, loadMoreSegmentsSaga),
  ]);
}
