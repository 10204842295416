import { PureComponent } from 'react';
import '../../styles.scss';
import { connect } from 'react-redux';
import { RootState } from '../../../../reducers';
import * as actions from '../../../../actions';
import { subMiddle } from '../../../../root';
import Popper from 'popper.js';
import { SegmentType } from '../../../../types/segment-types';
import EventElement from '../../../../types/event-element';
import Airport from '../../../../types/airport';
import { CrewRoster } from '../../../../types/crew-roster';
import { sortBy } from 'lodash';
import { setDataTestEntityId } from '../../../../utils';

interface ConnectedProps {
  type: SegmentType;
  segmentData: EventElement;
  airportsById: { [id: number]: Airport };
}
interface OwnProps {}

interface Props extends ConnectedProps, OwnProps {}

class TooltipCrew extends PureComponent<Props> {
  popper: Popper;
  createPopper = action => {
    const tooltipWrapper = document.getElementsByClassName(
      'tooltip-crew'
    )[0] as HTMLDivElement;
    const actionRef = action.payload[1];
    if (tooltipWrapper && actionRef) {
      this.popper = new Popper(actionRef, tooltipWrapper, {
        placement: 'auto-end',
        modifiers: {
          preventOverflow: { enabled: true },
          computeStyle: { gpuAcceleration: false },
        },
        positionFixed: true,
      });
    }
  };
  removePopper = action => {
    if (this.popper) {
      this.popper.destroy();
      delete this.popper;
    }
  };
  componentDidMount() {
    window.addEventListener('resize', this.removePopper);
    subMiddle.on(actions.userHoverCrew, this.createPopper);
    subMiddle.on(actions.userZoomHor, this.removePopper);
    subMiddle.on(actions.userZoomVer, this.removePopper);
    subMiddle.on(actions.userCloseTooltipCrew, this.removePopper);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.removePopper);
  }

  renderCrewElements(): React.ReactNode {
    const { segmentData } = this.props;
    const { team } = segmentData as CrewRoster;
    const teamArray = sortBy(team, c => c.role.rank);
    return teamArray.map((c, index) => (
      <tr key={`${c.crewMemberCode}-${index}`}>
        <td>{c.role.name}</td>
        <td
          className="tooltip-crew-table-code"
          {...setDataTestEntityId('tooltip-crew-table-code')}
        >
          {c.crewMemberCode}
        </td>
        <td
          className="tooltip-crew-table-fullname"
          {...setDataTestEntityId('tooltip-crew-table-fullname')}
        >
          {c.person && `${c.person.firstName || ''} ${c.person.lastName || ''}`}
        </td>
      </tr>
    ));
  }

  render() {
    return (
      (this.props.type == 'crewRoster' ||
        this.props.type == 'crewAssignment') && (
        <div className="tooltip-crew">
          <table className="tooltip-crew-table">
            <tbody>{this.renderCrewElements()}</tbody>
          </table>
        </div>
      )
    );
  }
}

export default connect((state: RootState) => ({
  type: state.ui.hoveredSegment,
  segmentData: state.ui.dataForSegment,
  airportsById: state.airports.airportsById,
}))(TooltipCrew);
