import './styles.scss';
import FavIcon from '../../../../assets/icon/favicon-32x32.png';
import { FC, useEffect, useState } from 'react';
import { CloseOutlined, MinusOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import {
  userCloseNearestAircraftModal,
  userToggleNearestAircraftModalSize,
} from '../../actions';
import { getNearestAcTitle } from './utils';
import { getAircraftById } from '../../selectors';
import { NearestAircraftTables } from './tables';
import { getICAOLabelFromAirport } from '../../utils/airport';
import { FilterByAircraft } from './filters/byAircraft';
import {
  MaxFerryRangeFilter,
  NextFlightTimeFilter,
} from './filters/rangeSlider';
import { MODAL_VERTICAL_PADDING } from './constants';
import { isEqual } from 'lodash';

interface StateProps {
  hasFlight: boolean;
  height: number;
  minimized: boolean;
  title: string;
  width: number;
}

export const NearestAircraftModal: FC<{}> = () => {
  const dispatch = useDispatch();
  const onClose = () => dispatch(userCloseNearestAircraftModal());
  const onMinimize = () => dispatch(userToggleNearestAircraftModalSize());
  const { height, title, hasFlight, width, minimized } = useSelector<
    RootState,
    StateProps
  >(state => {
    const { flight } = state.nearestAircraft;
    if (flight) {
      const flightLocation = getICAOLabelFromAirport(
        state.airports.airportsById[flight.departureAirportId]
      );
      return {
        height: state.ui.height,
        title: getNearestAcTitle(
          flight,
          getAircraftById(state, flight.aircraftId).tailNumber,
          flightLocation
        ),
        hasFlight: Boolean(state.nearestAircraft.flight),
        minimized: state.nearestAircraft.minimized,
        width: state.ui.width,
      };
    }
    return {
      height: state.ui.height,
      title: 'N/A',
      hasFlight: false,
      minimized: false,
      width: state.ui.width,
    };
  }, isEqual);
  const [bodyHeight, setBodyHeight] = useState(height);
  const [modalWidth, setModalWidth] = useState(width);
  useEffect(() => {
    if (title !== 'N/A') {
      setBodyHeight(height - (height * 10) / 100);
      setModalWidth(width - (width * 5) / 100);
    }
    return () => {
      setBodyHeight(height);
      setModalWidth(width);
    };
  }, [title, height, width]);
  return (
    <Modal
      className="nearest-ac"
      closable
      destroyOnClose={!hasFlight}
      footer={
        <div className="nearest-ac-modal-footer">
          <div className="nearest-ac-modal-footer-legend">
            Double click on the row will redirect to the tail on the timeline or
            to the exact flight (if it's presented).
            <div>
              <span>Red row</span> - tail under Maintenance
            </div>
          </div>
          <Button onClick={onClose}>Close</Button>
        </div>
      }
      closeIcon={<></>}
      onCancel={onClose}
      title={
        <div className="nearest-ac-modal-header">
          <div>
            <img src={FavIcon} />
            <span className="nearest-ac-modal-header-title">Nearest A/C</span>
          </div>
          <div className="nearest-ac-modal-header-buttons">
            <Button
              icon={<MinusOutlined />}
              title="Minimize"
              onClick={onMinimize}
            />
            <Button icon={<CloseOutlined />} title="Close" onClick={onClose} />
          </div>
        </div>
      }
      open={hasFlight && !minimized}
      width={modalWidth}
      style={{
        top: '30px',
      }}
      styles={{
        body: {
          height: `${bodyHeight}px`,
          maxHeight: `${bodyHeight}px`,
          padding: `${MODAL_VERTICAL_PADDING}px 24px`,
        },
      }}
    >
      <div className="nearest-ac-modal-body-header">
        <div className="nearest-ac-modal-body-header-text">{title}</div>
        <FilterByAircraft />
        <MaxFerryRangeFilter />
        <NextFlightTimeFilter />
      </div>
      <NearestAircraftTables bodyHeight={bodyHeight} />
    </Modal>
  );
};
