import * as d3S from 'd3-selection';
import { times } from 'lodash';
import { SegmentsAppenderArgs } from '..';
import { OperationalStatus } from '../../../../../../types/flight';
import { getLaneHeightKoef } from '../../../../../../reducers/ui';
import { isOperationalLegBusinessType } from '../../../../../../common/flight/flight-check-status';

const vjUSId = 6;

export function appendUsaFlaggedFlights(argsObject: SegmentsAppenderArgs) {
  const {
    selection,
    segmentsVisibility,
    positionMap,
    togglersState,
    scaleX,
    firstLineHeight,
    secondLineHeight,
  } = argsObject;
  const that = this;
  selection
    .filter(d => {
      const estimated =
        Boolean(d.arrivalUtcEstimated) || Boolean(d.departureUtcEstimated);
      return (
        d.baseCompanyId === vjUSId &&
        isOperationalLegBusinessType(d) &&
        estimated &&
        d.legOperationalStatusId !== OperationalStatus.NO_SHOW &&
        d.legOperationalStatusId !== OperationalStatus.CANCELLED &&
        d.legOperationalStatusId !== OperationalStatus.OPTION
      );
    })
    .append('g')
    .each(function(d) {
      const node = this;
      const laneKoef = getLaneHeightKoef(
        segmentsVisibility,
        togglersState[d.aircraftId],
        positionMap
      );
      const x = scaleX(d.departureUtcScheduled) || 0;
      const y = d.y + firstLineHeight / laneKoef;
      const w =
        //@ts-ignore
        that.calculateWidthFor(
          d.departureUtcScheduled,
          d.arrivalUtcScheduled,
          scaleX
        ) || 0;
      const cantonWidth = w * 0.4;
      const h = secondLineHeight / laneKoef;
      const cantonHeight = h * 0.5;
      const d3Node = d3S.select(node);
      d3Node
        .selectAll('rect')
        .data(times(3))
        .enter()
        .append('rect')
        .attr('fill', '#B22234')
        .attr('x', x)
        .attr('y', index => y + ((index * 2) / 5) * h)
        .attr('width', w)
        .attr('height', h / 5);
      d3Node
        .append('rect')
        .attr('x', x)
        .attr('y', y)
        .attr('height', cantonHeight)
        .attr('width', cantonWidth)
        .attr('fill', '#3C3B6E');
    });
}
