interface StateProps {
  kx: number;
  ky: number;
}

interface Props {
  color: string;
  patternIdName: string;
}

export const BasicMaintenancePattern = (props: StateProps & Props) => {
  const sideLength = Math.min(Math.min(10 / props.kx, 10 / props.ky), 10);
  return (
    <pattern
      id={props.patternIdName}
      width={sideLength}
      height={sideLength}
      patternUnits="userSpaceOnUse"
      patternTransform={`rotate(${(Math.atan(props.ky / props.kx) / Math.PI) *
        180})`}
      preserveAspectRatio="none"
    >
      <line stroke={props.color} strokeWidth={sideLength} y2={10} />
    </pattern>
  );
};
