import { PureComponent } from 'react';

interface Props {
  color?: string;
}

export default class LockedIcon extends PureComponent<Props> {
  render() {
    return (
      <svg width="7px" height="8px" viewBox="0 0 10 12">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            transform="translate(-772.000000, -159.000000)"
            fill={this.props.color || 'white'}
            fillRule="nonzero"
          >
            <g transform="translate(736.000000, 149.000000)">
              <path d="M44.7827584,14.8826672 L44.4231197,14.8826672 L44.4216637,13.241334 C44.4202077,11.4533341 42.8316821,9.99866758 40.8791498,10.0000009 C38.9266174,10.0013342 37.3380918,11.4560008 37.3395479,13.2440007 L37.3424599,14.8853339 L37.2143295,14.8853339 C36.543101,14.8866672 35.9985469,15.3853338 36.0000029,16.0000005 L36.0000029,20.8866668 C36.0000029,21.5013334 36.5460131,22 37.2172416,22 L44.7856705,21.996 C45.456899,21.996 46.0014531,21.4973334 45.9999971,20.8826668 L45.9999971,15.9946671 C45.9999971,15.3800005 45.4539869,14.8826672 44.7827584,14.8826672 Z M38.6470602,14.8853339 L38.6441481,13.2440007 C38.6441481,12.1146674 39.6458948,11.1960008 40.8791498,11.1946675 C42.1124047,11.1946675 43.1156074,12.1133341 43.1156074,13.241334 L43.1185194,14.8826672 L38.6470602,14.8853339 Z" />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
