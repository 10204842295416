import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { doGetCsTeamList } from '../actions';
import { CsTeamItem } from '../types/csTeam';

export interface CsTeamState {
  csTeamList: CsTeamItem[];
  csTeamIdByName: { [id: string]: number };
  loading: boolean;
}
export const initialState: CsTeamState = {
  csTeamList: [],
  csTeamIdByName: {},
  loading: false,
};

const getCsTeamMaps = (
  data: CsTeamItem[]
): Pick<CsTeamState, 'csTeamIdByName'> =>
  data.reduce(
    (acc, item) => {
      return {
        ...acc,
        csTeamIdByName: {
          ...acc.csTeamIdByName,
          [item.teamName.trim().toLocaleLowerCase()]: item.id,
        },
      };
    },
    {
      csTeamIdByName: {},
    }
  );

export default reducerWithInitialState(initialState)
  .case(doGetCsTeamList.started, state => ({
    ...state,
    loading: true,
  }))
  .case(doGetCsTeamList.done, (state, payload) => {
    const { csTeamIdByName } = getCsTeamMaps(payload.result);
    return {
      ...state,
      csTeamList: payload.result,
      csTeamIdByName,
      loading: false,
    };
  })
  .case(doGetCsTeamList.failed, state => ({
    ...state,
    loading: false,
  }));
