import { PureComponent } from 'react';
import { flatten } from 'lodash';
import { generate } from 'shortid';
import { setDataTestEntityId } from '../../../utils';
import { DescriptionWarningType } from '../../../types/descriptions-content';

interface Props {
  descriptions: string[];
  warningType: DescriptionWarningType;
}
export class DescriptionsContent extends PureComponent<Props> {
  render() {
    const { descriptions, warningType } = this.props;
    const updatedDescriptions = flatten(
      descriptions.map(des => des && des.split('\n'))
    );
    return updatedDescriptions.map(item => (
      <p
        key={generate()}
        className={`${warningType}-text`}
        {...setDataTestEntityId(`tooltip-aircraft-${warningType}-text`)}
      >
        {item}
      </p>
    ));
  }
}
