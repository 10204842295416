import { ContentState, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import '../../styles.scss';
import { FC, useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { Button, Popconfirm, Radio, RadioChangeEvent, message } from 'antd';
import {
  HandoverRemark,
  HandoverType,
} from '../../../../types/handover-remarks';
import { prepareEditorStateForSave } from '../../utils';
import { HANDOVER_REMARK_CARD_WIDTH } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { doResetPushedId } from '../../../../actions';
import { RootState } from '../../../../reducers';
import { hasPermission } from '../../../../utils/check-permissions';
import { DEFINE_TYPE_WARNING, EMPTY_REMARK_WARNING } from '../../constants';

export const HandoverCardEditor: FC<{
  aircraftId?: number;
  id: string;
  description: string;
  disabledHandoverType: boolean;
  handoverType: HandoverType;
  wasUpdated: boolean;
  onCancel: () => void;
  onCancelUpdate: (id: string) => void;
  onChangeHandoverType: (e: RadioChangeEvent) => void;
  onSave: (text: HandoverRemark['remarks']) => void;
  onUpdateConfirm: (id: string) => void;
  setCardText: (text: HandoverRemark['remarks']) => void;
}> = ({
  aircraftId,
  description,
  disabledHandoverType,
  id,
  handoverType,
  onCancel,
  onCancelUpdate,
  onChangeHandoverType,
  onSave,
  onUpdateConfirm,
  setCardText,
  wasUpdated,
}) => {
  const dispatch = useDispatch();
  const hasOpsEditPermission = useSelector<RootState, boolean>(state =>
    hasPermission(state, 'AG-Timeline-Handover-Ops_Edit')
  );
  const hasCsEditPermission = useSelector<RootState, boolean>(state =>
    hasPermission(state, 'AG-Timeline-Handover-CS_Edit')
  );
  const isTypeSwitcherVisible =
    !aircraftId && hasCsEditPermission && hasOpsEditPermission;
  const [isVisiblePopconfirm, setIsVisiblePopconfirm] = useState<boolean>(
    false
  );
  const [isValidText, setValidText] = useState<boolean>(true);
  const [isValidType, setValidType] = useState<boolean>(true);
  const [editorState, setEditorState] = useState<EditorState>(
    description
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(description).contentBlocks,
            htmlToDraft(description).entityMap
          )
        )
      : EditorState.createEmpty()
  );
  useEffect(() => {
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          htmlToDraft(description).contentBlocks,
          htmlToDraft(description).entityMap
        )
      )
    );
  }, [description]);
  useEffect(() => {
    if (wasUpdated) {
      setIsVisiblePopconfirm(true);
    }
  }, [wasUpdated]);
  const onConfirmPopconfirm = () => {
    onUpdateConfirm(id);
    setIsVisiblePopconfirm(false);
    dispatch(doResetPushedId(id));
  };
  const onCancelPopconfirm = () => {
    onCancelUpdate(id);
    setIsVisiblePopconfirm(false);
    dispatch(doResetPushedId(id));
  };
  const onChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
    setValidText(true);
  };
  const onClickSave = (): void => {
    const text = prepareEditorStateForSave(editorState);
    if (!text.replace(/&nbsp;/g, ' ').trim()) {
      message.warning(EMPTY_REMARK_WARNING);
      setValidText(false);
    } else if (isTypeSwitcherVisible && !handoverType) {
      message.warning(DEFINE_TYPE_WARNING);
      setValidType(false);
    } else {
      onSave(text);
      onCancelPopconfirm();
      dispatch(doResetPushedId(id));
    }
  };
  const onBlur = () => {
    const text = prepareEditorStateForSave(editorState);
    // allows save from confirmation modal
    setCardText(text);
  };
  const onCancelEdit = () => {
    onCancel();
    onCancelPopconfirm();
    dispatch(doResetPushedId(id));
  };
  const onChangeRadio = (e: RadioChangeEvent) => {
    onChangeHandoverType(e);
    setValidType(true);
  };
  return (
    <div className="remarks-card-editor-container">
      <Popconfirm
        title="Remark was updated by another person. Would you like to get updates?"
        open={isVisiblePopconfirm}
        onConfirm={onConfirmPopconfirm}
        onCancel={onCancelPopconfirm}
        okText="Yes"
        cancelText="No"
      >
        <Editor
          editorState={editorState}
          editorClassName="handover-editor"
          editorStyle={{
            width: HANDOVER_REMARK_CARD_WIDTH,
            border: `1px solid ${isValidText ? 'rgba(0, 0, 0, 0.15)' : 'red'}`,
            borderRadius: '4px',
            margin: '0',
            padding: '2px 4px',
          }}
          onEditorStateChange={onChange}
          onBlur={onBlur}
          toolbar={{
            options: ['inline', 'colorPicker', 'remove'],
            inline: {
              options: ['bold', 'italic', 'underline', 'strikethrough'],
            },
          }}
          wrapperStyle={{
            position: 'relative',
          }}
          toolbarStyle={{
            padding: '0',
            border: 'none',
            marginLeft: '12px',
            position: 'absolute',
            bottom: '-40px',
          }}
          stripPastedStyles
        />
      </Popconfirm>
      <div className="remarks-card-editor-container-buttons">
        {isTypeSwitcherVisible ? (
          <Radio.Group
            buttonStyle="solid"
            size="small"
            value={handoverType}
            style={{
              marginRight: '28px',
              border: !isValidType ? '1px solid red' : 'inherit',
            }}
            disabled={disabledHandoverType}
            onBlur={onBlur}
          >
            {Object.values(HandoverType).map(v => (
              <Radio.Button key={v} value={v} onChange={onChangeRadio}>
                {v}
              </Radio.Button>
            ))}
          </Radio.Group>
        ) : null}
        <Button size="small" onClick={onCancelEdit}>
          Cancel
        </Button>
        <Button type="primary" size="small" onClick={onClickSave}>
          Save
        </Button>
      </div>
    </div>
  );
};
