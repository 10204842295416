import { SegmentType } from '../../types/segment-types';
import { RootState } from '../../reducers';
import { GrainRole } from '../../utils/check-permissions';
import { SEGMENT_SERVICES } from '../../sagas/elements';

type Days = { start: number; end: number }[];

export const getSegmentsWithDayRangesForNonFetchedDays = (
  notFetchedDays: Days,
  hasExistPermissions: {
    emptyLegOffers: (state: RootState, role: GrainRole) => boolean;
    oneWayOffers: (state: RootState, role: GrainRole) => boolean;
  }
) =>
  notFetchedDays.reduce(
    (acc, { start, end }) =>
      acc.concat(
        Object.keys(SEGMENT_SERVICES)
          .filter(segmentType => {
            switch (segmentType) {
              case 'emptyLegOffers':
              case 'oneWayOffers': {
                return hasExistPermissions[segmentType];
              }
              default: {
                return true;
              }
            }
          })
          .map((segmentType: SegmentType) => ({
            segmentType,
            dayRange: [start, end],
          }))
      ),
    []
  );
