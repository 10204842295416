import { gql } from '@apollo/client';
import { apolloClient } from '../../data-processing/graphql';

const deleteNote = require('./delete-note-mutation.gql');

export function deleteNoteMutation(id: number) {
  return apolloClient.mutate<any>({
    mutation: gql(deleteNote),
    variables: { id },
  });
}
