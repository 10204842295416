import { axiosAuthed as axios } from '../axios-config';
import { transformBaseCompanyToFE } from './base-company-model';

export const getBaseCompanies = () =>
  axios
    .get('/integration/timeline/base-companies')
    .then(resp => resp.data.map(transformBaseCompanyToFE))
    .catch(e => {
      throw e;
    });
