import { connect } from 'react-redux';
import { RootState } from '../../../reducers';
import { getAircraftById } from '../../../selectors';
import { getDraggingFlights } from '../../../selectors/flights';
import { FlightGhost } from './flight-ghost';
import { HOLD_FLIGHT_HEIGHT } from '../../../constants';
import { utc } from 'moment';
import Aircraft from '../../../types/aircraft';

export interface FlightGhostStateProps {
  width: number;
  height: number;
  offset: {
    top: number;
    left: number;
  };
  currentTail: string;
  newTail: string;
}
export interface FlightGhostOwnProps {
  id: number;
  selectedAircraft: Aircraft;
  index?: number;
}

const zeroProps: FlightGhostStateProps = {
  currentTail: '',
  height: 0,
  newTail: '',
  offset: {
    left: 0,
    top: 0,
  },
  width: 0,
};
export const FlightGhostConnected = connect(
  (state: RootState, ownProps: FlightGhostOwnProps): FlightGhostStateProps => {
    const flight = getDraggingFlights(state).find(fl => fl.id === ownProps.id);
    if (!flight) {
      return zeroProps;
    }
    const { start, end, aircraftId } = flight;
    const { transform, positionMap, holdAircraftPositionMap } = state.ui;
    const top =
      (ownProps.index - Object.keys(holdAircraftPositionMap).length) *
        state.ui.rowHeight *
        state.ui.transform.ky +
      state.ui.transform.translateY;
    return {
      width: transform.scaleX(utc(end)) - transform.scaleX(utc(start)),
      height: transform.ky * positionMap.flights,
      offset: {
        top,
        left: transform.scaleX(utc(start)),
      },
      currentTail: getAircraftById(state, aircraftId)?.tailNumber,
      newTail: ownProps.selectedAircraft?.tailNumber,
    };
  }
)(FlightGhost);

export const HoldFlightGhostConnected = connect(
  (state: RootState, ownProps: FlightGhostOwnProps): FlightGhostStateProps => {
    const flight = getDraggingFlights(state).find(fl => fl.id === ownProps.id);
    if (!flight) {
      return zeroProps;
    }
    const { start, end, aircraftId } = flight;
    const newTail = ownProps.selectedAircraft;
    const { transform, holdAircraftPositionMap, holdLineHeight } = state.ui;
    const top = holdAircraftPositionMap[newTail.id]?.y1 ?? holdLineHeight;
    return {
      width: transform.scaleX(utc(end)) - transform.scaleX(utc(start)),
      height: HOLD_FLIGHT_HEIGHT,
      offset: {
        top,
        left: transform.scaleX(utc(start)),
      },
      currentTail: getAircraftById(state, aircraftId)?.tailNumber,
      newTail: newTail?.tailNumber,
    };
  }
)(FlightGhost);
