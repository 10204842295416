import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Checkbox, Tooltip, Button } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FC } from 'react';
import { setDataTestEntityId } from '../../../utils';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { getAllAircraftCheckBoxState } from '../../../selectors';

interface StateProps {
  isEditModeAcTypes: boolean;
  isGroupedByOperator: boolean;
  showLabels: boolean;
  checkBoxState: {
    indeterminate: boolean;
    isAllSelected: boolean;
    pickedAcCount: number;
  };
}
interface Props {
  onSelectAll: (e: CheckboxChangeEvent) => void;
  onToggleGroupedBy: () => void;
  onToggleLabelOperators: () => void;
  onToggleSaveEditState: () => void;
  onUserResetChanges: () => void;
}

export const AircraftFilterHeader: FC<Props> = ({
  onSelectAll,
  onToggleGroupedBy,
  onToggleLabelOperators,
  onToggleSaveEditState,
  onUserResetChanges,
}) => {
  const {
    checkBoxState,
    isEditModeAcTypes,
    isGroupedByOperator,
    showLabels,
  } = useSelector<RootState, StateProps>(state => {
    const { isGroupedByOperator, showLabels } = state.aircraft;
    return {
      isGroupedByOperator,
      showLabels,
      isEditModeAcTypes: state.ui.isEditModeAcTypes,
      checkBoxState: getAllAircraftCheckBoxState(state),
    };
  }, isEqual);
  const { indeterminate, isAllSelected, pickedAcCount } = checkBoxState;

  return (
    <div className="filter-bar-actypes-content-first-row">
      <div
        {...setDataTestEntityId('ac-type-filter-aircraft-count')}
      >{`Aircraft count: ${pickedAcCount}`}</div>
      <Checkbox
        disabled={isEditModeAcTypes}
        onChange={onSelectAll}
        checked={isAllSelected}
        indeterminate={indeterminate}
        className="filter-bar-actypes-content-first-row-select-all"
        {...setDataTestEntityId('ac-type-filter-select-all')}
      >
        Select All
      </Checkbox>
      <Tooltip
        title={
          isGroupedByOperator && !isEditModeAcTypes
            ? 'Default sorting will be applied'
            : null
        }
        destroyTooltipOnHide
      >
        <Checkbox
          checked={isGroupedByOperator}
          disabled={isEditModeAcTypes}
          onChange={onToggleGroupedBy}
          {...setDataTestEntityId('ac-type-filter-grouped-by')}
        >
          Group By Operator
        </Checkbox>
      </Tooltip>
      <Checkbox
        checked={showLabels}
        disabled={!isGroupedByOperator || isEditModeAcTypes}
        onChange={onToggleLabelOperators}
        {...setDataTestEntityId('ac-type-filter-label-operators')}
      >
        Label operators
      </Checkbox>
      <Tooltip
        title={
          !isEditModeAcTypes
            ? 'Edit Ac Type position is available only along with Group by operator functionality'
            : null
        }
        placement="right"
        destroyTooltipOnHide
      >
        <Button
          className="filter-bar-actypes-content-button"
          size="small"
          icon={isEditModeAcTypes ? <SaveOutlined /> : <EditOutlined />}
          disabled={!isGroupedByOperator && !isEditModeAcTypes}
          onClick={onToggleSaveEditState}
        >
          {isEditModeAcTypes ? 'Save preset' : 'Edit'}
        </Button>
      </Tooltip>
      {isEditModeAcTypes && (
        <Button
          className="filter-bar-actypes-content-button"
          size="small"
          onClick={onUserResetChanges}
        >
          Cancel
        </Button>
      )}
    </div>
  );
};
