import { CSSProperties, FC, useRef } from 'react';
import Aircraft from '../../types/aircraft';
import './styles.scss';
import * as Color from 'color';
import { AircraftTogglerState } from '../../reducers/aircraft';
import {
  getAircraftIndexMapExcludingHolding,
  getFoundAircraftBySearch,
} from '../../selectors';
import { RootState } from '../../reducers';
import { useSelector } from 'react-redux';
import { ToggleIcons } from './toggle-icons';
import AircraftContent from './content';
import DarkenedBox from './darkened-box';
import { openAircraftModule, setDataTestEntityId } from '../../utils';
import { isEqual } from 'lodash';

interface OwnProps {
  hovered: boolean;
  aircraft: Aircraft;
  transformKy: number;
  rowHeight: number;
  planeBlockWidth: number;
  index: number;
  baseCompaniesIds: number[];
  isUnavailableForAdvertisement: boolean;
  aircraftTogglerState: AircraftTogglerState;
  hasTailHandover: boolean;
  onClickElementsToggler: (
    type: 'crew' | 'notes',
    value: boolean,
    aircraftId: number,
    top: number
  ) => void;
}
interface StateProps {
  aircraftListBySearch: Aircraft[];
  aircraftIndexMap: { [aircraftId: number]: number };
}

export const AircraftComponent: FC<OwnProps> = ({
  aircraft,
  aircraftTogglerState,
  baseCompaniesIds,
  hasTailHandover,
  hovered,
  index,
  isUnavailableForAdvertisement,
  onClickElementsToggler,
  planeBlockWidth: width,
  rowHeight,
  transformKy,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { id, hasAogDrivers } = aircraft;
  const { aircraftIndexMap, aircraftListBySearch } = useSelector<
    RootState,
    StateProps
  >(
    state => ({
      aircraftListBySearch: getFoundAircraftBySearch(state),
      aircraftIndexMap: getAircraftIndexMapExcludingHolding(state),
    }),
    isEqual
  );
  const openTailProfile = () => {
    openAircraftModule(id);
  };

  const aircraftStyle: CSSProperties = {
    height: rowHeight * transformKy,
    width,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: hovered ? 'black' : 'transparent',
    position: 'relative',
    boxShadow: hasAogDrivers ? ' inset 0px 0px 0px 1px red' : 'none',
  };
  const isDarkened =
    aircraftListBySearch.length &&
    !aircraftListBySearch.some(a => aircraftIndexMap[a.id] === index);
  return (
    <DarkenedBox isDarkened={isDarkened}>
      <div
        className="aircraft"
        {...setDataTestEntityId(
          `aircraft-item${isDarkened ? '-darkened' : ''}`
        )}
        style={{
          display: 'flex',
          alignItems: 'stretch',
          width,
          backgroundColor: Color(
            aircraft.aircraftTypeColour ? aircraft.aircraftTypeColour : 'orange'
          )
            .alpha(0.4)
            .lighten(index % 2 == 0 ? 0.2 : 0)
            .toString(),
          ...aircraftStyle,
        }}
        ref={ref}
        onClick={openTailProfile}
      >
        <ToggleIcons
          aircraft={aircraft}
          baseCompaniesIds={baseCompaniesIds}
          aircraftTogglerState={aircraftTogglerState}
          aircraftComponentNode={ref}
          onClickElementsToggler={onClickElementsToggler}
        />
        <AircraftContent
          aircraft={aircraft}
          isUnavailableForAdvertisement={isUnavailableForAdvertisement}
          baseCompaniesIds={baseCompaniesIds}
          hasTailHandover={hasTailHandover}
        />
      </div>
    </DarkenedBox>
  );
};
