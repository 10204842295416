import './styles.scss';
import { FC, useEffect, useState } from 'react';
import { userToggleSidebar } from '../../actions';
import { MenuUnfoldOutlined } from '@ant-design/icons';
import { DispatchProp, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import LoadingBar from 'react-redux-loading-bar';
import { UserProfileComponent } from '../topbar/user-profile';
import { DashboardConnected } from '../topbar/dashboard';
import { TimesTopBar } from './timeline-bar-times';
import { ENV_NAME } from '../../constants/environment';
import { setDataTestEntityId } from '../../utils';
import { AccidentIncidentBoxComponent } from '../topbar/accident-incident-box';
import { ContactSupportTeam } from '../topbar/contact-support-team';
import { isEqual } from 'lodash';

const TagComponent: FC<{ envName: string }> = ({ envName }) => {
  if (!envName || envName === 'PROD') {
    return null;
  }
  return (
    <div className={`timelinebar-center-sticked-env-name ${envName}`}>
      {envName}
    </div>
  );
};

interface StateProps {
  user: string;
  height: number;
}

export const TimelineBar: FC<{
  isNotPermitted: boolean;
}> = ({ isNotPermitted }) => {
  const dispatch = useDispatch();
  const { height, user } = useSelector<RootState, StateProps>(
    state => ({
      height: state.ui.timelineBarHeight,
      user: state.user.userName,
    }),
    isEqual
  );
  const [envName, setEnvName] = useState<string>(ENV_NAME);
  useEffect(() => {
    setEnvName(ENV_NAME);
  }, []);
  const toggleSideBar = () => dispatch(userToggleSidebar());
  return (
    <>
      <LoadingBar />
      <div className="timelinebar" style={{ height }}>
        <div className="timelinebar-left-sticked">
          <MenuUnfoldOutlined
            className="trigger-unfold"
            onClick={toggleSideBar}
          />
        </div>
        <div
          className="timelinebar-center-sticked"
          {...setDataTestEntityId('timelinebar-header-title')}
        >
          Timeline
          <TagComponent envName={envName} />
        </div>
        <div className="timelinebar-right-sticked">
          <AccidentIncidentBoxComponent />
          <ContactSupportTeam />
          {!isNotPermitted && <DashboardConnected />}
          <UserProfileComponent />
          <div className="timelinebar-user-name">{user}</div>
          <TimesTopBar />
        </div>
      </div>
    </>
  );
};
