import { PureComponent } from 'react';
import * as actions from '../../../../actions';
import './styles.scss';
import { connect } from 'react-redux';
import { MainContentConnected } from './main-content';
import { RootState } from '../../../../reducers';
import { getDraggingFlights } from '../../../../selectors/flights';
import { getAircraftById } from '../../../../selectors';
import { Dispatch } from 'redux';
import { Action } from 'typescript-fsa';
interface StateProps {
  isModalVisible: boolean;
}
interface DispatchProps {
  onCloseMultipleDnDModal: () => void;
}
class FlightsChangeTailModal extends PureComponent<StateProps & DispatchProps> {
  render() {
    const { isModalVisible, onCloseMultipleDnDModal } = this.props;
    return (
      isModalVisible && (
        <div className="dnd-multiple-modal-main-container">
          <div className="dnd-multiple-modal-button-container">
            <div className="dnd-multiple-modal-close-button">
              <i className="material-icons" onClick={onCloseMultipleDnDModal}>
                close
              </i>
            </div>
          </div>
          <div className="dnd-multiple-modal-inner-container">
            <MainContentConnected />
          </div>
        </div>
      )
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  isModalVisible:
    state.ui.isMultipleDnDModalVisible &&
    getDraggingFlights(state)?.length > 0 &&
    !!getAircraftById(state, state.ui.newAircraftId),
});

const mapDispatchToProps = (
  dispatch: Dispatch<Action<void>>
): DispatchProps => ({
  onCloseMultipleDnDModal: () => dispatch(actions.userCloseDndModal()),
});

export const FlightsChangeTailModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(FlightsChangeTailModal);
