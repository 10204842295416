import { LoadingOutlined } from '@ant-design/icons';
import { AutoComplete, Input, Select, Tooltip } from 'antd';
import { FieldProps, FormikProps } from 'formik';
import { FC } from 'react';

import { MxFormProps } from '../mx-event-drawer';
import { MxEventFormName } from '../constants';
import { MxEventFormValues } from '../interfaces';
import { RootState } from '../../../reducers';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';

type StateProps = Pick<MxFormProps, 'serviceProviders'> & {
  isLoadingServiceProvider: boolean;
};
export const ServiceProviderOptions: FC<Pick<
  FormikProps<MxEventFormValues>,
  'setFieldValue'
> &
  FieldProps<MxEventFormValues['serviceProviderName']>['field'] & {
    disabled: boolean;
    airportId: MxEventFormValues['airportId'];
  }> = props => {
  const { disabled, airportId, value, setFieldValue } = props;
  const { isLoadingServiceProvider, serviceProviders } = useSelector<
    RootState,
    StateProps
  >(
    state => ({
      isLoadingServiceProvider:
        state.eventElementCreationEdit.isLoadingServiceProvider,
      serviceProviders: state.eventElementCreationEdit.serviceProviders,
    }),
    isEqual
  );
  const onSelect = (value: string) => {
    const provider = serviceProviders.find(el => el.id === +value);
    const fullProviderName = provider.typeName
      ? `${provider.name} (${provider.typeName})`
      : provider.name;
    setFieldValue(MxEventFormName.serviceProviderId, provider.id);
    setFieldValue(MxEventFormName.serviceProviderName, fullProviderName);
  };

  const onBlur = () => {
    if (!value) {
      setFieldValue(MxEventFormName.serviceProviderName, null);
      setFieldValue(MxEventFormName.serviceProviderId, null);
    }
  };

  const onClear = () => {
    setFieldValue(MxEventFormName.serviceProviderId, null);
    setFieldValue(MxEventFormName.serviceProviderName, null);
  };

  const providers = serviceProviders?.some(el => !!el) ? serviceProviders : [];
  const elements = providers.map(pr => {
    const title = pr?.typeName ? `${pr?.name} (${pr?.typeName})` : pr?.name;
    return (
      <Select.Option value={`${pr?.id}`} key={pr?.id} label={pr?.name}>
        <Tooltip title={title} placement="topLeft">
          {title}
        </Tooltip>
      </Select.Option>
    );
  });

  return isLoadingServiceProvider ? (
    <Input suffix={<LoadingOutlined />} />
  ) : (
    <Tooltip title={value}>
      <AutoComplete
        allowClear
        className="edit-event-form-body-service-provider"
        defaultValue={value}
        disabled={disabled || !airportId}
        onBlur={onBlur}
        onSelect={onSelect}
        onClear={onClear}
        placeholder={
          serviceProviders && !serviceProviders.length
            ? 'No service providers found for selected airport'
            : 'Select service provider'
        }
        popupClassName="edit-event-form-body-auto-complete-dropdown"
        popupMatchSelectWidth
        filterOption={(i, option) =>
          option.label
            .toString()
            .toLocaleLowerCase()
            .includes(i)
        }
      >
        {elements}
      </AutoComplete>
    </Tooltip>
  );
};
