import { PureComponent } from 'react';
import Flight from '../../types/flight';

interface Props {
  selectedFlight: Flight;
}

class AdditionalInfoRow extends PureComponent<Props> {
  render() {
    const { selectedFlight } = this.props;
    const { requestedAircraftTypeName, bookedBy } = selectedFlight;
    return (
      <div className="ft-box-group">
        <div className="ft-box">
          <div className="ft-box-label">Requested A/C</div>
          <div>{requestedAircraftTypeName}</div>
        </div>

        <div className="ft-box" />

        <div className="ft-box-wide">
          <div className="ft-box-label">Booked by</div>
          <div>
            {bookedBy ? `${bookedBy.firstName} ${bookedBy.lastName}` : 'N/A'}
          </div>
        </div>
      </div>
    );
  }
}

export default AdditionalInfoRow;
