import { Popconfirm } from 'antd';
import { FC } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { Action } from 'typescript-fsa';
import {
  userClickEditPeakDay,
  userCloseContextMenu,
  userDeletePeakDay,
  userTogglePeakDaysModal,
} from '../../../actions';
import { RootState } from '../../../reducers';
import { PeakDaysState } from '../../../reducers/peak-days';
import { hasPermission } from '../../../utils/check-permissions';
import { ContextMenuSimpleWrapper } from '../wrappers';

interface StateProps {
  contextMenu: PeakDaysState['contextMenu'];
  id: number;
  hasViewPermission: boolean;
  hasEditPermission: boolean;
}

const PeakDaysContextMenu: FC<StateProps & DispatchProp<Action<unknown>>> = ({
  hasViewPermission,
  hasEditPermission,
  id,
  contextMenu: { isOpen, position },
  dispatch,
}) => {
  const onDelete = () => dispatch(userDeletePeakDay.started(id));
  const onClickEdit = () => dispatch(userClickEditPeakDay(id));
  const onOpenPeakDaysPage = () => dispatch(userTogglePeakDaysModal());
  const onClose = () => dispatch(userCloseContextMenu());
  if (!isOpen || (!hasViewPermission && !hasEditPermission)) return null;
  return (
    <ContextMenuSimpleWrapper position={position}>
      {id && <li onClick={onClickEdit}>Edit Peak day</li>}
      {hasEditPermission && (
        <Popconfirm
          title="Do you want to delete this Peak day?"
          onConfirm={onDelete}
          onCancel={onClose}
          okText={<span data-stopbubbling="true">Yes</span>}
          cancelText="No"
        >
          <li>Delete Peak day</li>
        </Popconfirm>
      )}
      <li onClick={onOpenPeakDaysPage}>Open Peak days page</li>
    </ContextMenuSimpleWrapper>
  );
};

export const PeakDaysContextMenuConnected = connect(
  (state: RootState): StateProps => ({
    id: state.peakDays.eventId,
    contextMenu: state.peakDays.contextMenu,
    hasViewPermission: hasPermission(state, 'AG-Timeline-Timeline-Event-View'), // permission for Peak Day
    hasEditPermission: hasPermission(state, 'AG-Timeline-Timeline-Event-Edit'),
  })
)(PeakDaysContextMenu);
