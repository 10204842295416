import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as actions from '../actions';
import { utc } from 'moment';
import { uniqBy } from 'lodash';
import { RootState } from '.';
import {
  getAircraftByIdMap,
  getAircraftByIdMapWithHold,
  getAircraftIndexMapExcludingHolding,
  getAircraftIndexMapWithHolding,
  getFlightsForHoldTails,
  getHoldFlightsPoolDividedByTimeRange,
} from '../selectors';
import Flight, {
  HoldFlightsPoolByAircraftIdMap,
  HoldFlightsPoolHoursDelimiter,
  OperationalStatus,
} from '../types/flight';

import { ReducerShape } from './ui';
import { createSelector } from 'reselect';
import Aircraft from '../types/aircraft';
import { HOLD_AC_TYPE_ID } from '../constants/environment';
import { AircraftTogglerState } from './aircraft';
import { mapFocusWindowRectsWithProps } from '../utils/search';
import { ONE_MINUTE_MS } from '../constants';
import { Pool } from '../data-processing/pool-structure';

export type DashboardWidgetType = 'Delayed Flights' | 'Handover Remark Flights'; // | 'Airport Mismatch';

export const DashboardWidgetList: DashboardWidgetType[] = [
  'Delayed Flights',
  'Handover Remark Flights',
];

export interface DashboardReducerShape {
  focusedElement: number;
  flight: Flight[];
  id: number;
  widgetActive: DashboardWidgetType;
}
const initialState: DashboardReducerShape = {
  focusedElement: 0,
  flight: [],
  id: null,
  widgetActive: null,
};
export const dashboardReducer = reducerWithInitialState(initialState)
  .case(actions.userWidgetNext, (state, payload) => ({
    ...state,
    focusedElement:
      state.focusedElement + 2 > payload ? 0 : state.focusedElement + 1,
  }))
  .case(actions.userWidgetPrev, state => ({
    ...state,
    focusedElement: Math.max(0, state.focusedElement - 1),
  }))
  .case(actions.userSelectDashboardWidget, (state, payload) => ({
    ...state,
    widgetActive: payload.type,
    id: payload?.id || initialState.id,
    focusedElement: initialState.focusedElement,
    flight: payload?.flight ? [payload.flight] : state.flight,
  }))
  .cases(
    [
      actions.userCloseDashboardWidget,
      actions.userCloseHandoverRemarksDrawer,
      actions.userPinHandoverRemarksDrawer,
    ],
    () => ({ ...initialState })
  );

export const getTodaysFlights = createSelector<
  RootState,
  number,
  Pool<Flight>,
  Flight[]
>(
  state =>
    utc(state.time.now)
      .startOf('d')
      .valueOf(),
  state => state.timelineEvents.flights,
  (startOfToday, flights): Flight[] => {
    if (flights.length === 0) return [];
    return flights.reduce((closestFromLeft, current) => {
      return current.day <= startOfToday && current.day > closestFromLeft.day
        ? current
        : closestFromLeft;
    }).pool;
  }
);

export const getDelayedFlights = createSelector(
  getTodaysFlights,
  getAircraftIndexMapExcludingHolding,
  getAircraftByIdMap,
  (state: RootState) => state.time.now,
  (
    flights: Flight[],
    aircraftIndexMap: { [aircraftId: number]: number },
    aircraftByIdMap: { [id: string]: Aircraft },
    now: number
  ) =>
    uniqBy(
      flights.filter(f => {
        if (f.legOperationalStatusId == OperationalStatus.NO_SHOW) return false;
        if (f.departureUtcBlock) return false;
        if (!aircraftIndexMap[f.aircraftId]) return false;
        if (aircraftByIdMap[f.aircraftId].aircraftTypeId === HOLD_AC_TYPE_ID)
          return false;
        return (
          f.departureUtcEstimated + 10 * ONE_MINUTE_MS < now &&
          f.arrivalUtcEstimated > now
        );
      }),
      f => f.id
    )
);

export const getTraverseArray = createSelector<
  RootState,
  DashboardWidgetType,
  Flight[],
  Flight[],
  Flight[]
>(
  state => state.dashboard.widgetActive,
  getDelayedFlights,
  state => state.dashboard.flight,
  (widgetActive, delayedFlights, flightWithRemarks) => {
    if (widgetActive === 'Handover Remark Flights') {
      return flightWithRemarks;
    }
    if (widgetActive === 'Delayed Flights') {
      return delayedFlights;
    }
    return [];
  }
);

export const getDashboardTraverseRects = createSelector<
  RootState,
  Flight[],
  { [aircraftId: number]: number },
  {
    [aircraftId: string]: Aircraft;
  },
  HoldFlightsPoolHoursDelimiter,
  HoldFlightsPoolByAircraftIdMap,
  { [aircraftId: number]: AircraftTogglerState },
  ReducerShape,
  {
    x1: number;
    x2: number;
    y1: number;
    y2: number;
  }[]
>(
  getTraverseArray,
  getAircraftIndexMapWithHolding,
  getAircraftByIdMapWithHold,
  getHoldFlightsPoolDividedByTimeRange,
  getFlightsForHoldTails,
  state => state.aircraft.togglersState,
  state => state.ui,
  (
    traverseElements,
    indexMap,
    aircraftById,
    holdFlightsDelimiter,
    holdFlightsPool,
    togglersState,
    ui
  ) => {
    return traverseElements.map(f => {
      const segmentType = 'flights';
      return mapFocusWindowRectsWithProps(f, {
        ui,
        indexMap,
        segmentType,
        togglersState,
        aircraftById,
        holdFlightsDelimiter,
        holdFlightsPool,
      });
    });
  }
);
