import * as d3S from 'd3-selection';
import { isEqual } from 'lodash';
import { utc } from 'moment';
import { Component } from 'react';

import {
  accountTypeColorizer,
  flightFerryColorizer,
} from '../../../../common/flight/flight-colorization';
import {
  HOLD_FLIGHT_HEIGHT,
  HOLD_FLIGHT_MARGIN,
  HOLD_LANE_STROKE,
} from '../../../../constants';
import { ZoomLevelForHoldFlights } from '../../../../d3/components/zoom';
import { getInitialScale } from '../../../../reducers/ui';
import { store } from '../../../../root';
import Flight, { HoursFlight } from '../../../../types/flight';
import {
  getDay,
  getSvgWidthForHoldFlight,
} from '../../../../utils/hold-line-flights';
import { ExtendedEventElement } from '../vistajet';
import dataMappers from '../vistajet/data-mappers';
import { getSvgHoldFlightsD3creator } from './d3-creator';

const { holdFlightDataMapper } = dataMappers;
export interface HoldFlightEventElement extends ExtendedEventElement {
  height: number;
  backgroundColor: string;
  stroke: string;
  kx: number;
  x: number;
  width: number;
}
interface Props {
  flights: Flight[];
  flightTimeStart: number;
  zoomLevel: ZoomLevelForHoldFlights;
  columnId: string;
  kx: number;
  translateX: number;
  y1: number;
}

export class HoldFlightSvgColumns extends Component<Props> {
  linkedSelection: d3S.Selection<
    d3S.BaseType,
    HoldFlightEventElement,
    SVGGElement,
    {}
  >;
  constructor(props) {
    super(props);
  }
  rootRef: SVGGElement;
  getRootRef = ref => (this.rootRef = ref);

  componentDidMount() {
    this.update(this.props);
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.columnId === prevProps.columnId &&
      (!isEqual(prevProps.flights, this.props.flights) ||
        this.props.kx !== prevProps.kx ||
        this.props.y1 !== prevProps.y1 ||
        this.props.translateX !== prevProps.translateX)
    ) {
      this.update(this.props);
    }
  }

  update = ({ flights }) => {
    if (!this.linkedSelection) {
      this.linkedSelection = d3S.select(this.rootRef).selectAll('.hold-flight');
    }
    const mappedWithData = this.linkedSelection.data(
      flights.map((d, i) => this.buildPropsForComponent(d, i)),
      holdFlightDataMapper
    );
    const exit = mappedWithData.exit();
    exit.remove();
    this.linkedSelection = this.renderEntered(mappedWithData.enter()).merge(
      mappedWithData
    );
  };

  renderEntered(
    entered: d3S.Selection<
      d3S.BaseType,
      HoldFlightEventElement,
      SVGGElement,
      {}
    >
  ): d3S.Selection<d3S.BaseType, HoldFlightEventElement, SVGGElement, {}> {
    return getSvgHoldFlightsD3creator(entered);
  }

  rectWidthCalculator = (d: Flight) => {
    const { flightTimeStart, zoomLevel, kx } = this.props;
    const state = store.getState();
    const { width, planeBlockWidth } = state.ui;
    const { start } = d;
    const scaleX = getInitialScale(width - planeBlockWidth);
    return getSvgWidthForHoldFlight({
      flightTimeStart,
      kx,
      scaleX,
      start,
      zoomLevel,
    });
  };

  rectTopCalculator = (index: number) => {
    const { y1 } = this.props;
    return (
      y1 + HOLD_LANE_STROKE + (HOLD_FLIGHT_MARGIN + HOLD_FLIGHT_HEIGHT) * index
    );
  };
  rectLeftCalculator = (d: Flight, timeStart: HoursFlight) => {
    const state = store.getState();
    const {
      ui: { width, planeBlockWidth },
    } = state;
    const { zoomLevel } = this.props;
    const scaleX = getInitialScale(width - planeBlockWidth);
    switch (zoomLevel) {
      case 'fullView': {
        return scaleX(
          utc(d.start)
            .hours(timeStart)
            .minutes(0)
            .valueOf()
        );
      }
      case 'weeklyView': {
        const today = getDay(d.start);
        const tomorrow = utc(today)
          .add(1, 'day')
          .toDate()
          .valueOf();
        const center = scaleX((today + tomorrow) / 2);
        return center - this.rectWidthCalculator(d) / 2;
      }
    }
  };
  buildPropsForComponent = (d: Flight, index: number) => {
    const state = store.getState();
    const {
      transform: { kx },
      width,
    } = state.ui;
    const { flightTimeStart } = this.props;
    const newD = Object.create(d) as HoldFlightEventElement;
    newD.x = this.rectLeftCalculator(d, flightTimeStart);
    newD.width = this.rectWidthCalculator(d);
    newD.height = HOLD_FLIGHT_HEIGHT;
    newD.y = this.rectTopCalculator(index);
    newD.backgroundColor = accountTypeColorizer(d);
    newD.stroke = flightFerryColorizer(d.legBusinessTypeId);
    newD.kx = kx;
    newD.canvasWidth = width;
    return newD;
  };

  render() {
    return <g className="hold-flights-svg-column" ref={this.getRootRef} />;
  }
}
