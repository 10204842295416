import { gql } from '@apollo/client';
import { apolloClient } from '../../data-processing/graphql';

const deleteMxEvent = require('./delete-mx-event.gql');

export function deleteMxEventMutation(id: number) {
  return apolloClient.mutate<any>({
    mutation: gql(deleteMxEvent),
    variables: { id },
  });
}
