import { EventGroup } from '../event-group';
import {
  getLaneHeightKoef,
  getInitialScale,
  getElementOffset,
} from '../../../../../reducers/ui';
import * as d3S from 'd3-selection';
import { store } from '../../../../../root';
import { ExtendedEventElement } from '..';
import EventElement, {
  OverlappedElements,
} from '../../../../../types/event-element';
import { getAircraftIndexMapExcludingHolding } from '../../../../../selectors';

export interface OverlappedFlightWithElOrOWOffer extends ExtendedEventElement {
  elements: OverlappedElements[];
}
export class OverlappedFlightWithElOrOWOffer extends EventGroup {
  rectTopCalculator = d => {
    const state = store.getState();
    const { togglersState } = state.aircraft;
    const { segmentsVisibility, positionMap } = state.ui;
    return (
      getAircraftIndexMapExcludingHolding(state)[d.aircraftId] *
        state.ui.rowHeight +
      getElementOffset(
        segmentsVisibility,
        'oneWayOffers',
        togglersState[d.aircraftId],
        positionMap
      ) /
        getLaneHeightKoef(
          segmentsVisibility,
          togglersState[d.aircraftId],
          positionMap
        )
    );
  };
  buildPropsForComponent = (d: EventElement) => {
    const { time, ui } = store.getState();
    const newD = Object.create(d) as ExtendedEventElement;
    newD.y = this.rectTopCalculator(newD);
    newD.now = time.now;
    newD.canvasWidth = ui.width;
    return newD;
  };
  renderEntered(
    entered: d3S.Selection<
      d3S.EnterElement,
      OverlappedFlightWithElOrOWOffer,
      SVGGElement,
      {}
    >
  ) {
    const state = store.getState();
    const { ui, aircraft } = state;
    const { segmentsVisibility, positionMap, width, planeBlockWidth } = ui;
    const { togglersState } = aircraft;
    const scaleX = getInitialScale(width - planeBlockWidth);
    const overlapsEntered = entered;
    const rectGroup = overlapsEntered.append('g').classed('event', true);
    rectGroup
      .append('rect')
      .attr('fill', 'rgba(255,143,0,0.2)')
      .attr('x', d => {
        return scaleX(d.start);
      })
      .attr('y', d => d.y)
      .attr('width', d =>
        this.rectWidthCalculator(d, scaleX) < 10
          ? 10
          : this.rectWidthCalculator(d, scaleX)
      )
      .attr(
        'height',
        m =>
          positionMap['oneWayOffers'] /
          getLaneHeightKoef(
            segmentsVisibility,
            togglersState[m.aircraftId],
            positionMap
          )
      );
    rectGroup
      .append('path')
      .attr(
        'd',
        d =>
          `M ${scaleX(d.start) - 1} ${d.y} V ${d.y +
            positionMap['oneWayOffers'] /
              getLaneHeightKoef(
                segmentsVisibility,
                togglersState[d.aircraftId],
                positionMap
              )}`
      )
      .attr('fill', 'transparent')
      .attr('stroke', 'orange')
      .attr('stroke-width', 2)
      .attr('vector-effect', 'non-scaling-stroke');
    rectGroup
      .append('path')
      .attr(
        'd',
        d =>
          `M ${
            this.rectWidthCalculator(d, scaleX) < 10
              ? scaleX(d.start) + 10
              : scaleX(d.end) + 1
          } ${d.y} V ${d.y +
            positionMap['oneWayOffers'] /
              getLaneHeightKoef(
                segmentsVisibility,
                togglersState[d.aircraftId],
                positionMap
              )}`
      )
      .attr('fill', 'transparent')
      .attr('stroke', 'orange')
      .attr('stroke-width', 2)
      .attr('vector-effect', 'non-scaling-stroke');
    return rectGroup;
  }
}
