import { ExtendedEventElement } from '..';
import { FlightExtended } from '../../../../../types/flight';
import Maintenance from '../../../../../types/maintenance';
import Note, { MergedNote } from '../../../../../types/note';
import OneWayOffer from '../../../../../types/one-way-offer';
import EmptyLegOffer from '../../../../../types/empty-leg-offer';
import { values } from 'lodash';
import { OverlappedElements } from '../../../../../types/event-element';
import { isFlight } from '../../../../../common/flight/flight-check-status';
import { HoldFlightEventElement } from '../../hold-flights/holdFlightSvgColumns';
import { isMergeNote } from '../../../../../utils/note';
import MaintenanceItem, {
  MergedMEL,
} from '../../../../../types/maintenance-item';
import { isMergedMaintenanceItems } from '../../../../../utils/maintenance-item';

const plainDataMapper = (d: ExtendedEventElement) =>
  `${d.id}:${d.start}-${d.end}-${d.aircraftId}-${d.y}-${d.canvasWidth}`;

const flightProperties: (keyof (ExtendedEventElement & FlightExtended))[] = [
  'id',
  'start',
  'end',
  'y',
  'canvasWidth',
  'bpmItemStatus',
  'slots',
  'permits',
  'aircraftId',
  'legBusinessTypeId',
  'legBusinessStatusId',
  'legOperationalStatusId',
  'orderBusinessTypeId',
  'demoFlight',
  'lineCheck',
  'sensitive',
  'departureUtcActual',
  'departureUtcBlock',
  'departureUtcEstimated',
  'departureUtcScheduled',
  'arrivalUtcActual',
  'arrivalUtcBlock',
  'arrivalUtcEstimated',
  'arrivalUtcScheduled',
  'baseCompanyId',
  'departureSlotMismatch',
  'arrivalSlotMismatch',
  'slaBorder',
  'hasOpsRemarks',
  'hasCsRemarks',
];
const flightDataMapper = (d: ExtendedEventElement & FlightExtended) => {
  return (
    flightProperties.map(field => d[field]).join('-') +
    '-' +
    d.permits
      .map(p =>
        values(p)
          .map(String)
          .join('-')
      )
      .join('-') +
    '-' +
    d.slots
      .map(p =>
        values(p)
          .map(String)
          .join('-')
      )
      .join('-') +
    '-' +
    (d.start <= d.now && d.end >= d.now ? d.now.toString() : 'not-now')
  );
};
const holdFlightDataMapper = (d: HoldFlightEventElement) => {
  return `${plainDataMapper(d)}-${d.height}-${d.width}-${d.backgroundColor}-${
    d.stroke
  }`;
};

const maintenanceDataMapper = (d: Maintenance & ExtendedEventElement) =>
  `${plainDataMapper(d)}-${d.maintenanceType}-${d.maintenanceOrderType}-${
    d.categoryId
  }-${d.isDispatchable}`;

const noteDataMapper = (d: (Note | MergedNote) & ExtendedEventElement) =>
  isMergeNote(d)
    ? d.notes && d.notes.length
      ? `${plainDataMapper(d)}-` +
        d.notes
          .map((n: Note) => `${n.start}-${n.end}-${n.importanceFactor}`)
          .join('-')
      : plainDataMapper(d)
    : `${plainDataMapper(d)}-${d.importanceFactor}`;

const melDataMapper = (
  d: (MaintenanceItem | MergedMEL) & ExtendedEventElement
) =>
  isMergedMaintenanceItems(d)
    ? `${plainDataMapper(d)}-` +
      d.maintenanceItems
        .map((m: MaintenanceItem) => `${m.start}-${m.end}`)
        .join('-')
    : plainDataMapper(d);

const oneWayEmptyLegDataMapper = (
  d: ExtendedEventElement & (OneWayOffer | EmptyLegOffer)
) => `${plainDataMapper(d)}-${d.offerStatus}`;

export const overlappedFlightWithELOrOwDataMapper = (
  oe: OverlappedElements & ExtendedEventElement
) =>
  `${plainDataMapper(
    oe
  )}-${oe.elements.map(
    (
      e: (FlightExtended | EmptyLegOffer | OneWayOffer) & ExtendedEventElement
    ) => (isFlight(e) ? flightDataMapper(e) : oneWayEmptyLegDataMapper(e))
  )}`;

export default {
  plainDataMapper,
  flightDataMapper,
  maintenanceDataMapper,
  noteDataMapper,
  oneWayEmptyLegDataMapper,
  holdFlightDataMapper,
  melDataMapper,
};
