import { getLaneHeightKoef } from '../../../../../../reducers/ui';
import { SegmentsAppenderArgs } from '..';

export function appendUnderlying(argsObject: SegmentsAppenderArgs) {
  const {
    selection,
    elementName,
    segmentsVisibility,
    positionMap,
    togglersState,
    scaleX,
  } = argsObject;
  selection
    .append('rect')
    .attr('y', d => d.y)
    .attr('x', d => scaleX(d.start))
    .attr(
      'height',
      f =>
        positionMap[elementName] /
        getLaneHeightKoef(
          segmentsVisibility,
          togglersState[f.aircraftId],
          positionMap
        )
    )
    .attr('width', d => this.rectWidthCalculator(d, scaleX))
    .style('fill', d =>
      d.hasOpsRemarks || d.hasCsRemarks
        ? 'url(#handoverRemarksOnwhite)'
        : 'white'
    );
}
