import './styles.scss';
import { FC, useEffect, useState } from 'react';
import * as actions from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { GraphQLClient } from 'graphql-request';
import { RootState } from '../../../reducers';
import { Layout, Menu, Row, Col, Input, MenuProps, ConfigProvider } from 'antd';
import Logout from './components/ui/Logout/Logout';

import {
  SIDEBAR_BACKEND_URL,
  SIDEBAR_BACKEND_URL_AUTH0,
  SIDEBAR_ENABLED,
  USE_AUTH,
} from '../../../constants/environment';
import ALL_APPS_QUERY from '../../../data-processing/graphql/all_apps_query';
import Logo from '../../../accets/images/vj_logo.png';
import { getTokenFromLocalStorage } from '../../../utils/tokenLocalStorage';
import { MenuFoldOutlined } from '@ant-design/icons';
import { handleError, handleGraphQlErrors } from '../../error-handling/utils';
import { GraphQLError } from 'graphql';

const { Sider } = Layout;
const { Search } = Input;

type Response = {
  domain: string;
  id: string;
  name: string;
  url: string;
};

const findCurrentApplication = (apps: Response[]): string => {
  let currentApp = null;
  const currentLocation = window.location.href;
  apps.forEach(app => {
    if (!currentApp && app.url && currentLocation.startsWith(app.url)) {
      currentApp = `${app.id}`;
    }
  });

  return currentApp;
};

const getSubMenuItems = (
  domains: string[],
  visibleApps: Response[]
): MenuProps['items'] => {
  const itemsSet = new Set();

  const filteredItems = visibleApps.filter(item => {
    const duplicate = itemsSet.has(item.id);
    itemsSet.add(item.id);
    return !duplicate;
  });
  return domains.map(domain => ({
    label: domain,
    key: domain,
    children: filteredItems.reduce((acc, item) => {
      if (item.domain === domain || (!item.domain && domain === 'Others')) {
        return acc.concat({
          className: 'menu-item',
          key: item.id,
          label: (
            <a
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              className="menu-item-name"
              id={item.id}
            >
              {item.name}
            </a>
          ),
          type: 'group',
        });
      }
      return acc;
    }, []),
  }));
};

const SideBarComponent: FC<{}> = () => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState<string>('');
  const [allApps, setAllApps] = useState<Response[]>([]);
  const [domains, setDomains] = useState<string[]>([]);
  const [openDomains, setOpenDomains] = useState<string[]>([]);
  const [visibleApps, setVisibleApps] = useState<Response[]>([]);
  const [selectedApp, setSelectedApp] = useState<string | null>(null);

  const getAllApplications = async () => {
    const token = getTokenFromLocalStorage();
    try {
      const result = await new GraphQLClient(
        USE_AUTH ? SIDEBAR_BACKEND_URL_AUTH0 : SIDEBAR_BACKEND_URL,
        {
          headers: {
            authorization: token ? `Bearer ${token}` : '',
          },
        }
      ).rawRequest<{ apps: Response[] }>(ALL_APPS_QUERY);
      if (result?.errors) {
        handleGraphQlErrors(
          (result.errors as unknown) as readonly GraphQLError[]
        );
      }
      return result;
    } catch (error) {
      handleError({
        error,
        message: `Failed to fetch related application's links: ${error.message}`,
        url: 'ALL_APPS_QUERY',
        content: error.stack || error.toString(),
      });
    }
  };

  useEffect(() => {
    if (SIDEBAR_ENABLED) {
      (async () => {
        const response = await getAllApplications();
        if (response?.data) {
          const { apps } = response.data;
          const domainSet = new Set<string>();
          apps.forEach(app => {
            domainSet.add(app.domain || 'Others');
          });

          const domains = [...domainSet].sort(sortDomains);
          setAllApps(apps);
          setDomains(domains);
          setOpenDomains([...domainSet]);
          setVisibleApps(apps);
          setSelectedApp(findCurrentApplication(apps));
        }
      })();
    }
  }, [SIDEBAR_ENABLED]);

  const isSidebarCollapsed = useSelector<RootState, boolean>(
    state => state.ui.isSidebarCollapsed
  );
  const headerHeight = useSelector<RootState, number>(
    state => state.ui.timelineBarHeight
  );

  const onOpenChange = (openedDomains: string[]): void => {
    const latestOpenKey = openedDomains.find(
      key => openedDomains.indexOf(key) === -1
    );
    if (domains.indexOf(latestOpenKey) === -1) {
      setOpenDomains(openedDomains);
    } else {
      setOpenDomains(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const handleSearch = (query: string): void => {
    const visibleApps = filterApps(query);
    const filteredDomains = new Set<string>();
    visibleApps.forEach(app => {
      filteredDomains.add(app.domain || 'Others');
    });
    const domains = [...filteredDomains].sort(sortDomains);
    setQuery(query);
    setDomains(domains);
    setOpenDomains([...filteredDomains]);
    setVisibleApps(visibleApps);
  };

  const filterApps = (query: string): Response[] => {
    if (query.length < 3 && query.length >= 0) return allApps;
    if (allApps === null) return [];
    return allApps.filter(
      item => item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  };

  const toggleSideBar = (): void => {
    dispatch(actions.userToggleSidebar());
  };

  return (
    <Sider
      className="sidebar"
      width={255}
      trigger={null}
      collapsible
      collapsed={isSidebarCollapsed}
      collapsedWidth={0}
      hidden={isSidebarCollapsed}
    >
      <Row
        style={{
          backgroundColor: '#002140',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'end',
          justifyContent: 'space-between',
        }}
      >
        <Col span={18}>
          <div
            className="logo-container"
            style={{ textAlign: 'center', height: '100%' }}
          >
            <a href="/">
              <img
                src={Logo}
                className="App-logo"
                alt="Logo"
                style={{ width: '70%', paddingTop: '8%' }}
              />
            </a>
          </div>
        </Col>
        <Col span={6}>
          <MenuFoldOutlined className="trigger-fold" onClick={toggleSideBar} />
        </Col>
      </Row>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          padding: '16px 0',
          backgroundColor: '#002140',
        }}
      >
        <Search
          className="ant-search"
          value={query}
          onChange={e => handleSearch(e.target.value)}
          style={{ width: 200 }}
        />
      </Row>
      <ConfigProvider
        theme={{
          token: {
            colorLink: '#fff',
          },
        }}
      >
        <Menu
          mode="inline"
          theme="dark"
          openKeys={openDomains}
          onOpenChange={onOpenChange}
          selectedKeys={[selectedApp]}
          className="sidebar-scroll"
          inlineIndent={0}
          style={{
            height: `${window.innerHeight - headerHeight * 2}px`,
            color: '#fff',
            textAlign: 'start',
          }}
          items={getSubMenuItems(domains, visibleApps)}
        />
        {!isSidebarCollapsed ? <Logout /> : null}
      </ConfigProvider>
    </Sider>
  );
};

export default SideBarComponent;

function sortDomains(a: string, b: string) {
  if (a === 'Others' && b !== 'Others') {
    return 1;
  }
  if (a !== 'Others' && b === 'Others') {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  if (a < b) {
    return -1;
  }
  return 0;
}
