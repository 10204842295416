import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as actions from '../actions';
import { RootState } from '.';
import { OfferStatus } from '../types/empty-leg-offer';
import createCachedSelector from 're-reselect';
import { DEFAULT_USER_PREFERENCES } from '../types/user-preferences';

export type EmptyLegGroupType =
  | 'Advertised One Way Offers'
  | 'Advertised Empty Leg Offers'
  | 'Unadvertised One Way Offers'
  | 'Unadvertised Empty Leg Offers'
  | 'Cancelled One Way Offers'
  | 'Cancelled Empty Leg Offers';

export interface EmptyLeg {
  emptyLegMap: { [el in EmptyLegGroupType]: boolean };
}

export const initialState: EmptyLeg = {
  emptyLegMap: {
    'Advertised One Way Offers': true,
    'Advertised Empty Leg Offers': true,
    'Unadvertised One Way Offers': false,
    'Unadvertised Empty Leg Offers': false,
    'Cancelled One Way Offers': false,
    'Cancelled Empty Leg Offers': false,
  },
};

export default reducerWithInitialState(initialState)
  .case(actions.userToggleEmptyLegView, (state, payload) => ({
    ...state,
    emptyLegMap: {
      ...state.emptyLegMap,
      [payload[0]]: payload[1],
    },
  }))
  .case(actions.doGetUserPreferences.done, (state, { result }) => ({
    ...state,
    emptyLegMap: result.emptyLegMap,
  }))
  .case(actions.userResetPreferences, state => ({
    ...state,
    emptyLegMap: DEFAULT_USER_PREFERENCES.emptyLegMap,
  }));

export const getSelectedEmptyLegIds = createCachedSelector(
  (state: RootState) => state.emptyLegs.emptyLegMap,
  emptyLegMap => {
    let resultArr: OfferStatus[] = [];
    if (emptyLegMap['Unadvertised Empty Leg Offers']) {
      resultArr.push(OfferStatus.NEW);
    }
    if (emptyLegMap['Advertised Empty Leg Offers']) {
      resultArr.push(OfferStatus.ADVERTISED);
    }
    if (emptyLegMap['Cancelled Empty Leg Offers']) {
      resultArr.push(OfferStatus.CANCELLED);
    }
    return resultArr;
  }
)((state, key) => key);

export const getSelectedOneWayIds = createCachedSelector(
  (state: RootState) => state.emptyLegs.emptyLegMap,
  oneWayMap => {
    let resultArr: OfferStatus[] = [];
    if (oneWayMap['Unadvertised One Way Offers']) {
      resultArr.push(OfferStatus.NEW);
    }
    if (oneWayMap['Advertised One Way Offers']) {
      resultArr.push(OfferStatus.ADVERTISED);
    }
    if (oneWayMap['Cancelled One Way Offers']) {
      resultArr.push(OfferStatus.CANCELLED);
    }
    return resultArr;
  }
)((state, key) => key);
