import { Radio } from 'antd';
import { ReactElement } from 'react';

interface Props<T> {
  options: T;
  value: string[];
  onChange: (v: string[]) => void;
  disabled?: boolean;
}

export const MultiRadioComponent: <T>(
  p: Props<T>
) => ReactElement<Props<T>> = ({
  options,
  value: currentValue,
  onChange,
  disabled,
}) => {
  const onChangeFilter = e => {
    const { value } = e.target;
    const nextValue = currentValue.includes(value)
      ? currentValue.filter(c => c !== value)
      : currentValue.concat(value);
    onChange(nextValue);
  };
  return (
    <Radio.Group
      buttonStyle="solid"
      size="small"
      className="remarks-title-column-radio unselectable"
      value={currentValue}
      disabled={disabled}
    >
      {Object.values(options).map(v => (
        <Radio.Button
          key={v}
          value={v}
          onClick={e => onChangeFilter(e)}
          style={{ padding: '0 10px' }}
          className={
            currentValue.includes(v) && 'ant-radio-button-wrapper-checked'
          }
        >
          {v}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};
