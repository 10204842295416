import * as d3S from 'd3-selection';
import { store } from '../../../../../root';
import { EventGroup } from '../event-group';
import { ExtendedEventElement } from '..';
import { getInitialScale, getLaneHeightKoef } from '../../../../../reducers/ui';
import {
  THIN_ELEMENT_HEIGHT,
  LIFT_UP_CASCADER_ELEMENT_HEIGHT,
} from '../../../../../constants';
import appender, { SegmentDatum } from '../appender';
import { isMergedMaintenanceItems } from '../../../../../utils/maintenance-item';
import MaintenanceItem, {
  MergedMEL,
} from '../../../../../types/maintenance-item';

export class MelGroup extends EventGroup {
  renderEntered(
    entered: d3S.Selection<
      d3S.EnterElement,
      ExtendedEventElement & (MaintenanceItem | MergedMEL),
      SVGGElement,
      {}
    >
  ) {
    const state = store.getState();
    const {
      width,
      planeBlockWidth,
      segmentsVisibility,
      positionMap,
    } = state.ui;
    const { togglersState } = state.aircraft;
    const scaleX = getInitialScale(width - planeBlockWidth);
    const rectGroup = entered.append('g').classed('event', true);
    const appendedParts = ['body', 'border', 'leftBorder'];
    const getHeight = (d: ExtendedEventElement, coef = THIN_ELEMENT_HEIGHT) =>
      coef /
      getLaneHeightKoef(
        segmentsVisibility,
        togglersState[d.aircraftId],
        positionMap
      );
    const basicData = {
      element: 'mel',
      color: 'rgba(64,169,255,0.25)',
      borderColor: 'rgba(64,169,255,1)',
    };
    rectGroup.each(function(d) {
      const container = d3S.select(this);
      if (isMergedMaintenanceItems(d)) {
        d.maintenanceItems.forEach(function(mel, i) {
          const segmentDatum: SegmentDatum = {
            ...basicData,
            x: scaleX(mel.start),
            y:
              d.y +
              getHeight(
                mel as MaintenanceItem & ExtendedEventElement,
                LIFT_UP_CASCADER_ELEMENT_HEIGHT
              ) *
                (i % 4),
            width: scaleX(mel.end) - scaleX(mel.start),
            height: getHeight(mel as MaintenanceItem & ExtendedEventElement),
          };
          appendedParts.forEach(part =>
            appender[part].call(null, container, segmentDatum)
          );
        });
      } else {
        const segmentDatum: SegmentDatum = {
          ...basicData,
          x: scaleX(d.start),
          y: d.y,
          width: scaleX(d.end) - scaleX(d.start),
          height: getHeight(d),
        };
        appendedParts.forEach(part =>
          appender[part].call(null, container, segmentDatum)
        );
      }
    });

    return rectGroup;
  }
}
