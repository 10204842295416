import { PureComponent } from 'react';
import { SvgPatterns } from './svg-patterns/svg-patterns';
import { PeakDaysElementsConnected } from './timeline-elements/peak-days';
import { HorizontalDelimiter } from './timeline-elements/horizontal-delimeter';
import { AxisConnected } from './axis/axis-combined';
import { AircraftHighlighterConnected } from './timeline-elements/aircraft-highlighter';
import { TimelineSegments } from './timeline-elements/svg-segments/vistajet';
import { SearchWindowConnected } from './timeline-elements/search-window';
import { DelayFocus, SearchFocus } from './focus-window/focus-window';
import { ConnectedSelection as TimelineSelection } from './timeline-selection/selection';
import { HighlightTailBySearchConnected } from './highlight-area-by-tail-search';
import { HoldFlightSvgLayer } from './timeline-elements/svg-segments/hold-flights';
import { OperatingCompanyDividersConnected } from './timeline-elements/operating-companies-divider';

interface Props {
  width: number;
  height: number;
  planeBlockWidth: number;
  marginTop: number;
  holdLineHeight: number;
  timelineBarHeight: number;
  filterBarHeight: number;
  multipleSearchBarHeight: number;
  controlsBarHeight: number;
  aircraftLoadingComplete: boolean;
  visibleHoldAcLines: number;
}

export class SvgLayer extends PureComponent<Props> {
  render() {
    const {
      width,
      height,
      planeBlockWidth,
      marginTop,
      holdLineHeight,
      timelineBarHeight,
      filterBarHeight,
      multipleSearchBarHeight,
      controlsBarHeight,
      aircraftLoadingComplete,
      visibleHoldAcLines,
    } = this.props;
    return (
      <svg
        className="timeline-zoomed"
        width={width ? Math.max(width - planeBlockWidth, 0) : 0}
        height={height}
      >
        <defs>
          <clipPath id="eventsSVGClip">
            <rect
              x={0}
              y={marginTop + holdLineHeight}
              width={width}
              height={height - marginTop - holdLineHeight}
            />
          </clipPath>
          <clipPath id="eventsSVGClipHold">
            <rect x={0} y={marginTop} width={width} height={holdLineHeight} />
          </clipPath>
          <SvgPatterns />
        </defs>
        <PeakDaysElementsConnected />
        <HorizontalDelimiter />
        <AxisConnected />
        <rect x={0} y={marginTop - 1} width={width} height={1} fill="#c5c5c5" />
        {visibleHoldAcLines && visibleHoldAcLines % 2 !== 0 ? (
          <rect
            x={0}
            y={marginTop + holdLineHeight - 1}
            width={width}
            height={1}
            fill="rgba(0,0,0,0.3)"
          />
        ) : null}
        <OperatingCompanyDividersConnected />
        <AircraftHighlighterConnected />
        {holdLineHeight > 0 && <HoldFlightSvgLayer />}
        {aircraftLoadingComplete && (
          <>
            <TimelineSegments />
            <TimelineSelection
              controlsBarHeight={controlsBarHeight}
              timelineBarHeight={timelineBarHeight}
              filterBarHeight={filterBarHeight}
              multipleSearchBarHeight={multipleSearchBarHeight}
              planeBlockWidth={planeBlockWidth}
            />
            <SearchWindowConnected />
            <SearchFocus />
            <DelayFocus />
            <HighlightTailBySearchConnected />
          </>
        )}
      </svg>
    );
  }
}
