import { PureComponent } from 'react';
import { DispatchProp, connect } from 'react-redux';

import {
  CaretDownOutlined,
  CaretUpOutlined,
  CloseOutlined,
  ExclamationCircleTwoTone,
  LeftOutlined,
  LoadingOutlined,
  RightOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import {
  Button,
  Input,
  Space,
  Select,
  AutoComplete,
  Tooltip,
  message,
} from 'antd';
import { RootState } from '../../reducers';
import * as actions from '../../actions';
import {
  getAutoCompleteOptions,
  getFoundElements,
  getUnfilteredFoundElements,
} from '../../selectors';
import { createDelayedForScrollConnect } from '../../utils/create-delayed-for-scroll-connect';
import { getTooltipMessage } from './utils';
import { getSelectedStatusesIds } from '../../reducers/operational-statuses';
import { setDataTestEntityId } from '../../utils';
import { InputProps } from 'antd/lib/input';
import { SearchType, searchTypeList } from '../../reducers/search';
import { debounce } from 'lodash';
import { AnyAction } from 'redux';
import { toOptionsValue } from '../email-component/utils';

const InputGroup = Space.Compact;
const Option = Select.Option;

class SearchBar extends PureComponent<
  {
    searchType: SearchType;
    searchQuery: string;
    focused: number;
    count: number;
    isSearching: boolean;
    autoCompleteItems: string[];
    visibleOpStatuses: number[];
    tooltipMessage: string;
    isShowMultipleSearchBar: boolean;
    isVerticalMode: boolean;
  } & DispatchProp<AnyAction>,
  { isTimeOut: boolean; timer: number }
> {
  constructor(props) {
    super(props);
    this.state = {
      isTimeOut: true,
      timer: null,
    };
  }
  asyncSearch = () => {
    if (!this.props.searchQuery) return;
    const payload = Number(this.props.searchQuery.trim());
    if (isNaN(payload)) {
      message.warning(
        `Search by ${this.props.searchType} should contain numbers only`
      );
    } else if (this.props.searchType === 'Order ID') {
      this.props.dispatch(actions.userSearchOrder.started(payload));
    } else {
      this.props.dispatch(
        actions.userSearchLeg.started({
          id: payload,
        })
      );
    }
  };
  onSearch = () => {
    if (!this.props.searchQuery) return;
    if (!!this.props.tooltipMessage) {
      clearTimeout(this.state.timer);
      this.setState({ isTimeOut: true });
      this.startTimer();
    }
    if (this.props.count > 0) {
      this.props.dispatch(actions.userFocusFirstFound());
    } else {
      if (
        this.props.searchType === 'Leg ID' ||
        this.props.searchType === 'Order ID'
      ) {
        this.asyncSearch();
      }
    }
  };
  onChangeQuery = query => {
    this.props.dispatch(actions.userChangeSearchQuery(query));
  };
  onChangeSearchType = searchType =>
    this.props.dispatch(actions.userChangeSearchType(searchType));
  nextSearch = () => {
    this.props.dispatch(actions.userSearchNext(this.props.count));
  };
  prevSearch = () => {
    this.props.dispatch(actions.userSearchPrev(this.props.count));
  };
  startTimer = () => {
    clearTimeout(this.state.timer);
    this.setState({
      isTimeOut: false,
      timer: window.setTimeout(() => this.setState({ isTimeOut: true }), 2500),
    });
  };
  componentDidUpdate(prevProps) {
    clearTimeout(this.state.timer);
    this.setState({ isTimeOut: true });
    if (
      !!this.props.tooltipMessage &&
      this.props.searchQuery &&
      this.props.searchQuery.length > 2 &&
      (this.props.searchQuery !== prevProps.searchQuery ||
        this.props.searchType !== prevProps.searchType)
    ) {
      debounce(this.startTimer, 1500)();
    }
  }
  onClickMoreFilters = () => {
    this.props.dispatch(actions.userToggleMultipleSearchBarPanel());
  };
  render() {
    const {
      searchQuery,
      searchType,
      count,
      focused,
      isSearching,
      autoCompleteItems,
      dispatch,
      isShowMultipleSearchBar,
      isVerticalMode,
    } = this.props;
    return (
      <div
        className="topbar-search-panel"
        style={{ minWidth: isVerticalMode ? '664px' : '560px' }}
        {...setDataTestEntityId('topbar-search-panel')}
      >
        <InputGroup>
          <Select
            value={
              isShowMultipleSearchBar && searchType !== 'Leg ID'
                ? ('Order ID' as SearchType)
                : searchType
            }
            style={{ minWidth: 150 }}
            onChange={this.onChangeSearchType}
            popupClassName="test-entity-search-type-dropdown"
            {...setDataTestEntityId('search-type-dropdown')}
          >
            {searchTypeList.map(t => (
              <Option
                value={t}
                key={t}
                {...setDataTestEntityId('select-search-by-type-option-item')}
                disabled={
                  isShowMultipleSearchBar &&
                  !(t === 'Leg ID' || t === 'Order ID')
                }
              >
                {t}
              </Option>
            ))}
          </Select>
          <Tooltip
            placement="bottom"
            title={
              <>
                <ExclamationCircleTwoTone twoToneColor="gold" />
                {` ${this.props.tooltipMessage}`}
              </>
            }
            open={
              searchType !== 'Leg ID' &&
              searchType !== 'Order ID' &&
              !!this.props.tooltipMessage &&
              !this.state.isTimeOut
            }
          >
            <AutoComplete
              style={{ width: 200, height: 32 }}
              onChange={this.onChangeQuery}
              value={searchQuery}
              options={toOptionsValue(autoCompleteItems)}
              popupClassName="test-entity-search-autocomplete-dropdown"
              {...setDataTestEntityId('search-autocomplete-dropdown')}
            >
              {
                (
                  <Input
                    suffix={
                      isSearching ? (
                        <LoadingOutlined />
                      ) : (
                        <SearchOutlined onClick={this.onSearch} />
                      )
                    }
                    onPressEnter={this.onSearch}
                    {...setDataTestEntityId('topbar-search-input')}
                  />
                ) as React.ReactElement<InputProps>
              }
            </AutoComplete>
          </Tooltip>
          {searchQuery.length > 0 && (
            <Button
              type="text"
              title="Clear"
              onClick={() => dispatch(actions.userChangeSearchQuery(''))}
              {...setDataTestEntityId('topbar-search-button')}
            >
              <CloseOutlined />
            </Button>
          )}
          <div>
            <Button type="link" onClick={this.onClickMoreFilters}>
              {isShowMultipleSearchBar ? (
                <>
                  Less filters <CaretUpOutlined />
                </>
              ) : (
                <>
                  More filters <CaretDownOutlined />
                </>
              )}
            </Button>
          </div>
        </InputGroup>
        {!!count && (
          <Button type="dashed">
            {focused + 1}/{count}
          </Button>
        )}
        {count > 1 && (
          <div style={{ minWidth: 100 }}>
            <Button.Group>
              <Button
                type="text"
                onClick={this.prevSearch}
                {...setDataTestEntityId('topbar-search-prev-button')}
              >
                <LeftOutlined />
              </Button>
              <Button
                type="text"
                onClick={this.nextSearch}
                {...setDataTestEntityId('topbar-search-next-button')}
              >
                <RightOutlined />
              </Button>
            </Button.Group>
          </div>
        )}
      </div>
    );
  }
}

const advancedMap = createDelayedForScrollConnect((state: RootState) => {
  const count = getFoundElements(state).length;
  const {
    search: { focusedFoundElement, searchType, searchQuery, searching },
    ui: { isShowMultipleSearchBar, isVerticalMode },
  } = state;
  return {
    searchType,
    searchQuery,
    focused: focusedFoundElement,
    count,
    isSearching: searching,
    autoCompleteItems: getAutoCompleteOptions(state),
    visibleOpStatuses: getSelectedStatusesIds(state),
    tooltipMessage: getTooltipMessage(
      getUnfilteredFoundElements(state),
      count,
      searchQuery
    ),
    isShowMultipleSearchBar,
    isVerticalMode,
  };
});

export const SearchBarConnected = connect(advancedMap)(SearchBar);
