import { utc } from 'moment';
import * as d3Scale from 'd3-scale';
import { HOLD_FLIGHT_MIN_WIDTH } from '../../constants';

const Today = utc().toDate();
const Yesterday = utc()
  .subtract(1, 'day')
  .toDate();

export enum zoomLevels {
  WEEK,
  DAY,
  HOUR,
  HOUR3,
}

const weekWidth = 55;

export const getZoomLevel = (
  scaleX: d3Scale.ScaleTime<number, number>
): zoomLevels => {
  const dayWidth = scaleX(Today) - scaleX(Yesterday);
  if (dayWidth > 1200) {
    return zoomLevels.HOUR;
  }
  if (dayWidth > 200) {
    return zoomLevels.HOUR3;
  }
  if (dayWidth > weekWidth) {
    return zoomLevels.DAY;
  }
  return zoomLevels.WEEK;
};

export type ZoomLevelForHoldFlights =
  | 'fullView'
  | 'weeklyCompressed'
  | 'weeklyView'
  | 'unavailable';

export const getZoomLevelForHoldFlightRender = (
  scaleX: d3Scale.ScaleTime<number, number>
): ZoomLevelForHoldFlights => {
  const dayWidth = scaleX(Today) - scaleX(Yesterday);
  const leftMargin = 1;
  const holdFlightMinWidth = HOLD_FLIGHT_MIN_WIDTH + leftMargin;
  const hoursDivider = 4;
  const minWidthFullView = holdFlightMinWidth * hoursDivider;
  if (dayWidth >= minWidthFullView) {
    return 'fullView';
  }
  if (dayWidth > weekWidth && dayWidth < holdFlightMinWidth) {
    return 'weeklyCompressed';
  }
  if (dayWidth < weekWidth) {
    return 'unavailable';
  }
  return 'weeklyView';
};
