import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar';
import timelineEvents, { TimelineEventsState } from './timeline-events';
import airports, { AirportsState } from './airports';
import timezones, { TimezonesState } from './timezones';
import aircraft, { AircraftState } from './aircraft';
import opstatuses, { OperationStatus } from './operational-statuses';
import emptyLegs, { EmptyLeg } from './empty-legs';
import flightDetails, { FlightDetailsState } from './flight-details';
import { SearchReducerShape, searchReducer } from './search';
import { DashboardReducerShape, dashboardReducer } from './dashboard';
import * as ui from './ui';
import user, { UserReducerShape } from './user';
import baseCompanies, { BaseCompaniesState } from './baseCompanies';
import peakDays, { PeakDaysState } from './peak-days';
import eventElementCreationEdit, {
  EventElementCreationEdit,
} from './event-element-creation-edit';
import iframeUi, { IframeUiState } from './iframe-ui';
import oneWayOfferController, {
  OneWayOfferController,
} from './one-way-offer-controller';
import emptyLegOfferController, {
  EmptyLegOfferController,
} from './empty-leg-offer-controller';
import email, { EmailState } from './email';
import errorState, { ErrorHandlingState } from './error-handling';
import nearestAircraft, { NearestAircraftState } from './nearest-aircraft';
import handoverRemarks, { HandoverRemarksState } from './handover-remarks';
import time, { TimeState } from './time';
import csTeam, { CsTeamState } from './csTeam';

export interface RootState {
  flightDetails: FlightDetailsState;
  airports: AirportsState;
  timezones: TimezonesState;
  aircraft: AircraftState;
  opstatuses: OperationStatus;
  emptyLegs: EmptyLeg;
  timelineEvents: TimelineEventsState;
  ui: ui.ReducerShape;
  user: UserReducerShape;
  search: SearchReducerShape;
  dashboard: DashboardReducerShape;
  loadingBar: any;
  baseCompanies: BaseCompaniesState;
  peakDays: PeakDaysState;
  eventElementCreationEdit: EventElementCreationEdit;
  iframeUi: IframeUiState;
  oneWayOfferController: OneWayOfferController;
  emptyLegOfferController: EmptyLegOfferController;
  email: EmailState;
  errorState: ErrorHandlingState;
  nearestAircraft: NearestAircraftState;
  handoverRemarks: HandoverRemarksState;
  time: TimeState;
  csTeam: CsTeamState;
}

export default combineReducers<RootState>({
  flightDetails,
  airports,
  timezones,
  aircraft,
  opstatuses,
  emptyLegs,
  timelineEvents,
  user,
  ui: ui.reducer,
  search: searchReducer,
  dashboard: dashboardReducer,
  loadingBar: loadingBarReducer,
  baseCompanies,
  peakDays: peakDays,
  eventElementCreationEdit,
  iframeUi,
  oneWayOfferController,
  emptyLegOfferController,
  email,
  errorState,
  nearestAircraft,
  handoverRemarks,
  time,
  csTeam,
});
