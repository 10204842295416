import { apolloClient } from '../../data-processing/graphql';
import { gql } from '@apollo/client';
import { nearestAircraftQueryVariables } from '../../types/operation-result-types';

const nearestAircraft = require('./get-nearest-aircraft.gql');

export function getNearestAircraft(args: {
  aircraftId: number;
  icao: string;
  dateMs: number;
}) {
  const { aircraftId, dateMs, icao } = args;
  return apolloClient.query<nearestAircraftQueryVariables>({
    query: gql(nearestAircraft),
    variables: {
      aircraftId,
      icao,
      dateMs,
    },
    fetchPolicy: 'no-cache',
  });
}
