import { store as reduxStore } from '../../../../../root';
import * as d3S from 'd3-selection';
import * as actions from '../../../../../actions';
import {
  getElementOffsetWithKoef,
  getLaneHeightKoef,
} from '../../../../../reducers/ui';
import {
  getBoundingElement,
  getEuclideanDistance,
  setDataTestEntityIdD3Elements,
} from '../../../../../utils';
import OneWayOffer from '../../../../../types/one-way-offer';
import { getICAOLabelFromAirport } from '../../../../../utils/airport';
import { MIN_RANGE_SELECTION } from '../../../../../constants';
import { getOfferStatusName } from '../../../../../utils/empty-leg-offer';
export const getOneWayOffersD3creator = (
  d: d3S.Selection<any, any, any, any>
): d3S.Selection<any, any, any, any> => {
  const state = reduxStore.getState();
  const { transform, segmentsVisibility, positionMap } = state.ui;
  const { airportsById } = state.airports;
  const { togglersState } = state.aircraft;

  const container = d
    .append('div')
    .classed('label-text', true)
    .classed('one-way-offer-text-label', true)
    .attr(
      'data-test-entity',
      setDataTestEntityIdD3Elements('one-way-offer-text-label')
    )
    .style('transform', d => `translate(${d.x}px, ${d.y}px)`)
    .style('padding-left', d => `${Math.max(-d.x, 0)}px`)
    .on('mouseover', function(d) {
      d3S.select(this).style('cursor', 'pointer');
      reduxStore.dispatch(
        actions.userHoverSegment([d, getBoundingElement(this), 'oneWayOffers'])
      );
    })
    .on('mouseout', function(d) {
      reduxStore.dispatch(actions.userCloseTooltipSegment());
    })
    .on('contextmenu', d => {
      d3S.event.preventDefault();
      d3S.event.stopPropagation();
      reduxStore.dispatch(
        actions.userOpenContextMenuForOneWayOffer({
          id: d.id,
          position: { x: d3S.event.clientX, y: d3S.event.clientY },
        })
      );
    })
    .on(
      'click',
      d =>
        getEuclideanDistance(reduxStore.getState().ui.timelineSelection) <
          MIN_RANGE_SELECTION &&
        reduxStore.dispatch(actions.userClickEditOneWayOffer(d.id))
    )
    .style(
      'top',
      el =>
        `${getElementOffsetWithKoef(
          segmentsVisibility,
          'oneWayOffers',
          togglersState[el.aircraftId],
          positionMap
        ) * transform.ky}px`
    )
    .style(
      'padding-right',
      d => `${Math.max(d.x + d.width - transform.scaleX.range()[1], 0)}px`
    )
    .style(
      'height',
      d =>
        `${(positionMap.oneWayOffers * transform.ky) /
          getLaneHeightKoef(
            segmentsVisibility,
            togglersState[d.aircraftId],
            positionMap
          )}px`
    )
    .style('width', d => `${d.width}px`)
    .text(
      (d: OneWayOffer) =>
        `${getICAOLabelFromAirport(
          airportsById[d.departureAirportId]
        )} - ${getOfferStatusName(d.offerStatus)}`
    );
  return container;
};
