import { PureComponent } from 'react';
import './styles.scss';
import { RootState } from '../../reducers';
import * as actions from '../../actions';
import { connect, DispatchProp } from 'react-redux';
import { getSortedVisibleAircraft } from '../../selectors';
import { subMiddle } from '../../root';
import { AnyAction } from 'redux';

interface StateProps {
  overallHeight: number;
  offset: number;
  timelineHeight: number;
  top: number;
}

export class VerticalScrollBar extends PureComponent<
  StateProps & DispatchProp<AnyAction>
> {
  ignoreUserScroll: boolean;
  ignoreUserZoom: boolean;
  onUserZoomVer = (action, state) => {
    if (this.ignoreUserZoom) {
      this.ignoreUserZoom = false;
      return false;
    }
    this.ignoreUserScroll = true;
    this.ref.scrollTop = -state.ui.transform.translateY;
  };
  catchRef = (ref: HTMLDivElement) => {
    if (ref) {
      this.ref = ref;
      subMiddle.on(actions.userZoomVer, this.onUserZoomVer);
    }
  };
  ref: HTMLDivElement;
  onScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (this.ignoreUserScroll) {
      this.ignoreUserScroll = false;
      return false;
    }
    const scrollTop = event?.currentTarget?.scrollTop;
    this.ignoreUserZoom = true;
    this.props.dispatch(actions.userScrollScrollbar(scrollTop));
  };
  componentWillUnmount() {
    subMiddle.off(actions.userZoomVer, this.onUserZoomVer);
  }
  render() {
    return (
      <div
        // what is 21????
        style={{ height: this.props.timelineHeight - 21, top: this.props.top }}
        className="timeline-vertical-scrollbar"
        ref={this.catchRef}
        onScroll={this.onScroll}
      >
        <div style={{ height: this.props.overallHeight, width: 1 }} />
      </div>
    );
  }
}
const horizontalScrollHeight = 20;
export const VerticalScrollBarConnected = connect<
  StateProps,
  DispatchProp<AnyAction>,
  {},
  RootState
>(state => ({
  overallHeight:
    state.ui.rowHeight *
      state.ui.transform.ky *
      getSortedVisibleAircraft(state).length +
    horizontalScrollHeight,
  offset: state.ui.transform.translateY,
  timelineHeight:
    state.ui.height -
    state.ui.marginTop -
    state.ui.filterBarHeight -
    state.ui.multipleSearchBarHeight -
    state.ui.holdLineHeight,
  top:
    state.ui.marginTop +
    state.ui.holdLineHeight +
    state.ui.controlsBarHeight +
    state.ui.timelineBarHeight +
    state.ui.filterBarHeight +
    state.ui.multipleSearchBarHeight,
}))(VerticalScrollBar);
