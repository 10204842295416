import * as d3Scale from 'd3-scale';
import * as d3Selection from 'd3-selection';
import { utc } from 'moment';

export const axisHourD3Creator = (
  entered: d3Selection.Selection<any, Date, any, any>,
  options: {
    verticesTopOffset: number;
    verticesHeight: number;
  },
  data: d3Scale.ScaleTime<number, number>
) => {
  const { verticesTopOffset, verticesHeight } = options;
  const rendered = entered.append('g').classed('tick', true);
  rendered
    .append('line')
    .attr('stroke', '#E0E0DE')
    .attr('y1', -verticesTopOffset + 23)
    .attr('y2', verticesHeight);
  rendered
    .attr('transform', d => `translate(${data(d)},0)`)
    .append('text')
    .text((d: Date) => utc(d).format('HH:mm'))
    .attr('x', 3)
    .attr('dy', '-1.3em')
    .attr('fill', 'rgba(0,0,0,0.85)')
    .classed('axis-hour3', true);
  return rendered;
};
