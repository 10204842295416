export const HANDOVER_REMARK_CARD_WIDTH = 520;
export const HANDOVER_HEADER_HEIGHT = 102;
export const EMPTY_REMARK_WARNING = 'Remark cannot be empty';
export const DEFINE_TYPE_WARNING = 'Please define a remark type (Ops|CS)';

export const HORemarkBackgroundColor = {
  ops: 'rgb(247, 216, 155, 0.2)',
  cs: 'rgb(222, 254, 246, 0.8)',
  tail: 'rgb(172, 213, 251, 0.2)',
} as const;

export const DATE_PICKER_WIDTH = 115;
