import Timezone from '../../types/timezone';

export interface TimezoneGVModel {
  timezoneId: {
    key: number;
  };
  cityList: string;
  regionName: string;
  dstUtcOffset: number;
  standardUtcOffsetMinutes: number;
  code: string;
  countryName: string;
  dstFromTimeUtc: number;
  dstToTimeUtc: number;
  effectiveFromTimeUtc: number;
  effectiveToTimeUtc: number;
  source: string;
  dateCreated: number;
  isActive: boolean;
}

export const transformToFE = (t: TimezoneGVModel): Timezone => {
  return {
    id: t.timezoneId.key,
    dstUtcOffset: t.dstUtcOffset,
    standardUtcOffset: t.standardUtcOffsetMinutes,
    countryName: t.countryName,
    dstFromTimeUtc: t.dstFromTimeUtc,
    dstToTimeUtc: t.dstToTimeUtc,
    effectiveFromTimeUtc: t.effectiveFromTimeUtc,
    effectiveToTimeUtc: t.effectiveToTimeUtc,
  };
};
