import { store as reduxStore } from '../../../../../root';
import * as d3S from 'd3-selection';
import { utc } from 'moment';
import {
  getElementOffsetWithKoef,
  getLaneHeightKoef,
} from '../../../../../reducers/ui';
import { GroundTime } from '../../../../../types/ground-time';
import { getICAOLabelFromAirport } from '../../../../../utils/airport';

export const getGroundTimeD3creator = (
  d: d3S.Selection<any, any, any, any>
): d3S.Selection<any, any, any, any> => {
  const state = reduxStore.getState();
  const { transform, segmentsVisibility, positionMap } = state.ui;
  const { airportsById } = state.airports;
  const { groundTimeType } = state.timelineEvents;
  const { togglersState } = state.aircraft;

  const container = d
    .append('div')
    .classed('label-text', true)
    .style(
      'transform',
      d =>
        `translate(${d.x}px, ${d.y -
          4 +
          d.ky *
            getElementOffsetWithKoef(
              segmentsVisibility,
              'groundTime',
              togglersState[d.aircraftId],
              positionMap
            )}px)`
    )
    .classed('ground-time-text-label', true)
    .style('padding-left', d => `${-d.x > d.width ? 0 : Math.max(-d.x, 0)}px`)
    .style(
      'padding-right',
      d => `${Math.max(d.x + d.width - transform.scaleX.range()[1], 0)}px`
    )
    .style(
      'height',
      d =>
        `${(positionMap.groundTime * d.ky) /
          getLaneHeightKoef(
            segmentsVisibility,
            togglersState[d.aircraftId],
            positionMap
          )}px`
    )
    .style('width', d => `${d.width}px`)
    .text((d: GroundTime) => {
      const isCPLGroundTimeType: boolean = groundTimeType === 'CPL';
      const mins =
        utc(d.end).diff(d.start, 'm') - (isCPLGroundTimeType ? 75 : 0);
      let hrs = 0;
      let justMins = 0;

      if ((isCPLGroundTimeType && mins > 0) || !isCPLGroundTimeType) {
        hrs = Math.floor(mins / 60);
        justMins = mins % 60;
      }
      let time = hrs
        ? `${hrs}h `
        : (isCPLGroundTimeType && !justMins && '0h ') || '';
      time += justMins ? `${justMins}m ` : '';
      return `${time}${getICAOLabelFromAirport(airportsById[d.baseAirportId])}`;
    });
  return container;
};
