import { Action, Success } from 'typescript-fsa';
import {
  all,
  call,
  delay,
  put,
  race,
  select,
  spawn,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import * as actions from '../actions';
import * as service from '../services/handover-remarks-service';
import Flight from '../types/flight';
import {
  HandoverRemarkResponse,
  HandoverEmail,
} from '../types/handover-remarks';
import { RootState } from '../reducers';
import { message } from 'antd';
import {
  getAircraftByIdMap,
  getAllAvailableAircraftByIdMap,
  getAvailableAircraftTypesByOperatingCompanyMap,
} from '../selectors';
import { HandoverRemarksState } from '../reducers/handover-remarks';
import { flatMap, isEqual, uniq } from 'lodash';
import Aircraft, { FilterByAircraftType } from '../types/aircraft';

import { ascending } from '../utils';
import { hasPermission } from '../utils/check-permissions';
import { connectHandoverWs } from '../services/webSocket';
import { handleError } from '../components/error-handling/utils';
import ContactSupportTeamMessage from '../components/contact-support-team';
import {
  MAX_RECONNECT_ATTEMPTS,
  ONE_MINUTE_MS,
  ONE_SECOND_MS,
} from '../constants';
import { CloseCircleOutlined } from '@ant-design/icons';
import { getCsTeamList } from '../services/cs-team-service/service';
import {
  getDividedFlightRemarks,
  sortCards,
} from '../components/handover-remarks/utils';

const isForFlight = (
  payload:
    | { isFor: Flight | number | null }
    | Success<actions.SaveHandoverRemarkPayload | string, boolean>
): payload is { isFor: Flight } => !!(payload as { isFor: Flight }).isFor?.id;

const isForTail = (
  payload:
    | { isFor: Flight | number | null }
    | Success<actions.SaveHandoverRemarkPayload | string, boolean>
): payload is { isFor: number } =>
  (payload as { isFor: number }).isFor !== undefined &&
  typeof (payload as { isFor: number }).isFor === 'number';

// Handover fetch
function* getAllHandoverRemarksSaga({ payload }) {
  const { reconnected } = payload;
  const hasHandoverViewPermission = yield select((state: RootState) =>
    hasPermission(state, 'AG-Timeline-Handover-View')
  );
  if (!hasHandoverViewPermission) return;
  yield put(actions.doGetAllHandoverRemarks.started());
  try {
    const result: HandoverRemarkResponse = yield call(
      service.getAllHandoverRemarks
    );
    if (!result || !result.Items) {
      const error = new Error('Failed to load OPS Handover remarks');
      yield put(
        actions.doGetAllHandoverRemarks.failed({
          params: null,
          error,
        })
      );
      handleError({
        message: error.message,
        content: '',
        error,
      });
    } else {
      yield put(
        actions.doGetAllHandoverRemarks.done({
          params: null,
          result: result.Items || [],
        })
      );

      if (!reconnected) {
        connectHandoverWs();
      } else {
        yield put(actions.wsHandoverSetAttemptToReconnect.done(null));
      }
    }
  } catch (error) {
    yield put(actions.doGetAllHandoverRemarks.failed({ params: null, error }));
  }
}

function* getHandoverRemarksForFlightSaga(flightLegIds: number[]) {
  yield put(
    actions.doGetHandoverRemarksForFlight.started({
      flightLegId: flightLegIds[0],
    })
  );
  try {
    const promises = flightLegIds.map(flightLegId =>
      call(service.getHandoverRemarksForFlight, flightLegId)
    );
    const result: HandoverRemarkResponse[] = yield all(promises);
    if (!result) {
      const error = new Error(
        `Failed to load OPS Handover remarks for flight# ${flightLegIds[0]}`
      );
      yield put(
        actions.doGetHandoverRemarksForFlight.failed({
          params: { flightLegId: flightLegIds[0] },
          error,
        })
      );
      handleError({
        message: error.message,
        content: '',
        error,
      });
    } else {
      const remarks = flatMap(result.map(r => r?.Items));
      const { csCards, opsCards } = getDividedFlightRemarks(remarks);
      yield put(
        actions.doGetHandoverRemarksForFlight.done({
          params: { flightLegId: flightLegIds[0] },
          result:
            [...opsCards.sort(sortCards), ...csCards.sort(sortCards)] || [],
        })
      );
    }
  } catch (error) {
    yield put(
      actions.doGetHandoverRemarksForFlight.failed({
        params: { flightLegId: flightLegIds[0] },
        error,
      })
    );
  }
}

function* getHandoverRemarksForAircraftSaga(aircraftId: number) {
  yield put(
    actions.doGetHandoverRemarksForAircraft.started({
      aircraftId,
    })
  );
  try {
    const result: HandoverRemarkResponse = yield call(
      service.getHandoverRemarksForAircraft,
      aircraftId
    );
    if (!result || !result.Items) {
      const tailNumber = yield select(
        (state: RootState) => getAircraftByIdMap(state)[aircraftId]?.tailNumber
      );
      const error = new Error(
        `Fail to load OPS Handover remarks for Aircraft ${tailNumber}`
      );
      yield put(
        actions.doGetHandoverRemarksForAircraft.failed({
          params: { aircraftId },
          error,
        })
      );
      handleError({
        message: error.message,
        content: '',
        error,
      });
    } else {
      yield put(
        actions.doGetHandoverRemarksForAircraft.done({
          params: { aircraftId },
          result: result.Items.sort(sortCards),
        })
      );
    }
  } catch (error) {
    yield put(
      actions.doGetHandoverRemarksForAircraft.failed({
        params: { aircraftId },
        error,
      })
    );
  }
}

function* getCurrentHandoverRemarksSaga(
  action: Action<
    | { isFor: Flight | number | null }
    | Success<actions.SaveHandoverRemarkPayload | string, boolean>
  >
) {
  if (!action.payload) return; // opens in all view mode
  let flightLegIds = [];
  let aircraftId;
  if (isForTail(action.payload)) {
    aircraftId = action.payload.isFor;
  } else if (isForFlight(action.payload)) {
    // open from context menu
    const { id, originalFlightLegId } = action.payload.isFor;
    flightLegIds.push(id);
    if (originalFlightLegId) {
      // support chargeable to operational flow
      flightLegIds.push(originalFlightLegId);
    }
  } else {
    // update after single save or delete action
    const { flight, aircraftId: stateTailId } = yield select(
      (state: RootState) => state.handoverRemarks
    );
    if (flight) {
      const { id, originalFlightLegId } = flight;
      originalFlightLegId
        ? flightLegIds.push(id, originalFlightLegId)
        : flightLegIds.push(id);
    }
    if (stateTailId) {
      aircraftId = stateTailId;
    }
    const isSubmitting = yield select(
      (state: RootState) => state.handoverRemarks.savingIds.length > 0
    );
    // prevent update on Submit save all
    if (isSubmitting) return;
  }
  if (flightLegIds.length > 0) {
    yield call(getHandoverRemarksForFlightSaga, flightLegIds);
  }
  if (aircraftId) {
    yield call(getHandoverRemarksForAircraftSaga, aircraftId);
  }
  return;
}
function* deleteHandoverRemarkSaga(action: Action<string>) {
  const id = action.payload;
  try {
    const result: boolean = yield call(service.deleteHandoverRemark, id);
    if (!result) {
      const mes = 'Failed to delete handover remark';
      yield put(
        actions.userDeleteHandoverRemark.failed({
          params: id,
          error: new Error(mes),
        })
      );
      message.error(mes);
    } else {
      yield put(
        actions.userDeleteHandoverRemark.done({
          params: id,
          result,
        })
      );
      message.success('Handover remark deleted successfully');
    }
  } catch (error) {
    yield put(actions.userDeleteHandoverRemark.failed({ params: id, error }));
    message.error('Failed to delete handover remark');
  }
}
function* saveHandoverRemarkSaga(
  action: Action<actions.SaveHandoverRemarkPayload>
) {
  const { remark, isNew } = action.payload;
  try {
    const result: boolean = yield call(service.saveHandoverRemark, remark);
    if (!result) {
      const mes = 'Failed to save remark';
      message.error(mes);
      yield put(
        actions.userSaveHandoverRemark.failed({
          params: action.payload,
          error: new Error(mes),
        })
      );
    } else {
      yield put(
        actions.userSaveHandoverRemark.done({
          params: action.payload,
          result,
        })
      );
      message.success(
        isNew ? 'New handover remark is created' : 'Handover remark is saved'
      );
    }
  } catch (error) {
    yield put(
      actions.userSaveHandoverRemark.failed({ params: action.payload, error })
    );
  }
}
function* updateFiltersSelectionSaga(
  action: Action<actions.ChangeHandoverAircraftFilterPayload>
) {
  const { ids, type } = action.payload;
  const allAvailableAircraftByIdMap: {
    [aircraftId: number]: Aircraft;
  } = yield select((state: RootState) => getAllAvailableAircraftByIdMap(state));
  const aircraftTypesByOperatingCompanyMap: {
    [companyId: number]: FilterByAircraftType[];
  } = yield select((state: RootState) =>
    getAvailableAircraftTypesByOperatingCompanyMap(state)
  );
  const filtersState: HandoverRemarksState['filters'] = yield select(
    (state: RootState) => state.handoverRemarks.filters
  );
  const { tail, acTypes } = filtersState;

  if (type === 'acTypes') {
    const filteredTailIds = tail
      .reduce<number[]>((acc, id) => {
        if (ids.includes(allAvailableAircraftByIdMap[id].aircraftTypeId)) {
          return acc.concat(id);
        }
        return acc;
      }, [])
      .sort(ascending);
    tail.sort(ascending);
    if (!isEqual(filteredTailIds, tail)) {
      // removing tails which are not presented in picked ac type
      yield put(
        actions.userChangeHandoverRemarksAircraftFilter({
          ids: filteredTailIds,
          type: 'tail',
        })
      );
    }
  }
  if (type === 'operator') {
    if (acTypes.length > 0) {
      const filteredAcTypeIds = uniq(
        ids.reduce((acc, id) => {
          let operatorAcTypes = [];
          for (const acTypeId of acTypes) {
            if (
              aircraftTypesByOperatingCompanyMap[id].find(
                f => f.id === acTypeId
              )
            ) {
              operatorAcTypes.push(acTypeId);
            }
          }
          return acc.concat(operatorAcTypes);
        }, [])
      ).sort(ascending);
      acTypes.sort(ascending);
      if (!isEqual(filteredAcTypeIds, acTypes)) {
        // removing actypes which are not presented in picked operator
        yield put(
          actions.userChangeHandoverRemarksAircraftFilter({
            ids: filteredAcTypeIds,
            type: 'acTypes',
          })
        );
      }
    }
  }
}

function* sendHandoverEmailSaga(action: Action<HandoverEmail>) {
  const email = action.payload;
  try {
    const result = yield call(service.sendHandoverEmail, email);
    yield put(
      actions.userSendHandoverEmail.done({
        params: action.payload,
        result: result?.statusText,
      })
    );
  } catch (error) {
    yield put(
      actions.userSendHandoverEmail.failed({ params: action.payload, error })
    );
  }
}

function* handoverReloadingSaga() {
  const showSupportMsg = yield select(
    (state: RootState) => state.handoverRemarks.showContactSupportMsg
  );
  if (showSupportMsg) return;
  yield put(actions.wsHandoverSetAttemptToReconnect.started(null));
  try {
    yield call(getAllHandoverRemarksSaga, { payload: { reconnected: true } });
  } catch (error) {
    handleError({
      error,
      message: error.message,
      content: JSON.stringify(error),
      url: 'handover/api',
    });
    yield put(actions.wsHandoverSetAttemptToReconnect.failed(null));
  }
}
const messageContent = {
  content: ContactSupportTeamMessage(
    'Handover remarks live updates is unavailable'
  ),
  duration: 0,
  icon: <CloseCircleOutlined onClick={() => message.destroy()} />,
};

function* handoverWsClosingNotifier() {
  const hideIssuesInformMessage = message.warning(
    'Connection to Handover remarks service lost, we are reloading data...',
    0
  );
  const { success, fail } = yield race({
    timeout: delay(ONE_MINUTE_MS * 5),
    success: take(actions.wsHandoverSetAttemptToReconnect.done),
    fail: take(actions.wsHandoverSetAttemptToReconnect.failed),
  });
  hideIssuesInformMessage();
  if (success) {
    message.success('Reloading Handover remarks data complete!', 1.5);
  }
  if (!success && !fail) {
    const showSupportMsg = yield select(
      (state: RootState) => state.handoverRemarks.showContactSupportMsg
    );
    if (!showSupportMsg) {
      yield put(actions.doShowHandoverContactSupportMessage());
      message.error(messageContent);
    }
  }
}
function* limitedHandoverWSReconnectSaga() {
  let reloadAttemptIteration = 1;
  let timeout = null;
  while (true) {
    yield take(actions.wsHandoverConnectionClosed);
    const showSupportMsg = yield select(
      (state: RootState) => state.handoverRemarks.showContactSupportMsg
    );
    if (showSupportMsg) continue;
    if (reloadAttemptIteration === MAX_RECONNECT_ATTEMPTS) {
      clearTimeout(timeout);
      yield put(actions.doShowHandoverContactSupportMessage());
      message.error(messageContent);
      yield put(actions.wsHandoverSetAttemptToReconnect.failed(null));
      break;
    }
    if (timeout) {
      clearTimeout(timeout);
    }
    if (reloadAttemptIteration > 1) {
      yield spawn(handoverWsClosingNotifier);
    }
    timeout = setTimeout(() => (reloadAttemptIteration = 1), ONE_MINUTE_MS);
    if (reloadAttemptIteration > 1) {
      yield delay(
        reloadAttemptIteration > 3 ? 30 * ONE_SECOND_MS : 10 * ONE_SECOND_MS
      );
      yield call(handoverReloadingSaga);
      reloadAttemptIteration++;
      continue;
    }
    yield call(handoverReloadingSaga);
    reloadAttemptIteration++;
  }
}

export function* getCsTeamListSaga() {
  const loaded = yield select(
    (state: RootState) => state.csTeam.csTeamList.length > 0
  );
  if (loaded) return;
  yield put(actions.doGetCsTeamList.started());
  try {
    const startFetchingTime = new Date().valueOf();
    const result = yield call(getCsTeamList);
    if (!result) {
      throw new Error(`Failed to fetch CS Team List. Response is ${result}`);
    }
    const endFetchingTime = new Date().valueOf();
    yield put(
      actions.doGetCsTeamList.done(
        {
          params: null,
          result,
        },
        {
          csTeamListLoadingTime: endFetchingTime - startFetchingTime,
        }
      )
    );
  } catch (error) {
    yield put(actions.doGetCsTeamList.failed({ params: null, error }));
  }
}

export function* handoverRemarksSaga() {
  yield all([
    limitedHandoverWSReconnectSaga(),
    takeLatest(actions.timelineMounted, getAllHandoverRemarksSaga),
    takeLatest(actions.wsHandoverSetAttemptToReconnect.done, connectHandoverWs),
    takeLatest(
      actions.userOpenHandoverRemarksDrawer,
      getCurrentHandoverRemarksSaga
    ),
    takeLatest(
      actions.userSaveHandoverRemark.done,
      getCurrentHandoverRemarksSaga
    ),
    takeLatest(
      actions.userDeleteHandoverRemark.done,
      getCurrentHandoverRemarksSaga
    ),
    takeLatest(
      actions.userDeleteHandoverRemark.started,
      deleteHandoverRemarkSaga
    ),
    takeEvery(actions.userSaveHandoverRemark.started, saveHandoverRemarkSaga),
    takeEvery(
      actions.userChangeHandoverRemarksAircraftFilter,
      updateFiltersSelectionSaga
    ),
    takeLatest(actions.userSendHandoverEmail.started, sendHandoverEmailSaga),
    takeLatest(actions.userOpenHandoverRemarksDrawer, getCsTeamListSaga),
  ]);
}
