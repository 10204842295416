import { FC } from 'react';
import * as actions from '../../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Divider } from 'antd';
import { ExtendedSegmentType } from '../../../../types/segment-types';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RootState } from '../../../../reducers';
import { hasPermission } from '../../../../utils/check-permissions';
import { isEqual } from 'lodash';

interface StateProps {
  segmentsVisibility: { [st in ExtendedSegmentType]: boolean };
  isDaySeparatorOn: boolean;
  hasEmptyLegsPermission: boolean;
  hasOneWayOffersPermission: boolean;
  hasHandoverViewPermission: boolean;
}

export const ElementsContent: FC = () => {
  const {
    segmentsVisibility,
    isDaySeparatorOn,
    hasEmptyLegsPermission,
    hasOneWayOffersPermission,
    hasHandoverViewPermission,
  } = useSelector<RootState, StateProps>(
    state => ({
      segmentsVisibility: state.ui.segmentsVisibility,
      isDaySeparatorOn: state.ui.isDaySeparatorOnTop,
      hasEmptyLegsPermission: hasPermission(state, 'AG-Timeline-View-EL-Offer'),
      hasOneWayOffersPermission: hasPermission(
        state,
        'AG-Timeline-View-OW-Offer'
      ),
      hasHandoverViewPermission: hasPermission(
        state,
        'AG-Timeline-Handover-View'
      ),
    }),
    isEqual
  );
  const dispatch = useDispatch();
  const onChangeSegmentVisibility = (type: ExtendedSegmentType | 'crew') => (
    e: CheckboxChangeEvent
  ) => dispatch(actions.userSetSegmentVisibility([type, e.target.checked]));
  const onChangeDaySeparatorView = (e: CheckboxChangeEvent) => {
    dispatch(actions.userSetDaysSeparatorVisibility(e.target.checked));
  };

  const {
    flights,
    generalNotes,
    emptyLegOffers,
    oneWayOffers,
    maintenances,
    maintenanceItems,
    crewRoster,
    crewRestNotes,
    handoverRemarks,
    handoverRemarks_cs,
  } = segmentsVisibility;
  return (
    <div
      data-test-entity="filter-bar-elements"
      className="unselectable checkbox-list"
    >
      <div>
        <Checkbox
          onChange={onChangeSegmentVisibility('flights')}
          checked={flights}
        >
          Flights
        </Checkbox>
      </div>
      <div>
        <Checkbox
          onChange={onChangeSegmentVisibility('maintenances')}
          checked={maintenances}
        >
          Maintenances
        </Checkbox>
      </div>
      <div>
        <Checkbox
          onChange={onChangeSegmentVisibility('maintenanceItems')}
          checked={maintenanceItems}
        >
          MEL
        </Checkbox>
      </div>
      <div>
        <Checkbox
          onChange={onChangeSegmentVisibility('generalNotes')}
          checked={generalNotes}
        >
          General Notes
        </Checkbox>
      </div>

      {hasEmptyLegsPermission && (
        <div>
          <Checkbox
            onChange={onChangeSegmentVisibility('emptyLegOffers')}
            checked={emptyLegOffers}
          >
            Empty Leg Offers
          </Checkbox>
        </div>
      )}
      {hasOneWayOffersPermission && (
        <div>
          <Checkbox
            onChange={onChangeSegmentVisibility('oneWayOffers')}
            checked={oneWayOffers}
          >
            One Way Offers
          </Checkbox>
        </div>
      )}
      <div>
        <Checkbox
          onChange={onChangeSegmentVisibility('crew')}
          checked={crewRoster}
        >
          Crew
        </Checkbox>
      </div>
      <div>
        <Checkbox
          onChange={onChangeSegmentVisibility('crewRestNotes')}
          checked={crewRestNotes}
        >
          Crew Rest Notes
        </Checkbox>
      </div>
      <Divider type="horizontal" style={{ margin: '10px 0' }} />
      <div>
        <Checkbox
          onChange={onChangeDaySeparatorView}
          checked={isDaySeparatorOn}
        >
          Day Separator
        </Checkbox>
      </div>
      {hasHandoverViewPermission && (
        <>
          <Divider type="horizontal" style={{ margin: '10px 0' }} />
          <div>
            <Checkbox
              onChange={onChangeSegmentVisibility('handoverRemarks')}
              checked={handoverRemarks}
            >
              OPS Handover
            </Checkbox>
          </div>
          <div>
            <Checkbox
              onChange={onChangeSegmentVisibility('handoverRemarks_cs')}
              checked={handoverRemarks_cs}
            >
              CS Handover
            </Checkbox>
          </div>
        </>
      )}
    </div>
  );
};
