import { FC } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { userClickEditOneWayOffer } from '../../../actions';
import { RootState } from '../../../reducers';
import { hasPermission } from '../../../utils/check-permissions';
import { ContextMenuSimpleWrapper } from '../wrappers';
import { Action } from 'typescript-fsa';

interface StateProps {
  hasEditEventPermission: boolean;
  isOpen: boolean;
  position: { x: number; y: number };
}

const OneWayOfferContextMenu: FC<StateProps &
  DispatchProp<Action<number | void>>> = ({
  hasEditEventPermission,
  isOpen,
  dispatch,
  position,
}) => {
  const onEdit = () => {
    dispatch(userClickEditOneWayOffer());
  };
  if (!isOpen || !hasEditEventPermission) return null;
  return (
    <ContextMenuSimpleWrapper position={position}>
      <li onClick={onEdit}>Edit</li>
    </ContextMenuSimpleWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  isOpen: state.oneWayOfferController.contextMenu.isOpen,
  position: state.oneWayOfferController.contextMenu.position,
  hasEditEventPermission: hasPermission(state, 'AG-Timeline-View-OW-Offer'),
});

export const OneWayOfferContextMenuConnected = connect(mapStateToProps)(
  OneWayOfferContextMenu
);
