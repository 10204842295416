import { CSSProperties, FC, useCallback, lazy, Suspense } from 'react';
import Aircraft from '../../../types/aircraft';
import { RootState } from '../../../reducers';
import * as actions from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { AircraftTogglerState } from '../../../reducers/aircraft';
import { AircraftComponent } from '../../aircraft';
import {
  getSortedVisibleAircraft,
  getBaseCompaniesIds,
  getUnavailableForAdvertisementMapLabels,
} from '../../../selectors';
import { getTailsWithHandover } from '../../../selectors/handover-remarks';
import { isEqual } from 'lodash';

// @ts-ignore
// const RemoteComponent = lazy(() => import('remote/Roster'));

interface StateProps {
  aircraft: Aircraft[];
  holdAircraftLength: number;
  translateY: number;
  transformKy: number;
  rowHeight: number;
  planeBlockWidth: number;
  hoveredAircraftIndex: number;
  baseCompaniesIds: number[];
  hasUnavailableForAdvertisement: { [aircraftId: number]: boolean };
  aircraftTogglerStateMap: { [aircraftId: number]: AircraftTogglerState };
  tailsWithHandover: Set<number>;
}
export const AircraftList: FC<{}> = () => {
  const dispatch = useDispatch();
  const clickElementsToggler = useCallback(
    (
      type: 'crew' | 'notes',
      value: boolean,
      aircraftId: number,
      top: number
    ) => {
      dispatch(
        actions.userToggleTailElementsVisibility({
          type,
          value,
          aircraftId,
          top,
        })
      );
    },
    []
  );

  const {
    aircraft,
    aircraftTogglerStateMap,
    baseCompaniesIds,
    hasUnavailableForAdvertisement,
    holdAircraftLength,
    hoveredAircraftIndex,
    planeBlockWidth,
    rowHeight,
    tailsWithHandover,
    transformKy,
    translateY,
  } = useSelector<RootState, StateProps>(
    state => ({
      aircraft: getSortedVisibleAircraft(state),
      aircraftTogglerStateMap: state.aircraft.togglersState,
      baseCompaniesIds: getBaseCompaniesIds(state),
      hoveredAircraftIndex: state.ui.hoveredAircraftIndex,
      hasUnavailableForAdvertisement: getUnavailableForAdvertisementMapLabels(
        state
      ),
      holdAircraftLength: state.ui.visibleHoldAcIds.length,
      rowHeight: state.ui.rowHeight,
      planeBlockWidth: state.ui.planeBlockWidth,
      tailsWithHandover: getTailsWithHandover(state),
      transformKy: state.ui.transform.ky,
      translateY: state.ui.transform.translateY,
    }),
    isEqual
  );
  const rootStyle: CSSProperties = {
    transform: `translateY(${translateY}px)`,
  };
  return (
    <div className="aircraft-list" style={rootStyle}>
      {(aircraft || []).map((a, index) => {
        return (
          <div>
            <AircraftComponent
              aircraft={a}
              index={index}
              onClickElementsToggler={clickElementsToggler}
              aircraftTogglerState={aircraftTogglerStateMap[a.id]}
              key={a.id}
              rowHeight={rowHeight}
              transformKy={transformKy}
              hovered={hoveredAircraftIndex === holdAircraftLength + index}
              planeBlockWidth={planeBlockWidth}
              baseCompaniesIds={baseCompaniesIds}
              isUnavailableForAdvertisement={hasUnavailableForAdvertisement[a.id]}
              hasTailHandover={tailsWithHandover.has(a.id)}
            />
            {index === 0 && (
              <div
                style={{ width: '100vh', height: 200 }}
              >
                <Suspense fallback="Loading Button">
                  {/* <RemoteComponent /> */}
                </Suspense>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
