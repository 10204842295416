import styled from 'styled-components';
import { Select } from 'antd';

export const SelectComponentStyled = styled(Select)<{
  $focused: boolean;
  $multiple: boolean;
}>`
  .ant-select-selector {
    cursor: pointer;
  }
  .ant-select-arrow {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-select-clear {
    height: 14px;
    margin-top: -5px;
  }
  .ant-select-selection-search-input {
    margin-left: 0 !important;
  }
  .ant-select-selection-overflow-item {
    align-items: center;
  }
  .ant-select-selection-overflow-item > span > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ant-select-selection-item {
    background: #fff;
    border: none;
    height: 22px;
    padding-left: 0;
    padding-right: ${p => (p.$multiple ? '14px' : 0)};
  }
  .ant-select-selection-item-content {
    margin-right: 6px;
  }
  .ant-select-selection-overflow-item.ant-select-selection-overflow-item-rest {
    display: ${p => (p.$focused ? 'inline-block' : 'none')};
    .ant-select-selection-item {
      color: #bfbfbf;
    }
  }
  .ant-select-selection-search {
    position: absolute;
    left: 0;
    margin-left: 0;
  }
`;
