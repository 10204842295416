import { Button, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import classNames from 'classnames';
import { Field, Form, FormikProps } from 'formik';
import { FC, useEffect } from 'react';

import { EmailAddressAutoComplete } from './address-autocomplete';
import { EmailBodyEditor } from './body-editor';
import { EmailFormFieldName, FieldTitle } from './constants';
import { SharedProps, EmailFormValues } from './interfaces';
import { getHandoverEmailTemplate } from '../handover-remarks/utils';
import { transformFromHandoverToMainTemplate } from './utils';

export const EmailFormComponent: FC<{
  formikProps: FormikProps<EmailFormValues>;
} & SharedProps> = props => {
  const {
    isArincEmail,
    isHandoverEmail,
    isLoading,
    onClose,
    formikProps,
    sending,
    formValues,
    fromUser,
    handoverRemarks,
    filters,
  } = props;
  const {
    values: { to },
    setValues,
    resetForm,
  } = formikProps;

  useEffect(() => {
    if (!isLoading) {
      if (isHandoverEmail) {
        const template = transformFromHandoverToMainTemplate(
          getHandoverEmailTemplate(handoverRemarks, fromUser, filters)
        );
        setValues(template);
      } else {
        setValues(formValues);
      }
      return () => {
        resetForm();
      };
    }
  }, [isLoading]);

  const className = classNames({
    'email-form': true,
    'email-form-loading': isLoading,
  });
  const showBcc = isHandoverEmail ? false : !isArincEmail || to.length > 0;
  return (
    <Form className={className}>
      <Field name={EmailFormFieldName.to}>
        {fieldProps => {
          return (
            <EmailAddressAutoComplete
              {...props}
              {...formikProps}
              {...fieldProps}
              title={FieldTitle.to}
              name={EmailFormFieldName.to}
            />
          );
        }}
      </Field>
      <Field name={EmailFormFieldName.cc}>
        {fieldProps => {
          return (
            <EmailAddressAutoComplete
              {...props}
              {...formikProps}
              {...fieldProps}
              title={FieldTitle.cc}
              name={EmailFormFieldName.cc}
            />
          );
        }}
      </Field>
      {showBcc && (
        <Field name={EmailFormFieldName.bcc}>
          {fieldProps => {
            return (
              <EmailAddressAutoComplete
                {...props}
                {...formikProps}
                {...fieldProps}
                title={FieldTitle.bcc}
                name={EmailFormFieldName.bcc}
              />
            );
          }}
        </Field>
      )}
      <div className="email-form-field-wrapper">
        <label className="email-form-field-wrapper-label">Subject</label>
        <Input
          onChange={formikProps.handleChange}
          id={EmailFormFieldName.subject}
          value={formikProps.values.subject}
          name={EmailFormFieldName.subject}
          className="email-form-field-wrapper-input"
        />
      </div>
      <Field name={EmailFormFieldName.body}>
        {fieldProps => {
          return isArincEmail ? (
            <TextArea
              onChange={formikProps.handleChange}
              id={EmailFormFieldName.body}
              value={formikProps.values.body}
              name={EmailFormFieldName.body}
              autoSize={false}
              className="email-form-field-wrapper-textarea"
            />
          ) : (
            <EmailBodyEditor
              {...props}
              {...formikProps}
              {...fieldProps}
              name={EmailFormFieldName.body}
            />
          );
        }}
      </Field>
      <div className="email-form-footer">
        <Button
          htmlType="reset"
          onClick={onClose}
          className="mx-event-form-footer-button"
        >
          Cancel
        </Button>
        <Button
          htmlType="submit"
          key="save"
          type="primary"
          id="email-submit"
          loading={sending}
        >
          Send
        </Button>
      </div>
    </Form>
  );
};
