const SeatingManIcon = () => (
  <svg height="10px" viewBox="0 0 9 11" version="1.1">
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.85"
      fillOpacity="0.85"
    >
      <g
        transform="translate(-71.000000, -523.000000)"
        fill="#002140"
        fillRule="nonzero"
      >
        <g transform="translate(4.000000, 522.000000)">
          <g>
            <g transform="translate(67.000000, 0.000000)" opacity="0.5">
              <path d="M2.019375,2.92591643 C1.580625,2.48602922 1.580625,1.76980261 2.019375,1.32991541 C2.458125,0.890028198 3.1725,0.890028198 3.61125,1.32991541 C4.05,1.76980261 4.05,2.48602922 3.61125,2.92591643 C3.166875,3.37144322 2.458125,3.37144322 2.019375,2.92591643 Z M1.125,8.89823122 L1.125,3.82260959 L0,3.82260959 L0,8.89823122 C0,10.4547552 1.26,11.718021 2.8125,11.718021 L6.1875,11.718021 L6.1875,10.5901051 L2.8125,10.5901051 C1.87875,10.5901051 1.125,9.83440144 1.125,8.89823122 Z M9,11.1935401 L6.148125,8.33427326 L4.21875,8.33427326 L4.21875,6.25890797 C5.00625,6.90745963 6.24375,7.47705716 7.3125,7.47705716 L7.3125,6.25890797 C6.37875,6.27018713 5.281875,5.76826455 4.685625,5.10843373 L3.898125,4.2342989 C3.79125,4.11586773 3.65625,4.01999487 3.51,3.95231992 C3.346875,3.8733658 3.16125,3.82260959 2.97,3.82260959 L2.953125,3.82260959 C2.255625,3.82260959 1.6875,4.39220713 1.6875,5.091515 L1.6875,8.33427326 C1.6875,9.27044348 2.44125,10.0261471 3.375,10.0261471 L6.226875,10.0261471 L8.195625,12 L9,11.1935401 Z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SeatingManIcon;
