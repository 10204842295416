import { PureComponent } from 'react';
import * as d3Scale from 'd3-scale';
import { utc } from 'moment';
import EventElement from '../../../../../types/event-element';
import { isMaintenance } from '../../../../../types/maintenance';
import { getMaintenanceSegmentBorderColor } from '../../../../../common/maintenance/maintenance-colorization';

import {
  getBorderColorToEmptyLeg,
  getBorderColorToOneWay,
} from '../../../../../common/empty-leg-and-one-way/empty-leg-one-way-colorization';
import { isEmptyLegOffer } from '../../../../../utils/empty-leg-offer';
import { isOneWayOffer } from '../../../../../utils/one-way-offer';

interface Props {
  scale: d3Scale.ScaleTime<number, number>;
  y: number;
  element: EventElement;
}
export default class ElementPreviewContent extends PureComponent<Props> {
  render() {
    const { element, scale, y } = this.props;

    return (
      <>
        <text
          x={scale(element.start) - 45}
          y={y + 8}
          width={40}
          opacity={0.5}
          fontSize={12}
        >
          {utc(element.start).format('HH:mm [Z]')}
        </text>
        <rect
          x={scale(element.start)}
          y={y}
          width={scale(element.end) - scale(element.start)}
          height={6}
          fill={getFillColor(element)}
        />
        <text
          x={scale(element.end) + 5}
          y={y + 8}
          width={40}
          opacity={0.5}
          fontSize={12}
        >
          {utc(element.end).format('HH:mm [Z]')}
        </text>
      </>
    );
  }
}

function getFillColor(element: EventElement): string {
  if (isMaintenance(element)) {
    return getMaintenanceSegmentBorderColor(
      element.maintenanceOrderType,
      element.maintenanceType
    );
  }
  if (isEmptyLegOffer(element)) {
    return getBorderColorToEmptyLeg(element.offerStatus);
  }
  if (isOneWayOffer(element)) {
    return getBorderColorToOneWay(element.offerStatus);
  }

  return 'lightgrey';
}
