import { PureComponent } from 'react';
import { isSpecialLoad } from '../../common/flight/flight-check-status';
import { getOrderTypeColor } from '../../common/flight/flight-colorization';
import { CENTRED_DOT_SYMBOL_BOLD } from '../../constants';
import Flight, { OperationalStatus } from '../../types/flight';
import { setDataTestEntityId } from '../../utils';
import LockedIcon from '../svg-icons/flight-details-bar/locked';

interface Props {
  selectedFlight: Flight;
}

class OrderTypeAndFlightStateRow extends PureComponent<Props> {
  getBusinessTypeColors() {
    const {
      legOperationalStatusId,
      orderBusinessTypeId,
    } = this.props.selectedFlight;
    if (legOperationalStatusId === OperationalStatus.NO_SHOW) {
      return {
        backgroundColor: '#696969',
        color: 'white',
      };
    }
    const orderTypeColors = getOrderTypeColor(orderBusinessTypeId);
    return {
      backgroundColor: orderTypeColors.primaryColor,
      color: orderTypeColors.contrastTextColor,
    };
  }
  isNoShow() {
    return (
      this.props.selectedFlight.legOperationalStatusId ===
      OperationalStatus.NO_SHOW
    );
  }
  render() {
    const {
      lockedFlight,
      demoFlight,
      sensitive,
      flightOrderId,
      id,
    } = this.props.selectedFlight;
    return (
      <div style={this.getBusinessTypeColors()} className="ft-type-row">
        <span className="ft-icons-row">
          {!this.isNoShow() && lockedFlight && (
            <span
              className="ft-locked-icon"
              {...setDataTestEntityId(`tooltip-flight-locked-icon`)}
            >
              <LockedIcon />
            </span>
          )}
          {!this.isNoShow() && isSpecialLoad(this.props.selectedFlight) && (
            <span
              className="ft-sl-icon"
              {...setDataTestEntityId(`tooltip-flight-sl-icon`)}
            >
              SL
            </span>
          )}
          {!this.isNoShow() && demoFlight && (
            <span
              className="ft-demo"
              {...setDataTestEntityId(`tooltip-flight-demo`)}
            >
              Demo
            </span>
          )}
          {!this.isNoShow() && sensitive && (
            <span
              className="ft-sensitive"
              {...setDataTestEntityId(`tooltip-flight-sensitive`)}
            >
              Sensitive
            </span>
          )}

          {this.isNoShow() && <span>{'No-Show'}</span>}
        </span>

        <span
          className="ft-type-flight-id"
          {...setDataTestEntityId(`tooltip-flight-type-id`)}
        >
          {`${flightOrderId} ${CENTRED_DOT_SYMBOL_BOLD} ${id}`}
        </span>
      </div>
    );
  }
}

export default OrderTypeAndFlightStateRow;
