import { PureComponent } from 'react';
import * as actions from '../../../actions';
import Aircraft from '../../../types/aircraft';
import { AircraftLocationConnected } from './location';
import { connect } from 'react-redux';
import { HoveredTailPart } from '../tooltip';
import { setDataTestEntityId, shortenStringLength } from '../../../utils';
import { Tooltip } from 'antd';
import { RootState } from '../../../reducers';
import { getTailHandoverTooltipText } from '../../../selectors/handover-remarks';

interface StateProps {
  handoverText: string;
}
interface OwnProps {
  aircraft: Aircraft;
  baseCompaniesIds: number[];
  isUnavailableForAdvertisement: boolean;
  hasTailHandover: boolean;
}
interface DispatchProps {
  onHover: (aircraftId: number, part: HoveredTailPart, ref) => void;
  onUnhover: () => void;
  onClickHandover: (id: number) => void;
}
class SecondaryInfo extends PureComponent<
  OwnProps & StateProps & DispatchProps
> {
  ref: HTMLDivElement;
  getRef = ref => (this.ref = ref);
  onHover = () => {
    this.props.onHover(this.props.aircraft.id, 'main', this.ref);
  };
  onUnhover = () => {
    this.props.onUnhover();
  };
  onClickHO = e => {
    e.stopPropagation();
    this.props.onClickHandover(this.props.aircraft?.id);
  };
  render() {
    const {
      aircraft,
      baseCompaniesIds,
      hasTailHandover,
      handoverText,
    } = this.props;
    return (
      <>
        <div
          className="aircraft-content-secondary-info"
          {...setDataTestEntityId('aircraft-content-secondary-info')}
        >
          <div
            onMouseEnter={this.onHover}
            onMouseLeave={this.onUnhover}
            ref={this.getRef}
            className="aircraft-content-secondary-info-type"
            {...setDataTestEntityId('aircraft-content-secondary-info-type')}
          >
            {aircraft.aircraftTypeCode}
          </div>
          {hasTailHandover ? (
            <Tooltip
              title={shortenStringLength(handoverText, 100)}
              overlayStyle={{ whiteSpace: 'pre-line' }}
            >
              <div
                className="aircraft-content-secondary-info-handover"
                {...setDataTestEntityId(
                  'aircraft-content-secondary-info-handover'
                )}
                onClick={this.onClickHO}
              >
                H/O
              </div>
            </Tooltip>
          ) : null}
          <AircraftLocationConnected
            aircraft={aircraft}
            baseCompaniesIds={baseCompaniesIds}
            hasTailHandover={hasTailHandover}
          />
        </div>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onHover: (aircraftId, part, ref) => {
    dispatch(
      actions.userHoverTailBlock({
        aircraftId,
        part,
        ref,
      })
    );
  },
  onUnhover: () => dispatch(actions.userLeaveTailTooltipWithDebounce()),
  onClickHandover: (id: number) =>
    dispatch(
      actions.userOpenHandoverRemarksDrawer({
        isFor: id,
      })
    ),
});

const SecondaryInfoConnected = connect(
  (state: RootState, ownProps: OwnProps): StateProps => ({
    handoverText: getTailHandoverTooltipText(state, ownProps.aircraft.id),
  }),
  mapDispatchToProps
)(SecondaryInfo);

export default SecondaryInfoConnected;
