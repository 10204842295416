import { Moment, utc } from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { userChangePeakDaysRangeFilter } from '../../../../actions';
import { DatePickerComponent } from '../../../../common/components/date-range-filter/date-range-filter';
import { RootState } from '../../../../reducers';

import { FC } from 'react';
import { PeakDaysState } from '../../../../reducers/peak-days';
import { isEqual } from 'lodash';

export const DatePickerFrom: FC<{}> = () => {
  const dispatch = useDispatch();
  const date = useSelector<RootState, number | null>(
    state => state.peakDays.filters.from
  );
  const onChange = ({ type, value }) =>
    dispatch(userChangePeakDaysRangeFilter({ type, value }));
  return (
    <DatePickerComponent
      containerClassName="event-list-top-filter-input"
      date={date}
      onChangeRange={onChange}
      type="from"
      title="Start"
    />
  );
};

export const DatePickerTo: FC<{}> = () => {
  const dispatch = useDispatch();
  const { from, to } = useSelector<RootState, PeakDaysState['filters']>(
    state => state.peakDays.filters,
    isEqual
  );
  const onChange = ({ type, value }) =>
    dispatch(userChangePeakDaysRangeFilter({ type, value }));
  const disabledDate = (current: Moment) => {
    if (from && current) {
      return (
        utc(current)
          .startOf('day')
          .valueOf() < from
      );
    }
    return false;
  };
  return (
    <DatePickerComponent
      containerClassName="event-list-top-filter-input"
      date={to}
      disabledDate={disabledDate}
      onChangeRange={onChange}
      type="to"
      title="End"
    />
  );
};
