import { PureComponent } from 'react';
import Airport from '../../../../types/airport';
import Aircraft from '../../../../types/aircraft';
import { DispatchProp, connect } from 'react-redux';
import { RootState } from '../../../../reducers';
import { Popover } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import '../styles.scss';
import { Header } from '../header';
import OneWayOffer from '../../../../types/one-way-offer';
import OneWayOfferCardContent from './content';
import TooltipOneWayCommon from '../../../tooltip/vistajet/one-way';
import EventElement from '../../../../types/event-element';
import { getAircraftById } from '../../../../selectors';
import { getBorderColorToOneWay } from '../../../../common/empty-leg-and-one-way/empty-leg-one-way-colorization';
import { hasPermission } from '../../../../utils/check-permissions';
import { AnyAction } from 'redux';

interface PreviewProps {
  isFocusing: boolean;
  isFocused: boolean;
  onFocus: () => void;
  onBlur: () => void;
  element: OneWayOffer;
  placement: TooltipPlacement;
}

interface StateProps {
  airportsById: { [id: string]: Airport };
  aircraft: Aircraft;
  hasCheckedOneWayOffersPermission: boolean;
}

class OneWayOfferPreviewCardComponent extends PureComponent<
  PreviewProps & StateProps & DispatchProp<AnyAction>
> {
  render() {
    const {
      element,
      airportsById,
      aircraft,
      isFocused,
      isFocusing,
      onFocus,
      onBlur,
      placement = 'right',
      hasCheckedOneWayOffersPermission,
    } = this.props;
    return (
      <Popover
        content={
          <TooltipOneWayCommon
            segmentData={element}
            airportsById={airportsById}
            aircraft={aircraft}
          />
        }
        overlayClassName="overlaped-popover"
        placement={placement}
      >
        <div
          className="card"
          style={{
            marginTop: 8,
            border: '1px solid #DDDDDD',
            borderRadius: 4,
            opacity: !isFocusing || isFocused ? 1 : 0.3,
          }}
          onMouseEnter={onFocus}
          onMouseLeave={onBlur}
        >
          <Header
            height={6}
            colors={[getBorderColorToOneWay(element.offerStatus)]}
          />
          <OneWayOfferCardContent
            element={element}
            airportsById={airportsById}
            hasCheckedOneWayOffersPermission={hasCheckedOneWayOffersPermission}
          />
        </div>
      </Popover>
    );
  }
}

export const OneWayOfferPreviewCard = connect<StateProps>(
  (state: RootState) => ({
    airportsById: state.airports.airportsById,
    aircraft:
      state.ui.dataForSegment &&
      getAircraftById(
        state,
        (state.ui.dataForSegment as EventElement).aircraftId
      ),
    hasCheckedOneWayOffersPermission: hasPermission(
      state,
      'AG-Timeline-View-OW-Offer'
    ),
  })
)(OneWayOfferPreviewCardComponent);
