import { transformToFE as transformCrewAssignment } from './crew-service/crew-assignment-model';
import { transformToFE as transformCrewRoster } from './crew-service/crew-roster-model';
import { transformToFE as transformEmptyLegOffer } from './empty-leg-offer-service/empty-leg-offer-model';
import { transformToFE as transformFlight } from './flight-service/flight-model';
import { transformToFE as transformMaintenance } from './maintenance-service/maintenance-model';
import { transformToFE as transformMaintenanceItem } from './maintenance-item-service/maintenance-item-model';
import { transformToFE as transformNote } from './note-service/note-model';
import { transformToFE as transformOneWayOffer } from './one-way-offer-service/one-way-offer-model';
import { transformToFE as transformAircraft } from './aircraft-service/aircraft-model';
import { transformAirportToFE } from './airport-service/airport-model';
import { transformToFE as transformTimezone } from './timezone-service/timezone-model';
import { transformPeakDayToFE } from './peak-day-service/peak-day-model';
import { WsIncome } from '../types/ws-income';

export const transformIncomeMessage = (segmentType: WsIncome['type'], msg) => {
  switch (segmentType) {
    case 'flights':
      return transformFlight(msg);
    case 'availabilityNotes':
    case 'generalNotes':
    case 'notes':
      return transformNote(msg);
    case 'crewAssignment':
      return transformCrewAssignment(msg);
    case 'crewRoster':
      return transformCrewRoster(msg);
    case 'emptyLegOffers':
      return transformEmptyLegOffer(msg);
    case 'maintenances':
      return transformMaintenance(msg);
    case 'maintenanceItems':
      return transformMaintenanceItem(msg);
    case 'oneWayOffers':
      return transformOneWayOffer(msg);
    case 'aircraft':
      return transformAircraft(msg);
    case 'airport':
      return transformAirportToFE(msg);
    case 'timezones':
      return transformTimezone(msg);
    case 'availabilityEvents':
      return transformPeakDayToFE(msg);
  }
};
