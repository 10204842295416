import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { getFilteredNearestAircraftTable } from '../../../selectors/nearest-aircraft';
import { NearestAircraftTableData } from '../../../types/nearest-aircraft';
import { NearestAircraftResizableTable } from './table-component';

import Flight from '../../../types/flight';
import { getTableBodyHeight } from '../utils';
import { isEqual } from 'lodash';

interface StateProps {
  downgradeTableData: NearestAircraftTableData[];
  inFleetTableData: NearestAircraftTableData[];
  upgradeTableData: NearestAircraftTableData[];
  flight: Flight | null;
  isVerticalMode: boolean;
}

interface OwnProps {
  bodyHeight: number;
}
export const NearestAircraftTables: FC<OwnProps> = ({ bodyHeight }) => {
  const {
    downgradeTableData,
    flight,
    inFleetTableData,
    upgradeTableData,
    isVerticalMode,
  } = useSelector<RootState, StateProps>(
    state => ({
      downgradeTableData: getFilteredNearestAircraftTable(
        state,
        'downgradeTableData'
      ),
      inFleetTableData: getFilteredNearestAircraftTable(
        state,
        'inFleetTableData'
      ),
      upgradeTableData: getFilteredNearestAircraftTable(
        state,
        'upgradeTableData'
      ),
      flight: state.nearestAircraft.flight,
      isVerticalMode: state.ui.isVerticalMode,
    }),
    isEqual
  );
  const flightTime = flight
    ? flight.departureUtcEstimated || flight.departureUtcScheduled
    : new Date().getTime();
  const tableBodyHeight = getTableBodyHeight(isVerticalMode, bodyHeight);
  return (
    <div className="nearest-ac-modal-body">
      <NearestAircraftResizableTable
        dataSource={inFleetTableData}
        flightTime={flightTime}
        title={() => (
          <div className="nearest-ac-modal-body-table-title">In Fleet:</div>
        )}
        tableBodyHeight={tableBodyHeight}
      />
      <NearestAircraftResizableTable
        dataSource={upgradeTableData}
        flightTime={flightTime}
        title={() => (
          <div className="nearest-ac-modal-body-table-title">Upgrade:</div>
        )}
        tableBodyHeight={tableBodyHeight}
      />
      <NearestAircraftResizableTable
        dataSource={downgradeTableData}
        flightTime={flightTime}
        title={() => (
          <div className="nearest-ac-modal-body-table-title">Downgrade:</div>
        )}
        tableBodyHeight={tableBodyHeight}
      />
    </div>
  );
};
