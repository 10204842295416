import actionCreatorFactory from 'typescript-fsa';
import Flight, {
  FlightLabelTimeType,
  HoldFlightsPoolHoursDelimiter,
  UpgradeReason,
} from '../types/flight';
import { ZoomTransform } from 'd3-zoom';
import {
  SegmentType,
  PeakDaysSegmentType,
  ExtendedSegmentType,
} from '../types/segment-types';
import { SearchType } from '../reducers/search';
import { DashboardWidgetType } from '../reducers/dashboard';
import { DayZoomLevel, StatisticsMode } from '../reducers/ui';
import { StatusGroupType } from '../reducers/operational-statuses';
import EventElement, { OverlappedElements } from '../types/event-element';
import { EmptyLegGroupType } from '../reducers/empty-legs';
import { ReferenceObject } from 'popper.js';
import Note, { MergedNote } from '../types/note';
import PeakDay, {
  PeakDaysFilterDateType as RangeFilterDateType,
  PeakDaysSelectFilterOptionValue,
  PeakDaysSelectFilterType,
} from '../types/peak-day';
import { WsUpdateBatch } from '../data-processing/ws-income/ws-update-batch';
import Maintenance, {
  MXEventDtoFull,
  MXEventOrderTypeDto,
  MXServiceProviderDto,
} from '../types/maintenance';
import MaintenanceItem from '../types/maintenance-item';
import { CrewDuty, CrewAssignment } from '../types/crew-roster';
import EmptyLegOffer from '../types/empty-leg-offer';
import OneWayOffer from '../types/one-way-offer';
import Aircraft, {
  OperatingCompany,
  CurrentAcTypePositionPayload,
  SelectAircraftOrActypePayload,
  SelectAllAircraftPayload,
  SelectOperatingCompanyPayload,
  MxEventPresenceType,
} from '../types/aircraft';
import Airport from '../types/airport';
import Timezone from '../types/timezone';
import { UserProfile } from '../types/user';
import { GroundTimeType } from '../types/ground-time';
import { UserPreferences } from '../types/user-preferences';
import { HoveredTailPart } from '../components/aircraft/tooltip';
import NoteTimelineSegmentDto from '../types/NoteTimelineSegmentDto';
import { ApolloError } from 'apollo-server-express';
import {
  AutoAdvertisementInput,
  DeletePeakDayMutation,
  DeletePeakDayMutationVariables,
  EMailVoInput,
  MXEventDtoInput,
  nearestAircraftQuery,
  nearestAircraftQueryVariables,
  SavePeakDayMutation,
  SavePeakDayMutationVariables,
} from '../types/operation-result-types';
import { MailTemplate } from '../types/email';
import { GraphQLError } from 'graphql';
import { AxiosError } from 'axios';
import {
  HandoverRemarksViewByType,
  HandoverType,
  HandoverEmail,
  HandoverRemark,
  HandoverSelectFilterType,
} from '../types/handover-remarks';
import { WsHandoverUpdate } from '../types/ws-income';
import { CsTeamItem } from '../types/csTeam';

type ErrorUnion = Error | ApolloError | GraphQLError;
type RestErrorUnion = AxiosError | Error;

const actionCreator = actionCreatorFactory('timeline');
export const doAirportsMappersFetch = actionCreator.async<
  void,
  Airport[],
  RestErrorUnion
>('DO_AIRPORTS_MAPPERS_FETCH');
export const wsUpdateBatch = actionCreator<WsUpdateBatch>('WS_UPDATE_BATCH');
export const doAircraftMappersFetch = actionCreator.async<
  void,
  Aircraft[],
  RestErrorUnion
>('DO_AIRCRAFT_MAPPERS_FETCH');
export const doTimezonesMappersFetch = actionCreator.async<
  void,
  Timezone[],
  RestErrorUnion
>('DO_TIMEZONES_MAPPERS_FETCH');
export const doMaintenanceItemsMappersFetch = actionCreator.async<
  void,
  MaintenanceItem[],
  RestErrorUnion
>('DO_MAINTENANCE_ITEMS_MAPPERS_FETCH');
export const receivedUserAndRoles = actionCreator<{
  userProfile: UserProfile;
  roles: string[];
  userName: string;
}>('RECEIVED_USER_PROFILE');
export const userLogout = actionCreator('USER_LOGOUT');
export const userScrollScrollbar = actionCreator<number>(
  'USER_SCROLL_SCROLLBAR'
);
export const userHorizontalScrollingTimeline = actionCreator<{
  scrollAmount: number;
  date: number;
}>('USER_HORIZONTAL_SCROLLING_TIMELINE');
export type ResponseResultTypes =
  | Flight
  | Maintenance
  | MaintenanceItem
  | Note
  | CrewAssignment
  | CrewDuty
  | EmptyLegOffer
  | OneWayOffer;
export interface SegmentFetchParams {
  segmentType: SegmentType;
  dayRange: [number, number];
}
export const getSegmentRequest = actionCreator.async<
  SegmentFetchParams,
  ResponseResultTypes[],
  RestErrorUnion
>('DO_GET_SEGMENT_REQUEST');
export const userSetDaysSeparatorVisibility = actionCreator<boolean>(
  'USER_SET_DAYS_SEPARATOR_VISIBILITY'
);
export const userChangeTailForFlights = actionCreator.async<
  {
    flights: Flight[];
    newAircraftId: number;
    upgradeReason?: { [id: number]: UpgradeReason };
  },
  any,
  ErrorUnion
>('USER_CHANGE_TAIL_FOR_FLIGHTS');
export const userMarkFlight = actionCreator.async<
  {
    flight: Flight;
    newDemo: boolean;
    newLocked: boolean;
    newSensitive: boolean;
    newLineCheck: boolean;
  },
  any,
  ErrorUnion
>('USER_MARK_FLIGHT');
interface ResizePayload {
  refDimensions: [number, number];
  innerDimensions: [number, number];
}
export const timelineMounted = actionCreator<ResizePayload>('TIMELINE_MOUNTED');
export const timelineResized = actionCreator<ResizePayload>('TIMELINE_RESIZED');

export const userZoomHor = actionCreator<ZoomTransform>('USER_ZOOM_HOR');
export const userZoomVer = actionCreator<ZoomTransform>('USER_ZOOM_VER');
export const userSetTextAbility = actionCreator<boolean>(
  'USER_SET_TEXT_ABILITY'
);
export const doEnableTextLabels = actionCreator('DO_ENABLE_TEXT_LABELS');
export const doDisableTextLabels = actionCreator('DO_DISABLE_TEXT_LABELS');
export const userHoverAircraft = actionCreator<number>('USER_HOVER_AIRCRAFT');
export const userToggleTailElementsVisibility = actionCreator<{
  type: 'notes' | 'crew';
  value: boolean;
  aircraftId: number;
  top: number;
}>('USER_TOGGLE_TAIL_ELEMENTS_VISIBILITY');
export const userSetSegmentVisibility = actionCreator<
  [ExtendedSegmentType | 'crew', boolean]
>('USER_SET_SEGMENT_VISIBILITY');
export const userSetGroundTimeType = actionCreator<GroundTimeType>(
  'USER_SET_GROUND_TIME_TYPE'
);
export const userToggleAircraftByMxEvents = actionCreator<MxEventPresenceType>(
  'USER_TOGGLE_AIRCRAFTS_BY_MX_EVENT'
);
export const userToggleFilterPanel = actionCreator('USER_TOGGLE_FILTER_PANEL');
export const userToggleMultipleSearchBarPanel = actionCreator(
  'USER_TOGGLE_MULTIPLE_SEARCH_BAR_PANEL'
);
export const userResetMultipleSearchBarPanel = actionCreator(
  'USER_RESET_MULTIPLE_SEARCH_BAR_PANEL'
);
export const userGoToNow = actionCreator('USER_GO_TO_NOW');
export const userGoToCertainDate = actionCreator<moment.Moment>(
  'USER_GO_TO_CERTAIN_DATE'
);
export const userGoToNextDate = actionCreator('USER_GO_TO_NEXT_DAY');
export const userGoToPrevDate = actionCreator('USER_GO_TO_PREV_DAY');
export const userChangeSearchType = actionCreator<SearchType>(
  'USER_CHANGE_SEARCH_TYPE'
);
export const userChangeSearchQuery = actionCreator<string>(
  'USER_CHANGE_SEARCH_QUERY'
);
export const userChangeSearchQueryAllFilters = actionCreator<{
  query: string;
  searchType: SearchType;
}>('USER_CHANGE_SEARCH_QUERY_ALL_FILTERS');
export const userChangeSearchACQuery = actionCreator<{
  query: string;
  isFromNearestAC: boolean;
  isFromHandover: boolean;
}>('USER_CHANGE_SEARCH_AC_QUERY');
export const userSearchOrder = actionCreator.async<
  number,
  Flight[],
  ErrorUnion
>('USER_SEARCH_ORDER');
export interface SearchLegPayload {
  id: number;
  isNearestAC?: boolean;
}
export const userSearchLeg = actionCreator.async<
  SearchLegPayload,
  Flight,
  ErrorUnion
>('USER_SEARCH_LEG');
export const userSearchNext = actionCreator<number>('USER_SEARCH_NEXT');
export const userSearchPrev = actionCreator<number>('USER_SEARCH_PREV');
export const userFocusFirstFound = actionCreator('USER_FOCUS_FIRST_FOUND');

export const userOpenPFA = actionCreator.async<number, string, ErrorUnion>(
  'USER_OPEN_PFA'
);
export const userOpenGlobalHudPFA = actionCreator.async<
  {
    orderId: number;
    operatingCompanyId: number;
  },
  string
>('USER_OPEN_GLOBAL_HUD_PFA');
export const userOpenFP = actionCreator.async<number, string>('USER_OPEN_FP');
// --> Notes view, edit, delete
export interface CreateNotePayload {
  start: number;
  end: number;
  uniqIdentifier: string;
}
export const userClickCreateNote = actionCreator<CreateNotePayload>(
  'USER_CLICK_CREATE_NOTE'
);
export const doCreateNote = actionCreator<CreateNotePayload>('DO_CREATE_NOTE');
export const userSaveNote = actionCreator.async<
  NoteTimelineSegmentDto,
  any,
  ErrorUnion
>('USER_SAVE_NOTE');
export interface EditNotePayload
  extends Omit<CreateNotePayload, 'uniqIdentifier'> {
  id: number;
}
export const userClickOpenNote = actionCreator<EditNotePayload>(
  'USER_CLICK_OPEN_NOTE'
);
export const doOpenNoteForEdit = actionCreator<EditNotePayload>(
  'DO_OPEN_NOTE_FOR_EDIT'
);
export const userCloseNoteModal = actionCreator('USER_CLOSE_NOTE_MODAL');
export const userDeleteNote = actionCreator.async<number, any, ErrorUnion>(
  'USER_DELETE_NOTE'
);
// <-- Notes
// --> Aircraft filters
export const userToggleACTypeView = actionCreator<
  SelectAircraftOrActypePayload
>('USER_TOGGLE_AC_TYPE_VIEW');
export const userToggleAircraft = actionCreator<SelectAircraftOrActypePayload>(
  'USER_TOGGLE_AC_VIEW'
);
export const userToggleOperatingCompaniesView = actionCreator<
  SelectOperatingCompanyPayload
>('USER_TOGGLE_OPERATING_COMPANY_VIEW');
export const userToggleSelectAllAcTypes = actionCreator<
  SelectAllAircraftPayload
>('USER_TOGGLE_SELECT_ALL_AC_TYPES');
export const userToggleGroupByOperator = actionCreator<void>(
  'USER_TOGGLE_GROUP_BY_OPERATOR'
);
export const userToggleLabelOperatorsView = actionCreator(
  'USER_TOGGLE_LABEL_OPERATORS_VIEW'
);
export const userToggleSubChartersView = actionCreator(
  'USER_TOGGLE_SUB_CHARTERS_VIEW'
);
export const userToggleEditModeOfAcTypesPosition = actionCreator<boolean>(
  'USER_TOGGLE_EDIT_MODE_OF_AC_TYPES_POSITION'
);
export const userChangeCurrentAcTypePosition = actionCreator<
  CurrentAcTypePositionPayload
>('USER_CHANGE_CURRENT_AC_TYPES_POSITION');
export const doSetAcTypesPositionMap = actionCreator<
  CurrentAcTypePositionPayload
>('DO_SET_AC_TYPES_POSITION_MAP');
export const userResetChangesInAcTypesPositionMap = actionCreator<
  CurrentAcTypePositionPayload
>('USER_RESET_CHANGES_IN_AC_TYPES_POSITION_MAP');
// <-- Aircraft filters
export const userToggleOpStatusView = actionCreator<[StatusGroupType, boolean]>(
  'USER_TOGGLE_OP_STATUS_VIEW'
);
export const userToggleTimeLabelView = actionCreator<FlightLabelTimeType>(
  'USER_TOGGLE_TIME_LABEL_VIEW'
);
export const userToggleOpStatusViewAll = actionCreator<boolean>(
  'USER_TOGGLE_OP_STATUS_VIEW_ALL'
);
export const userToggleEmptyLegView = actionCreator<
  [EmptyLegGroupType, boolean]
>('USER_TOGGLE_EMPTY_LEG_VIEW');
export const userHoverFlight = actionCreator<
  [Flight, HTMLElement | SVGGElement]
>('USER_HOVER_FLIGHT');
export const userHoverSegment = actionCreator<
  [
    EventElement,
    HTMLElement | ReferenceObject,
    SegmentType | PeakDaysSegmentType
  ]
>('USER_HOVER_SEGMENT');
export const userHoverCrew = actionCreator<
  [EventElement, HTMLElement | ReferenceObject, SegmentType]
>('USER_HOVER_CREW');
export const userHoverOverlap = actionCreator<
  [OverlappedElements, HTMLElement | ReferenceObject]
>('USER_HOVER_OVERLAP');
export const userHoverMergedNotes = actionCreator<
  [MergedNote, HTMLElement | ReferenceObject, SegmentType, Note]
>('USER_HOVER_MERGED_NOTES');
export const userBlurOverlap = actionCreator('USER_BLUR_OVERLAP');
export const userCloseContextMenu = actionCreator('USER_CLOSE_CONTEXT_MENU');
export const userOpenNoteContextMenu = actionCreator<[ReferenceObject, Note]>(
  'USER_OPEN_NOTE_CONTEXT_MENU'
);
export const userCloseNoteMenu = actionCreator('USER_CLOSE_NOTE_MENU');
export const userOpenFlightMenu = actionCreator<
  [HTMLElement | ReferenceObject, Flight]
>('USER_OPEN_FLIGHT_MENU');
export const userCloseTooltip = actionCreator('USER_CLOSE_TOOLTIP');
export const userCloseTooltipSegment = actionCreator(
  'USER_CLOSE_TOOLTIP_SEGMENT'
);
export const userCloseTooltipCrew = actionCreator('USER_CLOSE_TOOLTIP_CREW');

export interface DashboardWidgetPayload {
  type: DashboardWidgetType;
  id?: number;
  flight?: Flight;
}
export const userSelectDashboardWidget = actionCreator<DashboardWidgetPayload>(
  'USER_SELECT_DASHBOARD_WIDGET'
);

export const userWidgetNext = actionCreator<number>('USER_WIDGET_NEXT');
export const userWidgetPrev = actionCreator<number>('USER_WIDGET_PREV');
export const userCloseDashboardWidget = actionCreator(
  'USER_CLOSE_DASHBOARD_WIDGET'
);
export const refreshTimer = actionCreator<{ now: number; today: number }>(
  'DO_REFRESH_TIMER'
);
export interface ChangeZoomPayload {
  zoomLevel: DayZoomLevel;
  toDate?: number;
}
export const userChangeZoomLevel = actionCreator<ChangeZoomPayload>(
  'USER_CHANGE_ZOOM_LEVEL'
);
// Drag and Drop --->
// Flights selection
export const userSelectFlightsForDnDBegin = actionCreator<{
  start: { x: number; y: number };
  firstAircraftId: number;
  dateStart: number;
}>('USER_SELECT_FLIGHTS_FOR_DND_BEGIN');
export const userSelectFlightsForDnDInProgress = actionCreator<{
  end: {
    x: number;
    y: number;
  };
}>('USER_SELECT_FLIGHTS_FOR_DND_IN_PROGRESS');
export const userSelectFlightsForDnDEnd = actionCreator<{
  selectedFlights: number[];
}>('USER_SELECT_FLIGHTS_FOR_DND_END');
// Flights drag
export const userDragFlightsBegin = actionCreator<{
  flightId: number;
  draggingPosition: {
    x: number;
    y: number;
  };
}>('USER_DRAG_FLIGHTS_BEGIN');
export const userDragFlightsInProgress = actionCreator<{
  draggingPosition: {
    x: number;
    y: number;
  };
}>('USER_DRAG_FLIGHTS_IN_PROGRESS');
export const userDragFlightsEnd = actionCreator<{
  newAircraftId: number;
}>('USER_DRAG_FLIGHTS_END');
export const userMoveFlightToTail = actionCreator<{
  newAircraftId: number;
  selectedFlightId: number;
}>('USER_MOVE_FLIGHT_TO_TAIL');
export const userCancelDraggingFlights = actionCreator(
  'USER_CANCEL_DRAGGING_FLIGHTS'
);
// Confirmation pop up
export const userCloseDndModal = actionCreator('USER_CLOSE_DND_MODAL');
export const userToggleFlightSelection = actionCreator<{ flightId: number }>(
  'USER_TOGGLE_FLIGHT_SELECTION'
);
// <--- Drag and Drop
export const doGetBaseCompanies = actionCreator.async<
  void,
  { id: number }[],
  RestErrorUnion
>('DO_GET_BASE_COMPANIES');
export const userTimelineSelectionBegin = actionCreator<{
  mouseX: number;
  mouseY: number;
  aircraftId: number;
  dateStart: number;
}>('USER_TIMELINE_SELECTION_BEGIN');
export const userTimelineSelection = actionCreator<{ x: number; y: number }>(
  'USER_TIMELINE_SELECTION'
);
export const userTimelineSelectionEnd = actionCreator<number>(
  'USER_TIMELINE_SELECTION_END'
);
interface CreateEventsMenuPayload {
  start: number;
  end: number;
  aircraftId: number;
  mousePositionAtRightClick: {
    x: number;
    y: number;
  };
}
export const userOpenCreateEventsMenu = actionCreator<CreateEventsMenuPayload>(
  'USER_OPEN_CREATE_EVENTS_MENU'
);
export const userOpenCreateEventsMenuFromTopBar = actionCreator(
  'USER_OPEN_CREATE_EVENTS_MENU_FROM_TOP_BAR'
);
export const userClickCreateMaintenance = actionCreator<{
  start: number;
  end: number;
  uniqIdentifier: string;
}>('USER_CLICK_CREATE_MAINTENANCE');
export const userOpenMxEmailTemplate = actionCreator<{
  mxEvent: MXEventDtoInput;
  serviceProviderName: string;
}>('USER_OPEN_MX_EMAIL_TEMPLATE');
export const userCancelEmail = actionCreator('USER_CANCEL_EMAIL');
export interface EmailTemplateParams {
  emailType: 'RTS' | 'AOG';
  operator: OperatingCompany;
  manufacturerId: number | null;
}
export const doFetchEmailTemplate = actionCreator.async<
  EmailTemplateParams,
  MailTemplate,
  ApolloError
>('DO_FETCH_AOG_EMAIL_TEMPLATE');
export const doFetchEmailAddressOptions = actionCreator.async<
  string,
  string[],
  ApolloError
>('DO_FETCH_EMAIL_ADDRESS_OPTIONS');
export const userResetEmailAddressOptions = actionCreator(
  'USER_RESET_EMAIL_ADDRESS_OPTIONS'
);
export const userSearchEmailAddressOptions = actionCreator<string>(
  'USER_SEARCH_EMAIL_ADDRESS_OPTIONS'
);
export const doCloseContextMenu = actionCreator('DO_CLOSE_CONTEXT_MENU');
export const userCloseIframe = actionCreator('USER_CLOSE_IFRAME');
interface ContextMenuForEventElementPayload {
  id: number;
  position: { x: number; y: number };
}
export const userOpenContextMenuForMxEvent = actionCreator<
  [ReferenceObject, Maintenance]
>('USER_OPEN_CONTEXT_MENU_FOR_MX_EVENT');
export const userClickEditMxEvent = actionCreator<{ id: number }>(
  'USER_CLICK_EDIT_MX_EVENT'
);
export const userClickCloneMxEvent = actionCreator<{ id: number }>(
  'USER_CLICK_CLONE_MX_EVENT'
);
export const doFetchMxEventById = actionCreator.async<
  { id: number },
  MXEventDtoFull,
  ApolloError
>('DO_FETCH_MX_EVENT_BY_ID');
export const doFetchMxOrderTypes = actionCreator.async<
  number,
  MXEventOrderTypeDto[],
  ApolloError
>('DO_FETCH_MX_ORDER_TYPES');
export const userSaveMxEvent = actionCreator.async<
  { mxEvent: MXEventDtoInput; serviceProviderName: string },
  string,
  ApolloError
>('USER_SAVE_MX_EVENT');
export const userSendMxEmail = actionCreator.async<
  { mailTemplate: EMailVoInput },
  string,
  ApolloError
>('USER_SEND_MX_EMAIL');
export const userSearchForServiceProviders = actionCreator.async<
  number,
  MXServiceProviderDto[],
  ApolloError
>('USER_SEARCH_FOR_SERVICE_PROVIDERS');
export const userResetCurrentAirport = actionCreator(
  'USER_RESET_CURRENT_AIRPORT'
);
export interface ChangeDatesPayload {
  type: 'start' | 'end';
  time: number;
}
export const userChangeDatesInEditDrawer = actionCreator<ChangeDatesPayload>(
  'USER_CHANGE_DATES_IN_EDIT_DRAWER'
);
export const userCloseMxEventModal = actionCreator('USER_CLOSE_MX_EVENT_MODAL');
export const userMoveIframe = actionCreator<[number, number]>(
  'USER_MOVE_IFRAME'
);
export interface IframeDragStartpayload {
  mousePos: {
    x: number;
    y: number;
  };
  nodeOffset: {
    x: number;
    y: number;
  };
}
export const userMoveIframeStart = actionCreator<IframeDragStartpayload>(
  'USER_MOVE_IFRAME_START'
);
export const userMoveIframeEnd = actionCreator<[number, number]>(
  'USER_MOVE_IFRAME_END'
);
export const userDeleteMxEvent = actionCreator.async<number, any, ErrorUnion>(
  'USER_DELETE_MX_EVENT'
);
export const userClickEditOneWayOffer = actionCreator<number | void>(
  'USER_CLICK_EDIT_ONE_WAY'
);
export const userOpenContextMenuForOneWayOffer = actionCreator<
  ContextMenuForEventElementPayload
>('USER_OPEN_CONTEXT_MENU_FOR_ONE_WAY_OFFER');
export const userOpenContextMenuForEmptyLegOffer = actionCreator<
  ContextMenuForEventElementPayload
>('USER_OPEN_CONTEXT_MENU_FOR_EMPTY_LEG_OFFER');
export const userOpenContextMenuForPeakDay = actionCreator<
  ContextMenuForEventElementPayload
>('USER_OPEN_CONTEXT_MENU_FOR_PEAK_DAY');
export const userResetIframePosition = actionCreator(
  'USER_RESET_IFRAME_POSITION'
);
export const doStopScroll = actionCreator('DO_STOP_SCROLL');
export const webSocketConnectionClosed = actionCreator('WS_CONNECTION_CLOSED');
export const setReInitLoadingDataOnWebSocketConnectionLost = actionCreator.async(
  'SET_RE_INIT_LOADING_DATA_ON_WEBSOCKET_CONNECTION_LOST'
);
export const userFocusOverlapCards = actionCreator('USER_FOCUS_OVERLAP_CARDS');
export const userUnhoverOvlElement = actionCreator('USER_UNHOVER_OVL_ELEMENT');

export const userHoverTooltipNote = actionCreator<number>(
  'USER_HOVER_TOOLTIP_NOTE'
);
export const userUnhoverTooltipNote = actionCreator<number>(
  'USER_UNHOVER_TOOLTIP_NOTE'
);
export const userHoverTailBlock = actionCreator<{
  aircraftId: number;
  part: HoveredTailPart;
  ref: ReferenceObject;
}>('USER_HOVER_TAIL_BLOCK');
export const userUnhoverTailTooltip = actionCreator(
  'USER_UNHOVER_TAIL_TOOLTIP'
);
export const userLeaveTailTooltipWithDebounce = actionCreator(
  'USER_LEAVE_TAIL_TOOLTIP_WITH_DEBOUNCE'
);
export const doSegmentsFetchingByDaysRange = actionCreator.async<
  {
    fetchedDays?: { start: number; end: number }[];
    totalSegmentsFetchingByDaysRangeLoadingTime?: number;
  },
  any,
  RestErrorUnion
>('DO_SEGMENTS_FETCHING_BY_DAYS_RANGE');
export const doGetUserPreferences = actionCreator.async<
  void,
  UserPreferences,
  RestErrorUnion
>('DO_GET_USER_PREFERENCES');
export const userSavePreferences = actionCreator.async('USER_SAVE_PREFERENCES');
export const userResetPreferences = actionCreator<{
  visibleAircraftTypeByOperatingCompanyMap: {
    [companyId: number]: number[];
  };
}>('USER_RESET_PREFERENCES');
export const doShowContactSupportMessage = actionCreator(
  'DO_SHOW_CONTACT_SUPPORT_MESSAGE'
);
export const userToggleSidebar = actionCreator('USER_TOGGLE_SIDEBAR');
export const userToggleHoldLineTypeView = actionCreator<number[]>(
  'USER_TOGGLE_HOLD_LINE_TYPE_VIEW'
);
export const doSetHeightForHoldAircraft = actionCreator<
  HoldFlightsPoolHoursDelimiter
>('DO_SET_HEIGHT_FOR_HOLD_AIRCRAFT');
export const userChangeStatisticsMode = actionCreator<StatisticsMode>(
  'USER_CHANGE_STATISTICS_MODE'
);
export const userClickToOpenArincEmail = actionCreator<number>(
  'USER_CLICK_TO_OPEN_ARINC_EMAIL'
);
export const userClickToSendArincEmail = actionCreator.async<
  { mailTemplate: EMailVoInput },
  string,
  ApolloError
>('USER_CLICK_TO_SEND_ARINC_EMAIL');
export const doHydrateAcTypeFiltersConfig = actionCreator<{
  map: { [companyId: number]: number[] };
  acIds: number[];
}>('DO_HYDRATE_AC_TYPE_FILTER_CONFIG');
export const userToggleExcludeIncludeEmptyLeg = actionCreator<
  AutoAdvertisementInput
>('USER_TOGGLE_EXCLUDE_INCLUDE_EMPTY_LEG');
export const doSetFirstError = actionCreator<{
  title: string;
  content: string;
}>('DO_SET_FIRST_ERROR');
export const doUpdateErrorDialog = actionCreator<{
  title: string;
  content: string;
}>('DO_UPDATE_ERROR_DIALOG');
export const doResetErrorHandlingState = actionCreator(
  'DO_RESET_ERROR_HANDLING_STATE'
);
// Nearest AC --->>>
export const userOpenNearestAircraftModal = actionCreator<Flight>(
  'USER_OPEN_NEAREST_AIRCRAFT_MODAL'
);

export const doGetNearestAircraftData = actionCreator.async<
  nearestAircraftQueryVariables,
  nearestAircraftQuery['nearestAircraft'] & {
    mainOperatorIds: number[];
    visibleAircraftIds: number[];
  },
  ErrorUnion
>('DO_GET_NEAREST_AIRCRAFT_DATA');
export const userChangeMaxFerryRange = actionCreator<[number, number]>(
  'USER_CHANGE_MAX_FERRY_RANGE'
);
export const userChangeNextFlightTimeRange = actionCreator<[number, number]>(
  'USER_CHANGE_NEXT_FLIGHT_TIME_RANGE'
);
export interface AcFilterConfigPayload {
  acTypeFilterByOperatorMap: { [companyId: number]: number[] };
}
export const doHydrateAircraftFilterData = actionCreator<AcFilterConfigPayload>(
  'DO_HYDRATE_AIRCRAFT_FILTER_DATA'
);
export const userToggleNearestAcSelectAllView = actionCreator<
  SelectAllAircraftPayload
>('USER_TOGGLE_NEAREST_AC_SELECT_ALL_VIEW');
export const userToggleNearestAcOperatorView = actionCreator<
  SelectOperatingCompanyPayload
>('USER_TOGGLE_NEAREST_AC_OPERATOR_VIEW');
export const userToggleNearestAcAircraftTypeView = actionCreator<
  SelectAircraftOrActypePayload
>('USER_TOGGLE_NEAREST_AC_AIRCRAFT_TYPE_VIEW');
export const userToggleNearestAcExactTailView = actionCreator<
  SelectAircraftOrActypePayload
>('USER_TOGGLE_NEAREST_AC_EXACT_TAIL_VIEW');
export const userToggleNearestAcSubChartersView = actionCreator(
  'USER_TOGGLE_NEAREST_AC_SUB_CHARTERS_VIEW'
);
export const userToggleNearestAircraftModalSize = actionCreator(
  'USER_TOGGLE_NEAREST_AIRCRAFT_MODAL_SIZE'
);
export const userCloseNearestAircraftModal = actionCreator(
  'USER_CLOSE_NEAREST_AIRCRAFT_MODAL'
);
// <<<-----

// PEAK DAYS ---->>
export const doGetPeakDays = actionCreator.async<
  any,
  PeakDay[],
  RestErrorUnion
>('DO_GET_PEAK_DAYS');
export const userTogglePeakDaysModal = actionCreator(
  'USER_TOGGLE_PEAK_DAYS_MODAL'
);
export const userClickEditPeakDay = actionCreator<number>(
  'USER_CLICK_EDIT_PEAK_DAY'
);
export const userClickCreatePeakDay = actionCreator(
  'USER_CLICK_CREATE_PEAK_DAY'
);
export const userSavePeakDay = actionCreator.async<
  SavePeakDayMutationVariables['day'],
  SavePeakDayMutation,
  ErrorUnion
>('USER_SAVE_PEAK_DAY');
export const userCloseSinglePeakDayModal = actionCreator(
  'USER_CLOSE_SINGLE_PEAK_DAY_MODAL'
);
export const userDeletePeakDay = actionCreator.async<
  DeletePeakDayMutationVariables['id'],
  DeletePeakDayMutation,
  ErrorUnion
>('USER_DELETE_PEAK_DAY');
export interface ChangeDaysRangeFilterPayload {
  type: RangeFilterDateType;
  value: number | null;
}
export const userChangePeakDaysRangeFilter = actionCreator<
  ChangeDaysRangeFilterPayload
>('USER_CHANGE_PEAK_DAYS_RANGE_FILTER');
export const userResetPeakDaysFilters = actionCreator(
  'USER_RESET_PEAK_DAYS_FILTERS'
);
export interface ChangeSelectFilterPayload {
  type: PeakDaysSelectFilterType;
  value: PeakDaysSelectFilterOptionValue[];
}
export const userChangeSelectFilter = actionCreator<ChangeSelectFilterPayload>(
  'USER_CHANGE_SELECT_FILTER'
);
// <<<-----
// --->>> HANDOVER REMARKS
export const userOpenHandoverRemarksDrawer = actionCreator<{
  isFor: Flight | number | null;
  navigateFrom?: string;
}>('USER_OPEN_HANDOVER_REMARKS_DRAWER');
export const userCloseHandoverRemarksDrawer = actionCreator(
  'USER_CLOSE_HANDOVER_REMARKS_DRAWER'
);
export const userPinHandoverRemarksDrawer = actionCreator<boolean>(
  'USER_PIN_HANDOVER_REMARKS_DRAWER'
);
export const userChangeHandoverRemarksRangeFilter = actionCreator<
  ChangeDaysRangeFilterPayload
>('USER_CHANGE_HANDOVER_REMARKS_RANGE_FILTER');
export interface ChangeHandoverAircraftFilterPayload {
  type: HandoverSelectFilterType;
  ids: number[];
}
export const userChangeHandoverRemarksAircraftFilter = actionCreator<
  ChangeHandoverAircraftFilterPayload
>('USER_CHANGE_HANDOVER_REMARKS_AIRCRAFT_FILTER');
export const userChangeHandoverViewByType = actionCreator<
  HandoverRemarksViewByType
>('USER_CHANGE_HANDOVER_VIEW_BY_TYPE');
export const userChangeHandoverViewByCsTeam = actionCreator<number[]>(
  'USER_CHANGE_HANDOVER_VIEW_BY_CS_TEAM'
);
export const userChangeHandoverViewByDepartment = actionCreator<HandoverType[]>(
  'USER_CHANGE_HANDOVER_VIEW_BY_DEPARTMENT'
);
export const doGetAllHandoverRemarks = actionCreator.async<
  void,
  HandoverRemark[],
  RestErrorUnion
>('DO_GET_ALL_HANDOVER_REMARKS');
export const doGetHandoverRemarksForFlight = actionCreator.async<
  {
    flightLegId: number;
  },
  HandoverRemark[],
  RestErrorUnion
>('DO_GET_HANDOVER_REMARKS_FOR_FLIGHT');
export const doGetHandoverRemarksForAircraft = actionCreator.async<
  {
    aircraftId: number;
  },
  HandoverRemark[],
  RestErrorUnion
>('DO_GET_HANDOVER_REMARKS_FOR_AIRCRAFT');
export const userDeleteHandoverRemark = actionCreator.async<
  string,
  boolean,
  RestErrorUnion
>('USER_DELETE_HANDOVER_REMARK');
export interface SaveHandoverRemarkPayload {
  remark: HandoverRemark;
  isNew: boolean;
  keepOpened?: boolean;
}
export const userSaveHandoverRemark = actionCreator.async<
  SaveHandoverRemarkPayload,
  boolean,
  RestErrorUnion
>('USER_SAVE_HANDOVER_REMARK');
export type WsHandoverUpdateBatch = WsHandoverUpdate | WsHandoverUpdate[];
export const wsHandoverUpdateBatch = actionCreator<WsHandoverUpdateBatch>(
  'WS_HANDOVER_UPDATE'
);
export const wsHandoverConnectionClosed = actionCreator(
  'WS_HANDOVER_CONNECTION_CLOSED'
);
export const wsHandoverSetAttemptToReconnect = actionCreator.async(
  'WS_HANDOVER_SET_ATTEMPT_TO_RECONNECT'
);
export const doShowHandoverContactSupportMessage = actionCreator(
  'DO_SHOW_HANDOVER_CONTACT_SUPPORT_MESSAGE'
);
export const userOpenHandoverEmailModal = actionCreator(
  'USER_OPEN_HANDOVER_EMAIL_MODAL'
);
export const userSendHandoverEmail = actionCreator.async<
  HandoverEmail,
  string,
  AxiosError
>('USER_SEND_HANDOVER_EMAIL');
export const doResetPushedId = actionCreator<string>('DO_RESET_PUSHED_ID');
export const doSetHandoverNavigateTo = actionCreator<{
  to: 'All' | 'Back' | Flight | number;
  remarkId?: string;
}>('DO_SET_HANDOVER_NAVIGATE_TO');
// <<<-----
export const doGetCsTeamList = actionCreator.async<
  void,
  CsTeamItem[],
  RestErrorUnion
>('DO_GET_CS_TEAM_LIST');
