import './styles.scss';

import { DownOutlined } from '@ant-design/icons';

import { Button, Dropdown, MenuProps } from 'antd';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { userChangeStatisticsMode } from '../../../actions';
import { RootState } from '../../../reducers';
import { StatisticsMode } from '../../../reducers/ui';
import {
  AogStats,
  FlightAvailableStats,
  SmxStats,
  TodayStats,
  TomorrowStats,
  TotalAc,
  UmxStats,
} from '../show-stats';
import { getOperatingCompanyOptionsMap } from '../../../selectors/statistic';

const StatMenu: FC<{
  optionsMap: { [key in StatisticsMode]: string };
  onChange: (option: StatisticsMode) => void;
  value: StatisticsMode;
}> = ({ onChange, value, optionsMap }) => {
  const items = Object.keys(optionsMap).reduce<MenuProps['items']>(
    (acc, key) => {
      return acc.concat({
        key,
        label: optionsMap[key],
        onClick: () => onChange(key as StatisticsMode),
      });
    },
    []
  );

  return (
    <Dropdown
      menu={{ items, selectable: true }}
      trigger={['click']}
      className="statistics-dropdown"
    >
      {
        <Button style={{ marginLeft: '12px' }}>
          {value} <DownOutlined />
        </Button>
      }
    </Dropdown>
  );
};

export const StatisticsComponent: FC<{}> = () => {
  const dispatch = useDispatch();
  const statisticsMode = useSelector<RootState, StatisticsMode>(
    state => state.ui.statisticsMode
  );
  const optionsMap = useSelector<
    RootState,
    { [key in StatisticsMode]: string }
  >(getOperatingCompanyOptionsMap);

  const onChange = (option: StatisticsMode) =>
    dispatch(userChangeStatisticsMode(option));

  return (
    <>
      <StatMenu
        onChange={onChange}
        value={statisticsMode}
        optionsMap={optionsMap}
      />
      <div className="statistics-container">
        <TodayStats
          styles={{
            minWidth: '52px',
          }}
        />
        <TomorrowStats
          styles={{
            minWidth: '65px',
          }}
        />
        <AogStats />
        <UmxStats />
        <SmxStats />
        <FlightAvailableStats
          styles={{
            minWidth: '32px',
          }}
        />
        <TotalAc />
      </div>
    </>
  );
};
