import { MouseEvent, PureComponent } from 'react';
import { connect, DispatchProp } from 'react-redux';
import {
  userCancelDraggingFlights,
  userChangeZoomLevel,
} from '../../../../actions';
import { SUPPORTED_RESOLUTION_WIDTH } from '../../../../constants';
import { getZoomLevelForHoldFlightRender } from '../../../../d3/components/zoom';
import { RootState } from '../../../../reducers';
import { HoldLineFlightsGhostsConnected } from '../../../flight-label/flights-change-tail/dragging-ghosts-container';
import { HoldFlightsTextLabelsGroupConnected } from './labelsGroup';
import { AnyAction } from 'redux';

interface StateProps {
  width: number;
  height: number;
  top: number;
  isScrolling: boolean;
  isNotEnoughPlaceForHoldFlights: boolean;
  isMultipleDnDModalVisible: boolean;
}

interface OwnProps {
  onMouseMove: (e: MouseEvent<HTMLOrSVGElement>) => void;
  onMouseDown: (e: MouseEvent<HTMLOrSVGElement>) => void;
  onContextMenu: (e: MouseEvent<HTMLOrSVGElement>) => void;
}

export class HoldFlightsTextLabels extends PureComponent<
  DispatchProp<AnyAction> & OwnProps & StateProps
> {
  onClickSeeMore = () => {
    const { dispatch } = this.props;
    dispatch(
      userChangeZoomLevel({
        zoomLevel:
          window.screen.availWidth >= SUPPORTED_RESOLUTION_WIDTH ? '3d' : '1d',
      })
    );
  };
  onMouseUp = (e: MouseEvent) => {
    const { dispatch, isMultipleDnDModalVisible } = this.props;
    if (!e.shiftKey && !isMultipleDnDModalVisible) {
      dispatch(userCancelDraggingFlights());
    }
  };
  render() {
    const {
      width,
      height,
      top,
      isScrolling,
      isNotEnoughPlaceForHoldFlights,
      onMouseMove,
      onMouseDown,
      onContextMenu,
    } = this.props;
    return (
      <div
        className="hold-flight-labels"
        onDragOver={ev => {
          ev.preventDefault();
          ev.dataTransfer.dropEffect = 'move';
        }}
        onDrop={ev => ev.preventDefault()}
        style={{
          top: `${top}px`,
          width: `${width}px`,
          height: `${height}px`,
          display: !isScrolling ? 'flex' : 'none',
          willChange: 'display',
        }}
        onMouseMove={onMouseMove}
        onMouseDown={onMouseDown}
        onContextMenu={onContextMenu}
        onMouseUp={e => this.onMouseUp(e)}
      >
        {isNotEnoughPlaceForHoldFlights ? (
          <div
            className="hold-flight-labels-unavailable"
            onClick={this.onClickSeeMore}
            style={{ width: `${width}px` }}
          >
            unable to show flights, click to zoom in
          </div>
        ) : (
          <>
            <HoldFlightsTextLabelsGroupConnected />
            <HoldLineFlightsGhostsConnected />
          </>
        )}
      </div>
    );
  }
}

export const HoldFlightsTextLabelsConnected = connect(
  (state: RootState): Omit<StateProps, keyof OwnProps> => ({
    width: state.ui.width,
    height: state.ui.holdLineHeight,
    top: state.ui.marginTop,
    isScrolling: state.ui.isScrollingHorizontally,
    isNotEnoughPlaceForHoldFlights:
      getZoomLevelForHoldFlightRender(state.ui.transform.scaleX) &&
      getZoomLevelForHoldFlightRender(state.ui.transform.scaleX) ===
        'unavailable',
    isMultipleDnDModalVisible: state.ui.isMultipleDnDModalVisible,
  })
)(HoldFlightsTextLabels);
