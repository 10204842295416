import { Moment, utc } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  ChangeDaysRangeFilterPayload,
  userChangeHandoverRemarksRangeFilter,
} from '../../../../actions';
import { DatePickerComponent } from '../../../../common/components/date-range-filter/date-range-filter';
import { DAY_IN_MILLISECONDS } from '../../../../constants';
import { PAST_HANDOVER_REMARKS_DAYS_VIEW } from '../../../../constants/environment';
import { RootState } from '../../../../reducers';

import { FC } from 'react';
import { capitalize } from '../../../../utils';
import { HandoverRemarksState } from '../../../../reducers/handover-remarks';
import { isEqual } from 'lodash';
import { DATE_PICKER_WIDTH } from '../../constants';

export const DatePickerFrom: FC<{}> = () => {
  const dispatch = useDispatch();
  const { from, today } = useSelector<
    RootState,
    { from: number | null; today: number }
  >(
    state => ({
      from: state.handoverRemarks.filters.from,
      today: state.time.today,
    }),
    isEqual
  );
  const disabledDate = (current: Moment) =>
    utc(current)
      .startOf('day')
      .valueOf() <
    utc(today)
      .startOf('day')
      .valueOf() -
      PAST_HANDOVER_REMARKS_DAYS_VIEW * DAY_IN_MILLISECONDS;
  const type: ChangeDaysRangeFilterPayload['type'] = 'from';
  const onChange = ({ type, value }) =>
    dispatch(userChangeHandoverRemarksRangeFilter({ type, value }));
  return (
    <DatePickerComponent
      containerClassName="remarks-title-column-more-filters-container"
      date={from}
      disabledDate={disabledDate}
      onChangeRange={onChange}
      title={`STA ${capitalize(type)}`}
      type={type}
      width={DATE_PICKER_WIDTH}
    />
  );
};
export const DatePickerTo: FC<{}> = () => {
  const dispatch = useDispatch();
  const { from, to } = useSelector<RootState, HandoverRemarksState['filters']>(
    state => state.handoverRemarks.filters,
    isEqual
  );
  const disabledDate = (current: Moment) => {
    if (from && current) {
      return (
        utc(current)
          .startOf('day')
          .valueOf() < from
      );
    }
    return false;
  };
  const type: ChangeDaysRangeFilterPayload['type'] = 'to';
  const onChange = ({ type, value }) =>
    dispatch(userChangeHandoverRemarksRangeFilter({ type, value }));
  return (
    <DatePickerComponent
      containerClassName="remarks-title-column-more-filters-container"
      date={to}
      disabledDate={disabledDate}
      onChangeRange={onChange}
      title={`STA ${capitalize(type)}`}
      type={type}
      width={DATE_PICKER_WIDTH}
    />
  );
};
