import { SegmentsAppenderArgs } from '..';
import { getLaneHeightKoef } from '../../../../../../reducers/ui';
import { OperationalStatus } from '../../../../../../types/flight';
import { handlingStatusColorizator } from '../../../../../../common/flight/flight-colorization';

const patternPrefix = 'handoverRemarksOn';
export function appendThirdLine(argsObject: SegmentsAppenderArgs) {
  const {
    selection,
    segmentsVisibility,
    positionMap,
    togglersState,
    nowTime,
    scaleX,
    firstLineHeight,
    secondLineHeight,
    thirdLineHeight,
  } = argsObject;
  // Estimated + Status
  selection
    .append('rect')
    .attr(
      'height',
      f =>
        thirdLineHeight /
        getLaneHeightKoef(
          segmentsVisibility,
          togglersState[f.aircraftId],
          positionMap
        )
    )
    .attr(
      'y',
      d =>
        d.y +
        (firstLineHeight + secondLineHeight) /
          getLaneHeightKoef(
            segmentsVisibility,
            togglersState[d.aircraftId],
            positionMap
          )
    )
    .attr('x', d => {
      const flightIsEstimatedAtNow =
        d.departureUtcEstimated <= nowTime && nowTime < d.arrivalUtcEstimated;
      if (
        d.legOperationalStatusId == OperationalStatus.NO_SHOW ||
        d.legOperationalStatusId == OperationalStatus.OPTION
      ) {
        return scaleX(d.start) || 0;
      }
      if (d.departureUtcBlock && d.arrivalUtcBlock) {
        return scaleX(d.departureUtcBlock) || 0;
      }
      if (d.departureUtcBlock && !d.arrivalUtcBlock) {
        return 0;
      }
      const hasActualDepartureTime = d.departureUtcActual;
      if (!flightIsEstimatedAtNow || hasActualDepartureTime)
        return scaleX(d.departureUtcEstimated) || 0;
      return scaleX(nowTime);
    })
    .attr('width', d => {
      if (
        d.legOperationalStatusId === OperationalStatus.NO_SHOW ||
        d.legOperationalStatusId === OperationalStatus.OPTION
      ) {
        return this.calculateWidthFor(d.start, d.end, scaleX) || 0;
      }
      if (d.departureUtcBlock && d.arrivalUtcBlock) {
        return (
          this.calculateWidthFor(
            d.departureUtcBlock,
            d.arrivalUtcBlock,
            scaleX
          ) || 0
        );
      }
      if (d.departureUtcBlock && !d.arrivalUtcBlock) {
        return 0;
      }
      return (
        this.calculateWidthFor(
          d.departureUtcEstimated,
          d.arrivalUtcEstimated,
          scaleX
        ) || 0
      );
    })
    .style('fill', d =>
      d.hasOpsRemarks || d.hasCsRemarks
        ? `url(#${patternPrefix}${handlingStatusColorizator(d)})`
        : handlingStatusColorizator(d)
    );

  //For taxi time as DARKGREY
  selection
    .filter(
      f =>
        !!f.departureUtcBlock && !!f.arrivalUtcBlock && !!f.departureUtcActual
    )
    .append('rect')
    .attr(
      'height',
      f =>
        thirdLineHeight /
        getLaneHeightKoef(
          segmentsVisibility,
          togglersState[f.aircraftId],
          positionMap
        )
    )
    .attr(
      'y',
      d =>
        d.y +
        (firstLineHeight + secondLineHeight) /
          getLaneHeightKoef(
            segmentsVisibility,
            togglersState[d.aircraftId],
            positionMap
          )
    )
    .attr('x', d => scaleX(d.departureUtcBlock))
    .attr('width', d =>
      this.calculateWidthFor(d.departureUtcBlock, d.departureUtcActual, scaleX)
    )
    .style('fill', d =>
      d.hasOpsRemarks || d.hasCsRemarks
        ? `url(#${patternPrefix}#383838)`
        : '#383838'
    );
  selection
    .filter(f => !!f.arrivalUtcActual && !!f.arrivalUtcBlock)
    .append('rect')
    .attr(
      'height',
      f =>
        thirdLineHeight /
        getLaneHeightKoef(
          segmentsVisibility,
          togglersState[f.aircraftId],
          positionMap
        )
    )
    .attr(
      'y',
      d =>
        d.y +
        (firstLineHeight + secondLineHeight) /
          getLaneHeightKoef(
            segmentsVisibility,
            togglersState[d.aircraftId],
            positionMap
          )
    )
    .attr('x', d => scaleX(d.arrivalUtcActual))
    .attr('width', d =>
      this.calculateWidthFor(d.arrivalUtcActual, d.arrivalUtcBlock, scaleX)
    )
    .style('fill', d =>
      d.hasOpsRemarks || d.hasCsRemarks
        ? `url(#${patternPrefix}#383838)`
        : '#383838'
    );
  //For departureUtcBlock to NOW as RED
  selection
    .filter(
      f =>
        !!f.departureUtcBlock &&
        !f.arrivalUtcBlock &&
        !f.departureUtcActual &&
        !!f.arrivalUtcEstimated
    )
    .append('rect')
    .attr(
      'height',
      f =>
        thirdLineHeight /
        getLaneHeightKoef(
          segmentsVisibility,
          togglersState[f.aircraftId],
          positionMap
        )
    )
    .attr(
      'y',
      d =>
        d.y +
        (firstLineHeight + secondLineHeight) /
          getLaneHeightKoef(
            segmentsVisibility,
            togglersState[d.aircraftId],
            positionMap
          )
    )
    .attr('x', d => scaleX(d.departureUtcBlock))
    .attr('width', d =>
      d.arrivalUtcEstimated > nowTime
        ? this.calculateWidthFor(d.departureUtcBlock, nowTime, scaleX)
        : this.calculateWidthFor(
            d.departureUtcBlock,
            d.arrivalUtcEstimated,
            scaleX
          )
    )
    .style('fill', d =>
      d.hasOpsRemarks || d.hasCsRemarks
        ? `url(#${patternPrefix}#8b0000)`
        : '#8b0000'
    );
  //For departureUtcActual to departureUtcEstimated as ROSE
  selection
    .filter(
      f =>
        !!f.departureUtcActual &&
        !!f.departureUtcBlock &&
        !f.arrivalUtcBlock &&
        !!f.arrivalUtcEstimated
    )
    .append('rect')
    .attr(
      'height',
      f =>
        thirdLineHeight /
        getLaneHeightKoef(
          segmentsVisibility,
          togglersState[f.aircraftId],
          positionMap
        )
    )
    .attr(
      'y',
      d =>
        d.y +
        (firstLineHeight + secondLineHeight) /
          getLaneHeightKoef(
            segmentsVisibility,
            togglersState[d.aircraftId],
            positionMap
          )
    )
    .attr('x', d => scaleX(d.departureUtcActual))
    .attr('width', d =>
      this.calculateWidthFor(
        d.departureUtcActual,
        d.arrivalUtcEstimated,
        scaleX
      )
    )
    .style('fill', d =>
      d.hasOpsRemarks || d.hasCsRemarks
        ? `url(#${patternPrefix}#ff6666)`
        : '#ff6666'
    );
  //For departureUtcBlock to departureUtcActual as RED
  selection
    .filter(
      f => !!f.departureUtcBlock && !f.arrivalUtcBlock && !!f.departureUtcActual
    )
    .append('rect')
    .attr(
      'height',
      f =>
        thirdLineHeight /
        getLaneHeightKoef(
          segmentsVisibility,
          togglersState[f.aircraftId],
          positionMap
        )
    )
    .attr(
      'y',
      d =>
        d.y +
        (firstLineHeight + secondLineHeight) /
          getLaneHeightKoef(
            segmentsVisibility,
            togglersState[d.aircraftId],
            positionMap
          )
    )
    .attr('x', d => scaleX(d.departureUtcBlock))
    .attr('width', d =>
      this.calculateWidthFor(d.departureUtcBlock, d.departureUtcActual, scaleX)
    )
    .style('fill', d =>
      d.hasOpsRemarks || d.hasCsRemarks
        ? `url(#${patternPrefix}#8b0000)`
        : '#8b0000'
    );
}
