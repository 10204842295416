import { Tooltip, Button } from 'antd';
import { FC } from 'react';

interface Props {
  icon: React.ReactNode;
  onClick: () => void;
  tooltipTitle: string;
}
export const TobBarButtonComponent: FC<Props> = ({
  onClick,
  icon,
  tooltipTitle,
}) => (
  <Tooltip title={tooltipTitle}>
    <Button
      className="topbar-button"
      type="default"
      icon={icon}
      size="middle"
      onClick={onClick}
    />
  </Tooltip>
);
