import { AutoComplete } from 'antd';
import { FieldProps, FormikProps } from 'formik';
import { debounce, isEqual } from 'lodash';
import { FC, useState } from 'react';

import Airport from '../../../types/airport';
import { MxEventFormName } from '../constants';
import { MxEventFormValues } from '../interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { getActiveAirportList } from '../../../selectors/airport';
import {
  userResetCurrentAirport,
  userSearchForServiceProviders,
} from '../../../actions';

export const AirportAutocomplete: FC<Pick<
  FormikProps<MxEventFormValues>,
  | 'values'
  | 'initialValues'
  | 'errors'
  | 'touched'
  | 'setFieldValue'
  | 'handleChange'
> &
  FieldProps<MxEventFormValues['airportIcao']>['field'] & {
    disabled: boolean;
  }> = props => {
  const dispatch = useDispatch();
  const {
    setFieldValue,
    handleChange,
    initialValues: { airportId: initialAirportId },
    errors,
    touched,
    disabled,
    value,
    values,
  } = props;
  const airportList = useSelector<RootState, Airport[]>(getActiveAirportList);
  const airportsById = useSelector<RootState, { [id: number]: Airport }>(
    state => state.airports.airportsById,
    isEqual
  );
  const [airportListFiltered, setFilteredAirport] = useState<Airport[]>(
    airportList
  );
  const doResetServiceProvidersSet = () => dispatch(userResetCurrentAirport());
  const onSearchServiceProviders = (airportId: number) => {
    dispatch(userSearchForServiceProviders.started(airportId));
  };

  const onSelect = (value: string) => {
    const airport = airportsById[+value];
    setFieldValue(MxEventFormName.airportId, airport.id);
    setFieldValue(MxEventFormName.airportIcao, airport.ICAO);
    if (initialAirportId !== airport.id) {
      setFieldValue(MxEventFormName.serviceProviderId, null);
      setFieldValue(MxEventFormName.serviceProviderName, '');
      doResetServiceProvidersSet();
    }
    onSearchServiceProviders(airport.id);
    handleChange(value);
  };
  const onBlur = () => {
    setFieldValue(MxEventFormName.airportIcao, value || '');
    handleChange(value || '');
  };
  const onClear = () => {
    setFieldValue(MxEventFormName.airportIcao, '');
    setFieldValue(MxEventFormName.serviceProviderId, null);
    setFieldValue(MxEventFormName.serviceProviderName, '');
    handleChange('');
  };

  const onHandleChange = debounce((value: string) => {
    const list =
      value?.length > 1
        ? airportList.filter((el: Airport) =>
            `${el.ICAO} ${el.name}${el.IATA ? ` ${el.IATA}` : ''} `
              .toLocaleLowerCase()
              .includes(value.toLowerCase().trim())
          )
        : [];
    setFilteredAirport(list);
    handleChange(value || '');
    if (!value) {
      setFieldValue(MxEventFormName.airportId, null);
      setFieldValue(MxEventFormName.airportIcao, null);
      if (values.serviceProviderId || values.serviceProviderName) {
        setFieldValue(MxEventFormName.serviceProviderId, null);
        setFieldValue(MxEventFormName.serviceProviderName, null);
        doResetServiceProvidersSet();
      }
    }
  }, 50);

  const options = airportListFiltered.map(airport => ({
    label: `${airport.ICAO || ''} ${airport.name}${
      airport.IATA ? ` ${airport.IATA}` : ''
    }`,
    value: `${airport.id}`,
  }));
  const hasError = errors.airportIcao && touched.airportIcao;
  return (
    <div className="edit-event-form-body-auto-complete">
      <AutoComplete
        allowClear
        defaultValue={value}
        disabled={disabled}
        onBlur={onBlur}
        onClear={onClear}
        onChange={onHandleChange}
        onSelect={onSelect}
        options={options}
        placeholder="Select airport"
        popupClassName="edit-event-form-body-auto-complete-dropdown"
        status={hasError ? 'error' : ''}
        style={{ width: '256px' }}
      />
      {hasError ? (
        <div className="edit-event-form-body-error-message">
          {errors.airportIcao}
        </div>
      ) : null}
    </div>
  );
};
