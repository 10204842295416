import { PureComponent } from 'react';
import WarningIcon from '../../svg-icons/aircraft/WarningIcon';
import Aircraft from '../../../types/aircraft';
import { RootState } from '../../../reducers';
import { connect } from 'react-redux';
import { isOwnedAndIsOperatedByVJ } from '../../../common/aircraft/aircraft-check-status';
import { getAircraftById, getBaseCompaniesIds } from '../../../selectors';
import { setDataTestEntityId } from '../../../utils';
import { DescriptionsContent } from './descriptions-content';

interface StateProps {
  aircraft: Aircraft;
  isOwnedAndOperatedByVJ: boolean;
}

class PaxTooltipContent extends PureComponent<StateProps> {
  render() {
    const { aircraft, isOwnedAndOperatedByVJ } = this.props;
    return (
      <>
        {isOwnedAndOperatedByVJ &&
          aircraft.customerAffectingNotes &&
          aircraft.customerAffectingNotes.length > 0 && (
            <div
              className="pax-warning"
              {...setDataTestEntityId('tooltip-aircraft-pax-warning')}
            >
              <header
                className="pax-warning-header"
                {...setDataTestEntityId('tooltip-aircraft-pax-warning-header')}
              >
                <div
                  className="pax-warning-icon"
                  {...setDataTestEntityId('tooltip-aircraft-pax-warning-icon')}
                >
                  <WarningIcon />
                </div>
                <h1 className="pax-warning-h1">Has PAX Affecting defects</h1>
              </header>
              <DescriptionsContent
                warningType="pax-warning"
                descriptions={aircraft.customerAffectingNotes}
              />
            </div>
          )}
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  const aircraft = getAircraftById(state, state.ui.hoveredAircraftId);
  return {
    aircraft,
    isOwnedAndOperatedByVJ: isOwnedAndIsOperatedByVJ(
      aircraft,
      getBaseCompaniesIds(state)
    ),
  };
};

const PaxTooltipContentConnected = connect(mapStateToProps)(PaxTooltipContent);

export default PaxTooltipContentConnected;
