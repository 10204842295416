import { UserProfile } from '../types/user';
import { receivedUserAndRoles } from '../actions';
import { USE_AUTH } from '../constants/environment';
import { keycloak } from '../initialize';
import { User } from '@auth0/auth0-spa-js';

//@ts-ignore
const isUnsafeMode = UNSAFE_MODE === 'true';

const getUserProfileFromKeycloakToken = (
  keycloakInstance: Keycloak.KeycloakInstance
): UserProfile => {
  const tokenParsed = keycloakInstance.tokenParsed as UserProfile;
  return {
    email: tokenParsed.email,
    family_name: tokenParsed.family_name,
    given_name: tokenParsed.given_name,
    groups: tokenParsed.groups,
    name: tokenParsed.name,
    preferred_username: tokenParsed.preferred_username,
    roles: (tokenParsed as any).realm_access?.roles,
    sub: tokenParsed.sub,
  };
};
const getUnsafeUser = (): UserProfile => ({
  createdTimestamp: new Date().valueOf(),
  enabled: true,
  given_name: 'UNSAFE',
  name: 'UNSAFE',
  email: '',
  family_name: '',
  groups: [],
  sub: new Date().valueOf().toString(),
  roles: [],
  preferred_username: '',
});
const getAuth0UserData = (user: User, roles?: string[]): UserProfile => {
  // eslint-disable-next-line camelcase
  const {
    family_name,
    given_name,
    user_email,
    name,
    preferred_username,
    sub,
    email,
    nickname,
  } = user;
  return {
    email: email || user_email,
    family_name,
    given_name,
    name,
    groups: roles,
    preferred_username: preferred_username || nickname,
    roles,
    sub,
  };
};
export const setUserToStore = (user?: User, roles?: string[]) => {
  if (USE_AUTH) {
    const userProfile = isUnsafeMode
      ? getUnsafeUser()
      : getAuth0UserData(user, roles);
    store.dispatch(
      receivedUserAndRoles({
        roles,
        userName: isUnsafeMode ? 'UNSAFE' : user.name,
        userProfile,
      })
    );
  } else {
    const { tokenParsed } = keycloak;
    if (!tokenParsed) {
      throw new Error('Unable to parse token');
    }
    const {
      preferred_username,
      given_name: firstName,
      realm_access,
    } = tokenParsed as Keycloak.KeycloakTokenParsed & {
      preferred_username?: string;
      given_name?: string;
    };
    const userName = isUnsafeMode ? 'UNSAFE' : firstName || preferred_username;
    const roles = realm_access?.roles || [];
    const userProfile = isUnsafeMode
      ? getUnsafeUser()
      : getUserProfileFromKeycloakToken(keycloak);
    store.dispatch(receivedUserAndRoles({ userProfile, roles, userName }));
  }
};
