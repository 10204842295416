import { Divider } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { isEqual } from 'lodash';
import { FC, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  userToggleACTypeView,
  userToggleOperatingCompaniesView,
  userToggleAircraft,
} from '../../../actions';
import { TailsByAcTypeOnOperatorList } from '../../../common/components/aircraft-filter/ac-types-list';
import { OperatingCompanyItem } from '../../../common/components/aircraft-filter/operator-item';
import { RootState } from '../../../reducers';
import { getCachedAircraftFilter } from '../../../selectors';
import {
  FilterByOperatingCompany,
  FilterByAircraftType,
} from '../../../types/aircraft';
import { getCompanyAcTypeIdsAndAircraftIds } from '../../../utils/aircraft';
import { DndAcTypes } from './dnd-ac-types';

const MainContent: FC<{
  classNamePrefix: string;
  company: FilterByOperatingCompany;
  index: number;
  isEditModeAcTypes: boolean;
  operatingCompanies: FilterByOperatingCompany[];
}> = ({
  classNamePrefix,
  company,
  index,
  isEditModeAcTypes,
  operatingCompanies,
}) => {
  const dispatch = useDispatch();
  const filterByAircraftType = useSelector<RootState, FilterByAircraftType[]>(
    state => getCachedAircraftFilter(state, company.id),
    isEqual
  );
  const isVerticalMode = useSelector<RootState, boolean>(
    state => state.ui.isVerticalMode
  );
  const handelSelectCompany = (e: CheckboxChangeEvent, companyId: number) => {
    const {
      companiesAcTypeIds,
      companyAircraftIds,
    } = getCompanyAcTypeIdsAndAircraftIds(filterByAircraftType);
    dispatch(
      userToggleOperatingCompaniesView({
        companyId,
        companiesAcTypeIds,
        companyAircraftIds,
        checked: e.target.checked,
      })
    );
  };
  const handleSelectAcType = (
    event: CheckboxChangeEvent,
    payload: {
      companyId: number;
      acTypeId: number;
    }
  ) => {
    const { acTypeId, companyId } = payload;
    const type = filterByAircraftType.find(ac => ac.id === acTypeId);
    const aircraftIdsOnThisType = type.tails.map(t => t.id);
    const { checked } = event.target;
    dispatch(
      userToggleACTypeView({
        companyId,
        acTypeId,
        aircraftIdsOnThisType,
        checked,
        isToRemoveAcType: !checked,
      })
    );
  };
  const handleSelectTail = (
    event: CheckboxChangeEvent,
    payload: {
      companyId: number;
      acTypeId: number;
      acId: number;
    }
  ) => {
    const { acTypeId, companyId, acId } = payload;
    const aircraftIdsOnThisType =
      filterByAircraftType
        .find(ac => ac.id === acTypeId)
        ?.tails.filter(t => t.checked) || [];
    const { checked } = event.target;
    const isToRemoveAcType = !checked && aircraftIdsOnThisType.length === 1;
    dispatch(
      userToggleAircraft({
        companyId,
        acTypeId,
        aircraftIdsOnThisType: [acId],
        isToRemoveAcType,
        checked,
      })
    );
  };
  return (
    <div
      className="filter-bar-actypes-content-companies-column"
      key={company.id}
      style={{
        width: isVerticalMode ? '150px' : '155px',
        maxWidth: isVerticalMode ? '155px' : '160px',
      }}
    >
      <OperatingCompanyItem
        filterByAircraftType={filterByAircraftType}
        disabled={isEditModeAcTypes}
        company={company}
        classNamePrefix={classNamePrefix}
        onSelectOperatingCompany={handelSelectCompany}
      />
      <Divider
        className={`${classNamePrefix}actypes-content-companies-divider`}
      />
      {isEditModeAcTypes ? (
        <DndAcTypes
          filterByAircraftType={filterByAircraftType}
          company={company}
        />
      ) : (
        <TailsByAcTypeOnOperatorList
          company={company}
          filterByAircraftType={filterByAircraftType}
          onSelectAcType={handleSelectAcType}
          onSelectTail={handleSelectTail}
          classNamePrefix={classNamePrefix}
          isLastOne={operatingCompanies.length === index + 1}
        />
      )}
    </div>
  );
};

export const MainContentMemoized = memo(MainContent);
