import { PureComponent } from 'react';
import { utc } from 'moment';
import Airport from '../../../../types/airport';
import { capitalize } from 'lodash';
import OneWayOffer from '../../../../types/one-way-offer';
import { OneWayActionButtons } from '../../../tooltip/action-buttons';
import {
  getICAOLabelFromAirport,
  getCityLabelFromAirport,
  getCountryLabelFromAirport,
} from '../../../../utils/airport';

interface Props {
  element: OneWayOffer;
  airportsById: { [id: number]: Airport };
  hasCheckedOneWayOffersPermission: boolean;
}

export default class OneWayOfferCardContent extends PureComponent<Props> {
  getAirportInfo = (a: Airport) =>
    a ? (
      <>
        <strong>{getICAOLabelFromAirport(a)}</strong>
        {` ${capitalize(getCityLabelFromAirport(a))}, ${capitalize(
          getCountryLabelFromAirport(a)
        )} `}
      </>
    ) : (
      <strong>{' No airport specified '}</strong>
    );
  render() {
    const {
      element,
      airportsById,
      hasCheckedOneWayOffersPermission,
    } = this.props;
    const airport = airportsById[element.departureAirportId];
    return (
      <div className="card-content">
        <div>{this.getAirportInfo(airport)}</div>
        <div className="card-one-way">
          <div className="card-one-way-icon">
            <svg height={40} width={15}>
              <circle cx={7} cy={10} r={2} fill="rgba(0, 0, 0, 0.85)" />
              <line
                x1={7}
                x2={7}
                y1={10}
                y2={32}
                strokeWidth={1}
                stroke="rgba(0, 0, 0, 0.85)"
              />
              <circle cx={7} cy={32} r={2} fill="rgba(0, 0, 0, 0.85)" />
            </svg>
          </div>
          <div className="card-one-way-durations">
            <div>{utc(element.start).format('DD MMM, HH:mm [Z]')}</div>
            <div>{utc(element.end).format('DD MMM, HH:mm [Z]')}</div>
          </div>
          {hasCheckedOneWayOffersPermission && (
            <OneWayActionButtons
              id={element.id}
              hasEditPermission={hasCheckedOneWayOffersPermission}
            />
          )}
        </div>
      </div>
    );
  }
}
