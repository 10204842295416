import Maintenance from '../../types/maintenance';

export interface MaintenanceGVModel {
  maintenanceEventId: {
    key: number;
  };
  startDate: number;
  endDate: number;
  aircraftId: {
    key: number;
  };
  airportId: {
    key: number;
  };
  reason: string;
  maintenanceType: {
    maintenanceTypeId: {
      key: number;
    };
    name: string;
  };
  categoryId: number;
  creator: {
    personId: {
      key: number;
    };
    firstName: string;
    lastName: string;
    active: true;
  };
  maintenanceOrderTypeId: {
    key: number;
  };
  lastUpdated: number;
  isDispatchable: boolean;
  companyName: string | null;
  releasedBy: string;
  releasedTime: string;
  orderId: string;
  updatedBy: string;
  createdAtMs: number;
}

export const transformToFE = (m: MaintenanceGVModel): Maintenance => ({
  id: m.maintenanceEventId.key,
  start: m.startDate,
  end: m.endDate,
  aircraftId: m.aircraftId.key,
  categoryId: m.categoryId,
  reason: m.reason,
  creator: m.creator && {
    id: m.creator.personId.key,
    firstName: m.creator.firstName,
    lastName: m.creator.lastName,
  },
  lastUpdated: m.lastUpdated,
  airportId: m.airportId ? m.airportId.key : null,
  maintenanceType: m.maintenanceType.maintenanceTypeId.key,
  maintenanceOrderType:
    m.maintenanceOrderTypeId && m.maintenanceOrderTypeId.key,
  isDispatchable: m.isDispatchable,
  releasedBy: m.releasedBy,
  releasedTime: m.releasedTime,
  orderId: m.orderId,
  updatedBy: m.updatedBy,
  createdAt: m.createdAtMs,
  companyName: m.companyName || 'N/A',
});
