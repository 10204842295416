import Timeline from './components/timeline';
import { ErrorBoundary } from './components/error-handling/error-boundary';
import { NotPermittedPage } from './components/error-handling/not-permitted-page';
import { TimelineBar } from './components/timeline-bar';
import { Routes, Route, useNavigate } from 'react-router-dom';
import SidebarComponentConnected from './components/timeline-bar/sidebarComponent/SidebarComponent';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from './reducers';
import { hasPermission } from './utils/check-permissions';
import { NoteDrawerConnected } from './components/edit-event-drawer/note-drawer';
import { EditMxEventDrawerConnected } from './components/edit-event-drawer/mx-event-drawer';
import { NearestAircraftModal } from './components/nearest-aircraft-modal';
import { HandoverRemarksPanel } from './components/handover-remarks';
import {
  MxEmailComponentConnected,
  ArincEmailComponentConnected,
} from './components/email-component';
import { PeakDaysModal } from './components/peak-days/event-list-modal';
import { SinglePeakDayDrawer } from './components/peak-days/single-event-modal';
import { isEqual } from 'lodash';

const TimeLine: FC = () => (
  <ErrorBoundary>
    <Timeline />
    <HandoverRemarksPanel />
    <NoteDrawerConnected />
    <EditMxEventDrawerConnected />
    <NearestAircraftModal />
    <SinglePeakDayDrawer />
    <PeakDaysModal />
    <MxEmailComponentConnected />
    <ArincEmailComponentConnected />
  </ErrorBoundary>
);

const NotAuthorized = () => (
  <>
    <SidebarComponentConnected />
    <TimelineBar isNotPermitted />
    <NotPermittedPage />
  </>
);

export const Main: FC = () => {
  const { isAuthorized, userName } = useSelector<
    RootState,
    { isAuthorized: boolean; userName: string }
  >(
    state => ({
      isAuthorized: hasPermission(state, 'AG-Timeline'),
      userName: state.user.userName,
    }),
    isEqual
  );
  const navigate = useNavigate();
  useEffect(() => {
    if (userName && !isAuthorized) {
      navigate('/unauthorized');
    }
    // navigation for test cafe
    if (window.location.pathname.match(/\%20/g)?.length > 0) {
      navigate('/');
    }
  }, [isAuthorized, userName]);

  // prevent loading Timeline without user data
  if (!userName) return null;
  return (
    <Routes>
      <Route path="/" element={<TimeLine />} />
      <Route path="/unauthorized" element={<NotAuthorized />} />
    </Routes>
  );
};
