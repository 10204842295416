import './styles.scss';

import { Button, Drawer, Spin } from 'antd';
import { Form, Formik, FormikHelpers } from 'formik';
import { utc } from 'moment';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import { userCloseMxEventModal, userSaveMxEvent } from '../../actions';
import { RootState } from '../../reducers';
import { getOperatingAircraftForDropdown } from '../../selectors';
import { hasPermission } from '../../utils/check-permissions';
import { ConfirmationModal } from '../../common/components/confirmation-modal/confirmation-modal';
import {
  initialMxEventFormValues,
  MxEventCategoryName,
  FormId,
  MxEventOrderTypeId,
  spinnerStyles,
} from './constants';
import { MxEventFormValues, MxModalStateProps } from './interfaces';
import { MxEventFormDetails } from './mx-event-form';
import { transformMxFromStateForSave } from './utils';
import { MXEventDtoInput } from '../../types/operation-result-types';
import { HOUR_IN_MS, RIGHT_DRAWER_WIDTH } from '../../constants';
import { AnyAction, Dispatch } from 'redux';
import { CloseOutlined } from '@ant-design/icons';

const validationSchema = Yup.object().shape({
  aircraftName: Yup.string()
    .nullable()
    .required('Aircraft is required'),
  splitHours: Yup.number()
    .nullable()
    .positive()
    .integer('Split hours must be an integer')
    .min(1, 'Split hours must be greater than or equal to 1')
    .notRequired(),
  maintenanceTypeId: Yup.number()
    .nullable()
    .required('Maintenance type is required'),
  startTimeMs: Yup.number()
    .nullable()
    .required('Please select start date'),
  endTimeMs: Yup.number()
    .nullable()
    .required('Please select end date')
    .min(
      Yup.ref('startTimeMs'),
      'The End date must be after than the Start date'
    )
    .when('startTimeMs', (startTimeMs: number, schema) => {
      return schema.test({
        test: endTimeMs =>
          !!startTimeMs && endTimeMs > startTimeMs + HOUR_IN_MS / 2 - 1,
        message:
          'End date should be greater than Start date at least for 30 minutes',
      });
    }),
});
interface DispatchProps {
  onClose: () => void;
  onSubmit: (mxEvent: MXEventDtoInput, serviceProviderName: string) => void;
}

interface State {
  initialValues: MxEventFormValues;
  mxEventId: number | null;
  visibleConfirmationPopUp: boolean;
  formIsTouched: boolean;
  uniqIdentifier: string;
}

export interface MxFormProps extends MxModalStateProps, DispatchProps {}
class MxEventModal extends PureComponent<MxFormProps, State> {
  constructor(props: MxFormProps) {
    super(props);
    this.state = MxEventModal.getUpdateMxEventDetails(props);
  }
  static getDerivedStateFromProps(props: MxFormProps, state: State) {
    if (
      props.mxEventId !== state.mxEventId ||
      state.uniqIdentifier !== props.uniqIdentifier
    ) {
      return MxEventModal.getUpdateMxEventDetails(props);
    }
    return null;
  }
  static getUpdateMxEventDetails(props: MxFormProps) {
    const visibleConfirmationPopUp = false;
    const formIsTouched = false;
    const { mxEventDetails, mxEventId } = props;
    if (mxEventDetails) {
      const rtsSelected =
        (mxEventDetails.releasedTimeMs &&
          utc(mxEventDetails.releasedTimeMs).isSame(
            utc(mxEventDetails.lastUpdatedMs),
            'second'
          )) ||
        false;
      const hydrated = {
        aircraftId: mxEventDetails.aircraftId,
        aircraftName:
          props.aircraftList.find(ac => ac.id === mxEventDetails.aircraftId)
            ?.tailNumber || '',
        airportIcao: mxEventDetails.airportIcao,
        airportId: mxEventDetails.airportId,
        categoryName: mxEventDetails.categoryName as MxEventCategoryName,
        dispatchable: mxEventDetails.dispatchable,
        endTimeMs: mxEventDetails.endTimeMs
          ? utc(mxEventDetails.endTimeMs).valueOf()
          : null,
        id: mxEventDetails.id,
        maintenanceTypeId: mxEventDetails.maintenanceTypeId,
        notSplit: initialMxEventFormValues.notSplit,
        splitHours: initialMxEventFormValues.splitHours,
        orderNumber: mxEventDetails.orderNumber,
        orderTypeId: mxEventDetails.orderTypeId || MxEventOrderTypeId.None,
        reason: mxEventDetails.reason,
        rtsSelected,
        serviceProviderId: mxEventDetails.serviceProviderId,
        serviceProviderName: mxEventDetails.serviceProviderTypeName
          ? `${mxEventDetails.serviceProviderName} (${mxEventDetails.serviceProviderTypeName})`
          : mxEventDetails.serviceProviderName,
        startTimeMs: utc(mxEventDetails.startTimeMs).valueOf(),
      };
      return {
        initialValues: hydrated,
        mxEventId,
        formIsTouched,
        visibleConfirmationPopUp,
        uniqIdentifier: props.uniqIdentifier,
      };
    }
    const end =
      props.start && props.end && props.start === props.end
        ? props.start + HOUR_IN_MS
        : props.end;
    const updatedWithStateValue: MxEventFormValues = {
      ...initialMxEventFormValues,
      startTimeMs: props.start
        ? utc(props.start).valueOf()
        : initialMxEventFormValues.startTimeMs,
      endTimeMs: end ? end : initialMxEventFormValues.endTimeMs,
      aircraftId: props.selectedAircraftId,
      aircraftName:
        props.aircraftList.find(ac => ac.id === props.selectedAircraftId)
          ?.tailNumber || initialMxEventFormValues.aircraftName,
    };
    return {
      initialValues: updatedWithStateValue,
      mxEventId: null,
      formIsTouched,
      visibleConfirmationPopUp,
      uniqIdentifier: props.uniqIdentifier,
    };
  }

  handleClose = () => {
    if (this.state.formIsTouched) {
      this.setState({
        visibleConfirmationPopUp: true,
      });
    } else {
      this.props.onClose();
    }
  };
  onCancelSave = () => {
    this.setState({
      visibleConfirmationPopUp: false,
    });
    this.props.onClose();
  };
  onCloseConfirmationModal = () => {
    this.setState({
      visibleConfirmationPopUp: false,
    });
  };
  setFormIsTouched = () => this.setState({ formIsTouched: true });
  render() {
    const {
      visible,
      mxEventId,
      isFailedToLoadMxEventDetails,
      isLoadingMxEvent,
      isSaving,
      height,
      mxEventDetails,
      onSubmit,
      isCloningMxEvent,
      serviceProviders,
      disabled,
      isLoadingOrderTypes,
      orderTypes,
      airportsByIcao,
    } = this.props;
    const loading = isLoadingMxEvent || isSaving;
    const title = (
      <div className="drawer-title-wrapper">
        <span className="drawer-title">
          {isCloningMxEvent || !mxEventId || isFailedToLoadMxEventDetails
            ? 'Create MX Event'
            : `Edit MX Event #${mxEventId}`}
        </span>
        <Button
          icon={<CloseOutlined />}
          onClick={this.handleClose}
          style={{ border: 'none' }}
        />
      </div>
    );
    return (
      <>
        <ConfirmationModal
          open={this.state.visibleConfirmationPopUp}
          onClose={this.onCloseConfirmationModal}
          footer={
            <div className="edit-event-form-footer">
              <Button
                htmlType="reset"
                key="reset"
                onClick={this.onCancelSave}
                form={FormId.createEditMxEvent}
                className="edit-event-form-footer-button-cancel"
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                key="save"
                form={FormId.createEditMxEvent}
                loading={isSaving}
                className="edit-event-form-footer-button-submit"
              >
                Yes
              </Button>
            </div>
          }
        />
        <Drawer
          open={visible}
          closable={false}
          placement="right"
          width={RIGHT_DRAWER_WIDTH}
          maskClosable
          onClose={this.handleClose}
          title={title}
        >
          <Spin
            spinning={loading}
            tip={isLoadingMxEvent ? 'Loading...' : 'Saving...'}
            style={spinnerStyles}
          >
            <Formik
              initialValues={this.state.initialValues}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={(
                values: MxEventFormValues,
                { setSubmitting }: FormikHelpers<MxEventFormValues>
              ) => {
                onSubmit(
                  transformMxFromStateForSave(
                    values,
                    !!mxEventId,
                    mxEventDetails
                  ),
                  serviceProviders?.find(
                    pr => pr.id === values.serviceProviderId
                  )?.name || values.serviceProviderName?.replace(/\s\(.+/gi, '')
                );
                setSubmitting(false);
              }}
            >
              {formikProps => (
                <Form
                  className="edit-event-form"
                  style={{
                    height: `${height}px`,
                    opacity: isLoadingMxEvent ? 0.5 : 1,
                  }}
                  id={FormId.createEditMxEvent}
                >
                  <MxEventFormDetails
                    {...formikProps}
                    mxEventId={mxEventId}
                    orderTypes={orderTypes}
                    isLoadingOrderTypes={isLoadingOrderTypes}
                    airportsByIcao={airportsByIcao}
                    disabled={disabled}
                    formIsTouched={this.state.formIsTouched}
                    setFormIsTouched={this.setFormIsTouched}
                  />
                  <div className="edit-event-form-footer">
                    <Button
                      onClick={this.handleClose}
                      className="edit-event-form-footer-button-cancel"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      key="save"
                      form={FormId.createEditMxEvent}
                      className="edit-event-form-footer-button-submit"
                      disabled={disabled}
                    >
                      {isCloningMxEvent ||
                      !mxEventId ||
                      isFailedToLoadMxEventDetails
                        ? 'Create'
                        : 'Save'}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Spin>
        </Drawer>
      </>
    );
  }
}

const mapStateToProps = (state: RootState): MxModalStateProps => ({
  aircraftList: getOperatingAircraftForDropdown(state),
  airportsByIcao: state.airports.airportsByIcao,
  end: state.eventElementCreationEdit.end,
  disabled:
    hasPermission(state, 'AG-Timeline-View-MX-Event') &&
    !hasPermission(state, 'AG-Timeline-Create-MX-Event'),
  height: state.ui.height,
  isFailedToLoadMxEventDetails:
    state.eventElementCreationEdit.isFailedToLoadMxEventDetails,
  isLoadingMxEvent: state.eventElementCreationEdit.isLoadingEvent,
  isLoadingOrderTypes: state.eventElementCreationEdit.isLoadingOrderTypes,

  isSaving: state.eventElementCreationEdit.isSaving,
  mxEventDetails: state.eventElementCreationEdit.mxEventDetails,
  mxEventId: state.eventElementCreationEdit.id,
  orderTypes: state.eventElementCreationEdit.orderTypes,
  selectedAircraftId: state.eventElementCreationEdit.aircraftId,
  serviceProviders: state.eventElementCreationEdit.serviceProviders,
  start: state.eventElementCreationEdit.start,
  visible: state.eventElementCreationEdit.isMxModalVisible,
  uniqIdentifier: state.eventElementCreationEdit.uniqIdentifier,
  isCloningMxEvent: state.eventElementCreationEdit.isCloningMxEvent,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => ({
  onClose: () => {
    dispatch(userCloseMxEventModal());
  },
  onSubmit: (mxEvent: MXEventDtoInput, serviceProviderName) => {
    dispatch(userSaveMxEvent.started({ mxEvent, serviceProviderName }));
  },
});

export const EditMxEventDrawerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(MxEventModal);
