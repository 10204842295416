import { PureComponent } from 'react';
import * as actions from '../../actions';
import './styles.scss';
import { DispatchProp, connect } from 'react-redux';
import {
  BarChartOutlined,
  FileTextOutlined,
  FilterOutlined,
} from '@ant-design/icons';
import { Popover, Button } from 'antd';
import { RootState } from '../../reducers';
import { SearchBarConnected } from './search-bar';
import { DateSwitcherConnected } from './date-switcher';
import { CreateEventsMenuCard } from '../create-events-menu/create-events-menu-card';
import { hasPermission } from '../../utils/check-permissions';
import { StatisticsComponent } from './statistics';
import { setDataTestEntityId, openOrderWindow } from '../../utils';
import { LinkToCustomerPreferencesTabComponent } from './customer-preferences-link';
import FilterHoldLine from './filter-hold-line';
import { utc } from 'moment';
import { HOUR_IN_MS } from '../../constants';
import { ResetPreset } from '../filter-top-bar/reset-preset';
import { SavePreset } from '../filter-top-bar/save-preset';
import { TobBarButtonComponent } from './topbar-button';
import { generate } from 'shortid';
import { AnyAction } from 'redux';

interface StateProps {
  isShowFilter: boolean;
  isVerticalMode: boolean;
  hasCreateMxEventPermission: boolean;
  hasCreateOrderPermission: boolean;
  hasCreateNotePermission: boolean;
  hasCreatePeakDayPermission: boolean;
  hasSomeCreateElementsPermissions: boolean;
  hasHandoverViewPermission: boolean;
}

export class TopBar extends PureComponent<
  StateProps & DispatchProp<AnyAction>,
  {
    isOpened: boolean;
    isInitialized: boolean;
    isNoteOpened: boolean;
    isNoteInitialized: boolean;
    isCreateEventsPopoverVisible: boolean;
  }
> {
  constructor(props) {
    super(props);
    this.state = {
      isInitialized: false,
      isOpened: false,
      isNoteOpened: false,
      isNoteInitialized: false,
      isCreateEventsPopoverVisible: false,
    };
  }
  hidePopover = () => {
    this.setState({ isCreateEventsPopoverVisible: false });
  };
  handlePopoverVisibilityChange = visible =>
    this.setState({ isCreateEventsPopoverVisible: visible });
  onClickCreateOrder = () => {
    this.hidePopover();
    openOrderWindow({});
  };
  onClickCreateElement = (type: 'mx' | 'note') => {
    this.hidePopover();
    const now = utc().valueOf();
    const payload = {
      end: now + HOUR_IN_MS,
      start: now,
      uniqIdentifier: generate(),
    };
    this.props.dispatch(
      type === 'mx'
        ? actions.userClickCreateMaintenance(payload)
        : actions.userClickCreateNote(payload)
    );
  };
  onClickPeakDays = () =>
    this.props.dispatch(actions.userTogglePeakDaysModal());
  onClickHandoverRemarks = () =>
    this.props.dispatch(
      actions.userOpenHandoverRemarksDrawer({
        isFor: null,
      })
    );
  onClickCreatePeakDay = () => {
    this.hidePopover();
    this.props.dispatch(actions.userClickCreatePeakDay());
  };
  onGoToNow = () => this.props.dispatch(actions.userGoToNow());
  onShowFilter = () => {
    return this.props.dispatch(actions.userToggleFilterPanel());
  };

  render() {
    const {
      hasCreateMxEventPermission,
      hasCreateOrderPermission,
      hasCreateNotePermission,
      hasSomeCreateElementsPermissions,
      hasCreatePeakDayPermission,
      hasHandoverViewPermission,
      isVerticalMode,
      isShowFilter,
    } = this.props;
    const gvStyle: React.CSSProperties = {
      position: 'absolute',
      top: '51px',
      boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.5)',
      background: 'white',
      zIndex: 500,
    };
    const gvNoteStyle: React.CSSProperties = {
      position: 'absolute',
      top: '51px',
      boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.5)',
      background: 'white',
      zIndex: 500,
    };
    if (!this.state.isOpened) {
      gvStyle.top = '-950px';
    }
    if (!this.state.isNoteOpened) {
      gvNoteStyle.top = '-950px';
    }
    const topBarStyle: React.CSSProperties = isVerticalMode
      ? {
          flexWrap: 'wrap',
          justifyContent: 'end',
          height: '90px',
        }
      : {
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
          height: '50px',
        };
    return (
      <div className="topbar" style={topBarStyle}>
        <div className="topbar-sticked">
          <SearchBarConnected />

          <Button
            onClick={this.onShowFilter}
            {...setDataTestEntityId('toggle-filter-button')}
            style={{ marginLeft: isVerticalMode ? 60 : 0 }}
          >
            <FilterOutlined />
          </Button>

          <DateSwitcherConnected />

          <div
            style={{
              margin: isVerticalMode ? '0px 0px 0px 10px' : '10px',
            }}
          >
            <Button
              onClick={this.onGoToNow}
              {...setDataTestEntityId('move-to-now-button')}
            >
              Now
            </Button>
          </div>
        </div>

        <div
          className="topbar-sticked"
          style={{
            width: isVerticalMode && isShowFilter ? '100%' : 'inherit',
            justifyContent:
              isVerticalMode && isShowFilter ? 'space-between' : 'none',
          }}
        >
          {!isVerticalMode && (
            <>
              <FilterHoldLine />
              {hasHandoverViewPermission && (
                <TobBarButtonComponent
                  onClick={this.onClickHandoverRemarks}
                  tooltipTitle="Open Handover Remarks"
                  icon={<FileTextOutlined />}
                />
              )}
              <TobBarButtonComponent
                onClick={this.onClickPeakDays}
                tooltipTitle="Open Peak days page"
                icon={<BarChartOutlined />}
              />
              <LinkToCustomerPreferencesTabComponent />
            </>
          )}
          {isVerticalMode && isShowFilter && (
            <div>
              <ResetPreset />
              <SavePreset />
            </div>
          )}
          <div className="topbar-sticked">
            {isVerticalMode && (
              <>
                <div style={{ marginRight: '12px' }}>
                  <FilterHoldLine />
                </div>
                {hasHandoverViewPermission && (
                  <TobBarButtonComponent
                    onClick={this.onClickHandoverRemarks}
                    tooltipTitle="Open Handover Remarks"
                    icon={<FileTextOutlined />}
                  />
                )}
                <TobBarButtonComponent
                  onClick={this.onClickPeakDays}
                  tooltipTitle="Open Peak days page"
                  icon={<BarChartOutlined />}
                />
                <LinkToCustomerPreferencesTabComponent />
              </>
            )}
            <StatisticsComponent />
            <div
              style={{
                margin: isVerticalMode
                  ? '4px 0px 4px 14px'
                  : '10px 10px 10px 28px',
              }}
            >
              <Popover
                placement={isVerticalMode ? 'bottomRight' : 'bottom'}
                trigger="click"
                open={this.state.isCreateEventsPopoverVisible}
                onOpenChange={
                  hasSomeCreateElementsPermissions &&
                  this.handlePopoverVisibilityChange
                }
                content={
                  <div style={{ width: 180, margin: '-10px -16px' }}>
                    <CreateEventsMenuCard
                      createFromTopBar
                      hasCreateMxEventPermission={hasCreateMxEventPermission}
                      hasCreateOrderPermission={hasCreateOrderPermission}
                      hasCreateNotePermission={hasCreateNotePermission}
                      onClickCreateMxEvent={() =>
                        this.onClickCreateElement('mx')
                      }
                      onClickCreateOrder={this.onClickCreateOrder}
                      onClickCreateNote={() =>
                        this.onClickCreateElement('note')
                      }
                      hasCreatePeakDayPermission={hasCreatePeakDayPermission}
                      onClickCreatePeakDay={this.onClickCreatePeakDay}
                    />
                  </div>
                }
              >
                <Button
                  type="primary"
                  onClick={() =>
                    this.props.dispatch(
                      actions.userOpenCreateEventsMenuFromTopBar()
                    )
                  }
                  disabled={!hasSomeCreateElementsPermissions}
                >
                  Create
                </Button>
              </Popover>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const TopBarConnected = connect((state: RootState) => {
  const hasCreateNotePermission = hasPermission(
    state,
    'AG-Timeline-Create-Note'
  );
  const hasCreateOrderPermission = hasPermission(
    state,
    'AG-Timeline-Create-Order'
  );
  const hasCreateMxEventPermission = hasPermission(
    state,
    'AG-Timeline-Create-MX-Event'
  );
  const hasCreatePeakDayPermission = hasPermission(
    state,
    'AG-Timeline-Timeline-Event-Edit' // permission for Peak Day
  );
  const hasHandoverViewPermission = hasPermission(
    state,
    'AG-Timeline-Handover-View'
  );
  const hasSomeCreateElementsPermissions =
    hasCreateNotePermission ||
    hasCreateOrderPermission ||
    hasCreateMxEventPermission ||
    hasCreatePeakDayPermission;
  return {
    isShowFilter: state.ui.isShowFilter,
    isVerticalMode: state.ui.isVerticalMode,
    hasCreateMxEventPermission,
    hasCreateOrderPermission,
    hasCreateNotePermission,
    hasCreatePeakDayPermission,
    hasSomeCreateElementsPermissions,
    hasHandoverViewPermission,
  };
})(TopBar);
