import {
  ArrowLeftOutlined,
  CloseOutlined,
  PlusOutlined,
  PushpinOutlined,
} from '@ant-design/icons';
import { Button, Popconfirm, Tooltip } from 'antd';
import { FC, useState } from 'react';
import {
  doSetHandoverNavigateTo,
  userCloseHandoverRemarksDrawer,
  userOpenHandoverRemarksDrawer,
} from '../../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { isEqual } from 'lodash';
import { HandoverRemarksState } from '../../../../reducers/handover-remarks';
import { OneRemarkTitleComponent } from './OneRemarkTitle';
import { hasPermission } from '../../../../utils/check-permissions';

const NavigationButton: FC<{
  isChangedOrder: boolean;
  hasUnsavedRemarks: boolean;
  onCancel: () => void;
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onBlur: () => void;
  title: string;
  visiblePopConfirm: boolean;
}> = ({
  isChangedOrder,
  hasUnsavedRemarks,
  onCancel,
  onClick,
  onBlur,
  title,
  visiblePopConfirm,
}) => {
  const saving = useSelector<RootState, boolean>(
    state => state.handoverRemarks.savingIds?.length > 0
  );
  return (
    <Popconfirm
      onCancel={onCancel}
      title={
        isChangedOrder && !hasUnsavedRemarks
          ? 'Would you like to save new order of remarks?'
          : 'Would you like to save changes before switch?'
      }
      okText="Yes, Save"
      cancelText="No"
      open={visiblePopConfirm}
      onOpenChange={onBlur}
      okButtonProps={{
        loading: saving,
        htmlType: 'submit',
        form: 'handoverRemarks',
      }}
    >
      <Button
        key={title}
        onClick={onClick}
        style={{
          height: '28px',
        }}
        icon={title === 'Back' ? <ArrowLeftOutlined /> : null}
      >
        {title}
      </Button>
    </Popconfirm>
  );
};

interface Props {
  editingIds: string[];
  isChangedOrder: boolean;
  isShowingRelated: boolean;
  title: string;
  handleClose: () => void;
  onAddRemark: () => void;
  onClickPin: () => void;
  onToggleRelatedRemarks: () => void;
  setOrderChanged: (flag: boolean) => void;
}

export const CurrentHandoverRemarksTitle: FC<Props> = ({
  editingIds,
  isChangedOrder,
  isShowingRelated,
  handleClose,
  onAddRemark,
  onClickPin,
  onToggleRelatedRemarks,
  setOrderChanged,
  title,
}) => {
  // select store state
  const { navigate, unPinned, flight, aircraftId } = useSelector<
    RootState,
    Partial<HandoverRemarksState>
  >(
    state => ({
      aircraftId: state.handoverRemarks.aircraftId,
      flight: state.handoverRemarks.flight,
      navigate: state.handoverRemarks.navigate,
      unPinned: state.handoverRemarks.unPinned,
    }),
    isEqual
  );
  const hasOpsEditPermission = useSelector<RootState, boolean>(state =>
    hasPermission(state, 'AG-Timeline-Handover-Ops_Edit')
  );
  const hasCsEditPermission = useSelector<RootState, boolean>(state =>
    hasPermission(state, 'AG-Timeline-Handover-CS_Edit')
  );
  const isNavigating = Boolean(navigate?.remarkId && navigate?.from);
  const hasUnsavedRemarks = editingIds.length > 0;
  const isTail = title.startsWith('Tail');
  const buttonTitle = `${isShowingRelated ? 'Hide' : 'Show'} ${
    isTail ? 'flight' : 'tail'
  } remarks`;
  // actions
  const dispatch = useDispatch();
  const onNavigate = () => {
    // in case click cancel from popup
    if (isChangedOrder) {
      setOrderChanged(false);
    }
    // navigation it self
    dispatch(
      userOpenHandoverRemarksDrawer({
        isFor: navigate.from,
      })
    );
  };
  const [visible, setVisible] = useState(false);
  const onClickNavigate = () => {
    if (isChangedOrder || hasUnsavedRemarks) {
      dispatch(doSetHandoverNavigateTo({ to: 'Back' }));
      if (!visibleAll) {
        setVisible(isChangedOrder || hasUnsavedRemarks);
      } else {
        onConfirm();
      }
    } else {
      onNavigate();
    }
  };
  const onViewAll = () =>
    dispatch(
      userOpenHandoverRemarksDrawer({
        isFor: null,
        navigateFrom: null,
      })
    );
  const [visibleAll, setVisibleAll] = useState(false);
  const onClickViewAll = () => {
    if (isChangedOrder || hasUnsavedRemarks) {
      dispatch(doSetHandoverNavigateTo({ to: 'All' }));
      if (!visible) {
        setVisibleAll(isChangedOrder || hasUnsavedRemarks);
      } else {
        onConfirm();
      }
    } else {
      onViewAll();
    }
  };
  const onBlur = () => {
    setVisible(false);
    setVisibleAll(false);
  };
  const onConfirm = () => {
    setVisible(false);
    setVisibleAll(false);
  };
  return (
    <>
      <div className="remarks-title-row">
        <h2>Handover Remarks</h2>
        <div className="remarks-title-row-buttons">
          {isNavigating && (
            <NavigationButton
              isChangedOrder={isChangedOrder}
              hasUnsavedRemarks={hasUnsavedRemarks}
              onCancel={onNavigate}
              onClick={onClickNavigate}
              onBlur={onBlur}
              title="Back"
              visiblePopConfirm={visible}
            />
          )}
          <NavigationButton
            isChangedOrder={isChangedOrder}
            hasUnsavedRemarks={hasUnsavedRemarks}
            onCancel={onViewAll}
            onClick={onClickViewAll}
            onBlur={onBlur}
            title="View all"
            visiblePopConfirm={visibleAll}
          />
          <Tooltip title="Add new Handover remark">
            <Button
              icon={<PlusOutlined />}
              type="primary"
              onClick={onAddRemark}
              style={{ width: '26px' }}
              className="remarks-title-row-buttons-plus"
              disabled={!hasCsEditPermission && !hasOpsEditPermission}
            />
          </Tooltip>
          <Tooltip
            title={unPinned ? 'Pin Handover panel' : 'Unpin Handover panel'}
          >
            <Button
              style={{ marginLeft: '25px' }}
              type="primary"
              icon={<PushpinOutlined />}
              size="small"
              onClick={onClickPin}
            />
          </Tooltip>
          <Button
            icon={<CloseOutlined />}
            onClick={handleClose}
            style={{
              border: 'none',
              marginLeft: '8px',
              visibility: unPinned ? 'visible' : 'hidden',
            }}
            size="small"
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'baseline',
          justifyContent: 'space-between',
        }}
      >
        <Tooltip title={title}>
          <h5 className="remarks-title-flight-details">
            {
              <OneRemarkTitleComponent
                title={title}
                flight={flight}
                forTail={!!aircraftId}
              />
            }
          </h5>
        </Tooltip>
        <Button
          type="link"
          style={{ padding: '0 10px' }}
          onClick={onToggleRelatedRemarks}
        >
          {buttonTitle}
        </Button>
      </div>
    </>
  );
};
