import './styles.scss';
import { Button, Drawer, Table } from 'antd';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  userClickCreatePeakDay,
  userResetPeakDaysFilters,
  userTogglePeakDaysModal,
} from '../../../actions';
import { RootState } from '../../../reducers';
import { EVENT_LIST_PEAK_DAYS_COLUMNS } from './columns';
import PeakDay from '../../../types/peak-day';
import { DatePickerFrom, DatePickerTo } from './components/date-picker';
import { getFilteredPeakDays } from '../../../selectors/peak-days';
import { utc } from 'moment';
import { useResizeColumns } from '../../../common/components/resizable-table/useResizeColumns';
import {
  SelectFilterAdvertising,
  SelectFilterCrew,
} from './components/select-filter';
import { hasPermission } from '../../../utils/check-permissions';
import { CloseOutlined } from '@ant-design/icons';

export const PeakDaysModal: FC<{}> = () => {
  const dispatch = useDispatch();
  const { components, endUpColumns } = useResizeColumns<PeakDay>(
    EVENT_LIST_PEAK_DAYS_COLUMNS
  );
  const onClose = () => dispatch(userTogglePeakDaysModal());
  const onClickCreatePeakDay = () => dispatch(userClickCreatePeakDay());
  const onResetFilters = () => dispatch(userResetPeakDaysFilters());
  const peakDaysData = useSelector<RootState, PeakDay[]>(getFilteredPeakDays);
  const visible = useSelector<RootState, boolean>(
    state => state.peakDays.isEventListModalVisible
  );
  const hasEditPermission = useSelector<RootState, boolean>(state =>
    hasPermission(state, 'AG-Timeline-Timeline-Event-Edit')
  );
  const title = (
    <div className="drawer-title-wrapper">
      <span className="drawer-title">Peak days</span>
      <Button
        icon={<CloseOutlined />}
        onClick={onClose}
        style={{ border: 'none' }}
      />
    </div>
  );
  return (
    <Drawer
      closable={false}
      rootClassName="event-list"
      title={title}
      placement="bottom"
      onClose={onClose}
      open={visible}
      styles={{
        body: {
          padding: '5px 24px',
        },
      }}
      height="436px"
    >
      <div className="event-list-top">
        <div className="event-list-top-filter">
          <DatePickerFrom />
          <DatePickerTo />
          <SelectFilterAdvertising />
          <SelectFilterCrew />
          <Button
            className="event-list-top-filter-button"
            onClick={onResetFilters}
          >
            Reset
          </Button>
        </div>
        <Button
          className="event-list-top-create-tl-event-button"
          onClick={onClickCreatePeakDay}
          disabled={!hasEditPermission}
        >
          Create a New Peak day
        </Button>
      </div>
      <Table
        bordered
        rootClassName="event-list-table"
        dataSource={peakDaysData}
        columns={endUpColumns}
        components={components}
        pagination={{ pageSize: 7 }}
        rowKey={record => `${utc(record.start).format('DD/MM/YY')}`}
      />
    </Drawer>
  );
};
