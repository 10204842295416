import { axiosAuthed as axios } from '../axios-config';
import { transformPeakDayToFE } from './peak-day-model';

export const getPeakDays = () =>
  axios
    .get('/integration/timeline/gvevents')
    .then(resp => resp.data.map(transformPeakDayToFE))
    .catch(e => {
      throw e;
    });
