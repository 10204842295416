import {
  OrderBusinessTypeId,
  OperationalStatus,
  UpgradeReason,
} from '../types/flight';

export const AXIS_TOP_MARGIN = 30;
export const LINE_HEIGHT = 16;
export const THIN_ELEMENT_HEIGHT = 12;
export const LIFT_UP_CASCADER_ELEMENT_HEIGHT = 9;
export const CENTRED_DOT_SYMBOL = '\u00B7';
export const CENTRED_DOT_SYMBOL_BOLD = '•';
export const DAY_IN_MILLISECONDS = 86400000; // 1000 * 60 * 60 * 24;
export const ONE_SECOND_MS = 1000;
export const ONE_MINUTE_MS = 60 * ONE_SECOND_MS;
export const ONE_HOUR_IN_MINUTES = 60;
export const HOUR_IN_MS = 60 * 60 * ONE_SECOND_MS;
// flights
export const FLIGHT_FIRST_LINE_HEIGHT = 3;
export const FLIGHT_SECOND_LINE_HEIGHT = 12;
export const FLIGHT_THIRD_LINE_HEIGHT = 14;

export const HANDLING_ID = 32;
export const SPECIAL_LOAD_START_ID = 361;
export const SPECIAL_LOAD_END_ID = 371;
export const NEW_SPECIAL_LOAD_ID = 5187;
export const LIGHT_TEXT_COLOR = 'white';
export const DARK_TEXT_COLOR = 'rgba(38,38,38,1)';
export const ON_DEMAND_LIKE_BUSINESS_TYPES_ARR = [
  OrderBusinessTypeId.ON_DEMAND,
  OrderBusinessTypeId.VJ_DIRECT,
  OrderBusinessTypeId.B2S,
  OrderBusinessTypeId.OWNER_RELEASE,
];
export const FP_OPERATIONAL_STATUS_MAP = {
  [OperationalStatus.QUOTED]: false,
  [OperationalStatus.OPTION]: true,
  [OperationalStatus.CONFIRMED]: true,
  [OperationalStatus.READY]: true,
  [OperationalStatus.FLYING]: false,
  [OperationalStatus.LANDED]: true,
  [OperationalStatus.COMPLETED]: false,
  [OperationalStatus.CANCELLED]: false,
  [OperationalStatus.UNCONFIRMED]: true,
  [OperationalStatus.NO_SHOW]: false,
};
export const UPGRADE_REASON_LABEL_MAPPER = {
  [UpgradeReason.NO_UPGRADE_ID]: 'No Upgrade',
  [UpgradeReason.OPERATIONAL_UPGRADE_ID]: 'Operational Upgrade',
  [UpgradeReason.OPERATIONAL_UPGRADE_CREW_AVAILABILITY_ID]:
    'Operational Upgrade - Crew Availability',
  [UpgradeReason.OPERATIONAL_UPGRADE_AIRCRAFT_AVAILABILITY_ID]:
    'Operational Upgrade - Aircraft Availability',
  [UpgradeReason.OPERATIONAL_UPGRADE_AICRAFT_PERFORMANCE_ID]:
    'Operational Upgrade - Aircraft Performance',
  [UpgradeReason.OPERATIONAL_UPGRADE_AOG_ID]: 'Operational Upgrade - AOG',
  [UpgradeReason.GUARANTEED_UPGRADE_COMMERCIAL_RELATED_ID]:
    'Guaranteed Upgrade - Commercial related',
  [UpgradeReason.GUARANTEED_UPGRADE_CUSTOMER_SERVICE_RELATED_ID]:
    'Guaranteed Upgrade - Customer Service related',
  [UpgradeReason.COMPLIMENTARY_UPGRADE_COMMERCIAL_RELATED_ID]:
    'Complimentary Upgrade - Commercial related',
  [UpgradeReason.COMPLIMENTARY_UPGRADE_CUSTOMER_SERVICE_RELATED_ID]:
    'Complimentary Upgrade - Customer Service related',
  [UpgradeReason.GUARANTEED_UPGRADE_ID]: 'Guaranteed Upgrade',
  [UpgradeReason.COMPLIMENTARY_UPGRADE_ID]: 'Complimentary Upgrade',
  [UpgradeReason.CLIENT_REQUESTED_UPGRADE_ID]: 'Do Not Use',
};
export const WIDTH_FLIGHT_MENU = 200;
export const HEIGHT_FLIGHT_MENU_TAILS = 200;
export const HOLD_FLIGHT_HEIGHT = 11;
export const HOLD_FLIGHT_MARGIN = 1;
export const HOLD_FLIGHT_MIN_WIDTH = 148.15;
export const HOLD_LANE_STROKE = 2;

export const MAX_HOLD_FLIGHTS_ON_LESS_THAN_3_DAYS_VIEW = 10;
export const GENERATE_NEW_ID = -1;
export const GV_INPUT_SOURCE_ID = 2;
export const TOP_BAR_HEIGHT = 116;
export const SUPPORTED_RESOLUTION_WIDTH = 1920;

export const MIN_RANGE_SELECTION = 4;
export const TRIP_PROFILE_MAX_LENGTH = 4;
export const COMMON_TOOLTIP_WIDTH = 320;
export const OFFSET_FOR_VERTICAL_MODE = 40;
export const MULTIPLE_SEARCH_BAR_HEIGHT = 60;
export const FILTER_BAR_HEIGHT = 50;
export const RIGHT_DRAWER_WIDTH = 420;

export const REG_EXP_NEW_LINE_IN_EDITOR = `</p>(\r\n|\n|\r)`;

export const MAX_RECONNECT_ATTEMPTS = 8;
