import * as d3S from 'd3-selection';
import * as actions from '../../../../../actions';
import { store as reduxStore } from '../../../../../root';
import {
  getElementOffsetWithKoef,
  getLaneHeightKoef,
} from '../../../../../reducers/ui';
import Note, { MergedNote } from '../../../../../types/note';
import {
  getNoteBoundingElement,
  getEuclideanDistance,
  setDataTestEntityIdD3Elements,
} from '../../../../../utils';
import { ExtendedEventElement } from '../..';
import {
  buildPropsForComponent,
  getLeftMarginForMergedElement,
} from '../utils';
import { hasPermission } from '../../../../../utils/check-permissions';
import {
  THIN_ELEMENT_HEIGHT,
  LIFT_UP_CASCADER_ELEMENT_HEIGHT,
  MIN_RANGE_SELECTION,
} from '../../../../../constants';
import { isMergeNote } from '../../../../../utils/note';

export const getMergedGeneralNotesD3creator = d => {
  const state = reduxStore.getState();
  const hasViewPermission = hasPermission(state, 'AG-Timeline-View-Note');
  const hasEditPermission = hasPermission(state, 'AG-Timeline-Create-Note');
  const { transform, segmentsVisibility, positionMap } = state.ui;
  const { togglersState } = state.aircraft;

  const container = d
    .append('div')
    .classed('label-text', true)
    .style('transform', d => `translate(${d.x}px, ${d.y}px)`)
    .style(
      'top',
      el =>
        `${getElementOffsetWithKoef(
          segmentsVisibility,
          'generalNotes',
          togglersState[el.aircraftId],
          positionMap
        ) * transform.ky}px`
    )
    .each(function(d) {
      const container = this;
      if (!isMergeNote(d)) {
        d3S
          .select(container)
          .append('div')
          .classed('cascade-single-element-text-label', true)
          .classed('notes-text-label', true)
          .attr(
            'data-test-entity',
            setDataTestEntityIdD3Elements('notes-text-label')
          )
          .classed('general-notes-text-label', true)
          .attr('data-content', d.message)
          .style('position', 'absolute')
          .style(
            'width',
            (d: ExtendedEventElement) =>
              `${d.x < 0 ? Math.max(d.width + d.x, 0) : d.width}px`
          )
          .style(
            'margin-left',
            (d: ExtendedEventElement) =>
              `${-d.x > d.width ? 0 : Math.max(-d.x, 0)}px`
          )
          .style(
            'height',
            (d: ExtendedEventElement) =>
              `${(THIN_ELEMENT_HEIGHT * transform.ky) /
                getLaneHeightKoef(
                  segmentsVisibility,
                  togglersState[d.aircraftId],
                  positionMap
                )}px`
          )
          .on('mouseover', function(d: Note) {
            d3S.select(this).style('cursor', 'pointer');
            reduxStore.dispatch(
              actions.userHoverSegment([
                d,
                getNoteBoundingElement(this),
                'generalNotes',
              ])
            );
          })
          .on('mouseout', function(d: Note) {
            reduxStore.dispatch(actions.userCloseTooltipSegment());
          })
          .on('contextmenu', function(d: Note) {
            d3S.event.preventDefault();
            d3S.event.stopPropagation();
            hasEditPermission &&
              reduxStore.dispatch(
                actions.userOpenNoteContextMenu([
                  getNoteBoundingElement(this),
                  d,
                ])
              );
          })
          .on(
            'click',
            (d: Note) =>
              hasViewPermission &&
              !isMergeNote(d) &&
              getEuclideanDistance(reduxStore.getState().ui.timelineSelection) <
                MIN_RANGE_SELECTION &&
              reduxStore.dispatch(
                actions.userClickOpenNote({
                  id: d.id,
                  end: d.end,
                  start: d.start,
                })
              )
          )
          .text((d: Note) => d.message)
          .attr('data-test-entity-id', (d: Note) => d.id);
      } else {
        d.notes.forEach(function(note, i) {
          const noteWithProps = buildPropsForComponent(note);
          const parentNote = d as MergedNote & ExtendedEventElement;
          const id = note.id;
          const aliquotNoteWithProps =
            d.notes[i + 4] && buildPropsForComponent(d.notes[i + 4]);
          const textWidth = aliquotNoteWithProps
            ? aliquotNoteWithProps.x - noteWithProps.x
            : noteWithProps.width;
          d3S
            .select(container)
            .append('div')
            .classed('cascade-single-element-text-label', true)
            .style(
              'width',
              `${
                noteWithProps.x < 0
                  ? Math.max(noteWithProps.width + noteWithProps.x, 0)
                  : noteWithProps.width
              }px`
            )
            .attr('data-test-entity-id', note.id)
            .classed('notes-text-label', true)
            .attr(
              'data-test-entity',
              setDataTestEntityIdD3Elements('notes-text-label')
            )
            .classed('merged-note-text-label', true)
            .attr('data-content', note.message)
            .style(
              'margin-left',
              `${getLeftMarginForMergedElement(parentNote, noteWithProps)}px`
            )
            .style('position', 'absolute')
            .style(
              'top',
              `${((LIFT_UP_CASCADER_ELEMENT_HEIGHT * transform.ky) /
                getLaneHeightKoef(
                  segmentsVisibility,
                  togglersState[note.aircraftId],
                  positionMap
                )) *
                (i % 4)}px`
            )
            .style(
              'height',
              d =>
                `${(THIN_ELEMENT_HEIGHT * transform.ky) /
                  getLaneHeightKoef(
                    segmentsVisibility,
                    togglersState[note.aircraftId],
                    positionMap
                  )}px`
            )
            .on(
              'click',
              () =>
                hasViewPermission &&
                getEuclideanDistance(
                  reduxStore.getState().ui.timelineSelection
                ) < MIN_RANGE_SELECTION &&
                reduxStore.dispatch(
                  actions.userClickOpenNote({
                    id,
                    end: note.end,
                    start: note.start,
                  })
                )
            )
            .on('mouseover', function(d) {
              reduxStore.dispatch(
                actions.userHoverSegment([
                  note,
                  getNoteBoundingElement(this),
                  'generalNotes',
                ])
              );
              d3S.select(this).style('cursor', 'pointer');
            })
            .on('mouseout', function(d) {
              reduxStore.dispatch(actions.userCloseTooltipSegment());
            })
            .on('contextmenu', function(d) {
              d3S.event.preventDefault();
              d3S.event.stopPropagation();
              hasEditPermission &&
                reduxStore.dispatch(
                  actions.userOpenNoteContextMenu([
                    getNoteBoundingElement(this),
                    note,
                  ])
                );
            })
            .append('span')
            .style('display', 'inline-block')
            .style('width', `${textWidth}px`)
            .classed('notes-text-label', true)
            .attr(
              'data-test-entity',
              setDataTestEntityIdD3Elements('notes-text-label')
            )
            .text(note.message);
        });
      }
    });
  return container;
};
