import { SegmentsAppenderArgs } from '..';
import { getLaneHeightKoef } from '../../../../../../reducers/ui';
import { OperationalStatus } from '../../../../../../types/flight';
import { getScheduledPlusBusinessTypeColor } from '../../../../../../common/flight/flight-colorization';

export function appendSecondLine(argsObject: SegmentsAppenderArgs) {
  const {
    selection,
    segmentsVisibility,
    positionMap,
    togglersState,
    scaleX,
    firstLineHeight,
    secondLineHeight,
  } = argsObject;
  // Scheduled + business type color
  selection
    .append('rect')
    .attr(
      'height',
      f =>
        secondLineHeight /
        getLaneHeightKoef(
          segmentsVisibility,
          togglersState[f.aircraftId],
          positionMap
        )
    )
    .attr(
      'y',
      d =>
        d.y +
        firstLineHeight /
          getLaneHeightKoef(
            segmentsVisibility,
            togglersState[d.aircraftId],
            positionMap
          )
    )
    .attr('x', d => {
      if (
        d.legOperationalStatusId == OperationalStatus.NO_SHOW ||
        d.legOperationalStatusId == OperationalStatus.OPTION
      ) {
        return scaleX(d.start) || 0;
      }
      return scaleX(d.departureUtcScheduled) || 0;
    })
    .attr('width', d => {
      if (
        d.legOperationalStatusId == OperationalStatus.NO_SHOW ||
        d.legOperationalStatusId == OperationalStatus.OPTION
      ) {
        return this.calculateWidthFor(d.start, d.end, scaleX) || 0;
      }
      return (
        this.calculateWidthFor(
          d.departureUtcScheduled,
          d.arrivalUtcScheduled,
          scaleX
        ) || 0
      );
    })
    .style('fill', getScheduledPlusBusinessTypeColor);
}
