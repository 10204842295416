import { DAY_IN_MILLISECONDS, ONE_HOUR_IN_MINUTES } from '../../constants';
import Aircraft from '../../types/aircraft';
import MaintenanceItem from '../../types/maintenance-item';

enum CycleRange {
  YELLOW = 9,
  GREEN = 49,
}
enum HoursRange {
  YELLOW = 19,
  GREEN = 49,
}
enum DueDateRange {
  RED = 2,
  GREEN = 5,
}
type MelZoneColor = 'red' | 'orange' | 'green';

export const getMelZoneColor = (
  aircraft: Aircraft,
  mel: MaintenanceItem,
  today: number
): MelZoneColor | null => {
  if (!aircraft) {
    return null;
  }
  const { totalLandings, minutesFlown } = aircraft;
  const hoursDifference = Math.round(
    (mel.hoursInMinutes - minutesFlown) / ONE_HOUR_IN_MINUTES
  );
  if (
    (mel.dueDate &&
      mel.dueDate < today + DueDateRange.RED * DAY_IN_MILLISECONDS) ||
    (mel.hoursInMinutes &&
      minutesFlown &&
      hoursDifference <= HoursRange.YELLOW) ||
    (mel.cycles &&
      totalLandings &&
      mel.cycles - totalLandings <= CycleRange.YELLOW)
  ) {
    return 'red';
  }
  if (
    (mel.dueDate &&
      mel.dueDate < today + DueDateRange.GREEN * DAY_IN_MILLISECONDS) ||
    (mel.hoursInMinutes &&
      minutesFlown &&
      hoursDifference <= HoursRange.GREEN) ||
    (mel.cycles &&
      totalLandings &&
      mel.cycles - totalLandings <= CycleRange.GREEN)
  ) {
    return 'orange';
  }
  return 'green';
};

export const getMelBackgroundColor = (zone: string): string => {
  if (zone === 'red') {
    return '#FF545445';
  }
  if (zone === 'orange') {
    return '#FEF5E6';
  }
  return '#51A4485C';
};

export const getMelTagColor = (
  aircraft: Aircraft,
  mels: MaintenanceItem[],
  today: number
): string => {
  const colors = mels.map(m => getMelZoneColor(aircraft, m, today));
  return colors.includes('red')
    ? 'red'
    : colors.includes('orange')
    ? '#e0e006'
    : 'green';
};
