import { store as reduxStore } from '../../../../../root';
import * as d3S from 'd3-selection';
import * as actions from '../../../../../actions';
import {
  getElementOffsetWithKoef,
  getLaneHeightKoef,
} from '../../../../../reducers/ui';
import {
  getBoundingElement,
  getEuclideanDistance,
  openWindowAppEmptyLeg2020,
  setDataTestEntityIdD3Elements,
} from '../../../../../utils';
import EmptyLegOffer from '../../../../../types/empty-leg-offer';
import { MIN_RANGE_SELECTION } from '../../../../../constants';
import { getICAOLabelFromAirport } from '../../../../../utils/airport';
import { getOfferStatusName } from '../../../../../utils/empty-leg-offer';

export const getEmptyLegOffersD3creator = (
  d: d3S.Selection<any, any, any, any>
): d3S.Selection<any, any, any, any> => {
  const state = reduxStore.getState();
  const { togglersState } = state.aircraft;
  const { airportsById } = state.airports;
  const { transform, segmentsVisibility, positionMap } = state.ui;
  const container = d
    .append('div')
    .classed('label-text', true)
    .classed('empty-leg-offer-text-label', true)
    .attr(
      'data-test-entity',
      setDataTestEntityIdD3Elements('empty-leg-offer-text-label')
    )
    .style('transform', d => `translate(${d.x}px, ${d.y}px)`)
    .style('padding-left', d => `${Math.max(-d.x, 0)}px`)
    .on('mouseover', function(d) {
      d3S.select(this).style('cursor', 'pointer');
      reduxStore.dispatch(
        actions.userHoverSegment([
          d,
          getBoundingElement(this),
          'emptyLegOffers',
        ])
      );
    })
    .on('mouseout', function(d) {
      reduxStore.dispatch(actions.userCloseTooltipSegment());
    })
    .on('contextmenu', d => {
      d3S.event.preventDefault();
      d3S.event.stopPropagation();
      reduxStore.dispatch(
        actions.userOpenContextMenuForEmptyLegOffer({
          id: d.id,
          position: { x: d3S.event.clientX, y: d3S.event.clientY },
        })
      );
    })
    .on(
      'click',
      d =>
        getEuclideanDistance(reduxStore.getState().ui.timelineSelection) <
          MIN_RANGE_SELECTION && openWindowAppEmptyLeg2020({ id: d.id })
    )
    .style(
      'top',
      el =>
        `${getElementOffsetWithKoef(
          segmentsVisibility,
          'emptyLegOffers',
          togglersState[el.aircraftId],
          positionMap
        ) * transform.ky}px`
    )
    .style(
      'height',
      d =>
        `${(positionMap.emptyLegOffers * transform.ky) /
          getLaneHeightKoef(
            segmentsVisibility,
            togglersState[d.aircraftId],
            positionMap
          )}px`
    )
    .style('width', d => `${d.width}px`)
    .style(
      'padding-right',
      d => `${Math.max(d.x + d.width - transform.scaleX.range()[1], 0)}px`
    )
    .text(
      ({ offerStatus, departureAirportId, arrivalAirportId }: EmptyLegOffer) =>
        `${getICAOLabelFromAirport(
          airportsById[departureAirportId]
        )} - ${getICAOLabelFromAirport(
          airportsById[arrivalAirportId]
        )} - ${getOfferStatusName(offerStatus)}`
    );
  return container;
};
