import { PureComponent } from 'react';
import { getServiceStatusType } from '../../common/flight/flight-colorization';
import { getServiceInfo } from '../../common/flight/flight-labels';
import Flight from '../../types/flight';
import StatusRow from './status-row';

interface Props {
  selectedFlight: Flight;
}

class ServicesRow extends PureComponent<Props> {
  getArrText() {
    const { selectedFlight } = this.props;
    return getServiceInfo(selectedFlight, selectedFlight.arrivalAirportId);
  }
  getDepText() {
    const { selectedFlight } = this.props;
    return getServiceInfo(selectedFlight, selectedFlight.departureAirportId);
  }
  getArrClassName() {
    const { selectedFlight } = this.props;
    return getServiceStatusType(
      selectedFlight,
      selectedFlight.arrivalAirportId
    );
  }
  getDepClassName() {
    const { selectedFlight } = this.props;
    return getServiceStatusType(
      selectedFlight,
      selectedFlight.departureAirportId
    );
  }
  render() {
    return (
      <>
        <div className="ft-name">Services</div>
        <StatusRow
          arrText={this.getArrText()}
          depText={this.getDepText()}
          arrClassName={this.getArrClassName()}
          depClassName={this.getDepClassName()}
        />
      </>
    );
  }
}

export default ServicesRow;
