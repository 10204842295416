import { isEqual } from 'lodash';
import { utc } from 'moment';
import { PureComponent } from 'react';
import { getAFBVersionInfo } from '../../common/flight/flight-colorization';
import { AfbSnapshot } from '../../services/flight-service/flight-model';
import { AfbCrewType } from '../../types/afbCrewType';
import { universalDateAndTimeFormatter } from '../../utils/time';

interface Props {
  afbSnapshot: AfbSnapshot;
  HAS_PAX_PERMISSION: boolean;
  leadName: string;
  numberOfPassengers: number;
  passengersCount: number;
  crewType: AfbCrewType[];
  isCorrectAfb: boolean;
}

class PaxRow extends PureComponent<Props> {
  crewType: AfbCrewType[];
  afbVersionInfo: {
    color: string;
    ack: string;
  };
  getAfbVersionInfo() {
    const afbVersionInfo = getAFBVersionInfo(this.props.crewType);
    if (!isEqual(this.afbVersionInfo, afbVersionInfo)) {
      this.afbVersionInfo = afbVersionInfo;
    }
    return this.afbVersionInfo;
  }
  getFbText() {
    const { afbSnapshot } = this.props;
    const afbVersionInfo = this.getAfbVersionInfo();
    if (!this.props.isCorrectAfb || !afbSnapshot || !afbSnapshot.hrv) {
      return '';
    }
    if (!afbVersionInfo) {
      return afbSnapshot.hrv;
    }
    return `${afbSnapshot.hrv} ${afbVersionInfo.ack}`;
  }
  getPaxInfo() {
    const { numberOfPassengers, passengersCount } = this.props;
    return `${numberOfPassengers} Exp./${numberOfPassengers -
      passengersCount} TBA`;
  }
  render() {
    const { HAS_PAX_PERMISSION, afbSnapshot, leadName } = this.props;
    return (
      <div className="ft-box-group">
        <div className="ft-box">
          <div
            className="ft-box-label"
            style={{ color: this.getAfbVersionInfo().color }}
          >
            FB
            {this.getFbText()}
          </div>
          <div>
            {this.props.isCorrectAfb && afbSnapshot && afbSnapshot.timestamp
              ? universalDateAndTimeFormatter(
                  utc(afbSnapshot.timestamp),
                  'fullUtc'
                )
              : 'N/A'}
          </div>
        </div>

        <div className="ft-box">
          <div className="ft-box-label">Number of pax</div>
          <div>{this.getPaxInfo()}</div>
        </div>

        {HAS_PAX_PERMISSION && (
          <div className="ft-box-wide">
            <div className="ft-box-label">Lead Pax</div>
            <div>{leadName ? leadName : 'N/A'}</div>
          </div>
        )}
      </div>
    );
  }
}

export default PaxRow;
