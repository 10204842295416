import { PureComponent } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { utc } from 'moment';
import DatePicker from '../../common/components/date-time-pickers/date-picker';
import * as actions from '../../actions';
import { DispatchProp, connect } from 'react-redux';
import { RootState } from '../../reducers';
import { createDelayedForScrollConnect } from '../../utils/create-delayed-for-scroll-connect';
import { setDataTestEntityId } from '../../utils';
import { AnyAction } from 'redux';

interface StateProps {
  currentValue: number;
  isVerticalMode: boolean;
}
class DateSwitcher extends PureComponent<StateProps & DispatchProp<AnyAction>> {
  onDateChange = (momentDate: moment.Moment) => {
    this.props.dispatch(actions.userGoToCertainDate(momentDate));
  };
  onNextDate = () => {
    this.props.dispatch(actions.userGoToNextDate());
  };
  onPrevDate = () => {
    this.props.dispatch(actions.userGoToPrevDate());
  };
  render() {
    const { currentValue, isVerticalMode } = this.props;
    return (
      <div
        className="topbar-date-switcher"
        style={{ display: 'flex', marginLeft: isVerticalMode ? 10 : 30 }}
      >
        <Button
          title="Previous Date"
          onClick={this.onPrevDate}
          {...setDataTestEntityId('move-to-previous-date-button')}
        >
          <LeftOutlined />
        </Button>
        <DatePicker
          allowClear={false}
          value={utc(currentValue)}
          onChange={this.onDateChange}
          format={`DD MMM'YY`}
          {...setDataTestEntityId('ant-calendar-picker')}
        />
        <Button
          title="Next Date"
          onClick={this.onNextDate}
          {...setDataTestEntityId('move-to-next-date-button')}
        >
          <RightOutlined />
        </Button>
      </div>
    );
  }
}

const advancedMap = createDelayedForScrollConnect(
  (state: RootState): StateProps => ({
    currentValue: utc(state.ui.transform.scaleX.invert(250)).valueOf(),
    isVerticalMode: state.ui.isVerticalMode,
  })
);
export const DateSwitcherConnected = connect(advancedMap)(DateSwitcher);
