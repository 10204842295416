import {
  SegmentType,
  SegmentTypeOverlaps,
} from '../../../../../types/segment-types';
import { ExtendedEventElement } from '..';
import { SubscriptionTuple } from '..';

export interface EventGroupOwnProps {
  elementName: SegmentType | SegmentTypeOverlaps;
  getColor?: (d: ExtendedEventElement) => string;
  getBorderColor?: (d: ExtendedEventElement) => string;
  ownSubscriptions?: SubscriptionTuple[];
}
export const elementTypes: EventGroupOwnProps[] = [
  {
    elementName: 'airportMismatches',
    getColor: () => 'transparent',
  },
  {
    elementName: 'groundTime',
    getColor: () => 'transparent',
  },
  {
    elementName: 'fboMismatches',
    getColor: () => 'transparent',
  },
];

export const crewElements: EventGroupOwnProps[] = [
  {
    elementName: 'crewRoster',
    getColor: () => 'rgba(161,136,127,0.3)',
    getBorderColor: () => 'rgba(161,136,127,1)',
  },
  {
    elementName: 'crewAssignment',
    getColor: () => 'rgba(127,55,0,1)',
    getBorderColor: () => 'rgba(161,136,127,1)',
  },
];
