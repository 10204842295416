import { PureComponent } from 'react';
import { RootState } from '../../../reducers';
import './../styles.scss';
import { connect } from 'react-redux';
import {
  FlightGhostConnected,
  HoldFlightGhostConnected,
} from './ghosts-render';
import Aircraft from '../../../types/aircraft';
import { getIndexByYPosition } from '../../../reducers/ui';
import { getAircraftByIndex, getVisibleHoldAircraft } from '../../../selectors';

interface StateProps {
  selectedFlights: number[];
  selectedAircraft: Aircraft | undefined;
  index: number;
}

class FlightsGhosts extends PureComponent<StateProps> {
  render() {
    const { selectedFlights, selectedAircraft, index } = this.props;
    if (!selectedFlights.length || !selectedAircraft) return null;
    return selectedFlights.map(id => (
      <FlightGhostConnected
        key={id}
        id={id}
        selectedAircraft={selectedAircraft}
        index={index}
      />
    ));
  }
}
class HoldLineFlightsGhosts extends PureComponent<StateProps> {
  render() {
    const { selectedFlights, selectedAircraft } = this.props;
    if (!selectedFlights.length || !selectedAircraft) return null;
    return selectedFlights.map(id => (
      <HoldFlightGhostConnected
        key={id}
        id={id}
        selectedAircraft={selectedAircraft}
      />
    ));
  }
}

const mapStateToProps = (state: RootState): StateProps => {
  const { selectedFlights } = state.ui;
  const index = getIndexByYPosition(
    state,
    state.flightDetails.draggingPosition.y,
    getVisibleHoldAircraft(state)
  );
  return {
    selectedFlights,
    selectedAircraft: getAircraftByIndex(state, index),
    index,
  };
};

export const FlightsGhostsConnected = connect(mapStateToProps)(FlightsGhosts);

export const HoldLineFlightsGhostsConnected = connect(mapStateToProps)(
  HoldLineFlightsGhosts
);
