import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { Action, ActionCreator } from 'typescript-fsa';
import { RootState } from '../../reducers';

export class SubMiddle<StoreType> {
  listenersByType: Map<string, Array<Function>>;
  constructor() {
    this.listenersByType = new Map<string, Array<Function>>();
  }
  on<P>(
    action: ActionCreator<P>,
    f: (action?: Action<P>, state?: StoreType, prevState?: StoreType) => void
  ) {
    if (!this.listenersByType.has(action.type)) {
      this.listenersByType.set(action.type, [f]);
    } else {
      const prevArr = this.listenersByType.get(action.type);
      this.listenersByType.set(action.type, [...prevArr, f]);
    }
  }
  off<P>(
    action: ActionCreator<P>,
    f: (action?: Action<P>, state?: StoreType, prevState?: StoreType) => void
  ) {
    if (this.listenersByType.has(action.type)) {
      const prevArr = this.listenersByType.get(action.type);
      this.listenersByType.set(
        action.type,
        prevArr.filter(fn => fn !== f)
      );
    }
  }
  middleware: Middleware = <StoreType extends Dispatch<AnyAction>>(
    store: MiddlewareAPI<StoreType>
  ) => next => action => {
    const prevState = store.getState();
    next(action);
    if (this.listenersByType.has(action.type)) {
      const arrOflisteners = this.listenersByType.get(action.type);
      arrOflisteners.forEach(listener => {
        listener(action, store.getState(), prevState);
      });
    }
    return action;
  };
}

export interface SubscriptionFunction<P> {
  (Action?: Action<P>, state?: RootState, prevState?: RootState): void;
}
