import { createDelayedForScrollConnect } from '../../utils/create-delayed-for-scroll-connect';
import { RootState } from '../../reducers';
import { connect } from 'react-redux';

interface Props {
  ky: number;
  kx: number;
}
const radius = 5;
const SlotMismatchDotSymbol = ({ kx, ky }: Props) => {
  return (
    <symbol id="slot-mismatch-dot">
      <ellipse cx={17} cy={17} rx={radius / kx} ry={radius / ky} fill="red" />
    </symbol>
  );
};

const mapStateToProps = (state: RootState): Props => ({
  kx: state.ui.transform.kx,
  ky: state.ui.transform.ky,
});
const advancedMap = createDelayedForScrollConnect(mapStateToProps);

export const SlotMismatchDotSymbolConnected = connect(advancedMap)(
  SlotMismatchDotSymbol
);
