import styled from 'styled-components';

export const FilterBarRightStickedStyled = styled.div<{
  isVerticalMode: boolean;
}>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 8px;
  padding-bottom: ${p => (p.isVerticalMode ? '10px' : '0px')};
  & > .ant-btn + .ant-btn {
    margin-left: ${p => (p.isVerticalMode ? '5px' : '15px')};
  }
`;
