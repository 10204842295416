import * as Fuse from 'fuse.js';
import {
  chain,
  sortBy,
  uniqBy,
  spread,
  merge,
  uniqWith,
  isEqual,
} from 'lodash';
import { utc } from 'moment';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

import {
  isAircraftAvailable,
  isHoldAircraft,
  isSubCharter,
} from '../common/aircraft/aircraft-check-status';
import { isFlight } from '../common/flight/flight-check-status';
import {
  HOLD_AC_TYPE_ID,
  RWA_OPERATING_COMPANY_ID,
  VJ_OPERATING_COMPANY_ID,
  XO_JET_OPERATING_COMPANY_ID,
} from '../constants/environment';
import {
  elementsSelector,
  getElementsInVisibleTimeRange,
  getTouchedByFlightAircraftMap,
  isCrewElementVisible,
  isFlightVisible,
} from './event-elements';
import {
  createPoolWithOutOvernightDuplication,
  Pool,
} from '../data-processing/pool-structure';
import { RootState } from '../reducers';
import { AircraftState, AircraftTogglerState } from '../reducers/aircraft';
import { getSelectedStatusesIds } from '../reducers/operational-statuses';
import { SearchQueryAllFilters, SearchType } from '../reducers/search';
import { ReducerShape } from '../reducers/ui';
import Aircraft, {
  ExtendedAircraft,
  FilterByAircraftType,
  FilterByOperatingCompany,
  MxEventPresenceType,
  OperatingCompanyLabel,
  OperatingCompanyLabelData,
} from '../types/aircraft';
import Airport from '../types/airport';
import BaseCompany from '../types/base-company';
import { CrewAssignment, CrewRoster } from '../types/crew-roster';
import EventElement, { OverlappedElements } from '../types/event-element';
import Flight, {
  HoldFlightsPoolByAircraftIdMap,
  HoldFlightsPoolHoursDelimiter,
  PermissionSlotType,
} from '../types/flight';
import FlightBase from '../types/flight-base';
import { InputSourceIdType } from '../types/input-source';
import MaintenanceItem from '../types/maintenance-item';
import { AvailabilityNote } from '../types/note';
import { SegmentType } from '../types/segment-types';
import {
  filterSortAircraftForAllOptions,
  sortByCompanyRank,
  sortByOperatingCompany,
} from '../utils/aircraft';
import { isCrew, isCrewAssignment } from '../utils/crew-roster';
import {
  getOverlappedFlights,
  getOverlappedElements,
} from '../utils/event-element';
import {
  collectPoolForTimeFlight,
  sortFlightsByStart,
} from '../utils/hold-line-flights';
import {
  getCrewRostersForSearch,
  getOptionsForAutocomplete,
  mapFocusWindowRectsWithProps,
} from '../utils/search';
import { getLeadTimeBorderStyle } from '../common/flight/flight-colorization';
import { HandoverType } from '../types/handover-remarks';
import {
  filterByTeam,
  getFlightLegIds,
} from '../components/handover-remarks/utils';

// Note to escape an circular imports in unit tests all selectors which are called in others are collected here.
// Selectors combined by aircraft, hold aircraft, hold flights, search

// Aircraft --->>>>

export const getBaseCompaniesIds = createSelector<
  RootState,
  BaseCompany[],
  number[]
>(
  state => state.baseCompanies.baseCompaniesData,
  baseCompaniesData => baseCompaniesData.map(bc => bc.id)
);

export const getAircraftIndexById = createCachedSelector<
  RootState,
  number,
  Aircraft[],
  Aircraft[],
  number,
  number
>(
  state => getSortedVisibleAircraft(state),
  state => getVisibleHoldAircraft(state),
  (_state, id) => id,
  (aircraft, holdAircraft, id) =>
    [...holdAircraft, ...aircraft].findIndex(a => id === a.id)
)((_state, id) => id);

export const getAircraftByIndex = (
  state: RootState,
  index: number
): Aircraft => {
  const aircraft = getVisibleAircraftFleetWithHoldLines(state);
  return aircraft[index];
};

export const getAllOperatingAircraft = createSelector<
  RootState,
  number,
  number,
  Aircraft[],
  Aircraft[]
>(
  state => state.ui.transform.scaleX.domain()[0].valueOf(),
  state => state.ui.transform.scaleX.domain()[1].valueOf(),
  state => state.aircraft.aircraft,
  (start, end, aircraft) => {
    return aircraft.filter(ac => {
      // It is not active yet
      if (ac.operationStartDate && ac.operationStartDate > end) return false;
      // It has no end date
      if (!ac.operationEndDate) return true;
      // It is already not active
      if (ac.operationEndDate < start) return false;
      return true;
    });
  }
);

export const getAvailableAircraftForMoveToTailMenu = createSelector<
  RootState,
  Aircraft[],
  number[],
  Aircraft[]
>(
  getAllOperatingAircraft,
  getBaseCompaniesIds,
  (aircraft, baseCompaniesIds) => {
    return chain(aircraft)
      .filter(ac => !isSubCharter(ac, baseCompaniesIds))
      .sortBy('tailNumber')
      .sortBy('aircraftTypeRank')
      .sortBy(ac => sortByOperatingCompany(ac, baseCompaniesIds))
      .value();
  }
);
export const getAvailableAircraftForTypes = createSelector<
  RootState,
  Aircraft[],
  Aircraft[]
>(getAvailableAircraftForMoveToTailMenu, aircraft => {
  return chain(aircraft)
    .filter(ac => ac.aircraftTypeId !== HOLD_AC_TYPE_ID)
    .value();
});

export const getAllAcTypeOptions = createSelector<
  RootState,
  Aircraft[],
  { typeId: number; name: string; rank: number }[]
>(getAvailableAircraftForMoveToTailMenu, aircraft => {
  return chain(aircraft)
    .uniqBy(a => a.aircraftTypeId)
    .map(a => ({
      name: a.aircraftTypeCode,
      rank: a.aircraftTypeRank,
      typeId: a.aircraftTypeId,
    }))
    .sortBy('rank')
    .sortBy('name')
    .value();
});

export const getSubChartersAircraft = createSelector<
  RootState,
  Aircraft[],
  number[],
  {
    [aircraftId: number]: boolean;
  },
  Aircraft[]
>(
  getAllOperatingAircraft,
  getBaseCompaniesIds,
  getTouchedByFlightAircraftMap,
  (operative, baseCompaniesIds, touchedAircraftMap) => {
    return operative.filter(a => {
      if (isSubCharter(a, baseCompaniesIds)) {
        return !!touchedAircraftMap[a.id];
      }
    });
  }
);

export const getVisibleAircraft = createSelector<
  RootState,
  Aircraft[],
  boolean,
  number[],
  number[],
  {
    [aircraftId: number]: boolean;
  },
  Aircraft[]
>(
  getAllOperatingAircraft,
  state => state.aircraft.showSubCharters,
  state => state.aircraft.visibleAcIds,
  getBaseCompaniesIds,
  getTouchedByFlightAircraftMap,
  (
    operative,
    showSubCharters,
    visibleAcIds,
    baseCompaniesIds,
    touchedAircraftMap
  ) => {
    if (!showSubCharters) {
      return chain(operative)
        .filter(ac => !isSubCharter(ac, baseCompaniesIds))
        .filter(a => {
          return visibleAcIds?.includes(a.id);
        })
        .filter(ac => !isHoldAircraft(ac))
        .value();
    }
    return operative.filter(a => {
      if (isSubCharter(a, baseCompaniesIds)) {
        return !!touchedAircraftMap[a.id];
      }
      return visibleAcIds.includes(a.id) && !isHoldAircraft(a);
    });
  }
);

const getVisibleAircraftByMXEvents = createSelector<
  RootState,
  Aircraft[],
  MxEventPresenceType,
  Aircraft[]
>(
  getVisibleAircraft,
  state => state.ui.mxEventPresenceType,
  (visibleAircraft, mxEventPresenceType) => {
    return visibleAircraft.filter(el => {
      if (mxEventPresenceType === 'MX' && !isAircraftAvailable(el)) return el;
      if (mxEventPresenceType === 'RTS' && isAircraftAvailable(el)) return el;
      if (mxEventPresenceType === 'ALL') return el;
    });
  }
);

export const getAircraftById = createCachedSelector<
  RootState,
  number,
  Aircraft[],
  number,
  Aircraft
>(
  state => state.aircraft.aircraft,
  (_, id) => id,
  (aircraft, id) => aircraft.find(a => a.id === id)
)((_state, id) => id);

const extendAircraftWithUsersAcTypeSequence = createSelector<
  RootState,
  Aircraft[],
  { [companyId: number]: number[] },
  ExtendedAircraft[]
>(
  getVisibleAircraftByMXEvents,
  state => state.aircraft.acTypesPositionMap,
  (aircraft, acTypePositionMap) =>
    aircraft.map(ac => {
      if (acTypePositionMap[ac.operatingCompanyId]) {
        return {
          ...ac,
          userAircraftTypeRank:
            acTypePositionMap[ac.operatingCompanyId].indexOf(
              ac.aircraftTypeId
            ) + 1,
        };
      }
      return ac;
    })
);

export const getSortedVisibleAircraft = createSelector<
  RootState,
  Aircraft[],
  number[],
  boolean,
  Aircraft[]
>(
  extendAircraftWithUsersAcTypeSequence,
  getBaseCompaniesIds,
  state => state.aircraft.isGroupedByOperator,
  (visibleAircraft, baseCompaniesIds, isGroupedByOperator) => {
    if (isGroupedByOperator) {
      return chain(visibleAircraft)
        .sortBy('tailNumber')
        .sortBy('aircraftTypeRank')
        .sortBy('userAircraftTypeRank')
        .sortBy('operatingCompanyName')
        .sortBy((ac: Aircraft) => {
          if (ac.operatingCompanyId === VJ_OPERATING_COMPANY_ID) return 0;
          if (ac.operatingCompanyId === XO_JET_OPERATING_COMPANY_ID) return 1;
          if (ac.operatingCompanyId === RWA_OPERATING_COMPANY_ID) return 2;
          if (isSubCharter(ac, baseCompaniesIds)) return 4;
          return 3;
        })
        .value();
    }
    return chain(visibleAircraft)
      .sortBy('tailNumber')
      .sortBy('aircraftTypeRank')
      .sortBy((ac: Aircraft) => sortByOperatingCompany(ac, baseCompaniesIds))
      .value();
  }
);

export const getAircraftByIdMap = createSelector<
  RootState,
  Aircraft[],
  { [aircraftId: number]: Aircraft }
>(getSortedVisibleAircraft, aircraft =>
  aircraft.reduce<{ [aircraftId: number]: Aircraft }>((acc, aircraft) => {
    acc[aircraft.id] = aircraft;
    return acc;
  }, {})
);

export const getAllAvailableAircraftByIdMap = createSelector<
  RootState,
  Aircraft[],
  {
    [aircraftId: number]: Aircraft;
  }
>(getAvailableAircraftForMoveToTailMenu, aircraft =>
  aircraft.reduce((acc, aircraft) => {
    acc[aircraft.id] = aircraft;
    return acc;
  }, {})
);

export const getYForLabel = (
  acc: OperatingCompanyLabelData[],
  rowHeight: number
): number => acc[acc.length - 1]?.y + acc[acc.length - 1]?.quantity * rowHeight;

export const getOperatingCompaniesDividersData = createSelector<
  RootState,
  Aircraft[],
  number[],
  ReducerShape['rowHeight'],
  boolean,
  AircraftState['visibleAircraftTypeByOperatingCompanyMap'],
  OperatingCompanyLabelData[]
>(
  getSortedVisibleAircraft,
  getBaseCompaniesIds,
  state => state.ui.rowHeight,
  state => state.aircraft.showSubCharters,
  state => state.aircraft.visibleAircraftTypeByOperatingCompanyMap,
  (
    aircraft,
    baseCompaniesIds,
    rowHeight,
    showSubCharters,
    visibleAircraftMap
  ) => {
    let quantity = 0;
    let y = 0;
    if (!Object.values(visibleAircraftMap).length && showSubCharters) {
      return [
        {
          aircraftIndex: 0,
          companyName: OperatingCompanyLabel.subCharters,
          quantity: aircraft.length,
          y: 0,
        },
      ];
    }
    if (
      aircraft.length > 0 &&
      aircraft.every(
        ac => ac.operatingCompanyId === aircraft[0].operatingCompanyId
      )
    ) {
      return [
        {
          aircraftIndex: 0,
          companyName: aircraft[0].operatingCompanyName,
          quantity: aircraft.length,
          y: 0,
        },
      ];
    }
    return aircraft.reduce<OperatingCompanyLabelData[]>((acc, ac, index) => {
      if (
        isSubCharter(ac, baseCompaniesIds) &&
        !!acc.find(s => s.companyName === OperatingCompanyLabel.subCharters)
      ) {
        return acc;
      }
      if (aircraft[index - 1]) {
        quantity += 1;
        if (ac.operatingCompanyId !== aircraft[index - 1].operatingCompanyId) {
          y = !acc.length ? 0 : getYForLabel(acc, rowHeight);
          acc.push({
            aircraftIndex: !acc.length ? 0 : index - quantity,
            companyName: aircraft[index - 1].operatingCompanyName,
            quantity: !acc.length ? index : quantity,
            y,
          });
          quantity = 0;
          if (
            aircraft[aircraft.length - 1].operatingCompanyId ===
              ac.operatingCompanyId &&
            !isSubCharter(ac, baseCompaniesIds)
          ) {
            acc.push({
              aircraftIndex: index,
              companyName: ac.operatingCompanyName,
              quantity: aircraft.length - index,
              y: getYForLabel(acc, rowHeight),
            });
          }
        }
        if (isSubCharter(ac, baseCompaniesIds)) {
          y = getYForLabel(acc, rowHeight);
          acc.push({
            aircraftIndex: index,
            companyName: OperatingCompanyLabel.subCharters,
            quantity: aircraft.length - index,
            y,
          });
        }
      }
      return acc;
    }, []);
  }
);

export const getOperatingCompaniesMap = createSelector<
  RootState,
  Aircraft[],
  { [companyId: number]: number[] },
  FilterByOperatingCompany[]
>(
  getAvailableAircraftForTypes,
  state => state.aircraft.visibleAircraftTypeByOperatingCompanyMap,
  (aircraft, visibleAircraftTypeByOperatingCompanyMap) => {
    return chain(aircraft)
      .uniqBy(a => a.operatingCompanyId)
      .map(a => ({
        id: a.operatingCompanyId,
        name: a.operatingCompanyName,
        checked: !!visibleAircraftTypeByOperatingCompanyMap[
          a.operatingCompanyId
        ],
      }))
      .sortBy('name')
      .sortBy(c => sortByCompanyRank(c.id))
      .value();
  }
);

export const getAvailableAircraftTypesByOperatingCompanyMap = createSelector<
  RootState,
  Aircraft[],
  { [companyId: number]: number[] },
  { [companyId: number]: number[] },
  boolean,
  { [companyId: number]: FilterByAircraftType[] }
>(
  getAvailableAircraftForTypes,
  state => state.aircraft.visibleAircraftTypeByOperatingCompanyMap,
  state => state.aircraft.acTypesPositionMap,
  state => state.aircraft.isGroupedByOperator,
  (
    aircraft,
    visibleAircraftTypeByOperatingCompanyMap,
    acTypesPositionMap,
    isGroupedByOperator
  ) => {
    return aircraft.reduce<{ [companyId: number]: FilterByAircraftType[] }>(
      (acc, tail) => {
        const acType: FilterByAircraftType = {
          id: tail.aircraftTypeId,
          code: tail.aircraftTypeCode,
          name: tail.aircraftTypeName,
          rank: tail.aircraftTypeRank,
          checked:
            visibleAircraftTypeByOperatingCompanyMap[
              tail.operatingCompanyId
            ]?.includes(tail.aircraftTypeId) || false,
          tails: [],
        };
        const accByOpCompany = acc[tail.operatingCompanyId] || [];
        return {
          ...acc,
          [tail.operatingCompanyId]: accByOpCompany.find(
            t => t.id === tail.aircraftTypeId
          )
            ? accByOpCompany // removing duplications
            : chain(accByOpCompany.concat(acType))
                .sortBy('code')
                .sortBy('rank')
                .value()
                .sort((a, b) => {
                  if (isGroupedByOperator) {
                    return (
                      acTypesPositionMap[tail.operatingCompanyId]?.indexOf(
                        a.id
                      ) -
                      acTypesPositionMap[tail.operatingCompanyId]?.indexOf(b.id)
                    );
                  }
                }),
        };
      },
      {}
    );
  }
);

export const getAvailableTailsOnExactAcTypeByCompanyIdMap = createSelector<
  RootState,
  Aircraft[],
  { [companyId: number]: { [acTypeId: number]: number[] } }
>(getAvailableAircraftForTypes, aircraft =>
  aircraft.reduce<{ [companyId: number]: { [acTypeId: number]: number[] } }>(
    (acc, ac) => {
      acc = {
        ...acc,
        [ac.operatingCompanyId]: {
          ...acc[ac.operatingCompanyId],
          [ac.aircraftTypeId]: (
            acc[ac.operatingCompanyId]?.[ac.aircraftTypeId] || []
          ).concat(ac.id),
        },
      };
      return acc;
    },
    {}
  )
);

export const getFullMainAcFilterMap = createSelector<
  RootState,
  { [companyId: number]: { [acTypeId: number]: number[] } },
  { [companyId: number]: FilterByAircraftType[] },
  { [aircraftId: number]: Aircraft },
  AircraftState['visibleAcIds'],
  { [companyId: number]: FilterByAircraftType[] }
>(
  getAvailableTailsOnExactAcTypeByCompanyIdMap,
  getAvailableAircraftTypesByOperatingCompanyMap,
  getAllAvailableAircraftByIdMap,
  state => state.aircraft.visibleAcIds,
  (
    availableAircraftMap,
    availableAircraftTypesMap,
    aircraftByIdMap,
    visibleAcIds
  ) => {
    return Object.keys(availableAircraftTypesMap).reduce<{
      [companyId: number]: FilterByAircraftType[];
    }>((acc, key) => {
      acc[+key] = availableAircraftTypesMap[+key].map(
        (t: FilterByAircraftType) => ({
          ...t,
          tails: availableAircraftMap[+key][t.id].map(id => {
            const currentAc: Aircraft = aircraftByIdMap[id];
            return {
              checked: visibleAcIds?.includes(id),
              id,
              name: currentAc.tailNumber,
            };
          }),
        })
      );
      return acc;
    }, {});
  }
);

export const getCachedAircraftFilter = createCachedSelector<
  RootState,
  number,
  { [companyId: number]: FilterByAircraftType[] },
  number,
  FilterByAircraftType[]
>(
  getFullMainAcFilterMap,
  (_state, key) => key,
  (map, key) => map[key]
)((_state, key) => key);

export const getAllAvailableAircraftCount = createSelector<
  RootState,
  Aircraft[],
  number[],
  { [id: number]: boolean },
  number
>(
  getAllOperatingAircraft,
  getBaseCompaniesIds,
  getTouchedByFlightAircraftMap,
  (aircraft, baseCompanyIds, touchedByFlightMap) =>
    aircraft.filter(ac => {
      if (isSubCharter(ac, baseCompanyIds)) {
        return !!touchedByFlightMap[ac.id];
      }
      return !isHoldAircraft(ac);
    }).length
);

export const getAllAircraftCheckBoxState = createSelector<
  RootState,
  number,
  Aircraft[],
  { indeterminate: boolean; isAllSelected: boolean; pickedAcCount: number }
>(
  getAllAvailableAircraftCount,
  getSortedVisibleAircraft,
  (allAvailableAircraftCount, aircraft) => {
    const pickedAcCount = aircraft.length;
    const indeterminate =
      pickedAcCount > 0 && pickedAcCount < allAvailableAircraftCount;
    const isAllSelected =
      pickedAcCount > 0 && pickedAcCount === allAvailableAircraftCount;
    return { indeterminate, isAllSelected, pickedAcCount };
  }
);

export const getAcTypesByIdMap = createSelector<
  RootState,
  Aircraft[],
  { [id: number]: Omit<FilterByAircraftType, 'checked' | 'tails'> }
>(
  state => state.aircraft.aircraft,
  aircraft =>
    aircraft.reduce<{
      [id: number]: Omit<FilterByAircraftType, 'checked' | 'tails'>;
    }>((acc, t) => {
      return {
        ...acc,
        [t.aircraftTypeId]: {
          id: t.aircraftTypeId,
          code: t.aircraftTypeCode,
          name: t.aircraftTypeName,
          rank: t.aircraftTypeRank,
        },
      };
    }, {})
);

export const getAllFoundAircraft = createSelector(
  getAllOperatingAircraft,
  (state: RootState) => state.aircraft.acQuery,
  (visibleACs, query) =>
    query.length > 1
      ? visibleACs.filter((a: Aircraft) =>
          a.tailNumber.toLowerCase().includes(query.toLowerCase())
        )
      : []
);

export const getAircraftIndexMapExcludingHolding = createSelector(
  getSortedVisibleAircraft,
  (aircraft: Aircraft[]) =>
    aircraft.reduce<{ [aircraftId: number]: number }>(
      (acc, aircraft, index) => {
        acc[aircraft.id] = index;
        return acc;
      },
      {}
    )
);

export const getMELsByAircraftId = createCachedSelector(
  (state: RootState, key) => state.timelineEvents.maintenanceItems,
  (state: RootState, key) => key,
  (maintenanceItems, aircraftId) =>
    chain(maintenanceItems)
      .filter(
        (mel: MaintenanceItem) =>
          mel.isActive &&
          mel.clearedTimestamp === null &&
          mel.aircraftId === aircraftId
      )
      .sortBy(mel => mel.dueDate)
      .value()
)((state, key) => key);

const unavailableForAdvertisementList = createSelector<
  RootState,
  Aircraft[],
  number,
  number[],
  Aircraft[]
>(
  state => state.aircraft.aircraft,
  state =>
    utc(state.time.now)
      .startOf('d')
      .valueOf(),
  getBaseCompaniesIds,
  (aircraft, startOfToday, baseCompaniesIds) => {
    return aircraft
      .filter(
        (a: Aircraft) =>
          !isSubCharter(a, baseCompaniesIds) && a.availabilityStartDate !== null
      )
      .filter(({ availabilityEndDate }) => {
        return (
          availabilityEndDate === null ||
          (availabilityEndDate && startOfToday < availabilityEndDate)
        );
      });
  }
);

export const getUnavailableForAdvertisementMapLabels = createSelector<
  RootState,
  Aircraft[],
  { [aircraftId: number]: boolean }
>(unavailableForAdvertisementList, aircraft => {
  if (!aircraft?.length) {
    return {};
  }
  return aircraft.reduce<{ [aircraftId: number]: boolean }>((acc, a) => {
    acc[a.id] = true;
    return acc;
  }, {});
});

export const getOperatingAircraftForDropdown = createSelector<
  RootState,
  number,
  number,
  Aircraft[],
  number[],
  Aircraft[]
>(
  (state: RootState) => state.eventElementCreationEdit.start,
  (state: RootState) => state.eventElementCreationEdit.end,
  (state: RootState) => state.aircraft.aircraft,
  getBaseCompaniesIds,
  (start, end, aircraft, baseCompaniesIds: number[]) =>
    filterSortAircraftForAllOptions(start, end, aircraft, baseCompaniesIds)
);

// Hold Aircraft --->>

export const getOperatingHoldAircraft = createSelector<
  RootState,
  Aircraft[],
  Aircraft[]
>(getAllOperatingAircraft, aircraft => {
  return aircraft.filter(ac => ac.aircraftTypeId === HOLD_AC_TYPE_ID);
});

export const getVisibleHoldAircraft = createSelector<
  RootState,
  Aircraft[],
  number[],
  Aircraft[]
>(
  getOperatingHoldAircraft,
  state => state.ui.visibleHoldAcIds,
  (aircraft, visibleHoldTailIds) => {
    return visibleHoldTailIds?.length > 0
      ? sortBy(
          (aircraft || []).filter(
            ac =>
              ac.aircraftTypeId === HOLD_AC_TYPE_ID &&
              visibleHoldTailIds.includes(ac.id)
          ),
          'tailNumber'
        )
      : [];
  }
);

export const getVisibleAircraftFleetWithHoldLines = createSelector(
  getSortedVisibleAircraft,
  getVisibleHoldAircraft,
  (realFleet, holdFleet) => [...holdFleet, ...realFleet]
);

export const getAircraftByIdMapWithHold = createSelector<
  RootState,
  Aircraft[],
  Aircraft[],
  { [aircraftId: string]: Aircraft }
>(
  getSortedVisibleAircraft,
  getVisibleHoldAircraft,
  (
    aircraft: Aircraft[],
    hold: Aircraft[]
  ): { [aircraftId: string]: Aircraft } =>
    [...hold, ...aircraft].reduce((acc, aircraft) => {
      acc[aircraft.id] = aircraft;
      return acc;
    }, {})
);

export const getAircraftIndexMapWithHolding = createSelector<
  RootState,
  Aircraft[],
  Aircraft[],
  { [aircraftId: string]: number }
>(getSortedVisibleAircraft, getVisibleHoldAircraft, (fleet, hold) =>
  [...hold, ...fleet].reduce<{ [aircraftId: string]: number }>(
    (acc, aircraft, index) => {
      acc[aircraft.id] = index;
      return acc;
    },
    {}
  )
);

export const getHoldAircraftFilterOptions = createSelector<
  RootState,
  Aircraft[],
  { id: number; tailNumber: string }[]
>(getOperatingHoldAircraft, aircraft => {
  return aircraft
    .map((a: Aircraft) => ({
      tailNumber: a.tailNumber,
      id: a.id,
    }))
    .sort((a, b) => {
      if (a.tailNumber > b.tailNumber) return 1;
      if (a.tailNumber < b.tailNumber) return -1;
      return 0;
    });
});

// Hold Flights -->>

export const getVisibleHoldFlightsBySegment = createSelector<
  RootState,
  Flight[],
  Aircraft[],
  ReducerShape['segmentsVisibility'],
  Pool<Flight>
>(
  state => elementsSelector(state, 'flights'),
  getVisibleHoldAircraft,
  state => state.ui.segmentsVisibility,
  (filteredElements, holdAc, segmentsVisibility) => {
    if (!!segmentsVisibility['flights']) {
      const holdAcIds = new Set();
      (holdAc || []).forEach(({ id }) => holdAcIds.add(id));
      const filtered = filteredElements.reduce<Flight[]>((acc, fl) => {
        if (!holdAcIds.has(fl.aircraftId)) return acc;
        return acc.concat({
          ...fl,
          start: fl.departureUtcEstimated || fl.departureUtcScheduled,
          end: fl.arrivalUtcEstimated || fl.arrivalUtcScheduled,
        });
      }, []);
      return createPoolWithOutOvernightDuplication(filtered);
    }
    return [];
  }
);

export const getFlightsForHoldTails = createSelector<
  RootState,
  Pool<Flight>,
  HoldFlightsPoolByAircraftIdMap
>(getVisibleHoldFlightsBySegment, holdFlights => {
  let holdFlightsPoolByAircraftIdMap = {};
  (holdFlights || []).forEach(x => {
    x.pool.forEach(j => {
      holdFlightsPoolByAircraftIdMap = {
        ...holdFlightsPoolByAircraftIdMap,
        [j.aircraftId]: uniqBy(
          [
            ...(holdFlightsPoolByAircraftIdMap[j.aircraftId] || []),
            {
              day: x.day,
              pool: x.pool
                .filter(v => v.aircraftId === j.aircraftId)
                .sort(sortFlightsByStart),
            },
          ],
          'day'
        ),
      };
    });
  });
  return holdFlightsPoolByAircraftIdMap;
});

export const getHoldFlightsPoolDividedByTimeRange = createSelector<
  RootState,
  HoldFlightsPoolByAircraftIdMap,
  HoldFlightsPoolHoursDelimiter
>(getFlightsForHoldTails, holdFlights => {
  return Object.keys(holdFlights).reduce((acc, aircraftId) => {
    const flightsPool = holdFlights[aircraftId];
    acc = {
      ...acc,
      [aircraftId]: flightsPool.map(g => ({
        day: g.day,
        pool: collectPoolForTimeFlight(g),
      })),
    };
    return acc;
  }, {});
});

// Search by aircraft --->>>

export const getFoundAircraft = createSelector<
  RootState,
  Aircraft[],
  Aircraft[],
  string,
  Aircraft[]
>(
  getSortedVisibleAircraft,
  getVisibleHoldAircraft,
  state => state.aircraft.acQuery,
  (visibleACs, holdAC, query) => {
    const result =
      query.length > 1
        ? [...holdAC, ...visibleACs].filter(a =>
            a.tailNumber.toLowerCase().includes(query.toLowerCase())
          )
        : [];
    return result;
  }
);

export const isValidTailSearch = createCachedSelector(
  (state: RootState, key) => state.ui.isScrollingVertically,
  (state: RootState, key) => state.ui.isScrollingHorizontally,
  (state: RootState, key) => state.aircraft.acQuery,
  getFoundAircraft,
  (state: RootState, key) => key === 'jumpToTail',
  (
    isScrollingVertically,
    isScrollingHorizontally,
    acQuery,
    foundAircraft,
    isJumpToTail
  ) =>
    !isScrollingVertically &&
    !isScrollingHorizontally &&
    acQuery.length > 2 &&
    (isJumpToTail
      ? foundAircraft.length
      : foundAircraft.length && foundAircraft.length < 10)
)((state, key) => key);

export const jumpToFirstTailBySearch = createSelector(
  (state: RootState) => isValidTailSearch(state, 'jumpToTail'),
  getFoundAircraft,
  getAircraftIndexMapWithHolding,
  (isValidTailSearch, foundAircraft, aircraftIndex) =>
    isValidTailSearch ? aircraftIndex[foundAircraft[0].id] : null
);

export const getFoundAircraftBySearch = createSelector(
  (state: RootState) => isValidTailSearch(state, ''),
  getFoundAircraft,
  (isValidSearch, foundAircraft) => (isValidSearch ? foundAircraft : [])
);

// Search by element or flight --->>>

export type FlightWithExtendedAirport = Flight & {
  departureCountry: string;
  arrivalCountry: string;
  departureIcao: string;
  arrivalIcao: string;
  departureAirportName: string;
  arrivalAirportName: string;
  countryPermits: string[];
};

export const getFlightsWithAirportInfo = <T extends FlightBase>(
  flights: T[],
  airports: {
    [id: number]: Airport;
  }
) =>
  flights.map(f => {
    const depAirport = airports[f.departureAirportId];
    const arrAirport = airports[f.arrivalAirportId];
    const countryPermits = f.permits.reduce((acc, el) => {
      if (
        el.permissionSlotTypeId === PermissionSlotType.OVERFLIGHT_PERMISSION &&
        el.place?.country
      ) {
        acc.push(el.place.country);
      }
      return acc;
    }, []);
    if (!depAirport || !arrAirport)
      return {
        ...f,
        departureCountry: null,
        arrivalCountry: null,
        departureIcao: null,
        arrivalIcao: null,
        departureAirportName: null,
        arrivalAirportName: null,
        countryPermits: [],
      };
    return {
      ...f,
      departureCountry: depAirport.place.country,
      arrivalCountry: arrAirport.place.country,
      departureIcao: depAirport.ICAO,
      arrivalIcao: arrAirport.ICAO,
      departureAirportName: depAirport.name,
      arrivalAirportName: arrAirport.name,
      countryPermits,
    };
  });

const getFlightSearchKeysBySearchType = (
  by: SearchType
): (keyof FlightWithExtendedAirport)[] => {
  switch (by) {
    case 'Leg ID':
      return ['id'];
    case 'Customer':
      return ['customerName'];
    case 'Passenger':
      return ['passengerNames'];
    case 'Airport':
      return [
        'departureIcao',
        'arrivalIcao',
        'departureAirportName',
        'arrivalAirportName',
      ];
    case 'Country':
      return ['departureCountry', 'arrivalCountry'];
    case 'Crew Member':
      //@ts-ignore
      return ['team.crewMemberCode'];
    case 'Overflight country':
      return ['countryPermits'];
    default:
      return ['id'];
  }
};

export const getFoundCrewMemberElements = createSelector<
  RootState,
  CrewAssignment[],
  CrewRoster[],
  boolean,
  boolean,
  { [aircraftId: string]: number },
  { [aircraftId: number]: AircraftTogglerState },
  CrewRoster[]
>(
  (state: RootState) => getElementsInVisibleTimeRange(state, 'crewAssignment'),
  (state: RootState) => getElementsInVisibleTimeRange(state, 'crewRoster'),
  (state: RootState) => state.ui.segmentsVisibility.crewAssignment,
  (state: RootState) => state.ui.segmentsVisibility.crewRoster,
  getAircraftIndexMapWithHolding,
  (state: RootState) => state.aircraft.togglersState,
  (
    crewAssignment,
    crewRoster,
    crewAssignmentElementEnabled,
    crewRosterElementEnabled,
    indexMap,
    togglersState
  ) => {
    let visibleCrewAssignment = crewAssignment.filter(el =>
      isCrewElementVisible(indexMap, el.aircraftId)
    );
    if (!crewAssignmentElementEnabled) {
      visibleCrewAssignment = visibleCrewAssignment.filter(
        el => (togglersState[el.aircraftId] || {}).crew
      );
    }

    let visibleCrewRoster = crewRoster.filter(el =>
      isCrewElementVisible(indexMap, el.aircraftId)
    );
    if (!crewRosterElementEnabled) {
      visibleCrewRoster = visibleCrewRoster.filter(
        el => (togglersState[el.aircraftId] || {}).crew
      );
    }

    const foundCrewRosters = visibleCrewRoster.filter(
      r =>
        !visibleCrewAssignment.some(
          ca =>
            ca.aircraftId === r.aircraftId && isInTimeRange(ca, r.start, r.end)
        )
    );
    return [...visibleCrewAssignment, ...foundCrewRosters];
  }
);

export const unfilteredFlightFinder = createSelector(
  (state: RootState) => getElementsInVisibleTimeRange(state, 'flights'),
  (state: RootState) => state.airports.airportsById,
  (state: RootState) => state.search.searchQuery,
  (state: RootState) => state.search.searchType,
  (state: RootState) => state.search.searchedResult,
  (state: RootState) => state.search.searchQueryAllFilters,
  (state: RootState) => state.ui.isShowMultipleSearchBar,
  getFoundCrewMemberElements,
  (
    flights,
    airports,
    query: string,
    by: SearchType,
    searchedResult,
    searchQueryAllFilters,
    isShowMultipleSearchBar,
    crewMember
  ) => {
    const queryAllFilters = searchQueryAllFilters[by];
    if (
      isShowMultipleSearchBar &&
      by !== 'Order ID' &&
      by !== 'Leg ID' &&
      queryAllFilters
    ) {
      const flightsWithAirportsForAllFilters = getFlightsWithAirportInfo(
        flights,
        airports
      );
      const paramsSearchQuery = Object.entries(searchQueryAllFilters).reduce(
        (acc, field) => {
          const [key, value] = field;
          if (value) {
            acc.push({
              key,
              value,
            });
          }
          return acc;
        },
        []
      );

      const flightsWithCrewMember = chain(flightsWithAirportsForAllFilters)
        .concat(crewMember)
        .groupBy('id')
        .map(spread(merge))
        .value();

      return paramsSearchQuery.reduce((acc, field) => {
        const paramsFieldNames: (keyof FlightWithExtendedAirport)[] = getFlightSearchKeysBySearchType(
          field.key
        );
        const fuse = new Fuse(acc, {
          keys: paramsFieldNames,
          tokenize: true,
          matchAllTokens: true,
          threshold: 0.15,
        });
        return sortBy(fuse.search(field.value.trim()), ['index', 'start']);
      }, flightsWithCrewMember);
    }
    if (!query) return [];
    if (by === 'Order ID' || by === 'Leg ID') {
      return chain(searchedResult.flightArr)
        .sortBy(['start'])
        .value();
    }
    const flightsWithAirports = getFlightsWithAirportInfo(flights, airports);
    const fieldNames: (keyof FlightWithExtendedAirport)[] = getFlightSearchKeysBySearchType(
      by
    );
    const fuse = new Fuse(flightsWithAirports, {
      keys: fieldNames,
      tokenize: true,
      matchAllTokens: true,
      threshold: 0.15,
    });
    return sortBy(fuse.search(query.trim()), ['index', 'start']);
  }
);

export const isFilteredSegmentType = (
  st: SegmentType,
  segmentVisibility: { [st in SegmentType]: boolean }
): boolean => !segmentVisibility[st];

export const flightFinder = createSelector(
  unfilteredFlightFinder,
  getSelectedStatusesIds,
  getAircraftIndexMapWithHolding,
  (state: RootState) => state.ui.segmentsVisibility,
  (
    flights,
    visibleOperationalStatuses,
    aircraftIndexMap,
    segmentsVisibility
  ) => {
    if (!segmentsVisibility.flights) {
      return [];
    }
    return flights.filter(f =>
      isFlightVisible({
        f,
        visibleOperationalStatuses,
        aircraftIndexMap,
      })
    );
  }
);

export const unfilteredCrewAssignmentFinder = createSelector<
  RootState,
  CrewAssignment[],
  string,
  SearchType,
  SearchQueryAllFilters,
  CrewRoster[]
>(
  (state: RootState) => getElementsInVisibleTimeRange(state, 'crewAssignment'),
  (state: RootState) => state.search.searchQuery,
  (state: RootState) => state.search.searchType,
  (state: RootState) => state.search.searchQueryAllFilters,
  (elements, query, searchType, searchQueryAllFilters) => {
    const queryAllFilters = searchQueryAllFilters[searchType];
    return getCrewRostersForSearch(elements, queryAllFilters || query);
  }
);

export const crewAssignmentFinder = createSelector(
  unfilteredCrewAssignmentFinder,
  (state: RootState) => state.ui.segmentsVisibility.crewAssignment,
  getAircraftIndexMapWithHolding,
  (state: RootState) => state.aircraft.togglersState,
  (
    elements: CrewAssignment[],
    crewAssignmentElementEnabled,
    indexMap,
    togglersState
  ) => {
    const visibleElements = elements.filter(el =>
      isCrewElementVisible(indexMap, el.aircraftId)
    );
    if (!crewAssignmentElementEnabled) {
      return visibleElements.filter(
        el => (togglersState[el.aircraftId] || {}).crew
      );
    }
    return visibleElements;
  }
);

export const unfilteredCrewRosterFinder = createSelector<
  RootState,
  CrewRoster[],
  string,
  SearchType,
  SearchQueryAllFilters,
  CrewRoster[]
>(
  (state: RootState) => getElementsInVisibleTimeRange(state, 'crewRoster'),
  (state: RootState) => state.search.searchQuery,
  (state: RootState) => state.search.searchType,
  (state: RootState) => state.search.searchQueryAllFilters,
  (elements, query, searchType, searchQueryAllFilters) => {
    const queryAllFilters = searchQueryAllFilters[searchType];
    return getCrewRostersForSearch(elements, queryAllFilters || query);
  }
);

export const crewRosterFinder = createSelector(
  unfilteredCrewRosterFinder,
  (state: RootState) => state.ui.segmentsVisibility.crewRoster,
  getAircraftIndexMapWithHolding,
  (state: RootState) => state.aircraft.togglersState,
  (
    elements: CrewRoster[],
    crewRosterElementEnabled,
    indexMap,
    togglersState
  ) => {
    const visibleElements = elements.filter(el =>
      isCrewElementVisible(indexMap, el.aircraftId)
    );
    if (!crewRosterElementEnabled) {
      return visibleElements.filter(
        el => (togglersState[el.aircraftId] || {}).crew
      );
    }
    return visibleElements;
  }
);

export const isInTimeRange = (
  element: EventElement,
  start: number,
  end: number
): boolean => {
  return (
    (element.start <= start && element.end >= start) ||
    (element.start <= end && element.end >= end) ||
    (element.start >= start && element.end <= end)
  );
};

export const getFoundFlights = createSelector(
  (state: RootState) => state.search.searchQuery,
  (state: RootState) => state.search.searchType,
  (state: RootState) => state.search.searchQueryAllFilters,
  flightFinder,
  (query, searchType, searchQueryAllFilters, flights) => {
    const multipleFiltersQuery = searchQueryAllFilters[searchType];
    if (query === '' && multipleFiltersQuery === '') return [];
    return flights;
  }
);

export const getCrewRosterFromFlights = <T extends CrewAssignment>(
  flights: T[]
) =>
  flights.map(el => {
    return {
      ...el,
      aircraftId: el.aircraftId,
      end: el.end,
      flightLegId: el.flightLegId,
      id: el.id,
      start: el.start,
      team: el.team,
    };
  });

export const getFoundTimelineElements = createSelector<
  RootState,
  SearchType,
  Flight[] | CrewAssignment[],
  CrewAssignment[],
  CrewRoster[],
  boolean,
  CrewAssignment[] | CrewRoster[] | Flight[]
>(
  (state: RootState) => state.search.searchType,
  getFoundFlights,
  crewAssignmentFinder,
  crewRosterFinder,
  (state: RootState) => state.ui.isShowMultipleSearchBar,
  (
    searchType,
    flights,
    crewAssignments,
    crewRosters,
    isShowMultipleSearchBar
  ) => {
    if (
      isShowMultipleSearchBar &&
      searchType !== 'Order ID' &&
      searchType !== 'Leg ID'
    ) {
      return searchType === 'Crew Member'
        ? getCrewRosterFromFlights(flights as CrewAssignment[])
        : flights;
    }
    if (isFlightSearchType(searchType)) {
      return flights;
    }
    if (searchType === 'Crew Member') {
      const foundCrewRosters = crewRosters.filter(
        r =>
          !crewAssignments.some(
            ca =>
              ca.aircraftId === r.aircraftId &&
              isInTimeRange(ca, r.start, r.end)
          )
      );
      return [...crewAssignments, ...foundCrewRosters];
    }
  }
);

export const getUnfilteredFoundTimelineElements = (state: RootState) => {
  const { searchQueryAllFilters, searchType, searchQuery } = state.search;
  const { isShowMultipleSearchBar } = state.ui;
  if (searchQuery === '' && !searchQueryAllFilters[searchType]) return [];
  if (
    isShowMultipleSearchBar &&
    searchType !== 'Order ID' &&
    searchType !== 'Leg ID'
  ) {
    return unfilteredFlightFinder(state);
  }
  if (isFlightSearchType(searchType)) {
    return unfilteredFlightFinder(state);
  }
  if (searchType === 'Crew Member') {
    const foundCrewAssignment = unfilteredCrewAssignmentFinder(state);
    return foundCrewAssignment.length > 0
      ? foundCrewAssignment
      : unfilteredCrewRosterFinder(state);
  }
  return [];
};

export const getFoundElements = createSelector(
  getFoundTimelineElements,
  (state: RootState) => state.ui.transform,
  (state: RootState) => state.search.searchType,
  (state: RootState) => state.ui.width,
  (state: RootState) => state.ui.planeBlockWidth,
  (elements, transform, searchType, width, planeBlockWidth) => {
    if (searchType == 'Order ID' || searchType == 'Leg ID') return elements;
    return (elements as EventElement[]).filter(
      el =>
        transform.scaleX(el.end) >= 0 &&
        transform.scaleX(el.start) <= width - planeBlockWidth
    );
  }
);

export const getUnfilteredFoundElements = createSelector(
  getUnfilteredFoundTimelineElements,
  (state: RootState) => state.ui.transform,
  (state: RootState) => state.ui.width,
  (state: RootState) => state.ui.planeBlockWidth,
  (elements, transform, width, planeBlockWidth) => {
    return (<EventElement[]>elements).filter(
      el =>
        transform.scaleX(el.end) >= 0 &&
        transform.scaleX(el.start) <= width - planeBlockWidth
    );
  }
);

export const getFilteredByAircraft = (
  elements: EventElement[],
  indexMap: { [id: string]: number }
): EventElement[] => elements.filter(el => indexMap[el.aircraftId] >= 0);

export function isFlightSearchType(st: SearchType): boolean {
  switch (st) {
    case 'Airport':
    case 'Country':
    case 'Customer':
    case 'Leg ID':
    case 'Order ID':
    case 'Passenger':
    case 'Overflight country':
      return true;
    default:
      return false;
  }
}

export const getAssociatedFlight = createSelector(
  getFoundElements,
  getAircraftIndexMapWithHolding,
  (state: RootState) => getVisibleElements(state, 'flights'),
  (state: RootState) => state.ui.segmentsVisibility,
  (foundElements, indexMap, visibleFlights: Flight[]) => {
    const elements = getFilteredByAircraft(
      foundElements,
      indexMap
    ) as CrewAssignment[];
    return visibleFlights.filter(fl =>
      elements.some(el => el.flightLegId === fl.id)
    );
  }
);
export const getFlightsRects = createSelector(
  getFoundElements,
  getAircraftIndexMapWithHolding,
  getAircraftByIdMapWithHold,
  getHoldFlightsPoolDividedByTimeRange,
  getFlightsForHoldTails,
  (state: RootState) => state.ui,
  (state: RootState) => state.aircraft.togglersState,
  (
    flights: Flight[],
    indexMap,
    aircraftById,
    holdFlightsDelimiter,
    holdFlightsPool,
    ui,
    togglersState
  ) => {
    return flights.map(f => {
      const segmentType = 'flights';
      return mapFocusWindowRectsWithProps(f, {
        ui,
        indexMap,
        segmentType,
        togglersState,
        aircraftById,
        holdFlightsDelimiter,
        holdFlightsPool,
      });
    });
  }
);

export const getElementsRects = createSelector(
  getFoundElements,
  getAircraftIndexMapWithHolding,
  getAircraftByIdMapWithHold,
  getHoldFlightsPoolDividedByTimeRange,
  getFlightsForHoldTails,
  (state: RootState) => state.ui,
  (state: RootState) => state.aircraft.togglersState,
  (
    foundElements: CrewAssignment[] | CrewRoster[] | Flight[],
    indexMap,
    aircraftById,
    holdFlightsDelimiter,
    holdFlightsPool,
    ui,
    togglersState
  ) => {
    if (!foundElements.length) return [];
    const segmentType = getSegmentType(foundElements[0]);

    return (foundElements as EventElement[])
      .filter(el => indexMap[el.aircraftId] >= 0)
      .map(el =>
        mapFocusWindowRectsWithProps(el, {
          ui,
          indexMap,
          segmentType,
          togglersState,
          aircraftById,
          holdFlightsDelimiter,
          holdFlightsPool,
        })
      );
  }
);
export const getAssociatedFlightRects = createSelector(
  getAssociatedFlight,
  getAircraftIndexMapWithHolding,
  getAircraftByIdMapWithHold,
  getHoldFlightsPoolDividedByTimeRange,
  getFlightsForHoldTails,
  (state: RootState) => state.ui,
  (state: RootState) => state.aircraft.togglersState,
  (
    associatedFlights,
    indexMap,
    aircraftById,
    holdFlightsDelimiter,
    holdFlightsPool,
    ui,
    togglersState
  ) => {
    return associatedFlights.map(el =>
      mapFocusWindowRectsWithProps(el, {
        indexMap,
        segmentType: 'flights',
        togglersState,
        aircraftById,
        ui,
        holdFlightsDelimiter,
        holdFlightsPool,
      })
    );
  }
);
export function getSegmentType(
  element: Flight | CrewAssignment | CrewRoster
): SegmentType {
  if (isFlight(element)) return 'flights';
  if (isCrew(element))
    return isCrewAssignment(element) ? 'crewAssignment' : 'crewRoster';
}

export const getAircraftRowIndex = (aircraftMap, aircraftId: number) =>
  aircraftMap[aircraftId];

const isSegmentTypeVisible = (
  segmentVisibility: { [s in SegmentType]: boolean },
  segmentType: SegmentType,
  aircraftTogglersState: AircraftTogglerState
) => {
  if (segmentType == 'crewAssignment' || segmentType == 'crewRoster') {
    return aircraftTogglersState.crew === undefined
      ? segmentVisibility[segmentType]
      : aircraftTogglersState.crew;
  }
  if (segmentType == 'generalNotes')
    return aircraftTogglersState.notes === undefined
      ? segmentVisibility[segmentType]
      : aircraftTogglersState.notes;
  if (segmentVisibility[segmentType]) return segmentVisibility[segmentType];
  return false;
};

export const getVisibleElements = createCachedSelector(
  elementsSelector,
  getAircraftIndexMapExcludingHolding,
  (state: RootState) => state.aircraft.togglersState,
  (state: RootState) => state.ui.segmentsVisibility,
  (state: RootState) => state.ui.transform.translateY,
  (state: RootState) => state.ui.transform.ky,
  (state: RootState) => state.ui.rowHeight,
  (state: RootState) => state.ui.marginTop,
  (state: RootState) => state.ui.height,
  (state, type) => type,
  (state: RootState) => state.handoverRemarks.allRemarks,
  (state: RootState) => state.time.now,
  (
    filteredElements = [],
    aircraftIndexMap,
    togglersState,
    segmentsVisibility,
    translateY,
    ky,
    rowHeight,
    marginTop,
    height,
    segmentType,
    allRemarks,
    nowTime
  ) => {
    const firstIndex = Math.ceil(-translateY / rowHeight / ky);
    const oneMoreIndex = Math.max(firstIndex - 1, 0);
    const count = Math.ceil((height + marginTop) / ky / rowHeight);
    const lastIndex = firstIndex + count;
    const opsFlightsRemarksSet = new Set(
      getFlightLegIds(filterByTeam(allRemarks, HandoverType.ops))
    );
    const csFlightsRemarksSet = new Set(
      getFlightLegIds(filterByTeam(allRemarks, HandoverType.cs))
    );
    const elements = filteredElements.filter(
      el =>
        aircraftIndexMap[el.aircraftId] >= oneMoreIndex &&
        aircraftIndexMap[el.aircraftId] <= lastIndex &&
        isSegmentTypeVisible(
          segmentsVisibility,
          segmentType,
          togglersState[el.aircraftId] || {}
        )
    );
    if (segmentType === 'flights') {
      return elements.map(fl => {
        return {
          ...fl,
          slaBorder: getLeadTimeBorderStyle(fl, nowTime),
          hasOpsRemarks:
            segmentsVisibility.handoverRemarks &&
            (opsFlightsRemarksSet.has(fl.id) ||
              opsFlightsRemarksSet.has(fl.originalFlightLegId)),
          hasCsRemarks:
            segmentsVisibility.handoverRemarks_cs &&
            (csFlightsRemarksSet.has(fl.id) ||
              csFlightsRemarksSet.has(fl.originalFlightLegId)),
        };
      });
    }
    return elements;
  }
)((state, type) => type);

export const getVisibleAvailabilityNotes = createSelector(
  (state: RootState) =>
    getVisibleElements(state, 'availabilityNotes') as AvailabilityNote[],
  (state: RootState) => state.ui.segmentsVisibility.crewRestNotes,
  (allNotes, isCrewRestNotesVisible) => {
    if (!isCrewRestNotesVisible) {
      return allNotes.filter(n => n.inputSourceId !== InputSourceIdType.SQL);
    }
    return allNotes;
  }
);

export const getOverlappedElementsSelector = createCachedSelector(
  getVisibleElements,
  (_state, type) => type,
  flightFinder,
  (elements, type, foundFlights) => {
    if (type === 'flights')
      return sortBy(
        getOverlappedFlights(
          foundFlights.length == 0 ? elements : foundFlights
        ),
        (overlapedElements: OverlappedElements) =>
          overlapedElements.elements.length
      );
    return sortBy(
      getOverlappedElements(elements),
      (overlapedElements: OverlappedElements) =>
        overlapedElements.elements.length
    );
  }
)((_state, key) => key);

export const getRects = (state: RootState) => {
  const { searchType, searchQueryAllFilters, searchQuery } = state.search;
  const { isShowMultipleSearchBar } = state.ui;
  if (searchQuery === '' && !searchQueryAllFilters[searchType]) return [];
  if (
    isShowMultipleSearchBar &&
    searchType !== 'Order ID' &&
    searchType !== 'Leg ID'
  ) {
    return uniqWith(
      [
        ...getFlightsRects(state),
        ...getElementsRects(state),
        ...getAssociatedFlightRects(state),
      ],
      isEqual
    );
  }
  if (isFlightSearchType(searchType)) return getFlightsRects(state);
  else if (searchType === 'Crew Member') {
    return [...getElementsRects(state), ...getAssociatedFlightRects(state)];
  } else {
    return getElementsRects(state);
  }
};

export const getTailsClippedRect = createSelector<
  RootState,
  Aircraft[],
  { [aircraftId: string]: number },
  number,
  number,
  number,
  number,
  number,
  number,
  { [id: number]: { y1: number; y2: number } },
  { x1: number; x2: number; y1: number; y2: number }[]
>(
  getFoundAircraftBySearch,
  getAircraftIndexMapWithHolding,
  state => state.ui.width,
  state => state.ui.rowHeight,
  state => state.ui.marginTop,
  state => state.ui.transform.ky,
  state => state.ui.transform.translateY,
  state => state.ui.holdLineHeight,
  state => state.ui.holdAircraftPositionMap,
  (
    foundElements,
    indexMap,
    x2,
    rowHeight,
    marginTop,
    ky,
    translateY,
    holdLineHeight,
    holdAircraftPositionMap
  ) => {
    const x1 = 0;
    return foundElements
      .filter(el => indexMap[el.id] >= 0)
      .map(el => {
        if (isHoldAircraft(el)) {
          const { y1, y2 } = holdAircraftPositionMap[el.id];
          return {
            x1,
            x2,
            y1: y1 + marginTop,
            y2: y2 + marginTop,
          };
        }
        const relativeOffset = translateY + marginTop;
        const topOffset =
          holdLineHeight > 0 ? holdLineHeight + relativeOffset : relativeOffset;
        const holdAircraftCount = Object.keys(holdAircraftPositionMap).length;
        const y1 =
          topOffset + (indexMap[el.id] - holdAircraftCount) * rowHeight * ky;
        return {
          x1,
          x2,
          y1,
          y2: y1 + rowHeight * ky,
        };
      });
  }
);

export const getAutoCompleteOptions = createSelector<
  RootState,
  string,
  SearchType,
  EventElement[],
  string[]
>(
  state => state.search.searchQuery,
  state => state.search.searchType,
  state => getFoundElements(state),
  (query, searchType, foundFlights) => {
    return getOptionsForAutocomplete(foundFlights, query, searchType);
  }
);

export const getMultipleSearchAutoCompleteOptions = createCachedSelector<
  RootState,
  SearchType,
  SearchQueryAllFilters,
  EventElement[],
  SearchType,
  string[]
>(
  state => state.search.searchQueryAllFilters,
  state => getFoundElements(state),
  (_state, searchType) => searchType,
  (searchQueryAllFilters, foundFlights, searchType) => {
    const queryAllFilters = searchQueryAllFilters[searchType];
    return getOptionsForAutocomplete(foundFlights, queryAllFilters, searchType);
  }
)((_state, searchType) => searchType);

export const checkIfHoldFlightIsHiddenBySearch = createCachedSelector(
  getFoundElements,
  getFoundFlights,
  getAssociatedFlight,
  (state: RootState) => state.search.searchType,
  (_state, id) => id,
  (
    foundElements,
    flightSearchResult,
    associatedFlightsResult,
    searchType,
    id
  ) => {
    if (foundElements?.length > 0 && id) {
      return isFlightSearchType(searchType)
        ? !flightSearchResult.map(fl => fl.id).includes(id)
        : !associatedFlightsResult.map(fl => fl.id).includes(id);
    }
    return false;
  }
)((_state, id) => id);
