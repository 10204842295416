import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as actions from '../actions';
import { utc } from 'moment';

export interface TimeState {
  now: number;
  today: number;
}
export const initialState: TimeState = {
  now: utc().valueOf(),
  today: utc().valueOf(),
};

export default reducerWithInitialState(initialState).case(
  actions.refreshTimer,
  (state, payload) => ({
    ...state,
    now: payload.now,
    today: payload.today,
  })
);
