import { RootState } from '../../reducers';
import { connect } from 'react-redux';

import { createDelayedForScrollConnect } from '../../utils/create-delayed-for-scroll-connect';
import { ChequeredPattern } from './chequered-pattern';

const flightThirdLineColor = [
  '#696969',
  '#999999',
  '#808080',
  '#10bbe8',
  '#ffa641',
  '#666666',
  'black',
  'darkgrey',
  'white',
  '#383838',
  '#8b0000',
  '#ff6666',
];
interface StateProps {
  kx: number;
  ky: number;
}

const FlightPatternForHandoverRemarks = (props: StateProps) => (
  <>
    {flightThirdLineColor.map(color => (
      <ChequeredPattern
        kx={props.kx}
        ky={props.ky}
        patternIdName={`handoverRemarksOn${color}`}
        color={color === 'white' ? '#ffffcc' : color}
        key={color}
      />
    ))}
  </>
);

const mapStateToProps = (state: RootState): StateProps => ({
  kx: state.ui.transform.kx,
  ky: state.ui.transform.ky,
});
const advancedMap = createDelayedForScrollConnect(mapStateToProps);

export const FlightPatternForHandoverRemarksConnected = connect(advancedMap)(
  FlightPatternForHandoverRemarks
);
