import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as actions from '../actions';
import BaseCompany from '../types/base-company';

export interface BaseCompaniesState {
  baseCompaniesData: BaseCompany[];
}
export const initialState: BaseCompaniesState = {
  baseCompaniesData: [],
};

export default reducerWithInitialState(initialState).case(
  actions.doGetBaseCompanies.done,
  (state, payload) => {
    return {
      ...state,
      baseCompaniesData: payload.result,
    };
  }
);
