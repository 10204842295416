import { gql } from '@apollo/client';
import { apolloClient } from '../../data-processing/graphql';
import { GetPFABPMQuery } from '../../types/operation-result-types';

const getPFA = require('./get-pfa-bpm.gql');

export function getPFABPM(orderId: number) {
  return apolloClient.query<GetPFABPMQuery>({
    query: gql(getPFA),
    variables: {
      orderId: orderId,
    },
  });
}
