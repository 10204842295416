import * as Color from 'color';
import { FC } from 'react';

interface Props {
  kx: number;
  ky: number;
  color: string;
  patternIdName: string;
}

export const ChequeredPattern: FC<Props> = ({
  color,
  kx,
  ky,
  patternIdName,
}) => (
  <pattern
    id={patternIdName}
    width={10 / kx}
    height={10 / ky}
    patternUnits="userSpaceOnUse"
  >
    <rect
      x={0}
      y={0}
      width={10 / kx}
      height={10 / ky}
      fill={Color(color)
        .alpha(0.5)
        .string()}
    />
    <rect
      x={0}
      y={0}
      width={5 / kx}
      height={5 / ky}
      fill={Color(color).string()}
    />
    <rect
      x={5 / kx}
      y={5 / ky}
      width={5 / kx}
      height={5 / ky}
      fill={Color(color).string()}
    />
  </pattern>
);
