import { FC, useState } from 'react';
import { Popover, Button } from 'antd';
import { AircraftFilter } from '../aircraft/content';
import { getButtonSize } from '../utils';
import { isEqual } from 'lodash';
import { ConfirmationModal } from '../../../common/components/confirmation-modal/confirmation-modal';
import { CurrentAcTypePositionPayload } from '../../../types/aircraft';
import { useDispatch, useSelector } from 'react-redux';
import {
  userToggleEditModeOfAcTypesPosition,
  userResetChangesInAcTypesPositionMap,
  userSavePreferences,
} from '../../../actions';
import { RootState } from '../../../reducers';
import { getUserPreferences } from '../../../selectors/user-preferences';
import { UserPreferences } from '../../../types/user-preferences';

interface StateProps {
  isVerticalMode: boolean;
  acTypesPositionMap: CurrentAcTypePositionPayload;
  userPreferences: UserPreferences;
  isEditModeAcTypes: boolean;
}

export const FilterACTypes: FC<{}> = () => {
  const dispatch = useDispatch();
  const {
    acTypesPositionMap,
    isEditModeAcTypes,
    isVerticalMode,
    userPreferences,
  } = useSelector<RootState, StateProps>(
    state => ({
      acTypesPositionMap: state.aircraft.acTypesPositionMap,
      isEditModeAcTypes: state.ui.isEditModeAcTypes,
      isVerticalMode: state.ui.isVerticalMode,
      userPreferences: getUserPreferences(state),
    }),
    isEqual
  );
  const [isPopoverVisible, setPopoverVisibility] = useState<boolean>(false);
  const [visibleConfirmationPopUp, setConfirmationPopUpVisibility] = useState<
    boolean
  >(false);
  const [initialAcTypesPositionMap, setInitialPosition] = useState<
    CurrentAcTypePositionPayload
  >({});
  const onMouseEnter = () => {
    setPopoverVisibility(true);
    setInitialPosition(acTypesPositionMap);
  };
  const reset = () => {
    setPopoverVisibility(false);
    setInitialPosition({});
    setConfirmationPopUpVisibility(false);
  };
  const handleHoverChange = (isHover: boolean) => {
    if (!isHover) {
      if (
        !isEqual(acTypesPositionMap, initialAcTypesPositionMap) &&
        Object.values(initialAcTypesPositionMap).length &&
        isEditModeAcTypes
      ) {
        setConfirmationPopUpVisibility(true);
      } else {
        dispatch(userToggleEditModeOfAcTypesPosition(false));
        reset();
      }
    }
  };
  const onCloseConfirmationModal = () => {
    setConfirmationPopUpVisibility(false);
  };
  const onCancelSave = () => {
    dispatch(userResetChangesInAcTypesPositionMap(initialAcTypesPositionMap));
    reset();
  };
  const onSave = () => {
    dispatch(userSavePreferences.started(JSON.stringify(userPreferences)));
    reset();
  };
  const handleKeyPress = event => {
    if (event.key === 'Escape') {
      onCancelSave();
    }
  };
  const footer = (
    <div>
      <Button htmlType="reset" key="reset" onClick={onCancelSave}>
        Cancel
      </Button>
      <Button type="primary" key="save" onClick={onSave}>
        Yes
      </Button>
    </div>
  );
  return (
    <>
      <ConfirmationModal
        open={visibleConfirmationPopUp}
        onClose={onCloseConfirmationModal}
        onKeyDown={handleKeyPress}
        confirmationQuestion="AC type position was updated. Would you like to save changes?"
        footer={footer}
      />
      <Popover
        content={<AircraftFilter />}
        placement="bottom"
        autoAdjustOverflow
        open={isPopoverVisible}
        trigger="hover"
        onOpenChange={handleHoverChange}
      >
        <Button
          type="dashed"
          size={getButtonSize(isVerticalMode)}
          onMouseEnter={onMouseEnter}
        >
          AC types
        </Button>
      </Popover>
    </>
  );
};
