import { PureComponent } from 'react';
import { utc } from 'moment';
import { setDataTestEntityId } from '../../../utils';

interface ConnectedProps {}
interface OwnProps {
  isUpdated: boolean;
  lastUpdated: number;
  updatedBy?: string;
}
interface Props extends ConnectedProps, OwnProps {}
export default class TooltipCreationLogTime extends PureComponent<Props> {
  render() {
    const { lastUpdated, isUpdated, updatedBy } = this.props;
    const wasCreated = isUpdated ? 'Updated' : 'Created';
    return (
      <div
        className="tooltip-common-updated-by"
        {...setDataTestEntityId('tooltip-common-updated-by')}
      >
        {`${wasCreated} ${(updatedBy && `by ${updatedBy}`) || ''}${utc(
          lastUpdated
        ).format(' DD MMM,’YY, HH:mm[Z]')}`}
      </div>
    );
  }
}
