import { CSSProperties, FC, useCallback, useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import { setDataTestEntityId } from '../../../utils';
import DragAndDropIcon from '../../svg-icons/aircraft/DragAndDropIcon';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';
import {
  FilterByAircraftType,
  FilterByOperatingCompany,
  CurrentAcTypePositionPayload,
} from '../../../types/aircraft';
import { useDispatch } from 'react-redux';
import { userChangeCurrentAcTypePosition } from '../../../actions';

const CurrentAcType: FC<{
  type: FilterByAircraftType;
}> = ({ type }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isSorting,
  } = useSortable({
    id: type.id,
    transition: {
      duration: 50, // milliseconds
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    },
  });
  const checked = type.checked && type.tails.every(s => s.checked);
  const indeterminate = type.checked && type.tails.some(s => !s.checked);
  const style: CSSProperties = {
    cursor: isSorting ? 'grabbing' : 'grab',
  };
  return (
    <div
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        display: 'flex',
        justifyContent: 'space-around',
        fontSize: '14px',
        width: '150px',
      }}
      key={type.id}
      {...attributes}
      {...listeners}
      ref={setNodeRef}
    >
      <Checkbox
        checked={checked}
        indeterminate={indeterminate}
        id={type.code}
        disabled
        style={{ ...style, padding: '3px 3px 3px 0' }}
        {...setDataTestEntityId(`ac-type-filter-${type.name}`)}
      />
      <label
        htmlFor={type.code}
        style={style}
        className="filter-bar-actypes-content-companies-column-types-menu-checkbox"
      >
        <div>{type.code}</div>
        <div>
          <DragAndDropIcon />
        </div>
      </label>
    </div>
  );
};

export const DndAcTypes: FC<{
  filterByAircraftType: FilterByAircraftType[];
  company: FilterByOperatingCompany;
}> = props => {
  const { company, filterByAircraftType } = props;
  const dispatch = useDispatch();
  const onChange = (payload: CurrentAcTypePositionPayload) =>
    dispatch(userChangeCurrentAcTypePosition(payload));
  const sensors = useSensors(useSensor(PointerSensor));
  const [sortableItems, setSortableItems] = useState<FilterByAircraftType[]>(
    []
  );
  useEffect(() => {
    if (filterByAircraftType) {
      setSortableItems(filterByAircraftType);
    }
  }, [filterByAircraftType]);

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (active && over && active.id !== over?.id) {
        const oldIndex = sortableItems.map(it => it.id).indexOf(+active.id);
        const newIndex = sortableItems.map(it => it.id).indexOf(+over.id);
        const newArray = arrayMove(sortableItems, oldIndex, newIndex);
        setSortableItems(newArray);
        const payload = {
          [company.id]: newArray.map(el => el.id),
        };
        onChange(payload);
      }
    },
    [sortableItems, company]
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={onDragEnd}
      modifiers={[restrictToVerticalAxis]}
    >
      <SortableContext
        strategy={verticalListSortingStrategy}
        items={sortableItems}
      >
        <div className="filter-bar-actypes-content-companies-column-types-edit">
          {sortableItems.map(type => {
            return <CurrentAcType key={type.id} type={type} />;
          })}
        </div>
      </SortableContext>
    </DndContext>
  );
};
