import { reducerWithInitialState } from 'typescript-fsa-reducers';
import Flight from '../types/flight';
import * as actions from '../actions';

export interface FlightDetailsState {
  selectedFlight: Flight | null;
  flightMenuOpenedFor: Flight | null;
  isDragging: boolean;
  draggingPosition: {
    x: number;
    y: number;
  };
}
export const initialState: FlightDetailsState = {
  selectedFlight: null,
  flightMenuOpenedFor: null,
  isDragging: false,
  draggingPosition: {
    x: 0,
    y: 0,
  },
};

export default reducerWithInitialState(initialState)
  .case(actions.userHoverFlight, (state, payload) => {
    if (state.isDragging) return { ...state };
    return {
      ...state,
      selectedFlight: payload[0],
    };
  })
  .case(actions.wsUpdateBatch, (state, payload) => {
    if (
      payload.data.flights &&
      state.selectedFlight &&
      payload.data.flights[state.selectedFlight.id]
    ) {
      return {
        ...state,
        selectedFlight: payload.data.flights[state.selectedFlight.id],
      };
    }
    return { ...state };
  })
  .case(actions.userOpenFlightMenu, (state, payload) => ({
    ...state,
    flightMenuOpenedFor: payload[1],
  }))
  .case(actions.userCloseContextMenu, state => ({
    ...state,
    flightMenuOpenedFor: initialState.flightMenuOpenedFor,
  }))
  .case(actions.userMoveFlightToTail, state => ({
    ...state,
    flightMenuOpenedFor: initialState.flightMenuOpenedFor,
  }))
  .cases(
    [
      actions.userZoomHor,
      actions.userZoomVer,
      actions.userToggleExcludeIncludeEmptyLeg,
      actions.userOpenNearestAircraftModal,
    ],
    state => ({
      ...state,
      flightMenuOpenedFor: initialState.flightMenuOpenedFor,
    })
  )
  .cases(
    [].concat([
      actions.userOpenCreateEventsMenu,
      actions.userOpenNoteContextMenu,
      actions.userOpenContextMenuForMxEvent,
      actions.userOpenContextMenuForOneWayOffer,
      actions.userOpenContextMenuForEmptyLegOffer,
      actions.userOpenContextMenuForPeakDay,
      actions.userCloseHandoverRemarksDrawer,
      actions.userFocusOverlapCards,
      actions.userMarkFlight.started,
      actions.userOpenPFA.started,
      actions.userOpenFP.started,
      actions.userOpenGlobalHudPFA.started,
    ]),
    state => ({
      ...state,
      flightMenuOpenedFor: initialState.flightMenuOpenedFor,
    })
  )
  .case(actions.userDragFlightsBegin, (state, payload) => ({
    ...state,
    isDragging: true,
    draggingPosition: payload.draggingPosition,
  }))
  .case(actions.userDragFlightsInProgress, (state, payload) => ({
    ...state,
    draggingPosition: payload.draggingPosition,
  }))
  .case(actions.userDragFlightsEnd, state => ({
    ...state,
    isDragging: false,
  }))
  .case(actions.userCloseDndModal, state => ({
    ...state,
    draggingPosition: initialState.draggingPosition,
  }))
  .case(actions.userChangeTailForFlights.started, state => ({
    ...state,
    draggingPosition: initialState.draggingPosition,
  }))
  .case(actions.userCancelDraggingFlights, state => ({
    ...state,
    isDragging: false,
    draggingPosition: initialState.draggingPosition,
  }))
  .case(actions.webSocketConnectionClosed, state => {
    const { flightMenuOpenedFor, selectedFlight } = state;
    return {
      ...initialState,
      flightMenuOpenedFor,
      selectedFlight,
    };
  });
