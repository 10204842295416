import * as d3S from 'd3-selection';
import { ExtendedEventElement } from '../..';
import * as actions from '../../../../../actions';
import {
  getElementOffsetWithKoef,
  getLaneHeightKoef,
} from '../../../../../reducers/ui';
import { store as reduxStore } from '../../../../../root';
import { OverlappedElements } from '../../../../../types/event-element';
import { getBoundingElement } from '../../../../../utils';

export const getBasicFlightOverlapD3creator = (
  d: d3S.Selection<
    any,
    ExtendedEventElement & OverlappedElements,
    HTMLDivElement,
    {}
  >
) => {
  const state = reduxStore.getState();
  const { transform, segmentsVisibility, positionMap } = state.ui;
  const { togglersState } = state.aircraft;
  const container = d
    .append('div')
    .style('transform', d => `translate(${d.x}px, ${d.y}px)`)
    .classed('label-text', true)
    .on('mouseover', function(d) {
      reduxStore.dispatch(
        actions.userHoverOverlap([d, getBoundingElement(this)])
      );
    })
    .on('mouseout', () => reduxStore.dispatch(actions.userUnhoverOvlElement()))
    .style(
      'top',
      el =>
        `${getElementOffsetWithKoef(
          segmentsVisibility,
          'flights',
          togglersState[el.aircraftId],
          positionMap
        ) *
          transform.ky -
          2}px`
    )
    .style(
      'height',
      d =>
        `${(positionMap.flights * transform.ky) /
          getLaneHeightKoef(
            segmentsVisibility,
            togglersState[d.aircraftId],
            positionMap
          ) +
          4}px`
    );
  return container;
};
