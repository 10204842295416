import { apolloClient } from '../../data-processing/graphql';
import { gql } from '@apollo/client';
import {
  getGlobalHudPFAQuery,
  getGlobalHudPFAQueryVariables,
} from '../../types/operation-result-types';

const getGlobalHudPFA = require('./get-global-hud-pfa-link.gql');

export function getGlobalHudPFAService(
  orderId: number,
  operatingCompanyId: number
) {
  return apolloClient
    .query<getGlobalHudPFAQuery, getGlobalHudPFAQueryVariables>({
      query: gql(getGlobalHudPFA),
      variables: {
        orderId,
        operatingCompanyId,
      },
    })
    .then(d => d.data?.getGlobalHudPFA);
}
