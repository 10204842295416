import { ApolloClient, InMemoryCache, from, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { USE_AUTH } from '../../constants/environment';
import {
  getTokenFromLocalStorage,
  removeTokenPair,
  setKeycloakTokenPair,
} from '../../utils/tokenLocalStorage';
import { keycloak } from '../../initialize';
import { handleError } from '../../components/error-handling/utils';

function getKeycloakAccessToken() {
  return new Promise(resolve => {
    keycloak
      .updateToken(30)
      .then(refreshed => {
        if (refreshed) {
          setKeycloakTokenPair(keycloak);
        }
        resolve(keycloak.token);
      })
      .catch(() => {
        removeTokenPair();
        keycloak.logout();
      });
  });
}

let tokenPromise = null;

async function getToken() {
  let token = getTokenFromLocalStorage();
  try {
    if (tokenPromise) {
      return tokenPromise;
    }
    if (!USE_AUTH && keycloak) {
      if (keycloak.isTokenExpired(10)) {
        tokenPromise = getKeycloakAccessToken();
        token = await tokenPromise;
        tokenPromise = null;
      } else {
        token = keycloak.token;
      }
    }
  } catch (error) {
    console.error(error);
    handleError({
      error,
      message: 'Failed to get token',
      content: 'at getToken()',
    });
  }
  return token;
}

const authLink = setContext(async (_req, { headers, ...context }) => {
  const token = await getToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
    ...context,
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation: { operationName } }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(error => {
        const { extensions, message, stack, name } = error;
        const content = JSON.stringify(error).replace(
          /Bearer [\w|.|-]+/im,
          '...'
        );
        handleError({
          error,
          content: stack || content,
          message: `GraphQL Error: ${message}: ${name || extensions.code}`,
          url:
            extensions.exception?.config?.url ||
            `GraphQL operation name: ${operationName}`,
        });
      });
    }

    if (networkError) {
      let message = `Network error: failed to ${operationName}`;
      if ('response' in networkError) {
        message = `${message}\n${networkError.response?.statusText}`;
      }
      if ('bodyText' in networkError) {
        message = `${message}\n${networkError.bodyText}`;
      }
      handleError({
        error: networkError,
        content: networkError.stack,
        message,
        url: operationName,
      });
    }
  }
);

export const apolloClient = new ApolloClient({
  link: from([authLink, errorLink, new HttpLink()]),
  cache: new InMemoryCache({}),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});
