import * as d3S from 'd3-selection';
import { store } from '../../../../../root';
import { EventGroup } from '../event-group';
import { ExtendedEventElement } from '..';
import { getInitialScale, getLaneHeightKoef } from '../../../../../reducers/ui';
import { OfferStatus } from '../../../../../types/empty-leg-offer';
import { EmptyLeg } from '../../../../../reducers/empty-legs';
import { getBorderColorToEmptyLeg } from '../../../../../common/empty-leg-and-one-way/empty-leg-one-way-colorization';

export class EmptyLegGroup extends EventGroup {
  renderEntered(
    entered: d3S.Selection<
      d3S.EnterElement,
      ExtendedEventElement,
      SVGGElement,
      {}
    >
  ) {
    const state = store.getState();
    const {
      width,
      planeBlockWidth,
      segmentsVisibility,
      positionMap,
    } = state.ui;
    const { togglersState } = state.aircraft;
    const { elementName } = this.props;
    const scaleX = getInitialScale(width - planeBlockWidth);
    const availabilityEntered = entered as d3S.Selection<
      d3S.EnterElement,
      ExtendedEventElement & EmptyLeg,
      SVGGElement,
      {}
    >;
    const rectGroup = availabilityEntered.append('g').classed('event', true);
    rectGroup
      .append('rect')
      .attr('x', d => scaleX(d.start))
      .attr('y', d => d.y)
      .style('fill', d =>
        d.offerStatus === OfferStatus.CANCELLED
          ? '#b5b5b5'
          : 'rgba(106,200,224,0.7)'
      )
      .attr('width', d => this.rectWidthCalculator(d, scaleX))
      .attr(
        'height',
        d =>
          positionMap[elementName] /
          getLaneHeightKoef(
            segmentsVisibility,
            togglersState[d.aircraftId],
            positionMap
          )
      )
      .attr('stroke-width', 1)
      .attr('stroke', d =>
        d.offerStatus === OfferStatus.CANCELLED
          ? '#8a8a8a'
          : 'rgba(106,200,224,1)'
      )
      .attr('vector-effect', 'non-scaling-stroke');
    rectGroup
      .append('path')
      .attr(
        'd',
        d =>
          `M ${scaleX(d.start)} ${d.y} V ${d.y +
            positionMap[elementName] /
              getLaneHeightKoef(
                segmentsVisibility,
                togglersState[d.aircraftId],
                positionMap
              )}`
      )
      .attr('fill', 'transparent')
      .attr('stroke', d => getBorderColorToEmptyLeg(d.offerStatus))
      .attr('stroke-width', 3)
      .attr('vector-effect', 'non-scaling-stroke');
    return rectGroup;
  }
}
