const ArrowIcon = props => (
  <svg width="22px" height="8px" viewBox="0 0 22 8" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-1264.000000, -302.000000)"
        fill={props.color ? props.color : '#BFBFBF'}
        fillRule="nonzero"
      >
        <g transform="translate(1130.000000, 156.000000)">
          <g>
            <g transform="translate(134.000000, 146.000000)">
              <path d="M22,4 L18,8 L18,5 L5.83,5 C5.42,6.17 4.31,7 3,7 C1.34314575,7 2.22044605e-16,5.65685425 0,4 C-2.22044605e-16,2.34314575 1.34314575,1 3,1 C4.31,1 5.42,1.83 5.83,3 L18,3 L18,0 L22,4 Z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ArrowIcon;
