import { PureComponent, Fragment } from 'react';
import { range } from 'lodash';
import { RootState } from '../../../reducers';
import { connect } from 'react-redux';
import {
  getSortedVisibleAircraft,
  getVisibleHoldAircraft,
} from '../../../selectors';
import { generate } from 'shortid';
import Aircraft from '../../../types/aircraft';
import { HOLD_LANE_STROKE } from '../../../constants';

interface StateProps {
  marginTop: number;
  holdLineHeight: number;
  translateY: number;
  ky: number;
}
interface DelimitersFleetProps {
  width: number;
  rowHeight: number;
  visibleAircraftQuantity: number;
  visibleHoldAcLines: number;
}
class DelimitersFleet extends PureComponent<DelimitersFleetProps> {
  render() {
    const { width, rowHeight, visibleHoldAcLines } = this.props;

    return (
      <>
        {range(this.props.visibleAircraftQuantity).map(index => (
          <Fragment key={generate()}>
            <line
              x1={0}
              x2={width}
              y1={(index + 1) * rowHeight}
              y2={(index + 1) * rowHeight}
              stroke="lightgray"
            />
            {(index + visibleHoldAcLines) % 2 === 0 && (
              <rect
                x={0}
                width={width}
                y={index * rowHeight}
                height={rowHeight}
                fill="white"
              />
            )}
          </Fragment>
        ))}
      </>
    );
  }
}
export const DelimitersFleetConnected = connect((state: RootState) => ({
  width: state.ui.width,
  rowHeight: state.ui.rowHeight,
  visibleAircraftQuantity: getSortedVisibleAircraft(state).length,
  visibleHoldAcLines: state.ui.visibleHoldAcIds.length,
}))(DelimitersFleet);

interface DelimitersHoldProps {
  width: number;
  rowHeight: number;
  holdAircraft: Aircraft[];
  holdAircraftPositionMap: { [id: number]: { y1: number; y2: number } };
}
class DelimitersHold extends PureComponent<DelimitersHoldProps> {
  render() {
    const {
      width,
      holdAircraft,
      holdAircraftPositionMap,
      rowHeight,
    } = this.props;
    let prevLineY = 0;
    const holdLines =
      holdAircraft.length > 0
        ? holdAircraft.map((ac, index) => {
            const position = holdAircraftPositionMap[ac.id];
            const height = position ? position.y2 - position.y1 : rowHeight;
            const y = height + prevLineY;
            const fragment = (
              <Fragment key={ac.id}>
                <line
                  x1={0}
                  x2={width}
                  y1={y}
                  y2={y}
                  stroke="rgba(0,0,0,0.3)"
                  strokeWidth={HOLD_LANE_STROKE}
                />
                {index % 2 === 0 && (
                  <rect
                    x={0}
                    width={width}
                    y={prevLineY}
                    height={height}
                    fill="white"
                  />
                )}
              </Fragment>
            );
            prevLineY += height;
            return fragment;
          })
        : null;
    return <>{holdLines}</>;
  }
}
export const DelimitersHoldConnected = connect((state: RootState) => ({
  width: state.ui.width,
  rowHeight: state.ui.rowHeight,
  holdAircraft: getVisibleHoldAircraft(state),
  holdAircraftPositionMap: state.ui.holdAircraftPositionMap,
}))(DelimitersHold);

class DelimiterComponent extends PureComponent<StateProps> {
  render() {
    const { translateY, ky, marginTop, holdLineHeight } = this.props;
    return (
      <>
        <g clipPath="url(#eventsSVGClipHold)">
          <g
            className="aircraft-delimiter"
            transform={`translate(0,${marginTop})scale(1,1)`}
          >
            <DelimitersHoldConnected />
          </g>
        </g>
        <g clipPath="url(#eventsSVGClip)">
          <g
            className="aircraft-delimiter"
            transform={`translate(0,${translateY +
              marginTop +
              holdLineHeight})scale(1, ${ky})`}
          >
            <DelimitersFleetConnected />
          </g>
        </g>
      </>
    );
  }
}
export const HorizontalDelimiter = connect((state: RootState) => ({
  marginTop: state.ui.marginTop,
  holdLineHeight: state.ui.holdLineHeight,
  translateY: state.ui.transform.translateY,
  ky: state.ui.transform.ky,
}))(DelimiterComponent);
