import { AxiosError } from 'axios';
import { HandoverRemarksState } from '../reducers/handover-remarks';
import Flight from './flight';

export interface HandoverFilters {
  acTypes: number[];
  csTeam: number[];
  from: number | null;
  operator: number[];
  radioByType: HandoverRemarksViewByType;
  radioByDepartment: HandoverType[];
  tail: number[];
  to: number | null;
}

export interface HandoverRemark {
  id: string;
  flightLegId?: number; // mandatory for flight remarks
  aircraftId?: number; // mandatory for tail remarks
  remarks: string;
  isActive: boolean; // means not deleted
  lastUpdatedAtMs?: number;
  createdAtMs?: number;
  updatedBy?: string;
  createdBy?: string;
  order?: number; // for sorting inside the flight
  expired?: boolean; // means flight is in the past
  activeFlightLegId?: number;
  activeTailId?: number;
  isActiveNotExpired?: number;
  type?: HandoverType;
}

export interface HandoverRemarkResponse {
  Items: HandoverRemark[];
  Count: number;
}

export interface AllRemarksShape {
  remarks: {
    title: string;
    cards: HandoverRemark[];
    flight?: Flight;
    aircraftId?: number;
  };
  flightLegId: number;
  operatingCompanyName: string;
  aircraftTypeName: string;
  tailNumber: string;
  aircraftId: number;
}

export type HandoverSelectFilterType = keyof Pick<
  HandoverRemarksState['filters'],
  'acTypes' | 'operator' | 'tail'
>;

export interface HandoverError extends AxiosError {
  name: string;
  message: string;
  details: {
    message: string;
    code: string;
    time: string;
    requestId: string;
    statusCode: number;
    retryable?: boolean;
    retryDelay?: number;
  };
}

export enum HandoverRemarksViewByType {
  all = 'All',
  flight = 'Flight',
  tail = 'Tail',
}

export interface HandoverEmail {
  email_to: string[];
  email_cc: string[];
  email_from: string;
  bodyHtml: string;
  subject: string;
}

export enum HandoverType {
  ops = 'Ops',
  cs = 'CS',
}
