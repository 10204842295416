import { apolloClient } from '../../data-processing/graphql';
import { MailTemplate } from '../../types/email';
import { gql } from '@apollo/client';

const getAogEmailGql = require('./get-aog-email-template.gql');

export function getAogEmailTemplate(
  operator: string,
  manufacturerId: number | null
) {
  return apolloClient.query<MailTemplate>({
    query: gql(getAogEmailGql),
    variables: {
      operator,
      manufacturerId,
    },
  });
}
