import { Button, Popconfirm, List } from 'antd';
import { CSSProperties, FC, PureComponent } from 'react';
import LockedIcon from '../../../svg-icons/flight-details-bar/locked';
import { DndDataSource } from './main-content';
import { getFontSize } from './utils';

const LockedFlightsList: FC<{
  dataSource: Array<{
    title: string;
  }>;
}> = ({ dataSource }) => {
  const baseClassName = `dnd-multiple-modal-last-row-buttons-locked-flights-list`;
  return (
    <List
      size="small"
      className={baseClassName}
      itemLayout="horizontal"
      dataSource={dataSource}
      renderItem={item => (
        <List.Item className={`${baseClassName}-item`}>
          <List.Item.Meta
            avatar={
              <div className={`${baseClassName}-item-avatar-container`}>
                <LockedIcon color="red" />
              </div>
            }
            title={
              <span className={`${baseClassName}-item-title-container`}>
                {item.title}
              </span>
            }
          />
        </List.Item>
      )}
    />
  );
};

interface Props {
  onCancel: () => void;
  onConfirm: () => void;
  isDisabled: boolean;
  dndDataSource: Array<DndDataSource>;
  isVerticalMode: boolean;
}

export class DndMultipleModalLastRowButtons extends PureComponent<Props> {
  getLockedFlightsDataSource = () => {
    return this.props.dndDataSource.reduce((acc, f) => {
      if (f.locked) {
        return [...acc, { title: `${f.legId} ${f.icao}` }];
      }
      return acc;
    }, []);
  };
  handleCancel = () => {
    this.props.onCancel();
  };
  handleConfirm = () => {
    this.props.onConfirm();
  };
  confirmOrPreventAction = e => {
    !this.getLockedFlightsDataSource().length
      ? this.handleConfirm()
      : e.preventDefault();
  };
  render() {
    const { isVerticalMode } = this.props;
    const lockedFlightsDataSource = this.getLockedFlightsDataSource();
    const style: CSSProperties = {
      fontSize: getFontSize(isVerticalMode),
    };
    return (
      <div className="dnd-multiple-modal-last-row-buttons">
        <Button onClick={this.handleCancel} style={style}>
          Cancel
        </Button>
        <Popconfirm
          title={
            <>
              <i>There are locked flights:</i>
              <LockedFlightsList dataSource={lockedFlightsDataSource} />
              Do you want to proceed?
            </>
          }
          disabled={!lockedFlightsDataSource.length}
          onConfirm={this.handleConfirm}
        >
          <Button
            type="primary"
            disabled={this.props.isDisabled}
            onClick={this.confirmOrPreventAction}
            style={style}
          >
            Change
          </Button>
        </Popconfirm>
      </div>
    );
  }
}
