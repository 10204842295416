import { PureComponent } from 'react';
import * as d3Scale from 'd3-scale';
import { AxisCombinedComponent } from '../../axis/axis-combined';
import EventElement from '../../../types/event-element';
import { TooltipPlacement } from 'antd/lib/tooltip';
import Preview from './previews';

interface StateProps {
  scale: d3Scale.ScaleTime<number, number>;
  mapWidth: number;
  lineHeight: number;
  overlappedElements: EventElement[];
  focused: number;
  onFocus: (index: number) => () => void;
  onBlur: () => void;
  placement: TooltipPlacement;
}
export class OverlapMap extends PureComponent<StateProps> {
  render() {
    const { scale, overlappedElements, focused, onFocus, onBlur } = this.props;
    return (
      <svg
        width={`${this.props.mapWidth}px`}
        height={`${this.props.lineHeight * overlappedElements.length + 50}px`}
      >
        <AxisCombinedComponent
          height={this.props.lineHeight * overlappedElements.length}
          marginTop={50}
          transform={{
            kx: 1,
            ky: 1,
            scaleX: scale,
            translateX: 0,
            translateY: 0,
          }}
        />
        {overlappedElements.map((el: EventElement, index) => (
          <Preview
            key={el.id}
            element={el}
            y={50 + 22 * index}
            scale={scale}
            isFocused={focused === null || index === focused}
            onFocus={onFocus(index)}
            onBlur={onBlur}
          />
        ))}
      </svg>
    );
  }
}
