import { isEmpty, chain } from 'lodash';
import createCachedSelector from 're-reselect';
import {
  getTimezoneOffset,
  getTimezonesByAirportId,
} from '../common/flight/flight-labels';
import { RootState } from '../reducers';
import Airport from '../types/airport';
import Timezone from '../types/timezone';
import { getAirportByIcao } from './airport';

export const getTimezonesByAirportIdSelector = createCachedSelector<
  RootState,
  number,
  number,
  {
    [id: number]: Airport;
  },
  {
    [id: number]: Timezone;
  },
  Timezone[]
>(
  (_, airportId) => airportId,
  state => state.airports.airportsById,
  state => state.timezones.timezonesById,
  (airportId, airportsById, timezonesById) => {
    return getTimezonesByAirportId(airportsById, timezonesById, airportId);
  }
)((_, airportId) => airportId);

const getTimezonesByAirport = createCachedSelector(
  getAirportByIcao,
  (state, icao) => state.timezones.timezonesById,
  (airport, timezonesById) => {
    if (!airport || !timezonesById || isEmpty(timezonesById)) {
      return null;
    }
    const timezones = chain(airport.timezoneIds)
      .map(id => timezonesById[id])
      .compact()
      .value();
    return timezones;
  }
)((state, icao) => icao);

export const getTimezoneOffsetForAirportByIcao = createCachedSelector(
  getTimezonesByAirport,
  (state, icao) => state.time.now,
  (timezones, nowTime) => {
    if (!timezones) {
      return null;
    }
    return getTimezoneOffset(timezones, nowTime);
  }
)((state, icao) => icao);
