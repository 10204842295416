import { gql } from '@apollo/client';
import { apolloClient } from '../../data-processing/graphql';
import { EMailVoInput } from '../../types/operation-result-types';

const sendEmail = require('./send-email.gql');

export function sendMxEmail(eMail: EMailVoInput) {
  return apolloClient.mutate({
    mutation: gql(sendEmail),
    variables: {
      eMail,
    },
  });
}
