import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import Airport from '../../types/airport';
import Timezone from '../../types/timezone';
import Flight from '../../types/flight';
import { Popover } from 'antd';
import { getAircraftById } from '../../selectors';
import Aircraft from '../../types/aircraft';
import { hasPermission } from '../../utils/check-permissions';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { Crew } from '../../types/crew-roster';
import TooltipParent from './parent';
import { getFlightCrewDetailsSorted } from '../../utils/crew-roster';

interface OwnProps {
  opened: boolean;
  selectedFlight: Flight;
  placement: TooltipPlacement;
  crew: { [flightLegId: number]: Crew[] };
}
interface Props {
  airportsById: { [id: number]: Airport };
  timezonesData: { [id: number]: Timezone };
  aircraft: Aircraft;
  isHiddenTooltipWrapper: boolean;
  HAS_PSS_PERMISSION: boolean;
  HAS_PAX_PERMISSION: boolean;
  HAS_REMARKS_PERMISSION: boolean;
  HAS_PROGRAM_REVENUE_PERMISSION: boolean;
  HAS_OD_REVENUE_PERMISSION: boolean;
  flightTooltipWidth: number;
  crew: { [flightLegId: number]: Crew[] };
}

class ReactFlightTooltip extends PureComponent<Props & OwnProps> {
  render() {
    const {
      opened,
      selectedFlight,
      airportsById,
      aircraft,
      timezonesData,
      children,
      isHiddenTooltipWrapper,
      HAS_PSS_PERMISSION,
      HAS_PAX_PERMISSION,
      HAS_REMARKS_PERMISSION,
      HAS_PROGRAM_REVENUE_PERMISSION,
      HAS_OD_REVENUE_PERMISSION,
      placement,
    } = this.props;
    return (
      <Popover
        open={opened && !isHiddenTooltipWrapper}
        style={{ padding: 0 }}
        // motion="none"
        mouseLeaveDelay={0}
        placement={placement}
        mouseEnterDelay={0}
        content={
          <div
            style={{
              margin: '-12px -16px',
            }}
          >
            <TooltipParent
              resizable
              HAS_PSS_PERMISSION={HAS_PSS_PERMISSION}
              HAS_PAX_PERMISSION={HAS_PAX_PERMISSION}
              HAS_REMARKS_PERMISSION={HAS_REMARKS_PERMISSION}
              HAS_PROGRAM_REVENUE_PERMISSION={HAS_PROGRAM_REVENUE_PERMISSION}
              HAS_OD_REVENUE_PERMISSION={HAS_OD_REVENUE_PERMISSION}
              selectedFlight={selectedFlight}
              airportsById={airportsById}
              timezonesData={timezonesData}
              aircraft={aircraft}
              crew={getFlightCrewDetailsSorted(
                this.props.crew[
                  this.props.selectedFlight && this.props.selectedFlight.id
                ] || []
              )}
            />
          </div>
        }
      >
        {children}
      </Popover>
    );
  }
}
export const ReactFlightTooltipConnected = connect(
  (state: RootState, ownProps: OwnProps) => ({
    airportsById: state.airports.airportsById,
    timezonesData: state.timezones.timezonesById,
    aircraft: ownProps.selectedFlight
      ? getAircraftById(state, ownProps.selectedFlight.aircraftId)
      : null,
    isHiddenTooltipWrapper: state.ui.isHiddenTooltipWrapper,
    flightTooltipWidth: state.ui.flightTooltipWidth,
    HAS_PSS_PERMISSION: hasPermission(state, 'AG-Timeline-PSS-View'),
    HAS_PAX_PERMISSION: hasPermission(state, 'AG-Timeline-Lead-Pax-View'),
    HAS_REMARKS_PERMISSION: hasPermission(state, 'AG-Timeline-Remarks-View'),
    HAS_PROGRAM_REVENUE_PERMISSION: hasPermission(
      state,
      'AG-Timeline-Program-Revenue-View'
    ),
    HAS_OD_REVENUE_PERMISSION: hasPermission(
      state,
      'AG-Timeline-OD-Revenue-View'
    ),
  })
)(ReactFlightTooltip);
