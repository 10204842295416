import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import { ModalFuncProps } from 'antd/lib/modal';
import React, { FC, useEffect } from 'react';

export const ConfirmationModal: FC<ModalFuncProps & {
  onClose: () => void;
  footer: React.ReactNode;
  confirmationTitle?: string;
  confirmationQuestion?: string;
  onKeyDown?: (event: any) => void;
}> = ({
  footer,
  open,
  onClose,
  onKeyDown,
  confirmationTitle = 'You have unsaved changes',
  confirmationQuestion = 'Would you like to save them before quitting?',
  children,
}) => {
  useEffect(() => {
    if (open && typeof onKeyDown !== 'undefined') {
      document.addEventListener('keydown', onKeyDown);
      return () => {
        document.removeEventListener('keydown', onKeyDown);
      };
    }
  }, [onKeyDown, open]);
  return (
    <Modal
      centered
      footer={footer}
      onCancel={onClose}
      destroyOnClose
      keyboard={false}
      title={[
        <div className="warning-title">
          <ExclamationCircleFilled />
          <span className="warning-title-text">{confirmationTitle}</span>
        </div>,
      ]}
      open={open}
      zIndex={1500}
    >
      {confirmationQuestion}
      {children}
    </Modal>
  );
};
