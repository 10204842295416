import './styles.scss';
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import FavIcon from '../../../../assets/icon/favicon-32x32.png';
import { userToggleNearestAircraftModalSize } from '../../actions';
import { isEqual } from 'lodash';

interface StateProps {
  loading: boolean;
  visible: boolean;
}

export const MinimizedNearestAcComponent: FC<{}> = () => {
  const dispatch = useDispatch();
  const onClick = () => dispatch(userToggleNearestAircraftModalSize());
  const { loading, visible } = useSelector<RootState, StateProps>(
    state => ({
      loading: state.nearestAircraft.loading,
      visible: state.nearestAircraft.minimized,
    }),
    isEqual
  );
  if (!visible) return null;
  return (
    <div
      className="nearest-ac-minimized-window"
      onClick={onClick}
      title="Restore Nearest Aircraft window"
    >
      <div>
        <img src={FavIcon} />
        <span className="nearest-ac-modal-header-title">Nearest A/C</span>
      </div>
      {loading ? <LoadingOutlined /> : <CheckOutlined />}
    </div>
  );
};
