import { axiosAuthed as axios } from '../axios-config';
import {
  transformToFE as tranformRoster,
  CrewRosterGVModel,
} from './crew-roster-model';
import {
  CrewAssignmentGVModel,
  transformToFE as tranformAssignments,
} from './crew-assignment-model';

export const getCrewRosters = (startTime: number, endTime: number) =>
  axios
    .get(
      `/integration/timeline/crew-roster?startTime=${startTime}&endTime=${endTime}`
    )
    .then(resp =>
      resp.data
        .filter((c: CrewRosterGVModel) => !!c.aircraftId)
        .map(tranformRoster)
    )
    .catch(e => {
      throw e;
    });

export const getCrewAssignments = (startTime: number, endTime: number) =>
  axios
    .get(
      `/integration/timeline/crew-assignments?startTime=${startTime}&endTime=${endTime}`
    )
    .then(resp =>
      resp.data
        .filter((c: CrewAssignmentGVModel) => !!c.aircraftId)
        .map(tranformAssignments)
    )
    .catch(e => {
      throw e;
    });
