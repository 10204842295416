import DatePicker from '../../../../common/components/date-time-pickers/date-picker';
import { PickerProps } from 'antd/es/date-picker/generatePicker';

import { FormikProps } from 'formik';
import { Moment, utc } from 'moment';
import { FC, useRef } from 'react';
import { BusyDays } from '../../../../types/peak-day';
import {
  isTimeGapLessThanDay,
  PeakDayFormFieldName,
  PeakDayFormFieldValues,
} from '../helpers';

export const DatePickerComponent: FC<PickerProps<Moment>> = ({
  value,
  onChange,
  disabled,
  disabledDate,
  cellRender,
}) => {
  const refDate = useRef(null);
  return (
    <DatePicker
      format="DD MMM'YY"
      value={value}
      onChange={onChange}
      allowClear
      ref={refDate}
      disabled={disabled}
      disabledDate={disabledDate}
      cellRender={cellRender}
    />
  );
};

export const DatePickerHandler: FC<Omit<PickerProps<Moment>, 'value'> & {
  busyDays: BusyDays;
  value: number;
  setFieldValue: FormikProps<PeakDayFormFieldValues>['setFieldValue'];
  type: PeakDayFormFieldName.start | PeakDayFormFieldName.end;
}> = ({ value, type, busyDays, disabled, setFieldValue }) => {
  const handleDateChange = (
    date: Moment,
    name: PeakDayFormFieldName.end | PeakDayFormFieldName.start
  ) => {
    let time = null;
    if (date) {
      time =
        name === PeakDayFormFieldName.start
          ? utc(date)
              .startOf('day')
              .valueOf()
          : utc(date)
              .endOf('day')
              .valueOf();
    }
    setFieldValue(name, time);
  };
  const overlappedDates = (current: Moment) => {
    if (current) {
      const startOfCurrentDay = utc(current)
        .startOf('day')
        .valueOf();
      const overlapped = busyDays.includes(startOfCurrentDay);

      return overlapped;
    }
    return false;
  };
  const pastDates = (current: Moment) => {
    return current && current < utc().endOf('day');
  };
  const disabledDates = (current: Moment) =>
    overlappedDates(current) || pastDates(current);
  const cellRender = (current: Moment) => {
    const overlapped = overlappedDates(current);
    let title = current.format('MMMM D, YYYY');
    if (disabledDates(current)) {
      title = `Can't ${
        overlapped ? 'overlap with other event' : 'be in the past'
      }`;
    }
    return (
      <div className="ant-calendar-date" title={title}>
        {current.date()}
      </div>
    );
  };
  const disableCondition =
    (value !== null && !isTimeGapLessThanDay(value)) || disabled;
  return (
    <DatePickerComponent
      onChange={v => handleDateChange(v, type)}
      value={value ? utc(value) : null}
      disabled={disableCondition}
      disabledDate={disabledDates}
      cellRender={cellRender}
    />
  );
};
