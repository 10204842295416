import { PureComponent } from 'react';
import Aircraft from '../../../types/aircraft';
import { RootState } from '../../../reducers';
import { connect, DispatchProp } from 'react-redux';
import {
  getBaseCompaniesIds,
  getUnavailableForAdvertisementMapLabels,
  getVisibleHoldAircraft,
} from '../../../selectors';
import { HoldAircraftComponentConnected } from '../../aircraft/hold-aircraft';
import { getTailsWithHandover } from '../../../selectors/handover-remarks';
import { AnyAction } from 'redux';

interface StateProps {
  holdAircraft: Aircraft[];
  holdAircraftPositionMap: { [id: number]: { y1: number; y2: number } };
  planeBlockWidth: number;
  rowHeight: number;
  hoveredAircraftIndex: number;
  baseCompaniesIds: number[];
  hasUnavailableForAdvertisement: { [aircraftId: number]: boolean };
  tailsWithHandover: Set<number>;
}
class HoldAircraftList extends PureComponent<
  StateProps & DispatchProp<AnyAction>
> {
  render() {
    const {
      holdAircraft,
      planeBlockWidth,
      hoveredAircraftIndex,
      baseCompaniesIds,
      hasUnavailableForAdvertisement,
      holdAircraftPositionMap,
      rowHeight,
      tailsWithHandover,
    } = this.props;
    return (
      <div className="aircraft-list">
        {holdAircraft.length > 0
          ? holdAircraft.map((hold, index) => {
              const position = holdAircraftPositionMap[hold.id];
              const height = position ? position.y2 - position.y1 : rowHeight;
              return (
                <HoldAircraftComponentConnected
                  aircraft={hold}
                  baseCompaniesIds={baseCompaniesIds}
                  hovered={hoveredAircraftIndex === index}
                  index={index}
                  isUnavailableForAdvertisement={
                    hasUnavailableForAdvertisement[hold.id]
                  }
                  planeBlockWidth={planeBlockWidth}
                  height={height}
                  key={hold.id}
                  hasTailHandover={tailsWithHandover.has(hold.id)}
                />
              );
            })
          : null}
      </div>
    );
  }
}

export const HoldAircraftListConnected = connect(
  (state: RootState): StateProps => ({
    planeBlockWidth: state.ui.planeBlockWidth,
    hoveredAircraftIndex: state.ui.hoveredAircraftIndex,
    rowHeight: state.ui.rowHeight,
    baseCompaniesIds: getBaseCompaniesIds(state),
    hasUnavailableForAdvertisement: getUnavailableForAdvertisementMapLabels(
      state
    ),
    holdAircraft: getVisibleHoldAircraft(state),
    holdAircraftPositionMap: state.ui.holdAircraftPositionMap,
    tailsWithHandover: getTailsWithHandover(state),
  })
)(HoldAircraftList);
