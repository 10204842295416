import { chain, sortBy } from 'lodash';

import Flight, { HandlingStatus } from '../../types/flight';
import { Place } from '../airport-service/airport-model';

interface Passenger {
  passengerPlanId: {
    key: number;
  };
  passengerName: string;
  isLeadPassenger: boolean;
  leadPassenger: boolean;
}

interface Slot {
  id: {
    key: number;
  };
  airportId: {
    key: number;
  };
  fromDate: number;
  toDate: number;
  permissionSlotTypeId: {
    key: number;
  };
  flightServiceStatusId: {
    key: number;
  };
  needsReview: boolean;
  requestManagementType: boolean;
  leadTime: number;
  flightLegId: {
    key: number;
  };
  slotNumber: string;
}
interface Request {
  id: {
    key: number;
  };
  flightLegId: {
    key: number;
  };
  flightServiceStatusId: {
    key: number;
  };
  needsReview: boolean;
  requestManagementType: boolean;
  airportId: {
    key: number;
  };
  serviceTypeId: {
    key: number;
  };
  serviceProviderId: {
    key: number;
  };
}
interface Permit {
  id: {
    key: number;
  };
  airportId: {
    key: number;
  };
  fromDate: number;
  toDate: number;
  permissionSlotTypeId: {
    key: number;
  };
  flightServiceStatusId: {
    key: number;
  };
  needsReview: boolean;
  requestManagementType: boolean;
  number: string;
  place: Place;
  leadTime: number;
  flightLegId: {
    key: number;
  };
}
interface Crew {
  crewPlanId: {
    key: number;
  };
  crewMemberCode: string;
  role: {
    crewRoleId: {
      key: number;
    };
    name: string;
    customerFacingName: string;
    rank: number;
    abbreviation: string;
  };
  crewDetailId: {
    key: number;
  };
}

export interface Feeds {
  [feed: string]: {
    identity: string;
  };
}

export interface AfbSnapshot {
  hrv: string;
  feeds: Feeds;
  timestamp: number;
}

interface AfbReadAggregate {
  key: {
    flightLegId: number;
    crewCode: string;
    feed: string;
  };
  correlationId: string;
  hrv: string;
  timestamp: number;
}

export interface FlightGVModel {
  flightLegId: {
    key: number;
  };
  legOperationalStatusId: {
    key: number;
  };
  legOperationalTypeId: {
    key: number;
  };
  aircraftId: {
    key: number;
  };
  departureAirportId: {
    key: number;
  };
  arrivalAirportId: {
    key: number;
  };
  numberOfPassengers: number;
  passengerPlanId: {
    key: number;
  };
  crewPlanId: {
    key: number;
  };
  scheduledDeparture: number;
  scheduledArrival: number;
  estimatedDeparture: number;
  estimatedArrival: number;
  blockDeparture: number;
  blockArrival: number;
  wheelsUpDeparture: number;
  wheelsDownArrival: number;
  lastSchedTimesUpdate: number;
  lastEstTimesUpdate: number;
  remarks: string;
  operationsDetailId: null;
  requestedAircraftType: {
    aircraftTypeId: {
      key: number;
    };
    code: string;
    name: string;
  };
  routeLegId: {
    key: number;
  };
  originalFlightLegId: {
    key: number;
  };
  originalRouteLegId: {
    key: number;
  };
  routeId: {
    key: number;
  };
  legBusinessStatusId: {
    key: number;
  };
  legBusinessTypeId: {
    key: number;
  };
  flightOrderId: {
    key: number;
  };
  flightOrderMspId: string;
  flightOrderBusinessStatusId: {
    key: number;
  };
  flightOrderBusinessTypeId: {
    key: number;
  };
  accountId: {
    key: number;
  };
  readyForPreparation: boolean;
  customerId: {
    key: number;
  };
  customerName: string;
  baseCompanyId: {
    key: number;
  };
  permissionsAndRequestsStatus: {
    hashSlotMismatch: boolean;
    hasDepartureSlotMismatch: boolean;
    hasArrivalSlotMismatch: boolean;
    hasUnconfirmedPermit: boolean;
    hasAllRequestsAutomatic: boolean;
  };
  passengers: Passenger[];
  crew: Crew[];
  bpmPreparationStatus: string;
  emptyLegOffer: {
    flightLegId: {
      key: number;
    };
    id: {
      key: number;
    };
    offerStatusId: {
      key: number;
    };
    wasAdvertised: boolean;
  };
  callSign: string;
  opsMemo: string;
  permits: Permit[];
  slots: Slot[];
  requests: Request[];
  isPfaFailed: boolean;
  isFpFailed: boolean;
  isOperationalLegCreated: boolean;
  sensitive: boolean;
  isClosed: boolean;
  flightLocked: boolean;
  isLineCheck: boolean;
  demo: boolean;
  isPilotTraining: boolean;
  jpFlightPlan: {
    jpFlightPlanId: {
      key: number;
    };
    jetPlanSourceType: string;
  };
  salesPerson: {
    personId: {
      key: number;
    };
    firstName: string;
    lastName: string;
    active: boolean;
  };
  isDemo: boolean;
  isFlightLocked: boolean;
  isSensitive: boolean;
  amounts: {
    currencyId: {
      key: number;
    };
    currencySymbol: string;
    orderAmountCents: number;
    flightLegAmountCents: number;
  };
  afbSnapshot: AfbSnapshot | null;
  afbReadAggregate: AfbReadAggregate[];
  fufi: string;
  tripProfile: string;
  flightAdvertise: boolean;
  csTeam: string;
}

export const transformToFE = (f: FlightGVModel): Flight => {
  const passengers = (f.passengers || []).map(f => f.passengerName);
  const leadPassengers = (f.passengers || [])
    .filter(f => f.leadPassenger === true || f.isLeadPassenger === true)
    .map(f => f.passengerName);
  const start = chain([
    'scheduledDeparture',
    'estimatedDeparture',
    'blockDeparture',
    'wheelsUpDeparture',
  ])
    .map(field => f[field])
    .compact()
    .min()
    .value();
  const end = chain([
    'scheduledArrival',
    'estimatedArrival',
    'blockArrival',
    'wheelsDownArrival',
  ])
    .map(field => f[field])
    .compact()
    .max()
    .value();

  return {
    start,
    end,
    sensitive: f.isSensitive,
    lineCheck: f.isLineCheck,
    demoFlight: f.isDemo,
    lockedFlight: f.isFlightLocked,
    legBusinessTypeId: f.legBusinessTypeId.key,
    legBusinessStatusId: f.legBusinessStatusId.key,
    legOperationalStatusId: f.legOperationalStatusId.key,
    orderBusinessTypeId: f.flightOrderBusinessTypeId.key,
    flightOrderId: f.flightOrderId.key,
    flightOrderMspId: f.flightOrderMspId,
    readyForPreparation: f.readyForPreparation,
    customerName: f.customerName,
    departureAirportId: f.departureAirportId.key,
    arrivalAirportId: f.arrivalAirportId.key,
    arrivalSlotMismatch: f.permissionsAndRequestsStatus.hasArrivalSlotMismatch,
    departureSlotMismatch:
      f.permissionsAndRequestsStatus.hasDepartureSlotMismatch,
    handlingStatus: getHandlingStatus(f),
    leadName: leadPassengers.join(', '),
    passengerNames: passengers,
    passengersCount: passengers ? passengers.length : null,
    flightCrews: sortBy(f.crew || [], c => c.role.rank).map(c => ({
      roleAbbr: c.role.abbreviation,
      crewCode: c.crewMemberCode,
    })),
    id: f.flightLegId.key,
    aircraftId: f.aircraftId.key,
    departureUtcScheduled: f.scheduledDeparture,
    arrivalUtcScheduled: f.scheduledArrival,
    departureUtcEstimated: f.estimatedDeparture,
    arrivalUtcEstimated: f.estimatedArrival,
    departureUtcBlock: f.blockDeparture,
    arrivalUtcBlock: f.blockArrival,
    departureUtcActual: f.wheelsUpDeparture,
    arrivalUtcActual: f.wheelsDownArrival,
    remarks: f.remarks,
    opsMemo: f.opsMemo,
    slots: (f.slots || []).map(c => ({
      airportId: c.airportId.key,
      fromDate: c.fromDate,
      toDate: c.toDate,
      permissionSlotTypeId: c.permissionSlotTypeId.key,
      flightServiceStatusId: c.flightServiceStatusId.key,
      needsReview: c.needsReview,
      requestManagementType: c.requestManagementType,
      leadTime: c.leadTime,
      flightLegId: c.flightLegId.key,
      slotNumber: c.slotNumber,
    })),
    permits: (f.permits || []).map(c => ({
      airportId: c.airportId ? c.airportId.key : 0,
      fromDate: c.fromDate,
      toDate: c.toDate,
      permissionSlotTypeId: c.permissionSlotTypeId.key,
      flightServiceStatusId: c.flightServiceStatusId.key,
      needsReview: c.needsReview,
      requestManagementType: c.requestManagementType,
      number: c.number,
      place: c.place,
      leadTime: c.leadTime,
      flightLegId: c.flightLegId.key,
    })),
    requests: (f.requests || []).map(c => ({
      airportId: c.airportId ? c.airportId.key : 0,
      flightServiceStatusId:
        c.flightServiceStatusId && c.flightServiceStatusId.key,
      needsReview: c.needsReview,
      requestManagementType: c.requestManagementType,
      flightLegId: c.flightLegId.key,
      serviceTypeId: c.serviceTypeId && c.serviceTypeId.key,
      serviceProviderId: c.serviceProviderId && c.serviceProviderId.key,
    })),
    requestedAircraftTypeName: f.requestedAircraftType.name,
    numberOfPassengers: f.numberOfPassengers,
    callSignValue: f.callSign,
    baseCompanyId: f.baseCompanyId && f.baseCompanyId.key,
    flightPlanId:
      f.jpFlightPlan &&
      f.jpFlightPlan.jpFlightPlanId &&
      f.jpFlightPlan.jpFlightPlanId.key,
    flightPlanType: f.jpFlightPlan && f.jpFlightPlan.jetPlanSourceType,
    pfaFailed: f.isPfaFailed,
    fpFailed: f.isFpFailed,
    bpmItemStatus: f.bpmPreparationStatus,
    bookedBy: f.salesPerson &&
      f.salesPerson.personId && {
        id: f.salesPerson.personId.key,
        firstName: f.salesPerson.firstName,
        lastName: f.salesPerson.lastName,
      },
    amounts: f.amounts && {
      currencyId: f.amounts.currencyId && f.amounts.currencyId.key,
      currencySymbol: f.amounts.currencySymbol,
      orderAmountCents: f.amounts.orderAmountCents,
      flightLegAmountCents: f.amounts.flightLegAmountCents,
    },
    afbSnapshot: f.afbSnapshot,
    afbReedFeedsByCrewCode: f.afbReadAggregate
      ? f.afbReadAggregate.reduce((acc, v) => {
          return {
            ...acc,
            [v.key.crewCode]: acc[v.key.crewCode]
              ? {
                  ...acc[v.key.crewCode],
                  [v.key.feed]: {
                    identity: v.correlationId,
                  },
                }
              : {
                  [v.key.feed]: {
                    identity: v.correlationId,
                  },
                },
          };
        }, {})
      : null,
    fufi: f.fufi,
    tripProfile: f.tripProfile,
    emptyLegOffer: f.emptyLegOffer,
    flightAdvertise: f.flightAdvertise,
    originalFlightLegId: f.originalFlightLegId?.key,
    csTeam: f.csTeam,
  };
};

function getHandlingStatus(flightSegment: FlightGVModel): HandlingStatus {
  if (!flightSegment.readyForPreparation) return HandlingStatus.NO_STATUS;
  if (!flightSegment.permissionsAndRequestsStatus.hasUnconfirmedPermit)
    return HandlingStatus.CONFIRMED_HANDLING;
  if (flightSegment.permissionsAndRequestsStatus.hasAllRequestsAutomatic)
    return HandlingStatus.AUTOMATIC_HANDLING;
  return HandlingStatus.MANUAL_HANDLING;
}
