import { gql } from '@apollo/client';
import { apolloClient } from '../../data-processing/graphql';
import { MXEventDtoInput } from '../../types/operation-result-types';

const saveMxEvent = require('./save-mx-event.gql');

export function saveMxEventMutation(mxEvent: MXEventDtoInput) {
  return apolloClient.mutate({
    mutation: gql(saveMxEvent),
    variables: {
      mxEvent,
    },
  });
}
