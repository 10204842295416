import {
  VISIBLE_AC_TYPES,
  VISIBLE_OPERATING_COMPANIES,
} from '../constants/environment';
import { AircraftState, AircraftTogglerState } from '../reducers/aircraft';
import { EmptyLegGroupType } from '../reducers/empty-legs';
import { OperationStatus } from '../reducers/operational-statuses';
import { DayZoomLevel, getInitialScale, ReducerShape } from '../reducers/ui';
import { MxEventPresenceType } from './aircraft';
import { FlightLabelTimeType } from './flight';
import { GroundTimeType } from './ground-time';
import { ExtendedSegmentType } from './segment-types';
import Transform from './transform';

export interface UserPreferences {
  segmentsVisibility: { [st in ExtendedSegmentType]: boolean };
  groundTimeType: GroundTimeType;
  timeLabelDisplayMode: FlightLabelTimeType;
  mxEventPresenceType: MxEventPresenceType;
  statusMap: OperationStatus['statusMap'];
  kx: Transform['kx'];
  scaleX: Transform['scaleX'];
  ky: Transform['ky'];
  togglersState: { [aircraftId: number]: AircraftTogglerState };
  emptyLegMap: { [el in EmptyLegGroupType]: boolean };
  isDaySeparatorOnTop: boolean;
  showSubCharters: boolean;
  showLabels: boolean;
  zoomLvl: DayZoomLevel;
  visibleHoldAcIds: number[];
  statisticsMode: ReducerShape['statisticsMode'];
  isGroupedByOperator: boolean;
  visibleAircraftTypeByOperatingCompanyMap: AircraftState['visibleAircraftTypeByOperatingCompanyMap'];
  visibleACTypes: number[];
  visibleOperatingCompanies: number[];
  visibleAcIds: AircraftState['visibleAcIds'];
  acTypesPositionMap: AircraftState['acTypesPositionMap'];
}
const initialVisibility = {
  airportMismatches: true,
  groundTime: true,
  emptyLegOffers: false,
  flights: true,
  fboMismatches: false,
  maintenances: true,
  maintenanceItems: false,
  availabilityNotes: true,
  generalNotes: false,
  oneWayOffers: false,
  crewAssignment: false,
  crewRoster: false,
  crewRestNotes: true,
  handoverRemarks: false,
  handoverRemarks_cs: false,
};
const initialEWOWVisibility: { [el in EmptyLegGroupType]: boolean } = {
  'Advertised One Way Offers': true,
  'Advertised Empty Leg Offers': true,
  'Unadvertised One Way Offers': false,
  'Unadvertised Empty Leg Offers': false,
  'Cancelled One Way Offers': false,
  'Cancelled Empty Leg Offers': false,
};
export const DEFAULT_USER_PREFERENCES: UserPreferences = {
  segmentsVisibility: initialVisibility,
  timeLabelDisplayMode: FlightLabelTimeType.NONE,
  groundTimeType: 'OPS' as GroundTimeType,
  mxEventPresenceType: 'ALL' as MxEventPresenceType,
  statusMap: {
    Option: true,
    Unconfirmed: true,
    Confirmed: true,
    Landed: true,
    'No Show': true,
  },
  kx: 1,
  scaleX: getInitialScale(100),
  ky: 1,
  togglersState: {},
  emptyLegMap: initialEWOWVisibility,
  isDaySeparatorOnTop: false,
  showSubCharters: false,
  showLabels: false,
  zoomLvl: '3d',
  visibleHoldAcIds: [],
  statisticsMode: 'VJ',
  isGroupedByOperator: false,
  visibleAircraftTypeByOperatingCompanyMap: VISIBLE_OPERATING_COMPANIES.reduce(
    (acc, id) => {
      return {
        ...acc,
        [id]: VISIBLE_AC_TYPES,
      };
    },
    {}
  ),
  visibleACTypes: VISIBLE_AC_TYPES,
  visibleOperatingCompanies: VISIBLE_OPERATING_COMPANIES,
  visibleAcIds: null,
  acTypesPositionMap: {},
};
