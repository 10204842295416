import { CSSProperties, MouseEvent, PureComponent } from 'react';
import { DraggableArea } from './draggableArea';
import { DraggableItem } from './draggableItem';

interface Props {
  areaStyle?: CSSProperties;
  itemStyle?: CSSProperties;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;

  isDragging: boolean;
  onDrag: (x: number, y: number) => void;
  onDragEnd: (x: number, y: number) => void;

  position: { x: number; y: number };
  offset: { x: number; y: number };
  width: number;
  height: number;

  onDragStart: (
    mousePos: { x: number; y: number },
    nodeOffset: { x: number; y: number }
  ) => void;
}

export class Draggable extends PureComponent<Props> {
  render() {
    return (
      <DraggableArea
        style={this.props.areaStyle}
        onClick={this.props.onClick}
        isDragging={this.props.isDragging}
        drag={this.props.onDrag}
        dragEnd={this.props.onDragEnd}
      >
        <DraggableItem
          isDragging={this.props.isDragging}
          position={this.props.position}
          offset={this.props.offset}
          width={this.props.width}
          height={this.props.height}
          onDragStart={this.props.onDragStart}
          style={this.props.itemStyle}
        >
          {this.props.children}
        </DraggableItem>
      </DraggableArea>
    );
  }
}
