import { axiosAuthed as axios } from '../axios-config';
import { uniqBy } from 'lodash';
import { transformToFE } from '../flight-service/flight-model';
import Flight from '../../types/flight';

const beUrl = '/integration/timeline';
const flightsRoute = '/flights';
const orderRoute = '/order';
const getFlightById: (reason: any) => Promise<Flight> = (id: number) =>
  axios.get(`${beUrl}${flightsRoute}/${id}`).then(d => {
    return d.data && transformToFE(d.data);
  });

const getFlightsByOrderId: (reason: any) => Promise<Flight[]> = (id: number) =>
  axios
    .get(`${beUrl}${flightsRoute}${orderRoute}/${id}`)
    .then(d => d.data && (d.data.map(transformToFE) as Flight[]))
    .then(flights => uniqBy(flights, 'id'));

export { getFlightById, getFlightsByOrderId };
