import * as d3S from 'd3-selection';

import * as actions from '../../../../actions';
import { isDraggableByOpStatus } from '../../../../common/flight/flight-check-status';
import { HOLD_FLIGHT_HEIGHT } from '../../../../constants';
import { getDragPosition } from '../../../../reducers/ui';
import { store as reduxStore } from '../../../../root';
import Flight from '../../../../types/flight';
import {
  leftClickHandlerCreator,
  openFlightCenter,
  openOrderWindow,
  setDataTestEntityIdD3Elements,
} from '../../../../utils';
import { getICAOLabelFromAirport } from '../../../../utils/airport';
import { hasPermission } from '../../../../utils/check-permissions';
import {
  formatDepArrTime,
  formatDuration,
  getTripProfile,
  getNextDaySign,
} from '../../../../utils/hold-line-flights';

export const getHoldFlightsD3creator = (
  d: d3S.Selection<any, any, any, any>
): d3S.Selection<any, any, any, any> => {
  const state = reduxStore.getState();
  const { airportsById } = state.airports;
  const {
    transform: { kx },
  } = state.ui;
  const hasFCPermission = hasPermission(
    state,
    'AG-Timeline-Flight-Center-View'
  );
  const hasOWPermission = hasPermission(state, 'AG-Timeline-Order-Window-View');
  const leftClickHandler = leftClickHandlerCreator(
    d => openFlightCenter((d as Flight).id),
    d => openOrderWindow({ id: (d as Flight).flightOrderId }),
    hasFCPermission,
    hasOWPermission
  );

  const container = d
    .append('div')
    .classed('label-text-hold', true)
    .style('transform', d => {
      return `translate(${d.x}px, ${d.y}px)`;
    })
    // default is 3px left padding, on scale by x axis more than 3 (super zoomed view) it will make a step for text to get out from ferry/live rect colorization
    .style('padding-left', `${kx > 3 ? kx * 1.5 : 3}px`)
    .attr(
      'data-test-entity',
      setDataTestEntityIdD3Elements('hold-flights-text-label')
    )
    .style('width', d => `${d.width}px`)
    .style('height', `${HOLD_FLIGHT_HEIGHT}px`)
    .style('color', d => `${d.color}`)
    .style('border', (d: Flight & { isSelected: boolean }) =>
      d.isSelected ? '2px solid #1890FF' : 'none'
    )
    .attr(
      'draggable',
      (d: Flight) =>
        hasPermission(state, 'AG-Timeline-Fight-Move') &&
        isDraggableByOpStatus(d.legOperationalStatusId)
    )
    .on('dragstart', function(d: Flight) {
      const event = d3S.event as DragEvent;
      const { x, y } = getDragPosition(state.ui, event);
      event.dataTransfer.setData('text', d.id.toString());
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setDragImage(new Image(10, 10), 0, 0);
      return reduxStore.dispatch(
        actions.userDragFlightsBegin({
          flightId: d.id,
          draggingPosition: {
            x,
            y,
          },
        })
      );
    })
    .on('mouseover', function(d) {
      const event = d3S.event as MouseEvent;
      d3S.select(this).style('cursor', 'pointer');
      if (!event.shiftKey && d) {
        reduxStore.dispatch(actions.userHoverFlight([d, this]));
      }
    })
    .on('contextmenu', function(d) {
      d3S.event.preventDefault();
      d3S.event.stopPropagation();
      reduxStore.dispatch(actions.userOpenFlightMenu([this, d]));
    })
    .on('mouseout', function() {
      const event = d3S.event as MouseEvent;
      const { left, right, top, bottom } = this.getBoundingClientRect();
      const { clientX, clientY } = event;
      const isOverFlight =
        clientX > left && clientX < right && clientY > top && clientY < bottom;
      if (!isOverFlight) {
        reduxStore.dispatch(actions.userCloseTooltip());
      }
    })
    .on('click', (d: Flight) => {
      const event = d3S.event as MouseEvent;
      if (event.shiftKey && isDraggableByOpStatus(d.legOperationalStatusId))
        return reduxStore.dispatch(
          actions.userToggleFlightSelection({ flightId: d.id })
        );
      if (!event.shiftKey) leftClickHandler(d);
    });
  container
    .append('span')
    .text(
      (data: Flight) =>
        `${getICAOLabelFromAirport(
          airportsById[data.departureAirportId]
        )} ${formatDepArrTime(data.start)} ${formatDuration(
          data.end - data.start
        )} ${getTripProfile(data)}`
    );
  container
    .append('span')
    .text(
      (data: Flight) =>
        ` ${formatDepArrTime(data.end)}${getNextDaySign(
          data.start,
          data.end
        )}${getICAOLabelFromAirport(airportsById[data.arrivalAirportId])}`
    );
  return container;
};
