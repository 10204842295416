import { Table, Empty } from 'antd';
import { TableProps } from 'antd/lib/table';
import { FC } from 'react';
import { useResizeColumns } from '../../../common/components/resizable-table/useResizeColumns';
import { NearestAircraftTableData } from '../../../types/nearest-aircraft';
import { NEAREST_AIRCRAFT_COLUMNS } from './columns';
import { useDispatch, useSelector } from 'react-redux';
import { userSearchLeg, userChangeSearchACQuery } from '../../../actions';
import { RootState } from '../../../reducers';

export const NearestAircraftResizableTable: FC<{
  dataSource: NearestAircraftTableData[];
  tableBodyHeight: number;
  flightTime: number;
} & TableProps<NearestAircraftTableData>> = props => {
  const { dataSource, tableBodyHeight, title, flightTime } = props;
  const dispatch = useDispatch();
  const loading = useSelector<RootState, boolean>(
    state => state.nearestAircraft.loading
  );
  const isVerticalMode = useSelector<RootState, boolean>(
    state => state.ui.isVerticalMode
  );
  const { components, endUpColumns } = useResizeColumns<
    NearestAircraftTableData
  >(NEAREST_AIRCRAFT_COLUMNS(flightTime, isVerticalMode));
  const onDoubleClick = (payload: NearestAircraftTableData): void => {
    if (payload.flightLegId) {
      dispatch(
        userSearchLeg.started({
          id: payload.flightLegId,
          isNearestAC: true,
        })
      );
    } else {
      dispatch(
        userChangeSearchACQuery({
          query: payload.tailNumber,
          isFromNearestAC: true,
          isFromHandover: false,
        })
      );
    }
  };
  return (
    <Table
      columns={endUpColumns}
      dataSource={dataSource}
      components={components}
      pagination={false}
      loading={loading}
      rowKey={record => `${record.aircraftId}_${record.tailNumber}`}
      rowClassName={(record, index) => {
        if (record.isOnSMX) {
          return 'nearest-ac-modal-body-row-red';
        }
        if (index % 2 > 0) {
          return 'nearest-ac-modal-body-row-odd';
        }
        return 'nearest-ac-modal-body-row';
      }}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No data"
            style={{ margin: '12px 0' }}
          />
        ),
      }}
      onRow={(row: NearestAircraftTableData) => {
        return {
          onDoubleClick: () => onDoubleClick(row),
        };
      }}
      title={title}
      scroll={{ y: tableBodyHeight, scrollToFirstRowOnChange: true }}
    />
  );
};
