import { PureComponent } from 'react';
import './styles.scss';
import Aircraft from '../../../types/aircraft';
import { isSubCharter } from '../../../common/aircraft/aircraft-check-status';
import { gvUrl } from '../../../services/gv-service';
import { PrimaryInfo } from './primary-info';
import SecondaryInfoConnected from './secondary-info';
import StatusesConnected from './statuses';
import { WifiComponent } from '../wifi-component';

interface Props {
  aircraft: Aircraft;
  isUnavailableForAdvertisement: boolean;
  baseCompaniesIds: number[];
  hasTailHandover: boolean;
}

export default class AircraftContent extends PureComponent<Props> {
  render() {
    const {
      aircraft,
      isUnavailableForAdvertisement,
      baseCompaniesIds,
      hasTailHandover,
    } = this.props;
    return (
      <div
        className="aircraft-content"
        style={{
          marginLeft: isSubCharter(aircraft, baseCompaniesIds) ? 20 : 0,
        }}
      >
        <PrimaryInfo
          aircraft={aircraft}
          isUnavailableForAdvertisement={isUnavailableForAdvertisement}
        />
        <SecondaryInfoConnected
          aircraft={aircraft}
          baseCompaniesIds={baseCompaniesIds}
          isUnavailableForAdvertisement={isUnavailableForAdvertisement}
          hasTailHandover={hasTailHandover}
        />
        <StatusesConnected
          aircraft={aircraft}
          gvUrl={gvUrl}
          baseCompaniesIds={baseCompaniesIds}
        />
        <WifiComponent aircraft={aircraft} />
      </div>
    );
  }
}
