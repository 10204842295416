import { gql } from '@apollo/client';
import { apolloClient } from '../../data-processing/graphql';
import { PeakDayDtoInput } from '../../types/operation-result-types';

const savePeakDay = require('./save-peak-day.gql');

export function savePeakDayMutation(day: PeakDayDtoInput) {
  return apolloClient.mutate({
    mutation: gql(savePeakDay),
    variables: {
      day,
    },
  });
}
