import { sendErrorToRaygun } from '../../utils/raygun-middleware';
import { store as reduxStore } from '../../root';
import { doSetFirstError, doUpdateErrorDialog } from '../../actions';
import { GraphQLError } from 'graphql';
import { AxiosError } from 'axios';
import { HandoverError } from '../../types/handover-remarks';

export const handleError = (args: {
  content: string;
  error: Error;
  message: string;
  url?: string;
}): void => {
  const { content, error, message, url } = args;
  const reduxState = reduxStore.getState();
  const {
    errorState: { errorCount, fullTitle },
  } = reduxState;
  if (errorCount >= 1 && fullTitle.includes(message)) {
    return;
  }
  const withUrl = url ? `${content}\nURL: ${url}` : content;
  const removeBearer = withUrl.replace(/"?Bearer [\w|.|-]+/gim, '...');
  sendErrorToRaygun(error, url, removeBearer);
  reduxStore.dispatch(
    errorCount === 0
      ? doSetFirstError({ content: removeBearer, title: message })
      : doUpdateErrorDialog({ content: removeBearer, title: message })
  );
};

export const handleGraphQlErrors = (errors: readonly GraphQLError[]): void => {
  errors.forEach(error => {
    const { extensions, message, stack, name, path } = error;
    const content = JSON.stringify(error);
    handleError({
      error,
      content: stack || content,
      message: `GraphQL Error: ${message}: ${name || extensions.code}`,
      url: path.map(p => p).join('/'),
    });
  });
};

const isHandoverError = (e: AxiosError): e is HandoverError =>
  !!(e as HandoverError).details;

export const handleAxiosError = (
  error: AxiosError,
  method: 'get' | 'post' | 'delete',
  url: string,
  message?: string,
  variables?: string
): void => {
  if (isHandoverError(error)) {
    const failedTo = () => {
      switch (method) {
        case 'delete':
          return 'remove';
        case 'get':
          return 'load';
        case 'post': {
          return /email/i.test(variables) ? 'send email with' : 'save';
        }
      }
    };
    handleError({
      error,
      message: `Failed to ${failedTo()} Handover remark(s). Error: ${
        error.details.message
      }`,
      content: `Error name: ${error.name}\nMessage: ${error.message}.\nDetailed message: ${error.details.message}.\nStatus code ${error.details.statusCode}\nCode ${error.details.code}`,
      url,
    });
  } else {
    handleError({
      error,
      content: JSON.stringify({
        ...error.toJSON(),
        ...error?.response,
      }).replace(/Bearer [\w|.|-]+/im, '...'),
      message: message || error.message,
      url,
    });
  }
};
