import { PureComponent } from 'react';
import { Radio, Tooltip } from 'antd';
import { FlightLabelTimeType } from '../../../types/flight';
import { GroundTimeType } from '../../../types/ground-time';
import { RadioChangeEvent } from 'antd/lib/radio';
import { connect, DispatchProp } from 'react-redux';
import { RootState } from '../../../reducers';
import * as actions from '../../../actions';
import { createDelayedForScrollConnect } from '../../../utils/create-delayed-for-scroll-connect';
import { getButtonSize } from '../utils';
import { AnyAction } from 'redux';
import { MxEventPresenceType } from '../../../types/aircraft';

interface StateProps {
  timeLabelDisplayMode: FlightLabelTimeType;
  groundTimeType: GroundTimeType;
  mxEventPresenceType: MxEventPresenceType;
  isVerticalMode: boolean;
}

class FilterTopBarLeft extends PureComponent<
  StateProps & DispatchProp<AnyAction>
> {
  onChangeGroundTime = (e: RadioChangeEvent): void => {
    const value = e.target.value as GroundTimeType;
    this.props.dispatch(actions.userSetGroundTimeType(value));
  };
  onChangeTimeLabelView = (e: RadioChangeEvent) =>
    this.props.dispatch(
      actions.userToggleTimeLabelView(e.target.value as FlightLabelTimeType)
    );
  onChangeMxEventPresence = (e: RadioChangeEvent) => {
    this.props.dispatch(
      actions.userToggleAircraftByMxEvents(
        e.target.value as MxEventPresenceType
      )
    );
  };
  render() {
    const {
      groundTimeType,
      timeLabelDisplayMode,
      mxEventPresenceType,
      isVerticalMode,
    } = this.props;
    return (
      <>
        <div className="filter-bar-ground-time-label">Ground time</div>
        <Radio.Group
          onChange={this.onChangeGroundTime}
          value={groundTimeType}
          size={getButtonSize(isVerticalMode)}
        >
          <Radio.Button value={'OPS'}>OPS</Radio.Button>
          <Radio.Button value={'CPL'}>CPL</Radio.Button>
          <Radio.Button value={'FBO'}>FBO</Radio.Button>
          <Radio.Button value={'NONE'}>Don't show</Radio.Button>
        </Radio.Group>

        <div
          className="filter-bar-times-label"
          style={{
            marginLeft: isVerticalMode ? '0' : '40px',
          }}
        >
          Dep. & Arr. time
        </div>
        <Radio.Group
          onChange={this.onChangeTimeLabelView}
          value={timeLabelDisplayMode}
          size={getButtonSize(isVerticalMode)}
        >
          <Radio.Button value={'Estimated'}>EST</Radio.Button>
          <Radio.Button value={'Scheduled'}>SCH</Radio.Button>
          <Radio.Button value={'None'}>Don't show</Radio.Button>
        </Radio.Group>

        <Radio.Group
          onChange={this.onChangeMxEventPresence}
          value={mxEventPresenceType}
          size={getButtonSize(isVerticalMode)}
          style={{
            marginLeft: isVerticalMode ? '5px' : '40px',
          }}
        >
          <Tooltip title="Tail on MX">
            <Radio.Button value={'MX'}>MX</Radio.Button>
          </Tooltip>
          <Tooltip title="Available tails">
            <Radio.Button value={'RTS'}>RTS</Radio.Button>
          </Tooltip>
          <Tooltip title="All fleet">
            <Radio.Button value={'ALL'}>All</Radio.Button>
          </Tooltip>
        </Radio.Group>
      </>
    );
  }
}

const advancedMap = createDelayedForScrollConnect((state: RootState) => ({
  timeLabelDisplayMode: state.ui.timeLabelDisplayMode,
  groundTimeType: state.timelineEvents.groundTimeType,
  mxEventPresenceType: state.ui.mxEventPresenceType,
  isVerticalMode: state.ui.isVerticalMode,
}));

export const FilterTopBarLeftConnected = connect(advancedMap)(FilterTopBarLeft);
