import styled from 'styled-components';
import { Checkbox, Menu, MenuProps, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import React, { FC, useState } from 'react';
import {
  FilterByAircraft,
  FilterByAircraftType,
  FilterByOperatingCompany,
} from '../../../types/aircraft';
import { setDataTestEntityId } from '../../../utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';

type MenuItem = Required<MenuProps>['items'][number];

const AcTypeItemStyled = styled.div<{ isVerticalMode: boolean }>`
  .sub-menu-actype .ant-menu-submenu-title {
    width: ${p => (p.isVerticalMode ? '142px' : '150px')};
  }
`;

const acTypeItem = (
  type: FilterByAircraftType,
  companyId: number,
  companyName: string,
  classNamePrefix: string,
  isLastOne: boolean,
  onSelectAcType: (
    event: CheckboxChangeEvent,
    payload: {
      companyId: number;
      acTypeId: number;
    }
  ) => void,
  isVerticalMode: boolean
): React.ReactNode => {
  const checked = type.checked && type.tails.every(s => s.checked);
  const onClick = e => e.stopPropagation();
  const onChange = (e: CheckboxChangeEvent) => {
    onSelectAcType(e, {
      companyId,
      acTypeId: type.id,
    });
  };
  const indeterminate = type.checked && type.tails.some(s => !s.checked);
  return (
    <AcTypeItemStyled isVerticalMode={isVerticalMode}>
      <Checkbox
        checked={checked}
        onClick={onClick}
        onChange={onChange}
        indeterminate={indeterminate}
        id={`${companyName}-${type.code}`}
        {...setDataTestEntityId(`ac-type-filter-${type.name}`)}
      />
      <Tooltip
        title={type.name || 'N/A'}
        key={type.id}
        placement={isLastOne ? 'left' : 'right'}
      >
        <label
          htmlFor={`${companyName}-${type.code}`}
          className={`${classNamePrefix}actypes-content-companies-column-types-menu-type-label`}
          onClick={onClick}
        >
          {type.code}
        </label>
      </Tooltip>
    </AcTypeItemStyled>
  );
};

export const TailsByAcTypeOnOperatorList: FC<{
  classNamePrefix: string;
  company: FilterByOperatingCompany;
  filterByAircraftType: FilterByAircraftType[];
  onSelectAcType: (
    event: CheckboxChangeEvent,
    payload: {
      companyId: number;
      acTypeId: number;
    }
  ) => void;
  onSelectTail: (
    event: CheckboxChangeEvent,
    payload: {
      companyId: number;
      acTypeId: number;
      acId: number;
    }
  ) => void;
  isLastOne: boolean;
}> = ({
  classNamePrefix,
  company,
  filterByAircraftType,
  onSelectAcType,
  onSelectTail,
  isLastOne,
}) => {
  const [openKeys, setOpenedKeys] = useState<string[]>([]);
  const onOpenChange = (openKeys: string[]) => {
    setOpenedKeys(openKeys.length > 0 ? [openKeys[openKeys.length - 1]] : []);
  };
  const isVerticalMode = useSelector<RootState, boolean>(
    state => state.ui.isVerticalMode
  );
  const tailItem = (
    tail: FilterByAircraft,
    acTypeId: number
  ): React.ReactNode => (
    <>
      <Checkbox
        checked={tail.checked}
        onChange={e =>
          onSelectTail(e, {
            companyId: company.id,
            acTypeId,
            acId: tail.id,
          })
        }
        {...setDataTestEntityId(`ac-type-filter-${tail.name}`)}
      >
        {tail.name}
      </Checkbox>
    </>
  );
  const items: MenuItem[] = filterByAircraftType.map(type => ({
    className: 'sub-menu-actype',
    key: `${company.id}-${type.code}`,
    label: acTypeItem(
      type,
      company.id,
      company.name,
      classNamePrefix,
      isLastOne,
      onSelectAcType,
      isVerticalMode
    ),
    children: type.tails.map<MenuItem>(tail => ({
      className: 'sub-menu-tail',
      key: tail.id,
      label: tailItem(tail, type.id),
    })),
  }));

  const selectedKeys = filterByAircraftType.reduce((acc, type) => {
    return type.checked ? acc.concat(`${company.id}-${type.code}`) : acc;
  }, []);
  return (
    <div className={`${classNamePrefix}actypes-content-companies-column-types`}>
      <Menu
        mode="inline"
        className={`${classNamePrefix}actypes-content-companies-column-types-menu`}
        onOpenChange={onOpenChange}
        openKeys={openKeys}
        items={items}
        inlineIndent={0}
        triggerSubMenuAction="click"
        selectable
        selectedKeys={selectedKeys}
      />
    </div>
  );
};
