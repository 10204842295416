const ALL_APPS_QUERY = `
        {
          apps {
            id
            name
            domain
            url
          }
        }
      `;

export default ALL_APPS_QUERY;
