import { isEqual } from 'lodash';
import { PureComponent } from 'react';
import { refreshTimer } from '../../actions';
import { getSlotStyle } from '../../common/flight/flight-colorization';
import { getSlotInfo } from '../../common/flight/flight-labels';
import { subMiddle } from '../../root';
import Flight from '../../types/flight';
import StatusRow from './status-row';

interface Props {
  selectedFlight: Flight;
}
interface State {
  depSlotStyle: string;
  arrSlotStyle: string;
  flightId: number;
}

class SlotsRow extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = SlotsRow.getUpdatedState(this.props.selectedFlight);
  }
  getDepSlots() {
    const { selectedFlight } = this.props;
    return getSlotInfo(selectedFlight, selectedFlight.departureAirportId);
  }
  getArrSlots() {
    const { selectedFlight } = this.props;
    return getSlotInfo(selectedFlight, selectedFlight.arrivalAirportId);
  }
  updateState = () => {
    const updated = SlotsRow.getUpdatedState(this.props.selectedFlight);
    if (!isEqual(this.state, updated)) {
      this.setState(updated);
    }
  };
  toogleUpdateSubscription(mode: 'on' | 'off') {
    subMiddle[mode](refreshTimer, this.updateState);
  }
  componentDidMount() {
    this.toogleUpdateSubscription('on');
  }
  componentWillUnmount() {
    this.toogleUpdateSubscription('off');
  }
  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.selectedFlight.id !== state.flightId) {
      return SlotsRow.getUpdatedState(props.selectedFlight);
    }
    return null;
  }
  static getUpdatedState(flight: Flight) {
    return {
      flightId: flight.id,
      depSlotStyle: getSlotStyle(flight, flight.departureAirportId),
      arrSlotStyle: getSlotStyle(flight, flight.arrivalAirportId),
    };
  }
  render() {
    const {
      arrivalSlotMismatch,
      departureSlotMismatch,
    } = this.props.selectedFlight;
    return (
      <>
        <div className="ft-slots">
          {departureSlotMismatch && (
            <div className="ft-slot-mismatch-left">
              <div className="ft-slot-mismatch-in" />
            </div>
          )}
          <div className="ft-slot-label">Slots</div>
          {arrivalSlotMismatch && (
            <div className="ft-slot-mismatch-right">
              <div className="ft-slot-mismatch-in" />
            </div>
          )}
        </div>
        <StatusRow
          arrText={this.getArrSlots()}
          depText={this.getDepSlots()}
          arrClassName={this.state.arrSlotStyle}
          depClassName={this.state.depSlotStyle}
        />
      </>
    );
  }
}

export default SlotsRow;
