import { chain, differenceBy, negate, sortBy, uniqBy } from 'lodash';
import { utc } from 'moment';
import createCachedSelector from 're-reselect';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { RootState } from '.';
import * as actions from '../actions';
import { CrewAssignment, CrewDuty } from '../types/crew-roster';
import EmptyLegOffer from '../types/empty-leg-offer';
import { OverlappedElements } from '../types/event-element';
import Flight from '../types/flight';
import Maintenance, { isMaintenance } from '../types/maintenance';
import MaintenanceItem from '../types/maintenance-item';
import Note, { AvailabilityNote, GeneralNote } from '../types/note';
import { OneWayOffer } from '../types/one-way-offer';
import {
  getAircraftIndexMapExcludingHolding,
  getAircraftByIdMap,
} from '../selectors';
import {
  Pool,
  mergePools,
  createPool,
  reducePoolByBatchUpdate,
  reduceListByBatchUpdate,
  reduceNotePoolByBatchUpdate,
} from '../data-processing/pool-structure';
import { GroundTimeType } from '../types/ground-time';
import { getTimeSegments } from '../utils/time';
import { getVisibleElements, getFoundFlights } from '../selectors';
import { isFlight } from '../common/flight/flight-check-status';
import {
  InitialLoadingAllRequestsMap,
  AllRequestsType,
} from '../types/segment-types';
import { DEFAULT_USER_PREFERENCES } from '../types/user-preferences';
import {
  getOverlappedElements,
  getOverlappedFlights,
} from '../utils/event-element';
import { getMergedMaintenanceItems } from '../utils/maintenance-item';
import { getMergedNotes, isNoteGeneral } from '../utils/note';
import { InputSourceIdType } from '../types/input-source';
import { MxEventPresenceType } from '../types/aircraft';

interface EventsStateVistaJet {
  flights: Pool<Flight>;
  availabilityNotes: Pool<AvailabilityNote>;
  generalNotes: Pool<GeneralNote>;
  emptyLegOffers: Pool<EmptyLegOffer>;
  oneWayOffers: Pool<OneWayOffer>;
  maintenances: Pool<Maintenance>;
  maintenanceItems: MaintenanceItem[];
  crewRoster: CrewDuty[];
  crewAssignment: CrewAssignment[];
  groundTimeType: GroundTimeType;
  mxEventPresenceType: MxEventPresenceType;
  fetchedDaysMap: { [day: number]: boolean };
  peakDaysLoadingTime: number;
  aircraftLoadingTime: number;
  airportsLoadingTime: number;
  baseCompaniesLoadingTime: number;
  timeZonesLoadingTime: number;
  oneWayOffersLoadingTime: number;
  emptyLegOffersLoadingTime: number;
  flightsLoadingTime: number;
  notesLoadingTime: number;
  crewAssignmentLoadingTime: number;
  crewRosterLoadingTime: number;
  maintenancesLoadingTime: number;
  maintenanceItemsLoadingTime: number;
  isReIniting: boolean;
}

interface CommonDataLoadingLogger {
  startingInitialLoadingTime: number;
  totalInitialLoadingTime: number;
  totalSegmentsFetchingByDaysRangeLoadingTime: number;
  initialLoadingAllRequestsMap: InitialLoadingAllRequestsMap;
  hasInitialLoadingDone: boolean;
}

export type TimelineEventsState = EventsStateVistaJet & CommonDataLoadingLogger;

const initialStateVistaJet: EventsStateVistaJet = {
  flights: [],
  availabilityNotes: [],
  generalNotes: [],
  emptyLegOffers: [],
  oneWayOffers: [],
  maintenances: [],
  maintenanceItems: [],
  crewRoster: [],
  crewAssignment: [],
  groundTimeType: 'OPS',
  mxEventPresenceType: 'ALL',
  fetchedDaysMap: {},
  peakDaysLoadingTime: 0,
  aircraftLoadingTime: 0,
  airportsLoadingTime: 0,
  baseCompaniesLoadingTime: 0,
  timeZonesLoadingTime: 0,
  oneWayOffersLoadingTime: 0,
  emptyLegOffersLoadingTime: 0,
  flightsLoadingTime: 0,
  notesLoadingTime: 0,
  crewAssignmentLoadingTime: 0,
  crewRosterLoadingTime: 0,
  maintenancesLoadingTime: 0,
  maintenanceItemsLoadingTime: 0,
  isReIniting: false,
};

const initialLoadingAllRequestsMap: InitialLoadingAllRequestsMap = {
  airports: false,
  aircraft: false,
  timeZones: false,
  baseCompanies: false,
  oneWayOffers: false,
  emptyLegOffers: false,
  flights: false,
  maintenances: false,
  maintenanceItems: false,
  peakDays: false,
  notes: false,
  crewAssignment: false,
  crewRoster: false,
};

export const initialState: TimelineEventsState = {
  ...initialStateVistaJet,
  startingInitialLoadingTime: 0,
  totalSegmentsFetchingByDaysRangeLoadingTime: 0,
  totalInitialLoadingTime: 0,
  initialLoadingAllRequestsMap,
  hasInitialLoadingDone: false,
};

export const checkingDisplayedMEL = (mel: MaintenanceItem) =>
  mel.isActive && mel.clearedTimestamp === null;

const getTotalInitialLoadingTime = (
  segmentType: AllRequestsType,
  params: {
    initialLoadingAllRequestsMap: InitialLoadingAllRequestsMap;
    hasInitialLoadingDone: boolean;
    startingInitialLoadingTime: number;
  }
) => {
  const { hasInitialLoadingDone } = params;
  if (hasInitialLoadingDone) return params;
  const { initialLoadingAllRequestsMap, startingInitialLoadingTime } = params;
  const nextAllRequestMap = {
    ...initialLoadingAllRequestsMap,
    [segmentType]: true,
  };
  const nextHasInitialLoadingDone = Object.keys(nextAllRequestMap).every(
    k => nextAllRequestMap[k]
  );
  return {
    initialLoadingAllRequestsMap: nextAllRequestMap,
    hasInitialLoadingDone: nextHasInitialLoadingDone,
    totalInitialLoadingTime: nextHasInitialLoadingDone
      ? new Date().valueOf() - startingInitialLoadingTime
      : 0,
  };
};

export default reducerWithInitialState(initialState)
  .case(actions.receivedUserAndRoles, (state, payload) => {
    const { roles } = payload;
    const oneWayOffers = !(roles.indexOf('AG-Timeline-View-OW-Offer') > -1);
    const emptyLegOffers = !(roles.indexOf('AG-Timeline-View-EL-Offer') > -1);
    // setting initial loading OW/EL by roles (needed for reconnecting websocket)
    initialLoadingAllRequestsMap['oneWayOffers'] = oneWayOffers;
    initialLoadingAllRequestsMap['emptyLegOffers'] = emptyLegOffers;
    return {
      ...state,
      initialLoadingAllRequestsMap: {
        ...state.initialLoadingAllRequestsMap,
        oneWayOffers,
        emptyLegOffers,
      },
    };
  })
  .cases(
    [
      actions.doAirportsMappersFetch.started,
      actions.doTimezonesMappersFetch.started,
      actions.doGetBaseCompanies.started,
      actions.doGetPeakDays.started,
      actions.doAircraftMappersFetch.started,
      actions.doMaintenanceItemsMappersFetch.started,
    ],
    state => {
      const { startingInitialLoadingTime } = state;
      return {
        ...state,
        startingInitialLoadingTime:
          startingInitialLoadingTime === 0
            ? new Date().valueOf()
            : startingInitialLoadingTime,
      };
    }
  )
  .case(actions.getSegmentRequest.started, (state, payload) => {
    const { startingInitialLoadingTime } = state;
    const { segmentType } = payload;
    return {
      ...state,
      startingInitialLoadingTime:
        startingInitialLoadingTime === 0
          ? new Date().valueOf()
          : state.startingInitialLoadingTime,
      [`${
        segmentType === 'availabilityNotes' ? 'notes' : `${segmentType}`
      }LoadingTime`]: 0,
    };
  })
  .caseWithAction(actions.getSegmentRequest.done, (state, action) => {
    const { payload, meta } = action;
    const { result = [], params } = payload;
    const { segmentType } = params;
    const {
      hasInitialLoadingDone,
      initialLoadingAllRequestsMap,
      startingInitialLoadingTime,
    } = state;
    switch (segmentType) {
      case 'availabilityNotes': {
        const notesLoadingTime =
          meta.notesLoadingTime || state.notesLoadingTime;
        return {
          ...state,
          availabilityNotes: mergePools(
            state.availabilityNotes,
            createPool(
              result.filter(
                (n: Note) => !isNoteGeneral(n) && n.isActive
              ) as AvailabilityNote[]
            )
          ),
          generalNotes: mergePools(
            state.generalNotes,
            createPool(
              result.filter(
                (n: Note) => isNoteGeneral(n) && n.isActive
              ) as GeneralNote[]
            )
          ),
          notesLoadingTime,
          ...getTotalInitialLoadingTime('notes', {
            initialLoadingAllRequestsMap,
            hasInitialLoadingDone,
            startingInitialLoadingTime,
          }),
        };
      }
      case 'crewAssignment': {
        const crewAssignmentLoadingTime =
          meta.crewAssignmentLoadingTime || state.crewAssignmentLoadingTime;
        return {
          ...state,
          [segmentType]: uniqBy(
            state[segmentType].concat(result as CrewAssignment[]),
            'id'
          ),
          crewAssignmentLoadingTime,
          ...getTotalInitialLoadingTime('crewAssignment', {
            initialLoadingAllRequestsMap,
            hasInitialLoadingDone,
            startingInitialLoadingTime,
          }),
        };
      }
      case 'crewRoster': {
        const crewRosterLoadingTime =
          meta.crewRosterLoadingTime || state.crewRosterLoadingTime;
        return {
          ...state,
          [segmentType]: uniqBy(
            state[segmentType].concat(result as CrewDuty[]),
            'id'
          ),
          crewRosterLoadingTime,
          ...getTotalInitialLoadingTime('crewRoster', {
            initialLoadingAllRequestsMap,
            hasInitialLoadingDone,
            startingInitialLoadingTime,
          }),
        };
      }
      case 'emptyLegOffers': {
        const emptyLegOffersLoadingTime =
          meta.emptyLegOffersLoadingTime || state.emptyLegOffersLoadingTime;
        return {
          ...state,
          [segmentType]: mergePools(
            state[segmentType],
            createPool(result as EmptyLegOffer[])
          ),
          emptyLegOffersLoadingTime,
          ...getTotalInitialLoadingTime('emptyLegOffers', {
            initialLoadingAllRequestsMap,
            hasInitialLoadingDone,
            startingInitialLoadingTime,
          }),
        };
      }
      case 'flights': {
        const flightsLoadingTime =
          meta.flightsLoadingTime || state.flightsLoadingTime;
        const flights = mergePools(
          state.flights,
          createPool(result as Flight[])
        );
        return {
          ...state,
          flights,
          flightsLoadingTime,
          ...getTotalInitialLoadingTime('flights', {
            initialLoadingAllRequestsMap,
            hasInitialLoadingDone,
            startingInitialLoadingTime,
          }),
        };
      }
      case 'maintenances': {
        const maintenancesLoadingTime =
          meta.maintenancesLoadingTime || state.maintenancesLoadingTime;
        const maintenances = mergePools(
          state.maintenances,
          createPool(result as Maintenance[])
        );
        return {
          ...state,
          maintenances,
          maintenancesLoadingTime,
          ...getTotalInitialLoadingTime('maintenances', {
            initialLoadingAllRequestsMap,
            hasInitialLoadingDone,
            startingInitialLoadingTime,
          }),
        };
      }
      case 'oneWayOffers': {
        const oneWayOffersLoadingTime =
          meta.oneWayOffersLoadingTime || state.oneWayOffersLoadingTime;
        return {
          ...state,
          [segmentType]: mergePools(
            state[segmentType],
            createPool(result as OneWayOffer[])
          ),
          oneWayOffersLoadingTime,
          ...getTotalInitialLoadingTime('oneWayOffers', {
            initialLoadingAllRequestsMap,
            hasInitialLoadingDone,
            startingInitialLoadingTime,
          }),
        };
      }
    }
    return state;
  })
  .case(actions.userSearchOrder.done, (state, payload) => {
    if (!payload.result.length) {
      return state;
    }
    const milliseconds = payload.result.map(f =>
      utc(f.start)
        .startOf('d')
        .valueOf()
    );
    const flightsWithEmptyNewDays = uniqBy(
      [
        ...state.flights,
        ...milliseconds.map(m => ({
          day: m,
          pool: [],
        })),
      ],
      d => d.day
    );
    const withNewFlights = flightsWithEmptyNewDays.map(d => {
      const suitableFlights = payload.result.filter(
        f =>
          f.start >= d.day &&
          utc(d.day)
            .add(1, 'd')
            .valueOf() <= f.start
      );
      if (suitableFlights.length == 0) return d;
      const newPool = d.pool.concat(suitableFlights);
      return {
        day: d.day,
        pool: newPool,
      };
    });
    return {
      ...state,
      flights: withNewFlights,
    };
  })
  .case(actions.userSearchLeg.done, (state, payload) => {
    if (!payload.result) return state;
    const firstDayMilliseconds = utc(payload.result.start)
      .startOf('d')
      .valueOf();
    const newFlights = [
      ...state.flights,
      { day: firstDayMilliseconds, pool: [payload.result] },
    ];
    return {
      ...state,
      flights: newFlights,
    };
  })
  .case(actions.userSetGroundTimeType, (state, groundTimeType) => {
    return {
      ...state,
      groundTimeType,
    };
  })
  .case(actions.wsUpdateBatch, (state, { data }) => {
    if (data.availabilityEvents) return state;
    const {
      flights: flightsUpdateData,
      oneWayOffers: oneWayOffersUpdateData,
      emptyLegOffers: emptyLegOffersUpdateData,
      availabilityNotes: availabilityNotesUpdateData,
      generalNotes: generalNotesUpdateData,
      maintenanceItems: maintenanceItemsUpdateData,
      maintenances: maintenancesUpdateData,
      crewRoster: crewRosterUpdateData,
      crewAssignment: crewAssignmentUpdateData,
    } = data;

    let {
      flights,
      oneWayOffers,
      emptyLegOffers,
      availabilityNotes,
      generalNotes,
      maintenanceItems,
      maintenances,
      crewRoster,
      crewAssignment,
    } = state;
    if (flightsUpdateData) {
      flights = reducePoolByBatchUpdate(flights, flightsUpdateData);
    }
    if (oneWayOffersUpdateData) {
      oneWayOffers = reducePoolByBatchUpdate(
        oneWayOffers,
        oneWayOffersUpdateData
      );
    }
    if (emptyLegOffersUpdateData) {
      emptyLegOffers = reducePoolByBatchUpdate(
        emptyLegOffers,
        emptyLegOffersUpdateData
      );
    }
    if (availabilityNotesUpdateData) {
      availabilityNotes = reduceNotePoolByBatchUpdate<AvailabilityNote>(
        availabilityNotes,
        availabilityNotesUpdateData,
        negate(isNoteGeneral)
      );
      generalNotes = reduceNotePoolByBatchUpdate<GeneralNote>(
        generalNotes,
        availabilityNotesUpdateData,
        isNoteGeneral
      );
    }
    if (generalNotesUpdateData) {
      generalNotes = reduceNotePoolByBatchUpdate<GeneralNote>(
        generalNotes,
        generalNotesUpdateData,
        isNoteGeneral
      );
      availabilityNotes = reduceNotePoolByBatchUpdate<AvailabilityNote>(
        availabilityNotes,
        generalNotesUpdateData,
        negate(isNoteGeneral)
      );
    }
    if (maintenanceItemsUpdateData) {
      const mapMaintenanceItems = new Map();
      maintenanceItems.forEach(item => {
        mapMaintenanceItems.set(`${item.id}`, item);
      });
      const updateData = Object.keys(maintenanceItemsUpdateData).reduce<
        Map<string, MaintenanceItem>
      >((acc, key) => {
        if (!maintenanceItemsUpdateData[key]) {
          acc.delete(key);
        } else {
          acc.set(key, maintenanceItemsUpdateData[key]);
        }
        return acc;
      }, mapMaintenanceItems);
      maintenanceItems = [...updateData.values()];
    }
    if (maintenancesUpdateData) {
      maintenances = reducePoolByBatchUpdate(
        maintenances,
        maintenancesUpdateData
      );
    }
    if (crewRosterUpdateData) {
      crewRoster = reduceListByBatchUpdate(crewRoster, crewRosterUpdateData);
    }
    if (crewAssignmentUpdateData) {
      crewAssignment = reduceListByBatchUpdate(
        crewAssignment,
        crewAssignmentUpdateData
      );
    }

    return {
      ...state,
      flights,
      oneWayOffers,
      emptyLegOffers,
      availabilityNotes,
      generalNotes,
      maintenanceItems,
      maintenances,
      crewRoster,
      crewAssignment,
    };
  })
  .caseWithAction(actions.doGetPeakDays.done, (state, action) => ({
    ...state,
    peakDaysLoadingTime: action.meta.peakDaysLoadingTime,
    ...getTotalInitialLoadingTime('peakDays', {
      initialLoadingAllRequestsMap: state.initialLoadingAllRequestsMap,
      hasInitialLoadingDone: state.hasInitialLoadingDone,
      startingInitialLoadingTime: state.startingInitialLoadingTime,
    }),
  }))
  .caseWithAction(actions.doAircraftMappersFetch.done, (state, action) => ({
    ...state,
    aircraftLoadingTime: action.meta.aircraftLoadingTime,
    ...getTotalInitialLoadingTime('aircraft', {
      initialLoadingAllRequestsMap: state.initialLoadingAllRequestsMap,
      hasInitialLoadingDone: state.hasInitialLoadingDone,
      startingInitialLoadingTime: state.startingInitialLoadingTime,
    }),
  }))
  .caseWithAction(actions.doAirportsMappersFetch.done, (state, action) => ({
    ...state,
    airportsLoadingTime: action.meta.airportsLoadingTime,
    ...getTotalInitialLoadingTime('airports', {
      initialLoadingAllRequestsMap: state.initialLoadingAllRequestsMap,
      hasInitialLoadingDone: state.hasInitialLoadingDone,
      startingInitialLoadingTime: state.startingInitialLoadingTime,
    }),
  }))
  .caseWithAction(actions.doGetBaseCompanies.done, (state, action) => ({
    ...state,
    baseCompaniesLoadingTime: action.meta.baseCompaniesLoadingTime,
    ...getTotalInitialLoadingTime('baseCompanies', {
      initialLoadingAllRequestsMap: state.initialLoadingAllRequestsMap,
      hasInitialLoadingDone: state.hasInitialLoadingDone,
      startingInitialLoadingTime: state.startingInitialLoadingTime,
    }),
  }))
  .caseWithAction(
    actions.doMaintenanceItemsMappersFetch.done,
    (state, { payload, meta }) => ({
      ...state,
      maintenanceItems: uniqBy(
        state.maintenanceItems.concat(
          payload.result.filter(checkingDisplayedMEL)
        ),
        'id'
      ),
      maintenanceItemsLoadingTime:
        meta.maintenanceItemsLoadingTime || state.maintenanceItemsLoadingTime,
      ...getTotalInitialLoadingTime('maintenanceItems', {
        initialLoadingAllRequestsMap: state.initialLoadingAllRequestsMap,
        hasInitialLoadingDone: state.hasInitialLoadingDone,
        startingInitialLoadingTime: state.startingInitialLoadingTime,
      }),
    })
  )
  .caseWithAction(actions.doTimezonesMappersFetch.done, (state, action) => ({
    ...state,
    timeZonesLoadingTime: action.meta.timeZonesLoadingTime,
    ...getTotalInitialLoadingTime('timeZones', {
      initialLoadingAllRequestsMap: state.initialLoadingAllRequestsMap,
      hasInitialLoadingDone: state.hasInitialLoadingDone,
      startingInitialLoadingTime: state.startingInitialLoadingTime,
    }),
  }))
  .case(
    actions.setReInitLoadingDataOnWebSocketConnectionLost.started,
    state => {
      const {
        groundTimeType,
        mxEventPresenceType,
        crewAssignment,
        crewRoster,
        emptyLegOffers,
        oneWayOffers,
        availabilityNotes,
        flights,
        generalNotes,
        maintenances,
        maintenanceItems,
      } = state;
      return {
        ...initialState,
        crewRoster,
        crewAssignment,
        emptyLegOffers,
        oneWayOffers,
        groundTimeType,
        mxEventPresenceType,
        availabilityNotes,
        maintenanceItems,
        flights,
        generalNotes,
        maintenances,
        isReIniting: true,
      };
    }
  )
  .case(actions.setReInitLoadingDataOnWebSocketConnectionLost.done, state => {
    return {
      ...state,
      isReIniting: false,
    };
  })
  .case(actions.doGetUserPreferences.done, (state, { result }) => ({
    ...state,
    groundTimeType: result.groundTimeType,
    mxEventPresenceType: result.mxEventPresenceType,
  }))
  .case(actions.userResetPreferences, state => ({
    ...state,
    groundTimeType: DEFAULT_USER_PREFERENCES.groundTimeType,
    mxEventPresenceType: DEFAULT_USER_PREFERENCES.mxEventPresenceType,
  }))
  .case(
    actions.doSegmentsFetchingByDaysRange.started,
    (state, { fetchedDays, totalSegmentsFetchingByDaysRangeLoadingTime }) => {
      return {
        ...state,
        fetchedDaysMap:
          fetchedDays && fetchedDays.length
            ? (fetchedDays as { start: number; end: number }[]).reduce(
                (acc, { start, end }) => {
                  return {
                    ...acc,
                    ...getUpdatedMap(state.fetchedDaysMap, start, end),
                  };
                },
                state.fetchedDaysMap
              )
            : state.fetchedDaysMap,
        totalSegmentsFetchingByDaysRangeLoadingTime,
      };
    }
  )
  .case(actions.doSegmentsFetchingByDaysRange.done, (state, payload) => {
    if (!state.hasInitialLoadingDone) return state;
    return {
      ...state,
      totalSegmentsFetchingByDaysRangeLoadingTime:
        payload.result.totalSegmentsFetchingByDaysRangeLoadingTime,
    };
  })
  .case(actions.doSegmentsFetchingByDaysRange.failed, (state, payload) => {
    const { fetchedDays } = payload as any;
    return {
      ...state,
      fetchedDaysMap:
        fetchedDays && fetchedDays.length
          ? getRemovedFetchedDaysMap(state.fetchedDaysMap, fetchedDays)
          : state.fetchedDaysMap,
      totalSegmentsFetchingByDaysRangeLoadingTime: 0,
    };
  });

function getFoundFetchedDays(
  fetchedDaysMap: { [day: number]: boolean },
  start: moment.Moment,
  end: moment.Moment
): number[] {
  let days = [] as number[];
  do {
    days.push(start.startOf('day').valueOf());
    start.add(1, 'day');
  } while (start.endOf('day').valueOf() <= end.valueOf());
  return days.filter(day => fetchedDaysMap[day]);
}

function getRemovedFetchedDaysMap(
  fetchedDaysMap: { [day: number]: boolean },
  removeFetchedDays: { start: number; end: number }[]
): { [day: number]: boolean } {
  const daysToRemove: number[] = removeFetchedDays.reduce(
    (acc, { start, end }) =>
      acc.concat(getFoundFetchedDays(fetchedDaysMap, utc(start), utc(end))),
    []
  );
  return Object.keys(fetchedDaysMap)
    .filter(day => !daysToRemove.includes(+day))
    .reduce((acc, day) => {
      acc[day] = fetchedDaysMap[day];
      return acc;
    }, {});
}

function getUpdatedMap(
  fetchedDaysMap: { [day: number]: boolean },
  start: number,
  end: number
): { [day: number]: boolean } {
  return getNotFetchedDays(fetchedDaysMap, utc(start), utc(end)).reduce(
    (acc, d) => {
      acc[d] = true;
      return acc;
    },
    { ...fetchedDaysMap }
  );
}

export const getSingleAndMergedAvailabilityNotes = createCachedSelector(
  (state: RootState, key) => state.timelineEvents.availabilityNotes,
  (state: RootState, key) => state.ui.transform.scaleX.domain()[0].valueOf(),
  (state: RootState, key) => state.ui.transform.scaleX.domain()[1].valueOf(),
  getAircraftIndexMapExcludingHolding,
  (state: RootState, key) => state.ui.segmentsVisibility.crewRestNotes,
  (availabilityNotes, start, end, indexMap, isCrewRestNoteVisible) => {
    return chain(availabilityNotes)
      .flatMap(p => p.pool)
      .uniqBy(f => f.id)
      .filter(n => indexMap[n.aircraftId] > 0 || indexMap[n.aircraftId] === 0)
      .filter(n => n.start < end && n.end > start)
      .filter(
        n => isCrewRestNoteVisible || n.inputSourceId !== InputSourceIdType.SQL
      )
      .thru(getMergedNotes)
      .value();
  }
)((_state, key) => key);

export const getSingleAndMergedGeneralNotes = createCachedSelector(
  (state, key) => getVisibleElements(state, 'generalNotes'),
  generalNotes => {
    return getMergedNotes(generalNotes);
  }
)((_state, key) => key);
/**
 * Uniquely encode two natural numbers into a single natural number
 * @param {number} a - first natural number
 * @param {number} b - second natural number
 * @returns {number} unique natural number
 */
export const cantorPairing = (a: number, b: number): number =>
  b + ((a + b) * (a + b + 1)) / 2;

export const getOverlapsByFlightWithMaintenances = (
  overlaps: OverlappedElements[]
): OverlappedElements[] => {
  return chain(
    overlaps.reduce((acc, ov) => {
      const flights = ov.elements.filter(el => isFlight(el));
      if (flights.length !== 1) return acc;
      const byFlight = flights[0];
      const mxs = ov.elements.filter(el => isMaintenance(el));
      if (!acc[byFlight.id]) {
        acc[byFlight.id] = {
          start: byFlight.start,
          end: byFlight.end,
          id: mxs.reduce((acc, mx) => cantorPairing(acc, mx.id), byFlight.id),
          aircraftId: flights[0].aircraftId,
          elements: [flights[0], ...mxs],
        };
        return acc;
      }
      if (acc[byFlight.id].elements.length < mxs.length + 1) {
        acc[byFlight.id].elements = [byFlight, ...mxs];
        acc[byFlight.id].id = mxs.reduce(
          (acc, mx) => cantorPairing(acc, mx.id),
          byFlight.id
        );
      }
      return acc;
    }, {} as { [id: number]: OverlappedElements })
  )
    .flatMap()
    .value();
};

export const fromFlightOverlaps = (ov: OverlappedElements) =>
  ov.elements.filter(el => isFlight(el)).length < 2;

export const getOverlapsOfMaintenancesAndOverlappedFlights = createCachedSelector(
  (state: RootState, key) => getOverlappedElementsSelector(state, 'flights'),
  (state: RootState, key) => getVisibleElements(state, 'maintenances'),
  (overlappedFlights, mXes) => {
    const allOverlaps = getOverlappedElements([...overlappedFlights, ...mXes]);
    const onlyWithFlights = allOverlaps.filter(ov =>
      ov.elements.some(o => !!(o as OverlappedElements).elements)
    );
    const result = onlyWithFlights.reduce((acc, item) => {
      const mxs = item.elements.filter(
        el => !(el as OverlappedElements).elements
      );
      const flightOv = item.elements.filter(
        el => !!(el as OverlappedElements).elements
      )[0];
      return acc.concat({
        ...flightOv,
        elements: [...(flightOv as OverlappedElements).elements, ...mxs],
      });
    }, []);
    return result;
  }
)((state, key) => key);

export const getOverlapsOfMaintenancesAndOverlappedFlightsIfAny = createCachedSelector(
  (state: RootState, key) => getOverlappedElementsSelector(state, 'flights'),
  getOverlapsOfMaintenancesAndOverlappedFlights,
  (allFlightOverlaps, withMxs) => {
    const flightOverlapsOnly = differenceBy(allFlightOverlaps, withMxs, 'id');
    return uniqBy([...flightOverlapsOnly, ...withMxs], 'id');
  }
)((state, key) => key);

export const getOverlapsOfVisibleMaintenancesAndFlights = createCachedSelector(
  (state: RootState, key) => getVisibleElements(state, 'flights'),
  (state: RootState, key) => getVisibleElements(state, 'maintenances'),
  (singleFlights, mxs) => getOverlappedElements([...singleFlights, ...mxs])
)((state, key) => key);

export const getOverlaps = createCachedSelector(
  (state: RootState, type1, type2, type3) => getVisibleElements(state, type1),
  (state: RootState, type1, type2, type3) => getVisibleElements(state, type2),
  (state: RootState, type1, type2, type3) =>
    !type3 ? [] : getVisibleElements(state, type3),
  (elementsTypeOne, elementsTypeTwo, elementsTypeThree) => {
    return getOverlappedElements([
      ...elementsTypeOne,
      ...elementsTypeTwo,
      ...elementsTypeThree,
    ]);
  }
)((state, type1, type2, type3) => `${type1}-${type2}-${type3}`);

export const getOverlappedElementsSelector = createCachedSelector(
  getVisibleElements,
  (_state, type) => type,
  getFoundFlights,
  (visibleFlights, type, foundFlights) => {
    if (type === 'flights') {
      return sortBy(
        getOverlappedFlights(
          !foundFlights.length ? visibleFlights : foundFlights
        ),
        (overlappedElements: OverlappedElements) =>
          overlappedElements.elements.length
      );
    }
    return sortBy(
      getOverlappedElements(visibleFlights),
      (overlappedElements: OverlappedElements) =>
        overlappedElements.elements.length
    );
  }
)((_state, key) => key);

export const getSingleAndMergedVisibleMelItems = createCachedSelector(
  (state, key) => getVisibleElements(state, 'maintenanceItems'),
  mels => {
    return getMergedMaintenanceItems(mels);
  }
)((_state, key) => key);

export const getNotFetchedDays = (
  fetchedDaysCacheMap: { [day: number]: boolean },
  start: moment.Moment,
  end: moment.Moment
) => {
  let days = [];
  do {
    days.push(start.startOf('day').valueOf());
    start.add(1, 'day');
  } while (start.endOf('day').valueOf() <= end.valueOf());
  return days.filter(day => !fetchedDaysCacheMap[day]);
};
