import { PureComponent } from 'react';

interface StateProps {
  fill?: string;
  width?: number;
  height?: number;
  strokeWidth?: number;
}
export default class UnaviableIcon extends PureComponent<StateProps> {
  render() {
    const {
      width = 14,
      fill = '#FE2D56',
      height = 14,
      strokeWidth = 1,
    } = this.props;
    return (
      <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 14 14"
        version="1.1"
      >
        <g
          id="unaviable-icon"
          stroke="none"
          strokeWidth={strokeWidth}
          fill="none"
          fillRule="evenodd"
        >
          <g
            transform="translate(-290.000000, -3754.000000)"
            fill={fill}
            fillRule="nonzero"
          >
            <g
              id="tooltip-tail-inf-unavailable"
              transform="translate(270.000000, 3618.000000)"
            >
              <g transform="translate(12.000000, 124.000000)">
                <path d="M15,12 C11.136,12 8,15.136 8,19 C8,22.864 11.136,26 15,26 C18.864,26 22,22.864 22,19 C22,15.136 18.864,12 15,12 Z M9.4,19 C9.4,15.906 11.906,13.4 15,13.4 C16.295,13.4 17.485,13.841 18.43,14.583 L10.583,22.43 C9.841,21.485 9.4,20.295 9.4,19 Z M15,24.6 C13.705,24.6 12.515,24.159 11.57,23.417 L19.417,15.57 C20.159,16.515 20.6,17.705 20.6,19 C20.6,22.094 18.094,24.6 15,24.6 Z" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
