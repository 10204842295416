import { xor } from 'lodash';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as actions from '../actions';
import {
  VISIBLE_AC_TYPES,
  VISIBLE_OPERATING_COMPANIES,
} from '../constants/environment';
import Aircraft from '../types/aircraft';
import { DEFAULT_USER_PREFERENCES } from '../types/user-preferences';
import {
  getStateOnToggleOperator,
  hydrateVisibleAircraftIds,
  getStateOnToggleAircraftOrActype,
} from '../utils/aircraft';

export interface AircraftTogglerState {
  crew?: boolean;
  notes?: boolean;
}
export interface AircraftState {
  acQuery: string;
  aircraft: Aircraft[];
  isGroupedByOperator: boolean;
  loading: boolean;
  showSubCharters: boolean;
  showLabels: boolean;
  statusLoading: {
    vistajet: boolean;
    userPreferences: boolean;
  };
  togglersState: { [aircraftId: number]: AircraftTogglerState };
  visibleAircraftTypeByOperatingCompanyMap: { [companyId: number]: number[] };
  visibleAcTypes: number[];
  visibleOperatingCompanies: number[];
  visibleAcIds: number[] | null;
  acTypesPositionMap: { [companyId: number]: number[] };
}
export const initialState: AircraftState = {
  acQuery: '',
  aircraft: [],
  isGroupedByOperator: false,
  loading: false,
  showSubCharters: false,
  showLabels: false,
  statusLoading: {
    vistajet: false,
    userPreferences: false,
  },
  togglersState: {},
  visibleAircraftTypeByOperatingCompanyMap: {},
  visibleAcTypes: VISIBLE_AC_TYPES,
  visibleOperatingCompanies: VISIBLE_OPERATING_COMPANIES,
  visibleAcIds: null,
  acTypesPositionMap: {},
};

export default reducerWithInitialState(initialState)
  .case(actions.doAircraftMappersFetch.started, state => ({
    ...state,
    loading: true,
  }))
  .case(actions.userChangeSearchACQuery, (state, payload) => ({
    ...state,
    acQuery: payload.query,
  }))
  .case(
    actions.doAircraftMappersFetch.done,
    (state, { result: aircraft = [] }) => {
      return {
        ...state,
        aircraft,
        loading: false,
        statusLoading: {
          ...state.statusLoading,
          vistajet: true,
        },
      };
    }
  )
  .case(actions.doAircraftMappersFetch.failed, state => ({
    ...state,
    loading: false,
  }))
  .case(actions.userToggleTailElementsVisibility, (state, payload) => {
    const { aircraftId } = payload;
    return {
      ...state,
      togglersState: {
        ...state.togglersState,
        [aircraftId]: {
          ...(state.togglersState[aircraftId] || {}),
          [payload.type]: payload.value,
        },
      },
    };
  })
  .case(actions.userSetSegmentVisibility, (state, payload) => {
    const [segmentType, isVisible] = payload;
    if (segmentType === 'crew' || segmentType === 'generalNotes') {
      let togglerElementType =
        segmentType === 'generalNotes' ? 'notes' : segmentType;
      return {
        ...state,
        togglersState: state.aircraft.reduce(
          (acc, val) => {
            acc[val.id] = {
              ...acc[val.id],
              [togglerElementType]: isVisible,
            };
            return acc;
          },
          { ...state.togglersState }
        ),
      };
    }
    return { ...state };
  })
  .case(actions.userToggleOperatingCompaniesView, (state, payload) => {
    const {
      visibleAcIds,
      visibleAircraftTypeByOperatingCompanyMap,
    } = getStateOnToggleOperator(
      payload,
      state.visibleAircraftTypeByOperatingCompanyMap,
      state.visibleAcIds
    );
    return {
      ...state,
      visibleAircraftTypeByOperatingCompanyMap,
      visibleAcIds,
    };
  })
  .cases(
    [actions.userToggleACTypeView, actions.userToggleAircraft],
    (state, payload) => {
      const {
        visibleAcIds,
        visibleAircraftTypeByOperatingCompanyMap,
      } = getStateOnToggleAircraftOrActype(
        payload,
        state.visibleAircraftTypeByOperatingCompanyMap,
        state.visibleAcIds
      );
      return {
        ...state,
        visibleAcIds,
        visibleAircraftTypeByOperatingCompanyMap,
      };
    }
  )
  .case(actions.userToggleSelectAllAcTypes, (state, payload) => {
    const { selected, allOptionsMap, allAcIds } = payload;
    return {
      ...state,
      showSubCharters: selected,
      visibleAircraftTypeByOperatingCompanyMap: !selected ? {} : allOptionsMap,
      visibleAcIds: !selected ? [] : allAcIds,
    };
  })
  .case(actions.userToggleSubChartersView, state => ({
    ...state,
    showSubCharters: !state.showSubCharters,
  }))
  .case(actions.userToggleLabelOperatorsView, state => ({
    ...state,
    showLabels: !state.showLabels,
  }))
  .case(actions.userToggleGroupByOperator, state => {
    const isGroupedByOperator = !state.isGroupedByOperator;
    return {
      ...state,
      isGroupedByOperator,
      showLabels:
        state.showLabels && !isGroupedByOperator ? false : state.showLabels,
    };
  })
  .case(actions.wsUpdateBatch, (state, payload) => ({
    ...state,
    aircraft: getAircraftUpdates(state, payload.data.aircraft),
  }))
  .case(actions.userScrollScrollbar, state => ({
    ...state,
    acQuery: '',
  }))
  .caseWithAction(actions.userZoomVer, (state, action) => {
    return {
      ...state,
      acQuery: action.meta.isNotScolling ? state.acQuery : '',
    };
  })
  .case(actions.doGetUserPreferences.done, (state, { result }) => ({
    ...state,
    visibleAircraftTypeByOperatingCompanyMap:
      result.visibleAircraftTypeByOperatingCompanyMap,
    togglersState: result.togglersState,
    showSubCharters: result.showSubCharters,
    isGroupedByOperator: result.isGroupedByOperator,
    showLabels: result.showLabels,
    visibleAcTypes: result.visibleACTypes,
    visibleOperatingCompanies: result.visibleOperatingCompanies,
    // W/A for backward compatibility and fork loading
    visibleAcIds:
      result.visibleAcIds ||
      hydrateVisibleAircraftIds(
        state.aircraft,
        result.visibleAircraftTypeByOperatingCompanyMap
      ),
    acTypesPositionMap: result.acTypesPositionMap,
    statusLoading: {
      ...state.statusLoading,
      userPreferences: true,
    },
  }))
  .case(actions.userResetPreferences, (state, payload) => {
    const { visibleAircraftTypeByOperatingCompanyMap } = payload;
    return {
      ...state,
      visibleAircraftTypeByOperatingCompanyMap,
      acTypesPositionMap: DEFAULT_USER_PREFERENCES.acTypesPositionMap,
      isGroupedByOperator: DEFAULT_USER_PREFERENCES.isGroupedByOperator,
      showLabels: DEFAULT_USER_PREFERENCES.showLabels,
      togglersState: DEFAULT_USER_PREFERENCES.togglersState,
      showSubCharters: DEFAULT_USER_PREFERENCES.showSubCharters,
      visibleAcTypes: DEFAULT_USER_PREFERENCES.visibleACTypes,
      visibleOperatingCompanies:
        DEFAULT_USER_PREFERENCES.visibleOperatingCompanies,
      visibleAcIds: hydrateVisibleAircraftIds(
        state.aircraft,
        visibleAircraftTypeByOperatingCompanyMap
      ),
    };
  })
  // W/A if user preferences is empty and aircraft mapper call receive result later than preferences
  .case(actions.doHydrateAcTypeFiltersConfig, (state, payload) => ({
    ...state,
    visibleAircraftTypeByOperatingCompanyMap: payload.map,
    visibleAcIds: payload.acIds,
  }))
  .case(actions.userChangeCurrentAcTypePosition, (state, payload) => {
    const acTypesPositionMap = {
      ...state.acTypesPositionMap,
      ...payload,
    };
    return {
      ...state,
      acTypesPositionMap,
    };
  })
  .cases(
    [
      actions.doSetAcTypesPositionMap,
      actions.userResetChangesInAcTypesPositionMap,
    ],
    (state, payload) => {
      return {
        ...state,
        acTypesPositionMap: payload,
      };
    }
  );

function getAircraftUpdates(
  state: AircraftState,
  data: { [id: number]: Aircraft }
) {
  if (!data) return state.aircraft;
  const currentAcMap = state.aircraft.reduce((acc, a) => {
    acc.set(`${a.id}`, a);
    return acc;
  }, new Map());
  const nextAcMap = Object.keys(data).reduce((acc, a) => {
    if (!data[a]) {
      acc.delete(a);
    } else {
      acc.set(a, data[a]);
    }
    return acc;
  }, currentAcMap);
  return [...nextAcMap.values()];
}
