import { PureComponent } from 'react';
import * as actions from '../../../actions';
import { DispatchProp } from 'react-redux';
import { EmptyLegGroupType } from '../../../reducers/empty-legs';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { SegmentType } from '../../../types/segment-types';
import { AnyAction } from 'redux';

interface Props {
  emptyLegMap: { [el in EmptyLegGroupType]: boolean };
  segmentsVisibility: { [st in SegmentType]: boolean };
  hasCheckedEmptyLegOffersPermission: boolean;
  hasCheckedOneWayOffersPermission: boolean;
}
const isOwKeyMap: { [type in EmptyLegGroupType]: boolean } = {
  'Advertised One Way Offers': true,
  'Cancelled One Way Offers': true,
  'Unadvertised One Way Offers': true,
  'Advertised Empty Leg Offers': false,
  'Cancelled Empty Leg Offers': false,
  'Unadvertised Empty Leg Offers': false,
};
const isELKeyMap: { [type in EmptyLegGroupType]: boolean } = {
  'Advertised Empty Leg Offers': true,
  'Cancelled Empty Leg Offers': true,
  'Unadvertised Empty Leg Offers': true,
  'Advertised One Way Offers': false,
  'Cancelled One Way Offers': false,
  'Unadvertised One Way Offers': false,
};

export class OneWayAndEmptyLegContent extends PureComponent<
  Props & DispatchProp<AnyAction>
> {
  onChangeEmptyLegVisibility = (type: EmptyLegGroupType) => (
    e: CheckboxChangeEvent
  ) =>
    this.props.dispatch(
      actions.userToggleEmptyLegView([type, e.target.checked])
    );

  isEmptyLegAndOneWayOptionDisabled = (key: EmptyLegGroupType) => {
    if (
      [
        'Advertised Empty Leg Offers',
        'Unadvertised Empty Leg Offers',
        'Cancelled Empty Leg Offers',
      ].some(k => k === key)
    ) {
      return !this.props.segmentsVisibility.emptyLegOffers;
    }
    return !this.props.segmentsVisibility.oneWayOffers;
  };
  render() {
    const {
      emptyLegMap,
      hasCheckedEmptyLegOffersPermission,
      hasCheckedOneWayOffersPermission,
    } = this.props;

    return (
      <div
        data-test-entity="filter-bar-onewayemptyleg"
        className="unselectable"
      >
        {Object.keys(emptyLegMap)
          .filter(offer => {
            if (
              (!hasCheckedOneWayOffersPermission && isOwKeyMap[offer]) ||
              (!hasCheckedEmptyLegOffersPermission && isELKeyMap[offer])
            )
              return false;
            return true;
          })
          .map(key => (
            <div key={key}>
              <Checkbox
                onChange={this.onChangeEmptyLegVisibility(
                  key as EmptyLegGroupType
                )}
                checked={emptyLegMap[key]}
                disabled={this.isEmptyLegAndOneWayOptionDisabled(
                  key as EmptyLegGroupType
                )}
              >
                {key}
              </Checkbox>
            </div>
          ))}
      </div>
    );
  }
}
