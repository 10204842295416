import { PureComponent } from 'react';
interface ConnectedProps {}
interface OwnProps {
  text: string;
}
interface Props extends ConnectedProps, OwnProps {}

export default class TooltipCancellationReason extends PureComponent<Props> {
  render() {
    const { text } = this.props;
    return (
      <div className="tooltip-cancellation-reason">
        <div className="tooltip-cancellation-reason-header">
          Cancellation Reason
        </div>
        <div className="tooltip-cancellation-reason-text">{text}</div>
      </div>
    );
  }
}
