import { store as reduxStore } from '../../../../root';
import * as d3S from 'd3-selection';
import * as actions from '../../../../actions';
import { PureComponent } from 'react';
import { ExtendedEventElement } from '..';
import { RootState } from '../../../../reducers';
import { Action } from 'typescript-fsa';
import { WsUpdateBatch } from '../../../../data-processing/ws-income/ws-update-batch';
import {
  getFoundElements,
  getFilteredByAircraft,
  getSegmentType,
  isFlightSearchType,
  getVisibleElements,
  getAssociatedFlight,
  getFoundFlights,
} from '../../../../selectors';
import {
  getSingleAndMergedAvailabilityNotes,
  getSingleAndMergedGeneralNotes,
  getOverlapsOfVisibleMaintenancesAndFlights,
  getOverlapsOfMaintenancesAndOverlappedFlightsIfAny,
  getOverlappedElementsSelector,
  getOverlapsByFlightWithMaintenances,
  getOverlaps,
  fromFlightOverlaps,
  getSingleAndMergedVisibleMelItems,
} from '../../../../reducers/timeline-events';
import Note, { MergedNote } from '../../../../types/note';
import MaintenanceItem, { MergedMEL } from '../../../../types/maintenance-item';
import EventElement, {
  OverlappedElements,
} from '../../../../types/event-element';
import { subMiddle } from '../../../../root';
import {
  flightDataMapper,
  crewDataMapper,
  emptyLegDataMapper,
  oneWayDataMapper,
  maintenanceDataMapper,
  maintenanceItemDataMapper,
  noteDataMapper,
  groundTimeTypeDataMapper,
  plainDataMapper,
  overlappedFlightsMapper,
  overlappedFlightWithMaintenancesMapper,
  overlappedMaintenancesDataMapper,
  overlappedFlightEmptyLegOneWayDataMapper,
  fboMismatchDataMapper,
} from './data-mappers';
import Flight from '../../../../types/flight';
import { SegmentType } from '../../../../types/segment-types';
import {
  CrewAssignment,
  CrewRoster,
  CrewDuty,
} from '../../../../types/crew-roster';
import { getLaneHeightKoef } from '../../../../reducers/ui';
import { SubscriptionFunction } from '../../../../data-processing/subscribe-middleware';
import { buildPropsForComponent, getNoteFromMergedNotesWidth } from './utils';
import { isFlight } from '../../../../common/flight/flight-check-status';
import Maintenance from '../../../../types/maintenance';
import EmptyLegOffer from '../../../../types/empty-leg-offer';
import OneWayOffer from '../../../../types/one-way-offer';
import { isOnHoldAircraft } from '../../../../utils/hold-line-flights';
import { getAircraftIndexMapExcludingHolding } from '../../../../selectors';
import { getAircraftByIdMapWithHold } from '../../../../selectors';
import { isEmptyLegOffer } from '../../../../utils/empty-leg-offer';
import { isMergedMaintenanceItems } from '../../../../utils/maintenance-item';
import { isMergeNote } from '../../../../utils/note';
export type ElementName =
  | SegmentType
  | 'mergedAvailabilityNotes'
  | 'mergedGeneralNotes'
  | 'mergedMaintenanceItems'
  | 'overlappedMaintenances'
  | 'overlappedFlights'
  | 'overlappedFlightWithMaintenances'
  | 'overlap-flight-el-ow';

export type ComponentD3creator = (
  s: d3S.Selection<any, any, any, any>
) => d3S.Selection<any, any, any, any>;

interface Props {
  hasRootId?: boolean;
  elementName: ElementName;
  componentD3creator: ComponentD3creator;
}
export class GroupOfTextLabels extends PureComponent<Props> {
  rootGroupRef: HTMLDivElement;
  d3Updater: (action: any, state: RootState) => void;
  scrollProtectedUpdate: (action: any, state: RootState) => void;
  linkedSelection: d3S.Selection<any, ExtendedEventElement, HTMLDivElement, {}>;
  batchActionHandler: (
    actionsBatch: Action<WsUpdateBatch>,
    state: RootState
  ) => void;
  constructor(props: Props) {
    super(props);
    switch (props.elementName) {
      case 'flights': {
        this.d3Updater = (_action, state) => {
          const aircraftByIdMap = getAircraftByIdMapWithHold(state);
          if (getFoundElements(state).length) {
            if (!isFlightSearchType(state.search.searchType)) {
              this.update(
                getAssociatedFlight(state).filter(fl =>
                  isOnHoldAircraft(fl, aircraftByIdMap)
                )
              );
            } else {
              this.update(
                getFoundFlights(state).filter(fl =>
                  isOnHoldAircraft(fl, aircraftByIdMap)
                )
              );
            }
          } else {
            this.update(
              getVisibleElements(state, 'flights').map(fl => ({
                ...fl,
                isSelected: state.ui.selectedFlights.includes(fl.id),
              }))
            );
          }
        };
        break;
      }
      case 'mergedAvailabilityNotes': {
        this.d3Updater = (_action, state) => {
          getFoundElements(state).length
            ? this.update([])
            : this.update(getSingleAndMergedAvailabilityNotes(state, ''));
        };
        break;
      }
      case 'mergedGeneralNotes': {
        this.d3Updater = (_action, state) => {
          getFoundElements(state).length
            ? this.update([])
            : this.update(getSingleAndMergedGeneralNotes(state, ''));
        };
        break;
      }
      case 'mergedMaintenanceItems': {
        this.d3Updater = (_action, state) => {
          getFoundElements(state).length
            ? this.update([])
            : this.update(getSingleAndMergedVisibleMelItems(state, ''));
        };
        break;
      }
      case 'overlappedMaintenances': {
        this.d3Updater = (_action, state) => {
          getFoundElements(state).length
            ? this.update([])
            : this.update(getOverlappedElementsSelector(state, 'maintenances'));
        };
        break;
      }
      case 'overlappedFlights': {
        this.d3Updater = (_action, state) => {
          const aircraftByIdMap = getAircraftByIdMapWithHold(state);
          if (
            getFoundFlights(state).length &&
            !isFlightSearchType(state.search.searchType)
          ) {
            this.update([]);
          } else {
            this.update(
              getOverlapsOfMaintenancesAndOverlappedFlightsIfAny(
                state,
                'flights'
              ).filter(fl => isOnHoldAircraft(fl, aircraftByIdMap))
            );
          }
        };
        break;
      }
      case 'overlappedFlightWithMaintenances': {
        this.d3Updater = (_action, state) => {
          if (getFoundFlights(state).length) {
            this.update([]);
          } else {
            this.update(
              getOverlapsByFlightWithMaintenances(
                getOverlapsOfVisibleMaintenancesAndFlights(state, '')
              )
            );
          }
        };
        break;
      }
      case 'overlap-flight-el-ow': {
        this.d3Updater = (_action, state) => {
          if (getFoundFlights(state).length) {
            this.update([]);
          } else {
            this.update(
              getOverlaps(
                state,
                'flights',
                'oneWayOffers',
                'emptyLegOffers'
              ).filter(fromFlightOverlaps)
            );
          }
        };
        break;
      }
      case 'crewAssignment': {
        this.d3Updater = (_action, state) => {
          const foundElements = getFoundElements(state);
          const indexMap = getAircraftIndexMapExcludingHolding(state);
          const filteredFoundElements = getFilteredByAircraft(
            foundElements,
            indexMap
          ).filter(
            (el: Flight | CrewAssignment | CrewRoster) =>
              getSegmentType(el) === 'crewAssignment'
          ) as CrewAssignment[];
          foundElements.length
            ? this.update(filteredFoundElements)
            : this.update(
                getVisibleElements(state, props.elementName as SegmentType)
              );
        };
        break;
      }
      case 'crewRoster': {
        this.d3Updater = (_action, state) => {
          const foundElements = getFoundElements(state);
          const indexMap = getAircraftIndexMapExcludingHolding(state);
          const filteredFoundElements = getFilteredByAircraft(
            foundElements,
            indexMap
          ).filter(
            (el: Flight | CrewRoster | CrewAssignment) =>
              getSegmentType(el) === 'crewRoster'
          ) as CrewRoster[];
          foundElements.length
            ? this.update(filteredFoundElements)
            : this.update(
                getVisibleElements(state, props.elementName as SegmentType)
              );
        };
        break;
      }
      default: {
        this.d3Updater = (_action, state) => {
          getFoundElements(state).length
            ? this.update([])
            : this.update(
                getVisibleElements(state, props.elementName as SegmentType)
              );
        };
      }
    }
    this.scrollProtectedUpdate = (action, state) => {
      if (state.ui.isScrollingVertically || state.ui.isScrollingHorizontally)
        return;
      this.d3Updater(action, state);
    };
    this.batchActionHandler = (
      actionBatch: Action<WsUpdateBatch>,
      state: RootState
    ) => {
      const { elementName } = this.props;
      const { payload } = actionBatch;
      const { data } = payload;
      if (
        !this.linkedSelection ||
        state.ui.isScrollingVertically ||
        state.ui.isScrollingHorizontally
      )
        return;
      switch (elementName) {
        case 'overlappedFlights': {
          const { flights } = data;
          if (!flights) return;
          this.d3Updater(actionBatch, state);
          this.linkedSelection.datum((oe: OverlappedElements) => {
            return {
              ...oe,
              elements: oe.elements.map(e => flights[e.id] || e),
            };
          });
          break;
        }
        case 'overlappedMaintenances': {
          const { maintenances } = data;
          if (!maintenances) return;
          this.d3Updater(actionBatch, state);
          this.linkedSelection.datum((oe: OverlappedElements) => {
            return {
              ...oe,
              elements: oe.elements.map(e => maintenances[e.id] || e),
            };
          });
          break;
        }
        case 'overlappedFlightWithMaintenances': {
          const { maintenances, flights } = data;
          if (!maintenances && !flights) return;
          this.d3Updater(actionBatch, state);
          this.linkedSelection.datum((oe: OverlappedElements) => {
            return {
              ...oe,
              elements: oe.elements.map((e: Flight | Maintenance) => {
                if (isFlight(e)) {
                  return (flights && flights[e.id]) || e;
                }
                return (maintenances && maintenances[e.id]) || e;
              }),
            };
          });
          break;
        }
        case 'overlap-flight-el-ow': {
          const { oneWayOffers, flights, emptyLegOffers } = data;
          if (!oneWayOffers && !emptyLegOffers && !flights) return;
          this.d3Updater(actionBatch, state);
          this.linkedSelection.datum((oe: OverlappedElements) => {
            return {
              ...oe,
              elements: oe.elements.map(
                (e: Flight | OneWayOffer | EmptyLegOffer) => {
                  if (isFlight(e)) {
                    return (flights && flights[e.id]) || e;
                  }
                  if (isEmptyLegOffer(e)) {
                    return (emptyLegOffers && emptyLegOffers[e.id]) || e;
                  }
                  return (oneWayOffers && oneWayOffers[e.id]) || e;
                }
              ),
            };
          });
          break;
        }
        case 'airportMismatches':
        case 'groundTime':
        case 'fboMismatches': {
          const { flights, maintenances } = data;
          if (!flights && !maintenances) return;
          this.d3Updater(actionBatch, state);
          break;
        }
        case 'mergedAvailabilityNotes': {
          const { availabilityNotes, generalNotes } = data;
          if (!availabilityNotes && !generalNotes) return;
          this.d3Updater(actionBatch, state);
          this.linkedSelection.datum((n: MergedNote | Note) => {
            if (isMergeNote(n)) {
              return {
                ...n,
                notes: n.notes.reduce((acc, innerNote) => {
                  if (availabilityNotes && availabilityNotes[innerNote.id]) {
                    return acc.concat(availabilityNotes[innerNote.id]);
                  } else if (generalNotes && generalNotes[innerNote.id]) {
                    return acc;
                  } else {
                    return acc.concat(innerNote);
                  }
                }, []),
              };
            }
            if (availabilityNotes && availabilityNotes[n.id]) {
              return availabilityNotes[n.id];
            } else if (generalNotes && generalNotes[n.id]) {
              return null;
            } else {
              return n;
            }
          });
          break;
        }
        case 'mergedGeneralNotes': {
          const { generalNotes, availabilityNotes } = data;
          if (!generalNotes && !availabilityNotes) return;
          this.d3Updater(actionBatch, state);
          this.linkedSelection.datum((n: MergedNote | Note) => {
            if (isMergeNote(n)) {
              return {
                ...n,
                notes: n.notes.reduce((acc, innerNote) => {
                  if (generalNotes && generalNotes[innerNote.id]) {
                    return acc.concat(generalNotes[innerNote.id]);
                  } else if (
                    availabilityNotes &&
                    availabilityNotes[innerNote.id]
                  ) {
                    return acc;
                  } else {
                    return acc.concat(innerNote);
                  }
                }, []),
              };
            }
            if (generalNotes && generalNotes[n.id]) {
              return generalNotes[n.id];
            } else if (availabilityNotes && availabilityNotes[n.id]) {
              return null;
            } else {
              return n;
            }
          });
          break;
        }
        case 'mergedMaintenanceItems': {
          const { maintenanceItems } = data;
          if (!maintenanceItems) return;
          this.d3Updater(actionBatch, state);
          this.linkedSelection.datum((m: MaintenanceItem | MergedMEL) => {
            if (isMergedMaintenanceItems(m)) {
              return {
                ...m,
                maintenanceItems: m.maintenanceItems.map(
                  innerItem => maintenanceItems[innerItem.id] || innerItem
                ),
              };
            }
            return maintenanceItems[m.id]
              ? { ...m, ...maintenanceItems[m.id] }
              : m;
          });
          break;
        }
        case 'crewRoster': {
          const { crewRoster } = data;
          if (!crewRoster) return;
          this.d3Updater(actionBatch, state);
          this.linkedSelection.datum((c: CrewRoster) =>
            crewRoster[c.id]
              ? {
                  ...c,
                  start: crewRoster[c.id].start,
                  end: crewRoster[c.id].end,
                  team: c.team.map((t: CrewDuty) => crewRoster[t.id] || t),
                  ...crewRoster[c.id],
                }
              : c
          );
          break;
        }
        default: {
          const batchForThisElementType = data[elementName];
          if (!batchForThisElementType) return;
          this.d3Updater(actionBatch, state);
          this.linkedSelection.datum(
            data => batchForThisElementType[data.id] || data
          );
        }
      }
    };
  }

  handleHoverNoteTooltip: SubscriptionFunction<any> = (
    action: any,
    state: RootState
  ) => {
    this.linkedSelection
      .filter(
        n => state.ui.dataForSegment && state.ui.dataForSegment.id === n.id
      )
      .selectAll('div.notes-text-label')
      .each(function(d: MergedNote, i) {
        // How it change to getting without data-test-entity-id ????
        const nodeWithNoteId = +d3S.select(this).attr('data-test-entity-id');
        if (action.payload === nodeWithNoteId) {
          const note = d.notes.find(n => n.id === nodeWithNoteId);
          const noteWithProps = buildPropsForComponent(note);
          d3S
            .select(this)
            .raise()
            .select('span')
            .style('width', `${noteWithProps.width}px`)
            .style('background', '#fdd835');
        }
      });
  };
  handleUnhoverNoteTooltip: SubscriptionFunction<any> = (
    action: any,
    state: RootState
  ) => {
    this.linkedSelection
      .filter(
        n => state.ui.dataForSegment && state.ui.dataForSegment.id === n.id
      )
      .selectAll('div.notes-text-label')
      .each(function(d: MergedNote) {
        // How it change to getting without data-test-entity-id ????
        const nodeWithNoteId = +d3S.select(this).attr('data-test-entity-id');
        if (action.payload === nodeWithNoteId) {
          const note = d.notes.find(n => n.id === nodeWithNoteId);
          const noteIndex = d.notes.indexOf(note);
          const widthOfNotOverlappedArea = getNoteFromMergedNotesWidth(
            note,
            d.notes[noteIndex + 1]
          );
          d3S
            .select(this)
            .lower()
            .select('span')
            .style('width', `${widthOfNotOverlappedArea}px`)
            .style('background', 'transparent');
        }
      });
  };

  toogleUpdateSubscription(mode: 'on' | 'off') {
    const { elementName } = this.props;
    subMiddle[mode](
      actions.doAirportsMappersFetch.done,
      this.scrollProtectedUpdate
    );
    subMiddle[mode](actions.getSegmentRequest.done, this.scrollProtectedUpdate);
    subMiddle[mode](actions.userChangeSearchQuery, this.d3Updater);
    subMiddle[mode](actions.userChangeSearchQueryAllFilters, this.d3Updater);
    subMiddle[mode](actions.userChangeSearchACQuery, this.d3Updater);
    subMiddle[mode](actions.userChangeSearchType, this.d3Updater);
    subMiddle[mode](actions.userToggleACTypeView, this.d3Updater);
    subMiddle[mode](actions.userToggleSubChartersView, this.d3Updater);
    subMiddle[mode](actions.userToggleAircraft, this.d3Updater);
    subMiddle[mode](actions.userToggleOperatingCompaniesView, this.d3Updater);
    subMiddle[mode](actions.userToggleAircraftByMxEvents, this.d3Updater);
    subMiddle[mode](actions.userToggleSelectAllAcTypes, this.d3Updater);
    subMiddle[mode](actions.userToggleTailElementsVisibility, this.d3Updater);
    subMiddle[mode](actions.userToggleGroupByOperator, this.d3Updater);
    subMiddle[mode](actions.userSetSegmentVisibility, this.d3Updater);
    subMiddle[mode](actions.wsUpdateBatch, this.batchActionHandler);
    subMiddle[mode](actions.userResetMultipleSearchBarPanel, this.d3Updater);
    subMiddle[mode](actions.userToggleMultipleSearchBarPanel, this.d3Updater);
    subMiddle[mode](actions.userChangeCurrentAcTypePosition, this.d3Updater);
    subMiddle[mode](
      actions.userResetChangesInAcTypesPositionMap,
      this.d3Updater
    );
    subMiddle[mode](actions.userSetGroundTimeType, this.d3Updater);
    subMiddle[mode](actions.doEnableTextLabels, this.d3Updater);
    subMiddle[mode](actions.doStopScroll, this.d3Updater);
    if (
      elementName === 'oneWayOffers' ||
      elementName === 'emptyLegOffers' ||
      elementName === 'overlap-flight-el-ow'
    ) {
      subMiddle[mode](actions.userToggleEmptyLegView, this.d3Updater);
    }
    if (
      elementName === 'flights' ||
      elementName === 'overlappedFlights' ||
      elementName === 'overlappedFlightWithMaintenances' ||
      elementName === 'groundTime' ||
      elementName === 'airportMismatches' ||
      elementName === 'fboMismatches'
    ) {
      subMiddle[mode](actions.userToggleOpStatusView, this.d3Updater);
      subMiddle[mode](actions.userTimelineSelectionBegin, this.d3Updater);
      subMiddle[mode](actions.userToggleOpStatusViewAll, this.d3Updater);
    }
    if (this.props.elementName === 'flights') {
      subMiddle[mode](actions.userToggleTimeLabelView, this.d3Updater);
      subMiddle[mode](actions.userToggleFlightSelection, this.d3Updater);
      subMiddle[mode](actions.userTimelineSelectionEnd, this.d3Updater);
      subMiddle[mode](actions.userSelectFlightsForDnDEnd, this.d3Updater);
      subMiddle[mode](actions.userCloseDndModal, this.d3Updater);
      subMiddle[mode](actions.userChangeTailForFlights.done, this.d3Updater);
      subMiddle[mode](actions.userCancelDraggingFlights, this.d3Updater);
    }
    if (this.props.elementName === 'mergedAvailabilityNotes') {
      subMiddle[mode](
        actions.userHoverTooltipNote,
        this.handleHoverNoteTooltip
      );
      subMiddle[mode](
        actions.userUnhoverTooltipNote,
        this.handleUnhoverNoteTooltip
      );
    }
  }
  componentDidMount() {
    const state = reduxStore.getState();
    this.d3Updater(null, state);
    this.toogleUpdateSubscription('on');
  }
  componentWillUnmount() {
    this.toogleUpdateSubscription('off');
  }
  getRootRef = ref => {
    this.rootGroupRef = ref;
  };
  getDataMapper = () => {
    switch (this.props.elementName) {
      case 'flights':
        return flightDataMapper;
      case 'crewAssignment':
      case 'crewRoster':
        return crewDataMapper;
      case 'emptyLegOffers':
        return emptyLegDataMapper;
      case 'oneWayOffers':
        return oneWayDataMapper;
      case 'maintenances':
        return maintenanceDataMapper;
      case 'maintenanceItems':
      case 'mergedMaintenanceItems':
        return maintenanceItemDataMapper;
      case 'availabilityNotes':
      case 'mergedAvailabilityNotes':
      case 'generalNotes':
      case 'mergedGeneralNotes':
        return noteDataMapper;
      case 'overlappedMaintenances':
        return overlappedMaintenancesDataMapper;
      case 'overlappedFlights':
        return overlappedFlightsMapper;
      case 'overlappedFlightWithMaintenances':
        return overlappedFlightWithMaintenancesMapper;
      case 'groundTime':
        return groundTimeTypeDataMapper;
      case 'fboMismatches':
        return fboMismatchDataMapper;
      case 'overlap-flight-el-ow':
        return overlappedFlightEmptyLegOneWayDataMapper;
      default:
        return plainDataMapper;
    }
  };
  update = data => {
    const { ui } = reduxStore.getState();
    if (ui.isScrollingVertically || ui.isScrollingHorizontally) return;
    if (!this.linkedSelection) {
      this.linkedSelection = d3S
        .select(this.rootGroupRef)
        .selectAll('.label-text');
    }
    const mappedWithData = this.linkedSelection.data(
      data.map(this.buildPropsForComponent),
      this.getDataMapper()
    );
    const exit = mappedWithData.exit();
    exit.remove();
    this.linkedSelection = this.renderEntered(mappedWithData.enter()).merge(
      mappedWithData
    );
  };
  renderEntered(entered) {
    if (this.props.hasRootId) {
      return this.props
        .componentD3creator(entered)
        .attr('data-test-entity-id', (d: EventElement) => d.id);
    }
    return this.props.componentD3creator(entered);
  }
  rectWidthCalculator = (d: ExtendedEventElement) => {
    const { transform } = reduxStore.getState().ui;
    const width = transform.scaleX(d.end) - transform.scaleX(d.start);
    return this.props.elementName === 'maintenances' ||
      this.props.elementName === 'crewAssignment'
      ? width
      : Math.max(10, width);
  };
  rectTopCalculator = (d: EventElement) => {
    const state = reduxStore.getState();
    const { rowHeight, transform } = state.ui;
    return (
      getAircraftIndexMapExcludingHolding(state)[d.aircraftId] *
        rowHeight *
        transform.ky +
      transform.translateY
    );
  };
  rectCenterCalculator = (d: EventElement) => {
    const { transform } = reduxStore.getState().ui;
    return transform.scaleX((d.end + d.start) / 2);
  };
  rectLeftCalculator = d =>
    this.rectCenterCalculator(d) - this.rectWidthCalculator(d) / 2;
  rectLeftCalculatorPx = d => `${this.rectLeftCalculator(d)}px`;
  rectWidthCalculatorPx = d => `${this.rectWidthCalculator(d)}px`;
  rectTopCalculatorPx = (d: EventElement) => `${this.rectTopCalculator(d)}px`;
  buildPropsForComponent = (d: EventElement) => {
    const state = reduxStore.getState();
    const { timelineEvents, ui } = state;
    const {
      transform,
      timeLabelDisplayMode,
      rowHeight,
      segmentsVisibility,
      positionMap,
    } = ui;
    const { togglersState } = state.aircraft;
    const { elementName } = this.props;
    const newD = isFlight(d)
      ? (Object.create(d) as ExtendedEventElement)
      : ({ ...d } as ExtendedEventElement);
    newD.x = this.rectLeftCalculator(d);
    newD.airportsDataLoaded = state.airports.airportsLoadingComplete;
    newD.width = this.rectWidthCalculator(newD);
    newD.ky = transform.ky;
    newD.rh = rowHeight;
    newD.laneKoef = getLaneHeightKoef(
      segmentsVisibility,
      togglersState[d.aircraftId],
      positionMap
    );
    newD.y = this.rectTopCalculator(newD);
    if (elementName === 'flights')
      newD.timeLabelFilterId = timeLabelDisplayMode;
    if (elementName === 'groundTime')
      newD.groundTimeType = timelineEvents.groundTimeType;
    return newD;
  };
  render() {
    const { elementName } = this.props;
    return <div className={`root-${elementName}`} ref={this.getRootRef} />;
  }
}
