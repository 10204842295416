import { axiosAuthed as axios } from '../axios-config';
import { transformToFE } from './timezone-model';

export const getTimezones = () =>
  axios
    .get('/integration/timeline/timezonesById')
    .then(resp => resp.data.map(transformToFE))
    .catch(e => {
      throw e;
    });
