import { PureComponent } from 'react';

interface Props {
  firstLineColor: string;
  secondLineColor: string;
  basicColor: string;
  isGogoWifi: boolean;
}

export default class WifiIcon extends PureComponent<Props> {
  render() {
    const {
      basicColor,
      firstLineColor,
      secondLineColor,
      isGogoWifi,
    } = this.props;
    return (
      <div
        className="aircraft-wifi-icon-wrapper"
        style={{ right: isGogoWifi ? '26px' : '31px' }}
      >
        <svg
          width="14px"
          height="14px"
          viewBox="0 0 24 24"
          version="1.1"
          className="aircraft-wifi-icon-wrapper-svg"
        >
          <g stroke="none" strokeWidth="1" fillRule="evenodd">
            <path
              d="M 5 10 Q 12 1 19 10"
              stroke={firstLineColor}
              strokeWidth="2"
              fill="transparent"
            />
            <path
              d="M 7 12 Q 12 6 17 12"
              stroke={secondLineColor}
              strokeWidth="2"
              fill="transparent"
            />
            <path
              d="M 9 14 Q 12 11 15 14"
              stroke={basicColor}
              strokeWidth="2"
              fill="transparent"
            />
            <circle cx="12" cy="17" r="1.5" fill={basicColor} />
          </g>
        </svg>
        {isGogoWifi ? (
          <span className="aircraft-wifi-icon-wrapper-gogo">GO</span>
        ) : null}
      </div>
    );
  }
}
