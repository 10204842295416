import { isEqual } from 'lodash';
import { PureComponent } from 'react';
import { refreshTimer } from '../../actions';
import { getPermitStyle } from '../../common/flight/flight-colorization';
import { getPermitInfo } from '../../common/flight/flight-labels';
import { subMiddle } from '../../root';
import Flight from '../../types/flight';
import StatusRow from './status-row';

interface Props {
  selectedFlight: Flight;
}
interface State {
  depPermitStyle: string;
  arrPermitStyle: string;
  flightId: number;
}

class AirportPermitsRow extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = AirportPermitsRow.getUpdatedState(this.props.selectedFlight);
  }
  getDepPermits() {
    const { selectedFlight } = this.props;
    return getPermitInfo(selectedFlight, selectedFlight.departureAirportId);
  }
  getArrPermits() {
    const { selectedFlight } = this.props;
    return getPermitInfo(selectedFlight, selectedFlight.arrivalAirportId);
  }
  updateState = () => {
    const updated = AirportPermitsRow.getUpdatedState(
      this.props.selectedFlight
    );
    if (!isEqual(this.state, updated)) {
      this.setState(updated);
    }
  };
  toogleUpdateSubscription(mode: 'on' | 'off') {
    subMiddle[mode](refreshTimer, this.updateState);
  }
  componentDidMount() {
    this.toogleUpdateSubscription('on');
  }
  componentWillUnmount() {
    this.toogleUpdateSubscription('off');
  }
  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.selectedFlight.id !== state.flightId) {
      return AirportPermitsRow.getUpdatedState(props.selectedFlight);
    }
    return null;
  }
  static getUpdatedState(flight: Flight) {
    return {
      flightId: flight.id,
      depPermitStyle: getPermitStyle(flight, flight.departureAirportId),
      arrPermitStyle: getPermitStyle(flight, flight.arrivalAirportId),
    };
  }
  render() {
    return (
      <>
        <div className="ft-name">Airport Permits</div>
        <StatusRow
          arrClassName={this.state.arrPermitStyle}
          arrText={this.getArrPermits()}
          depClassName={this.state.depPermitStyle}
          depText={this.getDepPermits()}
        />
      </>
    );
  }
}

export default AirportPermitsRow;
