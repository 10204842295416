import { PureComponent } from 'react';
import { Popover, Button } from 'antd';
import { ElementsContent } from './content';
import { createDelayedForScrollConnect } from '../../../utils/create-delayed-for-scroll-connect';
import { RootState } from '../../../reducers';
import { connect } from 'react-redux';
import { getButtonSize } from '../utils';

export interface StateProps {
  isVerticalMode: boolean;
}

class FilterByElements extends PureComponent<StateProps> {
  render() {
    const { isVerticalMode } = this.props;
    return (
      <Popover content={<ElementsContent />} placement="bottom">
        <Button type="dashed" size={getButtonSize(isVerticalMode)}>
          Elements
        </Button>
      </Popover>
    );
  }
}
const advancedMap = createDelayedForScrollConnect(
  (state: RootState): StateProps => ({
    isVerticalMode: state.ui.isVerticalMode,
  })
);

export const FilterByElementsConnected = connect(advancedMap)(FilterByElements);
