import { chain, cloneDeep, uniq } from 'lodash';
import {
  isOwnedAndIsNotOperatedByVJ,
  isOwnedAndIsOperatedByVJ,
  isSubCharter,
} from '../common/aircraft/aircraft-check-status';
import {
  HOLD_AC_TYPE_ID,
  RWA_OPERATING_COMPANY_ID,
  VJ_OPERATING_COMPANY_ID,
  XO_JET_OPERATING_COMPANY_ID,
} from '../constants/environment';
import Aircraft, {
  FilterByAircraftType,
  FilterByAircraft,
  SelectAllAircraftPayload,
  SelectOperatingCompanyPayload,
  SelectAircraftOrActypePayload,
} from '../types/aircraft';

export const sortByOperatingCompany = (
  ac: Aircraft,
  baseCompaniesIds: number[]
): 0 | 1 | 2 => {
  if (isOwnedAndIsOperatedByVJ(ac, baseCompaniesIds)) return 0;
  if (isOwnedAndIsNotOperatedByVJ(ac, baseCompaniesIds)) return 1;
  return 2;
};

export const isInOperationalRangeByDates = (
  ac: Aircraft,
  start: number,
  end: number
): boolean => {
  if (ac.operationStartDate && end && ac.operationStartDate > end) return false;
  if (!ac.operationEndDate) return true;
  if (start && ac.operationEndDate < start) return false;
  if (end && ac.operationEndDate < end) return false;
  if (start && ac.operationStartDate > start) return false;
  return true;
};

export const filterSortAircraftForAllOptions = (
  start: number,
  end: number,
  aircraft: Aircraft[],
  baseCompaniesIds: number[]
): Aircraft[] =>
  chain(
    aircraft
      .filter(ac => !isSubCharter(ac, baseCompaniesIds))
      .filter(ac => isInOperationalRangeByDates(ac, start, end))
      .filter(ac => ac.aircraftTypeId !== HOLD_AC_TYPE_ID)
  )
    .sortBy('tailNumber')
    .sortBy('aircraftTypeRank')
    .sortBy((ac: Aircraft) => sortByOperatingCompany(ac, baseCompaniesIds))
    .value();

export const sortByCompanyRank = (operatingCompanyId: number): number => {
  if (operatingCompanyId === VJ_OPERATING_COMPANY_ID) return 0;
  if (operatingCompanyId === XO_JET_OPERATING_COMPANY_ID) return 1;
  if (operatingCompanyId === RWA_OPERATING_COMPANY_ID) return 2;
  return 3;
};

export const hydrateVisibleAcTypesByOpCompanyConfig = (props: {
  acTypesOptionsByOperatingCompanyMap: {
    [companyId: number]: FilterByAircraft[];
  };
  visibleAcTypes: number[];
  visibleOperatingCompanies: number[];
}): { [companyId: number]: number[] } => {
  const {
    visibleAcTypes,
    visibleOperatingCompanies,
    acTypesOptionsByOperatingCompanyMap,
  } = props;
  return visibleOperatingCompanies.reduce((acc, companyId) => {
    if (acTypesOptionsByOperatingCompanyMap[companyId]) {
      const acTypesOnCompany = acTypesOptionsByOperatingCompanyMap[
        companyId
      ].map(f => f.id);
      const acTypes = acTypesOnCompany.filter(id =>
        visibleAcTypes.includes(id)
      );
      return {
        ...acc,
        [companyId]: acTypes,
      };
    }
    return acc;
  }, {});
};

export const getAircraftLocationStatus = (aircraft: Aircraft): string =>
  aircraft.location || aircraft.airportName || '';

export const hydrateVisibleAircraftIds = (
  aircraft: Aircraft[],
  visibleAircraftTypeByOperatingCompanyMap: { [companyId: number]: number[] }
) =>
  aircraft.reduce((acc, tail) => {
    if (
      visibleAircraftTypeByOperatingCompanyMap[
        tail.operatingCompanyId
      ]?.includes(tail.aircraftTypeId)
    ) {
      acc.push(tail.id);
    }
    return acc;
  }, []);

type ReturnAllAircraftOptionsType = Omit<SelectAllAircraftPayload, 'selected'>;
export const getAllAircraftOptionsAndIds = (acTypesByCompanyIdMap: {
  [id: number]: FilterByAircraftType[];
}): ReturnAllAircraftOptionsType =>
  Object.keys(acTypesByCompanyIdMap).reduce<ReturnAllAircraftOptionsType>(
    (acc, companyId) => {
      const { aircraftIds } = acTypesByCompanyIdMap[companyId].reduce<{
        aircraftIds: number[];
      }>(
        (acc, type) => {
          acc.aircraftIds = (acc.aircraftIds || []).concat(
            type.tails.map(t => t.id)
          );
          return acc;
        },
        { aircraftIds: [] }
      );
      acc.allOptionsMap = {
        ...acc.allOptionsMap,
        [companyId]: acTypesByCompanyIdMap[companyId].map(type => type.id),
      };
      acc.allAcIds = acc.allAcIds.concat(aircraftIds);
      return acc;
    },
    { allOptionsMap: {}, allAcIds: [] }
  );

type ReturnCompanyTypeAndAircraftIdsType = {
  companiesAcTypeIds: number[];
  companyAircraftIds: number[];
};
export const getCompanyAcTypeIdsAndAircraftIds = (
  filterByAircraftType: FilterByAircraftType[]
): ReturnCompanyTypeAndAircraftIdsType =>
  filterByAircraftType?.reduce<ReturnCompanyTypeAndAircraftIdsType>(
    (acc, c) => {
      acc.companiesAcTypeIds.push(c.id);
      const tailIds = c.tails.map(t => t.id);
      acc.companyAircraftIds.push(...tailIds);
      return acc;
    },
    { companiesAcTypeIds: [], companyAircraftIds: [] }
  );

export const sortOrderByDnd = (
  sequenceCompanyIdAircraft: number[],
  visibleAcTypes: { [companyId: number]: number[] }
) => {
  const resultOrder = [];
  const type = cloneDeep(visibleAcTypes);
  sequenceCompanyIdAircraft.forEach(el => {
    let obj = { operatingCompanyId: +el, aircraftTypeId: type[+el][0] };
    resultOrder.push(obj);
    type[el].shift();
  });
  return resultOrder;
};

interface StateUnionFiltersConfig {
  visibleAircraftTypeByOperatingCompanyMap: {};
  visibleAcIds: number[];
}

export const getStateOnToggleOperator = (
  payload: SelectOperatingCompanyPayload,
  stateMap: { [companyId: number]: number[] },
  stateAcIds: number[]
): StateUnionFiltersConfig => {
  const {
    companiesAcTypeIds,
    companyId,
    companyAircraftIds,
    checked,
  } = payload;
  let visibleAcIds = [...stateAcIds];
  let visibleAircraftTypeByOperatingCompanyMap = { ...stateMap };
  if (checked) {
    // add aircraft ids
    visibleAcIds.push(...companyAircraftIds);
    visibleAcIds = uniq(visibleAcIds);
    // add all actype ids to this operator
    visibleAircraftTypeByOperatingCompanyMap[companyId] = companiesAcTypeIds;
  } else {
    // remove operator from visibility map
    delete visibleAircraftTypeByOperatingCompanyMap[companyId];
    // remove all operator's aircraftIds from visible ids
    visibleAcIds = removeDuplicates(visibleAcIds, companyAircraftIds);
  }
  return {
    visibleAircraftTypeByOperatingCompanyMap,
    visibleAcIds,
  };
};

export const getStateOnToggleAircraftOrActype = (
  payload: SelectAircraftOrActypePayload,
  stateMap: { [companyId: number]: number[] },
  stateAcIds: number[]
): StateUnionFiltersConfig => {
  const {
    acTypeId,
    companyId,
    aircraftIdsOnThisType,
    isToRemoveAcType,
    checked,
  } = payload;
  let visibleAcIds = [...stateAcIds];
  let visibleAircraftTypeByOperatingCompanyMap = { ...stateMap };
  if (checked) {
    // add to visible ids
    visibleAcIds.push(...aircraftIdsOnThisType);
    visibleAcIds = uniq(visibleAcIds);
    // add actypeIds to operator and operator itself to visibility map
    if (visibleAircraftTypeByOperatingCompanyMap[companyId]) {
      visibleAircraftTypeByOperatingCompanyMap[companyId].push(acTypeId);
    } else {
      visibleAircraftTypeByOperatingCompanyMap[companyId] = [acTypeId];
    }
  } else {
    // remove from visible ids
    visibleAcIds = removeDuplicates(visibleAcIds, aircraftIdsOnThisType);
    // remove from operator's actype ids if it was last one
    if (isToRemoveAcType) {
      visibleAircraftTypeByOperatingCompanyMap[
        companyId
      ] = visibleAircraftTypeByOperatingCompanyMap[companyId].filter(
        id => id !== acTypeId
      );
    }
    // to remove operator if it was the last actype
    if (!visibleAircraftTypeByOperatingCompanyMap[companyId].length) {
      delete visibleAircraftTypeByOperatingCompanyMap[companyId];
    }
  }
  return {
    visibleAcIds,
    visibleAircraftTypeByOperatingCompanyMap,
  };
};

export const getAcFilterMapFromTailIds = (
  aircraftByIdMap: {
    [id: number]: Aircraft;
  },
  tailIds: number[]
): { [companyId: number]: number[] } =>
  tailIds.reduce((acc, id) => {
    const aircraft: Aircraft = aircraftByIdMap[id];
    if (aircraft) {
      acc[aircraft.operatingCompanyId] = (
        acc[aircraft.operatingCompanyId] || []
      ).find(id => id === aircraft.aircraftTypeId)
        ? acc[aircraft.operatingCompanyId]
        : (acc[aircraft.operatingCompanyId] || []).concat(
            aircraft.aircraftTypeId
          );
      return acc;
    }
    return acc;
  }, {});

export const removeDuplicates = <T>(from: T[], what: T[]): T[] => {
  return from.filter(id => !what.includes(id));
};
