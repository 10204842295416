import { message } from 'antd';
import { ApolloQueryResult } from '@apollo/client';
import { utc } from 'moment';
import {
  all,
  call,
  delay,
  put,
  race,
  select,
  spawn,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { Action, Success } from 'typescript-fsa';

import * as actions from '../actions';
import { getSegmentsWithDayRangesForNonFetchedDays } from '../common/sagas-common';
import ContactSupportTeamMessage from '../components/contact-support-team';
import { isFlightVisible } from '../selectors/event-elements';
import ResponseSizeLimitError from '../errors/ResponseSizeLimitError';
import { RootState } from '../reducers';
import { getSelectedStatusesIds } from '../reducers/operational-statuses';
import {
  getAircraftIndexMapWithHolding,
  getAllAvailableAircraftByIdMap,
  getAvailableAircraftTypesByOperatingCompanyMap,
  getHoldFlightsPoolDividedByTimeRange,
  getVisibleHoldAircraft,
  isFilteredSegmentType,
} from '../selectors';
import { getFPBPM } from '../services/bpm-services/get-fp-bpm';
import { getPFABPM } from '../services/bpm-services/get-pfa-bpm';
import { changeTailForMultipleFlightsMutation } from '../services/change-tail-for-multiple-flights/change-tail-for-multiple-flights';
import {
  getCrewAssignments,
  getCrewRosters,
} from '../services/crew-service/service';
import { getEmptyLegOffers } from '../services/empty-leg-offer-service/service';
import {
  getFlightById,
  getFlightsByOrderId,
} from '../services/flight-by-service/service';
import { getFlights } from '../services/flight-service/service';
import { getMaintenances } from '../services/maintenance-service/service';
import { markFlightMutation } from '../services/mark-flight';
import { getNotes } from '../services/note-service/service';
import { getOneWayOffers } from '../services/one-way-offer-service/service';
import {
  getPreferences,
  savePreferences,
} from '../services/user-preferences/service';
import { connectWs } from '../services/webSocket';
import EventElement from '../types/event-element';
import Flight, { UpgradeReason } from '../types/flight';
import {
  AutoAdvertisementInput,
  ChangeTailForMultipleFlightsMutation,
  GetFPBPMQuery,
  GetPFABPMQuery,
  MarkFlightMutation,
} from '../types/operation-result-types';
import { SegmentType } from '../types/segment-types';
import { UserPreferences } from '../types/user-preferences';
import {
  getLoadingTimeKeyNameBySegmentType,
  getNotFetchedDaysRangeList,
} from '../utils';
import {
  getAcFilterMapFromTailIds,
  hydrateVisibleAcTypesByOpCompanyConfig,
  hydrateVisibleAircraftIds,
} from '../utils/aircraft';
import { hasPermission } from '../utils/check-permissions';
import { sendErrorToRaygun } from '../utils/raygun-middleware';
import {
  getAircraftSaga,
  getPeakDaysSaga,
  getBaseCompaniesSaga,
  getMaintenanceItemsSaga,
} from './mapper-vistajet';
import { fetchCommonDataMappers } from './mappers-common';
import { saveFerryAutoAdvertisementMutation } from '../services/empty-leg-offer-service/save-Ferry-Auto-Advertisement';
import { updateDefaultUserPreferences } from '../utils/user-preferences';
import { SearchLegPayload } from '../actions';
import {
  MAX_RECONNECT_ATTEMPTS,
  ONE_MINUTE_MS,
  ONE_SECOND_MS,
} from '../constants';
import { handleError } from '../components/error-handling/utils';
import { getGlobalHudPFAService } from '../services/global-hud-service';
import { getNotFetchedDays } from '../reducers/timeline-events';
import { getTimeSegments } from '../utils/time';
import { WsUpdateBatch } from '../data-processing/ws-income/ws-update-batch';
import { isEqual } from 'lodash/fp';

export const SEGMENT_SERVICES: {
  [type in SegmentType]?: (start: number, end: number) => Promise<EventElement>;
} = {
  flights: getFlights,
  crewAssignment: getCrewAssignments,
  emptyLegOffers: getEmptyLegOffers,
  crewRoster: getCrewRosters,
  oneWayOffers: getOneWayOffers,
  availabilityNotes: getNotes,
  maintenances: getMaintenances,
};
export function* getSegmentElementsSaga(params: {
  segmentType: SegmentType;
  dayRange: [number, number];
}) {
  const { segmentType, dayRange } = params;
  const [start, end] = dayRange;
  yield put(actions.getSegmentRequest.started(params));
  try {
    const startFetching = new Date().valueOf();
    const result = yield call(SEGMENT_SERVICES[segmentType], start, end);
    const endFetching = new Date().valueOf();
    yield put(
      actions.getSegmentRequest.done(
        {
          params,
          result,
        },
        {
          [getLoadingTimeKeyNameBySegmentType(segmentType)]:
            endFetching - startFetching,
        }
      )
    );
  } catch (error) {
    if (
      error instanceof ResponseSizeLimitError &&
      !ResponseSizeLimitError.hasThrown()
    ) {
      message.error(
        ContactSupportTeamMessage(
          'Flights can be not visible due to response size limit'
        ),
        0
      );
    }
    yield put(actions.getSegmentRequest.failed({ params, error }));
  }
}

function* spawningLogger(
  notFetchedDaysRange: { start: number; end: number }[]
) {
  const hasExistPermissions = yield select((state: RootState) => ({
    emptyLegOffers: hasPermission(state, 'AG-Timeline-View-EL-Offer'),
    oneWayOffers: hasPermission(state, 'AG-Timeline-View-OW-Offer'),
  }));
  yield all(
    getSegmentsWithDayRangesForNonFetchedDays(
      notFetchedDaysRange,
      hasExistPermissions
    ).map((params: { segmentType: SegmentType; dayRange: [number, number] }) =>
      call(getSegmentElementsSaga, params)
    )
  );
}

function* loadSegmentForHandoverSaga(
  action: Action<actions.ChangeDaysRangeFilterPayload>
) {
  const { value } = action.payload;
  if (!value) return;
  const { hasExistPermissions, notFetchedDays } = yield select(
    (state: RootState) => ({
      notFetchedDays: getTimeSegments(
        getNotFetchedDays(
          state.timelineEvents.fetchedDaysMap,
          utc(
            state.handoverRemarks.filters.from ||
              utc(state.ui.transform.scaleX.invert(0))
                .subtract(1, 'd')
                .startOf('day')
                .valueOf()
          ),
          utc(
            state.handoverRemarks.filters.to ||
              utc(state.ui.transform.scaleX.invert(state.ui.width))
                .endOf('day')
                .valueOf()
          )
        )
      ),
      hasExistPermissions: {
        emptyLegOffers: hasPermission(state, 'AG-Timeline-View-EL-Offer'),
        oneWayOffers: hasPermission(state, 'AG-Timeline-View-OW-Offer'),
      },
    })
  );
  if (notFetchedDays?.length > 0) {
    try {
      const startingRunning = new Date().valueOf();
      yield put(
        actions.doSegmentsFetchingByDaysRange.started({
          fetchedDays: notFetchedDays,
          totalSegmentsFetchingByDaysRangeLoadingTime: 0,
        })
      );
      yield all(
        getSegmentsWithDayRangesForNonFetchedDays(
          notFetchedDays,
          hasExistPermissions
        ).map(
          (params: { segmentType: SegmentType; dayRange: [number, number] }) =>
            call(getSegmentElementsSaga, params)
        )
      );
      yield put(
        actions.doSegmentsFetchingByDaysRange.done({
          params: null,
          result: {
            totalSegmentsFetchingByDaysRangeLoadingTime:
              new Date().valueOf() - startingRunning,
          },
        })
      );
    } catch (error) {
      yield put(
        actions.doSegmentsFetchingByDaysRange.failed({
          fetchedDays: notFetchedDays,
        } as any)
      );
    }
  } else return;
}

function* limitedWebsocketReconnect() {
  let reloadAttemptIteration = 1;
  let timeout = null;
  while (true) {
    yield take(actions.webSocketConnectionClosed);
    const showSupportMsg = yield select(
      (state: RootState) => state.ui.showContactSupportMsg
    );
    if (showSupportMsg) continue;
    if (reloadAttemptIteration === MAX_RECONNECT_ATTEMPTS) {
      clearTimeout(timeout);
      yield put(actions.doShowContactSupportMessage());
      message.error(ContactSupportTeamMessage(), 0);
      yield put(
        actions.setReInitLoadingDataOnWebSocketConnectionLost.failed(null)
      );
      continue;
    }
    if (timeout) {
      clearTimeout(timeout);
    }
    yield spawn(wsClosingNotifier);
    timeout = setTimeout(() => (reloadAttemptIteration = 1), ONE_MINUTE_MS);
    if (reloadAttemptIteration > 1) {
      yield delay(
        reloadAttemptIteration > 3 ? 30 * ONE_SECOND_MS : 10 * ONE_SECOND_MS
      );
      yield call(timeLineReloadingData);
      reloadAttemptIteration++;
      continue;
    }
    yield call(timeLineReloadingData);
    reloadAttemptIteration++;
  }
}
function* timeLineReloadingData() {
  const showSupportMsg = yield select(
    (state: RootState) => state.ui.showContactSupportMsg
  );
  if (showSupportMsg) return;
  yield put(
    actions.setReInitLoadingDataOnWebSocketConnectionLost.started(null)
  );
  try {
    const days = yield select((state: RootState) => ({
      start: utc(state.ui.transform.scaleX.invert(0))
        .subtract(1, 'd')
        .startOf('day')
        .valueOf(),
      end: utc(state.ui.transform.scaleX.invert(state.ui.width))
        .endOf('day')
        .valueOf(),
      yesterday: utc()
        .subtract(1, 'day')
        .startOf('day')
        .valueOf(),
      tomorrow: utc()
        .add(1, 'day')
        .endOf('day')
        .valueOf(),
    }));
    yield all([
      spawn(fetchCommonDataMappers),
      spawn(fetchVistaJetDataMappers),
      ...getNotFetchedDaysRangeList(days).map(({ start, end }) =>
        spawn(spawningLogger, [{ start, end }])
      ),
    ]);
    yield put(actions.setReInitLoadingDataOnWebSocketConnectionLost.done(null));
  } catch (error) {
    sendErrorToRaygun(error, '', JSON.stringify(error));
    yield put(
      actions.setReInitLoadingDataOnWebSocketConnectionLost.failed(error)
    );
  }
}

function* searchLeg(action: Action<SearchLegPayload>) {
  try {
    const result: Flight = yield call(
      getFlightById as (id: number) => Promise<Flight>,
      +action.payload.id
    );
    yield put(
      actions.userSearchLeg.done({
        params: action.payload,
        result,
      })
    );
    if (!result) {
      message.warning('Cannot find flight by leg id - ' + action.payload.id);
      return;
    }
    const visibleOperationalStatuses = yield select((state: RootState) =>
      getSelectedStatusesIds(state)
    );
    const aircraftIndexMap = yield select((state: RootState) =>
      getAircraftIndexMapWithHolding(state)
    );
    const segmentVisibility = yield select(
      (state: RootState) => state.ui.segmentsVisibility
    );
    if (
      !isFlightVisible({
        f: result,
        visibleOperationalStatuses,
        aircraftIndexMap,
      }) ||
      isFilteredSegmentType('flights', segmentVisibility)
    ) {
      message.warning('Found flight is hidden by your filter settings');
    }
  } catch (error) {
    yield put(actions.userSearchLeg.failed(error));
  }
}

function* searchOrder(action: Action<number>) {
  try {
    const result = yield call(
      getFlightsByOrderId as (id: number) => Promise<Flight[]>,
      +action.payload
    );
    yield put(
      actions.userSearchOrder.done({
        params: action.payload,
        result,
      })
    );
    if (result.length == 0) {
      message.warning('Cannot find flights by order id - ' + action.payload);
      return;
    }
    const visibleOperationalStatuses = yield select((state: RootState) =>
      getSelectedStatusesIds(state)
    );
    const aircraftIndexMap = yield select((state: RootState) =>
      getAircraftIndexMapWithHolding(state)
    );
    const segmentVisibility = yield select(
      (state: RootState) => state.ui.segmentsVisibility
    );
    if (
      result.every(
        (f: Flight) =>
          !isFlightVisible({
            f,
            visibleOperationalStatuses,
            aircraftIndexMap,
          })
      ) ||
      isFilteredSegmentType('flights', segmentVisibility)
    ) {
      message.warning(
        `Found flight${
          result.length > 1 ? 's are' : ' is'
        } hidden by your filter settings`
      );
      return;
    }
    if (
      result.some(
        (f: Flight) =>
          !isFlightVisible({
            f,
            visibleOperationalStatuses,
            aircraftIndexMap,
          })
      )
    ) {
      message.warning('Some flights are hidden by your filter settings');
    }
  } catch (error) {
    yield put(actions.userSearchOrder.failed(error));
  }
}
function* changeTailForFlights(
  action: Action<{
    flights: Flight[];
    newAircraftId: number;
    upgradeReason: { [flightId: number]: UpgradeReason };
  }>
) {
  try {
    const response: ApolloQueryResult<ChangeTailForMultipleFlightsMutation> = yield call(
      changeTailForMultipleFlightsMutation,
      action.payload.flights,
      action.payload.newAircraftId,
      action.payload.upgradeReason
    );
    if (!response?.data?.userChangeTailForMultipleFlights) {
      const mes = `Failed to move cancelled flight ${action.payload.flights
        .map(f => f.id)
        .join(', ')}`;
      message.error(mes);
      throw new Error(mes);
    }
    yield put(
      actions.userChangeTailForFlights.done({
        params: action.payload,
        result: response,
      })
    );
    message.success('Tail has been changed');
  } catch (error) {
    yield put(actions.userChangeTailForFlights.failed(error));
  }
}

function* markFlightFunction(
  action: Action<{
    flight: Flight;
    newDemo: boolean;
    newLocked: boolean;
    newSensitive: boolean;
    newLineCheck: boolean;
  }>
) {
  try {
    const response: ApolloQueryResult<MarkFlightMutation> = yield call(
      markFlightMutation,
      action.payload.flight,
      action.payload.newDemo,
      action.payload.newLocked,
      action.payload.newSensitive,
      action.payload.newLineCheck
    );
    yield put(
      actions.userMarkFlight.done({
        params: action.payload,
        result: response,
      })
    );
  } catch (error) {
    yield put(actions.userMarkFlight.failed(error));
  }
}

function* getPFABPMSaga(action: Action<number>) {
  try {
    const response: ApolloQueryResult<GetPFABPMQuery> = yield call(
      getPFABPM,
      action.payload
    );
    const pfaBPM = response?.data?.pfaBPM;
    const linkToPFA = pfaBPM?.linkToPFA;
    const fault = pfaBPM?.fault;
    if (!linkToPFA && !!fault) {
      message.error(fault);
    } else if (!fault && !!linkToPFA) {
      window.open(linkToPFA);
      yield put(
        actions.userOpenPFA.done({
          params: action.payload,
          result: linkToPFA,
        })
      );
    } else {
      console.error(Error('Bad SOAP response. PFA link was not provided.'));
    }
  } catch (error) {
    yield put(actions.userOpenPFA.failed(error));
  }
}
function* getGlobalHudPFALinkSaga(
  action: Action<{
    orderId: number;
    operatingCompanyId: number;
  }>
) {
  const { orderId } = action.payload;
  try {
    const response = yield call(
      getGlobalHudPFAService,
      action.payload.orderId,
      action.payload.operatingCompanyId
    );
    if (response?.url) {
      window.open(response.url);
      yield put(
        actions.userOpenGlobalHudPFA.done({
          params: action.payload,
          result: response.url,
        })
      );
    } else {
      const message = `Can't open PFA task for order# ${orderId}.\nPlease, try again later or contact support team`;
      handleError({
        content: '',
        error: new Error(message),
        message,
      });
      yield put(
        actions.userOpenGlobalHudPFA.failed({
          error: response?.message,
          params: action.payload,
        })
      );
    }
  } catch (error) {
    yield put(
      actions.userOpenGlobalHudPFA.failed({
        error,
        params: action.payload,
      })
    );
  }
}
function* getFPBPMSaga(action: Action<number>) {
  try {
    const response: ApolloQueryResult<GetFPBPMQuery> = yield call(
      getFPBPM,
      action.payload
    );
    const fpBPM = response?.data?.fpBPM;
    const linkToFP = fpBPM?.linkToFP;
    const fault = fpBPM?.fault;
    if (!linkToFP && !!fault) {
      message.error(fault);
    } else if (!fault && !!linkToFP) {
      window.open(linkToFP);
      yield put(
        actions.userOpenFP.done({
          params: action.payload,
          result: linkToFP,
        })
      );
    } else {
      console.error(Error('Bad SOAP response. FP link was not provided.'));
    }
  } catch (error) {
    yield put(actions.userOpenFP.failed(error));
  }
}

function* wsClosingNotifier() {
  const hideIssuesInformMessage = message.warning(
    'Connection lost, we are reloading data...',
    0
  );
  const { success, fail } = yield race({
    timeout: delay(ONE_MINUTE_MS * 5),
    success: take(actions.setReInitLoadingDataOnWebSocketConnectionLost.done),
    fail: take(actions.setReInitLoadingDataOnWebSocketConnectionLost.failed),
  });
  hideIssuesInformMessage();
  if (success) message.success('Reloading data complete!', 1.5);
  if (!success && !fail) {
    const showSupportMsg = yield select(
      (state: RootState) => state.ui.showContactSupportMsg
    );
    if (!showSupportMsg) {
      yield put(actions.doShowContactSupportMessage());
      message.error(ContactSupportTeamMessage(), 0);
    }
  }
}

export function* fetchVistaJetDataMappers() {
  yield all([
    spawn(getAircraftSaga),
    spawn(getPeakDaysSaga),
    spawn(getBaseCompaniesSaga),
    spawn(getMaintenanceItemsSaga),
  ]);
}

function* getPreferencesSaga() {
  yield put(actions.doGetUserPreferences.started(null));
  try {
    let result = yield call(getPreferences);
    if (!result) {
      message.error('Failed to fetch user preferences');
      result = { data: updateDefaultUserPreferences() };
    }
    const data: UserPreferences = result.data;
    let modifiedResult = data;
    // hydrate aircraft filter user preferences from previous config
    if (
      !data.visibleAircraftTypeByOperatingCompanyMap ||
      !Object.keys(data.visibleAircraftTypeByOperatingCompanyMap).length
    ) {
      const acTypesOptionsByOperatingCompanyMap = yield select(
        (state: RootState) =>
          getAvailableAircraftTypesByOperatingCompanyMap(state)
      );
      const filterConfigUpdated = hydrateVisibleAcTypesByOpCompanyConfig({
        visibleAcTypes: data.visibleACTypes,
        visibleOperatingCompanies: data.visibleOperatingCompanies,
        acTypesOptionsByOperatingCompanyMap,
      });
      modifiedResult = {
        ...data,
        visibleAircraftTypeByOperatingCompanyMap: filterConfigUpdated,
      };
    }
    const isAircraftLoaded = yield select(
      (state: RootState) => state.aircraft.statusLoading.vistajet
    );
    if (
      isAircraftLoaded &&
      typeof data.visibleAcIds !== 'undefined' &&
      typeof data.visibleAircraftTypeByOperatingCompanyMap !== 'undefined'
    ) {
      const aircraftMap = yield select((state: RootState) =>
        getAllAvailableAircraftByIdMap(state)
      );
      const mapFromIds = getAcFilterMapFromTailIds(
        aircraftMap,
        data.visibleAcIds
      );
      if (!isEqual(data.visibleAircraftTypeByOperatingCompanyMap, mapFromIds)) {
        modifiedResult = {
          ...data,
          visibleAircraftTypeByOperatingCompanyMap: mapFromIds,
        };
      }
    }
    yield put(
      actions.doGetUserPreferences.done({
        params: null,
        result: modifiedResult,
      })
    );
  } catch (e) {
    yield put(actions.doGetUserPreferences.failed(e));
  }
}
function* saveUserPreferencesSaga(action: Action<string>) {
  try {
    const response = yield call(savePreferences, action.payload);
    yield put(
      actions.userSavePreferences.done({
        params: action.payload,
        result: response.data,
      })
    );
    message.success('Settings saved');
  } catch (e) {
    yield put(actions.userSavePreferences.failed(e));
  }
}

type HoldAcHeightCalculationTriggerAction = Action<
  | WsUpdateBatch
  | number[]
  | Success<actions.SegmentFetchParams, actions.ResponseResultTypes[]>
  | Success<void, UserPreferences>
  | Action<undefined>
>;
const isWsUpdateAction = (
  action: HoldAcHeightCalculationTriggerAction
): action is Action<WsUpdateBatch> =>
  action && action.type === actions.wsUpdateBatch.type;
const isSegmentFetchAction = (
  action: HoldAcHeightCalculationTriggerAction
): action is Action<
  Success<actions.SegmentFetchParams, actions.ResponseResultTypes[]>
> => action && action.type === actions.getSegmentRequest.done.type;
function* doSetHoldAircraftPositionMapSaga(
  action: HoldAcHeightCalculationTriggerAction
) {
  if (
    (isWsUpdateAction(action) &&
      typeof action.payload.data.flights === 'undefined') ||
    (isSegmentFetchAction(action) &&
      action.payload.params.segmentType !== 'flights')
  )
    return;
  const visibleHoldAircraft = yield select((state: RootState) =>
    getVisibleHoldAircraft(state)
  );
  if (
    !visibleHoldAircraft.length &&
    action.type !== actions.userToggleHoldLineTypeView.type
  )
    return;
  const holdFlightsMap = yield select((state: RootState) =>
    getHoldFlightsPoolDividedByTimeRange(state)
  );
  yield put(actions.doSetHeightForHoldAircraft(holdFlightsMap));
}

function* doSetAcTypeConfigSaga() {
  const {
    visibleAcTypes,
    visibleOperatingCompanies,
    visibleAircraftTypeByOperatingCompanyMap,
    aircraft,
    visibleAcIds,
    statusLoading,
  } = yield select((state: RootState) => state.aircraft);
  const hasMap =
    Object.keys(visibleAircraftTypeByOperatingCompanyMap).length > 0;
  // hydrate default config for ac types filter
  if (
    !statusLoading.userPreferences ||
    (statusLoading.userPreferences && hasMap && !visibleAcIds?.length)
  ) {
    const acTypesOptionsByOperatingCompanyMap = yield select(
      (state: RootState) =>
        getAvailableAircraftTypesByOperatingCompanyMap(state)
    );
    const visibleAircraftTypeByOperatingCompanyMap = hydrateVisibleAcTypesByOpCompanyConfig(
      {
        acTypesOptionsByOperatingCompanyMap,
        visibleAcTypes,
        visibleOperatingCompanies,
      }
    );
    const acIds = hydrateVisibleAircraftIds(
      aircraft,
      visibleAircraftTypeByOperatingCompanyMap
    );
    yield put(
      actions.doHydrateAcTypeFiltersConfig({
        map: visibleAircraftTypeByOperatingCompanyMap,
        acIds: visibleAcIds?.length > 0 ? visibleAcIds : acIds,
      })
    );
  } else if (statusLoading.userPreferences && hasMap) {
    const aircraftMap = yield select((state: RootState) =>
      getAllAvailableAircraftByIdMap(state)
    );
    const mapFromIds = getAcFilterMapFromTailIds(aircraftMap, visibleAcIds);
    const mapIsWrong = !isEqual(
      mapFromIds,
      visibleAircraftTypeByOperatingCompanyMap
    );
    if (mapIsWrong) {
      yield put(
        actions.doHydrateAcTypeFiltersConfig({
          map: mapFromIds,
          acIds: visibleAcIds,
        })
      );
    }
  }
}

function* excludeIncludeEmptyLegSaga(action: Action<AutoAdvertisementInput>) {
  try {
    yield call(saveFerryAutoAdvertisementMutation, action.payload);
  } catch (error) {
    sendErrorToRaygun(error);
  }
}

export function* elementsSaga() {
  yield all([
    takeLatest(actions.timelineMounted, () => connectWs()),
    takeLatest(actions.timelineMounted, fetchVistaJetDataMappers),
    takeLatest(actions.userSearchLeg.started, searchLeg),
    takeLatest(actions.userSearchOrder.started, searchOrder),
    takeLatest(actions.userChangeTailForFlights.started, changeTailForFlights),
    takeLatest(actions.userOpenPFA.started, getPFABPMSaga),
    takeLatest(actions.userOpenFP.started, getFPBPMSaga),
    takeLatest(actions.userMarkFlight.started, markFlightFunction),
    limitedWebsocketReconnect(),
    takeLatest(actions.setReInitLoadingDataOnWebSocketConnectionLost.done, () =>
      connectWs()
    ),
    takeLatest(actions.timelineMounted, getPreferencesSaga),
    takeLatest(actions.userSavePreferences.started, saveUserPreferencesSaga),
    takeLatest(
      actions.doGetUserPreferences.done,
      doSetHoldAircraftPositionMapSaga
    ),
    takeLatest(
      actions.getSegmentRequest.done,
      doSetHoldAircraftPositionMapSaga
    ),
    takeLatest(
      actions.userToggleHoldLineTypeView,
      doSetHoldAircraftPositionMapSaga
    ),
    //@ts-ignore
    takeLatest(actions.doStopScroll, doSetHoldAircraftPositionMapSaga),
    takeLatest(actions.wsUpdateBatch, doSetHoldAircraftPositionMapSaga),
    takeLatest(actions.doAircraftMappersFetch.done, doSetAcTypeConfigSaga),
    takeLatest(
      actions.userToggleExcludeIncludeEmptyLeg,
      excludeIncludeEmptyLegSaga
    ),
    takeLatest(actions.userOpenGlobalHudPFA.started, getGlobalHudPFALinkSaga),
    takeLatest(
      actions.userChangeHandoverRemarksRangeFilter,
      loadSegmentForHandoverSaga
    ),
  ]);
}
