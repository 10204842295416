import { Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { utc } from 'moment';
import PeakDay from '../../../types/peak-day';
import { EventListActionButtons } from './components/action-buttons';
import { CheckedIconComponent } from './components/icon-status';
import { getRegion, getPeakDayText } from '../../../utils/peak-days';

export const EVENT_LIST_PEAK_DAYS_COLUMNS: Array<ColumnProps<PeakDay>> = [
  {
    dataIndex: 'start',
    key: 'start',
    title: 'Start',
    width: 100,
    sorter: (a, b) => a.start - b.start,
    defaultSortOrder: 'ascend',
    render: (start: PeakDay['start']) => (
      <>{start !== null ? utc(start).format('DD MMM YY') : ''}</>
    ),
  },
  {
    dataIndex: 'end',
    key: 'end',
    title: 'End',
    width: 100,
    sorter: (a, b) => a.end - b.end,
    render: (end: PeakDay['end']) => (
      <>{end !== null ? utc(end).format('DD MMM YY') : ''}</>
    ),
  },
  {
    dataIndex: 'text',
    key: 'text',
    title: 'Description',
    width: 350,
    ellipsis: true,
    render: (text: PeakDay['text']) => (
      <Tooltip title={text} overlayStyle={{ whiteSpace: 'pre-line' }}>
        <div>{text}</div>
      </Tooltip>
    ),
  },
  {
    dataIndex: 'peakDayTypes',
    key: 'peakDayType',
    title: 'Type',
    width: 150,
    ellipsis: true,
    render: (types: PeakDay['peakDayTypes']) => (
      <Tooltip
        title={getPeakDayText(types)}
        overlayStyle={{ whiteSpace: 'pre-line' }}
      >
        <span>{getPeakDayText(types)}</span>
      </Tooltip>
    ),
  },
  {
    dataIndex: 'peakDayRegions',
    key: 'peakDayRegion',
    title: 'Region',
    width: 150,
    ellipsis: true,
    render: (regions: PeakDay['peakDayRegions']) => (
      <Tooltip
        title={getPeakDayText(regions)}
        overlayStyle={{ whiteSpace: 'pre-line' }}
      >
        <span>{getRegion(regions)}</span>
      </Tooltip>
    ),
  },
  {
    dataIndex: 'isAutoAdvertising',
    key: 'isAutoAdvertising',
    title: 'Auto Advertising',
    className: 'table-icon-row',
    width: 70,
    align: 'center',
    render: (isAutoAdvertising: PeakDay['isAutoAdvertising']) => (
      <CheckedIconComponent checked={isAutoAdvertising} />
    ),
  },
  {
    dataIndex: 'isCrewChange',
    key: 'isCrewChange',
    title: 'Crew Change',
    align: 'center',
    width: 70,
    render: (isCrewChange: PeakDay['isCrewChange']) => (
      <CheckedIconComponent checked={isCrewChange} />
    ),
  },
  {
    dataIndex: 'id',
    key: 'action',
    title: 'Action',
    width: 76,
    align: 'center',
    render: (id: PeakDay['id']) => <EventListActionButtons id={id} />,
  },
];
