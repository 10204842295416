import * as d3S from 'd3-selection';
import { debounce } from 'lodash';
import { PureComponent } from 'react';
import { Action } from 'typescript-fsa';
import { ExtendedEventElement, SubscriptionTuple } from '..';
import * as actions from '../../../../../actions';
import { WsUpdateBatch } from '../../../../../data-processing/ws-income/ws-update-batch';
import { RootState } from '../../../../../reducers';
import {
  fromFlightOverlaps,
  getOverlappedElementsSelector,
  getOverlaps,
  getSingleAndMergedGeneralNotes,
  getSingleAndMergedVisibleMelItems,
} from '../../../../../reducers/timeline-events';
import {
  getElementHeight,
  getElementOffset,
  getInitialScale,
  getLaneHeightKoef,
} from '../../../../../reducers/ui';
import { store, subMiddle } from '../../../../../root';
import {
  getAircraftIndexMapExcludingHolding,
  getVisibleAvailabilityNotes,
  getVisibleElements,
} from '../../../../../selectors';
import EventElement from '../../../../../types/event-element';
import { SegmentType } from '../../../../../types/segment-types';
import { WsUpdateBatchData } from '../../../../../types/ws-income';
import dataMappers, {
  overlappedFlightWithELOrOwDataMapper,
} from '../data-mappers';
import { EventGroupOwnProps } from '../element-types';
import { isGeneralWsUpdateAction } from '../../../../../utils/ws-income';

const {
  flightDataMapper,
  maintenanceDataMapper,
  noteDataMapper,
  oneWayEmptyLegDataMapper,
  plainDataMapper,
  melDataMapper,
} = dataMappers;

export class EventGroup extends PureComponent<EventGroupOwnProps> {
  linkedSelection: d3S.Selection<
    d3S.BaseType,
    ExtendedEventElement,
    SVGGElement,
    {}
  >;
  isBatchForThisElementType: (payload, elementName: SegmentType) => boolean;
  batchUpdater: (action: Action<any>, state: RootState) => void;
  d3Updater: (action: Action<any>, state: RootState) => void;
  scrollProtectedUpdater: (action: Action<any>, state: RootState) => void;
  debouncedUpdater: (action: any, _state: any) => void;
  subscriptions: SubscriptionTuple[];
  constructor(props) {
    super(props);
    if (this.props.ownSubscriptions)
      this.subscriptions = this.props.ownSubscriptions.map(
        ([a, b]) => [a, b.bind(this)] as SubscriptionTuple
      );
    this.scrollProtectedUpdater = (action, state: RootState) => {
      if (state.ui.isScrollingVertically || state.ui.isScrollingHorizontally)
        return;
      this.d3Updater(action, state);
    };
    switch (this.props.elementName) {
      case 'generalNotes': {
        this.d3Updater = (_action, state: RootState) => {
          const allElements = getSingleAndMergedGeneralNotes(state, '');
          this.update(allElements);
        };
        break;
      }
      case 'availabilityNotes': {
        this.d3Updater = (_action, state: RootState) => {
          const availabilityNotes = getVisibleAvailabilityNotes(state);
          this.update(availabilityNotes);
        };
        break;
      }
      case 'maintenanceItems': {
        this.d3Updater = (_action, state: RootState) => {
          const maintenanceItems = getSingleAndMergedVisibleMelItems(state, '');
          this.update(maintenanceItems);
        };
        break;
      }
      case 'overlappedMxs': {
        this.d3Updater = (_action, state: RootState) => {
          const overlappedMxs = getOverlappedElementsSelector(
            state,
            'maintenances'
          );
          this.update(overlappedMxs);
        };

        break;
      }
      case 'overlap-flight-el-ow': {
        this.d3Updater = (_action, state: RootState) => {
          const overlaps = getOverlaps(
            state,
            'flights',
            'oneWayOffers',
            'emptyLegOffers'
          ).filter(fromFlightOverlaps);
          this.update(overlaps);
        };

        break;
      }
      default: {
        this.d3Updater = (action, state: RootState) => {
          const allElements = getVisibleElements(state, props.elementName);
          this.update(allElements);
        };
      }
    }
    this.isBatchForThisElementType = (
      payload: WsUpdateBatchData,
      elementName: SegmentType | 'overlappedMxs'
    ) => {
      const updateKeys = Object.keys(payload) as SegmentType[];
      if (
        (updateKeys.includes('availabilityNotes') &&
          elementName === 'generalNotes') ||
        (updateKeys.includes('generalNotes') &&
          elementName === 'availabilityNotes')
      ) {
        return true;
      }
      if (
        elementName === 'overlappedMxs' &&
        updateKeys.includes('maintenances')
      ) {
        return true;
      }
      return !!payload[elementName];
    };
    this.batchUpdater = (action: Action<WsUpdateBatch>, state: RootState) => {
      if (state.ui.isScrollingVertically || state.ui.isScrollingHorizontally)
        return;
      if (isGeneralWsUpdateAction(action.payload)) {
        const batchForThisElementType = this.isBatchForThisElementType(
          action.payload.data,
          this.props.elementName as SegmentType
        );
        if (!batchForThisElementType) return;
        this.d3Updater(action, state);
      }
      if (this.props.elementName === 'flights') {
        this.d3Updater(action, state);
      }
      return;
    };
    this.debouncedUpdater = debounce((action, _state) => {
      this.d3Updater(action, _state);
    }, 300);
  }
  toggleUpdateSubscription(mode: 'on' | 'off') {
    subMiddle[mode](
      actions.getSegmentRequest.done,
      this.scrollProtectedUpdater
    );
    subMiddle[mode](actions.userZoomHor, this.debouncedUpdater);
    subMiddle[mode](actions.userZoomVer, this.debouncedUpdater);
    subMiddle[mode](actions.userToggleACTypeView, this.d3Updater);
    subMiddle[mode](actions.userToggleTailElementsVisibility, this.d3Updater);
    subMiddle[mode](actions.userToggleOperatingCompaniesView, this.d3Updater);
    subMiddle[mode](actions.userChangeSearchQueryAllFilters, this.d3Updater);
    subMiddle[mode](actions.userToggleSelectAllAcTypes, this.d3Updater);
    subMiddle[mode](actions.userChangeSearchACQuery, this.d3Updater);
    subMiddle[mode](actions.userToggleSubChartersView, this.d3Updater);
    subMiddle[mode](actions.userToggleAircraft, this.d3Updater);
    subMiddle[mode](actions.userToggleGroupByOperator, this.d3Updater);
    subMiddle[mode](actions.userToggleAircraftByMxEvents, this.d3Updater);
    subMiddle[mode](actions.userSetSegmentVisibility, this.d3Updater);
    subMiddle[mode](actions.userResetMultipleSearchBarPanel, this.d3Updater);
    subMiddle[mode](actions.userToggleMultipleSearchBarPanel, this.d3Updater);
    subMiddle[mode](actions.userChangeCurrentAcTypePosition, this.d3Updater);
    subMiddle[mode](
      actions.userResetChangesInAcTypesPositionMap,
      this.d3Updater
    );
    subMiddle[mode](actions.wsUpdateBatch, this.batchUpdater);
    if (
      this.props.elementName === 'overlap-flight-el-ow' ||
      this.props.elementName === 'emptyLegOffers' ||
      this.props.elementName === 'oneWayOffers'
    ) {
      subMiddle[mode](
        actions.userToggleEmptyLegView,
        this.scrollProtectedUpdater
      );
    }
    if (this.subscriptions)
      this.subscriptions.forEach(subscriptionTuple => {
        subMiddle[mode](...subscriptionTuple);
      });
    if (this.props.elementName === 'flights') {
      subMiddle[mode](actions.wsHandoverUpdateBatch, this.batchUpdater);
      subMiddle[mode](actions.refreshTimer, this.scrollProtectedUpdater);
      subMiddle[mode](actions.userToggleOpStatusView, this.d3Updater);
      subMiddle[mode](actions.userToggleOpStatusViewAll, this.d3Updater);
    }
  }

  rectWidthCalculator = (d: EventElement, scaleX) => {
    const { end, start } = d;
    return Math.max(0, scaleX(end) - scaleX(start));
  };
  rootRef: SVGGElement;
  getRootRef = ref => (this.rootRef = ref);
  getDataMapper = () => {
    switch (this.props.elementName) {
      case 'flights':
        return flightDataMapper;
      case 'maintenances':
      case 'overlappedMxs':
        return maintenanceDataMapper;
      case 'generalNotes':
      case 'availabilityNotes':
        return noteDataMapper;
      case 'oneWayOffers':
      case 'emptyLegOffers':
        return oneWayEmptyLegDataMapper;
      case 'overlap-flight-el-ow':
        return overlappedFlightWithELOrOwDataMapper;
      case 'maintenanceItems':
        return melDataMapper;
      default:
        return plainDataMapper;
    }
  };
  update = data => {
    if (!this.linkedSelection) {
      this.linkedSelection = d3S.select(this.rootRef).selectAll('.event');
    }
    const mappedWithData = this.linkedSelection.data(
      data.map(this.buildPropsForComponent),
      this.getDataMapper()
    );
    mappedWithData.exit().remove();
    this.linkedSelection = this.renderEntered(mappedWithData.enter()).merge(
      mappedWithData
    );
  };
  rectTopCalculator = d => {
    const state = store.getState();
    const { togglersState } = state.aircraft;
    const { segmentsVisibility, positionMap } = state.ui;
    return (
      getAircraftIndexMapExcludingHolding(state)[d.aircraftId] *
        state.ui.rowHeight +
      getElementOffset(
        segmentsVisibility,
        this.props.elementName as SegmentType,
        togglersState[d.aircraftId],
        positionMap
      ) /
        getLaneHeightKoef(
          segmentsVisibility,
          togglersState[d.aircraftId],
          positionMap
        )
    );
  };
  buildPropsForComponent = (d: EventElement) => {
    const { time, ui } = store.getState();
    const newD = Object.create(d) as ExtendedEventElement;
    newD.y = this.rectTopCalculator(newD);
    newD.now = time.now;
    newD.canvasWidth = ui.width;
    return newD;
  };
  componentDidMount() {
    const state = store.getState();
    const allElements = (() => {
      switch (this.props.elementName) {
        case 'generalNotes':
          return getSingleAndMergedGeneralNotes(state, '');
        case 'maintenanceItems':
          return getSingleAndMergedVisibleMelItems(state, '');
        case 'overlappedMxs':
          return getOverlappedElementsSelector(state, 'maintenances');
        case 'overlap-flight-el-ow': {
          return getOverlaps(
            state,
            'flights',
            'oneWayOffers',
            'emptyLegOffers'
          ).filter(fromFlightOverlaps);
        }
        default:
          return getVisibleElements(state, this.props.elementName);
      }
    })();
    this.update(allElements);
    this.toggleUpdateSubscription('on');
  }
  componentWillUnmount() {
    this.toggleUpdateSubscription('off');
  }
  renderEntered(
    entered: d3S.Selection<d3S.BaseType, ExtendedEventElement, SVGGElement, {}>
  ): d3S.Selection<d3S.BaseType, ExtendedEventElement, SVGGElement, {}> {
    const state = store.getState();
    const {
      width,
      planeBlockWidth,
      segmentsVisibility,
      positionMap,
    } = state.ui;
    const { togglersState } = state.aircraft;
    const { elementName, getColor, getBorderColor } = this.props;
    const scaleX = getInitialScale(width - planeBlockWidth);
    return entered
      .append('rect')
      .classed('event', true)
      .attr('x', d => scaleX(d.start))
      .attr('y', d => d.y)
      .style('fill', getColor)
      .attr('width', d => this.rectWidthCalculator(d, scaleX))
      .attr('height', d =>
        getElementHeight(
          segmentsVisibility,
          elementName as SegmentType,
          togglersState[d.aircraftId],
          positionMap
        )
      )
      .attr('stroke-width', 1)
      .attr('stroke', getBorderColor)
      .attr('vector-effect', 'non-scaling-stroke');
  }
  render() {
    return <g className={this.props.elementName} ref={this.getRootRef} />;
  }
}
