import { Tooltip } from 'antd';
import { Component } from 'react';

import Aircraft, { WifiType } from '../../types/aircraft';
import { openMaintenanceModule } from '../../utils';
import WifiIcon from '../svg-icons/aircraft/WifiIcon';

const GREEN = 'green';
const RED = 'red';
const LIGHT = 'rgba(0, 0, 0, 0.25)';

export class WifiComponent extends Component<{ aircraft: Aircraft }> {
  openMx = e => {
    e.stopPropagation();
    openMaintenanceModule(this.props.aircraft.id);
  };
  render() {
    const {
      wifi,
      hasPrimaryWiFiDefects,
      hasSwiftWiFiDefects,
    } = this.props.aircraft;
    const parsedWifiName = getParsedWifiName(wifi);
    const wifiOption = getEquippedWifiOption({
      hasPrimaryWiFiDefects,
      hasSwiftWiFiDefects,
      wifi: parsedWifiName,
    });
    const wifiName = getWifiName(wifi);
    const title = getWifiTooltipTitle(wifiOption, wifiName);
    const { basicColor, firstLineColor, secondLineColor } = getWifiLinesColor(
      wifiOption
    );
    const isGogoWifi =
      parsedWifiName === WifiType.gogo ||
      parsedWifiName === WifiType.gogoAdv ||
      parsedWifiName === WifiType.gogoAv;
    return (
      <>
        <Tooltip title={title} placement="right">
          <div onClick={this.openMx}>
            <WifiIcon
              firstLineColor={firstLineColor}
              basicColor={basicColor}
              secondLineColor={secondLineColor}
              isGogoWifi={isGogoWifi}
            />
          </div>
        </Tooltip>
      </>
    );
  }
}

enum EquippedWifiOption {
  NO_WIFI = 1,
  SWIFT_ONLY_WORKING,
  SWIFT_ONLY_BROKEN,
  TREE_BAR_WIFI_INSTALLED_AND_WORKING,
  TREE_BAR_WIFI_INSTALLED_BROKEN_SWIFT_WORKS,
  TREE_BAR_WIFI_INSTALLED_BROKEN_SWIFT_INSTALLED_BROKEN,
  TWO_BAR_WIFI_INSTALLED_AND_WORKING,
  TWO_BAR_WIFI_INSTALLED_BROKEN_SWIFT_WORKS,
  TWO_BAR_WIFI_INSTALLED_BROKEN_SWIFT_INSTALLED_BROKEN,
}

function getEquippedWifiOption(args: {
  wifi: WifiType;
  hasPrimaryWiFiDefects: boolean;
  hasSwiftWiFiDefects: boolean;
}): EquippedWifiOption {
  const { hasPrimaryWiFiDefects, hasSwiftWiFiDefects, wifi } = args;
  if (!wifi) {
    return EquippedWifiOption.NO_WIFI;
  }
  switch (wifi) {
    case WifiType.swift: {
      return hasSwiftWiFiDefects
        ? EquippedWifiOption.SWIFT_ONLY_BROKEN
        : EquippedWifiOption.SWIFT_ONLY_WORKING;
    }
    case WifiType.ku:
    case WifiType.gogoAv:
    case WifiType.gogoAdv: {
      if (hasPrimaryWiFiDefects && !hasSwiftWiFiDefects) {
        return EquippedWifiOption.TREE_BAR_WIFI_INSTALLED_BROKEN_SWIFT_WORKS;
      }
      if (hasPrimaryWiFiDefects && hasSwiftWiFiDefects) {
        return EquippedWifiOption.TREE_BAR_WIFI_INSTALLED_BROKEN_SWIFT_INSTALLED_BROKEN;
      }
      return EquippedWifiOption.TREE_BAR_WIFI_INSTALLED_AND_WORKING;
    }
    case WifiType.gogo:
    case WifiType.ka: {
      if (hasPrimaryWiFiDefects && !hasSwiftWiFiDefects) {
        return EquippedWifiOption.TWO_BAR_WIFI_INSTALLED_BROKEN_SWIFT_WORKS;
      }
      if (hasPrimaryWiFiDefects && hasSwiftWiFiDefects) {
        return EquippedWifiOption.TWO_BAR_WIFI_INSTALLED_BROKEN_SWIFT_INSTALLED_BROKEN;
      }
      return EquippedWifiOption.TWO_BAR_WIFI_INSTALLED_AND_WORKING;
    }
    default:
      return EquippedWifiOption.NO_WIFI;
  }
}

function getWifiTooltipTitle(
  option: EquippedWifiOption,
  wifiName: string
): string {
  switch (option) {
    case EquippedWifiOption.NO_WIFI: {
      return 'WiFi is N/A';
    }
    case EquippedWifiOption.SWIFT_ONLY_BROKEN: {
      return 'Swift equipped but defective';
    }
    case EquippedWifiOption.SWIFT_ONLY_WORKING: {
      return 'Swift equipped and working';
    }

    case EquippedWifiOption.TREE_BAR_WIFI_INSTALLED_AND_WORKING:
    case EquippedWifiOption.TWO_BAR_WIFI_INSTALLED_AND_WORKING: {
      return `${wifiName} equipped and operative`;
    }
    case EquippedWifiOption.TREE_BAR_WIFI_INSTALLED_BROKEN_SWIFT_WORKS:
    case EquippedWifiOption.TWO_BAR_WIFI_INSTALLED_BROKEN_SWIFT_WORKS: {
      return `${wifiName} equipped but defective. Swift operative`;
    }
    case EquippedWifiOption.TREE_BAR_WIFI_INSTALLED_BROKEN_SWIFT_INSTALLED_BROKEN:
    case EquippedWifiOption.TWO_BAR_WIFI_INSTALLED_BROKEN_SWIFT_INSTALLED_BROKEN: {
      return `${wifiName} equipped but defective. Swift defective`;
    }
    default:
      return '';
  }
}

function getWifiLinesColor(
  option: EquippedWifiOption
): { firstLineColor: string; secondLineColor: string; basicColor: string } {
  switch (option) {
    case EquippedWifiOption.SWIFT_ONLY_WORKING: {
      return {
        basicColor: GREEN,
        firstLineColor: LIGHT,
        secondLineColor: LIGHT,
      };
    }
    case EquippedWifiOption.SWIFT_ONLY_BROKEN: {
      return {
        basicColor: RED,
        firstLineColor: LIGHT,
        secondLineColor: LIGHT,
      };
    }
    case EquippedWifiOption.TREE_BAR_WIFI_INSTALLED_AND_WORKING: {
      return {
        basicColor: GREEN,
        firstLineColor: GREEN,
        secondLineColor: GREEN,
      };
    }
    case EquippedWifiOption.TREE_BAR_WIFI_INSTALLED_BROKEN_SWIFT_WORKS: {
      return {
        basicColor: GREEN,
        firstLineColor: RED,
        secondLineColor: RED,
      };
    }
    case EquippedWifiOption.TREE_BAR_WIFI_INSTALLED_BROKEN_SWIFT_INSTALLED_BROKEN: {
      return {
        basicColor: RED,
        firstLineColor: RED,
        secondLineColor: RED,
      };
    }
    case EquippedWifiOption.TWO_BAR_WIFI_INSTALLED_AND_WORKING: {
      return {
        basicColor: GREEN,
        firstLineColor: LIGHT,
        secondLineColor: GREEN,
      };
    }
    case EquippedWifiOption.TWO_BAR_WIFI_INSTALLED_BROKEN_SWIFT_WORKS: {
      return {
        basicColor: GREEN,
        firstLineColor: LIGHT,
        secondLineColor: RED,
      };
    }
    case EquippedWifiOption.TWO_BAR_WIFI_INSTALLED_BROKEN_SWIFT_INSTALLED_BROKEN: {
      return {
        basicColor: RED,
        firstLineColor: LIGHT,
        secondLineColor: RED,
      };
    }
    default:
      return {
        basicColor: LIGHT,
        firstLineColor: LIGHT,
        secondLineColor: LIGHT,
      };
  }
}

function getWifiName(wifi: WifiType): string {
  switch (wifi) {
    case WifiType.ka:
    case WifiType.ku: {
      return `${wifi.charAt(0).toUpperCase()}${wifi
        .slice(1)
        .toLocaleLowerCase()}`;
    }
    case WifiType.gogo:
    case WifiType.gogoAv:
    case WifiType.gogoAdv: {
      return wifi.replace(/_/g, ' ');
    }
    default:
      return wifi;
  }
}

function getParsedWifiName(wifi: string | WifiType): WifiType {
  if (!wifi) {
    return WifiType.na;
  }
  if (
    /go(_|\s)?go(_|\s)?biz/gim.test(wifi.toLocaleLowerCase()) ||
    wifi === WifiType.gogo
  ) {
    return WifiType.gogo;
  }
  if (
    /go(_|\s)?go(_|\s)?adv/gim.test(wifi.toLocaleLowerCase()) ||
    wifi === WifiType.gogoAdv
  ) {
    return WifiType.gogoAdv;
  }
  if (
    /go(_|\s)?go(_|\s)?av/gim.test(wifi.toLocaleLowerCase()) ||
    wifi === WifiType.gogoAv
  ) {
    return WifiType.gogoAv;
  }
  if (wifi.toLocaleLowerCase() === 'ku' || wifi === WifiType.ku) {
    return WifiType.ku;
  }
  if (wifi.toLocaleLowerCase() === 'ka' || wifi === WifiType.ka) {
    return WifiType.ka;
  }
  if (wifi.toLocaleLowerCase() === 'swift' || wifi === WifiType.swift) {
    return WifiType.swift;
  }
  return WifiType.na;
}
