import { chain, drop } from 'lodash';

import { utc } from 'moment';

let webSocketStatistics: { ts: number; count: number; delta: number }[] = [];
setInterval(
  () =>
    (webSocketStatistics = drop(
      webSocketStatistics,
      Math.max(webSocketStatistics.length - 1800, 0)
    )),
  40 * 60 * 1000
);
export function addToCounter(ts: number, count: number, delta: number) {
  webSocketStatistics.push({ ts, count, delta });
}

export function getWSStatisticsForTimeRange(minutes: number) {
  const firstMinute = utc()
    .subtract(minutes, 'minutes')
    .valueOf();
  const result = chain(webSocketStatistics)
    .filter(({ ts }) => ts >= firstMinute)
    .reduce(
      (acc, currentValue) => {
        const currentRate = (currentValue.count / currentValue.delta) * 1000;
        acc.max = Math.max(currentRate, acc.max);
        acc.min = Math.min(currentRate, acc.min);
        acc.sum = acc.sum + currentRate;
        acc.maxBatchSize = Math.max(acc.maxBatchSize, currentValue.count);
        acc.count += 1;
        return acc;
      },
      {
        max: Number.MIN_SAFE_INTEGER,
        min: Number.MAX_SAFE_INTEGER,
        sum: 0,
        count: 0,
        maxBatchSize: Number.MIN_SAFE_INTEGER,
      }
    )
    .value();
  console.group(
    '%c%s%i%s',
    'font-size:1.2em;',
    'WS Statistics for last ',
    minutes,
    ' minutes:'
  );
  console.log(
    'MAX:%c%f%c%s',
    'font-weight: bold',
    result.max == Number.MIN_SAFE_INTEGER ? 0 : result.max,
    'font-weight: normal',
    ' messages per second'
  );
  console.log(
    'MIN:%c%f%c%s',
    'font-weight: bold',
    result.min == Number.MAX_SAFE_INTEGER ? 0 : result.min,
    'font-weight: normal',
    ' messages per second'
  );
  console.log(
    'Average:%c%f%c%s',
    'font-weight: bold',
    result.sum / (result.count || 1),
    'font-weight: normal',
    ' messages per second'
  );
  console.groupEnd();
}
(window as any).getWSStatisticsForTimeRange = getWSStatisticsForTimeRange;
