import { Checkbox, Input, Radio, Spin } from 'antd';
import { Field, FieldInputProps, FormikProps } from 'formik';
import { PureComponent } from 'react';
import { utc } from 'moment';
import { MaintenanceType } from '../../types/maintenance';
import { MxFormProps } from './mx-event-drawer';
import { AircraftAutocomplete } from './components/aircraft-auto-complete';
import { AirportAutocomplete } from './components/airport-auto-complete';
import { MxEventCategoryName, MxEventFormName, MxEventType } from './constants';
import { DateTimePickerComponent } from './components/date-picker';
import { MxEventFormValues } from './interfaces';
import { setDataTestEntityId } from '../../utils';
import TextArea from 'antd/lib/input/TextArea';
import { ServiceProviderOptions } from './components/service-provider';

export class MxEventFormDetails extends PureComponent<
  Pick<
    MxFormProps,
    'mxEventId' | 'isLoadingOrderTypes' | 'orderTypes' | 'airportsByIcao'
  > &
    FormikProps<MxEventFormValues> & {
      disabled: boolean;
      formIsTouched: boolean;
      setFormIsTouched: (v: boolean) => void;
    }
> {
  componentDidUpdate() {
    const { formIsTouched, dirty, setFormIsTouched } = this.props;
    if (!formIsTouched && dirty) {
      setFormIsTouched(true);
    }
  }
  onClickRtsSelected = () => {
    const {
      setFieldValue,
      values: { rtsSelected },
      initialValues,
    } = this.props;
    if (!rtsSelected) {
      const time = utc().valueOf();
      setFieldValue(MxEventFormName.endTimeMs, time);
    } else {
      setFieldValue(MxEventFormName.endTimeMs, initialValues.endTimeMs);
    }
    setFieldValue(MxEventFormName.rtsSelected, !rtsSelected);
  };
  onClickDoNotSplit = () => {
    const {
      setFieldValue,
      values: { notSplit },
      initialValues,
    } = this.props;
    if (notSplit) {
      setFieldValue(MxEventFormName.splitHours, initialValues.splitHours);
    } else {
      setFieldValue(MxEventFormName.splitHours, null);
    }
    setFieldValue(MxEventFormName.notSplit, !notSplit);
  };
  validateIcao = (value: string) => {
    let error: string | undefined;
    const { airportsByIcao } = this.props;
    const validIcao = airportsByIcao[value?.toUpperCase()];
    if (value && !validIcao) {
      error = 'Please select airport from the list';
    }
    return error;
  };
  getSplitHours = () => {
    const {
      values: { maintenanceTypeId, splitHours },
      mxEventId,
    } = this.props;
    if (!!mxEventId) {
      return null;
    }
    return maintenanceTypeId === MaintenanceType.AOG ? splitHours : null;
  };
  render() {
    const {
      orderTypes,
      errors,
      touched,
      isLoadingOrderTypes,
      disabled,
      values,
      mxEventId,
      initialValues,
      setFieldValue,
      handleChange,
    } = this.props;
    const { orderNumber, reason, maintenanceTypeId } = values;
    const splitHoursValue = this.getSplitHours();
    return (
      <div className="edit-event-form-body">
        <div className="edit-event-form-body-new-line-row">
          <div>
            <label
              htmlFor={MxEventFormName.aircraftName}
              className="edit-event-form-body-label-required"
            >
              Tail#
            </label>
            <Field
              id={MxEventFormName.aircraftName}
              as={(
                fieldInputProps: FieldInputProps<
                  MxEventFormValues['aircraftName']
                >
              ) => (
                <AircraftAutocomplete
                  {...fieldInputProps}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  disabled={disabled}
                />
              )}
              name={MxEventFormName.aircraftName}
              {...setDataTestEntityId('mx-event-tail')}
            />
          </div>
          <div>
            <label htmlFor={MxEventFormName.airportIcao}>Airport</label>
            <Field
              id={MxEventFormName.airportIcao}
              as={(
                fieldInputProps: FieldInputProps<
                  MxEventFormValues['airportIcao']
                >
              ) => (
                <AirportAutocomplete
                  {...fieldInputProps}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  values={values}
                  initialValues={initialValues}
                  errors={errors}
                  touched={touched}
                  disabled={disabled}
                />
              )}
              name={MxEventFormName.airportIcao}
              {...setDataTestEntityId('mx-event-airport')}
              validate={this.validateIcao}
            />
          </div>
        </div>

        <div className="edit-event-form-body-new-line">
          <label
            htmlFor={MxEventFormName.maintenanceTypeId}
            className="edit-event-form-body-label-required"
          >
            Type
          </label>
          <div>
            <Field
              id={MxEventFormName.maintenanceTypeId}
              name={MxEventFormName.maintenanceTypeId}
              as={(
                formikProps: FieldInputProps<
                  MxEventFormValues['maintenanceTypeId']
                >
              ) => (
                <Radio.Group
                  className="edit-event-form-body-radio-group-mx-type"
                  {...formikProps}
                  disabled={disabled}
                >
                  <Radio.Button
                    value={MaintenanceType.SCHEDULED}
                    {...setDataTestEntityId('mx-event-smx')}
                  >
                    {MxEventType.SMX}
                  </Radio.Button>
                  <Radio.Button
                    value={MaintenanceType.UNSCHEDULED}
                    {...setDataTestEntityId('mx-event-umx')}
                  >
                    {MxEventType.UMX}
                  </Radio.Button>
                  <Radio.Button
                    value={MaintenanceType.AOG}
                    {...setDataTestEntityId('mx-event-aog')}
                  >
                    {MxEventType.AOG}
                  </Radio.Button>
                </Radio.Group>
              )}
            />
            <label
              htmlFor={MxEventFormName.dispatchable}
              className="edit-event-form-body-checkbox-on-line"
            >
              <Field
                id={MxEventFormName.dispatchable}
                name={MxEventFormName.dispatchable}
                as={(
                  formikProps: FieldInputProps<
                    MxEventFormValues['dispatchable']
                  >
                ) => (
                  <Checkbox
                    {...formikProps}
                    checked={formikProps.value}
                    disabled={disabled}
                  >
                    Dispatchable
                  </Checkbox>
                )}
              />
            </label>
            {errors.maintenanceTypeId && touched.maintenanceTypeId ? (
              <div className="edit-event-form-body-error-message">
                {errors.maintenanceTypeId}
              </div>
            ) : null}
          </div>
          <div className="edit-event-form-body-split-aog">
            <div>
              <label htmlFor={MxEventFormName.splitHours}> Split hours</label>
              <Input
                onChange={handleChange}
                disabled={
                  disabled ||
                  maintenanceTypeId !== MaintenanceType.AOG ||
                  !!mxEventId
                }
                id={MxEventFormName.splitHours}
                value={splitHoursValue}
                name={MxEventFormName.splitHours}
                className="edit-event-form-body-split-aog-input"
                type="number"
                {...setDataTestEntityId('mx-event-split-hours')}
              />
              {errors.splitHours && touched.splitHours ? (
                <div className="edit-event-form-body-error-message">
                  {errors.splitHours}
                </div>
              ) : null}
            </div>
            <label
              htmlFor={MxEventFormName.notSplit}
              className="edit-event-form-body-checkbox-on-line"
            >
              <Field
                id={MxEventFormName.notSplit}
                name={MxEventFormName.notSplit}
                as={(
                  formikProps: FieldInputProps<MxEventFormValues['notSplit']>
                ) => (
                  <Checkbox
                    {...formikProps}
                    checked={formikProps.value}
                    disabled={
                      disabled ||
                      maintenanceTypeId !== MaintenanceType.AOG ||
                      !!mxEventId
                    }
                    {...setDataTestEntityId('mx-event-do-not-split')}
                    onClick={this.onClickDoNotSplit}
                  >
                    Do not split
                  </Checkbox>
                )}
              />
            </label>
          </div>
          <div className="edit-event-form-body-new-line">
            <label
              htmlFor={MxEventFormName.startTimeMs}
              className="edit-event-form-body-label-required"
            >
              Starts (UTC)
            </label>
            <div>
              <Field
                id={MxEventFormName.startTimeMs}
                name={MxEventFormName.startTimeMs}
                as={(
                  formikProps: FieldInputProps<MxEventFormValues['startTimeMs']>
                ) => (
                  <DateTimePickerComponent
                    {...formikProps}
                    disabled={disabled}
                    setFieldValue={setFieldValue}
                  />
                )}
                {...setDataTestEntityId('mx-event-start-date')}
              />
            </div>
            {errors.startTimeMs && touched.startTimeMs ? (
              <div className="edit-event-form-body-error-message">
                {errors.startTimeMs}
              </div>
            ) : null}
          </div>
          <div className="edit-event-form-body-new-line">
            <label
              htmlFor={MxEventFormName.endTimeMs}
              className="edit-event-form-body-label-required"
            >
              Ends (UTC)
            </label>
            <div className="edit-event-form-body-picker-with-tick">
              <Field
                id={MxEventFormName.endTimeMs}
                name={MxEventFormName.endTimeMs}
                as={(
                  formikProps: FieldInputProps<MxEventFormValues['endTimeMs']>
                ) => (
                  <DateTimePickerComponent
                    {...formikProps}
                    disabled={disabled}
                    setFieldValue={setFieldValue}
                    isRtsSelected={values.rtsSelected}
                  />
                )}
                {...setDataTestEntityId('mx-event-end-date')}
              />
              <label
                htmlFor={MxEventFormName.rtsSelected}
                className="edit-event-form-body-checkbox-on-line"
              >
                <Field
                  id={MxEventFormName.rtsSelected}
                  name={MxEventFormName.rtsSelected}
                  as={(
                    formikProps: FieldInputProps<
                      MxEventFormValues['rtsSelected']
                    >
                  ) => (
                    <Checkbox
                      onClick={this.onClickRtsSelected}
                      {...formikProps}
                      disabled={disabled}
                      checked={formikProps.value}
                    >
                      Release to Service
                    </Checkbox>
                  )}
                />
              </label>
            </div>
            {errors.endTimeMs && touched.endTimeMs ? (
              <div className="edit-event-form-body-error-message">
                {errors.endTimeMs}
              </div>
            ) : null}
          </div>

          <div className="edit-event-form-body-new-line">
            <label htmlFor={MxEventFormName.categoryName}>Category</label>
            <div>
              <Field
                id={MxEventFormName.categoryName}
                name={MxEventFormName.categoryName}
                as={(
                  formikProps: FieldInputProps<
                    MxEventFormValues['categoryName']
                  >
                ) => (
                  <Radio.Group
                    className="edit-event-form-body-radio-group-category"
                    {...formikProps}
                    disabled={disabled}
                  >
                    <Radio.Button value={MxEventCategoryName.Normal}>
                      {MxEventCategoryName.Normal}
                    </Radio.Button>
                    <Radio.Button value={MxEventCategoryName.Provisional}>
                      {MxEventCategoryName.Provisional}
                    </Radio.Button>
                    <Radio.Button value={MxEventCategoryName.Must}>
                      {MxEventCategoryName.Must}
                    </Radio.Button>
                  </Radio.Group>
                )}
              />
            </div>
            <div className="edit-event-form-body-new-line">
              <label htmlFor={MxEventFormName.orderNumber}>Order#</label>
              <div>
                <Input
                  onChange={this.props.handleChange}
                  disabled={disabled}
                  id={MxEventFormName.orderNumber}
                  value={orderNumber}
                  name={MxEventFormName.orderNumber}
                  className="edit-event-form-body-input"
                  {...setDataTestEntityId('mx-event-order')}
                />
              </div>
            </div>
            <div className="edit-event-form-body-new-line">
              <label htmlFor={MxEventFormName.orderTypeId}>Order Type</label>
              <div>
                {isLoadingOrderTypes ? (
                  <Spin
                    spinning
                    style={{ position: 'absolute', left: '50%' }}
                  />
                ) : (
                  <Field
                    id={MxEventFormName.orderTypeId}
                    name={MxEventFormName.orderTypeId}
                    as={(
                      formikProps: FieldInputProps<
                        MxEventFormValues['orderTypeId']
                      >
                    ) => (
                      <Radio.Group
                        className="edit-event-form-body-radio-group-order-type"
                        {...formikProps}
                        disabled={disabled}
                      >
                        {orderTypes &&
                          (orderTypes.find(t => /None/gi.test(t.name))
                            ? orderTypes
                            : [{ id: 0, name: 'None' }, ...orderTypes]
                          ).map(or => (
                            <Radio.Button key={or.name} value={or.id}>
                              {or.name.replace(' Order', '')}
                            </Radio.Button>
                          ))}
                      </Radio.Group>
                    )}
                    {...setDataTestEntityId('mx-event-order-types')}
                  />
                )}
              </div>
            </div>
            <div className="edit-event-form-body-new-line">
              <label htmlFor={MxEventFormName.serviceProviderName}>
                Service Provider
              </label>
              <div>
                <Field
                  id={MxEventFormName.serviceProviderName}
                  name={MxEventFormName.serviceProviderName}
                  as={(
                    fieldInputProps: FieldInputProps<
                      MxEventFormValues['serviceProviderName']
                    >
                  ) => (
                    <ServiceProviderOptions
                      {...fieldInputProps}
                      disabled={disabled}
                      airportId={values.airportId}
                      setFieldValue={setFieldValue}
                    />
                  )}
                  {...setDataTestEntityId('mx-event-service-provider')}
                />
              </div>
            </div>
            <div className="edit-event-form-body-new-line">
              <label htmlFor={MxEventFormName.reason}>Reason</label>
              <div>
                <TextArea
                  rows={3}
                  onChange={this.props.handleChange}
                  disabled={disabled}
                  id={MxEventFormName.reason}
                  value={reason}
                  name={MxEventFormName.reason}
                  className="edit-event-form-body-input"
                  {...setDataTestEntityId('mx-event-reason')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
