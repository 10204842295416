import { PureComponent } from 'react';
import EventElement from '../../../types/event-element';
import Card from './card';
import { TooltipPlacement } from 'antd/lib/tooltip';

interface Props {
  overlappedElements: EventElement[];
  focused: number;
  onFocus: (index: number) => () => void;
  onBlur: () => void;
  placement: TooltipPlacement;
  isVerticalMode: boolean;
  flightTooltipWidth: number;
  boundingRect?: DOMRect;
}
export default class Cards extends PureComponent<Props> {
  render() {
    const {
      focused,
      onFocus,
      onBlur,
      overlappedElements,
      placement,
      boundingRect,
      flightTooltipWidth,
      isVerticalMode,
    } = this.props;
    return (
      <>
        {overlappedElements.map((el: EventElement, index) => (
          <Card
            key={el.id}
            element={el}
            index={index}
            focused={focused}
            onFocus={onFocus}
            onBlur={onBlur}
            placement={placement}
            boundingRect={boundingRect}
            flightTooltipWidth={flightTooltipWidth}
            isVerticalMode={isVerticalMode}
          />
        ))}
      </>
    );
  }
}
