import { PureComponent } from 'react';

interface Props {
  isFocused: boolean;
  onFocus: () => void;
  onBlur: () => void;
}

class Preview extends PureComponent<Props> {
  render() {
    const { isFocused, onFocus, onBlur, children } = this.props;
    return (
      <g
        opacity={isFocused ? 1 : 0.3}
        onMouseEnter={onFocus}
        onMouseLeave={onBlur}
      >
        {children}
      </g>
    );
  }
}

export default Preview;
