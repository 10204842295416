import { FC, useCallback } from 'react';
import * as actions from '../../actions';
import { RootState } from '../../reducers';
import { useDispatch, useSelector } from 'react-redux';

import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Popover, Avatar, Button } from 'antd';
import './styles.scss';
import { USE_AUTH } from '../../constants/environment';
import { useAuth0 } from '../../Auth0Wrapper';
import { UserProfile } from '../../types/user';
import { isEqual } from 'lodash';

// @ts-ignore
const buildNumber = BUILD_NUMBER || GIT_REVISION || '';

export const UserProfileComponent: FC<{}> = () => {
  const dispatch = useDispatch();
  const userProfile = useSelector<RootState, UserProfile>(
    state => state.user.userProfile,
    isEqual
  );
  const { logout: auth0logout } = useAuth0();

  const doLogout = useCallback(() => {
    if (USE_AUTH) {
      auth0logout();
    } else {
      dispatch(actions.userLogout());
    }
  }, [USE_AUTH]);
  return userProfile ? (
    <Popover
      placement="bottom"
      content={
        <div
          style={{
            background: '#002140',
            color: 'white',
            margin: '-14px -16px',
          }}
        >
          <Button
            className="logout-button"
            onClick={doLogout}
            icon={<LogoutOutlined />}
          >
            Log out
          </Button>
          <div
            style={{
              margin: '0 0 0 5px',
            }}
          >
            Current version: {buildNumber}
          </div>
        </div>
      }
      trigger="click"
    >
      <Avatar
        icon={<UserOutlined />}
        style={{
          cursor: 'pointer',
          margin: '0 10px',
          backgroundColor: '#cccccc',
        }}
      />
    </Popover>
  ) : null;
};
