import { Radio } from 'antd';
import { ReactElement } from 'react';

interface Props<T> {
  options: T;
  value: string;
  onChange: (v: string) => void;
  disabled?: boolean;
}

export const RadioComponent: <T>(p: Props<T>) => ReactElement<Props<T>> = ({
  options,
  value,
  onChange,
  disabled,
}) => {
  return (
    <Radio.Group
      defaultValue="All"
      buttonStyle="solid"
      size="small"
      className="remarks-title-column-radio unselectable"
      value={value}
      disabled={disabled}
    >
      {Object.values(options).map(v => (
        <Radio.Button
          key={v}
          value={v}
          onChange={e => onChange(e.target.value)}
          style={{ padding: '0 10px' }}
        >
          {v}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};
