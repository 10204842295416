import { PureComponent } from 'react';

interface Props {
  arrClassName: string;
  depClassName: string;
  arrText: string;
  depText: string;
}

class StatusRow extends PureComponent<Props> {
  render() {
    return (
      <>
        {this.props.children}
        <div className="ft-block">
          <div className="ft-block-row">
            <span className={this.props.depClassName}>
              {this.props.depText}
            </span>
            <span className={this.props.arrClassName}>
              {this.props.arrText}
            </span>
          </div>
        </div>
      </>
    );
  }
}

export default StatusRow;
