import { memoize } from 'lodash/fp';
import { PureComponent } from 'react';
import { generate } from 'shortid';
import { getAdditionalPermitsArray } from '../../common/flight/flight-check-status';
import { getPermitStyleForItem } from '../../common/flight/flight-colorization';
import { getAdditionalPermitInfoForItem } from '../../common/flight/flight-labels';
import Airport from '../../types/airport';
import Flight from '../../types/flight';

interface Props {
  selectedFlight: Flight;
  departureAirport: Airport;
  arrivalAirport: Airport;
}

class LegPermitsRow extends PureComponent<Props> {
  getPermitsArray = memoize(flight => getAdditionalPermitsArray(flight));
  render() {
    const permitsArray = this.getPermitsArray(this.props.selectedFlight);
    return (
      permitsArray.length > 0 && (
        <>
          <div className="ft-name">Leg Permits</div>
          {permitsArray.map(p => (
            <div className="ft-block" key={generate()}>
              <div className="ft-block-row">
                <span
                  className={getPermitStyleForItem(
                    this.props.selectedFlight,
                    p.airportId,
                    p
                  )}
                >
                  {getAdditionalPermitInfoForItem(
                    p,
                    this.props.departureAirport,
                    this.props.arrivalAirport
                  )}
                </span>
              </div>
            </div>
          ))}
        </>
      )
    );
  }
}

export default LegPermitsRow;
