import { apolloClient } from '../../data-processing/graphql';
import { MXEventDtoFull } from '../../types/maintenance';
import { gql } from '@apollo/client';

const getMxEvent = require('./get-mx-event.gql');

export function getMxEventById(id: number) {
  return apolloClient.query<MXEventDtoFull>({
    query: gql(getMxEvent),
    variables: {
      eventId: id,
    },
  });
}
