import BaseCompany from '../../types/base-company';

export interface BaseCompanyGVModel {
  baseCompanyId: {
    key: number;
  };
  companyId: {
    key: number;
  };
}

export const transformBaseCompanyToFE = (
  bc: BaseCompanyGVModel
): BaseCompany => ({
  id: bc.companyId.key,
});
