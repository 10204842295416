import Maintenance, {
  MaintenanceStatusTag,
  MaintenanceType,
} from '../../types/maintenance';
import {
  aogMailTemplateQuery,
  rtsMailTemplateQuery,
} from '../../types/operation-result-types';

export const getMaintenanceTypeLabel = (
  m: Maintenance
): MaintenanceStatusTag => {
  switch (m.maintenanceType) {
    case MaintenanceType.AOG:
      return MaintenanceStatusTag.AOG;
    case MaintenanceType.SCHEDULED:
      return MaintenanceStatusTag.SMX;
    case MaintenanceType.UNSCHEDULED:
      return MaintenanceStatusTag.UMX;
  }
};

export const getMaintenanceTypeTooltipLabel = (m: Maintenance): string => {
  switch (m.maintenanceType) {
    case MaintenanceType.AOG:
      return 'A/C on ground';
    case MaintenanceType.SCHEDULED:
      return 'Scheduled Maintenance';
    case MaintenanceType.UNSCHEDULED:
      return 'Unscheduled Maintenance';
  }
};

export const isRtsEmailResponse = (
  d: rtsMailTemplateQuery | aogMailTemplateQuery
): d is rtsMailTemplateQuery => !!(d as rtsMailTemplateQuery)?.rtsMailTemplate;

export const isAogEmailResponse = (
  d: rtsMailTemplateQuery | aogMailTemplateQuery
): d is aogMailTemplateQuery => !!(d as aogMailTemplateQuery)?.aogMailTemplate;
