import { SegmentsAppenderArgs } from '..';
import { getLaneHeightKoef } from '../../../../../../reducers/ui';
import { flightFerryColorizer } from '../../../../../../common/flight/flight-colorization';

export function appendFirstLine(argsObject: SegmentsAppenderArgs) {
  const {
    selection,
    segmentsVisibility,
    positionMap,
    togglersState,
    scaleX,
    firstLineHeight,
  } = argsObject;
  // ferry or live
  selection
    .append('rect')
    .attr(
      'height',
      f =>
        firstLineHeight /
        getLaneHeightKoef(
          segmentsVisibility,
          togglersState[f.aircraftId],
          positionMap
        )
    )
    .attr('y', d => d.y)
    .attr('x', d => scaleX(d.start))
    .attr('width', d => this.rectWidthCalculator(d, scaleX))
    .style('fill', d => flightFerryColorizer(d.legBusinessTypeId));
}
