import { CsTeamItem } from '../../types/csTeam';
import { axiosAuthed as axios } from '../axios-config';

interface CsTeamGVModel {
  csTeamId: {
    key: number;
  };
  name: string;
  isActive: true;
}

const transformCsTeamToFE = (data: CsTeamGVModel[]): CsTeamItem[] =>
  data.reduce<CsTeamItem[]>((acc, item) => {
    if (item.isActive) {
      return acc.concat({
        id: item.csTeamId.key,
        teamName: item.name || '',
      });
    }
    return acc;
  }, []);

export const getCsTeamList = () =>
  axios
    .get('/integration/timeline/csTeams')
    .then(resp => transformCsTeamToFE(resp?.data || []))
    .catch(e => {
      throw e;
    });
