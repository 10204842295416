import { PureComponent } from 'react';

interface StateProps {
  fill?: string;
  width?: number;
  height?: number;
  strokeWidth?: number;
}
export default class WarningIcon extends PureComponent<StateProps> {
  render() {
    const {
      width = 12,
      fill = '#F5A623',
      height = 11,
      strokeWidth = 1,
    } = this.props;
    return (
      <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 12 11"
        version="1.1"
      >
        <g
          stroke="none"
          strokeWidth={strokeWidth}
          fill="none"
          fillRule="evenodd"
        >
          <g
            transform="translate(-547.000000, -3755.000000)"
            fill={fill}
            fillRule="nonzero"
          >
            <g
              id="tooltip-tail-info-with-comment"
              transform="translate(526.000000, 3618.000000)"
            >
              <g
                id="cabin-deffect-warrning"
                transform="translate(12.000000, 124.000000)"
              >
                <path d="M9,24 L21,24 L15,13 L9,24 Z M15.5454545,22.2631579 L14.4545455,22.2631579 L14.4545455,21.1052632 L15.5454545,21.1052632 L15.5454545,22.2631579 Z M15.5454545,19.9473684 L14.4545455,19.9473684 L14.4545455,17.6315789 L15.5454545,17.6315789 L15.5454545,19.9473684 Z" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
