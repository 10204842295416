import { PureComponent } from 'react';
import { utc } from 'moment';
import DurationArrow from '../../svg-icons/tooltip/DurationArrow';
import { getFormattedDuration } from '../../../utils/time';

interface ConnectedProps {}
interface OwnProps {
  start: number;
  end: number;
}
interface Props extends ConnectedProps, OwnProps {}

export default class TooltipDates extends PureComponent<Props> {
  render() {
    const { start, end } = this.props;
    return (
      <div className="tooltip-common-dates">
        <div className="tooltip-common-dates-date-start">
          {utc(start).format('DD MMM,’YY, HH:mm[Z]')}
        </div>
        <div
          className="tooltip-common-dates-date-arrow"
          style={{
            minWidth: '84px',
          }}
        >
          <DurationArrow />
          <div className="tooltip-common-dates-date-duration">
            {getFormattedDuration(start, end)}
          </div>
        </div>
        <div className="tooltip-common-dates-date-end">
          {utc(end).format('DD MMM,’YY, HH:mm[Z]')}
        </div>
      </div>
    );
  }
}
