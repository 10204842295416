import { reducerWithInitialState } from 'typescript-fsa-reducers';

import * as actions from '../actions';
import { MxEventFormValuesForEmail } from '../components/email-component/interfaces';
import { initialMxEventFormValues } from '../components/edit-event-drawer/constants';
import {
  MaintenanceType,
  MXEventDtoFull,
  MXEventOrderTypeDto,
  MXServiceProviderDto,
} from '../types/maintenance';

export interface EventElementCreationEdit {
  id: number;
  isMxContextMenuOpened: boolean;
  isNoteContextMenuOpened: boolean;
  aircraftId: number;
  start: number;
  end: number;
  uniqIdentifier: string; // technical value, used for correct data hydration on mx event creation
  // MX event
  isMxModalVisible: boolean;
  mxEventDetails: MXEventDtoFull | null;
  isLoadingEvent: boolean;
  isFailedToLoadMxEventDetails: boolean;
  orderTypes: MXEventOrderTypeDto[] | null;
  isSaving: boolean;
  serviceProviders: MXServiceProviderDto[] | null;
  isLoadingServiceProvider: boolean;
  isLoadingOrderTypes: boolean;
  mxEventFormValues: MxEventFormValuesForEmail;
  isCloningMxEvent: boolean;
  // Note
  isNoteModalVisible: boolean;
  isEditDisabled: boolean;
}

export const initialState: EventElementCreationEdit = {
  id: null,
  isMxContextMenuOpened: false,
  isNoteContextMenuOpened: false,
  aircraftId: null,
  end: null,
  start: null,
  uniqIdentifier: '',
  isMxModalVisible: false,
  mxEventDetails: null,
  isLoadingEvent: false,
  isFailedToLoadMxEventDetails: false,
  orderTypes: null,
  isSaving: false,
  serviceProviders: null,
  isLoadingServiceProvider: false,
  isLoadingOrderTypes: false,
  mxEventFormValues: initialMxEventFormValues,
  isCloningMxEvent: false,
  isNoteModalVisible: false,
  isEditDisabled: false,
};

export default reducerWithInitialState(initialState)
  .case(actions.userOpenCreateEventsMenu, (state, payload) => ({
    ...state,
    aircraftId: payload.aircraftId,
    start: payload.start,
    end: payload.end,
    isMxContextMenuOpened: false,
    isNoteContextMenuOpened: false,
  }))
  .case(actions.userTimelineSelectionBegin, (state, payload) => ({
    ...state,
    aircraftId: payload.aircraftId,
    start: payload.dateStart,
    isMxContextMenuOpened: false,
    isNoteContextMenuOpened: false,
  }))
  .case(actions.userTimelineSelectionEnd, (state, payload) => ({
    ...state,
    end: payload,
  }))
  .cases(
    // listening these actions is W/A to not confuse user on create
    // note/mx from top bar to see selected aircraft which is not selected yet
    [actions.userCancelDraggingFlights, actions.userCloseDndModal],
    state => ({
      ...state,
      aircraftId: initialState.aircraftId,
      end: initialState.end,
      start: initialState.start,
    })
  )
  .case(actions.userOpenContextMenuForMxEvent, (state, payload) => ({
    ...state,
    id: payload[1]?.id,
    isMxContextMenuOpened: true,
  }))
  .case(actions.userCloseContextMenu, state => ({
    ...state,
    id: null,
    isMxContextMenuOpened: false,
    isNoteContextMenuOpened: false,
  }))
  .case(actions.userClickCreateMaintenance, (state, payload) => ({
    ...state,
    isMxModalVisible: true,
    uniqIdentifier: payload.uniqIdentifier,
    start: payload.start || state.start,
    end: payload.end || state.end,
  }))
  .case(actions.doCreateNote, (state, payload) => ({
    ...state,
    isNoteModalVisible: true,
    uniqIdentifier: payload.uniqIdentifier,
    start: payload.start || state.start,
    end: payload.end || state.end,
  }))
  .case(actions.userClickEditMxEvent, (state, payload) => ({
    ...state,
    id: payload.id || state.id,
    isMxContextMenuOpened: false,
    isMxModalVisible: true,
  }))
  .case(actions.userClickCloneMxEvent, (state, payload) => ({
    ...state,
    isCloningMxEvent: true,
    isMxContextMenuOpened: false,
    isMxModalVisible: true,
    id: payload.id,
  }))
  .case(actions.userDeleteMxEvent.started, state => ({
    ...state,
    id: null,
    isMxContextMenuOpened: false,
  }))
  .case(actions.userDeleteNote.started, state => ({
    ...state,
    isNoteMenuOpened: false,
  }))
  .cases(
    [
      actions.userFocusOverlapCards,
      actions.userOpenFlightMenu,
      actions.userOpenContextMenuForPeakDay,
      actions.userOpenContextMenuForOneWayOffer,
      actions.userOpenContextMenuForEmptyLegOffer,
    ] as any[],
    state => ({
      ...state,
      isMxContextMenuOpened: false,
      isNoteContextMenuOpened: false,
    })
  )
  .case(actions.doFetchMxEventById.started, state => ({
    ...state,
    isLoadingEvent: true,
    isFailedToLoadMxEventDetails: false,
  }))
  .case(actions.doFetchMxEventById.done, (state, action) => ({
    ...state,
    isLoadingEvent: false,
    mxEventDetails: state.isCloningMxEvent
      ? {
          ...action.result,
          startTimeMs: action.result.endTimeMs,
          endTimeMs: null,
          id: null,
        }
      : action.result,
    start: state.isCloningMxEvent
      ? action.result.endTimeMs
      : action.result.startTimeMs,
    end: state.isCloningMxEvent ? null : action.result.endTimeMs,
  }))
  .case(actions.doFetchMxEventById.failed, state => ({
    ...state,
    isLoadingEvent: false,
    isFailedToLoadMxEventDetails: true,
  }))
  .case(actions.doFetchMxOrderTypes.started, state => ({
    ...state,
    isLoadingOrderTypes: true,
  }))
  .case(actions.doFetchMxOrderTypes.done, (state, action) => ({
    ...state,
    orderTypes: action.result,
    isLoadingOrderTypes: false,
  }))
  .case(actions.doFetchMxOrderTypes.failed, state => ({
    ...state,
    isLoadingOrderTypes: false,
  }))
  .case(actions.userChangeDatesInEditDrawer, (state, payload) => ({
    ...state,
    start: payload.type === 'start' ? payload.time || null : state.start,
    end: payload.type === 'end' ? payload.time || null : state.end,
  }))
  .case(actions.userSearchForServiceProviders.started, state => ({
    ...state,
    serviceProviders: [],
    isLoadingServiceProvider: true,
  }))
  .case(actions.userSearchForServiceProviders.done, (state, action) => ({
    ...state,
    serviceProviders: action.result,
    isLoadingServiceProvider: false,
  }))
  .case(actions.userSearchForServiceProviders.failed, state => ({
    ...state,
    isLoadingServiceProvider: false,
  }))
  .case(actions.userResetCurrentAirport, state => ({
    ...state,
    serviceProviders: null,
  }))
  .cases(
    [actions.userSaveMxEvent.started, actions.userSaveNote.started],
    state => ({
      ...state,
      isSaving: true,
    })
  )
  .case(actions.userSaveMxEvent.done, (state, payload) => {
    const { id, maintenanceTypeId, rtsSelected } = payload.params.mxEvent;
    const shouldOpenEmail =
      maintenanceTypeId === MaintenanceType.AOG && (rtsSelected || !id);
    return {
      ...initialState,
      orderTypes: state.orderTypes,
      start: shouldOpenEmail ? state.start : initialState.start,
      end: shouldOpenEmail ? state.end : initialState.end,
    };
  })
  .cases(
    [actions.userSaveMxEvent.failed, actions.userSaveNote.failed],
    state => ({
      ...state,
      isSaving: false,
    })
  )
  .cases(
    [
      actions.userCloseMxEventModal,
      actions.userCloseNoteModal,
      actions.userSaveNote.done,
    ],
    state => ({
      ...initialState,
      orderTypes: state.orderTypes,
    })
  )
  .case(actions.userOpenMxEmailTemplate, (state, payload) => ({
    ...state,
    mxEventFormValues: {
      ...payload.mxEvent,
      serviceProviderName: payload.serviceProviderName,
    },
  }))
  .case(actions.userOpenNoteContextMenu, (state, payload) => ({
    ...state,
    isNoteContextMenuOpened: true,
    id: payload[1].id,
    start: payload[1].start,
    end: payload[1].end,
  }))
  .case(actions.userClickOpenNote, (state, payload) => ({
    ...state,
    isNoteContextMenuOpened: false,
    id: payload.id,
    start: payload.start,
    end: payload.end,
  }))
  .case(actions.doOpenNoteForEdit, (state, payload) => ({
    ...state,
    isNoteModalVisible: true,
  }))
  .cases(
    [
      actions.setReInitLoadingDataOnWebSocketConnectionLost.started,
      actions.webSocketConnectionClosed,
    ],
    state => ({
      ...state,
      isEditDisabled: true,
    })
  )
  .case(actions.setReInitLoadingDataOnWebSocketConnectionLost.done, state => ({
    ...state,
    isEditDisabled: false,
  }));
