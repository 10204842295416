import { PureComponent } from 'react';

interface StateProps {
  width?: number;
  height?: number;
  strokeWidth?: number;
}
export default class CrewIcon extends PureComponent<StateProps> {
  render() {
    const { width = 12, height = 12, strokeWidth = 1 } = this.props;
    return (
      <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox={`0 0 ${width} ${height}`}
        version="1.1"
        className="aircraft-toggle-icon"
      >
        <g stroke="none" strokeWidth={strokeWidth} fillRule="evenodd">
          <g transform="translate(-4.000000, -296.000000)">
            <g transform="translate(2.000000, 294.000000)">
              <g transform="translate(2.666667, 2.000000)">
                <path
                  className="icon-path"
                  d="M5.33333333,0 C6.80609267,0 8,1.19390733 8,2.66666667 C8,4.139426 6.80609267,5.33333333 5.33333333,5.33333333 C3.860574,5.33333333 2.66666667,4.139426 2.66666667,2.66666667 C2.66666667,1.19390733 3.860574,2.96059473e-16 5.33333333,0 L5.33333333,0 Z M8,7.02666667 C8,7.73333333 7.81333333,9.38 6.54,11.22 L6,8 L6.62666667,6.74666667 C6.21333333,6.7 5.78,6.66666667 5.33333333,6.66666667 C4.88666667,6.66666667 4.45333333,6.7 4.04,6.74666667 L4.66666667,8 L4.12666667,11.22 C2.85333333,9.38 2.66666667,7.73333333 2.66666667,7.02666667 C1.07333333,7.49333333 0,8.33333333 0,9.33333333 L0,12 L4,12 L4.72666667,12 L5.94,12 L6.66666667,12 L10.6666667,12 L10.6666667,9.33333333 C10.6666667,8.33333333 9.6,7.49333333 8,7.02666667 Z"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
