import { EventGroup } from '../event-group';
import {
  getLaneHeightKoef,
  getInitialScale,
  getElementOffset,
} from '../../../../../reducers/ui';
import * as d3S from 'd3-selection';
import { store } from '../../../../../root';
import { ExtendedEventElement } from '..';
import EventElement from '../../../../../types/event-element';
import { getAircraftIndexMapExcludingHolding } from '../../../../../selectors';
import { getOverlapWithMaintenanceGradient } from '../../../../svg-patterns/overlappedMaintenances';
import Maintenance from '../../../../../types/maintenance';

interface OverlappedMaintenance extends ExtendedEventElement {
  elements: Maintenance[];
}

export class OverlappedMaintenances extends EventGroup {
  rectTopCalculator = d => {
    const state = store.getState();
    const { togglersState } = state.aircraft;
    const { segmentsVisibility, positionMap } = state.ui;
    return (
      getAircraftIndexMapExcludingHolding(state)[d.aircraftId] *
        state.ui.rowHeight +
      getElementOffset(
        segmentsVisibility,
        'maintenances',
        togglersState[d.aircraftId],
        positionMap
      ) /
        getLaneHeightKoef(
          segmentsVisibility,
          togglersState[d.aircraftId],
          positionMap
        )
    );
  };
  buildPropsForComponent = (d: EventElement) => {
    const { time, ui } = store.getState();
    const newD = Object.create(d) as ExtendedEventElement;
    newD.y = this.rectTopCalculator(newD);
    newD.now = time.now;
    newD.canvasWidth = ui.width;
    return newD;
  };
  renderEntered(
    entered: d3S.Selection<
      d3S.EnterElement,
      OverlappedMaintenance,
      SVGGElement,
      {}
    >
  ) {
    const state = store.getState();
    const { ui, aircraft } = state;
    const { segmentsVisibility, positionMap, width, planeBlockWidth } = ui;
    const { togglersState } = aircraft;
    const scaleX = getInitialScale(width - planeBlockWidth);
    const maintenanceEntered = entered;
    const rectGroup = maintenanceEntered.append('g').classed('event', true);
    rectGroup
      .append('rect')
      .attr('fill', d =>
        getOverlapWithMaintenanceGradient(d.elements[0], d.elements[1])
      )
      .attr('x', d => {
        return scaleX(d.start);
      })
      .attr('y', d => d.y)
      .attr('width', d => this.rectWidthCalculator(d, scaleX))
      .attr(
        'height',
        m =>
          positionMap['maintenances'] /
          getLaneHeightKoef(
            segmentsVisibility,
            togglersState[m.aircraftId],
            positionMap
          )
      )
      .attr('vector-effect', 'non-scaling-stroke');
    return rectGroup;
  }
}
