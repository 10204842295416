import { FC } from 'react';
import { Checkbox, Select } from 'antd';
import '../styles.scss';
import {
  PeakDaysSelectFilterType,
  PeakDaysSelectFilterOptionValue,
} from '../../../../types/peak-day';
import { connect, DispatchProp } from 'react-redux';

import { userChangeSelectFilter } from '../../../../actions';
import { RootState } from '../../../../reducers';
import { AnyAction } from 'redux';

const optionsSelect: PeakDaysSelectFilterOptionValue[] = ['Yes', 'No'];

interface Props {
  type: PeakDaysSelectFilterType;
  selectedValue: PeakDaysSelectFilterOptionValue[];
}

export const SelectFilter: FC<Props & DispatchProp<AnyAction>> = ({
  type,
  selectedValue,
  dispatch,
}) => {
  const onChangeValue = (newValue: PeakDaysSelectFilterOptionValue[]) => {
    dispatch(userChangeSelectFilter({ type, value: newValue }));
  };
  return (
    <div className="event-list-top-filter-input">
      <label>
        {type === 'advertising' ? 'Auto Advertising' : 'Crew Change'}
      </label>
      <Select
        value={selectedValue}
        placeholder={selectedValue.length === 0 ? 'All' : selectedValue}
        mode="multiple"
        onChange={onChangeValue}
        optionLabelProp="value"
        className="event-list-top-filter-input-select"
      >
        {optionsSelect.map(el => {
          return (
            <Select.Option key={el} value={el} label={el}>
              <Checkbox key={el} checked={selectedValue.includes(el)} />
              <label className="event-list-top-filter-input-select-label">
                {el}
              </label>
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
};

export const SelectFilterAdvertising = connect(
  (state: RootState): Props => ({
    selectedValue: state.peakDays.filters.advertising,
    type: 'advertising',
  })
)(SelectFilter);
export const SelectFilterCrew = connect(
  (state: RootState): Props => ({
    selectedValue: state.peakDays.filters.crew,
    type: 'crew',
  })
)(SelectFilter);
