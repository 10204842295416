import { difference } from 'lodash';
import { createSelector } from 'reselect';

import { isDraggableByOpStatus } from '../common/flight/flight-check-status';
import { flattenPool, Pool } from '../data-processing/pool-structure';
import { RootState } from '../reducers';
import { getElementOffset, getLaneHeightKoef } from '../reducers/ui';
import { getVisibleElements } from '../selectors';
import Aircraft from '../types/aircraft';
import Flight from '../types/flight';
import {
  getAircraftIndexMapExcludingHolding,
  getAllOperatingAircraft,
} from '.';

export const getFlightsTransformedToRects = createSelector(
  (state: RootState) => getVisibleElements(state, 'flights'),
  (state: RootState) => state.aircraft.togglersState,
  (state: RootState) => state.ui.segmentsVisibility,
  (state: RootState) => state.ui.positionMap,
  getAircraftIndexMapExcludingHolding,
  (state: RootState) => state.ui.rowHeight,
  (state: RootState) => state.ui.transform.scaleX,
  (state: RootState) => state.ui.width - state.ui.planeBlockWidth,
  (state: RootState) => state.ui.transform.translateY,
  (
    flights,
    togglersState,
    segmentsVisibility,
    positionMap,
    aircrfatIndexMap,
    rowHeight,
    scaleX,
    uiWidth,
    translateY
  ) => {
    return flights.map(fl => {
      const y1 =
        aircrfatIndexMap[fl.aircraftId] * rowHeight +
        getElementOffset(
          segmentsVisibility,
          'flights',
          togglersState[fl.aircraftId],
          positionMap
        ) /
          getLaneHeightKoef(
            segmentsVisibility,
            togglersState[fl.aircraftId],
            positionMap
          ) +
        translateY;
      const x1 = scaleX(fl.start) % uiWidth;
      const width = scaleX(fl.end) - scaleX(fl.start);
      const height =
        positionMap['flights'] /
        getLaneHeightKoef(
          segmentsVisibility,
          togglersState[fl.aircraftId],
          positionMap
        );
      return {
        id: fl.id,
        x1,
        y1,
        x2: x1 + width,
        y2: y1 + height,
      };
    });
  }
);
const getSelectionInVisibleArea = createSelector(
  (state: RootState) => state.ui.draggingFlightsSelection,
  (state: RootState) => state.ui.planeBlockWidth,
  (state: RootState) =>
    state.ui.controlsBarHeight +
    state.ui.timelineBarHeight +
    state.ui.filterBarHeight +
    state.ui.multipleSearchBarHeight +
    state.ui.marginTop +
    state.ui.holdLineHeight,
  ({ start, end }, planeBlockWidth, offsetTop) => {
    return {
      x1: start.x - planeBlockWidth,
      x2: end.x - planeBlockWidth,
      y1: start.y - offsetTop,
      y2: end.y - offsetTop,
    };
  }
);
interface ISegment {
  start: number;
  end: number;
}
export const isInRange = (segment: ISegment, range: ISegment) =>
  !(
    Math.max(segment.start, segment.end) <= Math.min(range.start, range.end) ||
    Math.min(segment.start, segment.end) >= Math.max(range.start, range.end)
  );
export const getFlightsIdsInsideSelection = createSelector(
  getSelectionInVisibleArea,
  getFlightsTransformedToRects,
  (selection, rects) => {
    return rects.reduce((acc, rect) => {
      return isInRange(
        { start: rect.x1, end: rect.x2 },
        { start: selection.x1, end: selection.x2 }
      ) &&
        isInRange(
          { start: rect.y1, end: rect.y2 },
          { start: selection.y1, end: selection.y2 }
        )
        ? acc.concat(rect.id)
        : acc;
    }, []);
  }
);
export const getUpdatedSelectedFlightsIds = createSelector(
  (state: RootState) => state.ui.selectedFlights,
  getFlightsIdsInsideSelection,
  (alreadySelected, elementsToAdd) => {
    return alreadySelected.concat(difference(elementsToAdd, alreadySelected));
  }
);

export const getFlightsByIdFromPool = createSelector<
  RootState,
  Pool<Flight>,
  { [id: number]: Flight }
>(
  state => state.timelineEvents.flights,
  flightsPool =>
    flattenPool(flightsPool).reduce<{
      [id: number]: Flight;
    }>((acc, flight) => {
      acc[flight.id] = flight;
      return acc;
    }, {})
);
export const getFlightsByOriginalIdFromPool = createSelector<
  RootState,
  Pool<Flight>,
  { [id: number]: Flight }
>(
  state => state.timelineEvents.flights,
  flightsPool =>
    flattenPool(flightsPool).reduce<{
      [id: number]: Flight;
    }>((acc, flight) => {
      if (flight.originalFlightLegId) {
        acc[flight.originalFlightLegId] = flight;
        return acc;
      }
      return acc;
    }, {})
);

export const getFilteredByOpStatusUpdatedSelectedFlightIds = createSelector<
  RootState,
  number[],
  { [id: number]: Flight },
  number[]
>(
  getUpdatedSelectedFlightsIds,
  getFlightsByIdFromPool,
  (selectedFlights, flightsById) =>
    selectedFlights.filter(id =>
      isDraggableByOpStatus(flightsById[id].legOperationalStatusId)
    )
);

export const getDraggingFlights = createSelector(
  (state: RootState) => state.timelineEvents.flights,
  (state: RootState) => state.ui.selectedFlights,
  (flightsPool, ids): Flight[] =>
    flattenPool(flightsPool).filter(f => ids.includes(f.id))
);

export const getAircraftByDraggingFlights = createSelector(
  getAllOperatingAircraft,
  getDraggingFlights,
  (aircraft: Aircraft[], flights: Flight[]): Aircraft[] => {
    if (!flights.length) return [];
    const aircraftIdsByFlights = flights.map(f => f.aircraftId);
    return aircraft.filter(a => aircraftIdsByFlights.includes(a.id));
  }
);
