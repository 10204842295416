import { store as reduxStore } from '../../../../../root';
import * as d3S from 'd3-selection';
import * as actions from '../../../../../actions';
import {
  getBoundingElement,
  setDataTestEntityIdD3Elements,
} from '../../../../../utils';
import {
  getElementOffsetWithKoef,
  getLaneHeightKoef,
} from '../../../../../reducers/ui';
import { CrewRoster } from '../../../../../types/crew-roster';
import { sortBy } from 'lodash';
import { CENTRED_DOT_SYMBOL } from '../../../../../constants';

export const getCrewRosterD3creator = (
  d: d3S.Selection<any, any, any, any>
): d3S.Selection<any, any, any, any> => {
  const state = reduxStore.getState();
  const { transform, segmentsVisibility, positionMap } = state.ui;
  const { togglersState } = state.aircraft;

  const container = d
    .append('div')
    .classed('label-text', true)
    .style('transform', d => `translate(${d.x}px, ${d.y}px)`)
    .classed('crew-roster-text-label', true)
    .attr(
      'data-test-entity',
      setDataTestEntityIdD3Elements('crew-roster-text-label')
    )
    .style('padding-left', d => `${-d.x > d.width ? 0 : Math.max(-d.x, 0)}px`)
    .on('mouseover', function(d) {
      reduxStore.dispatch(
        actions.userHoverCrew([d, getBoundingElement(this), 'crewRoster'])
      );
    })
    .on('mouseout', function(d) {
      reduxStore.dispatch(actions.userCloseTooltipCrew());
    })
    .style(
      'padding-right',
      d => `${Math.max(d.x + d.width - transform.scaleX.range()[1], 0)}px`
    )
    .style(
      'top',
      el =>
        `${getElementOffsetWithKoef(
          segmentsVisibility,
          'crewRoster',
          togglersState[el.aircraftId],
          positionMap
        ) * transform.ky}px`
    )
    .style(
      'height',
      d =>
        `${(positionMap.crewRoster * transform.ky) /
          getLaneHeightKoef(
            segmentsVisibility,
            togglersState[d.aircraftId],
            positionMap
          )}px`
    )
    .style('width', d => `${d.width}px`)
    .text((d: CrewRoster) => {
      return sortBy(d.team, c => c.role.rank)
        .map(c => c.crewMemberCode)
        .join(` ${CENTRED_DOT_SYMBOL} `);
    });
  return container;
};
