import { PureComponent } from 'react';
import { Popover, Button } from 'antd';
import { RootState } from '../../../reducers';
import { DispatchProp, connect } from 'react-redux';
import {
  StatusGroupType,
  getAllCheckboxStatus,
} from '../../../reducers/operational-statuses';
import { createDelayedForScrollConnect } from '../../../utils/create-delayed-for-scroll-connect';
import { OpStatusesContent } from './content';
import { getButtonSize } from '../utils';
import { AnyAction } from 'redux';

interface StateProps {
  statusMap: { [st in StatusGroupType]: boolean };
  allCheckboxStatus: -1 | 0 | 1;
  isVerticalMode: boolean;
}

class FilterByOpStatuses extends PureComponent<
  StateProps & DispatchProp<AnyAction>
> {
  render() {
    const { isVerticalMode } = this.props;
    return (
      <Popover
        content={<OpStatusesContent {...this.props} />}
        placement="bottom"
      >
        <Button type="dashed" size={getButtonSize(isVerticalMode)}>
          OP status
        </Button>
      </Popover>
    );
  }
}

const advancedMap = createDelayedForScrollConnect((state: RootState) => ({
  statusMap: state.opstatuses.statusMap,
  allCheckboxStatus: getAllCheckboxStatus(state),
  isVerticalMode: state.ui.isVerticalMode,
}));

export const FilterByOpStatusesConnected = connect(advancedMap)(
  FilterByOpStatuses
);
