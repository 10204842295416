import createCachedSelector from 're-reselect';
import { RootState } from '../reducers';

const isUnsafeMode =
  typeof (window as any).UNSAFE_MODE !== 'undefined' &&
  (window as any).UNSAFE_MODE === 'true';

export type GrainRole =
  | 'AG-Timeline'
  /** CREATE */
  | 'AG-Timeline-Create-Note'
  | 'AG-Timeline-Create-Order'
  | 'AG-Timeline-Create-MX-Event'
  /** Update */
  | 'AG-Timeline-Fight-Move'
  | 'AG-Timeline-Mark-Flight'
  | 'AG-Timeline-ETD-Edit'
  | 'AG-Timeline-Timeline-Event-Edit' // Peak day edit
  /** DELETE */
  | 'AG-Timeline-Delete-MX-Event'
  | 'AG-Timeline-Delete-Note'
  /** VIEW */
  | 'AG-Timeline-View-MX-Event'
  | 'AG-Timeline-View-EL-Offer'
  | 'AG-Timeline-View-Note'
  | 'AG-Timeline-View-OW-Offer'
  | 'AG-Timeline-MEL-View'
  | 'AG-Timeline-Timeline-Event-View' // Peak day view
  | 'AG-Timeline-PSS-View'
  | 'AG-Timeline-Lead-Pax-View'
  | 'AG-Timeline-Remarks-View'
  | 'AG-Timeline-Program-Revenue-View'
  | 'AG-Timeline-OD-Revenue-View'
  | 'AG-Timeline-PFA-View'
  | 'AG-Timeline-FP-View'
  | 'AG-Timeline-Add-Ferry-View'
  | 'AG-Timeline-Flight-Center-View'
  | 'AG-Timeline-Order-Window-View'
  | 'AG-Timeline-Open-Quote'
  | 'AG-Timeline-MOC_ARINC-send'
  | 'AG-Timeline-Handover-View' // both CS & OPS teams
  // Handover create/edit/delete
  | 'AG-Timeline-Handover-Ops_Edit'
  | 'AG-Timeline-Handover-CS_Edit'
  /** External links */
  | 'AG-Timeline-FC2020-Default-View'
  | 'AG-Timeline-Customer-preferences';

const cachedRole = createCachedSelector(
  (state, key) => key,
  (state: RootState) => state.user.roles,
  (key, roles) => {
    return roles.reduce<{ [r in GrainRole]?: boolean }>((acc, role) => {
      acc[role] = true;
      return acc;
    }, {});
  }
)((state, key) => key);

export const hasPermission = (state: RootState, role: GrainRole) => {
  if (isUnsafeMode) return true;
  return !!cachedRole(state, '')[role];
};
