import { keys, values } from 'lodash';
import { createSelector } from 'reselect';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import * as actions from '../actions';
import { OperationalStatus } from '../types/flight';
import { DEFAULT_USER_PREFERENCES } from '../types/user-preferences';
import { RootState } from './';

export type StatusGroupType =
  | 'Option'
  | 'Unconfirmed'
  | 'Confirmed'
  | 'Landed'
  | 'No Show';

export interface OperationStatus {
  statusMap: { [sg in StatusGroupType]: boolean };
}

export const initialState: OperationStatus = {
  statusMap: {
    Option: true,
    Unconfirmed: true,
    Confirmed: true,
    Landed: true,
    'No Show': true,
  },
};

export const getAllCheckboxStatus = createSelector(
  (state: RootState) => state.opstatuses.statusMap,
  statusMap => {
    const vals = values(statusMap);
    if (vals.every(b => b)) {
      return 1;
    }
    if (vals.every(b => !b)) {
      return 0;
    }
    return -1;
  }
);

export const getSelectedStatusesIds = createSelector(
  (state: RootState) => state.opstatuses.statusMap,
  getAllCheckboxStatus,
  (sMap, checkboxStatus): OperationalStatus[] => {
    if (checkboxStatus === 1) {
      return [
        OperationalStatus.OPTION,
        OperationalStatus.CONFIRMED,
        OperationalStatus.READY,
        OperationalStatus.FLYING,
        OperationalStatus.LANDED,
        OperationalStatus.COMPLETED,
        OperationalStatus.UNCONFIRMED,
        OperationalStatus.NO_SHOW,
      ];
    }
    let resultArr: number[] = [];
    if (sMap.Option) {
      resultArr.push(OperationalStatus.OPTION);
    }
    if (sMap.Unconfirmed) {
      resultArr.push(OperationalStatus.UNCONFIRMED);
    }
    if (sMap.Landed) {
      resultArr.push(OperationalStatus.LANDED);
    }
    if (sMap['No Show']) {
      resultArr.push(OperationalStatus.NO_SHOW);
    }
    if (sMap.Confirmed) {
      resultArr.push(OperationalStatus.CONFIRMED);
      resultArr.push(OperationalStatus.READY);
      resultArr.push(OperationalStatus.FLYING);
    }
    return resultArr;
  }
);

export default reducerWithInitialState(initialState)
  .case(actions.userToggleOpStatusView, (state, payload) => ({
    ...state,
    statusMap: {
      ...state.statusMap,
      [payload[0]]: payload[1],
    },
  }))
  .case(actions.userToggleOpStatusViewAll, (state, payload) => {
    const newObj = keys(state.statusMap).reduce(
      (acc, k) => ({ ...acc, [k]: payload }),
      {}
    );
    return {
      ...state,
      statusMap: newObj as any,
    };
  })
  .case(actions.doGetUserPreferences.done, (state, { result }) => ({
    ...state,
    statusMap: result.statusMap,
  }))
  .case(actions.userResetPreferences, state => ({
    ...state,
    statusMap: DEFAULT_USER_PREFERENCES.statusMap,
  }));
