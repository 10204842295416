import { chain } from 'lodash';
import { duration } from 'moment';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { getAcTypesByIdMap } from '.';
import { formatNearestAcFerryTime } from '../components/nearest-aircraft-modal/utils';
import { RootState } from '../reducers';
import { NearestAircraftState } from '../reducers/nearest-aircraft';
import {
  FilterByAircraftType,
  FilterByOperatingCompany,
} from '../types/aircraft';
import Flight from '../types/flight';
import {
  NearestAircraftTableData,
  NearestAircraftTableKey,
  TimeForSliderKey,
} from '../types/nearest-aircraft';
import { sortByCompanyRank } from '../utils/aircraft';

function formatFerryTime(ferryTimeMs: number): number {
  return +formatNearestAcFerryTime(ferryTimeMs).replace(':', '.');
}
function formatNextTime(date: number): number {
  return Math.floor(duration(date).asHours());
}
function isInRange(
  el: NearestAircraftTableData,
  maxFerryRange: [number, number],
  nextEventRange: [number, number],
  flight: Flight
): boolean {
  const flightTime = flight
    ? flight.departureUtcEstimated || flight.departureUtcScheduled
    : new Date().getTime();
  const [minFerry, maxFerry] = maxFerryRange;
  const [minNext, maxNext] = nextEventRange;
  // Ferry time slider filter
  const rowFerry = formatFerryTime(el.ferryTimeMs);
  const inFerryRange =
    (el.ferryTimeMs === null && minFerry === 0) ||
    (rowFerry >= formatFerryTime(minFerry) &&
      rowFerry <= formatFerryTime(maxFerry));
  // Next time slider filter
  const rowNext = formatNextTime(el.nextFlightTimeMs - flightTime);
  const inNextEventRange =
    (el.nextFlightTimeMs === null && minNext === 0) ||
    (rowNext >= formatNextTime(minNext) && rowNext <= formatNextTime(maxNext));
  return inFerryRange && inNextEventRange;
}
export const getFilteredNearestAircraftTable = createCachedSelector<
  RootState,
  NearestAircraftTableKey,
  NearestAircraftTableData[],
  NearestAircraftState,
  NearestAircraftTableKey,
  NearestAircraftTableData[]
>(
  (state, tableKey) => state.nearestAircraft[tableKey],
  state => state.nearestAircraft,
  (_state, tableKey) => tableKey,
  (table, state) => {
    const {
      maxFerryRange,
      nextEventRange,
      flight,
      mainOperatorIds,
      showSubCharters,
      acTypeFilterByOperatorMap: config,
      visibleAircraftIds,
      loading,
    } = state;
    if (loading) return [];
    return table
      .filter(el => {
        // Filter by aircraft, ac type and operator
        const isOnTheFilters = mainOperatorIds.includes(el.operatingCompanyId);
        if (isOnTheFilters && config) {
          // operating company is on
          const isOnByOperator = config[el.operatingCompanyId];
          // ac type on operating company is on
          const isOnByAcType =
            isOnByOperator &&
            config[el.operatingCompanyId].includes(el.aircraftTypeId);
          if (
            !isOnByOperator ||
            (isOnByOperator && !isOnByAcType) ||
            (isOnByOperator &&
              isOnByAcType &&
              // exact aircraft inside ac type on operating company is off
              !visibleAircraftIds.includes(el.aircraftId))
          )
            return false;
        }
        if (!showSubCharters) return isOnTheFilters;
        return true;
      })
      .filter(el => isInRange(el, maxFerryRange, nextEventRange, flight));
  }
)((_state, tableKey) => tableKey);

export const getRangeForSliderByKey = createCachedSelector<
  RootState,
  TimeForSliderKey,
  NearestAircraftTableData[],
  NearestAircraftTableData[],
  NearestAircraftTableData[],
  number,
  TimeForSliderKey,
  [number, number]
>(
  state => state.nearestAircraft.downgradeTableData,
  state => state.nearestAircraft.inFleetTableData,
  state => state.nearestAircraft.upgradeTableData,
  state =>
    state.nearestAircraft.flight
      ? state.nearestAircraft.flight.departureUtcEstimated ||
        state.nearestAircraft.flight.departureUtcScheduled
      : new Date().getTime(),
  (_state, timeKey) => timeKey,
  (downgradeTableData, inFleetTableData, upgradeTableData, flightTime, key) => {
    const data = [
      ...downgradeTableData,
      ...inFleetTableData,
      ...upgradeTableData,
    ]
      .map(el => el[key])
      .filter(el => el !== null);
    const values = key === 'ferryTimeMs' ? data : data.map(d => d - flightTime);
    const minRange = Math.min(...values);
    const maxRange = Math.max(...values);
    return [minRange, maxRange];
  }
)((_state, timeKey) => timeKey);

export const getOnlyMainFleet = createSelector<
  RootState,
  NearestAircraftTableData[],
  number[],
  NearestAircraftTableData[]
>(
  state =>
    state.nearestAircraft.downgradeTableData.concat(
      state.nearestAircraft.inFleetTableData,
      state.nearestAircraft.upgradeTableData
    ),
  state => state.nearestAircraft.mainOperatorIds,
  (data, mainOperatorIds) =>
    data.reduce<NearestAircraftTableData[]>((acc, el) => {
      if (mainOperatorIds.includes(el.operatingCompanyId)) {
        return acc.concat(el);
      }
      return acc;
    }, [])
);

export const getOperatingCompaniesFilter = createSelector<
  RootState,
  NearestAircraftTableData[],
  NearestAircraftState['acTypeFilterByOperatorMap'],
  FilterByOperatingCompany[]
>(
  getOnlyMainFleet,
  state => state.nearestAircraft.acTypeFilterByOperatorMap,
  (data, config) => {
    return chain(data)
      .uniqBy(t => t.operatingCompanyId)
      .map(a => ({
        id: a.operatingCompanyId,
        name: a.operatingCompanyName,
        checked: config ? !!config[a.operatingCompanyId] : true,
      }))
      .sortBy('name')
      .sortBy(c => sortByCompanyRank(c.id))
      .value();
  }
);

const getNearestAircraftByIdMap = createSelector<
  RootState,
  NearestAircraftTableData[],
  { [aircraftId: number]: NearestAircraftTableData }
>(getOnlyMainFleet, aircraft =>
  aircraft.reduce<{ [aircraftId: number]: NearestAircraftTableData }>(
    (acc, aircraft) => {
      acc[aircraft.aircraftId] = aircraft;
      return acc;
    },
    {}
  )
);

export const getAcTypeFilterByOperatorMap = createSelector<
  RootState,
  NearestAircraftTableData[],
  { [id: number]: Omit<FilterByAircraftType, 'checked' | 'tails'> },
  NearestAircraftState['acTypeFilterByOperatorMap'],
  { [companyId: number]: FilterByAircraftType[] }
>(
  getOnlyMainFleet,
  getAcTypesByIdMap,
  state => state.nearestAircraft.acTypeFilterByOperatorMap,
  (data, acTypeMap, config) => {
    return data.reduce<{
      [companyId: number]: FilterByAircraftType[];
    }>((acc, tail) => {
      const acType: FilterByAircraftType = {
        ...acTypeMap[tail.aircraftTypeId],
        checked: config
          ? !!config[tail.operatingCompanyId]?.includes(tail.aircraftTypeId)
          : true,
        tails: [],
      };
      const accByOpCompany = acc[tail.operatingCompanyId] || [];
      return {
        ...acc,
        [tail.operatingCompanyId]: accByOpCompany.find(
          t => t.id === tail.aircraftTypeId
        )
          ? accByOpCompany // removing duplications
          : chain(accByOpCompany.concat(acType))
              .sortBy('code')
              .sortBy('rank')
              .value(),
      };
    }, {});
  }
);

const getAircraftIdsOnTypeByOperatorMap = createSelector<
  RootState,
  NearestAircraftTableData[],
  { [companyId: number]: { [acTypeId: number]: number[] } }
>(getOnlyMainFleet, data =>
  data.reduce<{ [companyId: number]: { [acTypeId: number]: number[] } }>(
    (acc, ac) => {
      acc = {
        ...acc,
        [ac.operatingCompanyId]: {
          ...acc[ac.operatingCompanyId],
          [ac.aircraftTypeId]: (
            acc[ac.operatingCompanyId]?.[ac.aircraftTypeId] || []
          ).concat(ac.aircraftId),
        },
      };
      return acc;
    },
    {}
  )
);

export const getFullFilterConfig = createSelector<
  RootState,
  { [companyId: number]: { [acTypeId: number]: number[] } },
  { [companyId: number]: FilterByAircraftType[] },
  { [aircraftId: number]: NearestAircraftTableData },
  NearestAircraftState['visibleAircraftIds'],
  { [companyId: number]: FilterByAircraftType[] }
>(
  getAircraftIdsOnTypeByOperatorMap,
  getAcTypeFilterByOperatorMap,
  getNearestAircraftByIdMap,
  state => state.nearestAircraft.visibleAircraftIds,
  (
    availableAircraftMap,
    availableAircraftTypesMap,
    aircraftByIdMap,
    visibleAircraftIds
  ) => {
    return Object.keys(availableAircraftTypesMap).reduce<{
      [companyId: number]: FilterByAircraftType[];
    }>((acc, key) => {
      acc[+key] = availableAircraftTypesMap[+key].map(
        (t: FilterByAircraftType) => ({
          ...t,
          tails: availableAircraftMap[+key][t.id].map(id => {
            const currentAc: NearestAircraftTableData = aircraftByIdMap[id];
            return {
              checked: !!visibleAircraftIds?.includes(id),
              id,
              name: currentAc.tailNumber,
            };
          }),
        })
      );
      return acc;
    }, {});
  }
);

export const getCachedAircraftFilter = createCachedSelector<
  RootState,
  number,
  { [companyId: number]: FilterByAircraftType[] },
  number,
  FilterByAircraftType[]
>(
  getFullFilterConfig,
  (_state, key) => key,
  (map, key) => map[key]
)((_state, key) => key);
