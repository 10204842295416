import * as d3S from 'd3-selection';
import { ExtendedEventElement } from '../..';
import { OVERLAP_MARKER_HEIGHT } from '../../../../../constants/environment';
import { getElementOffsetWithKoef } from '../../../../../reducers/ui';
import { store as reduxStore } from '../../../../../root';
import { OverlappedElements } from '../../../../../types/event-element';
import { getBasicFlightOverlapD3creator } from './basic-flight-overlap';

export const getOverlappedFlightMxPairsD3creator = (
  d: d3S.Selection<
    any,
    ExtendedEventElement & OverlappedElements,
    HTMLDivElement,
    {}
  >
) => {
  const state = reduxStore.getState();
  const { transform, segmentsVisibility, positionMap } = state.ui;
  const { togglersState } = state.aircraft;
  const container = getBasicFlightOverlapD3creator(d);
  container
    .style('width', d => `${d.width}px`)
    .append('div')
    .classed('label-text', true)
    .classed('overlap-marker', true)
    .style('height', `${OVERLAP_MARKER_HEIGHT}px`)
    .style('position', 'relative')
    .style('background-color', 'red')
    .style('top', d => {
      const offset =
        getElementOffsetWithKoef(
          segmentsVisibility,
          'maintenances',
          togglersState[d.aircraftId],
          positionMap
        ) *
          transform.ky -
        getElementOffsetWithKoef(
          segmentsVisibility,
          'flights',
          togglersState[d.aircraftId],
          positionMap
        ) *
          transform.ky;
      return `${offset - OVERLAP_MARKER_HEIGHT / 2}px`;
    });
  return container;
};
