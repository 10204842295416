import { SegmentsAppenderArgs } from '..';
import { getLaneHeightKoef } from '../../../../../../reducers/ui';

export function appendSlotMismatches(argsObject: SegmentsAppenderArgs) {
  const {
    selection,
    segmentsVisibility,
    positionMap,
    togglersState,
    scaleX,
    firstLineHeight,
    secondLineHeight,
    thirdLineHeight,
  } = argsObject;
  selection
    .filter(d => d.departureSlotMismatch)
    .append('use')
    .attr(
      'y',
      d =>
        d.y -
        17 +
        (firstLineHeight + secondLineHeight + thirdLineHeight / 2) /
          getLaneHeightKoef(
            segmentsVisibility,
            togglersState[d.aircraftId],
            positionMap
          )
    )
    .attr('x', d => scaleX(d.start) - 17)
    .attr('xlink:href', '#slot-mismatch-dot');
  selection
    .filter(d => d.arrivalSlotMismatch)
    .append('use')
    .attr(
      'y',
      d =>
        d.y -
        17 +
        (firstLineHeight + secondLineHeight + thirdLineHeight / 2) /
          getLaneHeightKoef(
            segmentsVisibility,
            togglersState[d.aircraftId],
            positionMap
          )
    )
    .attr('x', d => scaleX(d.end) - 17)
    .attr('xlink:href', '#slot-mismatch-dot');

  return selection;
}
