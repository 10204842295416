import { PureComponent } from 'react';
import { Popover } from 'antd';
import Note from '../../../../types/note';
import { connect } from 'react-redux';
import { ContextNoteMenuConnected } from '../../../context-menu/elements/noteMenu';
import TooltipDates from '../../dates';
import TooltipCreationLogTime from '../../creation-log-time';
import TooltipHeader from '../../header/';
import { NoteActionButtons as ActionButtons } from '../../action-buttons';
import * as actions from '../../../../actions';
import { RootState } from '../../../../reducers';
import { LINE_HEIGHT } from '../../../../constants';
import { setDataTestEntityId } from '../../../../utils';
import { InputSourceIdType } from '../../../../types/input-source';
import { AnyAction, Dispatch } from 'redux';

interface TooltipContentProps {
  segmentData: Note;
  hasEditPermission: boolean;
  hasDeletePermission: boolean;
  hasActionButtons?: boolean;
}
class TooltipContent extends PureComponent<TooltipContentProps> {
  render() {
    const {
      hasActionButtons,
      segmentData,
      hasEditPermission,
      hasDeletePermission,
    } = this.props;
    const {
      person,
      lastUpdated,
      message,
      start,
      end,
      inputSourceId,
    } = segmentData;
    const updatedBy =
      inputSourceId === InputSourceIdType.SQL
        ? 'System'
        : person && `${person.firstName ?? ''} ${person.lastName ?? ''}`;
    return (
      <div className="tooltip-common">
        <div
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ width: 272 }}>
            <TooltipCreationLogTime
              updatedBy={updatedBy}
              lastUpdated={lastUpdated}
              isUpdated={true}
            />
            <div
              className="tooltip-common-description"
              {...setDataTestEntityId('tooltip-note-description')}
              style={{
                maxHeight: `${LINE_HEIGHT * 11.5}px`,
                overflow: 'hidden',
              }}
            >
              <div
                style={{
                  display: '-webkit-box',
                  WebkitLineClamp: 11,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                }}
              >
                {message}
              </div>
            </div>
          </div>
          {hasActionButtons && (
            <div className="tooltip-action-buttons-container">
              <ActionButtons
                id={segmentData.id}
                exactNote={segmentData}
                hasEditPermission={hasEditPermission}
                hasDeletePermission={hasDeletePermission}
              />
            </div>
          )}
        </div>
        <TooltipDates start={start} end={end} />
      </div>
    );
  }
}

interface OwnProps {
  segmentData: Note;
  exactHoveredNote: Note;
  typeName: string;
  hasEditPermission: boolean;
  hasDeletePermission: boolean;
  hasActionButtons?: boolean;
  hasContextMenu?: boolean;
}
interface Props extends OwnProps {}
interface StateProps {
  hoveredNoteId: number;
}
interface DispatchProps {
  onHover: (id: number) => void;
  handleHoverOut: (id: number) => void;
}
interface State {
  visible: boolean;
  isHovered: boolean;
}
class TooltipNoteComponent extends PureComponent<
  Props & DispatchProps & StateProps,
  State
> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isHovered: false,
    };
  }
  hide = () => {
    this.setState({ visible: false });
  };
  handleOpenChange = visible => {
    this.setState({ visible });
  };
  handleHover = () => {
    this.props.onHover(this.props.segmentData.id);
  };
  handleHoverOut = () => {
    this.props.handleHoverOut(this.props.segmentData.id);
  };
  getOpacityByHover = () => {
    const { hoveredNoteId, exactHoveredNote, segmentData } = this.props;
    if (
      hoveredNoteId === segmentData.id ||
      (!hoveredNoteId &&
        exactHoveredNote &&
        exactHoveredNote.id === segmentData.id)
    ) {
      return 1;
    }
    return 0.5;
  };
  render() {
    const {
      segmentData,
      typeName,
      hasActionButtons,
      hasEditPermission,
      hasDeletePermission,
      hasContextMenu,
    } = this.props;
    const { id } = segmentData;
    const { visible } = this.state;
    return (
      <div
        className="tooltip-container"
        onMouseEnter={this.handleHover}
        onMouseLeave={this.handleHoverOut}
        style={{
          opacity: this.getOpacityByHover(),
        }}
      >
        <TooltipHeader
          id={id}
          color="rgba(0, 0, 0, 0.85)"
          backgroundColor={'#fdd835'}
          name={typeName}
        />
        {hasContextMenu ? (
          <Popover
            trigger="contextMenu"
            open={visible}
            onOpenChange={this.handleOpenChange}
            placement="right"
            mouseLeaveDelay={0}
            content={
              <div style={{ margin: '-12px -33px' }} onClick={this.hide}>
                <ContextNoteMenuConnected id={id} />
              </div>
            }
          >
            <TooltipContent
              hasActionButtons={hasActionButtons}
              segmentData={segmentData}
              hasDeletePermission={hasDeletePermission}
              hasEditPermission={hasEditPermission}
            />
          </Popover>
        ) : (
          <TooltipContent
            hasActionButtons={hasActionButtons}
            segmentData={segmentData}
            hasDeletePermission={hasDeletePermission}
            hasEditPermission={hasEditPermission}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  hoveredNoteId: state.ui.hoveredNoteId,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => ({
  onHover: id => dispatch(actions.userHoverTooltipNote(id)),
  handleHoverOut: id => dispatch(actions.userUnhoverTooltipNote(id)),
});

const TooltipNote = connect(
  mapStateToProps,
  mapDispatchToProps
)(TooltipNoteComponent);

export default TooltipNote;
