import {
  CloseOutlined,
  MinusOutlined,
  PlusOutlined,
  PushpinOutlined,
} from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DatePickerFrom, DatePickerTo } from '../filters/DatePicker';
import {
  OperatorMultiSelectComponent,
  AcTypeMultiSelectComponent,
  TailMultiSelectComponent,
  CsTeamMultiSelectComponent,
} from '../filters/SelectFilter';
import { RadioComponent } from '../filters/RadioFilter';
import StickyNoteIcon from '../../../svg-icons/sticky-note';
import {
  userChangeHandoverViewByDepartment,
  userChangeHandoverViewByType,
  userCloseHandoverRemarksDrawer,
} from '../../../../actions';
import { RootState } from '../../../../reducers';
import {
  HandoverRemarksViewByType,
  HandoverType,
} from '../../../../types/handover-remarks';
import { isEqual } from 'lodash';
import { MultiRadioComponent } from '../filters/MultiRadioFilter';

interface Props {
  notesAreSticked: boolean;
  showMoreFilters: boolean;
  onClickPin: () => void;
  onClickPinTailNotes: () => void;
  onToggleRangeFilters: () => void;
}

export const AllHandoverRemarksTitle: FC<Props> = ({
  notesAreSticked,
  onClickPin,
  onClickPinTailNotes,
  showMoreFilters,
  onToggleRangeFilters,
}) => {
  const { unPinned, radioByType, radioByDepartment } = useSelector(
    (state: RootState) => ({
      unPinned: state.handoverRemarks.unPinned,
      radioByType: state.handoverRemarks.filters.radioByType,
      radioByDepartment: state.handoverRemarks.filters.radioByDepartment,
    }),
    isEqual
  );
  const dispatch = useDispatch();
  const onClose = () => dispatch(userCloseHandoverRemarksDrawer());
  return (
    <div className="remarks-title-column">
      <div>
        <h2>Handover Remarks</h2>
        <RadioComponent
          options={HandoverRemarksViewByType}
          value={radioByType}
          onChange={value =>
            dispatch(
              userChangeHandoverViewByType(value as HandoverRemarksViewByType)
            )
          }
        />
        <MultiRadioComponent
          options={HandoverType}
          value={radioByDepartment}
          onChange={value =>
            dispatch(
              userChangeHandoverViewByDepartment(value as HandoverType[])
            )
          }
          disabled={radioByType === HandoverRemarksViewByType.tail}
        />
        <Tooltip
          title={notesAreSticked ? 'Unpin Tail Notes' : 'Pin Tail Notes'}
        >
          <Button
            type="primary"
            size="small"
            onClick={onClickPinTailNotes}
            style={{ marginLeft: '12px' }}
          >
            <div
              style={{
                width: '18px',
                height: '20px',
              }}
            >
              <StickyNoteIcon />
            </div>
          </Button>
        </Tooltip>
        <Tooltip
          title={unPinned ? 'Pin Handover panel' : 'Unpin Handover panel'}
        >
          <Button
            style={{ marginLeft: '12px' }}
            type="primary"
            icon={<PushpinOutlined />}
            size="small"
            onClick={onClickPin}
          />
        </Tooltip>
        {unPinned ? (
          <Button
            icon={<CloseOutlined />}
            onClick={onClose}
            style={{ border: 'none', marginLeft: '8px' }}
            size="small"
          />
        ) : null}
      </div>
      <div className="remarks-title-column-filter-container">
        <div style={{ display: 'flex' }}>
          <OperatorMultiSelectComponent type="operator" width={170} />
          <AcTypeMultiSelectComponent type="acTypes" width={158} />
          <TailMultiSelectComponent type="tail" width={130} />
        </div>
        <Button
          icon={showMoreFilters ? <MinusOutlined /> : <PlusOutlined />}
          style={{
            borderColor: 'transparent',
            fontSize: '12px',
          }}
          onClick={onToggleRangeFilters}
        >
          {showMoreFilters ? 'Less' : 'More'}
        </Button>
      </div>
      {showMoreFilters && (
        <div className="remarks-title-column-more-filters">
          <DatePickerFrom />
          <DatePickerTo />
          <CsTeamMultiSelectComponent />
        </div>
      )}
    </div>
  );
};
