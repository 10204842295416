import EventElement from '../types/event-element';
import { duration, utc } from 'moment';
import { chain, negate, reduce, sortBy } from 'lodash';
import Note from '../types/note';
import { buildMapFromBatch } from '../utils/build-map-from-batch';
import getActualId from '../utils/getId';
import { getDay } from '../utils/hold-line-flights';

export type Pool<T extends EventElement> = {
  day: number;
  pool: T[];
}[];

function addToDate<T extends EventElement>(
  acc: { [day: number]: T[] },
  key: number,
  el: T
) {
  if (acc[key]) {
    acc[key].push(el);
  } else {
    acc[key] = [el];
  }
}

function groupperReducer<T extends EventElement>(
  acc: { [day: number]: T[] },
  item: T
) {
  const dayOfStartMilliseconds = utc(item.start)
    .startOf('day')
    .valueOf();
  const millisecondsDuration = item.end - dayOfStartMilliseconds;
  const dayDuration = duration(millisecondsDuration).asDays();
  for (let i = dayDuration; i >= 0; i--) {
    addToDate(
      acc,
      utc(item.start)
        .startOf('day')
        .add(Math.floor(i), 'd')
        .valueOf(),
      item
    );
  }
  return acc;
}

function groupperMapper<T extends EventElement>(source: {
  [day: number]: T[];
}): Pool<T> {
  return Object.keys(source).map(key => {
    return {
      day: Number(key),
      pool: source[key],
    };
  });
}

export function createPool<T extends EventElement>(elements: T[]): Pool<T> {
  return groupperMapper(elements.reduce(groupperReducer, {}));
}

export function createPoolWithOutOvernightDuplication<T extends EventElement>(
  elements: T[]
): Pool<T> {
  return groupperMapper(
    elements.reduce((acc: { [day: number]: T[] }, item: T) => {
      addToDate(acc, getDay(item.start), item);
      return acc;
    }, {})
  );
}

export function getElementsMap<T extends EventElement>(
  pool: Pool<T>
): { [id: number]: T } {
  return pool.reduce((acc, p) => {
    p.pool.forEach(element => {
      acc[getActualId(element)] = element;
    });
    return acc;
  }, {});
}
export function mergePools<T extends EventElement>(
  currentPool: Pool<T>,
  mergeCandidatePool: Pool<T>
): Pool<T> {
  const currentElementsCollection = getElementsMap(currentPool);
  const updateElementsCollection = getElementsMap(mergeCandidatePool);
  const resultingElementsCollection = {
    ...currentElementsCollection,
    ...updateElementsCollection,
  };
  const resultingPool = createPool<T>(
    reduce(
      resultingElementsCollection,
      (acc, value) => {
        if (value) acc.push(value);
        return acc;
      },
      []
    )
  );
  return sortBy(resultingPool, d => d.day);
}

export function flattenPool<T extends EventElement>(p: Pool<T>): T[] {
  return chain(p)
    .flatMap(p => p.pool)
    .uniqBy(f => f.id)
    .value();
}

function getDaysForElement(f: EventElement): number[] {
  const dayOfStartMilliseconds = utc(f.start)
    .startOf('day')
    .valueOf();
  const days = [];
  const millisecondsDuration = f.end - dayOfStartMilliseconds;
  const dayDuration = duration(millisecondsDuration).asDays();
  for (let i = dayDuration; i > 0; i--) {
    days.push(
      utc(f.start)
        .startOf('day')
        .add(Math.floor(i), 'd')
        .valueOf()
    );
  }
  return days;
}
interface UpdatingReducerAcc<T extends EventElement> {
  existingPool: Pool<T>;
  touchedAndNotPresentingDays: number[];
}
export function updateInPool<T extends EventElement>(
  p: Pool<T>,
  e: T
): Pool<T> {
  const elementTouchedDays = getDaysForElement(e);
  const updatingResult: UpdatingReducerAcc<T> = p.reduce(
    (acc, stateDaysPool) => {
      const predicateForDayFinding = elementTouchedDay =>
        utc(elementTouchedDay).isBetween(
          utc(stateDaysPool.day),
          utc(stateDaysPool.day).add(1, 'd'),
          null,
          '[)'
        );
      const thisDayInPool = acc.touchedAndNotPresentingDays.find(
        predicateForDayFinding
      );
      if (!thisDayInPool) {
        const newThisDayInPool = {
          day: stateDaysPool.day,
          pool: stateDaysPool.pool.filter(f => f.id !== e.id),
        };
        return {
          touchedAndNotPresentingDays: acc.touchedAndNotPresentingDays,
          existingPool: [...acc.existingPool, newThisDayInPool],
        };
      }
      const poolUniqAndUpdated = chain([e])
        .concat(stateDaysPool.pool)
        .uniqBy(f => f.id)
        .value();

      const newThisDayInPool = {
        day: stateDaysPool.day,
        pool: poolUniqAndUpdated,
      };
      const newTouchedAndNotPresentingDays = acc.touchedAndNotPresentingDays.filter(
        negate(predicateForDayFinding)
      );
      return {
        touchedAndNotPresentingDays: newTouchedAndNotPresentingDays,
        existingPool: [...acc.existingPool, newThisDayInPool],
      };
    },
    {
      existingPool: [],
      touchedAndNotPresentingDays: elementTouchedDays,
    } as UpdatingReducerAcc<T>
  );
  const newElements = updatingResult.existingPool;
  const notExistingDays = updatingResult.touchedAndNotPresentingDays;
  const additionalDaysForElement = notExistingDays.map(function createPoolDay(
    day
  ) {
    const startOfDay = utc(day)
      .startOf('d')
      .valueOf();
    return { day: startOfDay, pool: [e] };
  });
  return newElements.concat(additionalDaysForElement);
}

export function reducePoolByBatchUpdate<T extends EventElement>(
  p: Pool<T>,
  batch: { [id: number]: T }
): Pool<T> {
  const updatePool = createPool<T>(
    reduce(
      batch,
      (acc, value) => {
        if (value) acc.push(value);
        return acc;
      },
      []
    )
  );
  return mergePools(p, updatePool).reduce((result, p) => {
    const pool = p.pool.reduce((acc, el: T) => {
      if (batch[getActualId(el)] === null) {
        return acc;
      }
      acc.push(el);
      return acc;
    }, []);
    if (!pool.length) return result;
    return result.concat({
      ...p,
      pool,
    });
  }, []);
}
export function reduceNotePoolByBatchUpdate<N extends Note>(
  p: Pool<N>,
  batch: { [id: number]: Note },
  isMyType: (n: Note) => boolean
): Pool<N> {
  const updatePool = createPool<N>(
    reduce(
      batch,
      (acc, value) => {
        if (value && isMyType(value)) acc.push(value as N);
        return acc;
      },
      []
    )
  );
  return mergePools(p, updatePool).map(p => ({
    ...p,
    pool: p.pool.reduce((acc, el) => {
      if (
        (!!batch[el.id] &&
          (!isMyType(batch[el.id]) || !batch[el.id].isActive)) ||
        batch[el.id] === null
      ) {
        return acc;
      }
      acc.push(el);
      return acc;
    }, []),
  }));
}
export function reduceListByBatchUpdate<T extends { id: number }>(
  l: T[],
  batch: { [id: number]: T }
): T[] {
  const mapByBatch = buildMapFromBatch(batch);
  return l
    .reduce((acc, el) => {
      if (!el || !el.id || batch[el.id] === null) return acc;
      if (mapByBatch.has(el.id)) mapByBatch.delete(el.id);
      if (batch[el.id]) {
        acc.push(batch[el.id]);
        return acc;
      }
      acc.push(el);
      return acc;
    }, [] as T[])
    .concat(Array.from(mapByBatch.values()));
}
