import { apolloClient } from '../../data-processing/graphql';
import { MXEventOrderTypeDto } from '../../types/maintenance';
import { gql } from '@apollo/client';

const getMxEventOrderTypes = require('./get-mx-event-order-types.gql');

export function getMxOrderTypes() {
  return apolloClient.query<MXEventOrderTypeDto>({
    query: gql(getMxEventOrderTypes),
  });
}
