import * as d3S from 'd3-selection';
import { ExtendedEventElement } from '../..';
import { OverlappedElements } from '../../../../../types/event-element';
import { getBasicFlightOverlapD3creator } from './basic-flight-overlap';

export const getOverlappedFlightsD3creator = (
  d: d3S.Selection<
    any,
    ExtendedEventElement & OverlappedElements,
    HTMLDivElement,
    {}
  >
) => {
  const container = getBasicFlightOverlapD3creator(d);
  container
    .style('width', d => `${d.x < 0 ? Math.max(d.width + d.x, 0) : d.width}px`)
    .style('background', 'rgba(200,0,0,0.3)')
    .style('border-left', 'solid 2px red')
    .style('border-right', 'solid 2px red')
    .style('border-round', '2px');
  return container;
};
