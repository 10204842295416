import { FC, useEffect } from 'react';
import { ReducerShape } from '../../../reducers/ui';
import { RootState } from '../../../reducers';
import { refreshTimer } from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { utc } from 'moment';

import { isEqual } from 'lodash';
import { ONE_SECOND_MS } from '../../../constants';

const markerContainerWidth = 45;
const markerLineWidth = 3;

interface CurrentMarkerProps {
  height: number;
  now: number;
  planeBlockWidth: number;
  scaleX: ReducerShape['transform']['scaleX'];
  today: number;
}
export const CurrentTimeMarker: FC<{}> = () => {
  const dispatch = useDispatch();
  const { height, now, planeBlockWidth, scaleX, today } = useSelector<
    RootState,
    CurrentMarkerProps
  >(
    state => ({
      height: state.ui.height,
      now: state.time.now,
      planeBlockWidth: state.ui.planeBlockWidth,
      scaleX: state.ui.transform.scaleX,
      today: state.time.today,
    }),
    isEqual
  );
  useEffect(() => {
    const now = utc().valueOf();

    const realToday = utc(today).day() !== utc(now).day() ? now : today;
    const timer = window.setInterval(
      () => dispatch(refreshTimer({ now, today: realToday })),
      ONE_SECOND_MS * 2
    );
    return () => {
      clearInterval(timer);
    };
  }, [now, today]);

  return (
    <div
      className="timeline-current-time-marker"
      style={{
        position: 'absolute',
        overflow: 'visible',
        width: `${markerLineWidth}px`,
        height: `${height}px`,
        transform: `translate(${scaleX(now) -
          markerLineWidth / 2 +
          planeBlockWidth}px,0)`,
      }}
    >
      <div
        className="current-time-marker-label"
        style={{
          height: '15px',
          position: 'absolute',
          border: '1px solid #4A90E2',
          width: `${markerContainerWidth}px`,
          left: `${-markerContainerWidth / 2 + markerLineWidth / 2}px`,
          lineHeight: '15px',
          textAlign: 'center',
          top: '8px',
          backgroundColor: '#fff',
        }}
      >
        <div>{utc(now).format('HH:mm')}</div>
      </div>
      <svg width={markerLineWidth} height={height}>
        <g className="current-time-marker">
          <line
            stroke="#4A90E2"
            x1={markerLineWidth / 2}
            x2={markerLineWidth / 2}
            y1={20}
            y2={height}
            strokeWidth={markerLineWidth}
          />
        </g>
      </svg>
    </div>
  );
};
