import { Checkbox, Divider } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import {
  getAllAvailableAircraftCount,
  getFullMainAcFilterMap,
  getOperatingCompaniesMap,
  getSortedVisibleAircraft,
} from '../../../selectors';
import {
  FilterByAircraftType,
  FilterByOperatingCompany,
  OperatingCompanyLabel,
  CurrentAcTypePositionPayload,
} from '../../../types/aircraft';
import {
  userToggleSubChartersView,
  userToggleSelectAllAcTypes,
  userToggleGroupByOperator,
  userToggleLabelOperatorsView,
  userToggleEditModeOfAcTypesPosition,
  userSavePreferences,
  doSetAcTypesPositionMap,
  userResetChangesInAcTypesPositionMap,
} from '../../../actions';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { setDataTestEntityId } from '../../../utils';
import { getAllAircraftOptionsAndIds } from '../../../utils/aircraft';
import { getUserPreferences } from '../../../selectors/user-preferences';
import { UserPreferences } from '../../../types/user-preferences';
import { AircraftFilterHeader } from './header';
import { isEqual } from 'lodash';
import { MainContentMemoized } from './main';

interface StateProps {
  acTypesByCompanyIdMap: {
    [id: number]: FilterByAircraftType[];
  };
  operatingCompanies: FilterByOperatingCompany[];
  showSubCharters: boolean;
  isEditModeAcTypes: boolean;
  acTypesPositionMap: CurrentAcTypePositionPayload;
  userPreferences: UserPreferences;
}

export const AircraftFilter: FC<{}> = () => {
  const dispatch = useDispatch();
  const {
    acTypesByCompanyIdMap,
    acTypesPositionMap,
    isEditModeAcTypes,
    operatingCompanies,
    showSubCharters,
    userPreferences,
  } = useSelector<RootState, StateProps>(state => {
    const { showSubCharters } = state.aircraft;
    return {
      acTypesByCompanyIdMap: getFullMainAcFilterMap(state),
      acTypesPositionMap: state.aircraft.acTypesPositionMap,
      isEditModeAcTypes: state.ui.isEditModeAcTypes,
      operatingCompanies: getOperatingCompaniesMap(state),
      showSubCharters,
      userPreferences: getUserPreferences(state),
    };
  }, isEqual);
  const [initialAcTypesPositionMap, setInitialPosition] = useState<
    CurrentAcTypePositionPayload
  >(acTypesPositionMap);

  useEffect(() => {
    let combinedAcTypesPositionMap = {};
    const isAcTypesPositionMap = !Object.values(acTypesPositionMap).length;
    if (isAcTypesPositionMap) {
      combinedAcTypesPositionMap = Object.entries(acTypesByCompanyIdMap).reduce<
        CurrentAcTypePositionPayload
      >((acc, el) => {
        const [companyId, acTypesIds] = el;
        return {
          ...acc,
          [companyId]: acTypesIds.map(s => s.id),
        };
      }, []);
      dispatch(doSetAcTypesPositionMap(combinedAcTypesPositionMap));
      setInitialPosition(combinedAcTypesPositionMap);
    }
  }, [acTypesPositionMap]);

  const handleSelectAll = (e: CheckboxChangeEvent) => {
    const { allOptionsMap, allAcIds } = getAllAircraftOptionsAndIds(
      acTypesByCompanyIdMap
    );
    dispatch(
      userToggleSelectAllAcTypes({
        allOptionsMap,
        selected: e.target.checked,
        allAcIds,
      })
    );
  };
  const onSavePreset = () => {
    dispatch(userSavePreferences.started(JSON.stringify(userPreferences)));
  };
  const toggleSaveEditState = () => {
    if (isEditModeAcTypes) {
      onSavePreset();
    } else {
      dispatch(userToggleEditModeOfAcTypesPosition(true));
    }
  };
  const onUserResetChanges = () => {
    dispatch(userResetChangesInAcTypesPositionMap(initialAcTypesPositionMap));
  };
  const onSelectSubCharters = () => dispatch(userToggleSubChartersView());
  const onToggleGroupedBy = () => dispatch(userToggleGroupByOperator());
  const onToggleLabelOperators = () => dispatch(userToggleLabelOperatorsView());

  const classNamePrefix = 'filter-bar-';
  return (
    <div className="filter-bar-actypes-content unselectable">
      <AircraftFilterHeader
        onSelectAll={handleSelectAll}
        onToggleGroupedBy={onToggleGroupedBy}
        onToggleLabelOperators={onToggleLabelOperators}
        onToggleSaveEditState={toggleSaveEditState}
        onUserResetChanges={onUserResetChanges}
      />

      <div className="filter-bar-actypes-content-companies-title">
        Operating companies
      </div>
      <div className="filter-bar-actypes-content-companies">
        {operatingCompanies.map((company, index) => (
          <MainContentMemoized
            classNamePrefix={classNamePrefix}
            company={company}
            index={index}
            isEditModeAcTypes={isEditModeAcTypes}
            operatingCompanies={operatingCompanies}
            key={company.id}
          />
        ))}
      </div>
      <Divider className="filter-bar-actypes-content-sub-divider" />
      <Checkbox
        onChange={onSelectSubCharters}
        checked={showSubCharters}
        disabled={isEditModeAcTypes}
        {...setDataTestEntityId(`ac-type-filter-subcharters`)}
      >
        {OperatingCompanyLabel.subCharters}
      </Checkbox>
    </div>
  );
};
