import { PureComponent } from 'react';
import { LINE_HEIGHT } from '../../constants';

interface Props {
  HAS_REMARKS_PERMISSION: boolean;
  remarks: string;
}

class RemarksBlock extends PureComponent<Props> {
  render() {
    const { HAS_REMARKS_PERMISSION, remarks } = this.props;
    return (
      HAS_REMARKS_PERMISSION && (
        <>
          <div className="ft-name not-centered">Remarks</div>
          <div
            className="ft-text"
            style={{
              maxHeight: `${LINE_HEIGHT * 6.5}px`,
            }}
          >
            {remarks &&
              remarks
                .split(/[\r\n]/)
                .map(el => el.trim())
                .join('\n')}
          </div>
        </>
      )
    );
  }
}

export default RemarksBlock;
