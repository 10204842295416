import { PureComponent } from 'react';
import Airport from '../../../../types/airport';
import { DispatchProp, connect } from 'react-redux';
import { RootState } from '../../../../reducers';
import { Popover } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import '../styles.scss';
import { Header } from '../header';
import TooltipEmptyLegCommon from '../../../tooltip/vistajet/empty-leg';
import EmptyLegOfferCardContent from './content';
import EmptyLegOffer from '../../../../types/empty-leg-offer';
import { getBorderColorToEmptyLeg } from '../../../../common/empty-leg-and-one-way/empty-leg-one-way-colorization';
import { hasPermission } from '../../../../utils/check-permissions';
import { AnyAction } from 'redux';

interface PreviewProps {
  isFocusing: boolean;
  isFocused: boolean;
  onFocus: () => void;
  onBlur: () => void;
  element: EmptyLegOffer;
  placement: TooltipPlacement;
}

interface StateProps {
  airportsById: { [id: string]: Airport };
  hasCheckedEmptyLegOffersPermission: boolean;
}

class EmptyLegOfferPreviewCardComponent extends PureComponent<
  PreviewProps & StateProps & DispatchProp<AnyAction>
> {
  render() {
    const {
      element,
      airportsById,
      isFocused,
      isFocusing,
      onFocus,
      onBlur,
      placement = 'right',
      hasCheckedEmptyLegOffersPermission,
    } = this.props;
    return (
      <Popover
        content={
          <TooltipEmptyLegCommon
            segmentData={element}
            airportsById={airportsById}
          />
        }
        overlayClassName="overlaped-popover"
        placement={placement}
      >
        <div
          className="card"
          style={{
            marginTop: 8,
            border: '1px solid #DDDDDD',
            borderRadius: 4,
            opacity: !isFocusing || isFocused ? 1 : 0.3,
          }}
          onMouseEnter={onFocus}
          onMouseLeave={onBlur}
        >
          <Header
            height={6}
            colors={[getBorderColorToEmptyLeg(element.offerStatus)]}
          />
          <EmptyLegOfferCardContent
            element={element}
            airportsById={airportsById}
            hasCheckedEmptyLegOffersPermission={
              hasCheckedEmptyLegOffersPermission
            }
          />
        </div>
      </Popover>
    );
  }
}

export const EmptyLegOfferPreviewCard = connect<StateProps>(
  (state: RootState) => ({
    airportsById: state.airports.airportsById,
    hasCheckedEmptyLegOffersPermission: hasPermission(
      state,
      'AG-Timeline-View-EL-Offer'
    ),
  })
)(EmptyLegOfferPreviewCardComponent);
