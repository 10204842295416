import { apolloClient } from '../../data-processing/graphql';
import { MailTemplate } from '../../types/email';
import { gql } from '@apollo/client';

const getRtsEmailGql = require('./get-rts-email-template.gql');

export function getRtsEmailTemplate(
  operator: string,
  manufacturerId: number | null
) {
  return apolloClient.query<MailTemplate>({
    query: gql(getRtsEmailGql),
    variables: {
      operator,
      manufacturerId,
    },
  });
}
