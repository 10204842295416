import EventElement from '../../types/event-element';
import Flight, {
  LegBusinessType,
  FlightServiceStatus,
  PermissionSlotType,
  OperationalStatus,
} from '../../types/flight';
import {
  ON_DEMAND_LIKE_BUSINESS_TYPES_ARR,
  HANDLING_ID,
  SPECIAL_LOAD_START_ID,
  SPECIAL_LOAD_END_ID,
  FP_OPERATIONAL_STATUS_MAP,
  NEW_SPECIAL_LOAD_ID,
} from '../../constants';

export const isFlight = (element: EventElement): element is Flight =>
  !!(element as Flight).flightOrderId;

export const isFlightOnDemandLike = (f: Flight) =>
  ON_DEMAND_LIKE_BUSINESS_TYPES_ARR.some(s => s === f.orderBusinessTypeId);

export const isFerryFlight = (legBusinessTypeId: LegBusinessType) =>
  legBusinessTypeId === LegBusinessType.FERRY_CHARGEABLE ||
  legBusinessTypeId === LegBusinessType.FERRY_OPERATIONAL;

export const isLiveFlight = (flight: Flight) =>
  flight.legBusinessTypeId === LegBusinessType.LIVE_CHARGEABLE ||
  flight.legBusinessTypeId === LegBusinessType.LIVE_OPERATIONAL;

export const getSlotWithLeadTimeByAirport = (f: Flight, airportId: number) => {
  if (f.slots)
    return f.slots.find(
      s =>
        s.leadTime &&
        s.airportId == airportId &&
        (s.flightServiceStatusId == FlightServiceStatus.NEW ||
          s.flightServiceStatusId == FlightServiceStatus.REQUESTED ||
          s.flightServiceStatusId == FlightServiceStatus.NEEDS_REVIEW)
    );
  return null;
};

export const getPermitsWithLeadTimeByAirport = (
  f: Flight,
  airportId: number
) => {
  if (f.permits)
    return f.permits.filter(
      s =>
        s.leadTime &&
        s.airportId == airportId &&
        (s.flightServiceStatusId == FlightServiceStatus.NEW ||
          s.flightServiceStatusId == FlightServiceStatus.REQUESTED ||
          s.flightServiceStatusId == FlightServiceStatus.NEEDS_REVIEW)
    );
  return null;
};

export const getPermitsWithLeadTimeByCountry = (f: Flight) => {
  if (f.permits)
    return f.permits.filter(
      s =>
        s.leadTime &&
        s.place &&
        s.place.country &&
        (s.flightServiceStatusId == FlightServiceStatus.NEW ||
          s.flightServiceStatusId == FlightServiceStatus.REQUESTED ||
          s.flightServiceStatusId == FlightServiceStatus.NEEDS_REVIEW)
    );
  return null;
};

export const getServicesStatusId = (f: Flight, airportId: number) => {
  if (f.requests) {
    const services = f.requests.filter(
      c => c.airportId == airportId && c.serviceTypeId !== HANDLING_ID
    );
    if (!services.length) return null;
    if (services.find(s => s.flightServiceStatusId == FlightServiceStatus.NEW))
      return FlightServiceStatus.NEW;
    if (
      services.find(
        s =>
          s.needsReview ||
          s.flightServiceStatusId == FlightServiceStatus.NEEDS_REVIEW
      )
    )
      return FlightServiceStatus.NEEDS_REVIEW;
    if (
      services.find(
        s => s.flightServiceStatusId == FlightServiceStatus.REQUESTED
      )
    )
      return FlightServiceStatus.REQUESTED;
    if (
      services.find(
        s => s.flightServiceStatusId == FlightServiceStatus.CONFIRMED
      )
    )
      return FlightServiceStatus.CONFIRMED;
    if (
      services.find(
        s => s.flightServiceStatusId == FlightServiceStatus.NOT_NEEDED
      )
    )
      return FlightServiceStatus.NOT_NEEDED;
  }
  return FlightServiceStatus.NEW;
};

export const getAdditionalPermitsArray = (f: Flight) => {
  if (f.permits)
    return f.permits.filter(
      c =>
        c.permissionSlotTypeId !== PermissionSlotType.INBOUND_PERMISSION &&
        c.permissionSlotTypeId !== PermissionSlotType.OUTBOUND_PERMISSION
    );
  return [];
};

export const isSpecialLoad = (f: Flight) => {
  if (f.permits)
    return f.requests.some(
      c =>
        c.flightServiceStatusId !== FlightServiceStatus.CANCELLED &&
        c.flightServiceStatusId !== FlightServiceStatus.INVALID &&
        ((c.serviceTypeId >= SPECIAL_LOAD_START_ID &&
          c.serviceTypeId <= SPECIAL_LOAD_END_ID) ||
          c.serviceTypeId === NEW_SPECIAL_LOAD_ID)
    );
  return false;
};

export const isNoFpStarted = (f: Flight) =>
  !f.readyForPreparation && FP_OPERATIONAL_STATUS_MAP[f.legOperationalStatusId];

export const isUnconfirmedOrOptionFlightStatus = (f: Flight) =>
  f.legOperationalStatusId === OperationalStatus.UNCONFIRMED ||
  f.legOperationalStatusId === OperationalStatus.OPTION;

export const isOperationalLegBusinessType = (f: Flight) =>
  f.legBusinessTypeId === LegBusinessType.LIVE_OPERATIONAL ||
  f.legBusinessTypeId === LegBusinessType.FERRY_OPERATIONAL;

export const isFailedPFAorFPIteration = (f: Flight) =>
  f.pfaFailed || f.fpFailed;

export const isDraggableByOpStatus = (
  legOperationalStatusId: OperationalStatus
) =>
  // QUOTED flights are hidden on TL as they don't have tail
  legOperationalStatusId !== OperationalStatus.CANCELLED &&
  legOperationalStatusId !== OperationalStatus.NO_SHOW &&
  legOperationalStatusId !== OperationalStatus.LANDED;
