export enum MaintenanceStatus {
  AOG = 1,
  SMX,
  UMX,
  RTSW,
  RTS,
}

export type OperatingCompany = 'VJ' | 'XO' | 'RWA' | 'JS' | 'AH';
export type MxEventPresenceType = 'MX' | 'RTS' | 'ALL';

export enum OperatingCompanyLabel {
  subCharters = 'Subcharters',
}
export enum WifiType {
  swift = 'Swift',
  ku = 'Ku',
  ka = 'Ka',
  gogo = 'Go_Go_Biz',
  gogoAdv = 'Go_Go_Advance_L5',
  gogoAv = 'Go_Go_Avance_L5',
  na = 'N/A',
}

export type MxDueTagColor = 'red' | 'amber';

export interface FilterByOperatingCompany {
  id: number;
  name: string;
  checked: boolean;
}
export interface FilterByAircraftType {
  id: number;
  checked: boolean;
  code: string;
  name: string;
  rank: number;
  tails: FilterByAircraft[];
}
export interface FilterByAircraft
  extends Omit<FilterByAircraftType, 'code' | 'rank' | 'tails'> {}

export interface SelectAircraftOrActypePayload {
  companyId: number;
  acTypeId: number;
  aircraftIdsOnThisType: number[];
  checked: boolean;
  isToRemoveAcType: boolean;
}
export interface CurrentAcTypePositionPayload {
  [id: number]: number[];
}
export interface SelectOperatingCompanyPayload {
  companyId: number;
  companiesAcTypeIds: number[];
  companyAircraftIds: number[];
  checked: boolean;
}

export interface SelectAllAircraftPayload {
  selected: boolean;
  allOptionsMap: { [companyId: number]: number[] };
  allAcIds: number[];
}
export interface OperatingCompanyLabelData {
  aircraftIndex: number;
  companyName: string;
  quantity: number;
  y: number;
}

export interface Aircraft {
  id: number;
  tailNumber: string;
  availabilityStartDate: number;
  availabilityEndDate: number;
  availabilityDesctiption: string;
  aircraftTypeId: number;
  aircraftTypeName: string;
  operationStartDate: number;
  operationEndDate: number;
  numberOfSeats: number;
  operatingCompanyId: number;
  operatingCompanyCountryCode: string;
  operatingCompanyName: string;
  owningCompanyId: number;
  aircraftTypeCode: string;
  aircraftTypeRank: number;
  aircraftTypeColour: string;
  maintenanceStatusId: MaintenanceStatus;
  aircraftDefects: string[];
  location: string;
  airportName: string;
  airportId: number;
  totalLandings: number;
  minutesFlown: number;
  hasMelDefects: boolean;
  serialNumber: string;
  hasAogDrivers: boolean;
  aogDescriptions: string[];
  lastRefurbishmentDate: number;
  refurbishmentNotes: string;
  manufactureDate: number;
  manufacturerId: number | null;
  wifi: WifiType;
  hasPrimaryWiFiDefects: boolean;
  hasSwiftWiFiDefects: boolean;
  customerAffectingNotes: string[];
  airTimeBeforeMX: number;
  landingsBeforeMX: number;
  hasPaxAffecting: boolean;
  nextDueDateMs: number;
}

export interface ExtendedAircraft extends Aircraft {
  userAircraftTypeRank?: number;
}

export default Aircraft;
