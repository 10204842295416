import { PureComponent } from 'react';
import { CENTRED_DOT_SYMBOL_BOLD } from '../../constants';
import Aircraft from '../../types/aircraft';
import Airport from '../../types/airport';
import { setDataTestEntityId } from '../../utils';
import {
  getICAOLabelFromAirport,
  getIATALabelFromAirport,
} from '../../utils/airport';

interface Props {
  departureAirport: Airport;
  arrivalAirport: Airport;
  aircraft: Aircraft;
  callSignValue: string;
}

class FlightTooltipCodes extends PureComponent<Props> {
  render() {
    return (
      <div className="ft-codes">
        {this.props.departureAirport && (
          <div className="ft-codes-departure">
            <div className="ft-codes-departure-icao">
              {getICAOLabelFromAirport(this.props.departureAirport)}
            </div>
            <div className="ft-codes-departure-iata">
              {getIATALabelFromAirport(this.props.departureAirport)}
            </div>
          </div>
        )}

        <div
          className="ft-codes-tail"
          {...setDataTestEntityId(`tooltip-flight-codes-tail`)}
        >
          {this.props.aircraft &&
            `${this.props.callSignValue} ${CENTRED_DOT_SYMBOL_BOLD} ${this.props.aircraft.tailNumber}`}
        </div>

        {this.props.arrivalAirport && (
          <div className="ft-codes-arrival">
            <div className="ft-codes-arrival-iata">
              {getIATALabelFromAirport(this.props.arrivalAirport)}
            </div>
            <div className="ft-codes-arrival-icao">
              {getICAOLabelFromAirport(this.props.arrivalAirport)}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default FlightTooltipCodes;
