import { PureComponent } from 'react';
import { FlightGhostOwnProps, FlightGhostStateProps } from './ghosts-render';

interface Props {
  currentTail: string;
  newTail: string;
}
export class FlightGhostTailNumber extends PureComponent<Props> {
  render() {
    const { currentTail, newTail } = this.props;
    return (
      <>
        <span className="tail-number-red">{currentTail}</span>
        <br />
        <span className="tail-number-green">{newTail}</span>
      </>
    );
  }
}

export class FlightGhost extends PureComponent<
  FlightGhostStateProps & FlightGhostOwnProps
> {
  render() {
    const { offset, height, width, currentTail, newTail } = this.props;
    return (
      <>
        <div
          style={{
            position: 'absolute',
            top: offset.top,
            left: offset.left,
            height,
            border: 'solid 1px rgba(100,100,100,0.8)',
            width,
            background: 'rgba(250,250,250,0.65)',
            overflow: 'visible',
          }}
        >
          <div
            style={{
              marginLeft: width,
              width: 100,
              fontSize: 12,
              lineHeight: '16px',
            }}
          >
            <FlightGhostTailNumber
              currentTail={currentTail}
              newTail={newTail}
            />
          </div>
        </div>
      </>
    );
  }
}
