import { PureComponent } from 'react';
import { dropRight } from 'lodash';
import OneWayOffer from '../../../../types/one-way-offer';
import Airport from '../../../../types/airport';
import Aircraft from '../../../../types/aircraft';
import TooltipHeader from '../../header/';
import TooltipCreationLogTime from '../../creation-log-time';
import TooltipCancellationReason from '../../cancellation-reason';
import { getBackgroundColorToOneWay } from '../../../../common/empty-leg-and-one-way/empty-leg-one-way-colorization';
import TooltipDates from '../../dates';
import { setDataTestEntityId } from '../../../../utils';
import { getOfferStatusName } from '../../../../utils/empty-leg-offer';

interface ConnectedProps {}
interface OwnProps {
  segmentData: OneWayOffer;
  airportsById: { [id: number]: Airport };
  aircraft: Aircraft;
}
interface Props extends ConnectedProps, OwnProps {}

function getAlternativeAirportsIcao(airportsArray: string[]): string {
  let result = null;
  if (airportsArray.length > 3) {
    result =
      dropRight(airportsArray, airportsArray.length - 3)
        .map(c => c)
        .join(', ') + '...';
  } else {
    result = airportsArray.map(c => c).join(', ');
  }
  return result;
}

export default class TooltipOneWayCommon extends PureComponent<Props> {
  render() {
    const { airportsById, aircraft, segmentData } = this.props;
    const {
      departureAirportId,
      validFrom,
      validTo,
      alternativeAirports,
      creator,
      offerStatus,
      cancellationReason,
    } = segmentData;
    const updatedBy = creator && `${creator.firstName} ${creator.lastName}`;
    const depAirport = airportsById[departureAirportId];
    return (
      <>
        <TooltipHeader
          id={segmentData.id}
          color="rgba(0, 0, 0, 0.85)"
          backgroundColor={getBackgroundColorToOneWay(offerStatus)}
          name={`One Way Offer • ${getOfferStatusName(offerStatus)}`}
        />
        <div className="tooltip-common">
          <TooltipCreationLogTime
            isUpdated={false}
            lastUpdated={validFrom}
            updatedBy={updatedBy}
          />
          <div className="tooltip-oneway-airport-values">
            <div className="tooltip-oneway-airport-group-current">
              <div className="tooltip-oneway-current-airport-label">
                Current Airport
              </div>
              <div className="tooltip-oneway-current-airport-info">
                <div className="tooltip-oneway-current-airport-icao">
                  {depAirport && depAirport.ICAO}
                </div>
                <div className="tooltip-oneway-current-airport-city">
                  {depAirport && depAirport.name.toLowerCase()}
                </div>
              </div>
            </div>
            <div className="tooltip-oneway-airport-group-aircraft">
              <div className="tooltip-oneway-aircraft-label">Tail #</div>
              <div className="tooltip-oneway-aircraft-number-and-type">
                <div
                  className="tooltip-oneway-aircraft-number"
                  {...setDataTestEntityId('tooltip-oneway-aircraft-number')}
                >
                  {aircraft && aircraft.tailNumber}
                </div>
                <div
                  className="tooltip-oneway-aircraft-type"
                  {...setDataTestEntityId('tooltip-oneway-aircraft-type')}
                >
                  {aircraft && aircraft.aircraftTypeCode}
                </div>
              </div>
            </div>
          </div>
          <div className="tooltip-oneway-alternative-airport">
            <div className="tooltip-oneway-alternative-airport-label">
              Alternative Airports
            </div>
            <div
              className="tooltip-oneway-alternative-airport-value"
              {...setDataTestEntityId(
                'tooltip-oneway-alternative-airport-value'
              )}
            >
              {getAlternativeAirportsIcao(alternativeAirports)}
            </div>
          </div>
          {cancellationReason && (
            <TooltipCancellationReason text={cancellationReason} />
          )}
          <TooltipDates start={validFrom} end={validTo} />
        </div>
      </>
    );
  }
}
