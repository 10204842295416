import { createDelayedForScrollConnect } from '../../utils/create-delayed-for-scroll-connect';
import { RootState } from '../../reducers';
import { connect } from 'react-redux';
import Maintenance, {
  MaintenanceType,
  MaintenanceOrderType,
} from '../../types/maintenance';
import { getMaintenanceSegmentColor } from '../../common/maintenance/maintenance-colorization';
import { BasicMaintenancePattern } from './basic-pattern';

type StripeColorType =
  | 'umx'
  | 'smx'
  | 'aog'
  | 'smx-double'
  | 'umx-double'
  | 'aog-double'
  | 'not-specified'
  // | 'umx-is-dispatchable'
  // | 'smx-is-dispatchable'
  // | 'aog-is-dispatchable'
  // | 'umx-is-dispatchable-double'
  // | 'smx-is-dispatchable-double'
  // | 'aog-is-dispatchable-double'
  // | 'service-order-double'
  | 'service-order';
const MaintenanceTypeColorMapping = {
  umx: getMaintenanceSegmentColor(null, MaintenanceType.UNSCHEDULED),
  smx: getMaintenanceSegmentColor(null, MaintenanceType.SCHEDULED),
  aog: getMaintenanceSegmentColor(null, MaintenanceType.AOG),
};
const MaintenanceSameTypeColorMapping = {
  'smx-double': '#96acfd',
  'umx-double': '#cea2fd',
  'aog-double': '#fda2a2',
  'not-specified': '#98bede',
};
// const MaintenanceIsDispatchableTypeColorMapping = {
//   'umx-is-dispatchable': getMaintenanceSegmentColor(
//     null,
//     MaintenanceType.UNSHEDULED,
//     true
//   ),
//   'smx-is-dispatchable': getMaintenanceSegmentColor(
//     null,
//     MaintenanceType.SCHEDULED,
//     true
//   ),
//   'aog-is-dispatchable': getMaintenanceSegmentColor(
//     null,
//     MaintenanceType.AOG,
//     true
//   ),
// };
// const MaintenanceSameIsDispatchableTypeColorMapping = {
//   'umx-is-dispatchable-double': 'black',
//   'smx-is-dispatchable-double': 'black',
//   'aog-is-dispatchable-double': 'black',
// };
const MaintenanceServiceOrderTypeColorMapping = {
  'service-order': getMaintenanceSegmentColor(
    MaintenanceOrderType.SERVICE_ORDER,
    MaintenanceType.UNSCHEDULED
  ),
  // 'service-order-double': 'black',
};
const MaintenanceTypeColorMappingAll = {
  ...MaintenanceTypeColorMapping,
  ...MaintenanceSameTypeColorMapping,
  // ...MaintenanceIsDispatchableTypeColorMapping,
  // ...MaintenanceSameIsDispatchableTypeColorMapping,
  ...MaintenanceServiceOrderTypeColorMapping,
};

interface StateProps {
  kx: number;
  ky: number;
}

const mapStateToProps = (state: RootState): StateProps => ({
  kx: state.ui.transform.kx,
  ky: state.ui.transform.ky,
});
const advancedMap = createDelayedForScrollConnect(mapStateToProps);

const AllOverlapWithMaintenancePatterns = (props: StateProps) => (
  <>
    {Object.keys(MaintenanceTypeColorMappingAll).map(type => {
      return (
        <BasicMaintenancePattern
          key={type}
          patternIdName={`overlapped-maintenance-with-${type}`}
          color={MaintenanceTypeColorMappingAll[type]}
          kx={props.kx}
          ky={props.ky}
        />
      );
    })}
  </>
);

export const AllOverlapWithMaintenancePatternsConnected = connect(advancedMap)(
  AllOverlapWithMaintenancePatterns
);

export const getOverlapWithMaintenanceGradient = (
  first: Maintenance,
  second: Maintenance
) => {
  if (first.maintenanceOrderType === MaintenanceOrderType.SERVICE_ORDER) {
    return getPatternUrl('service-order');
  }
  if (areSameColorMaintenanceSegments(first, second)) {
    // if (first.maintenanceOrderType === MaintenanceOrderType.SERVICE_ORDER) {
    //   return getPatternUrl('service-order-double');
    // }
    // if (first.isDispatchable) {
    //   switch (first.maintenanceType) {
    //     case MaintenanceType.AOG:
    //       return getPatternUrl('aog-is-dispatchable-double');
    //     case MaintenanceType.UNSHEDULED:
    //       return getPatternUrl('umx-is-dispatchable-double');
    //     case MaintenanceType.SCHEDULED:
    //       return getPatternUrl('smx-is-dispatchable-double');
    //   }
    // }
    switch (first.maintenanceType) {
      case MaintenanceType.AOG:
        return getPatternUrl('aog-double');
      case MaintenanceType.UNSCHEDULED:
        return getPatternUrl('umx-double');
      case MaintenanceType.SCHEDULED:
        return getPatternUrl('smx-double');
    }
  }
  // if (first.isDispatchable) {
  //   switch (first.maintenanceType) {
  //     case MaintenanceType.AOG:
  //       return getPatternUrl('aog-is-dispatchable');
  //     case MaintenanceType.SCHEDULED:
  //       return getPatternUrl('smx-is-dispatchable');
  //     case MaintenanceType.UNSHEDULED:
  //       return getPatternUrl('umx-is-dispatchable');
  //   }
  // }
  switch (first.maintenanceType) {
    case MaintenanceType.AOG:
      return getPatternUrl('aog');
    case MaintenanceType.SCHEDULED:
      return getPatternUrl('smx');
    case MaintenanceType.UNSCHEDULED:
      return getPatternUrl('umx');
  }
};

function getPatternUrl(name: StripeColorType) {
  return `url(#overlapped-maintenance-with-${name})`;
}

function areSameColorMaintenanceSegments(
  first: Maintenance,
  second: Maintenance
): boolean {
  if (
    first.maintenanceType === second.maintenanceType
    // &&
    // first.isDispatchable === second.isDispatchable
  ) {
    if (
      first.maintenanceOrderType !== MaintenanceOrderType.SERVICE_ORDER &&
      second.maintenanceOrderType !== MaintenanceOrderType.SERVICE_ORDER
    ) {
      return true;
    } else {
      return first.maintenanceOrderType === second.maintenanceOrderType;
    }
  }
  return false;
}
