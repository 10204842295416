import { WsHandoverUpdate, WsIncome } from '../../types/ws-income';
import * as actions from '../../actions';
import { WsUpdateBatch } from './ws-update-batch';

export function createWsThrottle(ms: number) {
  let isThrottling = null;
  let batchQueue = new WsUpdateBatch();
  return function pushMessage(msg: WsIncome) {
    if (!isThrottling) {
      isThrottling = setTimeout(() => {
        isThrottling = null;
        store.dispatch(actions.wsUpdateBatch(batchQueue));
        batchQueue.release();
        batchQueue = new WsUpdateBatch();
      }, ms);
      const oneTimeBatch = new WsUpdateBatch();
      oneTimeBatch.addToQueue(msg);
      store.dispatch(actions.wsUpdateBatch(oneTimeBatch));
    } else {
      batchQueue.addToQueue(msg);
    }
  };
}

export function createWsHandoverThrottle(ms: number) {
  let isThrottling = null;
  let batchQueue = [];
  return (income: WsHandoverUpdate) => {
    if (!isThrottling) {
      isThrottling = setTimeout(() => {
        isThrottling = null;
        store.dispatch(actions.wsHandoverUpdateBatch(batchQueue));
        batchQueue = [];
      }, ms);
      store.dispatch(actions.wsHandoverUpdateBatch(income));
    } else {
      batchQueue.push(income);
    }
  };
}
