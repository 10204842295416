import './styles.scss';

import { Button, Drawer, Tooltip } from 'antd';
import { Form, Formik, FormikHelpers } from 'formik';
import { utc } from 'moment';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import * as Yup from 'yup';
import {
  ChangeDatesPayload,
  userChangeDatesInEditDrawer,
  userCloseNoteModal,
  userSaveNote,
} from '../../actions';
import { RootState } from '../../reducers';
import { getOperatingAircraftForDropdown } from '../../selectors';
import { getNoteByIdMap } from '../../selectors/notes';
import { InputSourceIdType } from '../../types/input-source';
import Note from '../../types/note';
import NoteTimelineSegmentDto from '../../types/NoteTimelineSegmentDto';
import { hasPermission } from '../../utils/check-permissions';
import { Aircraft } from '../edit-event-drawer/interfaces';
import { NoteFormDetails } from '../edit-event-drawer/note-form';
import { ConfirmationModal } from '../../common/components/confirmation-modal/confirmation-modal';
import { FormId, initialNoteFormValues } from './constants';
import { NoteFormValues } from './interfaces';
import { transformNoteFormStateForSave } from './utils';
import { RIGHT_DRAWER_WIDTH } from '../../constants';
import { CloseOutlined } from '@ant-design/icons';

interface DispatchProps {
  onClose: () => void;
  onSubmit: (note: NoteTimelineSegmentDto) => void;
  onPickDate: (data: ChangeDatesPayload) => void;
}

interface State {
  initialValues: NoteFormValues;
  noteId: number | null;
  visibleConfirmationPopUp: boolean;
  formIsTouched: boolean;
  uniqIdentifier: string;
}

export interface NoteFormProps extends DispatchProps {
  aircraftList: Aircraft[];
  visible: boolean;
  uniqIdentifier: string;
  start: number;
  end: number;
  isSaving: boolean;
  selectedAircraftId: number;
  noteDetails: Note;
  noteId: number;
  userName: string;
  hasCreateNotePermission: boolean;
  height: number;
}

const validationSchema = Yup.object().shape({
  aircraftName: Yup.string()
    .nullable()
    .required('Aircraft is required'),
  fromDate: Yup.number()
    .nullable()
    .required('Please select start date'),
  toDate: Yup.number()
    .nullable()
    .required('Please select end date')
    .min(Yup.ref('fromDate'), 'The End date must be after than the Start date'),
  message: Yup.string()
    .nullable()
    .required('Note message is required'),
});

class CreateEditNoteDrawer extends PureComponent<NoteFormProps, State> {
  constructor(props: NoteFormProps) {
    super(props);
    this.state = CreateEditNoteDrawer.getUpdateNoteDetails(props);
  }
  static getDerivedStateFromProps(props: NoteFormProps, state: State) {
    if (
      props.noteId !== state.noteId ||
      state.uniqIdentifier !== props.uniqIdentifier
    ) {
      return CreateEditNoteDrawer.getUpdateNoteDetails(props);
    }
    return null;
  }
  static getUpdateNoteDetails(props: NoteFormProps) {
    const visibleConfirmationPopUp = false;
    const formIsTouched = false;
    const { noteDetails, noteId } = props;
    if (noteDetails) {
      const hydrated = {
        crewNotAvailable: noteDetails.crewNotAvailable,
        fromDate: noteDetails.start,
        id: noteDetails.id,
        importanceFactor: noteDetails.importanceFactor,
        message: noteDetails.message,
        toDate: noteDetails.end,
        aircraftId: noteDetails.aircraftId,
        aircraftName:
          props.aircraftList.find(ac => ac.id === noteDetails.aircraftId)
            ?.tailNumber || '',
      };
      return {
        initialValues: hydrated,
        noteId,
        formIsTouched,
        visibleConfirmationPopUp,
        uniqIdentifier: props.uniqIdentifier,
      };
    }
    const updatedWithStateValue = {
      ...initialNoteFormValues,
      fromDate: props.start
        ? utc(props.start).valueOf()
        : initialNoteFormValues.fromDate,
      toDate: props.end
        ? utc(props.end).valueOf()
        : initialNoteFormValues.toDate,
      aircraftId: props.selectedAircraftId,
      aircraftName:
        props.aircraftList.find(ac => ac.id === props.selectedAircraftId)
          ?.tailNumber || initialNoteFormValues.aircraftName,
    };
    return {
      initialValues: updatedWithStateValue,
      noteId: null,
      formIsTouched,
      visibleConfirmationPopUp,
      uniqIdentifier: props.uniqIdentifier,
    };
  }
  handleClose = () => {
    if (this.state.formIsTouched) {
      this.setState({
        visibleConfirmationPopUp: true,
      });
    } else {
      this.props.onClose();
    }
  };
  onCancelSave = () => {
    this.setState({
      visibleConfirmationPopUp: false,
    });
    this.props.onClose();
  };
  onCloseConfirmationModal = () => {
    this.setState({
      visibleConfirmationPopUp: false,
    });
  };
  setFormIsTouched = () => this.setState({ formIsTouched: true });
  render() {
    const {
      visible,
      noteId,
      onSubmit,
      userName,
      hasCreateNotePermission,
      noteDetails,
      height,
      isSaving,
    } = this.props;
    const isCrewRestNote = noteDetails?.inputSourceId === InputSourceIdType.SQL;
    const disabled = !hasCreateNotePermission || isCrewRestNote;
    const title = (
      <div className="drawer-title-wrapper">
        <span className="drawer-title">
          {!noteId ? 'Create a Note' : `Note# ${noteId}`}
        </span>
        <Button
          icon={<CloseOutlined />}
          onClick={this.handleClose}
          style={{ border: 'none' }}
        />
      </div>
    );
    return (
      <>
        <ConfirmationModal
          open={this.state.visibleConfirmationPopUp}
          onClose={this.onCloseConfirmationModal}
          footer={
            <div className="edit-event-form-footer">
              <Button
                htmlType="reset"
                key="reset"
                onClick={this.onCancelSave}
                form={FormId.createEditNote}
                className="edit-event-form-footer-button-cancel"
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                key="save"
                form={FormId.createEditNote}
                loading={isSaving}
                className="edit-event-form-footer-button-submit"
              >
                Yes
              </Button>
            </div>
          }
        />
        <Drawer
          closable={false}
          destroyOnClose
          maskClosable
          onClose={this.handleClose}
          placement="right"
          width={RIGHT_DRAWER_WIDTH}
          open={visible}
          title={title}
        >
          <Formik
            initialValues={this.state.initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(
              values: NoteFormValues,
              { setSubmitting }: FormikHelpers<NoteFormValues>
            ) => {
              onSubmit(
                transformNoteFormStateForSave(
                  values,
                  userName,
                  new Date().getTime()
                )
              );
              setSubmitting(false);
            }}
          >
            {formikProps => (
              <Form
                className="edit-event-form"
                style={{
                  height: `${height}px`,
                }}
                id={FormId.createEditNote}
              >
                <NoteFormDetails
                  {...formikProps}
                  disabled={disabled}
                  formIsTouched={this.state.formIsTouched}
                  setFormIsTouched={this.setFormIsTouched}
                />
                <div className="edit-event-form-footer">
                  <Button
                    onClick={this.handleClose}
                    className="edit-event-form-footer-button-cancel"
                  >
                    Cancel
                  </Button>
                  <Tooltip
                    title={
                      isCrewRestNote &&
                      'Note created by system and cannot be edited'
                    }
                    placement="topRight"
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      key="save"
                      form={FormId.createEditNote}
                      className="edit-event-form-footer-button-submit"
                      disabled={disabled}
                      loading={isSaving}
                    >
                      {!noteId ? 'Create' : 'Save'}
                    </Button>
                  </Tooltip>
                </div>
              </Form>
            )}
          </Formik>
        </Drawer>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  aircraftList: getOperatingAircraftForDropdown(state),
  end: state.eventElementCreationEdit.end,
  hasCreateNotePermission: hasPermission(state, 'AG-Timeline-Create-Note'),
  height: state.ui.height,
  isSaving: state.eventElementCreationEdit.isSaving,
  noteDetails:
    state.eventElementCreationEdit.id &&
    getNoteByIdMap(state)[state.eventElementCreationEdit.id],
  noteId: state.eventElementCreationEdit.id,
  selectedAircraftId: state.eventElementCreationEdit.aircraftId,
  start: state.eventElementCreationEdit.start,
  visible: state.eventElementCreationEdit.isNoteModalVisible,
  uniqIdentifier: state.eventElementCreationEdit.uniqIdentifier,
  userName: state.user.userProfile?.name,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => ({
  onClose: () => {
    dispatch(userCloseNoteModal());
  },
  onSubmit: (note: NoteTimelineSegmentDto) => {
    dispatch(userSaveNote.started(note));
  },
  onPickDate: ({ type, time }) => {
    dispatch(userChangeDatesInEditDrawer({ type, time }));
  },
});

export const NoteDrawerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEditNoteDrawer);
