import { FC } from 'react';
import { utc } from 'moment';
import { RootState } from '../../../reducers';
import { useSelector } from 'react-redux';
import './styles.scss';

const dateFormatter = (e: moment.Moment): string => {
  if (e) {
    return e.format('DD MMM, HH:mm');
  } else {
    return '';
  }
};

export const TimesTopBar: FC<{}> = () => {
  const now = useSelector<RootState, number>(state => state.time.now);
  return (
    <div className="timelinebar-times">
      <div>{'UTC ' + dateFormatter(utc(now))}</div>
      <div>{'LT ' + dateFormatter(utc(now).local())}</div>
    </div>
  );
};
