import { PureComponent } from 'react';
import * as actions from '../../../../actions';
import { Popover } from 'antd';
import Flight from '../../../../types/flight';
import Airport from '../../../../types/airport';
import { connect, DispatchProp } from 'react-redux';
import { RootState } from '../../../../reducers';
import { ReactFlightTooltipConnected } from '../../../flight-tooltip/flight-overlap-tooltip';
import { ReactContextFlightMenuConnected } from '../../../context-menu/elements/flightMenu';
import { hasPermission } from '../../../../utils/check-permissions';
import { TooltipPlacement } from 'antd/lib/tooltip';
import '../styles.scss';
import {
  flightFerryColorizer,
  accountTypeColorizer,
} from '../../../../common/flight/flight-colorization';
import { getDragPosition } from '../../../../reducers/ui';
import { store as reduxStore } from '../../../../root';
import { Header } from '../header';
import Wrapper from '../wrapper';
import FlightCardContent from './content';
import { Crew } from '../../../../types/crew-roster';
import { isDraggableByOpStatus } from '../../../../common/flight/flight-check-status';
import { getCrewByFlightLegIdMap } from '../../../../utils/crew-roster';
import { AnyAction } from 'redux';

interface PreviewProps {
  isFocusing: boolean;
  isFocused: boolean;
  onFocus: () => void;
  onBlur: () => void;
  element: Flight;
  placement: TooltipPlacement;
}
interface StateProps {
  airportsById: { [id: string]: Airport };
  isDraggable: boolean;
  isHiddenTooltipWrapper: boolean;
  crew: { [flightLegId: number]: Crew[] };
}

class FlightPreviewCardComponent extends PureComponent<
  PreviewProps & StateProps & DispatchProp<AnyAction>
> {
  dragStart = (event: React.DragEvent<Element>): void | boolean => {
    const state = reduxStore.getState();
    const { element: flight, dispatch } = this.props;
    if (event.ctrlKey || !isDraggableByOpStatus(flight.legOperationalStatusId))
      return false;
    const { x, y } = getDragPosition(state.ui, event);
    dispatch(
      actions.userDragFlightsBegin({
        flightId: flight.id,
        draggingPosition: { x, y },
      })
    );
    event.dataTransfer.setData('text', flight.id.toString());
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setDragImage(new Image(10, 10), 0, 0);
  };
  render() {
    const {
      element: flight,
      airportsById,
      isDraggable,
      isFocused,
      isFocusing,
      onFocus,
      onBlur,
      isHiddenTooltipWrapper,
      crew,
      placement,
    } = this.props;

    return (
      <ReactFlightTooltipConnected
        opened={isFocused && isFocusing}
        selectedFlight={flight}
        placement={placement}
        crew={crew}
      >
        <Popover
          trigger="contextMenu"
          mouseLeaveDelay={0}
          {...(isHiddenTooltipWrapper ? { visible: false } : {})}
          content={
            <div style={{ margin: '-10px -16px' }}>
              <ReactContextFlightMenuConnected openedFlight={flight} />
            </div>
          }
        >
          <div>
            <Wrapper
              isFocused={isFocused}
              isFocusing={isFocusing}
              onBlur={onBlur}
              onFocus={onFocus}
              draggable={isDraggable}
              onDragStart={event => this.dragStart(event)}
            >
              <Header
                height={6}
                colors={[
                  flightFerryColorizer(flight.legBusinessTypeId),
                  accountTypeColorizer(flight),
                ]}
              />
              <FlightCardContent flight={flight} airportsById={airportsById} />
            </Wrapper>
          </div>
        </Popover>
      </ReactFlightTooltipConnected>
    );
  }
}
export const FlightPreviewCard = connect<StateProps>(
  (state: RootState, ownProps: PreviewProps) => {
    const { legOperationalStatusId } = ownProps.element;
    return {
      airportsById: state.airports.airportsById,
      crew: getCrewByFlightLegIdMap(state, ''),
      isDraggable:
        hasPermission(state, 'AG-Timeline-Fight-Move') &&
        isDraggableByOpStatus(legOperationalStatusId),
      isHiddenTooltipWrapper: state.ui.isHiddenTooltipWrapper,
    };
  }
)(FlightPreviewCardComponent);
