import { gql } from '@apollo/client';

import { apolloClient } from '../../data-processing/graphql';
import { GetFPBPMQuery } from '../../types/operation-result-types';

const getFP = require('./get-fp-bpm.gql');

export function getFPBPM(legId: number) {
  return apolloClient.query<GetFPBPMQuery>({
    query: gql(getFP),
    variables: {
      legId,
    },
  });
}
