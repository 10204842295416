import { CrewAssignment, Crew } from '../../types/crew-roster';

interface CrewItem {
  crewPlanId: {
    key: number;
  };
  role: {
    crewRoleId: {
      key: number;
    };
    name: string;
    customerFacingName: string;
    rank: number;
    abbreviation: string;
  };
  memberCode: string;
  person: {
    personId: {
      key: number;
    };
    firstName: string;
    lastName: string;
    active: boolean;
  };
}
export interface CrewAssignmentGVModel {
  crewPlanId: {
    key: number;
  };
  flightLegId: {
    key: number;
  };
  aircraftId: {
    key: number;
  };
  scheduledDeparture: number;
  scheduledArrival: number;
  estimatedDeparture: number;
  estimatedArrival: number;
  blockDeparture: number;
  blockArrival: number;
  crewItems: CrewItem[];
}

const transformCrew = (cr: CrewItem): Crew => ({
  crewMemberCode: cr.memberCode,
  role: {
    id: cr.role.crewRoleId.key,
    name: cr.role.name,
    customerFacingName: cr.role.customerFacingName,
    rank: cr.role.rank,
    abbreviation: cr.role.abbreviation,
  },
  person: {
    id: cr.person.personId.key,
    firstName: cr.person.firstName,
    lastName: cr.person.lastName,
  },
});
export const transformToFE = (c: CrewAssignmentGVModel): CrewAssignment => ({
  id: c.flightLegId.key,
  start: Math.min(
    c.scheduledDeparture || Number.MAX_SAFE_INTEGER,
    c.estimatedDeparture || Number.MAX_SAFE_INTEGER,
    c.blockDeparture || Number.MAX_SAFE_INTEGER
  ),
  end: Math.max(
    c.scheduledArrival || Number.MIN_SAFE_INTEGER,
    c.estimatedArrival || Number.MIN_SAFE_INTEGER,
    c.blockArrival || Number.MIN_SAFE_INTEGER
  ),
  aircraftId: c.aircraftId.key,
  flightLegId: c.flightLegId.key,
  team: c.crewItems.map(transformCrew),
});
