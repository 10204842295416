import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { CSSProperties, FC, PureComponent } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { RootState } from '../../../reducers';
import { store as reduxStore } from '../../../root';
import Flight from '../../../types/flight';
import { InputSourceIdType } from '../../../types/input-source';
import Note from '../../../types/note';
import {
  openFlightCenter,
  openOrderWindow,
  openWindowAppEmptyLeg2020,
} from '../../../utils';
import { hasPermission } from '../../../utils/check-permissions';
import './styles.scss';

interface ActionButtonsProps {
  handleClickEdit: () => void;
  hasEditPermission: boolean;
  hasDeletePermission?: boolean;
  onConfirmDelete?: () => void;
  type: ActionButtonsElementType;
  style?: CSSProperties;
  handleClickCloneMxEvent?: () => void;
}

type ActionButtonsElementType =
  | 'note'
  | 'maintenance'
  | 'mel'
  | 'empty-leg'
  | 'one-way';

const ActionButtonsView: FC<ActionButtonsProps> = props => {
  const {
    type,
    style,
    onConfirmDelete,
    handleClickEdit,
    hasDeletePermission,
    hasEditPermission,
    handleClickCloneMxEvent,
  } = props;
  const title = `Do you want to delete this ${
    type === 'maintenance' ? 'Maintenance Event' : 'note'
  }?`;
  return (
    <div style={style ? style : {}}>
      {hasEditPermission && (
        <div className="action-btn" onClick={handleClickEdit} title="Edit">
          <EditOutlined />
        </div>
      )}
      {hasEditPermission && handleClickCloneMxEvent && (
        <div
          className="action-btn"
          onClick={handleClickCloneMxEvent}
          title="Copy"
        >
          <CopyOutlined />
        </div>
      )}
      {hasDeletePermission && (
        <Popconfirm
          title={title}
          okText="Yes"
          cancelText="No"
          onConfirm={onConfirmDelete}
        >
          <div className="action-btn" title="Delete">
            <DeleteOutlined />
          </div>
        </Popconfirm>
      )}
    </div>
  );
};
export class EmptyLegActionButtons extends PureComponent<{
  id: number;
  hasEditPermission: boolean;
}> {
  handleClickEdit = () => {
    openWindowAppEmptyLeg2020({ id: this.props.id });
  };
  render() {
    const { hasEditPermission } = this.props;
    return (
      <ActionButtonsView
        style={{ paddingTop: 3 }}
        handleClickEdit={this.handleClickEdit}
        hasEditPermission={hasEditPermission}
        type="empty-leg"
      />
    );
  }
}

export class OneWayActionButtons extends PureComponent<{
  id: number;
  hasEditPermission: boolean;
}> {
  handleClickEdit = () => {
    reduxStore.dispatch(actions.userClickEditOneWayOffer(this.props.id));
  };
  render() {
    const { hasEditPermission } = this.props;
    return (
      <ActionButtonsView
        style={{ marginLeft: '10px' }}
        handleClickEdit={this.handleClickEdit}
        hasEditPermission={hasEditPermission}
        type="one-way"
      />
    );
  }
}
export class NoteActionButtons extends PureComponent<{
  id: number;
  exactNote: Note;
  hasDeletePermission: boolean;
  hasEditPermission: boolean;
}> {
  handleClickEdit = () => {
    const { id, start, end } = this.props.exactNote;
    reduxStore.dispatch(actions.userClickOpenNote({ id, start, end }));
  };
  confirmDelete = () => {
    reduxStore.dispatch(actions.userDeleteNote.started(this.props.id));
  };
  render() {
    const { hasEditPermission, hasDeletePermission, exactNote } = this.props;
    return (
      <ActionButtonsView
        style={{ paddingTop: 5 }}
        handleClickEdit={this.handleClickEdit}
        onConfirmDelete={this.confirmDelete}
        type="note"
        hasEditPermission={hasEditPermission}
        hasDeletePermission={
          hasDeletePermission &&
          exactNote?.inputSourceId !== InputSourceIdType.SQL
        }
      />
    );
  }
}

export class MaintenanceActionButtons extends PureComponent<{
  id: number;
  hasDeletePermission: boolean;
  hasEditPermission: boolean;
}> {
  handleClickEdit = () => {
    reduxStore.dispatch(actions.userClickEditMxEvent({ id: this.props.id }));
  };
  confirmDelete = () => {
    reduxStore.dispatch(actions.userDeleteMxEvent.started(this.props.id));
  };
  handleClickCloneMxEvent = () => {
    reduxStore.dispatch(
      actions.userClickCloneMxEvent({
        id: this.props.id,
      })
    );
  };
  render() {
    return (
      <ActionButtonsView
        handleClickEdit={this.handleClickEdit}
        type="maintenance"
        onConfirmDelete={this.confirmDelete}
        handleClickCloneMxEvent={this.handleClickCloneMxEvent}
        hasDeletePermission={this.props.hasDeletePermission}
        hasEditPermission={this.props.hasEditPermission}
      />
    );
  }
}

class FlightActionButtons extends PureComponent<{
  flight: Flight;
  hasFCPermission: boolean;
  hasOWPermission: boolean;
  hasDefaultFC2020Permission: boolean;
}> {
  btnStyle: CSSProperties = {
    width: 40,
    height: 22.5,
    background: '#F2F2F2',
    color: 'rgba(0,0,0,.85)',
    cursor: 'pointer',
  };
  handleClickOW = () => {
    openOrderWindow({ id: this.props.flight.flightOrderId });
  };
  handleClickFC = () => {
    openFlightCenter(this.props.flight.id);
  };
  render() {
    const { hasFCPermission, hasOWPermission } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'space-between',
        }}
      >
        {hasOWPermission && (
          <div>
            <Button
              size="small"
              style={this.btnStyle}
              onClick={this.handleClickOW}
            >
              OW
            </Button>
          </div>
        )}
        {hasFCPermission && (
          <div>
            <Button
              size="small"
              style={this.btnStyle}
              onClick={this.handleClickFC}
            >
              FC
            </Button>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  hasFCPermission: hasPermission(state, 'AG-Timeline-Flight-Center-View'),
  hasOWPermission: hasPermission(state, 'AG-Timeline-Order-Window-View'),
  hasDefaultFC2020Permission: hasPermission(
    state,
    'AG-Timeline-FC2020-Default-View'
  ),
});
export const FlightActionButtonsConnected = connect(mapStateToProps)(
  FlightActionButtons
);
