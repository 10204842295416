import { message } from 'antd';
import { ApolloQueryResult } from '@apollo/client';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';

import { userDeletePeakDay, userSavePeakDay } from '../actions';

import {
  DeletePeakDayMutation,
  SavePeakDayMutation,
  SavePeakDayMutationVariables,
} from '../types/operation-result-types';
import { deletePeakDayMutation } from '../services/peak-day-service/delete-peak-day';
import { savePeakDayMutation } from '../services/peak-day-service/save-peak-day';
import { GENERATE_NEW_ID } from '../constants';
import { handleGraphQlErrors } from '../components/error-handling/utils';

function* savePeakDaySaga(action: Action<SavePeakDayMutationVariables['day']>) {
  try {
    const res: ApolloQueryResult<SavePeakDayMutation> = yield call(
      savePeakDayMutation,
      action.payload
    );
    if (res.errors) {
      handleGraphQlErrors(res.errors);
      yield put(
        userSavePeakDay.failed({
          params: action.payload,
          error: res.errors[0],
        })
      );
    }
    if (res.data) {
      yield put(
        userSavePeakDay.done({
          params: action.payload,
          result: res.data,
        })
      );
      message.success(
        `Peak day is ${
          action.payload.id !== GENERATE_NEW_ID ? 'saved' : 'created'
        }`
      );
    }
  } catch (error) {
    yield put(
      userSavePeakDay.failed({
        params: action.payload,
        error,
      })
    );
  }
}

function* deletePeakDaySaga(action: Action<number>) {
  try {
    const res: ApolloQueryResult<DeletePeakDayMutation> = yield call(
      deletePeakDayMutation,
      action.payload
    );
    if (res.errors) {
      handleGraphQlErrors(res.errors);
      yield put(
        userDeletePeakDay.failed({
          params: action.payload,
          error: res.errors[0],
        })
      );
    }
    if (res.data) {
      yield put(
        userDeletePeakDay.done({
          params: action.payload,
          result: res.data,
        })
      );
      message.success('Peak day has been deleted');
    }
  } catch (error) {
    yield put(userDeletePeakDay.failed({ error, params: action.payload }));
  }
}

export function* peakDaySaga() {
  yield all([
    takeLatest(userSavePeakDay.started, savePeakDaySaga),
    takeLatest(userDeletePeakDay.started, deletePeakDaySaga),
  ]);
}
