import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { differenceWith, isEqual, xor } from 'lodash';
import { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HandoverRemark } from '../../../../types/handover-remarks';
import { getDividedFlightRemarks, sortCardsInEditMode } from '../../utils';
import { EditableHandoverCard } from '../card/HandoverCardEdit';
import { RootState } from '../../../../reducers';
import { hasPermission } from '../../../../utils/check-permissions';

interface Props {
  cards: HandoverRemark[];
  editingIds: string[];
  isChangedOrder: boolean;
  newRemarkIds: string[];
  remarks: HandoverRemark[];
  userName: string;
  onSubmit: (
    e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
  setCards: (cards: HandoverRemark[]) => void;
  setEditIds: (ids: string[]) => void;
  setOrderChanged: (flag: boolean) => void;
  setNewRemarkIds: (id: string[]) => void;
}
export const EditableBodyContainer: FC<Props> = ({
  cards,
  editingIds,
  isChangedOrder,
  newRemarkIds,
  remarks,
  userName,
  onSubmit,
  setCards,
  setEditIds,
  setOrderChanged,
  setNewRemarkIds,
}) => {
  const isTailView = useSelector<RootState, boolean>(
    state => !!state.handoverRemarks.aircraftId
  );
  const pushedIds = useSelector<RootState, string[]>(
    state => state.handoverRemarks.pushedIds,
    isEqual
  );
  const [opsCards, setOpsCards] = useState<HandoverRemark[]>([]);
  const [csCards, setCsCards] = useState<HandoverRemark[]>([]);
  // conflict resolution
  const [updatedIds, setUpdatedIds] = useState<string[]>([]);
  useEffect(() => {
    const updatedRemarks = differenceWith(cards, remarks, isEqual);
    const toResolveIds = updatedRemarks.reduce((acc, card) => {
      if (
        editingIds.includes(card.id) &&
        remarks.find(c => c.id === card.id) &&
        pushedIds.includes(card.id)
      ) {
        return acc.concat(card.id);
      }
      return acc;
    }, []);
    if (toResolveIds.length > 0) {
      setUpdatedIds(toResolveIds);
    }
  }, [remarks]);
  const onCancelUpdate = (id: string) =>
    setUpdatedIds(c => c.filter(b => b !== id));
  const onUpdateConfirm = (id: string) => {
    setUpdatedIds(c => c.filter(b => b !== id));
    const updated = remarks.find(r => r.id === id);
    setCards(
      cards.reduce((acc, c) => {
        if (c.id === id) {
          return acc.concat(updated);
        }
        return acc.concat(c);
      }, [])
    );
  };

  // update on load
  useEffect(() => {
    const notSavedCards = editingIds
      .map(id => cards.find(c => c.id === id))
      .filter(c => !!c);
    notSavedCards.reverse();

    const loadedNotes = remarks.filter(r => !editingIds.includes(r.id));
    const newCards =
      editingIds.length > 0 || isChangedOrder
        ? sortCardsInEditMode(
            [...notSavedCards, ...loadedNotes],
            cards.map(c => c.id)
          )
        : [...notSavedCards, ...loadedNotes];
    setCards(newCards);
  }, [remarks]);

  const onSetEditIds = (id: string, keepShowing: boolean) => {
    setEditIds(xor(editingIds, [id]));
    const originRemark = remarks.find(r => r.id === id);
    if (!originRemark) {
      // keep on save new card and remove on cancel edit new card
      setCards(keepShowing ? cards : cards.filter(card => card.id !== id));
    } else {
      setCards(
        cards.reduce((acc, c) => {
          // recover original text on cancel edit
          if (c.id === originRemark.id) {
            return acc.concat(originRemark);
          }
          return acc.concat(c);
        }, [])
      );
    }
  };
  const onSetCard = (card: HandoverRemark) => {
    setCards(
      cards.reduce((acc, v) => {
        if (v.id === card.id) {
          return acc.concat(card);
        }
        return acc.concat(v);
      }, [])
    );
  };

  const sensors = useSensors(useSensor(PointerSensor));
  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (active && over && active.id !== over?.id) {
        const oldIndex = cards.map(it => it.id).indexOf(active.id as string);
        const newIndex = cards.map(it => it.id).indexOf(over.id as string);
        const newArray = arrayMove(cards, oldIndex, newIndex);
        setCards(
          newArray.map((note, index) => ({
            ...note,
            order: index + 1,
          }))
        );
        setOrderChanged(true);
      }
    },
    [cards]
  );

  useEffect(() => {
    const { csCards, opsCards } = getDividedFlightRemarks(cards, newRemarkIds);
    setOpsCards(opsCards);
    setCsCards(csCards);
  }, [cards]);
  const hasOpsEditPermission = useSelector<RootState, boolean>(state =>
    hasPermission(state, 'AG-Timeline-Handover-Ops_Edit')
  );
  const hasCsEditPermission = useSelector<RootState, boolean>(state =>
    hasPermission(state, 'AG-Timeline-Handover-CS_Edit')
  );
  const getCard = (
    card: HandoverRemark,
    setEditIds?: (id: string, keepShowing: boolean) => void
  ) => {
    return (
      <div key={card.id} className="remarks-card">
        <EditableHandoverCard
          card={card}
          editAllowed={typeof setEditIds !== 'undefined'}
          editingIds={editingIds}
          newRemarkIds={newRemarkIds}
          onCancelUpdate={onCancelUpdate}
          onUpdateConfirm={onUpdateConfirm}
          setCard={onSetCard}
          setEditIds={onSetEditIds}
          setNewRemarkIds={setNewRemarkIds}
          updatedIds={updatedIds}
          userName={userName}
        />
      </div>
    );
  };
  const getComponent = (draggable: boolean, items: HandoverRemark[]) => {
    return draggable ? (
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={onDragEnd}
        modifiers={[restrictToVerticalAxis]}
      >
        <SortableContext strategy={verticalListSortingStrategy} items={items}>
          {items.map(card => getCard(card, onSetEditIds))}
        </SortableContext>
      </DndContext>
    ) : (
      items.map(card => getCard(card))
    );
  };
  return (
    <form id="handoverRemarks" onSubmit={onSubmit}>
      {isTailView ? (
        getComponent(true, cards)
      ) : (
        <>
          {getComponent(hasOpsEditPermission, opsCards)}
          {getComponent(hasCsEditPermission, csCards)}
        </>
      )}
    </form>
  );
};
