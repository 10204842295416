import { CSSProperties, PureComponent } from 'react';
import { gvUrl } from '../services/gv-service';
import { RootState } from '../reducers';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { Draggable } from './draggable/draggable';
import { AnyAction, Dispatch } from 'redux';

interface StateProps {
  isOpen: boolean;
  width: number;
  height: number;
  view: string;
  iframePosition: { x: number; y: number };
  iframeOffset: { x: number; y: number };
  isDraggingIframe: boolean;
}
interface DispatchProps {
  closeIframe: () => void;
  moveIframe: (x: number, y: number) => void;
  moveIframeStart: (
    mousePos: { x: number; y: number },
    nodeOffset: { x: number; y: number }
  ) => void;
  moveIframeEnd: (x: number, y: number) => void;
  resetPosition: () => void;
}

class IframeWrapper extends PureComponent<StateProps & DispatchProps> {
  ref: HTMLIFrameElement;
  getIframeRef = ref => (this.ref = ref);
  backdropStyle: CSSProperties = {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 300,
    background: 'rgba(0,0,0,0.2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  onBackdropClick = () => {
    !this.props.isDraggingIframe && this.props.closeIframe();
  };
  onGVWindowClose = (evt: MessageEvent) => {
    if (
      evt.data === 'Window.closed' &&
      this.ref &&
      evt.source == this.ref.contentWindow
    )
      this.props.closeIframe();
  };
  componentDidMount() {
    window.addEventListener('message', this.onGVWindowClose);
  }
  componentWillUnmount() {
    window.removeEventListener('message', this.onGVWindowClose);
  }
  render() {
    if (!this.props.isOpen && !this.ref) return null;
    return (
      <Draggable
        areaStyle={
          this.props.isOpen
            ? this.backdropStyle
            : {
                position: 'fixed',
                top: `${-this.props.height - 1000}px`,
              }
        }
        itemStyle={{
          boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.5)',
          background: 'white',
          zIndex: 500,
        }}
        onClick={this.onBackdropClick}
        position={this.props.iframePosition}
        offset={this.props.iframeOffset}
        width={this.props.width}
        height={this.props.height}
        isDragging={this.props.isDraggingIframe}
        onDragStart={this.props.moveIframeStart}
        onDrag={this.props.moveIframe}
        onDragEnd={this.props.moveIframeEnd}
      >
        <iframe
          width={this.props.width}
          height={this.props.height}
          src={`${gvUrl}#view=${this.props.view}`}
          ref={this.getIframeRef}
        />
      </Draggable>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => ({
  closeIframe: () => dispatch(actions.userCloseIframe()),
  moveIframe: (x: number, y: number) =>
    dispatch(actions.userMoveIframe([x, y])),
  moveIframeStart: (
    mousePos: { x: number; y: number },
    nodeOffset: { x: number; y: number }
  ) => dispatch(actions.userMoveIframeStart({ mousePos, nodeOffset })),
  moveIframeEnd: (x: number, y: number) =>
    dispatch(actions.userMoveIframeEnd([x, y])),
  resetPosition: () => dispatch(actions.userResetIframePosition()),
});

export const ConnectedEditOneWayIframeWrapper = connect(
  (state: RootState): StateProps => ({
    isDraggingIframe: state.iframeUi.isDragging,
    iframePosition: state.iframeUi.iframeUi.editOneWayOfferIframe.position,
    iframeOffset: state.iframeUi.iframeUi.editOneWayOfferIframe.offset,
    isOpen: state.iframeUi.iframeUi.editOneWayOfferIframe.isOpen,
    width: 1085,
    height: 550,
    view: `OpenOneWayWindow&onewayid=${state.oneWayOfferController.id}`,
  }),
  mapDispatchToProps
)(IframeWrapper);
