import { gql } from '@apollo/client';
import { apolloClient } from '../data-processing/graphql';
import {
  CreateNoteMutation,
  CreateNoteMutationVariables,
} from '../types/operation-result-types';
import NoteTimelineSegmentDto from '../types/NoteTimelineSegmentDto';

const createNote = require('./create-note.gql');

export function createNoteMutation(dto: NoteTimelineSegmentDto) {
  const variables: CreateNoteMutationVariables = {
    dto,
  };
  return apolloClient.mutate<CreateNoteMutation>({
    mutation: gql(createNote),
    variables,
  });
}
