import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as actions from '../actions';
import { MailTemplate } from '../types/email';

export interface EmailState {
  aircraftId: number;
  emailAddressOptions: string[];
  eMailTemplate: MailTemplate | null;
  filterEmailAddressOptions: string;
  isArincEmailVisible: boolean;
  isHandoverEmailModalVisible: boolean;
  isMxEmailModalVisible: boolean;
  isLoadingEmailTemplate: boolean;
  sending: boolean;
}
export const initialState: EmailState = {
  aircraftId: null,
  emailAddressOptions: [],
  eMailTemplate: null,
  filterEmailAddressOptions: '',
  isArincEmailVisible: false,
  isHandoverEmailModalVisible: false,
  isMxEmailModalVisible: false,
  isLoadingEmailTemplate: true,
  sending: false,
};

export default reducerWithInitialState(initialState)
  .case(actions.doFetchEmailAddressOptions.started, (state, payload) => ({
    ...state,
    filterEmailAddressOptions: payload,
  }))
  .case(actions.doFetchEmailAddressOptions.done, (state, payload) => ({
    ...state,
    emailAddressOptions: payload.result,
  }))
  .case(actions.userResetEmailAddressOptions, state => ({
    ...state,
    emailAddressOptions: [],
    filterEmailAddressOptions: '',
  }))
  .case(actions.userClickToOpenArincEmail, (state, payload) => ({
    ...state,
    aircraftId: payload,
    isArincEmailVisible: true,
  }))
  .case(actions.userOpenMxEmailTemplate, (state, payload) => ({
    ...state,
    isMxEmailModalVisible: true,
  }))
  .case(actions.doFetchEmailTemplate.started, state => ({
    ...state,
    isLoadingEmailTemplate: true,
  }))
  .case(actions.doFetchEmailTemplate.failed, state => ({
    ...state,
    isLoadingEmailTemplate: false,
  }))
  .case(actions.doFetchEmailTemplate.done, (state, payload) => ({
    ...state,
    eMailTemplate: payload.result,
    isLoadingEmailTemplate: false,
  }))
  .case(actions.userOpenHandoverEmailModal, state => ({
    ...state,
    isHandoverEmailModalVisible: true,
  }))
  .cases(
    [
      actions.userClickToSendArincEmail.started,
      actions.userSendMxEmail.started,
      actions.userSendHandoverEmail.started,
    ],
    state => ({
      ...state,
      sending: true,
    })
  )
  .cases(
    [
      actions.userClickToSendArincEmail.failed,
      actions.userSendMxEmail.failed,
      actions.userSendHandoverEmail.failed,
    ],
    state => ({
      ...state,
      sending: false,
    })
  )
  .cases(
    [
      actions.userCancelEmail,
      actions.userClickToSendArincEmail.done,
      actions.userSendMxEmail.done,
      actions.userSendHandoverEmail.done,
    ],
    () => ({
      ...initialState,
    })
  );
