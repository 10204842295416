import { store as reduxStore } from '../../../../root';
import { ExtendedEventElement } from '..';
import EventElement from '../../../../types/event-element';
import { getAircraftIndexMapExcludingHolding } from '../../../../selectors';
import Note, { MergedNote } from '../../../../types/note';
import { MergedMEL } from '../../../../types/maintenance-item';

const rectWidthCalculator = (d: ExtendedEventElement) => {
  const { transform } = reduxStore.getState().ui;
  const width = transform.scaleX(d.end) - transform.scaleX(d.start);
  return Math.max(20, width);
};

const rectTopCalculator = (d: EventElement) => {
  const state = reduxStore.getState();
  const { ui } = state;
  return (
    getAircraftIndexMapExcludingHolding(state)[d.aircraftId] *
      ui.rowHeight *
      ui.transform.ky +
    ui.transform.translateY
  );
};
export const buildPropsForComponent = (d: EventElement) => {
  const state = reduxStore.getState();
  const ui = state.ui;
  const { transform } = ui;
  const newD = Object.create(d) as ExtendedEventElement;
  newD.x = transform.scaleX(d.start);
  newD.airportsDataLoaded = state.airports.airportsLoadingComplete;
  newD.width = rectWidthCalculator(newD);
  newD.ky = transform.ky;
  newD.rh = ui.rowHeight;
  newD.y = rectTopCalculator(newD);
  return newD;
};

export const getNoteFromMergedNotesWidth = (
  note: Note,
  nextNote: Note
): number => {
  const noteWithProps = buildPropsForComponent(note);
  if (nextNote) {
    const nextNoteWithProps = buildPropsForComponent(nextNote);
    const width =
      noteWithProps.x < 0
        ? nextNoteWithProps.x
        : nextNoteWithProps.x - noteWithProps.x;
    return Math.max(width, 0);
  }
  return noteWithProps.width;
};

export const getLeftMarginForMergedElement = (
  parentElement: ExtendedEventElement & (MergedNote | MergedMEL),
  currentElement: ExtendedEventElement
): number => {
  if (
    currentElement.x < 0 &&
    parentElement.x - currentElement.x > currentElement.width
  ) {
    return 0;
  }
  if (currentElement.x < 0) {
    return Math.max(-parentElement.x, 0);
  }
  if (parentElement.x < 0) {
    return Math.max(currentElement.x - parentElement.x, currentElement.x);
  }
  return Math.max(currentElement.x - parentElement.x, 0);
};
