import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { AutoComplete, Tooltip } from 'antd';
import { FieldProps, FormikProps } from 'formik';
import { debounce } from 'lodash';
import { FC, useMemo, useState } from 'react';
import { Aircraft, MxEventFormValues, NoteFormValues } from '../interfaces';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { getOperatingAircraftForDropdown } from '../../../selectors';

export const AircraftAutocomplete: FC<Pick<
  FormikProps<MxEventFormValues | NoteFormValues>,
  'errors' | 'handleChange' | 'touched' | 'setFieldValue'
> &
  FieldProps<
    MxEventFormValues['aircraftName'] | NoteFormValues['aircraftName']
  >['field'] & {
    disabled: boolean;
  }> = props => {
  const {
    setFieldValue,
    handleChange,
    errors,
    touched,
    value,
    disabled,
  } = props;
  const fullAircraftList = useSelector<RootState, Aircraft[]>(
    getOperatingAircraftForDropdown
  );

  const [aircraftListFiltered, setFilteredList] = useState<Aircraft[]>([]);
  const [isNotFound, setIsNotFound] = useState(false);

  const onHandleChange = debounce((value: string) => {
    if (!value) {
      setFieldValue('aircraftId', null);
      setFieldValue('aircraftName', '');
      setFilteredList(fullAircraftList);
    } else {
      const filtered = value
        ? fullAircraftList.filter((el: Aircraft) =>
            el.tailNumber
              .toLocaleLowerCase()
              .includes(value.toLowerCase().trim())
          )
        : [];
      setFilteredList(filtered);
      const isNotFound = value && value.length > 0 && !filtered.length;
      setIsNotFound(isNotFound);
      handleChange(value || '');
    }
  }, 50);

  const onSelect = (value: string) => {
    const aircraft = aircraftListFiltered.find(el => el.id === parseInt(value));
    handleChange(value);
    setFieldValue('aircraftId', aircraft.id);
    setFieldValue('aircraftName', aircraft.tailNumber);
    setFilteredList([]);
  };
  const options = aircraftListFiltered.map(aircraft => ({
    label: `${aircraft.tailNumber}`,
    value: `${aircraft.id}`,
  }));
  const hasError = errors.aircraftName && touched.aircraftName;
  return (
    <div className="edit-event-form-body-auto-complete">
      <Tooltip
        placement="bottomRight"
        title={
          <div>
            <ExclamationCircleTwoTone twoToneColor="gold" />
            {` No active tail found for the defined period`}
          </div>
        }
        open={isNotFound}
      >
        <AutoComplete
          allowClear
          className="edit-event-form-body-auto-complete-aircraft"
          defaultValue={value}
          disabled={disabled}
          onChange={onHandleChange}
          onSelect={onSelect}
          options={options}
          placeholder="Select tail"
          popupClassName="edit-event-form-body-auto-complete-dropdown"
          status={hasError ? 'error' : ''}
        />
        {hasError ? (
          <div className="edit-event-form-body-error-message">
            {errors.aircraftName}
          </div>
        ) : null}
      </Tooltip>
    </div>
  );
};
