import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { FC } from 'react';

export const CheckedIconComponent: FC<{ checked: boolean }> = ({ checked }) => {
  return checked ? (
    <CheckOutlined className="event-list-table-check-icon" />
  ) : (
    <CloseOutlined className="event-list-table-close-icon" />
  );
};
