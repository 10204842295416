import { PureComponent } from 'react';
import PeakDay from '../../../../types/peak-day';
import { Checkbox } from 'antd';
import TooltipCreationLogTime from '../../creation-log-time';
import TooltipDates from '../../dates';
import TooltipHeader from '../../header';
import { LINE_HEIGHT } from '../../../../constants';
import { setDataTestEntityId } from '../../../../utils';
import {
  getPeakDayText,
  getPeakDayTooltipColor,
  getRegion,
} from '../../../../utils/peak-days';

interface OwnProps {
  segmentData: PeakDay;
}

export default class TooltipPeakDay extends PureComponent<OwnProps> {
  render() {
    const { segmentData } = this.props;
    const {
      id,
      updatedBy,
      lastUpdated,
      text,
      isAutoAdvertising,
      start,
      end,
      isCrewChange,
      peakDayRegions,
      peakDayTypes,
    } = segmentData;
    return (
      <>
        <TooltipHeader
          id={id}
          color="rgba(0,0,0,0.85)"
          backgroundImage={getPeakDayTooltipColor(peakDayTypes)}
          name="Peak day"
        />
        <div className="tooltip-common">
          <TooltipCreationLogTime
            updatedBy={updatedBy}
            lastUpdated={lastUpdated}
            isUpdated={true}
          />
          <div
            className="tooltip-common-change-checkbox-crew"
            {...setDataTestEntityId('tooltip-peak-day-change-wrapper-checkbox')}
          >
            <Checkbox
              checked={isCrewChange}
              disabled
              {...setDataTestEntityId('tooltip-peak-day-change-checkbox-crew')}
            >
              Crew change
            </Checkbox>
          </div>
          <div className="tooltip-common-availability-for-advertisement">
            <strong>{`${
              isAutoAdvertising ? 'Available ' : 'Unavailable'
            } for auto advertisement`}</strong>
          </div>
          <div className="tooltip-common-region">
            {getRegion(peakDayRegions)}
          </div>
          <div className="tooltip-common-type">
            {getPeakDayText(peakDayTypes)}
          </div>
          <div
            className="tooltip-common-description"
            {...setDataTestEntityId('tooltip-peak-day-description')}
            style={{
              overflow: 'hidden',
              maxHeight: `${LINE_HEIGHT * 2.5}px`,
            }}
          >
            <div
              style={{
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                maxHeight: `${LINE_HEIGHT * 2.5}px`,
              }}
            >
              {text}
            </div>
          </div>
          <TooltipDates start={start} end={end} />
        </div>
      </>
    );
  }
}
