import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as actions from '../actions';
import Timezone from '../types/timezone';

export interface TimezonesState {
  timezonesById: { [id: number]: Timezone };
  loading: boolean;
}
export const initialState: TimezonesState = {
  timezonesById: null,
  loading: false,
};

export default reducerWithInitialState(initialState)
  .case(actions.doTimezonesMappersFetch.started, state => ({
    ...state,
    loading: true,
  }))
  .case(actions.doTimezonesMappersFetch.done, (state, payload) => ({
    ...state,
    loading: false,
    timezonesById: payload.result.reduce((acc, currentTimezone) => {
      acc[currentTimezone.id] = currentTimezone;
      return acc;
    }, {}),
  }))
  .case(actions.doTimezonesMappersFetch.failed, state => ({
    ...state,
    loading: false,
  }))
  .case(actions.wsUpdateBatch, (state, payload) => {
    if (!payload.data.timezones) return state;
    return {
      ...state,
      timezonesById: { ...state.timezonesById, ...payload.data.timezones },
    };
  });
