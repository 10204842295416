import {
  Crew,
  CrewAssignment,
  CrewDuty,
  CrewRoster,
} from '../types/crew-roster';
import Flight from '../types/flight';
import { chain } from 'lodash';
import { DAY_IN_MILLISECONDS } from '../constants';
import createCachedSelector from 're-reselect';
import { RootState } from '../reducers';

export function isCrew(
  el: CrewAssignment | CrewRoster | Flight
): el is CrewAssignment | CrewRoster {
  return !!(el as CrewAssignment | CrewRoster).team;
}

export function isCrewAssignment(
  el: CrewAssignment | CrewRoster
): el is CrewAssignment {
  return !!(el as CrewAssignment).flightLegId;
}

export const rosterReducer = (acc: CrewRoster[], d: CrewDuty): CrewRoster[] => {
  if (acc.length === 0)
    return [
      {
        aircraftId: d.aircraftId,
        start: d.dutyDate,
        end: d.dutyDate + DAY_IN_MILLISECONDS,
        id: d.id,
        team: [d],
      },
    ];
  const lastRoster = acc[acc.length - 1];
  if (lastRoster.start === d.dutyDate) {
    lastRoster.team.push(d);
    return acc;
  }
  acc.push({
    aircraftId: d.aircraftId,
    start: d.dutyDate,
    end: d.dutyDate + DAY_IN_MILLISECONDS,
    id: d.id,
    team: [d],
  });
  return acc;
};

export const getRosterFromCrewArray = (duties: CrewDuty[]): CrewRoster[] => {
  return chain(duties)
    .sortBy('dutyDate')
    .groupBy('aircraftId')
    .flatMap(crewPerAircraft => crewPerAircraft.reduce(rosterReducer, []))
    .value();
};

export const getCrewByFlightLegIdMap = createCachedSelector(
  (state: RootState, key) => state.timelineEvents.crewAssignment,
  (crew: CrewAssignment[]): { [flightLegId: number]: Crew[] } => {
    return crew.reduce((acc, crew) => {
      acc[crew.flightLegId] = crew.team;
      return acc;
    }, {});
  }
)((state, key) => key);

export const getFlightCrewDetailsSorted = (
  crew: Crew[] = []
): {
  roleAbbr: string;
  crewCode: string;
  firstName: string;
  lastName: string;
}[] => {
  return chain(crew)
    .sortBy(c => c.role.rank)
    .map(c => ({
      roleAbbr: c.role.abbreviation,
      crewCode: c.crewMemberCode,
      firstName: c.person.firstName,
      lastName: c.person.lastName,
    }))
    .value();
};
