import EventElement from './event-element';

export enum MaintenanceItemType {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  NA = 'N/A',
}
export default interface MaintenanceItem extends EventElement {
  createdDate: number;
  clearedTimestamp: number;
  description: string[];
  opDescription?: string[];
  dueDate: number;
  maintenanceItemRef: string;
  maintenanceItemType: string;
  updatedDate: number;
  isActive: boolean;
  isDiscreet: boolean;
  hoursInMinutes: number;
  cycles: number;
  isAogDriver: boolean;
}
export interface MergedMEL extends EventElement {
  maintenanceItems: MaintenanceItem[];
}

export const maintenanceItemColor = 'rgba(64, 169, 255, 1)';
export const maintenanceItemBorderWidth = 3;
export const maintenanceItemBorderStyle = `${maintenanceItemBorderWidth}px solid ${maintenanceItemColor}`;
