import { store as reduxStore } from '../../../../../root';
import * as d3S from 'd3-selection';
import * as actions from '../../../../../actions';
import Maintenance, { MaintenanceType } from '../../../../../types/maintenance';
import {
  getBoundingElement,
  getEuclideanDistance,
  getNoteBoundingElement,
  setDataTestEntityIdD3Elements,
} from '../../../../../utils';
import EventElement from '../../../../../types/event-element';
import { getICAOLabelFromAirport } from '../../../../../utils/airport';
import {
  getElementOffsetWithKoef,
  getLaneHeightKoef,
} from '../../../../../reducers/ui';
import { hasPermission } from '../../../../../utils/check-permissions';
import {
  CENTRED_DOT_SYMBOL,
  MIN_RANGE_SELECTION,
} from '../../../../../constants';
import { getMaintenanceTextBackgroundColor } from '../../../../../common/maintenance/maintenance-colorization';
import { getMaintenanceTypeLabel } from '../../../../../common/maintenance/maintenance-labels';

export const getMaintenancesD3creator = (
  d: d3S.Selection<any, any, any, any>
): d3S.Selection<any, any, any, any> => {
  const state = reduxStore.getState();
  const hasEditPermission = hasPermission(state, 'AG-Timeline-View-MX-Event');
  const { transform, segmentsVisibility, positionMap } = state.ui;
  const { airportsById } = state.airports;
  const { togglersState } = state.aircraft;
  const container = d
    .append('div')
    .classed('maintenance-text-label', true)
    .attr(
      'data-test-entity',
      setDataTestEntityIdD3Elements('maintenance-text-label')
    )
    .style(
      'transform',
      d =>
        `translate(${d.x}px, ${d.y +
          getElementOffsetWithKoef(
            segmentsVisibility,
            'maintenances',
            togglersState[d.aircraftId],
            positionMap
          ) *
            d.ky}px)`
    )
    .classed('label-text', true)
    .classed('wide', d => d.width > 80)
    .style(
      'height',
      d =>
        `${(positionMap.maintenances * d.ky) /
          getLaneHeightKoef(
            segmentsVisibility,
            togglersState[d.aircraftId],
            positionMap
          )}px`
    )
    .style('width', d => `${d.width}px`)
    .style('color', 'black')
    .style('padding-left', d => `${Math.max(-d.x, 0)}px`)
    .style(
      'padding-right',
      d => `${Math.max(d.x + d.width - transform.scaleX.range()[1], 0)}px`
    )
    .on('mouseover', function(d) {
      d3S.select(this).style('cursor', 'pointer');
      reduxStore.dispatch(
        actions.userHoverSegment([d, getBoundingElement(this), 'maintenances'])
      );
    })
    .on('mouseout', function(d) {
      reduxStore.dispatch(actions.userCloseTooltipSegment());
    })
    .on('contextmenu', function(d: Maintenance & EventElement) {
      d3S.event.preventDefault();
      d3S.event.stopPropagation();
      reduxStore.dispatch(
        actions.userOpenContextMenuForMxEvent([getNoteBoundingElement(this), d])
      );
    })
    .on(
      'click',
      (d: Maintenance & EventElement) =>
        hasEditPermission &&
        getEuclideanDistance(reduxStore.getState().ui.timelineSelection) <
          MIN_RANGE_SELECTION &&
        reduxStore.dispatch(actions.userClickEditMxEvent({ id: d.id }))
    );
  const labelInfo = container;
  labelInfo
    .append('span')
    .classed('text-field-horrizontal-align', true)
    .append('span')
    .classed('text-field-vertical-align', true)
    .append('span')
    .style('background-color', getMaintenanceTextBackgroundColor)
    .style('border-radius', '1px')
    .style('padding', d =>
      d.isDispatchable && d.maintenanceType === MaintenanceType.AOG
        ? '2px  2px 0px 2px'
        : '2px'
    )
    .text((data: Maintenance) =>
      data.airportId
        ? `${getICAOLabelFromAirport(
            airportsById[data.airportId]
          )} ${CENTRED_DOT_SYMBOL} ${getMaintenanceTypeLabel(data)}`
        : `${getMaintenanceTypeLabel(data)}`
    );

  return container;
};
