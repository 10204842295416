import { FC, FormEvent, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import {
  AllRemarksShape,
  HandoverRemark,
} from '../../../../types/handover-remarks';
import { Spin } from 'antd';
import { EditableBodyContainer } from './EditableBodyContainer';
import { MemoizedViewBodyContainer } from './ViewBodyContainer';
import { DividedBody } from './DividedBody';
import { getDrawerBodyHeight } from '../../utils';
import { RootState } from '../../../../reducers';
import { getFilteredAndSortedAllRemarks } from '../../../../selectors/handover-remarks';

interface Props {
  cards: HandoverRemark[];
  editingIds: string[];
  isAll: boolean;
  isChangedOrder: boolean;
  isShowingRelated: boolean;
  loadingAll: boolean;
  loadingCurrentRemarks: boolean;
  newRemarkIds: string[];
  relatedRemarks: AllRemarksShape['remarks'][];
  currentRemarks: HandoverRemark[];
  tailNotesAreSticked: boolean;
  userName: string;
  showRangeFilter: boolean;
  setNeedToAddRemark?: (flag: boolean) => void;
  onSubmit: (
    e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
  reset: () => void;
  setCards: (cards: HandoverRemark[]) => void;
  setEditIds: (ids: string[]) => void;
  setOrderChanged: (flag: boolean) => void;
  setNewRemarkIds: (id: string[]) => void;
}
export const HandoverBody: FC<Props> = ({
  cards,
  currentRemarks,
  editingIds,
  isAll,
  isChangedOrder,
  isShowingRelated,
  loadingAll,
  loadingCurrentRemarks,
  newRemarkIds,
  showRangeFilter,
  setNeedToAddRemark,
  onSubmit,
  relatedRemarks,
  reset,
  tailNotesAreSticked,
  userName,
  setCards,
  setEditIds,
  setOrderChanged,
  setNewRemarkIds,
}) => {
  const allRemarks = useSelector<RootState, AllRemarksShape['remarks'][]>(
    state => getFilteredAndSortedAllRemarks(state).map(a => a.remarks),
    isEqual
  );
  const [bodyHeight, setHeight] = useState(0);
  useEffect(() => {
    const newHeight = getDrawerBodyHeight(showRangeFilter, isAll);
    setHeight(newHeight);
  }, [showRangeFilter]);

  const divided = isAll ? false : isShowingRelated;
  const hasUnsavedRemarks = editingIds.length > 0;
  const editable = (
    <>
      <Spin spinning={loadingCurrentRemarks} className="handover-loading" />
      <EditableBodyContainer
        cards={cards}
        editingIds={editingIds}
        isChangedOrder={isChangedOrder}
        newRemarkIds={newRemarkIds}
        onSubmit={onSubmit}
        remarks={currentRemarks}
        setCards={setCards}
        setEditIds={setEditIds}
        setNewRemarkIds={setNewRemarkIds}
        setOrderChanged={setOrderChanged}
        userName={userName}
      />
    </>
  );
  if (divided) {
    return (
      <DividedBody
        bodyHeight={bodyHeight}
        topChildren={editable}
        bottomChildren={
          <MemoizedViewBodyContainer
            hasUnsavedRemarks={hasUnsavedRemarks}
            isChangedOrder={isChangedOrder}
            remarks={relatedRemarks}
            tailNotesAreSticked={false}
            setNeedToAddRemark={setNeedToAddRemark}
            reset={reset}
            showDivider={false}
          />
        }
      />
    );
  }
  return (
    <div style={{ height: bodyHeight }} className="remarks-body">
      <Spin spinning={loadingAll} className="handover-loading" />
      {isAll ? (
        <MemoizedViewBodyContainer
          hasUnsavedRemarks={false}
          remarks={allRemarks}
          tailNotesAreSticked={tailNotesAreSticked}
          showDivider
        />
      ) : (
        editable
      )}
    </div>
  );
};
