import { PureComponent } from 'react';
import { utc } from 'moment';
import { RootState } from '../../reducers';
import * as actions from '../../actions';
import { debounce } from 'lodash';
import { connect, DispatchProp } from 'react-redux';
import { subMiddle, store as reduxStore } from '../../root';

import './styles.scss';
import { AnyAction } from 'redux';

interface StateProps {
  timelineWidth: number;
  left: number;
}
export class HorizontalScrollBar extends PureComponent<
  StateProps & DispatchProp<AnyAction>
> {
  refScrollbar: HTMLDivElement;
  refScrollbarLine: HTMLDivElement;
  ignoreScroll: boolean;
  ignoreZoomHorizontal: boolean;
  dates: {
    dateStart: number;
    dateEnd: number;
  };
  debouncedPushLeft: () => void;
  debouncedPushRight: () => void;
  constructor(props) {
    super(props);
    this.debouncedPushLeft = debounce(() => {
      this.pushStart();
      const { scaleX } = reduxStore.getState().ui.transform;
      this.refScrollbar.scrollLeft = -scaleX(this.dates.dateStart);
      this.refScrollbarLine.style.width = `${scaleX(this.dates.dateEnd) -
        scaleX(this.dates.dateStart)}px`;
    }, 800);
    this.debouncedPushRight = debounce(() => {
      const { scaleX } = reduxStore.getState().ui.transform;
      this.pushEnd();
      this.refScrollbarLine.style.width = `${scaleX(this.dates.dateEnd) -
        scaleX(this.dates.dateStart)}px`;
    }, 800);
    this.dates = {
      dateStart: utc()
        .subtract(3, 'w')
        .valueOf(),
      dateEnd: utc()
        .add(9, 'w')
        .valueOf(),
    };
  }
  pushStart = () => {
    const { dateStart } = this.dates;
    this.dates.dateStart = utc(dateStart)
      .subtract(3, 'w')
      .valueOf();
  };
  pushEnd = () => {
    const { dateEnd } = this.dates;
    this.dates.dateEnd = utc(dateEnd)
      .add(3, 'w')
      .valueOf();
  };

  onUserZoomHorizontal = (_action, state: RootState) => {
    if (this.ignoreZoomHorizontal) return (this.ignoreZoomHorizontal = false);
    const currentWidth = state.ui.width - state.ui.planeBlockWidth;
    if (
      this.refScrollbar.style.width === '' ||
      this.props.timelineWidth !== currentWidth
    )
      this.refScrollbar.style.width = `${currentWidth}px`;
    const { scaleX } = state.ui.transform;
    if (scaleX(this.dates.dateStart) >= 0) {
      this.pushStart();
    } else if (scaleX(this.dates.dateEnd) <= state.ui.width) {
      this.pushEnd();
    }
    const start = scaleX(this.dates.dateStart);
    const end = scaleX(this.dates.dateEnd);
    this.ignoreScroll = true;
    this.refScrollbarLine.style.width = `${end - start}px`;
    this.refScrollbar.scrollLeft = -start;
  };

  onScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (this.ignoreScroll) {
      this.ignoreScroll = false;
      return true;
    }
    const { scrollLeft } = event.currentTarget;
    const { scaleX } = reduxStore.getState().ui.transform;
    const coordOfDateStart = scaleX(this.dates.dateStart);
    if (scrollLeft == 0) {
      this.debouncedPushLeft();
    } else if (
      this.refScrollbar.scrollLeft + this.refScrollbar.clientWidth >=
      this.refScrollbarLine.scrollWidth
    ) {
      this.debouncedPushRight();
    }
    const dateOfToScroll = scaleX.invert(coordOfDateStart + scrollLeft);
    this.ignoreZoomHorizontal = true;
    this.props.dispatch(
      actions.userHorizontalScrollingTimeline({
        scrollAmount: scrollLeft,
        date: dateOfToScroll.valueOf(),
      })
    );
  };

  catchRefScrollbar = (ref: HTMLDivElement) => {
    this.refScrollbar = ref;
  };

  catchRefScrollbarLine = (ref: HTMLDivElement) => {
    this.refScrollbarLine = ref;
  };

  componentDidMount() {
    subMiddle.on(actions.userZoomHor, this.onUserZoomHorizontal);
  }

  componentWillUnmount() {
    subMiddle.off(actions.userZoomHor, this.onUserZoomHorizontal);
  }

  render() {
    const { left } = this.props;
    return (
      <div
        style={{
          left,
        }}
        className="timeline-horizontal-scrollbar"
        ref={this.catchRefScrollbar}
        onScroll={this.onScroll}
      >
        <div
          className="timeline-horizontal-scrollbar-line"
          ref={this.catchRefScrollbarLine}
          style={{
            height: 1,
          }}
        />
      </div>
    );
  }
}

export const HorizontalScrollBarConnected = connect<
  StateProps,
  DispatchProp<AnyAction>,
  {},
  RootState
>((state: RootState) => ({
  timelineWidth: state.ui.width - state.ui.planeBlockWidth,
  left: state.ui.planeBlockWidth,
}))(HorizontalScrollBar);
