import { gql } from '@apollo/client';
import { apolloClient } from '../data-processing/graphql';

import Flight from '../types/flight';
import {
  MarkFlightMutationVariables,
  MarkFlightMutation,
} from '../types/operation-result-types';

const markFlight = require('./mark-flight.gql');

export function markFlightMutation(
  f: Flight,
  newDemo: boolean,
  newLocked: boolean,
  newSensitive: boolean,
  newLineCheck: boolean
) {
  const variables: MarkFlightMutationVariables = {
    id: f.id,
    newDemo,
    newLocked,
    newSensitive,
    newLineCheck,
  };
  return apolloClient.mutate<MarkFlightMutation>({
    mutation: gql(markFlight),
    variables,
  });
}
