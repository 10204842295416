import { call, put } from 'redux-saga/effects';

import * as actions from '../actions';
import { getAircraft } from '../services/aircraft-service/service';
import { getPeakDays } from '../services/peak-day-service/service';
import { getBaseCompanies } from '../services/base-company-service/service';
import { getMaintenanceItems } from '../services/maintenance-item-service/service';

export function* getAircraftSaga() {
  yield put(actions.doAircraftMappersFetch.started(null));
  try {
    const startFetchingAircraft = new Date().valueOf();
    const result = yield call(getAircraft);
    if (!result) {
      throw new Error(`Failed to fetch aircraft list. Response is ${result}`);
    }
    const endFetchingAircraft = new Date().valueOf();
    yield put(
      actions.doAircraftMappersFetch.done(
        {
          params: null,
          result,
        },
        {
          aircraftLoadingTime: endFetchingAircraft - startFetchingAircraft,
        }
      )
    );
  } catch (error) {
    yield put(actions.doAircraftMappersFetch.failed({ params: null, error }));
  }
}

export function* getPeakDaysSaga() {
  yield put(actions.doGetPeakDays.started(null));
  try {
    const startFetchingPeakDays = new Date().valueOf();
    const result = yield call(getPeakDays);
    if (!result) {
      throw new Error(`Failed to fetch Peak Days. Response is ${result}`);
    }
    const endFetchingPeakDays = new Date().valueOf();
    yield put(
      actions.doGetPeakDays.done(
        {
          params: null,
          result,
        },
        {
          peakDaysLoadingTime: endFetchingPeakDays - startFetchingPeakDays,
        }
      )
    );
  } catch (error) {
    yield put(actions.doGetPeakDays.failed({ params: null, error }));
  }
}

export function* getBaseCompaniesSaga() {
  yield put(actions.doGetBaseCompanies.started(null));
  try {
    const startFetchingBaseCompanies = new Date().valueOf();
    const result = yield call(getBaseCompanies);
    const endFetchingBaseCompanies = new Date().valueOf();
    if (!result) {
      throw new Error(`Failed to fetch base companies. Response is ${result}`);
    }
    yield put(
      actions.doGetBaseCompanies.done(
        {
          params: null,
          result,
        },
        {
          baseCompaniesLoadingTime:
            endFetchingBaseCompanies - startFetchingBaseCompanies,
        }
      )
    );
  } catch (error) {
    yield put(actions.doGetBaseCompanies.failed({ params: null, error }));
  }
}

export function* getMaintenanceItemsSaga() {
  yield put(actions.doMaintenanceItemsMappersFetch.started());
  try {
    const startFetchingMaintenanceItems = new Date().valueOf();
    const result = yield call(getMaintenanceItems);
    if (!result) {
      throw new Error(
        `Failed to fetch maintenance items. Response is ${result}`
      );
    }
    const endFetchingMaintenanceItems = new Date().valueOf();
    yield put(
      actions.doMaintenanceItemsMappersFetch.done(
        {
          params: null,
          result,
        },
        {
          maintenanceItemsLoadingTime:
            endFetchingMaintenanceItems - startFetchingMaintenanceItems,
        }
      )
    );
  } catch (error) {
    yield put(
      actions.doMaintenanceItemsMappersFetch.failed({ params: null, error })
    );
  }
}
