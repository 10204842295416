import OneWayOffer from '../../types/one-way-offer';

export interface OneWayOfferGVModel {
  id: {
    key: number;
  };
  status: {
    offerStatusId: {
      key: number;
    };
    name: string;
    description: string;
  };
  validFrom: number;
  validTo: number;
  aircraftId: {
    key: number;
  };
  departureAirportId: {
    key: number;
  };
  creator: {
    personId: {
      key: number;
    };
    firstName: string;
    lastName: string;
    active: boolean;
  };
  segmentStart: number;
  segmentEnd: number;
  defaultStart: number;
  defaultEnd: number;
  cancellationReason: string;
  alternativeAirports: string[];
}

export const transformToFE = (o: OneWayOfferGVModel): OneWayOffer => ({
  id: o.id.key,
  offerStatus: o.status.offerStatusId.key,
  departureAirportId: o.departureAirportId.key,
  validFrom: o.validFrom,
  validTo: o.validTo,
  aircraftId: o.aircraftId.key,
  start: o.segmentStart,
  end: o.segmentEnd,
  creator: {
    id: o.creator.personId.key,
    firstName: o.creator.firstName,
    lastName: o.creator.lastName,
  },
  cancellationReason: o.cancellationReason,
  alternativeAirports: o.alternativeAirports,
});
