import * as d3S from 'd3-selection';

export type SegmentDatum = {
  x: number;
  y: number;
  width: number;
  height: number;
  color: string;
  borderColor: string;
  element: string;
};
export type AppenderMel = (
  container: d3S.Selection<d3S.BaseType, {}, null, undefined>,
  coordinates: SegmentDatum
) => void;
export type SegmentParts = 'body' | 'border' | 'leftBorder';
const appendNoteBody = (container, { x, y, width, height, color, element }) => {
  container
    .append('rect')
    .classed(`${element}-body`, true)
    .attr('x', x)
    .attr('y', y)
    .style('fill', color)
    .attr('width', width)
    .attr('height', height);
};
const appendNoteLeftBorder = (container, { x, y, height, borderColor }) => {
  container
    .append('path')
    .attr('d', `M ${x} ${y} V ${y + height}`)
    .attr('fill', 'transparent')
    .attr('stroke', borderColor)
    .attr('stroke-width', 3)
    .attr('vector-effect', 'non-scaling-stroke');
};
const appendNoteBorder = (container, { x, y, width, height, borderColor }) => {
  container
    .append('rect')
    .attr('x', x)
    .attr('y', y)
    .attr('width', width)
    .attr('height', height)
    .style('fill', 'transparent')
    .attr('stroke', borderColor)
    .attr('stroke-width', 1)
    .attr('vector-effect', 'non-scaling-stroke');
};
const appender: { [sp in SegmentParts]: AppenderMel } = {
  body: appendNoteBody,
  border: appendNoteBorder,
  leftBorder: appendNoteLeftBorder,
};

export default appender;
