import { transformToFE } from './maintenance-item-model';
import { axiosAuthed as axios } from '../axios-config';

export const getMaintenanceItems = () =>
  axios
    .get(`/integration/timeline/maintenance-items`)
    .then(resp => resp.data.map(transformToFE))
    .catch(e => {
      throw e;
    });
