import { xor } from 'lodash';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as actions from '../actions';
import Flight from '../types/flight';
import { NearestAircraftTableData } from '../types/nearest-aircraft';
import {
  getStateOnToggleAircraftOrActype,
  getStateOnToggleOperator,
} from '../utils/aircraft';

export interface NearestAircraftState {
  downgradeTableData: NearestAircraftTableData[];
  flight: Flight | null;
  inFleetTableData: NearestAircraftTableData[];
  loading: boolean;
  maxFerryRange: [number, number];
  nextEventRange: [number, number];
  upgradeTableData: NearestAircraftTableData[];
  visibleAircraftIds: number[] | null; // null means all visible
  mainOperatorIds: number[] | null; // to indicate subCharters
  showSubCharters: boolean;
  acTypeFilterByOperatorMap: { [companyId: number]: number[] };
  minimized: boolean;
  isTailSearch: boolean;
}

export const initialState: NearestAircraftState = {
  downgradeTableData: [],
  flight: null,
  inFleetTableData: [],
  loading: false,
  maxFerryRange: [0, 0],
  nextEventRange: [0, 0],
  upgradeTableData: [],
  visibleAircraftIds: null,
  mainOperatorIds: null,
  showSubCharters: true,
  acTypeFilterByOperatorMap: {},
  minimized: false,
  isTailSearch: false,
};

export default reducerWithInitialState(initialState)
  .case(actions.userOpenNearestAircraftModal, (state, payload) => ({
    ...state,
    flight: payload,
    minimized: false,
  }))
  .case(actions.doGetNearestAircraftData.started, state => ({
    ...state,
    loading: true,
  }))
  .case(actions.doGetNearestAircraftData.done, (state, payload) => ({
    ...state,
    ...payload.result,
    loading: false,
  }))
  .case(actions.userChangeMaxFerryRange, (state, payload) => ({
    ...state,
    maxFerryRange: payload,
  }))
  .case(actions.userChangeNextFlightTimeRange, (state, payload) => ({
    ...state,
    nextEventRange: payload,
  }))
  .case(actions.doHydrateAircraftFilterData, (state, payload) => ({
    ...state,
    ...payload,
  }))
  .case(actions.userToggleNearestAcSubChartersView, state => ({
    ...state,
    showSubCharters: !state.showSubCharters,
  }))
  .case(actions.userToggleNearestAcSelectAllView, (state, payload) => {
    const { selected, allOptionsMap, allAcIds } = payload;
    return {
      ...state,
      showSubCharters: selected,
      acTypeFilterByOperatorMap: !selected ? {} : allOptionsMap,
      visibleAircraftIds: !selected ? [] : allAcIds,
    };
  })
  .case(actions.userToggleNearestAcOperatorView, (state, payload) => {
    const {
      visibleAcIds: visibleAircraftIds,
      visibleAircraftTypeByOperatingCompanyMap: acTypeFilterByOperatorMap,
    } = getStateOnToggleOperator(
      payload,
      state.acTypeFilterByOperatorMap,
      state.visibleAircraftIds
    );
    return {
      ...state,
      acTypeFilterByOperatorMap,
      visibleAircraftIds,
    };
  })
  .cases(
    [
      actions.userToggleNearestAcAircraftTypeView,
      actions.userToggleNearestAcExactTailView,
    ],
    (state, payload) => {
      const {
        visibleAcIds,
        visibleAircraftTypeByOperatingCompanyMap,
      } = getStateOnToggleAircraftOrActype(
        payload,
        state.acTypeFilterByOperatorMap,
        state.visibleAircraftIds
      );
      return {
        ...state,
        acTypeFilterByOperatorMap: visibleAircraftTypeByOperatingCompanyMap,
        visibleAircraftIds: visibleAcIds,
      };
    }
  )
  .case(actions.userToggleNearestAircraftModalSize, state => ({
    ...state,
    minimized: !state.minimized,
  }))
  .case(actions.userSearchLeg.started, state => ({
    ...state,
    minimized: !state.minimized && state.flight ? true : state.minimized,
  }))
  .case(actions.userChangeSearchACQuery, (state, payload) => ({
    ...state,
    minimized: payload.isFromNearestAC ? true : state.minimized,
    isTailSearch: payload.isFromNearestAC,
  }))
  .cases(
    [
      actions.userCloseNearestAircraftModal,
      actions.doGetNearestAircraftData.failed,
    ],
    () => ({ ...initialState })
  );
