import { Tooltip, Button } from 'antd';
import { FC } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../reducers';
import { NearestAircraftTableData } from '../../../types/nearest-aircraft';
import { openFlightCenter, openOrderWindow } from '../../../utils';
import { hasPermission } from '../../../utils/check-permissions';
import { AircraftIcon } from '../../svg-icons/aircraft/AircraftIcon';
import { OrderWindowIcon } from '../../svg-icons/aircraft/OrderWindowIcon';

interface StateProps {
  hasFCPermission: boolean;
  hasOWPermission: boolean;
}

const ActionButtons: FC<StateProps & { record: NearestAircraftTableData }> = ({
  record,
  hasFCPermission,
  hasOWPermission,
}) => {
  const { orderId, flightLegId } = record;
  const openOrderWindowApp = () => {
    openOrderWindow({ id: orderId });
  };
  const openWindowFlightCenter = () => {
    openFlightCenter(flightLegId);
  };

  return (
    <div className="nearest-ac-modal-body-buttons">
      {hasFCPermission && (
        <Button
          type="link"
          onClick={openWindowFlightCenter}
          className="nearest-ac-modal-body-buttons-link"
        >
          {flightLegId}
        </Button>
      )}
      <div className="nearest-ac-modal-body-buttons">
        {hasOWPermission && (
          <Tooltip placement="bottom" title={<div>Open Order Window</div>}>
            <Button
              type="link"
              className="nearest-ac-modal-body-buttons-with-icon"
              onClick={openOrderWindowApp}
            >
              <OrderWindowIcon />
            </Button>
          </Tooltip>
        )}
        {hasFCPermission && (
          <Tooltip placement="bottom" title={<div>Open Flight Centre</div>}>
            <Button
              type="link"
              className="nearest-ac-modal-body-buttons-with-icon"
              onClick={openWindowFlightCenter}
            >
              <AircraftIcon />
            </Button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export const ActionButtonsConnected = connect(
  (state: RootState): StateProps => ({
    hasFCPermission: hasPermission(state, 'AG-Timeline-Flight-Center-View'),
    hasOWPermission: hasPermission(state, 'AG-Timeline-Order-Window-View'),
  })
)(ActionButtons);
