import { utc } from 'moment';
import {
  DAY_IN_MILLISECONDS,
  GENERATE_NEW_ID,
  ONE_MINUTE_MS,
} from '../../../constants';
import { SavePeakDayMutationVariables } from '../../../types/operation-result-types';

export enum PeakDayFormFieldName {
  id = 'id', // hidden
  start = 'start',
  end = 'end',
  description = 'description',
  autoAdvertising = 'autoAdvertising',
  crewChange = 'crewChange',
  types = 'types',
  regions = 'regions',
}

export enum PeakDayRegions {
  int = 'INT', // International
  nam = 'NAM', // North America
}
export enum PeakDayTypeId {
  PEAK = 1,
  ROLLING_PEAK,
  HIGH_DEMAND,
}

export interface PeakDayFormFieldValues {
  [PeakDayFormFieldName.id]: number;
  [PeakDayFormFieldName.description]: string; // max 400 chars
  [PeakDayFormFieldName.end]: number;
  [PeakDayFormFieldName.start]: number;
  [PeakDayFormFieldName.autoAdvertising]: boolean;
  [PeakDayFormFieldName.crewChange]: boolean;
  [PeakDayFormFieldName.types]: number[];
  [PeakDayFormFieldName.regions]: number[];
}

export const defaultValues: PeakDayFormFieldValues = {
  description: '',
  end: null,
  id: null,
  autoAdvertising: false,
  crewChange: false,
  start: null,
  regions: [],
  types: [],
};

export const transformPeakDayFormValuesForSave = (
  values: PeakDayFormFieldValues
): SavePeakDayMutationVariables['day'] => {
  const { autoAdvertising, crewChange, description, regions, types } = values;
  const end = utc(values.end)
    .endOf('day')
    .valueOf();
  const start = utc(values.start)
    .startOf('day')
    .valueOf();
  const id = values.id === GENERATE_NEW_ID ? null : values.id;
  const lastUpdate = utc().valueOf();

  return {
    autoAdvertising,
    crewChange,
    description,
    id,
    end,
    lastUpdate,
    start,
    peakDayRegions: regions
      .sort((a, b) => a - b)
      .map(id => ({
        id,
      })),
    peakDayTypes: types
      .sort((a, b) => a - b)
      .map(id => ({
        id,
      })),
  };
};

export const isTimeGapLessThanDay = (timeStamp: number) => {
  return utc().valueOf() < timeStamp + DAY_IN_MILLISECONDS + ONE_MINUTE_MS;
};
