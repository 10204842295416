import { SegmentsAppenderArgs } from '..';
import { getLaneHeightKoef } from '../../../../../../reducers/ui';
import {
  getOrderTypeColor,
  getBorderColorForNotCoverWithOtherBorderFlights,
} from '../../../../../../common/flight/flight-colorization';
import {
  isUnconfirmedOrOptionFlightStatus,
  isNoFpStarted,
  isFailedPFAorFPIteration,
} from '../../../../../../common/flight/flight-check-status';

export function appendFlightBorders(argsObject: SegmentsAppenderArgs) {
  const {
    selection,
    elementName,
    segmentsVisibility,
    positionMap,
    togglersState,
    scaleX,
  } = argsObject;
  selection
    .append('rect')
    .attr('y', d => d.y)
    .attr('x', d => scaleX(d.start))
    .attr(
      'height',
      f =>
        positionMap[elementName] /
        getLaneHeightKoef(
          segmentsVisibility,
          togglersState[f.aircraftId],
          positionMap
        )
    )
    .attr('width', d => this.rectWidthCalculator(d, scaleX))
    .style('fill', 'transparent')
    .style('overflow', 'visible')
    .attr('stroke-width', 2)
    .attr('stroke', d => d.slaBorder)
    .attr('vector-effect', 'non-scaling-stroke');
  selection
    .filter(d => isUnconfirmedOrOptionFlightStatus(d))
    .append('rect')
    .attr('y', d => d.y)
    .attr('x', d => scaleX(d.start))
    .attr(
      'height',
      f =>
        positionMap[elementName] /
        getLaneHeightKoef(
          segmentsVisibility,
          togglersState[f.aircraftId],
          positionMap
        )
    )
    .attr('width', d => this.rectWidthCalculator(d, scaleX))
    .style('fill', 'transparent')
    .style('overflow', 'visible')
    .attr('stroke-width', 2)
    .attr('stroke-dasharray', 4)
    .attr('stroke', d => getOrderTypeColor(d.orderBusinessTypeId).primaryColor)
    .attr('vector-effect', 'non-scaling-stroke');
  selection
    .filter(d => isNoFpStarted(d))
    .append('rect')
    .attr('y', d => d.y)
    .attr('x', d => scaleX(d.start))
    .attr(
      'height',
      f =>
        positionMap[elementName] /
        getLaneHeightKoef(
          segmentsVisibility,
          togglersState[f.aircraftId],
          positionMap
        )
    )
    .attr('width', d => this.rectWidthCalculator(d, scaleX))
    .style('fill', 'transparent')
    .style('overflow', 'visible')
    .attr('stroke-width', 2)
    .attr('stroke-dasharray', 4)
    .attr('stroke', d => getOrderTypeColor(d.orderBusinessTypeId).primaryColor)
    .attr('vector-effect', 'non-scaling-stroke');
  selection
    .filter(d => isFailedPFAorFPIteration(d))
    .append('rect')
    .attr('y', d => d.y)
    .attr('x', d => scaleX(d.start))
    .attr(
      'height',
      f =>
        positionMap[elementName] /
        getLaneHeightKoef(
          segmentsVisibility,
          togglersState[f.aircraftId],
          positionMap
        )
    )
    .attr('width', d => this.rectWidthCalculator(d, scaleX))
    .style('fill', 'transparent')
    .style('overflow', 'visible')
    .attr('stroke-width', 2)
    .attr('stroke', '#964B00') // brown color
    .attr('vector-effect', 'non-scaling-stroke');
  selection
    .filter(
      d =>
        !isNoFpStarted(d) &&
        !isUnconfirmedOrOptionFlightStatus(d) &&
        !isFailedPFAorFPIteration(d) &&
        d.slaBorder === 'none'
    )
    .append('rect')
    .attr('y', d => d.y)
    .attr('x', d => scaleX(d.start))
    .attr(
      'height',
      f =>
        positionMap[elementName] /
        getLaneHeightKoef(
          segmentsVisibility,
          togglersState[f.aircraftId],
          positionMap
        )
    )
    .attr('width', d => this.rectWidthCalculator(d, scaleX))
    .style('fill', 'transparent')
    .style('overflow', 'visible')
    .attr('stroke-width', 0.5)
    .attr('stroke', d => getBorderColorForNotCoverWithOtherBorderFlights(d))
    .attr('vector-effect', 'non-scaling-stroke');
}
