import { useDispatch } from 'react-redux';
import * as actions from '../../../../../../actions/index';
import { LogoutOutlined } from '@ant-design/icons';
import { Menu, MenuProps } from 'antd';

import './styles.scss';
import { FC, useCallback } from 'react';
import { USE_AUTH } from '../../../../../../constants/environment';
import { useAuth0 } from '../../../../../../Auth0Wrapper';

const Logout: FC<{}> = () => {
  const dispatch = useDispatch();
  const { logout: auth0logout } = useAuth0();
  const onClick = useCallback(() => {
    if (USE_AUTH) {
      auth0logout();
    } else {
      dispatch(actions.userLogout());
    }
  }, [USE_AUTH]);
  const items: MenuProps['items'] = [
    {
      type: 'divider',
      key: 'divider',
    },
    {
      label: (
        <>
          Logout <LogoutOutlined />
        </>
      ),
      className: 'logout-button',
      key: 'item',
      onClick,
    },
  ];

  return (
    <div className="logout">
      <Menu mode="inline" theme="dark" className="logout-menu" items={items} />
    </div>
  );
};

export default Logout;
