import { HandoverEmail, HandoverRemark } from '../../types/handover-remarks';
import { axiosAuthed as axios } from '../axios-config';

export const getAllHandoverRemarks = () =>
  axios
    .get(`/handover/api`)
    .then(resp => resp.data)
    .catch(e => {
      throw e;
    });

export const getHandoverRemarksForFlight = (flightLegId: number) =>
  axios
    .get(`/handover/api?flightLegId=${flightLegId}`)
    .then(resp => resp.data)
    .catch(e => {
      throw e;
    });

export const getHandoverRemarksForAircraft = (aircraftId: number) =>
  axios
    .get(`/handover/api?aircraftId=${aircraftId}`)
    .then(resp => resp.data)
    .catch(e => {
      throw e;
    });

export const getHandoverRemarksById = (id: string) =>
  axios
    .get(`/handover/api?id=${id}`)
    .then(resp => resp.data)
    .catch(e => {
      throw e;
    });

export const saveHandoverRemark = (remark: HandoverRemark) =>
  axios
    .post<{ saved: boolean }>(`/handover/api`, JSON.stringify(remark))
    .then(d => d.data.saved)
    .catch(e => {
      throw e;
    });

export const deleteHandoverRemark = (id: string) =>
  axios
    .delete<{ deactivated: boolean }>(`/handover/api?id=${id}`)
    .then(d => d.data.deactivated)
    .catch(e => {
      throw e;
    });

export const sendHandoverEmail = (email: HandoverEmail) =>
  axios
    .post(`/handover/api`, JSON.stringify(email))
    .then(d => d)
    .catch(e => {
      throw e;
    });
